export default {
  BA: [
    "Bari",
    "Acquaviva delle Fonti",
    "Adelfia",
    "Alberobello",
    "Altamura",
    "Bari Sardo",
    "Bitonto",
    "Bitritto",
    "Capurso",
    "Casamassima",
    "Castellana Grotte",
    "Cellamare",
    "Conversano",
    "Corato",
    "Gioia del Colle",
    "Giovinazzo",
    "Gravina in Puglia",
    "Grumo Appula",
    "Locorotondo",
    "Modugno",
    "Mola di Bari",
    "Molfetta",
    "Monopoli",
    "Noci",
    "Noicattaro",
    "Palo del Colle",
    "Poggiorsini",
    "Polignano a Mare",
    "Putignano",
    "Rutigliano",
    "Ruvo di Puglia",
    "Sammichele di Bari",
    "Sannicandro di Bari",
    "Santeramo in Colle",
    "Terlizzi",
    "Toritto",
    "Triggiano",
    "Turi",
    "Valenzano",
  ],
  BT: [
    "Andria",
    "Barletta",
    "Bisceglie",
    "Canosa di Puglia",
    "Margherita di Savoia",
    "Minervino Murge",
    "San Ferdinando di Puglia",
    "Spinazzola",
    "Trani",
    "Trinitapoli",
  ],
  BR: [
    "Brindisi",
    "Carovigno",
    "Ceglie Messapica",
    "Cellino San Marco",
    "Cisternino",
    "Erchie",
    "Fasano",
    "Francavilla Fontana",
    "Latiano",
    "Mesagne",
    "Oria",
    "Ostuni",
    "San Donaci",
    "San Michele Salentino",
    "San Pancrazio Salentino",
    "San Pietro Vernotico",
    "San Vito dei Normanni",
    "Torchiarolo",
    "Torre Santa Susanna",
    "Villa Castelli",
  ],
  FG: [
    "Foggia",
    "Lucera",
    "San Severo",
    "Apricena",
    "Cagnano Varano",
    "Candela",
    "Carapelle",
    "Carpino",
    "Casalnuovo Monterotaro",
    "Casalvecchio di Puglia",
    "Castelluccio dei Sauri",
    "Castelluccio Valmaggiore",
    "Castelnuovo della Daunia",
    "Celenza Valfortore",
    "Cerignola",
    "Chieuti",
    "Deliceto",
    "Ischitella",
    "Isole Tremiti",
    "Lesina",
    "Manfredonia",
    "Mattinata",
    "Monte Sant'Angelo",
    "Monteleone di Puglia",
    "Monteiasi",
    "Ordona",
    "Orsara di Puglia",
    "Orta Nova",
    "Panni",
    "Peschici",
    "Pietramontecorvino",
    "Poggio Imperiale",
    "Rignano Garganico",
    "Rocchetta Sant'Antonio",
    "Rodi Garganico",
    "Roseto Valfortore",
    "San Giovanni Rotondo",
    "San Marco in Lamis",
    "San Marco la Catola",
    "San Nicandro Garganico",
    "San Paolo di Civitate",
    "San Pietro in Bevagna",
    "San Severo",
    "Sant'Agata di Puglia",
    "Serracapriola",
    "Stornara",
    "Stornarella",
    "Torremaggiore",
    "Troia",
    "Vico del Gargano",
    "Vieste",
    "Volturara Appula",
    "Volturino",
    "Zapponeta",
  ],
  LE: [
    "Lecce",
    "Acquarica del Capo",
    "Alessano",
    "Alezio",
    "Alliste",
    "Andrano",
    "Aradeo",
    "Arnesano",
    "Bagnolo del Salento",
    "Botrugno",
    "Calimera",
    "Campi Salentina",
    "Cannole",
    "Caprarica di Lecce",
    "Carmiano",
    "Carpignano Salentino",
    "Casarano",
    "Castri di Lecce",
    "Castrignano de' Greci",
    "Castrignano del Capo",
    "Cavallino",
    "Collepasso",
    "Copertino",
    "Corigliano d'Otranto",
    "Corsano",
    "Cursi",
    "Cutrofiano",
    "Diso",
    "Gagliano del Capo",
    "Galatina",
    "Galatone",
    "Gallipoli",
    "Giuggianello",
    "Giurdignano",
    "Guagnano",
    "Leverano",
    "Maglie",
    "Martano",
    "Martignano",
    "Matino",
    "Melendugno",
    "Melissano",
    "Melpignano",
    "Miggiano",
    "Minervino di Lecce",
    "Monteroni di Lecce",
    "Montesano Salentino",
    "Morciano di Leuca",
    "Muro Leccese",
    "Nardò",
    "Neviano",
    "Nociglia",
    "Novoli",
    "Ortelle",
    "Otranto",
    "Palmariggi",
    "Parabita",
    "Patù",
    "Poggiardo",
    "Presicce-Acquarica",
    "Racale",
    "Ruffano",
    "Salice Salentino",
    "Salve",
    "San Cassiano",
    "San Cesario di Lecce",
    "San Donato di Lecce",
    "San Pietro in Lama",
    "Sanarica",
    "Sannicola",
    "Santa Cesarea Terme",
    "Scorrano",
    "Seclì",
    "Sogliano Cavour",
    "Soleto",
    "Specchia",
    "Spongano",
    "Squinzano",
    "Sternatia",
    "Supersano",
    "Surano",
    "Surbo",
    "Taurisano",
    "Taviano",
    "Tiggiano",
    "Trepuzzi",
    "Tricase",
    "Tuglie",
    "Ugento",
    "Uggiano la Chiesa",
    "Veglie",
    "Vernole",
    "Zollino",
  ],
  TA: [
    "Taranto",
    "Avetrana",
    "Carosino",
    "Castellaneta",
    "Crispiano",
    "Faggiano",
    "Fragagnano",
    "Ginosa",
    "Grottaglie",
    "Laterza",
    "Leporano",
    "Lizzano",
    "Manduria",
    "Martina Franca",
    "Maruggio",
    "Massafra",
    "Monteiasi",
    "Montemesola",
    "Monteparano",
    "Mottola",
    "Palagiano",
    "Pulsano",
    "Roccaforzata",
    "San Giorgio Ionico",
    "San Marzano di San Giuseppe",
    "Sava",
    "Statte",
    "Taranto",
    "Torricella",
  ],
};
