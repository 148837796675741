<template>
  <Alert
    v-if="alert.show"
    :message="alert.header"
    :description="alert.message"
    :type="alert.type"
    showIcon
  />

  <div class="form">
    <Card style="width: 100%" :bordered="false" class="widget-1">
      <div class="input-wrapper">
        <img width="200" src="../assets/logo.png" />
        <p>Puoi inserire qualsiasi email e password</p>
        <Input placeholder="Email" v-model:value="emailInput">
          <template #prefix>
            <UserOutlined />
          </template>
        </Input>
        <Input
          placeholder="Password"
          type="password"
          v-model:value="passwordInput"
        >
          <template #prefix>
            <KeyOutlined />
          </template>
        </Input>
        <Button size="large" type="primary" @click="handleLogin">Login</Button>
      </div></Card
    >
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { Input, Button, Alert, message, Card } from "ant-design-vue";
import { UserOutlined, KeyOutlined } from "@ant-design/icons-vue";
import { login } from "../common/api/auth.api";
import { useUserStore } from "@/store/user.store";
import router from "@/router";
export default {
  components: { Input, UserOutlined, KeyOutlined, Button, Alert, Card },
  setup() {
    const userStore = useUserStore();

    const emailInput = ref("");
    const passwordInput = ref("");
    const user = computed(() => {
      return {
        email: emailInput.value,
        password: passwordInput.value,
      };
    });
    const alert = ref({
      show: false,
      type: "",
      header: "",
      message: "",
    });
    function handleLogin() {
      login(user.value).then((response) => {
        if (response.success) {
          const { nome, cognome, email, ruolo } = response.user;
          const { access_token } = response;
          userStore.setUser({ nome, cognome, access_token, email, ruolo });
          router.push("/terreni");
        } else {
          message.error("Impossibile loggare l'utente");
        }
      });
    }
    return { emailInput, passwordInput, handleLogin, alert };
  },
};
</script>

<style scoped>
.form {
  width: 80%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-wrapper > * {
  margin-top: 5%;
}

@media screen and (min-width: 768px) {
  .form {
    width: 50%;
    height: 40%;
  }
}
@media screen and (min-width: 1024px) {
  .form {
    width: 30%;
    height: 40%;
  }
}

@media screen and (max-width: 767px) {
  .input-wrapper {
    width: 80%;
  }
}
</style>
