<template>
  <Spin v-if="isLoading" tip="Caricamento..." size="large"> </Spin>
  <Form :form="form" @finish="handleFinish" v-if="!isLoading">
    <FormItem label="Nominativo" name="nome">
      <AutoComplete
        @select="onSelect"
        :options="
          utenti.map((utente) => {
            return {
              value: `${utente.nome} ${utente.cognome}`,
              id: utente._id,
            };
          })
        "
        v-model:value="form.nome"
      />
    </FormItem>
    <FormItem label="Ruolo" name="ruolo">
      <Input v-model:value="form.ruolo" />
    </FormItem>
    <FormItem>
      <Button type="primary" html-type="submit" @click="handleFinish">
        <PlusCircleOutlined /> Aggiungi Gestione
      </Button>
    </FormItem>
  </Form>
</template>
<script>
import { defineComponent, ref } from "vue";
import { useUserStore } from "@/store/user.store";
import {
  Form,
  FormItem,
  Input,
  Button,
  Spin,
  AutoComplete,
} from "ant-design-vue";
import { PlusCircleOutlined } from "@ant-design/icons-vue";
import { UsersApi } from "@/common/api/users.api";

export default defineComponent({
  name: "AddProprietarioModal",
  components: {
    Form,
    FormItem,
    Input,
    Button,
    Spin,
    AutoComplete,
    PlusCircleOutlined,
  },

  emits: ["update:visible", "add-gestione"],
  setup(props, { emit }) {
    const form = ref({
      nome: "",
      ruolo: "",
    });

    const isLoading = ref(true);
    const utenti = ref([]);
    const utente = ref("");
    const userStore = useUserStore();
    const usersApi = new UsersApi(userStore.user.access_token);
    const onSelect = (data) => {
      const selected = utenti.value.find(
        (utente) =>
          `${utente.nome} ${utente.cognome}`.toLowerCase().trim() ==
          data.toLowerCase().trim()
      );
      utente.value = selected;
    };

    usersApi.getAllUsers().then((response) => {
      if (response.success) {
        isLoading.value = false;
        utenti.value = response.users;
      }
    });

    const handleFinish = () => {
      emit("add-gestione", {
        utente: utente.value,
        ruolo: form.value.ruolo,
      });

      form.value = {
        nome: "",
        ruolo: 0,
      };
    };

    return {
      form,
      handleFinish,
      isLoading,
      utenti,
      utente,
      onSelect,
    };
  },
});
</script>
<style>
#map {
  width: 100%;
  height: 300px;
  border: 1px solid black;
}
</style>
