<template>
  <Alert
    v-if="action.show"
    :message="action.header"
    :description="action.message"
    :type="action.type"
    showIcon
  />

  <Modal
    title="Crea nuovo Terreno"
    :footer="null"
    v-model:visible="showCreateLandModal"
  >
    <CreateTerrenoModal
      @create-land="handleCreateLand"
      @update:visible="handleCreateLandClick(false)"
      :lands="lands"
      :proprietari="proprietari"
    />
  </Modal>

  <div>
    <Card :bordered="false" class="widget-1">
      <Statistic title="Lista" value="Terreni" class="text-success" />
      <div class="icon">
        <BlockOutlined />
      </div>
    </Card>
    <Button
      style="margin-top: 20px"
      v-if="userPermission != 'view'"
      @click="handleCreateLandClick(true)"
      type="primary"
      ><PlusCircleOutlined /> Nuovo Terreno</Button
    >

    <Skeleton :loading="isLoading" active>
      <Empty v-if="!lands.length" :image="emptyImage">
        <template #description> Nessun dato </template>
      </Empty>
      <TerreniTable
        v-else-if="!isLoading && !action.show && lands.length"
        style="margin-top: 10px"
        @view-land="handleViewLandClick"
        @delete-land="handleDeleteLandClick"
        :lands="lands"
      />
    </Skeleton>
  </div>
</template>

<script>
import { ref } from "vue";
import TerreniTable from "@/components/terreni/TerreniTable.vue";
import {
  Modal,
  Alert,
  message,
  Card,
  Statistic,
  Skeleton,
  Empty,
} from "ant-design-vue";
import CreateTerrenoModal from "@/components/terreni/CreateTerrenoModal.vue";
import { PlusCircleOutlined, BlockOutlined } from "@ant-design/icons-vue";
import { Button } from "ant-design-vue";
import { LandsApi } from "@/common/api/lands.api";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user.store";
export default {
  name: "TerreniView",
  components: {
    TerreniTable,
    Modal,
    Empty,
    CreateTerrenoModal,
    Button,
    PlusCircleOutlined,
    BlockOutlined,
    Alert,
    Card,
    Statistic,
    Skeleton,
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(true);
    const apiError = ref({ hasError: false, message: true });
    const action = ref({ show: false, message: "", header: "", type: "" });
    const showCreateLandModal = ref(false);
    const lands = ref([]);
    const emptyImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const userStore = useUserStore();
    const userPermission = userStore.user.ruolo;
    const landsApi = new LandsApi(userStore.user.access_token);

    landsApi.getAllLands().then((response) => {
      if (!response.success) {
        action.value = {
          show: true,
          message: "Impossibile reperire i terreni",
          header: "Errore",
          type: "error",
        };
      } else {
        lands.value = response.lands;
      }

      isLoading.value = false;
    });
    function handleCreateLandClick(val) {
      showCreateLandModal.value = val;
    }
    function handleDeleteLandClick(id) {
      landsApi
        .deleteLand(id)
        .then((response) => {
          if (!response.success) {
            apiError.value = { hasError: true, message: response.error };
          } else {
            message.success("Terreno eliminato");
            const filterLand = lands.value.filter((land) => land._id == id);
            const filterIdx = lands.value.indexOf(filterLand);
            lands.value.splice(filterIdx, 1);
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    }
    function handleCreateLand(land) {
      console.log(land);
      isLoading.value = true;
      landsApi
        .createLand(land)
        .then((response) => {
          if (response.success) {
            message.success("Terreno creato");
            router.push(`/terreni/${response.land._id}`);
          } else throw new Error(response.error);
        })
        .catch((error) => message.error(error.message))
        .finally(() => {
          isLoading.value = false;
        });
    }
    function handleViewLandClick(id) {
      router.push(`/terreni/${id}`);
    }

    return {
      lands,
      showCreateLandModal,
      handleCreateLandClick,
      isLoading,
      apiError,
      handleCreateLand,
      action,
      handleDeleteLandClick,
      handleViewLandClick,
      userPermission,
      emptyImage,
    };
  },
};
</script>
