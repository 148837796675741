import axios from "axios";
const API_URL = process.env.VUE_APP_BACKEND_URL;

export async function login(user) {
  try {
    let res = await axios.post(`${API_URL}/auth/login`, user);
    return res.data;
  } catch (error) {
    return { success: false, error: error.message };
  }
}
