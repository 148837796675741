<template>
  <div>
    <Table :columns="columns" :dataSource="proprietariFiltered" theme="dark">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <div class="actions-column">
            <Button
              v-if="userPermission != 'view'"
              type="danger"
              @click="handleOwnerRemove(record)"
            >
              <DeleteOutlined /> Elimina
            </Button>
          </div>
        </template>

        <template v-if="column.dataIndex === 'percentuale'">
          {{ record.percentuale }}%
        </template>
      </template>
    </Table>
  </div>
</template>

<script>
import { ref } from "vue";
import { Table, Button } from "ant-design-vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { useUserStore } from "@/store/user.store";

export default {
  components: { Table, Button, DeleteOutlined },
  emits: ["owner-remove"],
  props: {
    proprietari: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const userStore = useUserStore();
    const userPermission = userStore.user.ruolo;
    const proprietariFiltered = props.proprietari.map((proprietario) => {
      return {
        _id: proprietario.proprietario._id,
        nome: proprietario.proprietario.nome,
        cognome: proprietario.proprietario.cognome,
        percentuale: proprietario.percentuale,
      };
    });
    const columns = ref([
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
      },
      {
        title: "Cognome",
        dataIndex: "cognome",
        key: "cognome",
      },
      {
        title: "Percentuale",
        dataIndex: "percentuale",
        key: "percentuale",
      },
      {
        title: "Azione",
        dataIndex: "action",
        key: "action",
      },
    ]);

    const handleOwnerRemove = (owner) => {
      emit("owner-remove", owner._id);
    };

    return {
      columns,
      props,
      handleOwnerRemove,
      proprietariFiltered,
      userPermission,
    };
  },
};
</script>
