<template>
  <div class="control-center">
    <div class="user-info">
      <div class="user-name">{{ userFullName }}</div>
      <Button type="danger" @click="handleLogout"
        ><LogoutOutlined />Esci</Button
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Button } from "ant-design-vue";
import { LogoutOutlined } from "@ant-design/icons-vue";
import { useUserStore } from "@/store/user.store";
import router from "@/router";

export default defineComponent({
  name: "ControlCenter",
  components: { Button, LogoutOutlined },
  setup() {
    const userStore = useUserStore();
    const handleLogout = () => {
      userStore.logout();
      router.push("/login");
    };
    const userFullName = userStore.getNomeCompleto;

    return {
      userFullName,
      handleLogout,
    };
  },
});
</script>

<style scoped>
.control-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.user-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  color: black;
}
</style>
