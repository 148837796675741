<template>
  <div>
    <Table :columns="columns" :dataSource="dataSource">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'buttons'">
          <div class="actions-column">
            <Button @click="handleViewClick(record._id)" type="primary">
              <EyeOutlined /> Visualizza
            </Button>

            <Button
              v-if="userPermission != 'view'"
              @click="handleDeleteClick(record._id)"
              type="danger"
            >
              <DeleteOutlined />
              Elimina
            </Button>
          </div>
        </template>
      </template>
    </Table>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { Table, Button } from "ant-design-vue";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons-vue";
import { useUserStore } from "@/store/user.store";

export default {
  name: "LandTable",
  components: {
    Table,
    Button,
    DeleteOutlined,
    EyeOutlined,
  },
  props: {
    lands: {
      type: Array,
      required: true,
    },
  },
  emits: ["delete-land", "view-land"],
  setup(props, { emit }) {
    const userStore = useUserStore();
    const userPermission = userStore.user.ruolo;
    const columns = ref([
      {
        title: "Progetto",
        dataIndex: "progetto",
      },
      {
        title: "Codice",
        dataIndex: "codice",
      },
      {
        title: "Comune",
        dataIndex: "comune",
      },
      {
        title: "Provincia",
        dataIndex: "provincia",
      },
      {
        title: "Superficie",
        dataIndex: "superficie",
      },
      {
        title: "Actions",
        dataIndex: "buttons",
      },
    ]);

    const dataSource = computed(() => {
      return props.lands.map((land) => {
        const { _id, codice, progetto, comune, provincia, superficie } = land;
        return {
          _id,
          codice,
          progetto,
          comune,
          provincia,
          superficie,
        };
      });
    });
    const handleViewClick = (record) => {
      emit("view-land", record);
    };

    const handleDeleteClick = (record) => {
      emit("delete-land", record);
    };

    return {
      columns,
      dataSource,
      handleViewClick,
      handleDeleteClick,
      userPermission,
    };
  },
};
</script>

<style scoped>
.actions-column {
  display: inline-block;
  display: flex;
  gap: 10px;
}
</style>
