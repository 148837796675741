import axios from "axios";

export class UsersApi {
  API_URL = process.env.VUE_APP_BACKEND_URL;
  API_TOKEN;

  constructor(token) {
    this.API_TOKEN = token;
  }

  async getAllUsers() {
    try {
      let res = await axios.get(`${this.API_URL}/users/`, {
        headers: { Authorization: `Bearer ${this.API_TOKEN}` },
      });
      return res.data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  }

  async getUser(id) {
    try {
      let res = await axios.get(`${this.API_URL}/users/${id}`, {
        headers: { Authorization: `Bearer ${this.API_TOKEN}` },
      });
      return res.data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  }

  async createUser(body) {
    try {
      let res = await axios.post(`${this.API_URL}/users`, body, {
        headers: { Authorization: `Bearer ${this.API_TOKEN}` },
      });
      return res.data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  }

  async updateUser(id, body) {
    try {
      let res = await axios.put(`${this.API_URL}/users/${id}`, body, {
        headers: { Authorization: `Bearer ${this.API_TOKEN}` },
      });
      return res.data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  }

  async deleteUser(id) {
    try {
      let res = await axios.delete(`${this.API_URL}/users/${id}`, {
        headers: { Authorization: `Bearer ${this.API_TOKEN}` },
      });
      return res.data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  }
}
