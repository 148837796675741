<template>
  <div v-if="note.length">
    <Comment v-for="nota in note" v-bind:key="nota">
      <template #actions>
        <p class="elimina-button" @click="deleteNota(nota)">Elimina</p>
      </template>

      <template #avatar
        ><Avatar style="background-color: #87d068">{{
          nota.author[0]
        }}</Avatar></template
      >
      <template #author
        ><a>{{ nota.author }}</a></template
      >
      <template #content>
        <p>
          {{ nota.content }}
        </p>
      </template>
      <template #datetime>
        <Tooltip :title="moment(nota.datetime).format('YYYY-MM-DD HH:mm:ss')">
          <span>{{ moment(nota.datetime).fromNow() }}</span>
        </Tooltip>
      </template>
    </Comment>
  </div>

  <Empty v-else :image="emptyImage">
    <template #description> Vuoto </template>
  </Empty>

  <Divider orientation="left" orientation-margin="0px"> Nuova Nota </Divider>

  <Comment>
    <template #content>
      <Form>
        <FormItem>
          <Input v-model:value="newNote" placeholder="Nota..." />
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit"> Aggiungi Nota </Button>
        </FormItem>
      </Form>
    </template>
  </Comment>
</template>

<script>
import { defineComponent, ref } from "vue";
import {
  Comment,
  Form,
  Input,
  Button,
  Empty,
  Divider,
  Avatar,
  Tooltip,
} from "ant-design-vue";
import moment from "moment";
export default defineComponent({
  name: "CreateTerrenoModal",
  props: { note: { required: true } },
  emits: ["cerate-note", "delete-note"],
  components: {
    Comment,
    Button,
    FormItem: Form.Item,
    Input,
    Empty,
    Divider,
    Avatar,
    Tooltip,
  },
  setup(props, { emit }) {
    let newNote = ref("");

    function handleSubmit() {
      emit("create-note", newNote.value);
    }

    function deleteNota(nota) {
      emit("delete-note", nota);
    }

    const emptyImage = Empty.PRESENTED_IMAGE_SIMPLE;

    return { newNote, handleSubmit, deleteNota, moment, emptyImage };
  },
});
</script>

<style scoped>
.elimina-button {
  color: rgb(237, 166, 166);
  cursor: pointer;
}
.elimina-button:hover {
  color: rgb(239, 0, 0);
}
</style>
