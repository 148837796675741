<template>
  <div>
    <Table :columns="columns" :dataSource="dataSource">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'buttons'">
          <div class="actions-column">
            <Button
              v-if="userPermission != 'view'"
              @click="handleDeleteClick(record)"
              type="danger"
            >
              <DeleteOutlined />
              Elimina
            </Button>
          </div>
        </template>
      </template>
    </Table>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { Table, Button } from "ant-design-vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { useUserStore } from "@/store/user.store";

export default {
  name: "ParticelleTable",
  components: {
    Table,
    Button,
    DeleteOutlined,
  },
  props: {
    particelle: {
      type: Array,
      required: true,
    },
  },
  emits: ["delete-particella"],
  setup(props, { emit }) {
    const userStore = useUserStore();
    const userPermission = userStore.user.ruolo;
    const columns = ref([
      {
        title: "Numero Particella",
        dataIndex: "numero",
      },
      {
        title: "Reddito Agrario",
        dataIndex: "reddito_agrario",
      },
      {
        title: "Reddito Domenicale",
        dataIndex: "reddito_domenicale",
      },
      {
        title: "Superficie",
        dataIndex: "superficie",
      },
      {
        title: "Azioni",
        dataIndex: "buttons",
      },
    ]);

    const dataSource = computed(() => {
      return props.particelle.map((particella) => {
        const { numero, reddito_agrario, reddito_domenicale, superficie } =
          particella;
        return { numero, reddito_agrario, reddito_domenicale, superficie };
      });
    });

    const handleDeleteClick = (record) => {
      console.log(record);
      emit("delete-particella", record);
    };

    return {
      columns,
      dataSource,
      handleDeleteClick,
      userPermission,
    };
  },
};
</script>

<style scoped>
.actions-column {
  display: inline-block;
}
</style>
