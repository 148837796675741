<template>
  <Form :form="form" @finish="handleFinish">
    <FormItem label="Nome / Rag. Sociale" name="nome">
      <Input v-model:value="form.nome" />
    </FormItem>
    <FormItem label="Cognome / Forma giuridica" name="cognome">
      <Input v-model:value="form.cognome" />
    </FormItem>
    <FormItem label="Codice Fiscale / P.IVA" name="codice_fiscale">
      <Input v-model:value="form.codice_fiscale" />
    </FormItem>
    <FormItem label="Email" name="email">
      <Input v-model:value="form.email" />
    </FormItem>
    <FormItem label="Telefono" name="telefono">
      <Input v-model:value="form.telefono" />
    </FormItem>
    <FormItem label="Tipo" name="tipo">
      <Select v-model:value="form.tipo">
        <SelectOption value="interno">Interno</SelectOption>
        <SelectOption value="esterno">Esterno</SelectOption>
      </Select>
    </FormItem>
    <FormItem>
      <Button type="primary" html-type="submit" @click="handleFinish"
        >Crea Proprietario</Button
      >
    </FormItem>
  </Form>
</template>
<script>
import { defineComponent, ref } from "vue";

import { Form, FormItem, Input, Button, Select } from "ant-design-vue";

export default defineComponent({
  name: "CreateProprietarioModal",
  components: {
    Form,
    FormItem,
    Input,
    Button,
    Select,
    SelectOption: Select.Option,
  },
  props: {
    owner_template: { required: true },
  },
  emits: ["update:visible", "create-proprietario"],
  setup(props, { emit }) {
    const form = ref(props.owner_template);

    const handleFinish = () => {
      console.log(form);

      emit("create-proprietario", form.value);

      form.value = {
        nome: "",
        cognome: "",
        codice_fiscale: "",
        email: "",
        telefono: "",
        tipo: "",
      };
    };

    return {
      form,

      handleFinish,
    };
  },
});
</script>
<style>
#map {
  width: 100%;
  height: 300px;
  border: 1px solid black;
}
</style>
