<template>
  <Form>
    <FormItem label="Nome Documento" name="nome_documento">
      <Input placeholder="Nome..." v-model:value="fileName" />
    </FormItem>
    <FormItem label="File" name="File">
      <Upload
        :file-list="fileList"
        :before-upload="beforeUpload"
        @remove="handleRemove"
        :multiple="false"
      >
        <Button>
          <UploadOutlined />
          Seleziona File
        </Button>
      </Upload>
    </FormItem>

    <Button @click="handleUpload">Carica</Button>
  </Form>
</template>

<script>
import { ref } from "vue";
import { Input, Form, Upload, Button } from "ant-design-vue";
import { UploadOutlined } from "@ant-design/icons-vue";
import { storage } from "@/firebase";
import {
  ref as firebaseRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
export default {
  components: {
    Input,
    Form,
    FormItem: Form.Item,
    Upload,
    UploadOutlined,
    Button,
  },
  emits: ["document-upload"],
  props: {},
  setup(props, { emit }) {
    const fileList = ref([]);
    const fileName = ref("");
    const handleRemove = (file) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };
    const beforeUpload = (file) => {
      fileList.value = [...fileList.value, file];
      return false;
    };

    const handleUpload = () => {
      const storageRef = firebaseRef(
        storage,
        `documenti/${fileList.value[0].name}`
      );
      uploadBytes(storageRef, fileList.value[0]).then(() => {
        getDownloadURL(
          firebaseRef(storage, `documenti/${fileList.value[0].name}`)
        ).then((download_url) => {
          const uploadPayload = {
            nome: fileName.value,
            nome_originale: fileList.value[0].name,
            url: download_url,
          };
          fileName.value = "";

          console.log(uploadPayload);
          fileList.value = [];
          emit("document-upload", uploadPayload);
        });
      });
    };

    return { handleRemove, fileList, beforeUpload, handleUpload, fileName };
  },
};
</script>
