import axios from "axios";

export class LandsApi {
  API_TOKEN;
  API_URL = process.env.VUE_APP_BACKEND_URL;
  constructor(token) {
    this.API_TOKEN = token;
  }
  async getAllLands() {
    try {
      let res = await axios.get(`${this.API_URL}/lands`, {
        headers: { Authorization: `Bearer ${this.API_TOKEN}` },
      });
      return res.data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  }

  async getLand(id) {
    try {
      let res = await axios.get(`${this.API_URL}/lands/${id}`, {
        headers: { Authorization: `Bearer ${this.API_TOKEN}` },
      });
      return res.data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  }

  async createLand(body) {
    try {
      let res = await axios.post(`${this.API_URL}/lands`, body, {
        headers: { Authorization: `Bearer ${this.API_TOKEN}` },
      });
      return res.data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  }

  async updateLand(id, body) {
    try {
      let res = await axios.put(`${this.API_URL}/lands/${id}`, body, {
        headers: { Authorization: `Bearer ${this.API_TOKEN}` },
      });
      return res.data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  }

  async deleteLand(id) {
    try {
      let res = await axios.delete(`${this.API_URL}/lands/${id}`, {
        headers: { Authorization: `Bearer ${this.API_TOKEN}` },
      });
      return res.data;
    } catch (error) {
      return { success: false, error: error.message };
    }
  }
}
