<template>
  <Table :columns="columns" :dataSource="visiteMap">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'action'">
        <div class="actions-column">
          <Button type="primary" @click="handleVisitNote(record)">
            <CommentOutlined /> Note
          </Button>

          <Button
            v-if="userPermission != 'view'"
            type="danger"
            @click="handleVisitRemove(record)"
          >
            <DeleteOutlined /> Elimina
          </Button>
        </div>
      </template>

      <template v-if="column.dataIndex === 'nome'">
        {{ record.nome }}
      </template>

      <template v-if="column.dataIndex === 'cognome'">
        {{ record.cognome }}
      </template>

      <template v-if="column.dataIndex === 'visita'">
        {{ formatDate(record.visita) }}
      </template>
    </template>
  </Table>
</template>

<script>
import { Table, Button } from "ant-design-vue";
import { CommentOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import { useUserStore } from "@/store/user.store";

export default {
  components: {
    Table,
    Button,
    DeleteOutlined,
    CommentOutlined,
  },
  emits: ["visit-remove", "visit-notes"],
  props: {
    visite: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const userStore = useUserStore();
    const userPermission = userStore.user.ruolo;
    const visiteMap = props.visite.map((visita) => {
      return {
        _id: visita.proprietario._id,
        nome: visita.proprietario.nome,
        cognome: visita.proprietario.cognome,
        visita: visita.visita,
        visita_id: visita._id,
      };
    });
    const columns = [
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
      },
      {
        title: "Cognome",
        dataIndex: "cognome",
        key: "cognome",
      },
      {
        title: "Visita",
        dataIndex: "visita",
        key: "visita",
      },
      {
        title: "Azione",
        dataIndex: "action",
        key: "action",
      },
    ];

    const formatDate = (dateString) => {
      var options = {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      var date = new Date(dateString).toLocaleString("it-IT", options);
      return date;
    };

    const handleVisitRemove = (data) => {
      emit("visit-remove", data);
    };

    const handleVisitNote = (data) => {
      emit("visit-notes", data);
    };

    return {
      columns,
      formatDate,
      handleVisitRemove,
      visiteMap,
      userPermission,
      handleVisitNote,
    };
  },
};
</script>

<style scoped>
.actions-column {
  display: flex;
  gap: 10px;
}
</style>
