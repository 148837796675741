<template>
  <Alert
    v-if="alert.type"
    :message="alert.header"
    :description="alert.message"
    :type="alert.type"
    showIcon
  />
  <Modal
    title="Crea nuovo Proprietario"
    :footer="null"
    v-model:visible="showCreateOwnerModal"
  >
    <CreateProprietarioModal
      :owner_template="newOwnerTemplate"
      @create-proprietario="handleCreateOwner"
    />
  </Modal>

  <div>
    <Card :bordered="false" class="widget-1">
      <Statistic title="Lista" value="Proprietari" class="text-success" />
      <div class="icon">
        <UserOutlined />
      </div>
    </Card>

    <Button
      style="margin-top: 20px"
      v-if="userPermission != 'view'"
      @click="handleCreateOwnerClick"
      type="primary"
    >
      <PlusCircleOutlined /> Nuovo Proprietario
    </Button>

    <Skeleton :loading="isLoading" active>
      <Empty v-if="!owners.length" :image="emptyImage">
        <template #description> Nessun dato </template>
      </Empty>
      <ProprietariTable
        v-else-if="!isLoading && !alert.type && owners.length"
        style="margin-top: 10px"
        @delete-owner="handleDeleteOwnerClick"
        @view-owner="handleViewOwnerClick"
        :owners="owners"
      />
    </Skeleton>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Modal,
  Alert,
  message,
  Card,
  Statistic,
  Skeleton,
  Empty,
  Button,
} from "ant-design-vue";
import CreateProprietarioModal from "@/components/proprietari/CreateProprietarioModal";
import ProprietariTable from "@/components/proprietari/ProprietariTable";
import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons-vue";
import { OwnersApi } from "@/common/api/owners.api";
import router from "@/router";
import { useUserStore } from "@/store/user.store";

export default {
  name: "ProprietariView",
  components: {
    Modal,
    Button,
    Skeleton,
    Empty,
    Alert,
    Card,
    Statistic,
    UserOutlined,
    PlusCircleOutlined,
    CreateProprietarioModal,
    ProprietariTable,
  },
  setup() {
    const isLoading = ref(true);
    const owners = ref([]);
    const alert = ref({ type: "", message: "", header: "" });
    const newOwnerTemplate = {
      nome: "",
      cognome: "",
      codice_fiscale: "",
      indirizzo: "",
      email: "",
      telefono: "",
      tipo: "",
    };
    const emptyImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const userStore = useUserStore();
    const userPermission = userStore.user.ruolo;

    const ownersApi = new OwnersApi(userStore.user.access_token);
    ownersApi
      .getAllOwners()
      .then((response) => {
        if (response.success) {
          if (response.owners.length == 0)
            alert.value = {
              type: "warning",
              message: "Nessun proprietario trovato",
              header: "Avviso",
            };
          else owners.value = response.owners;
        } else {
          alert.value = {
            type: "error",
            message: "Impossibile reperire proprietari",
            header: "Errore",
          };
        }
      })
      .finally(() => {
        isLoading.value = false;
      });

    const showCreateOwnerModal = ref(false);

    function handleCreateOwnerClick() {
      showCreateOwnerModal.value = true;
    }

    function handleViewOwnerClick(id) {
      router.push(`/proprietari/${id}`);
    }

    function handleDeleteOwnerClick(id) {
      console.log(`deleting ${id}`);
      isLoading.value = true;
      ownersApi
        .deleteOwner(id)
        .then((response) => {
          if (!response.success) {
            message.error("Impossibile creare proprietario, riprova");
          } else {
            message.success("Proprietario creato");
            const filterOwner = owners.value.filter((owner) => owner._id == id);
            const filterIdx = owners.value.indexOf(filterOwner);
            owners.value.splice(filterIdx, 1);
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function handleCreateOwner(data) {
      isLoading.value = true;
      showCreateOwnerModal.value = false;
      ownersApi
        .createOwner(data)
        .then((response) => {
          if (response.success) {
            message.success("Proprietario creato");
            owners.value.push(response.owner);
          } else {
            message.error("Impossibile creare proprietario, riprova");

            showCreateOwnerModal.value = true;
            newOwnerTemplate.value = data;
            owners.value.push(data);
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    return {
      owners,
      showCreateOwnerModal,
      handleCreateOwnerClick,
      handleCreateOwner,
      isLoading,
      alert,
      newOwnerTemplate,
      handleViewOwnerClick,
      handleDeleteOwnerClick,
      userPermission,
      emptyImage,
    };
  },
};
</script>
