import { createRouter, createWebHistory } from "vue-router";
import TerreniView from "../views/TerreniView.vue";
import ProprietariView from "../views/ProprietariView.vue";
import UtentiView from "../views/UtentiView.vue";
import TerrenoDetailView from "../views/TerrenoDetailView.vue";
import ProprietariDetailView from "../views/ProprietarioDetailView.vue";
import LoginView from "../views/LoginView.vue";
import UtenteDetailView from "../views/UtenteDetailView.vue";
import UnauthorizedView from "../views/UnauthorizedView.vue";
import HomeView from "../views/HomeView.vue";

import { useUserStore } from "@/store/user.store";
const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/terreni",
    name: "terreni",
    component: TerreniView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/terreni/:id",
    name: "terreni-detail",
    component: TerrenoDetailView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/proprietari",
    name: "proprietari",
    component: ProprietariView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/proprietari/:id",
    name: "proprietari-detail",
    component: ProprietariDetailView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/utenti",
    name: "utenti",
    component: UtentiView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/utenti/:id",
    name: "utenti-detail",
    component: UtenteDetailView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },

  {
    path: "/unauthorized",
    name: "unauthorized",
    component: UnauthorizedView,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  routes,
});

router.beforeEach(async (to) => {
  const userStore = useUserStore();
  if (to.meta.requiresAuth && !isUserLogged(userStore.user))
    return { name: "login" };
  if (to.name == "login" && isUserLogged(userStore.user))
    return { name: "terreni" };
  if (to.meta.requiresAdmin && userStore.user.ruolo !== "admin")
    return { name: "unauthorized" };
});

function isUserLogged(user) {
  const { nome, cognome, email, access_token, ruolo } = user;
  return (
    nome &&
    nome.length &&
    cognome &&
    cognome.length &&
    email &&
    email.length &&
    access_token &&
    access_token.length &&
    ruolo &&
    ruolo.length
  );
}

export default router;
