<template>
  <Spin v-if="isLoading" tip="Caricamento..." size="large"> </Spin>
  <Form :form="form" @finish="handleFinish" v-if="!isLoading">
    <FormItem label="Nominativo" name="nome">
      <AutoComplete
        @select="onSelect"
        :options="
          proprietari.map((proprietario) => {
            return {
              value: `${proprietario.nome} ${proprietario.cognome}`,
              id: proprietario._id,
            };
          })
        "
        v-model:value="form.nome"
      />
    </FormItem>
    <FormItem label="Percentuale" name="percentuale">
      <Input v-model:value="form.percentuale" addon-before="%" />
    </FormItem>
    <FormItem>
      <Button type="primary" html-type="submit" @click="handleFinish">
        <PlusCircleOutlined /> Aggiungi Proprietario
      </Button>
    </FormItem>
  </Form>
</template>
<script>
import { defineComponent, ref } from "vue";
import { OwnersApi } from "@/common/api/owners.api";
import { useUserStore } from "@/store/user.store";
import {
  Form,
  FormItem,
  Input,
  Button,
  Spin,
  AutoComplete,
} from "ant-design-vue";
import { PlusCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "AddProprietarioModal",
  components: {
    Form,
    FormItem,
    Input,
    Button,
    Spin,
    AutoComplete,
    PlusCircleOutlined,
  },

  emits: ["update:visible", "add-proprietario"],
  setup(props, { emit }) {
    const form = ref({
      nome: "",
      percentuale: 0,
    });

    const isLoading = ref(true);
    const proprietari = ref([]);
    const proprietario = ref("");
    const userStore = useUserStore();
    const ownersApi = new OwnersApi(userStore.user.access_token);
    const onSelect = (data) => {
      const selected = proprietari.value.filter(
        (proprietario) =>
          `${proprietario.nome} ${proprietario.cognome}`.toLowerCase().trim() ==
          data.toLowerCase().trim()
      )[0];
      proprietario.value = selected;
    };

    ownersApi.getAllOwners().then((response) => {
      if (response.success) {
        isLoading.value = false;
        proprietari.value = response.owners;
      }
    });

    const handleFinish = () => {
      emit("add-proprietario", {
        proprietario: proprietario.value,
        percentuale: form.value.percentuale,
      });

      form.value = {
        nome: "",
        percentuale: 0,
      };
    };

    return {
      form,
      handleFinish,
      isLoading,
      proprietari,
      proprietario,
      onSelect,
    };
  },
});
</script>
<style>
#map {
  width: 100%;
  height: 300px;
  border: 1px solid black;
}
</style>
