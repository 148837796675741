import { defineStore } from "pinia";
import { ref, watch, computed } from "vue";

export const useUserStore = defineStore("UserStore", () => {
  const user = ref({
    nome: "",
    cognome: "",
    email: "",
    access_token: "",
    ruolo: "",
  });

  if (localStorage.getItem("user")) {
    user.value = JSON.parse(localStorage.getItem("user"));
  }

  const getNome = computed(() => user.value.nome);
  const getEmail = computed(() => user.value.email);
  const getAccessToken = computed(() => user.value.access_token);
  const getRuolo = computed(() => user.value.ruolo);

  const getNomeCompleto = computed(() => `${user.value.nome} ${user.value.cognome}`);

  const setUser = (newUser) => {
    user.value = newUser;
  };

  const logout = () => {
    user.value = {
      nome: "",
      cognome: "",
      email: "",
      access_token: "",
      ruolo: ""
    };
  };

  watch(
    user,
    (userVal) => {
      localStorage.setItem("user", JSON.stringify(userVal));
    },
    { deep: true }
  );

  return {
    user,
    getNome,
    getEmail,
    getRuolo,
    getAccessToken,
    getNomeCompleto,
    setUser,
    logout,
  };
});
