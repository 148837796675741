<template>
  <div>
    <Alert
      v-if="action.show"
      :message="action.header"
      :description="action.message"
      :type="action.type"
      showIcon
    />
    <Modal
      title="Crea nuovo utente"
      :footer="null"
      v-model:visible="showCreateUserModal"
    >
      <CreateUtenteModal @create-user="handleCreateUser" />
    </Modal>

    <Card :bordered="false" class="widget-1">
      <Statistic title="Lista" value="Utenti" class="text-success" />
      <div class="icon">
        <TeamOutlined />
      </div>
    </Card>

    <Button
      style="margin-top: 20px"
      @click="handleCreateLandClick"
      type="primary"
      ><PlusCircleOutlined /> Nuovo Utente</Button
    >
    <Skeleton :loading="isLoading" active>
      <Empty v-if="!users.length" :image="emptyImage">
        <template #description> Nessun dato </template>
      </Empty>
      <UtentiTable
        v-else-if="!isLoading && users.length && !action.show"
        style="margin-top: 10px"
        @view-user="handleUserView"
        @delete-user="handleUserDelete"
        :users="users"
      />
    </Skeleton>
  </div>
</template>

<script>
import { ref } from "vue";
import UtentiTable from "@/components/utenti/UtentiTable.vue";
import CreateUtenteModal from "@/components/utenti/CreateUtenteModal.vue";
import { PlusCircleOutlined, TeamOutlined } from "@ant-design/icons-vue";
import {
  Modal,
  Button,
  Alert,
  message,
  Card,
  Statistic,
  Skeleton,
  Empty,
} from "ant-design-vue";
import { useRouter } from "vue-router";
import { UsersApi } from "@/common/api/users.api";
import { useUserStore } from "@/store/user.store";
export default {
  name: "UtentiView",
  components: {
    UtentiTable,
    Modal,
    CreateUtenteModal,
    Button,
    PlusCircleOutlined,
    Skeleton,
    Empty,
    TeamOutlined,
    Card,
    Statistic,
    Alert,
  },
  setup() {
    const showCreateUserModal = ref(false);
    const users = ref([]);
    const action = ref({ show: false, message: "", type: "", header: "" });
    const router = useRouter();
    const userStore = useUserStore();
    const usersApi = new UsersApi(userStore.user.access_token);
    const emptyImage = Empty.PRESENTED_IMAGE_SIMPLE;
    const isLoading = ref(true);
    usersApi
      .getAllUsers()
      .then((response) => {
        if (response.success) {
          users.value = response.users;
        } else {
          action.value = {
            header: "Errore",
            show: true,
            message: "Impossibile reperire utenti",
            type: "error",
          };
        }
      })
      .finally(() => {
        isLoading.value = false;
      });

    function handleCreateLandClick() {
      showCreateUserModal.value = true;
    }

    function handleCreateUser(user) {
      usersApi.createUser(user).then((response) => {
        if (response.success) {
          message.success("Utente creato");
          users.value.push(response.user);
          showCreateUserModal.value = false;
        }
      });
    }

    function handleUserView(id) {
      router.push(`/utenti/${id}`);
    }

    function handleUserDelete(id) {
      console.log(id);
      usersApi.deleteUser(id).then((response) => {
        if (response.success) {
          message.success("Utente eliminato correttamente");
          users.value = users.value.filter((user) => user._id !== id);
        } else {
          message.success("Impossibile eliminare utente, riprova");
        }
      });
    }

    return {
      users,
      showCreateUserModal,
      handleCreateLandClick,
      handleCreateUser,
      action,
      handleUserView,
      handleUserDelete,
      emptyImage,
      isLoading,
    };
  },
};
</script>
