<template>
  <div>
    <Table :columns="columns" :dataSource="dataSource">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'percentuale'">
          <p>{{ record.percentuale }}%</p></template
        >
        <template v-if="column.dataIndex === 'buttons'">
          <div class="actions-column">
            <Button
              v-if="userPermission != 'view'"
              @click="handleDeleteClick(record)"
              type="danger"
            >
              <DeleteOutlined />
              Elimina
            </Button>
          </div>
        </template>
      </template>
    </Table>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { Table, Button } from "ant-design-vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { useUserStore } from "@/store/user.store";

export default {
  name: "LandUsageTable",
  components: {
    Table,
    Button,
    DeleteOutlined,
  },
  props: {
    utilizzi: {
      type: Array,
      required: true,
    },
  },
  emits: ["delete-utilizzo"],
  setup(props, { emit }) {
    const userStore = useUserStore();
    const userPermission = userStore.user.ruolo;
    const columns = ref([
      {
        title: "Destinazione d'uso",
        dataIndex: "nome",
      },
      {
        title: "Percentuale",
        dataIndex: "percentuale",
      },
      {
        title: "Actions",
        dataIndex: "buttons",
      },
    ]);

    const dataSource = computed(() => {
      return props.utilizzi.map((utilizzo) => {
        const { nome, percentuale } = utilizzo;
        return { nome, percentuale };
      });
    });

    const handleDeleteClick = (record) => {
      console.log(record);
      emit("delete-utilizzo", record);
    };

    return {
      columns,
      dataSource,
      handleDeleteClick,
      userPermission,
    };
  },
};
</script>

<style scoped>
.actions-column {
  display: inline-block;
}
</style>
