<template>
  <div>
    <Table :columns="columns" :dataSource="gestioneFiltered" theme="dark">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <div class="actions-column">
            <Button
              v-if="userPermission != 'view'"
              type="danger"
              @click="handleGestioneRemove(record)"
            >
              <DeleteOutlined /> Elimina
            </Button>
          </div>
        </template>
      </template>
    </Table>
  </div>
</template>

<script>
import { ref } from "vue";
import { Table, Button } from "ant-design-vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { useUserStore } from "@/store/user.store";

export default {
  components: { Table, Button, DeleteOutlined },
  emits: ["gestione-remove"],
  props: {
    gestione: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const userStore = useUserStore();
    const userPermission = userStore.user.ruolo;
    const gestioneFiltered = props.gestione.map((gest) => {
      return {
        _id: gest._id,
        nome: gest.utente.nome,
        cognome: gest.utente.cognome,
        ruolo: gest.ruolo,
      };
    });
    const columns = ref([
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
      },
      {
        title: "Cognome",
        dataIndex: "cognome",
        key: "cognome",
      },
      {
        title: "Ruolo",
        dataIndex: "ruolo",
        key: "ruolo",
      },
      {
        title: "Azione",
        dataIndex: "action",
        key: "action",
      },
    ]);

    const handleGestioneRemove = (gestione) => {
      emit("gestione-remove", gestione._id);
    };

    return {
      columns,
      props,
      handleGestioneRemove,
      gestioneFiltered,
      userPermission,
    };
  },
};
</script>
