import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Button, Layout, Table, Menu, Modal } from "ant-design-vue";
import {
  UserOutlined,
  TeamOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
import "ant-design-vue/dist/antd.css";
import "./scss/app.scss";

const icons = {
  UserOutlined,
  TeamOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
};
const pinia = createPinia();
pinia.use(piniaPersist);

const app = createApp(App);

app.use(icons);
app.use(Button).use(Table).use(Layout).use(Menu).use(Modal);
app.use(pinia).use(router).mount("#app");
