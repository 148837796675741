<template>
  <Form :form="form" @finish="handleFinish">
    <FormItem label="Nome" name="nome">
      <Input v-model:value="form.nome" />
    </FormItem>
    <FormItem label="Cognome" name="cognome">
      <Input v-model:value="form.cognome" />
    </FormItem>
    <FormItem label="Email" name="email">
      <Input v-model:value="form.email" />
    </FormItem>
    <FormItem label="Password" name="password">
      <Input v-model:value="form.password" />
    </FormItem>

    <FormItem label="Ruolo" name="ruolo">
      <Select v-model:value="form.ruolo">
        <SelectOption value="edit">Edit</SelectOption>
        <SelectOption value="view">View</SelectOption>
        <SelectOption value="admin">Admin</SelectOption>
      </Select>
    </FormItem>

    <FormItem>
      <Button type="primary" @click="handleFinish">Crea Utente</Button>
      <Button @click="generatePassword" type="ghost">Genera Password</Button>
    </FormItem>
  </Form>
</template>

<script>
import { defineComponent, ref } from "vue";
import { Form, FormItem, Input, Button, Select } from "ant-design-vue";

export default defineComponent({
  name: "CreateUserForm",
  components: {
    Form,
    FormItem,
    Input,
    Button,
    Select,
    SelectOption: Select.Option,
  },

  emits: ["update:visible", "create-user"],
  setup(props, { emit }) {
    const form = ref({
      nome: "",
      cognome: "",
      email: "",
      password: "",
      ruolo: "",
    });

    const generatePassword = () => {
      const password = Math.floor(10000 + Math.random() * 90000).toString();
      form.value.password = password;
    };

    const handleFinish = () => {
      emit("create-user", form.value);
    };

    return {
      form,
      generatePassword,
      handleFinish,
    };
  },
});
</script>
