<template>
  <Form :form="form">
    <FormItem label="Progetto" name="progetto">
      <Input v-model:value="form.progetto" />
    </FormItem>

    <FormItem label="Foglio *" name="foglio">
      <Input type="number" v-model:value="form.foglio" />
    </FormItem>

    <FormItem label="Proprietario Principale *" name="proprietario">
      <Select
        :options="proprietariOptions"
        @change="handleProprietarioChange"
        show-search
      />
    </FormItem>

    <FormItem label="Codice *" name="codice">
      <Input v-model:value="form.codice" />
    </FormItem>

    <FormItem label="Provincia *" name="provincia">
      <AutoComplete
        v-model:value="form.provincia"
        :options="provinceOptions"
        placeholder=""
      />
    </FormItem>

    <FormItem label="Comune *" name="comune">
      <AutoComplete
        v-model:value="form.comune"
        :options="comuniOptions"
        placeholder=""
      />
    </FormItem>

    <FormItem label="Indirizzo" name="indirizzo">
      <Input v-model:value="form.indirizzo" />
    </FormItem>

    <FormItem label="Superficie *" name="superficie">
      <InputNumber v-model:value="form.superficie" />
    </FormItem>

    <FormItem label="Coordinate *" name="coordinate">
      <InputGroup compact>
        <InputNumber v-model:value="form.coordinate.lat" style="width: 50%" />
        <InputNumber v-model:value="form.coordinate.lon" style="width: 50%" />
      </InputGroup>
    </FormItem>
    <FormItem label="Link Google Earth" name="g_earth_url">
      <Input v-model:value="googleEarthUrl" />
    </FormItem>

    <FormItem>
      <Button @click="handleFinish" type="primary">Crea Terreno</Button>
      <Button
        style="margin-left: 10px"
        @click="generaCodiceAutomatico"
        type="link"
        >Genera Codice</Button
      >
    </FormItem>
  </Form>
</template>
<script>
import { defineComponent, ref, computed } from "vue";
import listaProvince from "@/common/list/province";
import listaComuni from "@/common/list/comuni";
import listaCodici from "@/common/list/codici";

import {
  Form,
  FormItem,
  Input,
  InputNumber,
  Button,
  AutoComplete,
  message,
  Select,
} from "ant-design-vue";
import { useUserStore } from "@/store/user.store";
import { OwnersApi } from "@/common/api/owners.api";

export default defineComponent({
  name: "CreateTerrenoModal",
  components: {
    Form,
    FormItem,
    Input,
    InputNumber,
    Button,
    InputGroup: Input.Group,
    AutoComplete,
    Select,
  },
  props: { lands: { reqired: true } },
  emits: ["update:visible", "create-land"],
  setup(props, { emit }) {
    const form = ref({
      codice: "",
      proprietari: [],
      comune: "",
      provincia: "",
      superficie: null,
      progetto: "",
      coordinate: { lat: null, lon: null },
      particelle: [],
      foglio: "",
      g_earth_url: "",
      indirizzo: "",
    });

    const proprietari = ref();
    const proprietariOptions = ref();
    const userStore = useUserStore();
    const ownersApi = new OwnersApi(userStore.user.access_token);
    ownersApi
      .getAllOwners()
      .then((response) => {
        if (response.success) {
          proprietari.value = response.owners;
          proprietariOptions.value = response.owners.map((proprietario) => {
            return {
              value: proprietario._id,
              label: `${proprietario.nome} ${proprietario.cognome}`,
            };
          });
        } else throw new Error("Impossibile recuperare proprietari, riprova");
      })
      .catch((error) => {
        message.error(error.message);
      });

    function generaCodiceAutomatico() {
      if (!form.value.comune) return message.error("Comune non presente");
      if (!form.value.proprietari[0])
        return message.error("Proprietario principale non presente");

      let codiceBelfiore =
        listaCodici.find(
          (codice) =>
            codice.comune.toLowerCase().trim() ==
            form.value.comune.toLowerCase().trim()
        ).codice_belfiore || null;

      if (!codiceBelfiore) return message.error("Codice belfiore non trovato");

      let filteredCodes = props.lands.filter((land) =>
        land.codice.startsWith(codiceBelfiore)
      );
      if (!filteredCodes.length)
        return (form.value.codice = `${codiceBelfiore}-0001-${form.value.proprietari[0].proprietario.nome[0]}`);

      let filteredNumbers = filteredCodes.map((land) =>
        parseInt(land.codice.split("-")[1])
      );
      let maxNumber = Math.max(...filteredNumbers) + 1;
      if (!isFinite(maxNumber))
        return (form.value.codice = `${codiceBelfiore}-Non Definito-`);
      form.value.codice = `${codiceBelfiore}-${maxNumber
        .toString()
        .padStart(4, "0")}-${form.value.proprietari[0].proprietario.nome[0]}`;
    }

    const provinceOptions = computed(() =>
      listaProvince.map((provincia, i) => {
        return {
          value: provincia,
          id: i,
        };
      })
    );

    const comuniOptions = computed(() =>
      listaComuni[form.value.provincia || "BA"].map((comune, i) => {
        return {
          value: comune,
          id: i,
        };
      })
    );

    function handleProprietarioChange(proprietario) {
      const filtered = proprietari.value.find(
        (owner) => owner._id == proprietario
      );
      if (!filtered) message.error("Proprietario non trovato, riprova");
      form.value.proprietari[0] = {
        proprietario: filtered,
        percentuale: 100,
      };
    }

    const googleEarthUrl = computed(() => {
      if (form.value.coordinate.lat && form.value.coordinate.lon) {
        return `https://earth.google.com/web/@${form.value.coordinate.lat},${form.value.coordinate.lon}`;
      }
      return "";
    });

    const handleFinish = () => {
      if (
        !form.value.proprietari[0] ||
        !form.value.codice ||
        !form.value.comune ||
        !form.value.provincia ||
        !form.value.foglio ||
        !form.value.superficie ||
        !form.value.coordinate.lat ||
        !form.value.coordinate.lon
      )
        return message.error("Compilare tutti i campi obbligatori");
      emit("create-land", { ...form.value, g_earth_url: googleEarthUrl.value });
      emit("update:visible", false);
      form.value = {
        codice: "",
        proprietari: [],
        comune: "",
        provincia: "",
        superficie: null,
        progetto: "",
        coordinate: { lat: null, lon: null },
        particelle: [],
        foglio: "",
        g_earth_url: "",
        indirizzo: "",
      };
    };

    return {
      form,
      handleFinish,
      googleEarthUrl,
      provinceOptions,
      comuniOptions,
      generaCodiceAutomatico,
      proprietariOptions,
      handleProprietarioChange,
    };
  },
});
</script>
