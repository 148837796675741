<template>
  <div class="wrapper">
    <Card style="width: 30%" :bordered="false" class="widget-1">
      <RouterLink to="/terreni">
        <Skeleton :loading="landsLength == null">
          <Statistic
            title="Terreni"
            :value="landsLength"
            class="text-success"
          />
        </Skeleton>

        <div class="icon">
          <BlockOutlined />
        </div>
      </RouterLink>
    </Card>

    <Card style="width: 30%" :bordered="false" class="widget-1">
      <RouterLink to="/proprietari">
        <Skeleton :loading="ownersLength == null">
          <Statistic
            title="Proprietari"
            :value="ownersLength"
            class="text-success"
          />
        </Skeleton>

        <div class="icon">
          <UserOutlined />
        </div>
      </RouterLink>
    </Card>

    <Card style="width: 30%" :bordered="false" class="widget-1">
      <RouterLink to="/utenti">
        <Skeleton :loading="usersLength == null">
          <Statistic title="Utenti" :value="usersLength" class="text-success" />
        </Skeleton>

        <div class="icon">
          <TeamOutlined />
        </div>
      </RouterLink>
    </Card>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { LandsApi } from "@/common/api/lands.api";
import { UsersApi } from "@/common/api/users.api";
import { OwnersApi } from "@/common/api/owners.api";
import { Card, Statistic, Skeleton } from "ant-design-vue";
import {
  BlockOutlined,
  UserOutlined,
  TeamOutlined,
} from "@ant-design/icons-vue";
import { useUserStore } from "@/store/user.store";
const isLoading = ref(true);
const usersLength = ref(null);
const ownersLength = ref(null);
const landsLength = ref(null);
const userStore = useUserStore();
const landsApi = new LandsApi(userStore.user.access_token);
const usersApi = new UsersApi(userStore.user.access_token);
const ownersApi = new OwnersApi(userStore.user.access_token);

getData();
function getData() {
  landsApi.getAllLands().then((response) => {
    if (response.success) landsLength.value = response.lands.length;
    else landsLength.value = "-";
  });

  usersApi.getAllUsers().then((response) => {
    if (response.success) usersLength.value = response.users.length;
    else usersLength.value = "-";
  });

  ownersApi.getAllOwners().then((response) => {
    if (response.success) ownersLength.value = response.owners.length;
    else ownersLength.value = "-";
  });
  isLoading.value = false;
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  gap: 10%;
}
</style>
