export default [
  {
    id: 1,
    comune: "ABANO TERME",
    sigla: "PD",
    codice_elettorale: 1050540010,
    codice_istat: 28001,
    codice_belfiore: "A001",
  },
  {
    id: 2,
    comune: "ABBADIA CERRETO",
    sigla: "LO",
    codice_elettorale: 1030990010,
    codice_istat: 98001,
    codice_belfiore: "A004",
  },
  {
    id: 3,
    comune: "ABBADIA LARIANA",
    sigla: "LC",
    codice_elettorale: 1030980010,
    codice_istat: 97001,
    codice_belfiore: "A005",
  },
  {
    id: 4,
    comune: "ABBADIA SAN SALVATORE",
    sigla: "SI",
    codice_elettorale: 2090750010,
    codice_istat: 52001,
    codice_belfiore: "A006",
  },
  {
    id: 5,
    comune: "ABBASANTA",
    sigla: "OR",
    codice_elettorale: 4200950010,
    codice_istat: 95001,
    codice_belfiore: "A007",
  },
  {
    id: 6,
    comune: "ABBATEGGIO",
    sigla: "PE",
    codice_elettorale: 3130600010,
    codice_istat: 68001,
    codice_belfiore: "A008",
  },
  {
    id: 7,
    comune: "ABBIATEGRASSO",
    sigla: "MI",
    codice_elettorale: 1030490020,
    codice_istat: 15002,
    codice_belfiore: "A010",
  },
  {
    id: 8,
    comune: "ABETONE CUTIGLIANO",
    sigla: "PT",
    codice_elettorale: 2090630012,
    codice_istat: 47023,
    codice_belfiore: "M376",
  },
  {
    id: 9,
    comune: "ABRIOLA",
    sigla: "PZ",
    codice_elettorale: 3170640010,
    codice_istat: 76001,
    codice_belfiore: "A013",
  },
  {
    id: 10,
    comune: "ACATE",
    sigla: "RG",
    codice_elettorale: 4190650010,
    codice_istat: 88001,
    codice_belfiore: "A014",
  },
  {
    id: 11,
    comune: "ACCADIA",
    sigla: "FG",
    codice_elettorale: 3160310010,
    codice_istat: 71001,
    codice_belfiore: "A015",
  },
  {
    id: 12,
    comune: "ACCEGLIO",
    sigla: "CN",
    codice_elettorale: 1010270010,
    codice_istat: 4001,
    codice_belfiore: "A016",
  },
  {
    id: 13,
    comune: "ACCETTURA",
    sigla: "MT",
    codice_elettorale: 3170470010,
    codice_istat: 77001,
    codice_belfiore: "A017",
  },
  {
    id: 14,
    comune: "ACCIANO",
    sigla: "AQ",
    codice_elettorale: 3130380010,
    codice_istat: 66001,
    codice_belfiore: "A018",
  },
  {
    id: 15,
    comune: "ACCUMOLI",
    sigla: "RI",
    codice_elettorale: 2120690010,
    codice_istat: 57001,
    codice_belfiore: "A019",
  },
  {
    id: 16,
    comune: "ACERENZA",
    sigla: "PZ",
    codice_elettorale: 3170640020,
    codice_istat: 76002,
    codice_belfiore: "A020",
  },
  {
    id: 17,
    comune: "ACERNO",
    sigla: "SA",
    codice_elettorale: 3150720010,
    codice_istat: 65001,
    codice_belfiore: "A023",
  },
  {
    id: 18,
    comune: "ACERRA",
    sigla: "NA",
    codice_elettorale: 3150510010,
    codice_istat: 63001,
    codice_belfiore: "A024",
  },
  {
    id: 19,
    comune: "ACI BONACCORSI",
    sigla: "CT",
    codice_elettorale: 4190210010,
    codice_istat: 87001,
    codice_belfiore: "A025",
  },
  {
    id: 20,
    comune: "ACI CASTELLO",
    sigla: "CT",
    codice_elettorale: 4190210020,
    codice_istat: 87002,
    codice_belfiore: "A026",
  },
  {
    id: 21,
    comune: "ACI CATENA",
    sigla: "CT",
    codice_elettorale: 4190210030,
    codice_istat: 87003,
    codice_belfiore: "A027",
  },
  {
    id: 22,
    comune: "ACI SANT'ANTONIO",
    sigla: "CT",
    codice_elettorale: 4190210050,
    codice_istat: 87005,
    codice_belfiore: "A029",
  },
  {
    id: 23,
    comune: "ACIREALE",
    sigla: "CT",
    codice_elettorale: 4190210040,
    codice_istat: 87004,
    codice_belfiore: "A028",
  },
  {
    id: 24,
    comune: "ACQUAFONDATA",
    sigla: "FR",
    codice_elettorale: 2120330010,
    codice_istat: 60001,
    codice_belfiore: "A032",
  },
  {
    id: 25,
    comune: "ACQUAFORMOSA",
    sigla: "CS",
    codice_elettorale: 3180250010,
    codice_istat: 78001,
    codice_belfiore: "A033",
  },
  {
    id: 26,
    comune: "ACQUAFREDDA",
    sigla: "BS",
    codice_elettorale: 1030150010,
    codice_istat: 17001,
    codice_belfiore: "A034",
  },
  {
    id: 27,
    comune: "ACQUALAGNA",
    sigla: "PU",
    codice_elettorale: 2110590010,
    codice_istat: 41001,
    codice_belfiore: "A035",
  },
  {
    id: 28,
    comune: "ACQUANEGRA CREMONESE",
    sigla: "CR",
    codice_elettorale: 1030260010,
    codice_istat: 19001,
    codice_belfiore: "A039",
  },
  {
    id: 29,
    comune: "ACQUANEGRA SUL CHIESE",
    sigla: "MN",
    codice_elettorale: 1030450010,
    codice_istat: 20001,
    codice_belfiore: "A038",
  },
  {
    id: 30,
    comune: "ACQUAPENDENTE",
    sigla: "VT",
    codice_elettorale: 2120910010,
    codice_istat: 56001,
    codice_belfiore: "A040",
  },
  {
    id: 31,
    comune: "ACQUAPPESA",
    sigla: "CS",
    codice_elettorale: 3180250020,
    codice_istat: 78002,
    codice_belfiore: "A041",
  },
  {
    id: 32,
    comune: "ACQUARO",
    sigla: "VV",
    codice_elettorale: 3181030010,
    codice_istat: 102001,
    codice_belfiore: "A043",
  },
  {
    id: 33,
    comune: "ACQUASANTA TERME",
    sigla: "AP",
    codice_elettorale: 2110060010,
    codice_istat: 44001,
    codice_belfiore: "A044",
  },
  {
    id: 34,
    comune: "ACQUASPARTA",
    sigla: "TR",
    codice_elettorale: 2100800010,
    codice_istat: 55001,
    codice_belfiore: "A045",
  },
  {
    id: 35,
    comune: "ACQUAVIVA COLLECROCE",
    sigla: "CB",
    codice_elettorale: 3140190010,
    codice_istat: 70001,
    codice_belfiore: "A050",
  },
  {
    id: 36,
    comune: "ACQUAVIVA D'ISERNIA",
    sigla: "IS",
    codice_elettorale: 3140940010,
    codice_istat: 94001,
    codice_belfiore: "A051",
  },
  {
    id: 37,
    comune: "ACQUAVIVA DELLE FONTI",
    sigla: "BA",
    codice_elettorale: 3160090010,
    codice_istat: 72001,
    codice_belfiore: "A048",
  },
  {
    id: 38,
    comune: "ACQUAVIVA PICENA",
    sigla: "AP",
    codice_elettorale: 2110060020,
    codice_istat: 44002,
    codice_belfiore: "A047",
  },
  {
    id: 39,
    comune: "ACQUAVIVA PLATANI",
    sigla: "CL",
    codice_elettorale: 4190180010,
    codice_istat: 85001,
    codice_belfiore: "A049",
  },
  {
    id: 40,
    comune: "ACQUEDOLCI",
    sigla: "ME",
    codice_elettorale: 4190480001,
    codice_istat: 83107,
    codice_belfiore: "M211",
  },
  {
    id: 41,
    comune: "ACQUI TERME",
    sigla: "AL",
    codice_elettorale: 1010020010,
    codice_istat: 6001,
    codice_belfiore: "A052",
  },
  {
    id: 42,
    comune: "ACRI",
    sigla: "CS",
    codice_elettorale: 3180250030,
    codice_istat: 78003,
    codice_belfiore: "A053",
  },
  {
    id: 43,
    comune: "ACUTO",
    sigla: "FR",
    codice_elettorale: 2120330020,
    codice_istat: 60002,
    codice_belfiore: "A054",
  },
  {
    id: 44,
    comune: "ADELFIA",
    sigla: "BA",
    codice_elettorale: 3160090020,
    codice_istat: 72002,
    codice_belfiore: "A055",
  },
  {
    id: 45,
    comune: "ADRANO",
    sigla: "CT",
    codice_elettorale: 4190210060,
    codice_istat: 87006,
    codice_belfiore: "A056",
  },
  {
    id: 46,
    comune: "ADRARA SAN MARTINO",
    sigla: "BG",
    codice_elettorale: 1030120010,
    codice_istat: 16001,
    codice_belfiore: "A057",
  },
  {
    id: 47,
    comune: "ADRARA SAN ROCCO",
    sigla: "BG",
    codice_elettorale: 1030120020,
    codice_istat: 16002,
    codice_belfiore: "A058",
  },
  {
    id: 48,
    comune: "ADRIA",
    sigla: "RO",
    codice_elettorale: 1050710010,
    codice_istat: 29001,
    codice_belfiore: "A059",
  },
  {
    id: 49,
    comune: "ADRO",
    sigla: "BS",
    codice_elettorale: 1030150020,
    codice_istat: 17002,
    codice_belfiore: "A060",
  },
  {
    id: 50,
    comune: "AFFI",
    sigla: "VR",
    codice_elettorale: 1050890010,
    codice_istat: 23001,
    codice_belfiore: "A061",
  },
  {
    id: 51,
    comune: "AFFILE",
    sigla: "RM",
    codice_elettorale: 2120700010,
    codice_istat: 58001,
    codice_belfiore: "A062",
  },
  {
    id: 52,
    comune: "AFRAGOLA",
    sigla: "NA",
    codice_elettorale: 3150510020,
    codice_istat: 63002,
    codice_belfiore: "A064",
  },
  {
    id: 53,
    comune: "AFRICO",
    sigla: "RC",
    codice_elettorale: 3180670010,
    codice_istat: 80001,
    codice_belfiore: "A065",
  },
  {
    id: 54,
    comune: "AGAZZANO",
    sigla: "PC",
    codice_elettorale: 1080610010,
    codice_istat: 33001,
    codice_belfiore: "A067",
  },
  {
    id: 55,
    comune: "AGEROLA",
    sigla: "NA",
    codice_elettorale: 3150510030,
    codice_istat: 63003,
    codice_belfiore: "A068",
  },
  {
    id: 56,
    comune: "AGGIUS",
    sigla: "SS",
    codice_elettorale: 4200730010,
    codice_istat: 90001,
    codice_belfiore: "A069",
  },
  {
    id: 57,
    comune: "AGIRA",
    sigla: "EN",
    codice_elettorale: 4190280010,
    codice_istat: 86001,
    codice_belfiore: "A070",
  },
  {
    id: 58,
    comune: "AGLIANA",
    sigla: "PT",
    codice_elettorale: 2090630020,
    codice_istat: 47002,
    codice_belfiore: "A071",
  },
  {
    id: 59,
    comune: "AGLIANO TERME",
    sigla: "AT",
    codice_elettorale: 1010070010,
    codice_istat: 5001,
    codice_belfiore: "A072",
  },
  {
    id: 60,
    comune: "AGLIE'",
    sigla: "TO",
    codice_elettorale: 1010810010,
    codice_istat: 1001,
    codice_belfiore: "A074",
  },
  {
    id: 61,
    comune: "AGLIENTU",
    sigla: "SS",
    codice_elettorale: 4200730011,
    codice_istat: 90062,
    codice_belfiore: "H848",
  },
  {
    id: 62,
    comune: "AGNA",
    sigla: "PD",
    codice_elettorale: 1050540020,
    codice_istat: 28002,
    codice_belfiore: "A075",
  },
  {
    id: 63,
    comune: "AGNADELLO",
    sigla: "CR",
    codice_elettorale: 1030260020,
    codice_istat: 19002,
    codice_belfiore: "A076",
  },
  {
    id: 64,
    comune: "AGNANA CALABRA",
    sigla: "RC",
    codice_elettorale: 3180670020,
    codice_istat: 80002,
    codice_belfiore: "A077",
  },
  {
    id: 65,
    comune: "AGNONE",
    sigla: "IS",
    codice_elettorale: 3140940020,
    codice_istat: 94002,
    codice_belfiore: "A080",
  },
  {
    id: 66,
    comune: "AGNOSINE",
    sigla: "BS",
    codice_elettorale: 1030150030,
    codice_istat: 17003,
    codice_belfiore: "A082",
  },
  {
    id: 67,
    comune: "AGORDO",
    sigla: "BL",
    codice_elettorale: 1050100010,
    codice_istat: 25001,
    codice_belfiore: "A083",
  },
  {
    id: 68,
    comune: "AGOSTA",
    sigla: "RM",
    codice_elettorale: 2120700020,
    codice_istat: 58002,
    codice_belfiore: "A084",
  },
  {
    id: 69,
    comune: "AGRA",
    sigla: "VA",
    codice_elettorale: 1030860010,
    codice_istat: 12001,
    codice_belfiore: "A085",
  },
  {
    id: 70,
    comune: "AGRATE BRIANZA",
    sigla: "MB",
    codice_elettorale: 1031040010,
    codice_istat: 108001,
    codice_belfiore: "A087",
  },
  {
    id: 71,
    comune: "AGRATE CONTURBIA",
    sigla: "NO",
    codice_elettorale: 1010520010,
    codice_istat: 3001,
    codice_belfiore: "A088",
  },
  {
    id: 72,
    comune: "AGRIGENTO",
    sigla: "AG",
    codice_elettorale: 4190010010,
    codice_istat: 84001,
    codice_belfiore: "A089",
  },
  {
    id: 73,
    comune: "AGROPOLI",
    sigla: "SA",
    codice_elettorale: 3150720020,
    codice_istat: 65002,
    codice_belfiore: "A091",
  },
  {
    id: 74,
    comune: "AGUGLIANO",
    sigla: "AN",
    codice_elettorale: 2110030010,
    codice_istat: 42001,
    codice_belfiore: "A092",
  },
  {
    id: 75,
    comune: "AGUGLIARO",
    sigla: "VI",
    codice_elettorale: 1050900010,
    codice_istat: 24001,
    codice_belfiore: "A093",
  },
  {
    id: 76,
    comune: "AICURZIO",
    sigla: "MB",
    codice_elettorale: 1031040020,
    codice_istat: 108002,
    codice_belfiore: "A096",
  },
  {
    id: 77,
    comune: "AIDOMAGGIORE",
    sigla: "OR",
    codice_elettorale: 4200950020,
    codice_istat: 95002,
    codice_belfiore: "A097",
  },
  {
    id: 78,
    comune: "AIDONE",
    sigla: "EN",
    codice_elettorale: 4190280020,
    codice_istat: 86002,
    codice_belfiore: "A098",
  },
  {
    id: 79,
    comune: "AIELLI",
    sigla: "AQ",
    codice_elettorale: 3130380020,
    codice_istat: 66002,
    codice_belfiore: "A100",
  },
  {
    id: 80,
    comune: "AIELLO CALABRO",
    sigla: "CS",
    codice_elettorale: 3180250040,
    codice_istat: 78004,
    codice_belfiore: "A102",
  },
  {
    id: 81,
    comune: "AIELLO DEL FRIULI",
    sigla: "UD",
    codice_elettorale: 1060850010,
    codice_istat: 30001,
    codice_belfiore: "A103",
  },
  {
    id: 82,
    comune: "AIELLO DEL SABATO",
    sigla: "AV",
    codice_elettorale: 3150080010,
    codice_istat: 64001,
    codice_belfiore: "A101",
  },
  {
    id: 83,
    comune: "AIETA",
    sigla: "CS",
    codice_elettorale: 3180250050,
    codice_istat: 78005,
    codice_belfiore: "A105",
  },
  {
    id: 84,
    comune: "AILANO",
    sigla: "CE",
    codice_elettorale: 3150200010,
    codice_istat: 61001,
    codice_belfiore: "A106",
  },
  {
    id: 85,
    comune: "AILOCHE",
    sigla: "BI",
    codice_elettorale: 1010960010,
    codice_istat: 96001,
    codice_belfiore: "A107",
  },
  {
    id: 86,
    comune: "AIRASCA",
    sigla: "TO",
    codice_elettorale: 1010810020,
    codice_istat: 1002,
    codice_belfiore: "A109",
  },
  {
    id: 87,
    comune: "AIROLA",
    sigla: "BN",
    codice_elettorale: 3150110010,
    codice_istat: 62001,
    codice_belfiore: "A110",
  },
  {
    id: 88,
    comune: "AIROLE",
    sigla: "IM",
    codice_elettorale: 1070370010,
    codice_istat: 8001,
    codice_belfiore: "A111",
  },
  {
    id: 89,
    comune: "AIRUNO",
    sigla: "LC",
    codice_elettorale: 1030980020,
    codice_istat: 97002,
    codice_belfiore: "A112",
  },
  {
    id: 90,
    comune: "AISONE",
    sigla: "CN",
    codice_elettorale: 1010270020,
    codice_istat: 4002,
    codice_belfiore: "A113",
  },
  {
    id: 91,
    comune: "ALA",
    sigla: "TN",
    codice_elettorale: 1040830010,
    codice_istat: 22001,
    codice_belfiore: "A116",
  },
  {
    id: 92,
    comune: "ALA DI STURA",
    sigla: "TO",
    codice_elettorale: 1010810030,
    codice_istat: 1003,
    codice_belfiore: "A117",
  },
  {
    id: 93,
    comune: "ALA' DEI SARDI",
    sigla: "SS",
    codice_elettorale: 4200730020,
    codice_istat: 90002,
    codice_belfiore: "A115",
  },
  {
    id: 94,
    comune: "ALAGNA",
    sigla: "PV",
    codice_elettorale: 1030570010,
    codice_istat: 18001,
    codice_belfiore: "A118",
  },
  {
    id: 95,
    comune: "ALAGNA VALSESIA",
    sigla: "VC",
    codice_elettorale: 1010880020,
    codice_istat: 2002,
    codice_belfiore: "A119",
  },
  {
    id: 96,
    comune: "ALANNO",
    sigla: "PE",
    codice_elettorale: 3130600020,
    codice_istat: 68002,
    codice_belfiore: "A120",
  },
  {
    id: 97,
    comune: "ALANO DI PIAVE",
    sigla: "BL",
    codice_elettorale: 1050100020,
    codice_istat: 25002,
    codice_belfiore: "A121",
  },
  {
    id: 98,
    comune: "ALASSIO",
    sigla: "SV",
    codice_elettorale: 1070740010,
    codice_istat: 9001,
    codice_belfiore: "A122",
  },
  {
    id: 99,
    comune: "ALATRI",
    sigla: "FR",
    codice_elettorale: 2120330030,
    codice_istat: 60003,
    codice_belfiore: "A123",
  },
  {
    id: 100,
    comune: "ALBA",
    sigla: "CN",
    codice_elettorale: 1010270030,
    codice_istat: 4003,
    codice_belfiore: "A124",
  },
  {
    id: 101,
    comune: "ALBA ADRIATICA",
    sigla: "TE",
    codice_elettorale: 3130790001,
    codice_istat: 67001,
    codice_belfiore: "A125",
  },
  {
    id: 102,
    comune: "ALBAGIARA",
    sigla: "OR",
    codice_elettorale: 4200950030,
    codice_istat: 95003,
    codice_belfiore: "A126",
  },
  {
    id: 103,
    comune: "ALBAIRATE",
    sigla: "MI",
    codice_elettorale: 1030490050,
    codice_istat: 15005,
    codice_belfiore: "A127",
  },
  {
    id: 104,
    comune: "ALBANELLA",
    sigla: "SA",
    codice_elettorale: 3150720030,
    codice_istat: 65003,
    codice_belfiore: "A128",
  },
  {
    id: 105,
    comune: "ALBANO DI LUCANIA",
    sigla: "PZ",
    codice_elettorale: 3170640030,
    codice_istat: 76003,
    codice_belfiore: "A131",
  },
  {
    id: 106,
    comune: "ALBANO LAZIALE",
    sigla: "RM",
    codice_elettorale: 2120700030,
    codice_istat: 58003,
    codice_belfiore: "A132",
  },
  {
    id: 107,
    comune: "ALBANO SANT'ALESSANDRO",
    sigla: "BG",
    codice_elettorale: 1030120030,
    codice_istat: 16003,
    codice_belfiore: "A129",
  },
  {
    id: 108,
    comune: "ALBANO VERCELLESE",
    sigla: "VC",
    codice_elettorale: 1010880030,
    codice_istat: 2003,
    codice_belfiore: "A130",
  },
  {
    id: 109,
    comune: "ALBAREDO ARNABOLDI",
    sigla: "PV",
    codice_elettorale: 1030570020,
    codice_istat: 18002,
    codice_belfiore: "A134",
  },
  {
    id: 110,
    comune: "ALBAREDO D'ADIGE",
    sigla: "VR",
    codice_elettorale: 1050890020,
    codice_istat: 23002,
    codice_belfiore: "A137",
  },
  {
    id: 111,
    comune: "ALBAREDO PER SAN MARCO",
    sigla: "SO",
    codice_elettorale: 1030770010,
    codice_istat: 14001,
    codice_belfiore: "A135",
  },
  {
    id: 112,
    comune: "ALBARETO",
    sigla: "PR",
    codice_elettorale: 1080560010,
    codice_istat: 34001,
    codice_belfiore: "A138",
  },
  {
    id: 113,
    comune: "ALBARETTO DELLA TORRE",
    sigla: "CN",
    codice_elettorale: 1010270040,
    codice_istat: 4004,
    codice_belfiore: "A139",
  },
  {
    id: 114,
    comune: "ALBAVILLA",
    sigla: "CO",
    codice_elettorale: 1030240030,
    codice_istat: 13003,
    codice_belfiore: "A143",
  },
  {
    id: 115,
    comune: "ALBENGA",
    sigla: "SV",
    codice_elettorale: 1070740020,
    codice_istat: 9002,
    codice_belfiore: "A145",
  },
  {
    id: 116,
    comune: "ALBERA LIGURE",
    sigla: "AL",
    codice_elettorale: 1010020020,
    codice_istat: 6002,
    codice_belfiore: "A146",
  },
  {
    id: 117,
    comune: "ALBEROBELLO",
    sigla: "BA",
    codice_elettorale: 3160090030,
    codice_istat: 72003,
    codice_belfiore: "A149",
  },
  {
    id: 118,
    comune: "ALBERONA",
    sigla: "FG",
    codice_elettorale: 3160310020,
    codice_istat: 71002,
    codice_belfiore: "A150",
  },
  {
    id: 119,
    comune: "ALBESE CON CASSANO",
    sigla: "CO",
    codice_elettorale: 1030240040,
    codice_istat: 13004,
    codice_belfiore: "A153",
  },
  {
    id: 120,
    comune: "ALBETTONE",
    sigla: "VI",
    codice_elettorale: 1050900020,
    codice_istat: 24002,
    codice_belfiore: "A154",
  },
  {
    id: 121,
    comune: "ALBI",
    sigla: "CZ",
    codice_elettorale: 3180220020,
    codice_istat: 79002,
    codice_belfiore: "A155",
  },
  {
    id: 122,
    comune: "ALBIANO",
    sigla: "TN",
    codice_elettorale: 1040830020,
    codice_istat: 22002,
    codice_belfiore: "A158",
  },
  {
    id: 123,
    comune: "ALBIANO D'IVREA",
    sigla: "TO",
    codice_elettorale: 1010810040,
    codice_istat: 1004,
    codice_belfiore: "A157",
  },
  {
    id: 124,
    comune: "ALBIATE",
    sigla: "MB",
    codice_elettorale: 1031040030,
    codice_istat: 108003,
    codice_belfiore: "A159",
  },
  {
    id: 125,
    comune: "ALBIDONA",
    sigla: "CS",
    codice_elettorale: 3180250060,
    codice_istat: 78006,
    codice_belfiore: "A160",
  },
  {
    id: 126,
    comune: "ALBIGNASEGO",
    sigla: "PD",
    codice_elettorale: 1050540030,
    codice_istat: 28003,
    codice_belfiore: "A161",
  },
  {
    id: 127,
    comune: "ALBINEA",
    sigla: "RE",
    codice_elettorale: 1080680010,
    codice_istat: 35001,
    codice_belfiore: "A162",
  },
  {
    id: 128,
    comune: "ALBINO",
    sigla: "BG",
    codice_elettorale: 1030120040,
    codice_istat: 16004,
    codice_belfiore: "A163",
  },
  {
    id: 129,
    comune: "ALBIOLO",
    sigla: "CO",
    codice_elettorale: 1030240050,
    codice_istat: 13005,
    codice_belfiore: "A164",
  },
  {
    id: 130,
    comune: "ALBISOLA SUPERIORE",
    sigla: "SV",
    codice_elettorale: 1070740040,
    codice_istat: 9004,
    codice_belfiore: "A166",
  },
  {
    id: 131,
    comune: "ALBISSOLA MARINA",
    sigla: "SV",
    codice_elettorale: 1070740030,
    codice_istat: 9003,
    codice_belfiore: "A165",
  },
  {
    id: 132,
    comune: "ALBIZZATE",
    sigla: "VA",
    codice_elettorale: 1030860020,
    codice_istat: 12002,
    codice_belfiore: "A167",
  },
  {
    id: 133,
    comune: "ALBONESE",
    sigla: "PV",
    codice_elettorale: 1030570030,
    codice_istat: 18003,
    codice_belfiore: "A171",
  },
  {
    id: 134,
    comune: "ALBOSAGGIA",
    sigla: "SO",
    codice_elettorale: 1030770020,
    codice_istat: 14002,
    codice_belfiore: "A172",
  },
  {
    id: 135,
    comune: "ALBUGNANO",
    sigla: "AT",
    codice_elettorale: 1010070020,
    codice_istat: 5002,
    codice_belfiore: "A173",
  },
  {
    id: 136,
    comune: "ALBUZZANO",
    sigla: "PV",
    codice_elettorale: 1030570040,
    codice_istat: 18004,
    codice_belfiore: "A175",
  },
  {
    id: 137,
    comune: "ALCAMO",
    sigla: "TP",
    codice_elettorale: 4190820010,
    codice_istat: 81001,
    codice_belfiore: "A176",
  },
  {
    id: 138,
    comune: "ALCARA LI FUSI",
    sigla: "ME",
    codice_elettorale: 4190480010,
    codice_istat: 83001,
    codice_belfiore: "A177",
  },
  {
    id: 139,
    comune: "ALDENO",
    sigla: "TN",
    codice_elettorale: 1040830030,
    codice_istat: 22003,
    codice_belfiore: "A178",
  },
  {
    id: 140,
    comune: "ALDINO/ALDEIN",
    sigla: "BZ",
    codice_elettorale: 1040140002,
    codice_istat: 21001,
    codice_belfiore: "A179",
  },
  {
    id: 141,
    comune: "ALES",
    sigla: "OR",
    codice_elettorale: 4200950040,
    codice_istat: 95004,
    codice_belfiore: "A180",
  },
  {
    id: 142,
    comune: "ALESSANDRIA",
    sigla: "AL",
    codice_elettorale: 1010020030,
    codice_istat: 6003,
    codice_belfiore: "A182",
  },
  {
    id: 143,
    comune: "ALESSANDRIA DEL CARRETTO",
    sigla: "CS",
    codice_elettorale: 3180250070,
    codice_istat: 78007,
    codice_belfiore: "A183",
  },
  {
    id: 144,
    comune: "ALESSANDRIA DELLA ROCCA",
    sigla: "AG",
    codice_elettorale: 4190010020,
    codice_istat: 84002,
    codice_belfiore: "A181",
  },
  {
    id: 145,
    comune: "ALESSANO",
    sigla: "LE",
    codice_elettorale: 3160410020,
    codice_istat: 75002,
    codice_belfiore: "A184",
  },
  {
    id: 146,
    comune: "ALEZIO",
    sigla: "LE",
    codice_elettorale: 3160410030,
    codice_istat: 75003,
    codice_belfiore: "A185",
  },
  {
    id: 147,
    comune: "ALFANO",
    sigla: "SA",
    codice_elettorale: 3150720040,
    codice_istat: 65004,
    codice_belfiore: "A186",
  },
  {
    id: 148,
    comune: "ALFEDENA",
    sigla: "AQ",
    codice_elettorale: 3130380030,
    codice_istat: 66003,
    codice_belfiore: "A187",
  },
  {
    id: 149,
    comune: "ALFIANELLO",
    sigla: "BS",
    codice_elettorale: 1030150040,
    codice_istat: 17004,
    codice_belfiore: "A188",
  },
  {
    id: 150,
    comune: "ALFIANO NATTA",
    sigla: "AL",
    codice_elettorale: 1010020040,
    codice_istat: 6004,
    codice_belfiore: "A189",
  },
  {
    id: 151,
    comune: "ALFONSINE",
    sigla: "RA",
    codice_elettorale: 1080660010,
    codice_istat: 39001,
    codice_belfiore: "A191",
  },
  {
    id: 152,
    comune: "ALGHERO",
    sigla: "SS",
    codice_elettorale: 4200730030,
    codice_istat: 90003,
    codice_belfiore: "A192",
  },
  {
    id: 153,
    comune: "ALGUA",
    sigla: "BG",
    codice_elettorale: 1030120041,
    codice_istat: 16248,
    codice_belfiore: "A193",
  },
  {
    id: 154,
    comune: "ALI'",
    sigla: "ME",
    codice_elettorale: 4190480020,
    codice_istat: 83002,
    codice_belfiore: "A194",
  },
  {
    id: 155,
    comune: "ALI' TERME",
    sigla: "ME",
    codice_elettorale: 4190480030,
    codice_istat: 83003,
    codice_belfiore: "A201",
  },
  {
    id: 156,
    comune: "ALIA",
    sigla: "PA",
    codice_elettorale: 4190550010,
    codice_istat: 82001,
    codice_belfiore: "A195",
  },
  {
    id: 157,
    comune: "ALIANO",
    sigla: "MT",
    codice_elettorale: 3170470020,
    codice_istat: 77002,
    codice_belfiore: "A196",
  },
  {
    id: 158,
    comune: "ALICE BEL COLLE",
    sigla: "AL",
    codice_elettorale: 1010020050,
    codice_istat: 6005,
    codice_belfiore: "A197",
  },
  {
    id: 159,
    comune: "ALICE CASTELLO",
    sigla: "VC",
    codice_elettorale: 1010880040,
    codice_istat: 2004,
    codice_belfiore: "A198",
  },
  {
    id: 160,
    comune: "ALIFE",
    sigla: "CE",
    codice_elettorale: 3150200020,
    codice_istat: 61002,
    codice_belfiore: "A200",
  },
  {
    id: 161,
    comune: "ALIMENA",
    sigla: "PA",
    codice_elettorale: 4190550020,
    codice_istat: 82002,
    codice_belfiore: "A202",
  },
  {
    id: 162,
    comune: "ALIMINUSA",
    sigla: "PA",
    codice_elettorale: 4190550030,
    codice_istat: 82003,
    codice_belfiore: "A203",
  },
  {
    id: 163,
    comune: "ALLAI",
    sigla: "OR",
    codice_elettorale: 4200950050,
    codice_istat: 95005,
    codice_belfiore: "A204",
  },
  {
    id: 164,
    comune: "ALLEGHE",
    sigla: "BL",
    codice_elettorale: 1050100030,
    codice_istat: 25003,
    codice_belfiore: "A206",
  },
  {
    id: 165,
    comune: "ALLEIN",
    sigla: "AO",
    codice_elettorale: 1020040010,
    codice_istat: 7001,
    codice_belfiore: "A205",
  },
  {
    id: 166,
    comune: "ALLERONA",
    sigla: "TR",
    codice_elettorale: 2100800020,
    codice_istat: 55002,
    codice_belfiore: "A207",
  },
  {
    id: 167,
    comune: "ALLISTE",
    sigla: "LE",
    codice_elettorale: 3160410040,
    codice_istat: 75004,
    codice_belfiore: "A208",
  },
  {
    id: 168,
    comune: "ALLUMIERE",
    sigla: "RM",
    codice_elettorale: 2120700040,
    codice_istat: 58004,
    codice_belfiore: "A210",
  },
  {
    id: 169,
    comune: "ALLUVIONI PIOVERA",
    sigla: "AL",
    codice_elettorale: 1010020062,
    codice_istat: 6192,
    codice_belfiore: "M397",
  },
  {
    id: 170,
    comune: "ALME'",
    sigla: "BG",
    codice_elettorale: 1030120050,
    codice_istat: 16005,
    codice_belfiore: "A214",
  },
  {
    id: 171,
    comune: "ALMENNO SAN BARTOLOMEO",
    sigla: "BG",
    codice_elettorale: 1030120070,
    codice_istat: 16006,
    codice_belfiore: "A216",
  },
  {
    id: 172,
    comune: "ALMENNO SAN SALVATORE",
    sigla: "BG",
    codice_elettorale: 1030120080,
    codice_istat: 16007,
    codice_belfiore: "A217",
  },
  {
    id: 173,
    comune: "ALMESE",
    sigla: "TO",
    codice_elettorale: 1010810060,
    codice_istat: 1006,
    codice_belfiore: "A218",
  },
  {
    id: 174,
    comune: "ALONTE",
    sigla: "VI",
    codice_elettorale: 1050900030,
    codice_istat: 24003,
    codice_belfiore: "A220",
  },
  {
    id: 175,
    comune: "ALPAGO",
    sigla: "BL",
    codice_elettorale: 1050100035,
    codice_istat: 25072,
    codice_belfiore: "M375",
  },
  {
    id: 176,
    comune: "ALPETTE",
    sigla: "TO",
    codice_elettorale: 1010810070,
    codice_istat: 1007,
    codice_belfiore: "A221",
  },
  {
    id: 177,
    comune: "ALPIGNANO",
    sigla: "TO",
    codice_elettorale: 1010810080,
    codice_istat: 1008,
    codice_belfiore: "A222",
  },
  {
    id: 178,
    comune: "ALSENO",
    sigla: "PC",
    codice_elettorale: 1080610020,
    codice_istat: 33002,
    codice_belfiore: "A223",
  },
  {
    id: 179,
    comune: "ALSERIO",
    sigla: "CO",
    codice_elettorale: 1030240060,
    codice_istat: 13006,
    codice_belfiore: "A224",
  },
  {
    id: 180,
    comune: "ALTA VAL TIDONE",
    sigla: "PC",
    codice_elettorale: 1080610025,
    codice_istat: 33049,
    codice_belfiore: "M386",
  },
  {
    id: 181,
    comune: "ALTA VALLE INTELVI",
    sigla: "CO",
    codice_elettorale: 1030240065,
    codice_istat: 13253,
    codice_belfiore: "M383",
  },
  {
    id: 182,
    comune: "ALTAMURA",
    sigla: "BA",
    codice_elettorale: 3160090040,
    codice_istat: 72004,
    codice_belfiore: "A225",
  },
  {
    id: 183,
    comune: "ALTARE",
    sigla: "SV",
    codice_elettorale: 1070740050,
    codice_istat: 9005,
    codice_belfiore: "A226",
  },
  {
    id: 184,
    comune: "ALTAVALLE",
    sigla: "TN",
    codice_elettorale: 1040830034,
    codice_istat: 22235,
    codice_belfiore: "M349",
  },
  {
    id: 185,
    comune: "ALTAVILLA IRPINA",
    sigla: "AV",
    codice_elettorale: 3150080020,
    codice_istat: 64002,
    codice_belfiore: "A228",
  },
  {
    id: 186,
    comune: "ALTAVILLA MILICIA",
    sigla: "PA",
    codice_elettorale: 4190550040,
    codice_istat: 82004,
    codice_belfiore: "A229",
  },
  {
    id: 187,
    comune: "ALTAVILLA MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020070,
    codice_istat: 6007,
    codice_belfiore: "A227",
  },
  {
    id: 188,
    comune: "ALTAVILLA SILENTINA",
    sigla: "SA",
    codice_elettorale: 3150720050,
    codice_istat: 65005,
    codice_belfiore: "A230",
  },
  {
    id: 189,
    comune: "ALTAVILLA VICENTINA",
    sigla: "VI",
    codice_elettorale: 1050900040,
    codice_istat: 24004,
    codice_belfiore: "A231",
  },
  {
    id: 190,
    comune: "ALTIDONA",
    sigla: "FM",
    codice_elettorale: 2111050010,
    codice_istat: 109001,
    codice_belfiore: "A233",
  },
  {
    id: 191,
    comune: "ALTILIA",
    sigla: "CS",
    codice_elettorale: 3180250080,
    codice_istat: 78008,
    codice_belfiore: "A234",
  },
  {
    id: 192,
    comune: "ALTINO",
    sigla: "CH",
    codice_elettorale: 3130230010,
    codice_istat: 69001,
    codice_belfiore: "A235",
  },
  {
    id: 193,
    comune: "ALTISSIMO",
    sigla: "VI",
    codice_elettorale: 1050900050,
    codice_istat: 24005,
    codice_belfiore: "A236",
  },
  {
    id: 194,
    comune: "ALTIVOLE",
    sigla: "TV",
    codice_elettorale: 1050840010,
    codice_istat: 26001,
    codice_belfiore: "A237",
  },
  {
    id: 195,
    comune: "ALTO",
    sigla: "CN",
    codice_elettorale: 1010270050,
    codice_istat: 4005,
    codice_belfiore: "A238",
  },
  {
    id: 196,
    comune: "ALTO RENO TERME",
    sigla: "BO",
    codice_elettorale: 1080130005,
    codice_istat: 37062,
    codice_belfiore: "M369",
  },
  {
    id: 197,
    comune: "ALTO SERMENZA",
    sigla: "VC",
    codice_elettorale: 1010880045,
    codice_istat: 2170,
    codice_belfiore: "M389",
  },
  {
    id: 198,
    comune: "ALTOFONTE",
    sigla: "PA",
    codice_elettorale: 4190550050,
    codice_istat: 82005,
    codice_belfiore: "A239",
  },
  {
    id: 199,
    comune: "ALTOMONTE",
    sigla: "CS",
    codice_elettorale: 3180250090,
    codice_istat: 78009,
    codice_belfiore: "A240",
  },
  {
    id: 200,
    comune: "ALTOPASCIO",
    sigla: "LU",
    codice_elettorale: 2090430010,
    codice_istat: 46001,
    codice_belfiore: "A241",
  },
  {
    id: 201,
    comune: "ALTOPIANO DELLA VIGOLANA",
    sigla: "TN",
    codice_elettorale: 1040830037,
    codice_istat: 22236,
    codice_belfiore: "M350",
  },
  {
    id: 202,
    comune: "ALVIANO",
    sigla: "TR",
    codice_elettorale: 2100800030,
    codice_istat: 55003,
    codice_belfiore: "A242",
  },
  {
    id: 203,
    comune: "ALVIGNANO",
    sigla: "CE",
    codice_elettorale: 3150200030,
    codice_istat: 61003,
    codice_belfiore: "A243",
  },
  {
    id: 204,
    comune: "ALVITO",
    sigla: "FR",
    codice_elettorale: 2120330040,
    codice_istat: 60004,
    codice_belfiore: "A244",
  },
  {
    id: 205,
    comune: "ALZANO LOMBARDO",
    sigla: "BG",
    codice_elettorale: 1030120090,
    codice_istat: 16008,
    codice_belfiore: "A246",
  },
  {
    id: 206,
    comune: "ALZANO SCRIVIA",
    sigla: "AL",
    codice_elettorale: 1010020080,
    codice_istat: 6008,
    codice_belfiore: "A245",
  },
  {
    id: 207,
    comune: "ALZATE BRIANZA",
    sigla: "CO",
    codice_elettorale: 1030240070,
    codice_istat: 13007,
    codice_belfiore: "A249",
  },
  {
    id: 208,
    comune: "AMALFI",
    sigla: "SA",
    codice_elettorale: 3150720060,
    codice_istat: 65006,
    codice_belfiore: "A251",
  },
  {
    id: 209,
    comune: "AMANDOLA",
    sigla: "FM",
    codice_elettorale: 2111050020,
    codice_istat: 109002,
    codice_belfiore: "A252",
  },
  {
    id: 210,
    comune: "AMANTEA",
    sigla: "CS",
    codice_elettorale: 3180250100,
    codice_istat: 78010,
    codice_belfiore: "A253",
  },
  {
    id: 211,
    comune: "AMARO",
    sigla: "UD",
    codice_elettorale: 1060850020,
    codice_istat: 30002,
    codice_belfiore: "A254",
  },
  {
    id: 212,
    comune: "AMARONI",
    sigla: "CZ",
    codice_elettorale: 3180220030,
    codice_istat: 79003,
    codice_belfiore: "A255",
  },
  {
    id: 213,
    comune: "AMASENO",
    sigla: "FR",
    codice_elettorale: 2120330050,
    codice_istat: 60005,
    codice_belfiore: "A256",
  },
  {
    id: 214,
    comune: "AMATO",
    sigla: "CZ",
    codice_elettorale: 3180220040,
    codice_istat: 79004,
    codice_belfiore: "A257",
  },
  {
    id: 215,
    comune: "AMATRICE",
    sigla: "RI",
    codice_elettorale: 2120690020,
    codice_istat: 57002,
    codice_belfiore: "A258",
  },
  {
    id: 216,
    comune: "AMBIVERE",
    sigla: "BG",
    codice_elettorale: 1030120100,
    codice_istat: 16009,
    codice_belfiore: "A259",
  },
  {
    id: 217,
    comune: "AMBLAR-DON",
    sigla: "TN",
    codice_elettorale: 1040830042,
    codice_istat: 22237,
    codice_belfiore: "M351",
  },
  {
    id: 218,
    comune: "AMEGLIA",
    sigla: "SP",
    codice_elettorale: 1070390010,
    codice_istat: 11001,
    codice_belfiore: "A261",
  },
  {
    id: 219,
    comune: "AMELIA",
    sigla: "TR",
    codice_elettorale: 2100800040,
    codice_istat: 55004,
    codice_belfiore: "A262",
  },
  {
    id: 220,
    comune: "AMENDOLARA",
    sigla: "CS",
    codice_elettorale: 3180250110,
    codice_istat: 78011,
    codice_belfiore: "A263",
  },
  {
    id: 221,
    comune: "AMENO",
    sigla: "NO",
    codice_elettorale: 1010520020,
    codice_istat: 3002,
    codice_belfiore: "A264",
  },
  {
    id: 222,
    comune: "AMOROSI",
    sigla: "BN",
    codice_elettorale: 3150110020,
    codice_istat: 62002,
    codice_belfiore: "A265",
  },
  {
    id: 223,
    comune: "AMPEZZO",
    sigla: "UD",
    codice_elettorale: 1060850030,
    codice_istat: 30003,
    codice_belfiore: "A267",
  },
  {
    id: 224,
    comune: "ANACAPRI",
    sigla: "NA",
    codice_elettorale: 3150510040,
    codice_istat: 63004,
    codice_belfiore: "A268",
  },
  {
    id: 225,
    comune: "ANAGNI",
    sigla: "FR",
    codice_elettorale: 2120330060,
    codice_istat: 60006,
    codice_belfiore: "A269",
  },
  {
    id: 226,
    comune: "ANCARANO",
    sigla: "TE",
    codice_elettorale: 3130790010,
    codice_istat: 67002,
    codice_belfiore: "A270",
  },
  {
    id: 227,
    comune: "ANCONA",
    sigla: "AN",
    codice_elettorale: 2110030020,
    codice_istat: 42002,
    codice_belfiore: "A271",
  },
  {
    id: 228,
    comune: "ANDALI",
    sigla: "CZ",
    codice_elettorale: 3180220050,
    codice_istat: 79005,
    codice_belfiore: "A272",
  },
  {
    id: 229,
    comune: "ANDALO",
    sigla: "TN",
    codice_elettorale: 1040830050,
    codice_istat: 22005,
    codice_belfiore: "A274",
  },
  {
    id: 230,
    comune: "ANDALO VALTELLINO",
    sigla: "SO",
    codice_elettorale: 1030770030,
    codice_istat: 14003,
    codice_belfiore: "A273",
  },
  {
    id: 231,
    comune: "ANDEZENO",
    sigla: "TO",
    codice_elettorale: 1010810090,
    codice_istat: 1009,
    codice_belfiore: "A275",
  },
  {
    id: 232,
    comune: "ANDORA",
    sigla: "SV",
    codice_elettorale: 1070740060,
    codice_istat: 9006,
    codice_belfiore: "A278",
  },
  {
    id: 233,
    comune: "ANDORNO MICCA",
    sigla: "BI",
    codice_elettorale: 1010960020,
    codice_istat: 96002,
    codice_belfiore: "A280",
  },
  {
    id: 234,
    comune: "ANDRANO",
    sigla: "LE",
    codice_elettorale: 3160410050,
    codice_istat: 75005,
    codice_belfiore: "A281",
  },
  {
    id: 235,
    comune: "ANDRATE",
    sigla: "TO",
    codice_elettorale: 1010810100,
    codice_istat: 1010,
    codice_belfiore: "A282",
  },
  {
    id: 236,
    comune: "ANDREIS",
    sigla: "PN",
    codice_elettorale: 1060930010,
    codice_istat: 93001,
    codice_belfiore: "A283",
  },
  {
    id: 237,
    comune: "ANDRETTA",
    sigla: "AV",
    codice_elettorale: 3150080030,
    codice_istat: 64003,
    codice_belfiore: "A284",
  },
  {
    id: 238,
    comune: "ANDRIA",
    sigla: "BT",
    codice_elettorale: 3161060010,
    codice_istat: 110001,
    codice_belfiore: "A285",
  },
  {
    id: 239,
    comune: "ANDRIANO/ANDRIAN",
    sigla: "BZ",
    codice_elettorale: 1040140001,
    codice_istat: 21002,
    codice_belfiore: "A286",
  },
  {
    id: 240,
    comune: "ANELA",
    sigla: "SS",
    codice_elettorale: 4200730040,
    codice_istat: 90004,
    codice_belfiore: "A287",
  },
  {
    id: 241,
    comune: "ANFO",
    sigla: "BS",
    codice_elettorale: 1030150050,
    codice_istat: 17005,
    codice_belfiore: "A288",
  },
  {
    id: 242,
    comune: "ANGERA",
    sigla: "VA",
    codice_elettorale: 1030860030,
    codice_istat: 12003,
    codice_belfiore: "A290",
  },
  {
    id: 243,
    comune: "ANGHIARI",
    sigla: "AR",
    codice_elettorale: 2090050010,
    codice_istat: 51001,
    codice_belfiore: "A291",
  },
  {
    id: 244,
    comune: "ANGIARI",
    sigla: "VR",
    codice_elettorale: 1050890030,
    codice_istat: 23003,
    codice_belfiore: "A292",
  },
  {
    id: 245,
    comune: "ANGOLO TERME",
    sigla: "BS",
    codice_elettorale: 1030150060,
    codice_istat: 17006,
    codice_belfiore: "A293",
  },
  {
    id: 246,
    comune: "ANGRI",
    sigla: "SA",
    codice_elettorale: 3150720070,
    codice_istat: 65007,
    codice_belfiore: "A294",
  },
  {
    id: 247,
    comune: "ANGROGNA",
    sigla: "TO",
    codice_elettorale: 1010810110,
    codice_istat: 1011,
    codice_belfiore: "A295",
  },
  {
    id: 248,
    comune: "ANGUILLARA SABAZIA",
    sigla: "RM",
    codice_elettorale: 2120700050,
    codice_istat: 58005,
    codice_belfiore: "A297",
  },
  {
    id: 249,
    comune: "ANGUILLARA VENETA",
    sigla: "PD",
    codice_elettorale: 1050540040,
    codice_istat: 28004,
    codice_belfiore: "A296",
  },
  {
    id: 250,
    comune: "ANNICCO",
    sigla: "CR",
    codice_elettorale: 1030260030,
    codice_istat: 19003,
    codice_belfiore: "A299",
  },
  {
    id: 251,
    comune: "ANNONE DI BRIANZA",
    sigla: "LC",
    codice_elettorale: 1030980030,
    codice_istat: 97003,
    codice_belfiore: "A301",
  },
  {
    id: 252,
    comune: "ANNONE VENETO",
    sigla: "VE",
    codice_elettorale: 1050870010,
    codice_istat: 27001,
    codice_belfiore: "A302",
  },
  {
    id: 253,
    comune: "ANOIA",
    sigla: "RC",
    codice_elettorale: 3180670030,
    codice_istat: 80003,
    codice_belfiore: "A303",
  },
  {
    id: 254,
    comune: "ANTEGNATE",
    sigla: "BG",
    codice_elettorale: 1030120110,
    codice_istat: 16010,
    codice_belfiore: "A304",
  },
  {
    id: 255,
    comune: "ANTERIVO/ALTREI",
    sigla: "BZ",
    codice_elettorale: 1040140010,
    codice_istat: 21003,
    codice_belfiore: "A306",
  },
  {
    id: 256,
    comune: "ANTEY-SAINT-ANDRE'",
    sigla: "AO",
    codice_elettorale: 1020040020,
    codice_istat: 7002,
    codice_belfiore: "A305",
  },
  {
    id: 257,
    comune: "ANTICOLI CORRADO",
    sigla: "RM",
    codice_elettorale: 2120700060,
    codice_istat: 58006,
    codice_belfiore: "A309",
  },
  {
    id: 258,
    comune: "ANTIGNANO",
    sigla: "AT",
    codice_elettorale: 1010070030,
    codice_istat: 5003,
    codice_belfiore: "A312",
  },
  {
    id: 259,
    comune: "ANTILLO",
    sigla: "ME",
    codice_elettorale: 4190480040,
    codice_istat: 83004,
    codice_belfiore: "A313",
  },
  {
    id: 260,
    comune: "ANTONIMINA",
    sigla: "RC",
    codice_elettorale: 3180670040,
    codice_istat: 80004,
    codice_belfiore: "A314",
  },
  {
    id: 261,
    comune: "ANTRODOCO",
    sigla: "RI",
    codice_elettorale: 2120690030,
    codice_istat: 57003,
    codice_belfiore: "A315",
  },
  {
    id: 262,
    comune: "ANTRONA SCHIERANCO",
    sigla: "VB",
    codice_elettorale: 1011020010,
    codice_istat: 103001,
    codice_belfiore: "A317",
  },
  {
    id: 263,
    comune: "ANVERSA DEGLI ABRUZZI",
    sigla: "AQ",
    codice_elettorale: 3130380040,
    codice_istat: 66004,
    codice_belfiore: "A318",
  },
  {
    id: 264,
    comune: "ANZANO DEL PARCO",
    sigla: "CO",
    codice_elettorale: 1030240090,
    codice_istat: 13009,
    codice_belfiore: "A319",
  },
  {
    id: 265,
    comune: "ANZANO DI PUGLIA",
    sigla: "FG",
    codice_elettorale: 3160310030,
    codice_istat: 71003,
    codice_belfiore: "A320",
  },
  {
    id: 266,
    comune: "ANZI",
    sigla: "PZ",
    codice_elettorale: 3170640040,
    codice_istat: 76004,
    codice_belfiore: "A321",
  },
  {
    id: 267,
    comune: "ANZIO",
    sigla: "RM",
    codice_elettorale: 2120700070,
    codice_istat: 58007,
    codice_belfiore: "A323",
  },
  {
    id: 268,
    comune: "ANZOLA D'OSSOLA",
    sigla: "VB",
    codice_elettorale: 1011020020,
    codice_istat: 103002,
    codice_belfiore: "A325",
  },
  {
    id: 269,
    comune: "ANZOLA DELL'EMILIA",
    sigla: "BO",
    codice_elettorale: 1080130010,
    codice_istat: 37001,
    codice_belfiore: "A324",
  },
  {
    id: 270,
    comune: "AOSTA",
    sigla: "AO",
    codice_elettorale: 1020040030,
    codice_istat: 7003,
    codice_belfiore: "A326",
  },
  {
    id: 271,
    comune: "APECCHIO",
    sigla: "PU",
    codice_elettorale: 2110590020,
    codice_istat: 41002,
    codice_belfiore: "A327",
  },
  {
    id: 272,
    comune: "APICE",
    sigla: "BN",
    codice_elettorale: 3150110030,
    codice_istat: 62003,
    codice_belfiore: "A328",
  },
  {
    id: 273,
    comune: "APIRO",
    sigla: "MC",
    codice_elettorale: 2110440020,
    codice_istat: 43002,
    codice_belfiore: "A329",
  },
  {
    id: 274,
    comune: "APOLLOSA",
    sigla: "BN",
    codice_elettorale: 3150110040,
    codice_istat: 62004,
    codice_belfiore: "A330",
  },
  {
    id: 275,
    comune: "APPIANO GENTILE",
    sigla: "CO",
    codice_elettorale: 1030240100,
    codice_istat: 13010,
    codice_belfiore: "A333",
  },
  {
    id: 276,
    comune: "APPIANO SULLA STRADA DEL VINO/EPPAN AN DER WEINSTRASSE",
    sigla: "BZ",
    codice_elettorale: 1040140020,
    codice_istat: 21004,
    codice_belfiore: "A332",
  },
  {
    id: 277,
    comune: "APPIGNANO",
    sigla: "MC",
    codice_elettorale: 2110440030,
    codice_istat: 43003,
    codice_belfiore: "A334",
  },
  {
    id: 278,
    comune: "APPIGNANO DEL TRONTO",
    sigla: "AP",
    codice_elettorale: 2110060050,
    codice_istat: 44005,
    codice_belfiore: "A335",
  },
  {
    id: 279,
    comune: "APRICA",
    sigla: "SO",
    codice_elettorale: 1030770040,
    codice_istat: 14004,
    codice_belfiore: "A337",
  },
  {
    id: 280,
    comune: "APRICALE",
    sigla: "IM",
    codice_elettorale: 1070370020,
    codice_istat: 8002,
    codice_belfiore: "A338",
  },
  {
    id: 281,
    comune: "APRICENA",
    sigla: "FG",
    codice_elettorale: 3160310040,
    codice_istat: 71004,
    codice_belfiore: "A339",
  },
  {
    id: 282,
    comune: "APRIGLIANO",
    sigla: "CS",
    codice_elettorale: 3180250120,
    codice_istat: 78012,
    codice_belfiore: "A340",
  },
  {
    id: 283,
    comune: "APRILIA",
    sigla: "LT",
    codice_elettorale: 2120400010,
    codice_istat: 59001,
    codice_belfiore: "A341",
  },
  {
    id: 284,
    comune: "AQUARA",
    sigla: "SA",
    codice_elettorale: 3150720080,
    codice_istat: 65008,
    codice_belfiore: "A343",
  },
  {
    id: 285,
    comune: "AQUILA D'ARROSCIA",
    sigla: "IM",
    codice_elettorale: 1070370030,
    codice_istat: 8003,
    codice_belfiore: "A344",
  },
  {
    id: 286,
    comune: "AQUILEIA",
    sigla: "UD",
    codice_elettorale: 1060850040,
    codice_istat: 30004,
    codice_belfiore: "A346",
  },
  {
    id: 287,
    comune: "AQUILONIA",
    sigla: "AV",
    codice_elettorale: 3150080040,
    codice_istat: 64004,
    codice_belfiore: "A347",
  },
  {
    id: 288,
    comune: "AQUINO",
    sigla: "FR",
    codice_elettorale: 2120330070,
    codice_istat: 60007,
    codice_belfiore: "A348",
  },
  {
    id: 289,
    comune: "ARADEO",
    sigla: "LE",
    codice_elettorale: 3160410060,
    codice_istat: 75006,
    codice_belfiore: "A350",
  },
  {
    id: 290,
    comune: "ARAGONA",
    sigla: "AG",
    codice_elettorale: 4190010030,
    codice_istat: 84003,
    codice_belfiore: "A351",
  },
  {
    id: 291,
    comune: "ARAMENGO",
    sigla: "AT",
    codice_elettorale: 1010070040,
    codice_istat: 5004,
    codice_belfiore: "A352",
  },
  {
    id: 292,
    comune: "ARBA",
    sigla: "PN",
    codice_elettorale: 1060930020,
    codice_istat: 93002,
    codice_belfiore: "A354",
  },
  {
    id: 293,
    comune: "ARBOREA",
    sigla: "OR",
    codice_elettorale: 4200950060,
    codice_istat: 95006,
    codice_belfiore: "A357",
  },
  {
    id: 294,
    comune: "ARBORIO",
    sigla: "VC",
    codice_elettorale: 1010880060,
    codice_istat: 2006,
    codice_belfiore: "A358",
  },
  {
    id: 295,
    comune: "ARBUS",
    sigla: "SU",
    codice_elettorale: 4201110010,
    codice_istat: 111001,
    codice_belfiore: "A359",
  },
  {
    id: 296,
    comune: "ARCADE",
    sigla: "TV",
    codice_elettorale: 1050840020,
    codice_istat: 26002,
    codice_belfiore: "A360",
  },
  {
    id: 297,
    comune: "ARCE",
    sigla: "FR",
    codice_elettorale: 2120330080,
    codice_istat: 60008,
    codice_belfiore: "A363",
  },
  {
    id: 298,
    comune: "ARCENE",
    sigla: "BG",
    codice_elettorale: 1030120120,
    codice_istat: 16011,
    codice_belfiore: "A365",
  },
  {
    id: 299,
    comune: "ARCEVIA",
    sigla: "AN",
    codice_elettorale: 2110030030,
    codice_istat: 42003,
    codice_belfiore: "A366",
  },
  {
    id: 300,
    comune: "ARCHI",
    sigla: "CH",
    codice_elettorale: 3130230020,
    codice_istat: 69002,
    codice_belfiore: "A367",
  },
  {
    id: 301,
    comune: "ARCIDOSSO",
    sigla: "GR",
    codice_elettorale: 2090360010,
    codice_istat: 53001,
    codice_belfiore: "A369",
  },
  {
    id: 302,
    comune: "ARCINAZZO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700080,
    codice_istat: 58008,
    codice_belfiore: "A370",
  },
  {
    id: 303,
    comune: "ARCISATE",
    sigla: "VA",
    codice_elettorale: 1030860040,
    codice_istat: 12004,
    codice_belfiore: "A371",
  },
  {
    id: 304,
    comune: "ARCO",
    sigla: "TN",
    codice_elettorale: 1040830060,
    codice_istat: 22006,
    codice_belfiore: "A372",
  },
  {
    id: 305,
    comune: "ARCOLA",
    sigla: "SP",
    codice_elettorale: 1070390020,
    codice_istat: 11002,
    codice_belfiore: "A373",
  },
  {
    id: 306,
    comune: "ARCOLE",
    sigla: "VR",
    codice_elettorale: 1050890040,
    codice_istat: 23004,
    codice_belfiore: "A374",
  },
  {
    id: 307,
    comune: "ARCONATE",
    sigla: "MI",
    codice_elettorale: 1030490070,
    codice_istat: 15007,
    codice_belfiore: "A375",
  },
  {
    id: 308,
    comune: "ARCORE",
    sigla: "MB",
    codice_elettorale: 1031040040,
    codice_istat: 108004,
    codice_belfiore: "A376",
  },
  {
    id: 309,
    comune: "ARCUGNANO",
    sigla: "VI",
    codice_elettorale: 1050900060,
    codice_istat: 24006,
    codice_belfiore: "A377",
  },
  {
    id: 310,
    comune: "ARDARA",
    sigla: "SS",
    codice_elettorale: 4200730050,
    codice_istat: 90005,
    codice_belfiore: "A379",
  },
  {
    id: 311,
    comune: "ARDAULI",
    sigla: "OR",
    codice_elettorale: 4200950070,
    codice_istat: 95007,
    codice_belfiore: "A380",
  },
  {
    id: 312,
    comune: "ARDEA",
    sigla: "RM",
    codice_elettorale: 2120700081,
    codice_istat: 58117,
    codice_belfiore: "M213",
  },
  {
    id: 313,
    comune: "ARDENNO",
    sigla: "SO",
    codice_elettorale: 1030770050,
    codice_istat: 14005,
    codice_belfiore: "A382",
  },
  {
    id: 314,
    comune: "ARDESIO",
    sigla: "BG",
    codice_elettorale: 1030120130,
    codice_istat: 16012,
    codice_belfiore: "A383",
  },
  {
    id: 315,
    comune: "ARDORE",
    sigla: "RC",
    codice_elettorale: 3180670050,
    codice_istat: 80005,
    codice_belfiore: "A385",
  },
  {
    id: 316,
    comune: "ARENA",
    sigla: "VV",
    codice_elettorale: 3181030020,
    codice_istat: 102002,
    codice_belfiore: "A386",
  },
  {
    id: 317,
    comune: "ARENA PO",
    sigla: "PV",
    codice_elettorale: 1030570050,
    codice_istat: 18005,
    codice_belfiore: "A387",
  },
  {
    id: 318,
    comune: "ARENZANO",
    sigla: "GE",
    codice_elettorale: 1070340010,
    codice_istat: 10001,
    codice_belfiore: "A388",
  },
  {
    id: 319,
    comune: "ARESE",
    sigla: "MI",
    codice_elettorale: 1030490090,
    codice_istat: 15009,
    codice_belfiore: "A389",
  },
  {
    id: 320,
    comune: "AREZZO",
    sigla: "AR",
    codice_elettorale: 2090050020,
    codice_istat: 51002,
    codice_belfiore: "A390",
  },
  {
    id: 321,
    comune: "ARGEGNO",
    sigla: "CO",
    codice_elettorale: 1030240110,
    codice_istat: 13011,
    codice_belfiore: "A391",
  },
  {
    id: 322,
    comune: "ARGELATO",
    sigla: "BO",
    codice_elettorale: 1080130020,
    codice_istat: 37002,
    codice_belfiore: "A392",
  },
  {
    id: 323,
    comune: "ARGENTA",
    sigla: "FE",
    codice_elettorale: 1080290010,
    codice_istat: 38001,
    codice_belfiore: "A393",
  },
  {
    id: 324,
    comune: "ARGENTERA",
    sigla: "CN",
    codice_elettorale: 1010270060,
    codice_istat: 4006,
    codice_belfiore: "A394",
  },
  {
    id: 325,
    comune: "ARGUELLO",
    sigla: "CN",
    codice_elettorale: 1010270070,
    codice_istat: 4007,
    codice_belfiore: "A396",
  },
  {
    id: 326,
    comune: "ARGUSTO",
    sigla: "CZ",
    codice_elettorale: 3180220070,
    codice_istat: 79007,
    codice_belfiore: "A397",
  },
  {
    id: 327,
    comune: "ARI",
    sigla: "CH",
    codice_elettorale: 3130230030,
    codice_istat: 69003,
    codice_belfiore: "A398",
  },
  {
    id: 328,
    comune: "ARIANO IRPINO",
    sigla: "AV",
    codice_elettorale: 3150080050,
    codice_istat: 64005,
    codice_belfiore: "A399",
  },
  {
    id: 329,
    comune: "ARIANO NEL POLESINE",
    sigla: "RO",
    codice_elettorale: 1050710020,
    codice_istat: 29002,
    codice_belfiore: "A400",
  },
  {
    id: 330,
    comune: "ARICCIA",
    sigla: "RM",
    codice_elettorale: 2120700090,
    codice_istat: 58009,
    codice_belfiore: "A401",
  },
  {
    id: 331,
    comune: "ARIELLI",
    sigla: "CH",
    codice_elettorale: 3130230040,
    codice_istat: 69004,
    codice_belfiore: "A402",
  },
  {
    id: 332,
    comune: "ARIENZO",
    sigla: "CE",
    codice_elettorale: 3150200040,
    codice_istat: 61004,
    codice_belfiore: "A403",
  },
  {
    id: 333,
    comune: "ARIGNANO",
    sigla: "TO",
    codice_elettorale: 1010810120,
    codice_istat: 1012,
    codice_belfiore: "A405",
  },
  {
    id: 334,
    comune: "ARITZO",
    sigla: "NU",
    codice_elettorale: 4200530010,
    codice_istat: 91001,
    codice_belfiore: "A407",
  },
  {
    id: 335,
    comune: "ARIZZANO",
    sigla: "VB",
    codice_elettorale: 1011020030,
    codice_istat: 103003,
    codice_belfiore: "A409",
  },
  {
    id: 336,
    comune: "ARLENA DI CASTRO",
    sigla: "VT",
    codice_elettorale: 2120910020,
    codice_istat: 56002,
    codice_belfiore: "A412",
  },
  {
    id: 337,
    comune: "ARLUNO",
    sigla: "MI",
    codice_elettorale: 1030490100,
    codice_istat: 15010,
    codice_belfiore: "A413",
  },
  {
    id: 338,
    comune: "ARMENO",
    sigla: "NO",
    codice_elettorale: 1010520060,
    codice_istat: 3006,
    codice_belfiore: "A414",
  },
  {
    id: 339,
    comune: "ARMENTO",
    sigla: "PZ",
    codice_elettorale: 3170640050,
    codice_istat: 76005,
    codice_belfiore: "A415",
  },
  {
    id: 340,
    comune: "ARMO",
    sigla: "IM",
    codice_elettorale: 1070370040,
    codice_istat: 8004,
    codice_belfiore: "A418",
  },
  {
    id: 341,
    comune: "ARMUNGIA",
    sigla: "SU",
    codice_elettorale: 4201110020,
    codice_istat: 111002,
    codice_belfiore: "A419",
  },
  {
    id: 342,
    comune: "ARNAD",
    sigla: "AO",
    codice_elettorale: 1020040040,
    codice_istat: 7004,
    codice_belfiore: "A424",
  },
  {
    id: 343,
    comune: "ARNARA",
    sigla: "FR",
    codice_elettorale: 2120330090,
    codice_istat: 60009,
    codice_belfiore: "A421",
  },
  {
    id: 344,
    comune: "ARNASCO",
    sigla: "SV",
    codice_elettorale: 1070740070,
    codice_istat: 9007,
    codice_belfiore: "A422",
  },
  {
    id: 345,
    comune: "ARNESANO",
    sigla: "LE",
    codice_elettorale: 3160410070,
    codice_istat: 75007,
    codice_belfiore: "A425",
  },
  {
    id: 346,
    comune: "AROLA",
    sigla: "VB",
    codice_elettorale: 1011020040,
    codice_istat: 103004,
    codice_belfiore: "A427",
  },
  {
    id: 347,
    comune: "ARONA",
    sigla: "NO",
    codice_elettorale: 1010520070,
    codice_istat: 3008,
    codice_belfiore: "A429",
  },
  {
    id: 348,
    comune: "AROSIO",
    sigla: "CO",
    codice_elettorale: 1030240120,
    codice_istat: 13012,
    codice_belfiore: "A430",
  },
  {
    id: 349,
    comune: "ARPAIA",
    sigla: "BN",
    codice_elettorale: 3150110050,
    codice_istat: 62005,
    codice_belfiore: "A431",
  },
  {
    id: 350,
    comune: "ARPAISE",
    sigla: "BN",
    codice_elettorale: 3150110060,
    codice_istat: 62006,
    codice_belfiore: "A432",
  },
  {
    id: 351,
    comune: "ARPINO",
    sigla: "FR",
    codice_elettorale: 2120330100,
    codice_istat: 60010,
    codice_belfiore: "A433",
  },
  {
    id: 352,
    comune: "ARQUA' PETRARCA",
    sigla: "PD",
    codice_elettorale: 1050540050,
    codice_istat: 28005,
    codice_belfiore: "A434",
  },
  {
    id: 353,
    comune: "ARQUA' POLESINE",
    sigla: "RO",
    codice_elettorale: 1050710030,
    codice_istat: 29003,
    codice_belfiore: "A435",
  },
  {
    id: 354,
    comune: "ARQUATA DEL TRONTO",
    sigla: "AP",
    codice_elettorale: 2110060060,
    codice_istat: 44006,
    codice_belfiore: "A437",
  },
  {
    id: 355,
    comune: "ARQUATA SCRIVIA",
    sigla: "AL",
    codice_elettorale: 1010020090,
    codice_istat: 6009,
    codice_belfiore: "A436",
  },
  {
    id: 356,
    comune: "ARRE",
    sigla: "PD",
    codice_elettorale: 1050540060,
    codice_istat: 28006,
    codice_belfiore: "A438",
  },
  {
    id: 357,
    comune: "ARRONE",
    sigla: "TR",
    codice_elettorale: 2100800050,
    codice_istat: 55005,
    codice_belfiore: "A439",
  },
  {
    id: 358,
    comune: "ARSAGO SEPRIO",
    sigla: "VA",
    codice_elettorale: 1030860050,
    codice_istat: 12005,
    codice_belfiore: "A441",
  },
  {
    id: 359,
    comune: "ARSIE'",
    sigla: "BL",
    codice_elettorale: 1050100040,
    codice_istat: 25004,
    codice_belfiore: "A443",
  },
  {
    id: 360,
    comune: "ARSIERO",
    sigla: "VI",
    codice_elettorale: 1050900070,
    codice_istat: 24007,
    codice_belfiore: "A444",
  },
  {
    id: 361,
    comune: "ARSITA",
    sigla: "TE",
    codice_elettorale: 3130790020,
    codice_istat: 67003,
    codice_belfiore: "A445",
  },
  {
    id: 362,
    comune: "ARSOLI",
    sigla: "RM",
    codice_elettorale: 2120700100,
    codice_istat: 58010,
    codice_belfiore: "A446",
  },
  {
    id: 363,
    comune: "ARTA TERME",
    sigla: "UD",
    codice_elettorale: 1060850050,
    codice_istat: 30005,
    codice_belfiore: "A447",
  },
  {
    id: 364,
    comune: "ARTEGNA",
    sigla: "UD",
    codice_elettorale: 1060850060,
    codice_istat: 30006,
    codice_belfiore: "A448",
  },
  {
    id: 365,
    comune: "ARTENA",
    sigla: "RM",
    codice_elettorale: 2120700110,
    codice_istat: 58011,
    codice_belfiore: "A449",
  },
  {
    id: 366,
    comune: "ARTOGNE",
    sigla: "BS",
    codice_elettorale: 1030150061,
    codice_istat: 17007,
    codice_belfiore: "A451",
  },
  {
    id: 367,
    comune: "ARVIER",
    sigla: "AO",
    codice_elettorale: 1020040050,
    codice_istat: 7005,
    codice_belfiore: "A452",
  },
  {
    id: 368,
    comune: "ARZACHENA",
    sigla: "SS",
    codice_elettorale: 4200730060,
    codice_istat: 90006,
    codice_belfiore: "A453",
  },
  {
    id: 369,
    comune: "ARZAGO D'ADDA",
    sigla: "BG",
    codice_elettorale: 1030120140,
    codice_istat: 16013,
    codice_belfiore: "A440",
  },
  {
    id: 370,
    comune: "ARZANA",
    sigla: "NU",
    codice_elettorale: 4200530020,
    codice_istat: 91002,
    codice_belfiore: "A454",
  },
  {
    id: 371,
    comune: "ARZANO",
    sigla: "NA",
    codice_elettorale: 3150510050,
    codice_istat: 63005,
    codice_belfiore: "A455",
  },
  {
    id: 372,
    comune: "ARZERGRANDE",
    sigla: "PD",
    codice_elettorale: 1050540070,
    codice_istat: 28007,
    codice_belfiore: "A458",
  },
  {
    id: 373,
    comune: "ARZIGNANO",
    sigla: "VI",
    codice_elettorale: 1050900080,
    codice_istat: 24008,
    codice_belfiore: "A459",
  },
  {
    id: 374,
    comune: "ASCEA",
    sigla: "SA",
    codice_elettorale: 3150720090,
    codice_istat: 65009,
    codice_belfiore: "A460",
  },
  {
    id: 375,
    comune: "ASCIANO",
    sigla: "SI",
    codice_elettorale: 2090750020,
    codice_istat: 52002,
    codice_belfiore: "A461",
  },
  {
    id: 376,
    comune: "ASCOLI PICENO",
    sigla: "AP",
    codice_elettorale: 2110060070,
    codice_istat: 44007,
    codice_belfiore: "A462",
  },
  {
    id: 377,
    comune: "ASCOLI SATRIANO",
    sigla: "FG",
    codice_elettorale: 3160310050,
    codice_istat: 71005,
    codice_belfiore: "A463",
  },
  {
    id: 378,
    comune: "ASCREA",
    sigla: "RI",
    codice_elettorale: 2120690040,
    codice_istat: 57004,
    codice_belfiore: "A464",
  },
  {
    id: 379,
    comune: "ASIAGO",
    sigla: "VI",
    codice_elettorale: 1050900090,
    codice_istat: 24009,
    codice_belfiore: "A465",
  },
  {
    id: 380,
    comune: "ASIGLIANO VENETO",
    sigla: "VI",
    codice_elettorale: 1050900100,
    codice_istat: 24010,
    codice_belfiore: "A467",
  },
  {
    id: 381,
    comune: "ASIGLIANO VERCELLESE",
    sigla: "VC",
    codice_elettorale: 1010880070,
    codice_istat: 2007,
    codice_belfiore: "A466",
  },
  {
    id: 382,
    comune: "ASOLA",
    sigla: "MN",
    codice_elettorale: 1030450020,
    codice_istat: 20002,
    codice_belfiore: "A470",
  },
  {
    id: 383,
    comune: "ASOLO",
    sigla: "TV",
    codice_elettorale: 1050840030,
    codice_istat: 26003,
    codice_belfiore: "A471",
  },
  {
    id: 384,
    comune: "ASSAGO",
    sigla: "MI",
    codice_elettorale: 1030490110,
    codice_istat: 15011,
    codice_belfiore: "A473",
  },
  {
    id: 385,
    comune: "ASSEMINI",
    sigla: "CA",
    codice_elettorale: 4200170030,
    codice_istat: 92003,
    codice_belfiore: "A474",
  },
  {
    id: 386,
    comune: "ASSISI",
    sigla: "PG",
    codice_elettorale: 2100580010,
    codice_istat: 54001,
    codice_belfiore: "A475",
  },
  {
    id: 387,
    comune: "ASSO",
    sigla: "CO",
    codice_elettorale: 1030240130,
    codice_istat: 13013,
    codice_belfiore: "A476",
  },
  {
    id: 388,
    comune: "ASSOLO",
    sigla: "OR",
    codice_elettorale: 4200950080,
    codice_istat: 95008,
    codice_belfiore: "A477",
  },
  {
    id: 389,
    comune: "ASSORO",
    sigla: "EN",
    codice_elettorale: 4190280030,
    codice_istat: 86003,
    codice_belfiore: "A478",
  },
  {
    id: 390,
    comune: "ASTI",
    sigla: "AT",
    codice_elettorale: 1010070050,
    codice_istat: 5005,
    codice_belfiore: "A479",
  },
  {
    id: 391,
    comune: "ASUNI",
    sigla: "OR",
    codice_elettorale: 4200950090,
    codice_istat: 95009,
    codice_belfiore: "A480",
  },
  {
    id: 392,
    comune: "ATELETA",
    sigla: "AQ",
    codice_elettorale: 3130380050,
    codice_istat: 66005,
    codice_belfiore: "A481",
  },
  {
    id: 393,
    comune: "ATELLA",
    sigla: "PZ",
    codice_elettorale: 3170640060,
    codice_istat: 76006,
    codice_belfiore: "A482",
  },
  {
    id: 394,
    comune: "ATENA LUCANA",
    sigla: "SA",
    codice_elettorale: 3150720100,
    codice_istat: 65010,
    codice_belfiore: "A484",
  },
  {
    id: 395,
    comune: "ATESSA",
    sigla: "CH",
    codice_elettorale: 3130230050,
    codice_istat: 69005,
    codice_belfiore: "A485",
  },
  {
    id: 396,
    comune: "ATINA",
    sigla: "FR",
    codice_elettorale: 2120330110,
    codice_istat: 60011,
    codice_belfiore: "A486",
  },
  {
    id: 397,
    comune: "ATRANI",
    sigla: "SA",
    codice_elettorale: 3150720110,
    codice_istat: 65011,
    codice_belfiore: "A487",
  },
  {
    id: 398,
    comune: "ATRI",
    sigla: "TE",
    codice_elettorale: 3130790030,
    codice_istat: 67004,
    codice_belfiore: "A488",
  },
  {
    id: 399,
    comune: "ATRIPALDA",
    sigla: "AV",
    codice_elettorale: 3150080060,
    codice_istat: 64006,
    codice_belfiore: "A489",
  },
  {
    id: 400,
    comune: "ATTIGLIANO",
    sigla: "TR",
    codice_elettorale: 2100800060,
    codice_istat: 55006,
    codice_belfiore: "A490",
  },
  {
    id: 401,
    comune: "ATTIMIS",
    sigla: "UD",
    codice_elettorale: 1060850070,
    codice_istat: 30007,
    codice_belfiore: "A491",
  },
  {
    id: 402,
    comune: "ATZARA",
    sigla: "NU",
    codice_elettorale: 4200530030,
    codice_istat: 91003,
    codice_belfiore: "A492",
  },
  {
    id: 403,
    comune: "AUGUSTA",
    sigla: "SR",
    codice_elettorale: 4190760010,
    codice_istat: 89001,
    codice_belfiore: "A494",
  },
  {
    id: 404,
    comune: "AULETTA",
    sigla: "SA",
    codice_elettorale: 3150720120,
    codice_istat: 65012,
    codice_belfiore: "A495",
  },
  {
    id: 405,
    comune: "AULLA",
    sigla: "MS",
    codice_elettorale: 2090460010,
    codice_istat: 45001,
    codice_belfiore: "A496",
  },
  {
    id: 406,
    comune: "AURANO",
    sigla: "VB",
    codice_elettorale: 1011020050,
    codice_istat: 103005,
    codice_belfiore: "A497",
  },
  {
    id: 407,
    comune: "AURIGO",
    sigla: "IM",
    codice_elettorale: 1070370041,
    codice_istat: 8005,
    codice_belfiore: "A499",
  },
  {
    id: 408,
    comune: "AURONZO DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100050,
    codice_istat: 25005,
    codice_belfiore: "A501",
  },
  {
    id: 409,
    comune: "AUSONIA",
    sigla: "FR",
    codice_elettorale: 2120330120,
    codice_istat: 60012,
    codice_belfiore: "A502",
  },
  {
    id: 410,
    comune: "AUSTIS",
    sigla: "NU",
    codice_elettorale: 4200530040,
    codice_istat: 91004,
    codice_belfiore: "A503",
  },
  {
    id: 411,
    comune: "AVEGNO",
    sigla: "GE",
    codice_elettorale: 1070340020,
    codice_istat: 10002,
    codice_belfiore: "A506",
  },
  {
    id: 412,
    comune: "AVELENGO/HAFLING",
    sigla: "BZ",
    codice_elettorale: 1040140021,
    codice_istat: 21005,
    codice_belfiore: "A507",
  },
  {
    id: 413,
    comune: "AVELLA",
    sigla: "AV",
    codice_elettorale: 3150080070,
    codice_istat: 64007,
    codice_belfiore: "A508",
  },
  {
    id: 414,
    comune: "AVELLINO",
    sigla: "AV",
    codice_elettorale: 3150080080,
    codice_istat: 64008,
    codice_belfiore: "A509",
  },
  {
    id: 415,
    comune: "AVERARA",
    sigla: "BG",
    codice_elettorale: 1030120150,
    codice_istat: 16014,
    codice_belfiore: "A511",
  },
  {
    id: 416,
    comune: "AVERSA",
    sigla: "CE",
    codice_elettorale: 3150200050,
    codice_istat: 61005,
    codice_belfiore: "A512",
  },
  {
    id: 417,
    comune: "AVETRANA",
    sigla: "TA",
    codice_elettorale: 3160780010,
    codice_istat: 73001,
    codice_belfiore: "A514",
  },
  {
    id: 418,
    comune: "AVEZZANO",
    sigla: "AQ",
    codice_elettorale: 3130380060,
    codice_istat: 66006,
    codice_belfiore: "A515",
  },
  {
    id: 419,
    comune: "AVIANO",
    sigla: "PN",
    codice_elettorale: 1060930040,
    codice_istat: 93004,
    codice_belfiore: "A516",
  },
  {
    id: 420,
    comune: "AVIATICO",
    sigla: "BG",
    codice_elettorale: 1030120160,
    codice_istat: 16015,
    codice_belfiore: "A517",
  },
  {
    id: 421,
    comune: "AVIGLIANA",
    sigla: "TO",
    codice_elettorale: 1010810130,
    codice_istat: 1013,
    codice_belfiore: "A518",
  },
  {
    id: 422,
    comune: "AVIGLIANO",
    sigla: "PZ",
    codice_elettorale: 3170640070,
    codice_istat: 76007,
    codice_belfiore: "A519",
  },
  {
    id: 423,
    comune: "AVIGLIANO UMBRO",
    sigla: "TR",
    codice_elettorale: 2100800061,
    codice_istat: 55033,
    codice_belfiore: "M258",
  },
  {
    id: 424,
    comune: "AVIO",
    sigla: "TN",
    codice_elettorale: 1040830070,
    codice_istat: 22007,
    codice_belfiore: "A520",
  },
  {
    id: 425,
    comune: "AVISE",
    sigla: "AO",
    codice_elettorale: 1020040060,
    codice_istat: 7006,
    codice_belfiore: "A521",
  },
  {
    id: 426,
    comune: "AVOLA",
    sigla: "SR",
    codice_elettorale: 4190760020,
    codice_istat: 89002,
    codice_belfiore: "A522",
  },
  {
    id: 427,
    comune: "AVOLASCA",
    sigla: "AL",
    codice_elettorale: 1010020100,
    codice_istat: 6010,
    codice_belfiore: "A523",
  },
  {
    id: 428,
    comune: "AYAS",
    sigla: "AO",
    codice_elettorale: 1020040070,
    codice_istat: 7007,
    codice_belfiore: "A094",
  },
  {
    id: 429,
    comune: "AYMAVILLES",
    sigla: "AO",
    codice_elettorale: 1020040080,
    codice_istat: 7008,
    codice_belfiore: "A108",
  },
  {
    id: 430,
    comune: "AZEGLIO",
    sigla: "TO",
    codice_elettorale: 1010810140,
    codice_istat: 1014,
    codice_belfiore: "A525",
  },
  {
    id: 431,
    comune: "AZZANELLO",
    sigla: "CR",
    codice_elettorale: 1030260040,
    codice_istat: 19004,
    codice_belfiore: "A526",
  },
  {
    id: 432,
    comune: "AZZANO D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070060,
    codice_istat: 5006,
    codice_belfiore: "A527",
  },
  {
    id: 433,
    comune: "AZZANO DECIMO",
    sigla: "PN",
    codice_elettorale: 1060930050,
    codice_istat: 93005,
    codice_belfiore: "A530",
  },
  {
    id: 434,
    comune: "AZZANO MELLA",
    sigla: "BS",
    codice_elettorale: 1030150062,
    codice_istat: 17008,
    codice_belfiore: "A529",
  },
  {
    id: 435,
    comune: "AZZANO SAN PAOLO",
    sigla: "BG",
    codice_elettorale: 1030120170,
    codice_istat: 16016,
    codice_belfiore: "A528",
  },
  {
    id: 436,
    comune: "AZZATE",
    sigla: "VA",
    codice_elettorale: 1030860060,
    codice_istat: 12006,
    codice_belfiore: "A531",
  },
  {
    id: 437,
    comune: "AZZIO",
    sigla: "VA",
    codice_elettorale: 1030860061,
    codice_istat: 12007,
    codice_belfiore: "A532",
  },
  {
    id: 438,
    comune: "AZZONE",
    sigla: "BG",
    codice_elettorale: 1030120180,
    codice_istat: 16017,
    codice_belfiore: "A533",
  },
  {
    id: 439,
    comune: "BACENO",
    sigla: "VB",
    codice_elettorale: 1011020060,
    codice_istat: 103006,
    codice_belfiore: "A534",
  },
  {
    id: 440,
    comune: "BACOLI",
    sigla: "NA",
    codice_elettorale: 3150510060,
    codice_istat: 63006,
    codice_belfiore: "A535",
  },
  {
    id: 441,
    comune: "BADALUCCO",
    sigla: "IM",
    codice_elettorale: 1070370050,
    codice_istat: 8006,
    codice_belfiore: "A536",
  },
  {
    id: 442,
    comune: "BADESI",
    sigla: "SS",
    codice_elettorale: 4200730061,
    codice_istat: 90081,
    codice_belfiore: "M214",
  },
  {
    id: 443,
    comune: "BADIA CALAVENA",
    sigla: "VR",
    codice_elettorale: 1050890050,
    codice_istat: 23005,
    codice_belfiore: "A540",
  },
  {
    id: 444,
    comune: "BADIA PAVESE",
    sigla: "PV",
    codice_elettorale: 1030570060,
    codice_istat: 18006,
    codice_belfiore: "A538",
  },
  {
    id: 445,
    comune: "BADIA POLESINE",
    sigla: "RO",
    codice_elettorale: 1050710040,
    codice_istat: 29004,
    codice_belfiore: "A539",
  },
  {
    id: 446,
    comune: "BADIA TEDALDA",
    sigla: "AR",
    codice_elettorale: 2090050030,
    codice_istat: 51003,
    codice_belfiore: "A541",
  },
  {
    id: 447,
    comune: "BADIA/ABTEI",
    sigla: "BZ",
    codice_elettorale: 1040140030,
    codice_istat: 21006,
    codice_belfiore: "A537",
  },
  {
    id: 448,
    comune: "BADOLATO",
    sigla: "CZ",
    codice_elettorale: 3180220080,
    codice_istat: 79008,
    codice_belfiore: "A542",
  },
  {
    id: 449,
    comune: "BAGALADI",
    sigla: "RC",
    codice_elettorale: 3180670060,
    codice_istat: 80006,
    codice_belfiore: "A544",
  },
  {
    id: 450,
    comune: "BAGHERIA",
    sigla: "PA",
    codice_elettorale: 4190550060,
    codice_istat: 82006,
    codice_belfiore: "A546",
  },
  {
    id: 451,
    comune: "BAGNACAVALLO",
    sigla: "RA",
    codice_elettorale: 1080660020,
    codice_istat: 39002,
    codice_belfiore: "A547",
  },
  {
    id: 452,
    comune: "BAGNARA CALABRA",
    sigla: "RC",
    codice_elettorale: 3180670070,
    codice_istat: 80007,
    codice_belfiore: "A552",
  },
  {
    id: 453,
    comune: "BAGNARA DI ROMAGNA",
    sigla: "RA",
    codice_elettorale: 1080660030,
    codice_istat: 39003,
    codice_belfiore: "A551",
  },
  {
    id: 454,
    comune: "BAGNARIA",
    sigla: "PV",
    codice_elettorale: 1030570070,
    codice_istat: 18007,
    codice_belfiore: "A550",
  },
  {
    id: 455,
    comune: "BAGNARIA ARSA",
    sigla: "UD",
    codice_elettorale: 1060850080,
    codice_istat: 30008,
    codice_belfiore: "A553",
  },
  {
    id: 456,
    comune: "BAGNASCO",
    sigla: "CN",
    codice_elettorale: 1010270080,
    codice_istat: 4008,
    codice_belfiore: "A555",
  },
  {
    id: 457,
    comune: "BAGNATICA",
    sigla: "BG",
    codice_elettorale: 1030120181,
    codice_istat: 16018,
    codice_belfiore: "A557",
  },
  {
    id: 458,
    comune: "BAGNI DI LUCCA",
    sigla: "LU",
    codice_elettorale: 2090430020,
    codice_istat: 46002,
    codice_belfiore: "A560",
  },
  {
    id: 459,
    comune: "BAGNO A RIPOLI",
    sigla: "FI",
    codice_elettorale: 2090300010,
    codice_istat: 48001,
    codice_belfiore: "A564",
  },
  {
    id: 460,
    comune: "BAGNO DI ROMAGNA",
    sigla: "FC",
    codice_elettorale: 1080320010,
    codice_istat: 40001,
    codice_belfiore: "A565",
  },
  {
    id: 461,
    comune: "BAGNOLI DEL TRIGNO",
    sigla: "IS",
    codice_elettorale: 3140940030,
    codice_istat: 94003,
    codice_belfiore: "A567",
  },
  {
    id: 462,
    comune: "BAGNOLI DI SOPRA",
    sigla: "PD",
    codice_elettorale: 1050540080,
    codice_istat: 28008,
    codice_belfiore: "A568",
  },
  {
    id: 463,
    comune: "BAGNOLI IRPINO",
    sigla: "AV",
    codice_elettorale: 3150080090,
    codice_istat: 64009,
    codice_belfiore: "A566",
  },
  {
    id: 464,
    comune: "BAGNOLO CREMASCO",
    sigla: "CR",
    codice_elettorale: 1030260050,
    codice_istat: 19005,
    codice_belfiore: "A570",
  },
  {
    id: 465,
    comune: "BAGNOLO DEL SALENTO",
    sigla: "LE",
    codice_elettorale: 3160410080,
    codice_istat: 75008,
    codice_belfiore: "A572",
  },
  {
    id: 466,
    comune: "BAGNOLO DI PO",
    sigla: "RO",
    codice_elettorale: 1050710050,
    codice_istat: 29005,
    codice_belfiore: "A574",
  },
  {
    id: 467,
    comune: "BAGNOLO IN PIANO",
    sigla: "RE",
    codice_elettorale: 1080680020,
    codice_istat: 35002,
    codice_belfiore: "A573",
  },
  {
    id: 468,
    comune: "BAGNOLO MELLA",
    sigla: "BS",
    codice_elettorale: 1030150070,
    codice_istat: 17009,
    codice_belfiore: "A569",
  },
  {
    id: 469,
    comune: "BAGNOLO PIEMONTE",
    sigla: "CN",
    codice_elettorale: 1010270090,
    codice_istat: 4009,
    codice_belfiore: "A571",
  },
  {
    id: 470,
    comune: "BAGNOLO SAN VITO",
    sigla: "MN",
    codice_elettorale: 1030450030,
    codice_istat: 20003,
    codice_belfiore: "A575",
  },
  {
    id: 471,
    comune: "BAGNONE",
    sigla: "MS",
    codice_elettorale: 2090460020,
    codice_istat: 45002,
    codice_belfiore: "A576",
  },
  {
    id: 472,
    comune: "BAGNOREGIO",
    sigla: "VT",
    codice_elettorale: 2120910030,
    codice_istat: 56003,
    codice_belfiore: "A577",
  },
  {
    id: 473,
    comune: "BAGOLINO",
    sigla: "BS",
    codice_elettorale: 1030150080,
    codice_istat: 17010,
    codice_belfiore: "A578",
  },
  {
    id: 474,
    comune: "BAIA E LATINA",
    sigla: "CE",
    codice_elettorale: 3150200060,
    codice_istat: 61006,
    codice_belfiore: "A579",
  },
  {
    id: 475,
    comune: "BAIANO",
    sigla: "AV",
    codice_elettorale: 3150080100,
    codice_istat: 64010,
    codice_belfiore: "A580",
  },
  {
    id: 476,
    comune: "BAIRO",
    sigla: "TO",
    codice_elettorale: 1010810150,
    codice_istat: 1015,
    codice_belfiore: "A584",
  },
  {
    id: 477,
    comune: "BAISO",
    sigla: "RE",
    codice_elettorale: 1080680030,
    codice_istat: 35003,
    codice_belfiore: "A586",
  },
  {
    id: 478,
    comune: "BAJARDO",
    sigla: "IM",
    codice_elettorale: 1070370060,
    codice_istat: 8007,
    codice_belfiore: "A581",
  },
  {
    id: 479,
    comune: "BALANGERO",
    sigla: "TO",
    codice_elettorale: 1010810160,
    codice_istat: 1016,
    codice_belfiore: "A587",
  },
  {
    id: 480,
    comune: "BALDICHIERI D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070070,
    codice_istat: 5007,
    codice_belfiore: "A588",
  },
  {
    id: 481,
    comune: "BALDISSERO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010810170,
    codice_istat: 1017,
    codice_belfiore: "A590",
  },
  {
    id: 482,
    comune: "BALDISSERO D'ALBA",
    sigla: "CN",
    codice_elettorale: 1010270100,
    codice_istat: 4010,
    codice_belfiore: "A589",
  },
  {
    id: 483,
    comune: "BALDISSERO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010810180,
    codice_istat: 1018,
    codice_belfiore: "A591",
  },
  {
    id: 484,
    comune: "BALESTRATE",
    sigla: "PA",
    codice_elettorale: 4190550070,
    codice_istat: 82007,
    codice_belfiore: "A592",
  },
  {
    id: 485,
    comune: "BALESTRINO",
    sigla: "SV",
    codice_elettorale: 1070740080,
    codice_istat: 9008,
    codice_belfiore: "A593",
  },
  {
    id: 486,
    comune: "BALLABIO",
    sigla: "LC",
    codice_elettorale: 1030980040,
    codice_istat: 97004,
    codice_belfiore: "A594",
  },
  {
    id: 487,
    comune: "BALLAO",
    sigla: "SU",
    codice_elettorale: 4201110030,
    codice_istat: 111003,
    codice_belfiore: "A597",
  },
  {
    id: 488,
    comune: "BALME",
    sigla: "TO",
    codice_elettorale: 1010810190,
    codice_istat: 1019,
    codice_belfiore: "A599",
  },
  {
    id: 489,
    comune: "BALMUCCIA",
    sigla: "VC",
    codice_elettorale: 1010880080,
    codice_istat: 2008,
    codice_belfiore: "A600",
  },
  {
    id: 490,
    comune: "BALOCCO",
    sigla: "VC",
    codice_elettorale: 1010880090,
    codice_istat: 2009,
    codice_belfiore: "A601",
  },
  {
    id: 491,
    comune: "BALSORANO",
    sigla: "AQ",
    codice_elettorale: 3130380070,
    codice_istat: 66007,
    codice_belfiore: "A603",
  },
  {
    id: 492,
    comune: "BALVANO",
    sigla: "PZ",
    codice_elettorale: 3170640080,
    codice_istat: 76008,
    codice_belfiore: "A604",
  },
  {
    id: 493,
    comune: "BALZOLA",
    sigla: "AL",
    codice_elettorale: 1010020110,
    codice_istat: 6011,
    codice_belfiore: "A605",
  },
  {
    id: 494,
    comune: "BANARI",
    sigla: "SS",
    codice_elettorale: 4200730070,
    codice_istat: 90007,
    codice_belfiore: "A606",
  },
  {
    id: 495,
    comune: "BANCHETTE",
    sigla: "TO",
    codice_elettorale: 1010810200,
    codice_istat: 1020,
    codice_belfiore: "A607",
  },
  {
    id: 496,
    comune: "BANNIO ANZINO",
    sigla: "VB",
    codice_elettorale: 1011020070,
    codice_istat: 103007,
    codice_belfiore: "A610",
  },
  {
    id: 497,
    comune: "BANZI",
    sigla: "PZ",
    codice_elettorale: 3170640090,
    codice_istat: 76009,
    codice_belfiore: "A612",
  },
  {
    id: 498,
    comune: "BAONE",
    sigla: "PD",
    codice_elettorale: 1050540090,
    codice_istat: 28009,
    codice_belfiore: "A613",
  },
  {
    id: 499,
    comune: "BARADILI",
    sigla: "OR",
    codice_elettorale: 4200950100,
    codice_istat: 95010,
    codice_belfiore: "A614",
  },
  {
    id: 500,
    comune: "BARAGIANO",
    sigla: "PZ",
    codice_elettorale: 3170640100,
    codice_istat: 76010,
    codice_belfiore: "A615",
  },
  {
    id: 501,
    comune: "BARANELLO",
    sigla: "CB",
    codice_elettorale: 3140190020,
    codice_istat: 70002,
    codice_belfiore: "A616",
  },
  {
    id: 502,
    comune: "BARANO D'ISCHIA",
    sigla: "NA",
    codice_elettorale: 3150510070,
    codice_istat: 63007,
    codice_belfiore: "A617",
  },
  {
    id: 503,
    comune: "BARANZATE",
    sigla: "MI",
    codice_elettorale: 1030490116,
    codice_istat: 15250,
    codice_belfiore: "A618",
  },
  {
    id: 504,
    comune: "BARASSO",
    sigla: "VA",
    codice_elettorale: 1030860062,
    codice_istat: 12008,
    codice_belfiore: "A619",
  },
  {
    id: 505,
    comune: "BARATILI SAN PIETRO",
    sigla: "OR",
    codice_elettorale: 4200950110,
    codice_istat: 95011,
    codice_belfiore: "A621",
  },
  {
    id: 506,
    comune: "BARBANIA",
    sigla: "TO",
    codice_elettorale: 1010810210,
    codice_istat: 1021,
    codice_belfiore: "A625",
  },
  {
    id: 507,
    comune: "BARBARA",
    sigla: "AN",
    codice_elettorale: 2110030040,
    codice_istat: 42004,
    codice_belfiore: "A626",
  },
  {
    id: 508,
    comune: "BARBARANO MOSSANO",
    sigla: "VI",
    codice_elettorale: 1050900112,
    codice_istat: 24124,
    codice_belfiore: "M401",
  },
  {
    id: 509,
    comune: "BARBARANO ROMANO",
    sigla: "VT",
    codice_elettorale: 2120910040,
    codice_istat: 56004,
    codice_belfiore: "A628",
  },
  {
    id: 510,
    comune: "BARBARESCO",
    sigla: "CN",
    codice_elettorale: 1010270110,
    codice_istat: 4011,
    codice_belfiore: "A629",
  },
  {
    id: 511,
    comune: "BARBARIGA",
    sigla: "BS",
    codice_elettorale: 1030150090,
    codice_istat: 17011,
    codice_belfiore: "A630",
  },
  {
    id: 512,
    comune: "BARBATA",
    sigla: "BG",
    codice_elettorale: 1030120190,
    codice_istat: 16019,
    codice_belfiore: "A631",
  },
  {
    id: 513,
    comune: "BARBERINO DI MUGELLO",
    sigla: "FI",
    codice_elettorale: 2090300020,
    codice_istat: 48002,
    codice_belfiore: "A632",
  },
  {
    id: 514,
    comune: "BARBERINO TAVARNELLE",
    sigla: "FI",
    codice_elettorale: 2090300035,
    codice_istat: 48054,
    codice_belfiore: "M408",
  },
  {
    id: 515,
    comune: "BARBIANELLO",
    sigla: "PV",
    codice_elettorale: 1030570080,
    codice_istat: 18008,
    codice_belfiore: "A634",
  },
  {
    id: 516,
    comune: "BARBIANO/BARBIAN",
    sigla: "BZ",
    codice_elettorale: 1040140040,
    codice_istat: 21007,
    codice_belfiore: "A635",
  },
  {
    id: 517,
    comune: "BARBONA",
    sigla: "PD",
    codice_elettorale: 1050540100,
    codice_istat: 28010,
    codice_belfiore: "A637",
  },
  {
    id: 518,
    comune: "BARCELLONA POZZO DI GOTTO",
    sigla: "ME",
    codice_elettorale: 4190480050,
    codice_istat: 83005,
    codice_belfiore: "A638",
  },
  {
    id: 519,
    comune: "BARCIS",
    sigla: "PN",
    codice_elettorale: 1060930060,
    codice_istat: 93006,
    codice_belfiore: "A640",
  },
  {
    id: 520,
    comune: "BARD",
    sigla: "AO",
    codice_elettorale: 1020040090,
    codice_istat: 7009,
    codice_belfiore: "A643",
  },
  {
    id: 521,
    comune: "BARDELLO CON MALGESSO E BREGANO",
    sigla: "VA",
    codice_elettorale: 1030860071,
    codice_istat: 12144,
    codice_belfiore: "M433",
  },
  {
    id: 522,
    comune: "BARDI",
    sigla: "PR",
    codice_elettorale: 1080560020,
    codice_istat: 34002,
    codice_belfiore: "A646",
  },
  {
    id: 523,
    comune: "BARDINETO",
    sigla: "SV",
    codice_elettorale: 1070740090,
    codice_istat: 9009,
    codice_belfiore: "A647",
  },
  {
    id: 524,
    comune: "BARDOLINO",
    sigla: "VR",
    codice_elettorale: 1050890060,
    codice_istat: 23006,
    codice_belfiore: "A650",
  },
  {
    id: 525,
    comune: "BARDONECCHIA",
    sigla: "TO",
    codice_elettorale: 1010810220,
    codice_istat: 1022,
    codice_belfiore: "A651",
  },
  {
    id: 526,
    comune: "BAREGGIO",
    sigla: "MI",
    codice_elettorale: 1030490120,
    codice_istat: 15012,
    codice_belfiore: "A652",
  },
  {
    id: 527,
    comune: "BARENGO",
    sigla: "NO",
    codice_elettorale: 1010520110,
    codice_istat: 3012,
    codice_belfiore: "A653",
  },
  {
    id: 528,
    comune: "BARESSA",
    sigla: "OR",
    codice_elettorale: 4200950120,
    codice_istat: 95012,
    codice_belfiore: "A655",
  },
  {
    id: 529,
    comune: "BARETE",
    sigla: "AQ",
    codice_elettorale: 3130380080,
    codice_istat: 66008,
    codice_belfiore: "A656",
  },
  {
    id: 530,
    comune: "BARGA",
    sigla: "LU",
    codice_elettorale: 2090430030,
    codice_istat: 46003,
    codice_belfiore: "A657",
  },
  {
    id: 531,
    comune: "BARGAGLI",
    sigla: "GE",
    codice_elettorale: 1070340030,
    codice_istat: 10003,
    codice_belfiore: "A658",
  },
  {
    id: 532,
    comune: "BARGE",
    sigla: "CN",
    codice_elettorale: 1010270120,
    codice_istat: 4012,
    codice_belfiore: "A660",
  },
  {
    id: 533,
    comune: "BARGHE",
    sigla: "BS",
    codice_elettorale: 1030150091,
    codice_istat: 17012,
    codice_belfiore: "A661",
  },
  {
    id: 534,
    comune: "BARI",
    sigla: "BA",
    codice_elettorale: 3160090060,
    codice_istat: 72006,
    codice_belfiore: "A662",
  },
  {
    id: 535,
    comune: "BARI SARDO",
    sigla: "NU",
    codice_elettorale: 4200530050,
    codice_istat: 91005,
    codice_belfiore: "A663",
  },
  {
    id: 536,
    comune: "BARIANO",
    sigla: "BG",
    codice_elettorale: 1030120200,
    codice_istat: 16020,
    codice_belfiore: "A664",
  },
  {
    id: 537,
    comune: "BARICELLA",
    sigla: "BO",
    codice_elettorale: 1080130030,
    codice_istat: 37003,
    codice_belfiore: "A665",
  },
  {
    id: 538,
    comune: "BARILE",
    sigla: "PZ",
    codice_elettorale: 3170640110,
    codice_istat: 76011,
    codice_belfiore: "A666",
  },
  {
    id: 539,
    comune: "BARISCIANO",
    sigla: "AQ",
    codice_elettorale: 3130380090,
    codice_istat: 66009,
    codice_belfiore: "A667",
  },
  {
    id: 540,
    comune: "BARLASSINA",
    sigla: "MB",
    codice_elettorale: 1031040050,
    codice_istat: 108005,
    codice_belfiore: "A668",
  },
  {
    id: 541,
    comune: "BARLETTA",
    sigla: "BT",
    codice_elettorale: 3161060020,
    codice_istat: 110002,
    codice_belfiore: "A669",
  },
  {
    id: 542,
    comune: "BARNI",
    sigla: "CO",
    codice_elettorale: 1030240150,
    codice_istat: 13015,
    codice_belfiore: "A670",
  },
  {
    id: 543,
    comune: "BAROLO",
    sigla: "CN",
    codice_elettorale: 1010270130,
    codice_istat: 4013,
    codice_belfiore: "A671",
  },
  {
    id: 544,
    comune: "BARONE CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010810230,
    codice_istat: 1023,
    codice_belfiore: "A673",
  },
  {
    id: 545,
    comune: "BARONISSI",
    sigla: "SA",
    codice_elettorale: 3150720130,
    codice_istat: 65013,
    codice_belfiore: "A674",
  },
  {
    id: 546,
    comune: "BARRAFRANCA",
    sigla: "EN",
    codice_elettorale: 4190280040,
    codice_istat: 86004,
    codice_belfiore: "A676",
  },
  {
    id: 547,
    comune: "BARRALI",
    sigla: "SU",
    codice_elettorale: 4201110040,
    codice_istat: 111004,
    codice_belfiore: "A677",
  },
  {
    id: 548,
    comune: "BARREA",
    sigla: "AQ",
    codice_elettorale: 3130380100,
    codice_istat: 66010,
    codice_belfiore: "A678",
  },
  {
    id: 549,
    comune: "BARUMINI",
    sigla: "SU",
    codice_elettorale: 4201110050,
    codice_istat: 111005,
    codice_belfiore: "A681",
  },
  {
    id: 550,
    comune: "BARZAGO",
    sigla: "LC",
    codice_elettorale: 1030980050,
    codice_istat: 97005,
    codice_belfiore: "A683",
  },
  {
    id: 551,
    comune: "BARZANA",
    sigla: "BG",
    codice_elettorale: 1030120210,
    codice_istat: 16021,
    codice_belfiore: "A684",
  },
  {
    id: 552,
    comune: "BARZANO'",
    sigla: "LC",
    codice_elettorale: 1030980060,
    codice_istat: 97006,
    codice_belfiore: "A686",
  },
  {
    id: 553,
    comune: "BARZIO",
    sigla: "LC",
    codice_elettorale: 1030980070,
    codice_istat: 97007,
    codice_belfiore: "A687",
  },
  {
    id: 554,
    comune: "BASALUZZO",
    sigla: "AL",
    codice_elettorale: 1010020120,
    codice_istat: 6012,
    codice_belfiore: "A689",
  },
  {
    id: 555,
    comune: "BASCAPE'",
    sigla: "PV",
    codice_elettorale: 1030570090,
    codice_istat: 18009,
    codice_belfiore: "A690",
  },
  {
    id: 556,
    comune: "BASCHI",
    sigla: "TR",
    codice_elettorale: 2100800070,
    codice_istat: 55007,
    codice_belfiore: "A691",
  },
  {
    id: 557,
    comune: "BASCIANO",
    sigla: "TE",
    codice_elettorale: 3130790040,
    codice_istat: 67005,
    codice_belfiore: "A692",
  },
  {
    id: 558,
    comune: "BASELGA DI PINE'",
    sigla: "TN",
    codice_elettorale: 1040830100,
    codice_istat: 22009,
    codice_belfiore: "A694",
  },
  {
    id: 559,
    comune: "BASELICE",
    sigla: "BN",
    codice_elettorale: 3150110070,
    codice_istat: 62007,
    codice_belfiore: "A696",
  },
  {
    id: 560,
    comune: "BASIANO",
    sigla: "MI",
    codice_elettorale: 1030490140,
    codice_istat: 15014,
    codice_belfiore: "A697",
  },
  {
    id: 561,
    comune: "BASICO'",
    sigla: "ME",
    codice_elettorale: 4190480060,
    codice_istat: 83006,
    codice_belfiore: "A698",
  },
  {
    id: 562,
    comune: "BASIGLIO",
    sigla: "MI",
    codice_elettorale: 1030490150,
    codice_istat: 15015,
    codice_belfiore: "A699",
  },
  {
    id: 563,
    comune: "BASILIANO",
    sigla: "UD",
    codice_elettorale: 1060850090,
    codice_istat: 30009,
    codice_belfiore: "A700",
  },
  {
    id: 564,
    comune: "BASSANO BRESCIANO",
    sigla: "BS",
    codice_elettorale: 1030150100,
    codice_istat: 17013,
    codice_belfiore: "A702",
  },
  {
    id: 565,
    comune: "BASSANO DEL GRAPPA",
    sigla: "VI",
    codice_elettorale: 1050900120,
    codice_istat: 24012,
    codice_belfiore: "A703",
  },
  {
    id: 566,
    comune: "BASSANO IN TEVERINA",
    sigla: "VT",
    codice_elettorale: 2120910051,
    codice_istat: 56006,
    codice_belfiore: "A706",
  },
  {
    id: 567,
    comune: "BASSANO ROMANO",
    sigla: "VT",
    codice_elettorale: 2120910052,
    codice_istat: 56005,
    codice_belfiore: "A704",
  },
  {
    id: 568,
    comune: "BASSIANO",
    sigla: "LT",
    codice_elettorale: 2120400020,
    codice_istat: 59002,
    codice_belfiore: "A707",
  },
  {
    id: 569,
    comune: "BASSIGNANA",
    sigla: "AL",
    codice_elettorale: 1010020130,
    codice_istat: 6013,
    codice_belfiore: "A708",
  },
  {
    id: 570,
    comune: "BASTIA MONDOVI'",
    sigla: "CN",
    codice_elettorale: 1010270140,
    codice_istat: 4014,
    codice_belfiore: "A709",
  },
  {
    id: 571,
    comune: "BASTIA UMBRA",
    sigla: "PG",
    codice_elettorale: 2100580020,
    codice_istat: 54002,
    codice_belfiore: "A710",
  },
  {
    id: 572,
    comune: "BASTIDA PANCARANA",
    sigla: "PV",
    codice_elettorale: 1030570110,
    codice_istat: 18011,
    codice_belfiore: "A712",
  },
  {
    id: 573,
    comune: "BASTIGLIA",
    sigla: "MO",
    codice_elettorale: 1080500010,
    codice_istat: 36001,
    codice_belfiore: "A713",
  },
  {
    id: 574,
    comune: "BATTAGLIA TERME",
    sigla: "PD",
    codice_elettorale: 1050540110,
    codice_istat: 28011,
    codice_belfiore: "A714",
  },
  {
    id: 575,
    comune: "BATTIFOLLO",
    sigla: "CN",
    codice_elettorale: 1010270150,
    codice_istat: 4015,
    codice_belfiore: "A716",
  },
  {
    id: 576,
    comune: "BATTIPAGLIA",
    sigla: "SA",
    codice_elettorale: 3150720140,
    codice_istat: 65014,
    codice_belfiore: "A717",
  },
  {
    id: 577,
    comune: "BATTUDA",
    sigla: "PV",
    codice_elettorale: 1030570120,
    codice_istat: 18012,
    codice_belfiore: "A718",
  },
  {
    id: 578,
    comune: "BAUCINA",
    sigla: "PA",
    codice_elettorale: 4190550080,
    codice_istat: 82008,
    codice_belfiore: "A719",
  },
  {
    id: 579,
    comune: "BAULADU",
    sigla: "OR",
    codice_elettorale: 4200950130,
    codice_istat: 95013,
    codice_belfiore: "A721",
  },
  {
    id: 580,
    comune: "BAUNEI",
    sigla: "NU",
    codice_elettorale: 4200530060,
    codice_istat: 91006,
    codice_belfiore: "A722",
  },
  {
    id: 581,
    comune: "BAVENO",
    sigla: "VB",
    codice_elettorale: 1011020080,
    codice_istat: 103008,
    codice_belfiore: "A725",
  },
  {
    id: 582,
    comune: "BEDERO VALCUVIA",
    sigla: "VA",
    codice_elettorale: 1030860080,
    codice_istat: 12010,
    codice_belfiore: "A728",
  },
  {
    id: 583,
    comune: "BEDIZZOLE",
    sigla: "BS",
    codice_elettorale: 1030150110,
    codice_istat: 17014,
    codice_belfiore: "A729",
  },
  {
    id: 584,
    comune: "BEDOLLO",
    sigla: "TN",
    codice_elettorale: 1040830110,
    codice_istat: 22011,
    codice_belfiore: "A730",
  },
  {
    id: 585,
    comune: "BEDONIA",
    sigla: "PR",
    codice_elettorale: 1080560030,
    codice_istat: 34003,
    codice_belfiore: "A731",
  },
  {
    id: 586,
    comune: "BEDULITA",
    sigla: "BG",
    codice_elettorale: 1030120220,
    codice_istat: 16022,
    codice_belfiore: "A732",
  },
  {
    id: 587,
    comune: "BEE",
    sigla: "VB",
    codice_elettorale: 1011020090,
    codice_istat: 103009,
    codice_belfiore: "A733",
  },
  {
    id: 588,
    comune: "BEINASCO",
    sigla: "TO",
    codice_elettorale: 1010810240,
    codice_istat: 1024,
    codice_belfiore: "A734",
  },
  {
    id: 589,
    comune: "BEINETTE",
    sigla: "CN",
    codice_elettorale: 1010270160,
    codice_istat: 4016,
    codice_belfiore: "A735",
  },
  {
    id: 590,
    comune: "BELCASTRO",
    sigla: "CZ",
    codice_elettorale: 3180220090,
    codice_istat: 79009,
    codice_belfiore: "A736",
  },
  {
    id: 591,
    comune: "BELFIORE",
    sigla: "VR",
    codice_elettorale: 1050890070,
    codice_istat: 23007,
    codice_belfiore: "A737",
  },
  {
    id: 592,
    comune: "BELFORTE ALL'ISAURO",
    sigla: "PU",
    codice_elettorale: 2110590050,
    codice_istat: 41005,
    codice_belfiore: "A740",
  },
  {
    id: 593,
    comune: "BELFORTE DEL CHIENTI",
    sigla: "MC",
    codice_elettorale: 2110440040,
    codice_istat: 43004,
    codice_belfiore: "A739",
  },
  {
    id: 594,
    comune: "BELFORTE MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020140,
    codice_istat: 6014,
    codice_belfiore: "A738",
  },
  {
    id: 595,
    comune: "BELGIOIOSO",
    sigla: "PV",
    codice_elettorale: 1030570130,
    codice_istat: 18013,
    codice_belfiore: "A741",
  },
  {
    id: 596,
    comune: "BELGIRATE",
    sigla: "VB",
    codice_elettorale: 1011020100,
    codice_istat: 103010,
    codice_belfiore: "A742",
  },
  {
    id: 597,
    comune: "BELLA",
    sigla: "PZ",
    codice_elettorale: 3170640120,
    codice_istat: 76012,
    codice_belfiore: "A743",
  },
  {
    id: 598,
    comune: "BELLAGIO",
    sigla: "CO",
    codice_elettorale: 1030240191,
    codice_istat: 13250,
    codice_belfiore: "M335",
  },
  {
    id: 599,
    comune: "BELLANO",
    sigla: "LC",
    codice_elettorale: 1030980080,
    codice_istat: 97008,
    codice_belfiore: "A745",
  },
  {
    id: 600,
    comune: "BELLANTE",
    sigla: "TE",
    codice_elettorale: 3130790050,
    codice_istat: 67006,
    codice_belfiore: "A746",
  },
  {
    id: 601,
    comune: "BELLARIA-IGEA MARINA",
    sigla: "RN",
    codice_elettorale: 1081010010,
    codice_istat: 99001,
    codice_belfiore: "A747",
  },
  {
    id: 602,
    comune: "BELLEGRA",
    sigla: "RM",
    codice_elettorale: 2120700120,
    codice_istat: 58012,
    codice_belfiore: "A749",
  },
  {
    id: 603,
    comune: "BELLINO",
    sigla: "CN",
    codice_elettorale: 1010270170,
    codice_istat: 4017,
    codice_belfiore: "A750",
  },
  {
    id: 604,
    comune: "BELLINZAGO LOMBARDO",
    sigla: "MI",
    codice_elettorale: 1030490160,
    codice_istat: 15016,
    codice_belfiore: "A751",
  },
  {
    id: 605,
    comune: "BELLINZAGO NOVARESE",
    sigla: "NO",
    codice_elettorale: 1010520150,
    codice_istat: 3016,
    codice_belfiore: "A752",
  },
  {
    id: 606,
    comune: "BELLIZZI",
    sigla: "SA",
    codice_elettorale: 3150720141,
    codice_istat: 65158,
    codice_belfiore: "M294",
  },
  {
    id: 607,
    comune: "BELLONA",
    sigla: "CE",
    codice_elettorale: 3150200070,
    codice_istat: 61007,
    codice_belfiore: "A755",
  },
  {
    id: 608,
    comune: "BELLOSGUARDO",
    sigla: "SA",
    codice_elettorale: 3150720150,
    codice_istat: 65015,
    codice_belfiore: "A756",
  },
  {
    id: 609,
    comune: "BELLUNO",
    sigla: "BL",
    codice_elettorale: 1050100060,
    codice_istat: 25006,
    codice_belfiore: "A757",
  },
  {
    id: 610,
    comune: "BELLUSCO",
    sigla: "MB",
    codice_elettorale: 1031040060,
    codice_istat: 108006,
    codice_belfiore: "A759",
  },
  {
    id: 611,
    comune: "BELMONTE CALABRO",
    sigla: "CS",
    codice_elettorale: 3180250130,
    codice_istat: 78013,
    codice_belfiore: "A762",
  },
  {
    id: 612,
    comune: "BELMONTE CASTELLO",
    sigla: "FR",
    codice_elettorale: 2120330130,
    codice_istat: 60013,
    codice_belfiore: "A763",
  },
  {
    id: 613,
    comune: "BELMONTE DEL SANNIO",
    sigla: "IS",
    codice_elettorale: 3140940040,
    codice_istat: 94004,
    codice_belfiore: "A761",
  },
  {
    id: 614,
    comune: "BELMONTE IN SABINA",
    sigla: "RI",
    codice_elettorale: 2120690050,
    codice_istat: 57005,
    codice_belfiore: "A765",
  },
  {
    id: 615,
    comune: "BELMONTE MEZZAGNO",
    sigla: "PA",
    codice_elettorale: 4190550090,
    codice_istat: 82009,
    codice_belfiore: "A764",
  },
  {
    id: 616,
    comune: "BELMONTE PICENO",
    sigla: "FM",
    codice_elettorale: 2111050030,
    codice_istat: 109003,
    codice_belfiore: "A760",
  },
  {
    id: 617,
    comune: "BELPASSO",
    sigla: "CT",
    codice_elettorale: 4190210070,
    codice_istat: 87007,
    codice_belfiore: "A766",
  },
  {
    id: 618,
    comune: "BELSITO",
    sigla: "CS",
    codice_elettorale: 3180250140,
    codice_istat: 78014,
    codice_belfiore: "A768",
  },
  {
    id: 619,
    comune: "BELVEDERE DI SPINELLO",
    sigla: "KR",
    codice_elettorale: 3180970010,
    codice_istat: 101001,
    codice_belfiore: "A772",
  },
  {
    id: 620,
    comune: "BELVEDERE LANGHE",
    sigla: "CN",
    codice_elettorale: 1010270180,
    codice_istat: 4018,
    codice_belfiore: "A774",
  },
  {
    id: 621,
    comune: "BELVEDERE MARITTIMO",
    sigla: "CS",
    codice_elettorale: 3180250150,
    codice_istat: 78015,
    codice_belfiore: "A773",
  },
  {
    id: 622,
    comune: "BELVEDERE OSTRENSE",
    sigla: "AN",
    codice_elettorale: 2110030050,
    codice_istat: 42005,
    codice_belfiore: "A769",
  },
  {
    id: 623,
    comune: "BELVEGLIO",
    sigla: "AT",
    codice_elettorale: 1010070080,
    codice_istat: 5008,
    codice_belfiore: "A770",
  },
  {
    id: 624,
    comune: "BELVI'",
    sigla: "NU",
    codice_elettorale: 4200530070,
    codice_istat: 91007,
    codice_belfiore: "A776",
  },
  {
    id: 625,
    comune: "BEMA",
    sigla: "SO",
    codice_elettorale: 1030770060,
    codice_istat: 14006,
    codice_belfiore: "A777",
  },
  {
    id: 626,
    comune: "BENE LARIO",
    sigla: "CO",
    codice_elettorale: 1030240210,
    codice_istat: 13021,
    codice_belfiore: "A778",
  },
  {
    id: 627,
    comune: "BENE VAGIENNA",
    sigla: "CN",
    codice_elettorale: 1010270190,
    codice_istat: 4019,
    codice_belfiore: "A779",
  },
  {
    id: 628,
    comune: "BENESTARE",
    sigla: "RC",
    codice_elettorale: 3180670080,
    codice_istat: 80008,
    codice_belfiore: "A780",
  },
  {
    id: 629,
    comune: "BENETUTTI",
    sigla: "SS",
    codice_elettorale: 4200730080,
    codice_istat: 90008,
    codice_belfiore: "A781",
  },
  {
    id: 630,
    comune: "BENEVELLO",
    sigla: "CN",
    codice_elettorale: 1010270200,
    codice_istat: 4020,
    codice_belfiore: "A782",
  },
  {
    id: 631,
    comune: "BENEVENTO",
    sigla: "BN",
    codice_elettorale: 3150110080,
    codice_istat: 62008,
    codice_belfiore: "A783",
  },
  {
    id: 632,
    comune: "BENNA",
    sigla: "BI",
    codice_elettorale: 1010960030,
    codice_istat: 96003,
    codice_belfiore: "A784",
  },
  {
    id: 633,
    comune: "BENTIVOGLIO",
    sigla: "BO",
    codice_elettorale: 1080130050,
    codice_istat: 37005,
    codice_belfiore: "A785",
  },
  {
    id: 634,
    comune: "BERBENNO",
    sigla: "BG",
    codice_elettorale: 1030120230,
    codice_istat: 16023,
    codice_belfiore: "A786",
  },
  {
    id: 635,
    comune: "BERBENNO DI VALTELLINA",
    sigla: "SO",
    codice_elettorale: 1030770070,
    codice_istat: 14007,
    codice_belfiore: "A787",
  },
  {
    id: 636,
    comune: "BERCETO",
    sigla: "PR",
    codice_elettorale: 1080560040,
    codice_istat: 34004,
    codice_belfiore: "A788",
  },
  {
    id: 637,
    comune: "BERCHIDDA",
    sigla: "SS",
    codice_elettorale: 4200730090,
    codice_istat: 90009,
    codice_belfiore: "A789",
  },
  {
    id: 638,
    comune: "BEREGAZZO CON FIGLIARO",
    sigla: "CO",
    codice_elettorale: 1030240220,
    codice_istat: 13022,
    codice_belfiore: "A791",
  },
  {
    id: 639,
    comune: "BEREGUARDO",
    sigla: "PV",
    codice_elettorale: 1030570140,
    codice_istat: 18014,
    codice_belfiore: "A792",
  },
  {
    id: 640,
    comune: "BERGAMASCO",
    sigla: "AL",
    codice_elettorale: 1010020150,
    codice_istat: 6015,
    codice_belfiore: "A793",
  },
  {
    id: 641,
    comune: "BERGAMO",
    sigla: "BG",
    codice_elettorale: 1030120240,
    codice_istat: 16024,
    codice_belfiore: "A794",
  },
  {
    id: 642,
    comune: "BERGANTINO",
    sigla: "RO",
    codice_elettorale: 1050710060,
    codice_istat: 29006,
    codice_belfiore: "A795",
  },
  {
    id: 643,
    comune: "BERGEGGI",
    sigla: "SV",
    codice_elettorale: 1070740100,
    codice_istat: 9010,
    codice_belfiore: "A796",
  },
  {
    id: 644,
    comune: "BERGOLO",
    sigla: "CN",
    codice_elettorale: 1010270210,
    codice_istat: 4021,
    codice_belfiore: "A798",
  },
  {
    id: 645,
    comune: "BERLINGO",
    sigla: "BS",
    codice_elettorale: 1030150120,
    codice_istat: 17015,
    codice_belfiore: "A799",
  },
  {
    id: 646,
    comune: "BERNALDA",
    sigla: "MT",
    codice_elettorale: 3170470030,
    codice_istat: 77003,
    codice_belfiore: "A801",
  },
  {
    id: 647,
    comune: "BERNAREGGIO",
    sigla: "MB",
    codice_elettorale: 1031040070,
    codice_istat: 108007,
    codice_belfiore: "A802",
  },
  {
    id: 648,
    comune: "BERNATE TICINO",
    sigla: "MI",
    codice_elettorale: 1030490190,
    codice_istat: 15019,
    codice_belfiore: "A804",
  },
  {
    id: 649,
    comune: "BERNEZZO",
    sigla: "CN",
    codice_elettorale: 1010270220,
    codice_istat: 4022,
    codice_belfiore: "A805",
  },
  {
    id: 650,
    comune: "BERTINORO",
    sigla: "FC",
    codice_elettorale: 1080320020,
    codice_istat: 40003,
    codice_belfiore: "A809",
  },
  {
    id: 651,
    comune: "BERTIOLO",
    sigla: "UD",
    codice_elettorale: 1060850100,
    codice_istat: 30010,
    codice_belfiore: "A810",
  },
  {
    id: 652,
    comune: "BERTONICO",
    sigla: "LO",
    codice_elettorale: 1030990020,
    codice_istat: 98002,
    codice_belfiore: "A811",
  },
  {
    id: 653,
    comune: "BERZANO DI SAN PIETRO",
    sigla: "AT",
    codice_elettorale: 1010070090,
    codice_istat: 5009,
    codice_belfiore: "A812",
  },
  {
    id: 654,
    comune: "BERZANO DI TORTONA",
    sigla: "AL",
    codice_elettorale: 1010020160,
    codice_istat: 6016,
    codice_belfiore: "A813",
  },
  {
    id: 655,
    comune: "BERZO DEMO",
    sigla: "BS",
    codice_elettorale: 1030150130,
    codice_istat: 17016,
    codice_belfiore: "A816",
  },
  {
    id: 656,
    comune: "BERZO INFERIORE",
    sigla: "BS",
    codice_elettorale: 1030150140,
    codice_istat: 17017,
    codice_belfiore: "A817",
  },
  {
    id: 657,
    comune: "BERZO SAN FERMO",
    sigla: "BG",
    codice_elettorale: 1030120250,
    codice_istat: 16025,
    codice_belfiore: "A815",
  },
  {
    id: 658,
    comune: "BESANA IN BRIANZA",
    sigla: "MB",
    codice_elettorale: 1031040080,
    codice_istat: 108008,
    codice_belfiore: "A818",
  },
  {
    id: 659,
    comune: "BESANO",
    sigla: "VA",
    codice_elettorale: 1030860081,
    codice_istat: 12011,
    codice_belfiore: "A819",
  },
  {
    id: 660,
    comune: "BESATE",
    sigla: "MI",
    codice_elettorale: 1030490220,
    codice_istat: 15022,
    codice_belfiore: "A820",
  },
  {
    id: 661,
    comune: "BESENELLO",
    sigla: "TN",
    codice_elettorale: 1040830130,
    codice_istat: 22013,
    codice_belfiore: "A821",
  },
  {
    id: 662,
    comune: "BESENZONE",
    sigla: "PC",
    codice_elettorale: 1080610030,
    codice_istat: 33003,
    codice_belfiore: "A823",
  },
  {
    id: 663,
    comune: "BESNATE",
    sigla: "VA",
    codice_elettorale: 1030860090,
    codice_istat: 12012,
    codice_belfiore: "A825",
  },
  {
    id: 664,
    comune: "BESOZZO",
    sigla: "VA",
    codice_elettorale: 1030860100,
    codice_istat: 12013,
    codice_belfiore: "A826",
  },
  {
    id: 665,
    comune: "BESSUDE",
    sigla: "SS",
    codice_elettorale: 4200730100,
    codice_istat: 90010,
    codice_belfiore: "A827",
  },
  {
    id: 666,
    comune: "BETTOLA",
    sigla: "PC",
    codice_elettorale: 1080610040,
    codice_istat: 33004,
    codice_belfiore: "A831",
  },
  {
    id: 667,
    comune: "BETTONA",
    sigla: "PG",
    codice_elettorale: 2100580030,
    codice_istat: 54003,
    codice_belfiore: "A832",
  },
  {
    id: 668,
    comune: "BEURA-CARDEZZA",
    sigla: "VB",
    codice_elettorale: 1011020110,
    codice_istat: 103011,
    codice_belfiore: "A834",
  },
  {
    id: 669,
    comune: "BEVAGNA",
    sigla: "PG",
    codice_elettorale: 2100580040,
    codice_istat: 54004,
    codice_belfiore: "A835",
  },
  {
    id: 670,
    comune: "BEVERINO",
    sigla: "SP",
    codice_elettorale: 1070390030,
    codice_istat: 11003,
    codice_belfiore: "A836",
  },
  {
    id: 671,
    comune: "BEVILACQUA",
    sigla: "VR",
    codice_elettorale: 1050890080,
    codice_istat: 23008,
    codice_belfiore: "A837",
  },
  {
    id: 672,
    comune: "BIANCAVILLA",
    sigla: "CT",
    codice_elettorale: 4190210080,
    codice_istat: 87008,
    codice_belfiore: "A841",
  },
  {
    id: 673,
    comune: "BIANCHI",
    sigla: "CS",
    codice_elettorale: 3180250160,
    codice_istat: 78016,
    codice_belfiore: "A842",
  },
  {
    id: 674,
    comune: "BIANCO",
    sigla: "RC",
    codice_elettorale: 3180670090,
    codice_istat: 80009,
    codice_belfiore: "A843",
  },
  {
    id: 675,
    comune: "BIANDRATE",
    sigla: "NO",
    codice_elettorale: 1010520170,
    codice_istat: 3018,
    codice_belfiore: "A844",
  },
  {
    id: 676,
    comune: "BIANDRONNO",
    sigla: "VA",
    codice_elettorale: 1030860110,
    codice_istat: 12014,
    codice_belfiore: "A845",
  },
  {
    id: 677,
    comune: "BIANZANO",
    sigla: "BG",
    codice_elettorale: 1030120251,
    codice_istat: 16026,
    codice_belfiore: "A846",
  },
  {
    id: 678,
    comune: "BIANZE'",
    sigla: "VC",
    codice_elettorale: 1010880110,
    codice_istat: 2011,
    codice_belfiore: "A847",
  },
  {
    id: 679,
    comune: "BIANZONE",
    sigla: "SO",
    codice_elettorale: 1030770080,
    codice_istat: 14008,
    codice_belfiore: "A848",
  },
  {
    id: 680,
    comune: "BIASSONO",
    sigla: "MB",
    codice_elettorale: 1031040090,
    codice_istat: 108009,
    codice_belfiore: "A849",
  },
  {
    id: 681,
    comune: "BIBBIANO",
    sigla: "RE",
    codice_elettorale: 1080680040,
    codice_istat: 35004,
    codice_belfiore: "A850",
  },
  {
    id: 682,
    comune: "BIBBIENA",
    sigla: "AR",
    codice_elettorale: 2090050040,
    codice_istat: 51004,
    codice_belfiore: "A851",
  },
  {
    id: 683,
    comune: "BIBBONA",
    sigla: "LI",
    codice_elettorale: 2090420010,
    codice_istat: 49001,
    codice_belfiore: "A852",
  },
  {
    id: 684,
    comune: "BIBIANA",
    sigla: "TO",
    codice_elettorale: 1010810250,
    codice_istat: 1025,
    codice_belfiore: "A853",
  },
  {
    id: 685,
    comune: "BICCARI",
    sigla: "FG",
    codice_elettorale: 3160310060,
    codice_istat: 71006,
    codice_belfiore: "A854",
  },
  {
    id: 686,
    comune: "BICINICCO",
    sigla: "UD",
    codice_elettorale: 1060850110,
    codice_istat: 30011,
    codice_belfiore: "A855",
  },
  {
    id: 687,
    comune: "BIDONI'",
    sigla: "OR",
    codice_elettorale: 4200950140,
    codice_istat: 95014,
    codice_belfiore: "A856",
  },
  {
    id: 688,
    comune: "BIELLA",
    sigla: "BI",
    codice_elettorale: 1010960040,
    codice_istat: 96004,
    codice_belfiore: "A859",
  },
  {
    id: 689,
    comune: "BIENNO",
    sigla: "BS",
    codice_elettorale: 1030150150,
    codice_istat: 17018,
    codice_belfiore: "A861",
  },
  {
    id: 690,
    comune: "BIENO",
    sigla: "TN",
    codice_elettorale: 1040830150,
    codice_istat: 22015,
    codice_belfiore: "A863",
  },
  {
    id: 691,
    comune: "BIENTINA",
    sigla: "PI",
    codice_elettorale: 2090620020,
    codice_istat: 50001,
    codice_belfiore: "A864",
  },
  {
    id: 692,
    comune: "BINAGO",
    sigla: "CO",
    codice_elettorale: 1030240230,
    codice_istat: 13023,
    codice_belfiore: "A870",
  },
  {
    id: 693,
    comune: "BINASCO",
    sigla: "MI",
    codice_elettorale: 1030490240,
    codice_istat: 15024,
    codice_belfiore: "A872",
  },
  {
    id: 694,
    comune: "BINETTO",
    sigla: "BA",
    codice_elettorale: 3160090080,
    codice_istat: 72008,
    codice_belfiore: "A874",
  },
  {
    id: 695,
    comune: "BIOGLIO",
    sigla: "BI",
    codice_elettorale: 1010960050,
    codice_istat: 96005,
    codice_belfiore: "A876",
  },
  {
    id: 696,
    comune: "BIONAZ",
    sigla: "AO",
    codice_elettorale: 1020040100,
    codice_istat: 7010,
    codice_belfiore: "A877",
  },
  {
    id: 697,
    comune: "BIONE",
    sigla: "BS",
    codice_elettorale: 1030150160,
    codice_istat: 17019,
    codice_belfiore: "A878",
  },
  {
    id: 698,
    comune: "BIRORI",
    sigla: "NU",
    codice_elettorale: 4200530080,
    codice_istat: 91008,
    codice_belfiore: "A880",
  },
  {
    id: 699,
    comune: "BISACCIA",
    sigla: "AV",
    codice_elettorale: 3150080110,
    codice_istat: 64011,
    codice_belfiore: "A881",
  },
  {
    id: 700,
    comune: "BISACQUINO",
    sigla: "PA",
    codice_elettorale: 4190550100,
    codice_istat: 82010,
    codice_belfiore: "A882",
  },
  {
    id: 701,
    comune: "BISCEGLIE",
    sigla: "BT",
    codice_elettorale: 3161060030,
    codice_istat: 110003,
    codice_belfiore: "A883",
  },
  {
    id: 702,
    comune: "BISEGNA",
    sigla: "AQ",
    codice_elettorale: 3130380110,
    codice_istat: 66011,
    codice_belfiore: "A884",
  },
  {
    id: 703,
    comune: "BISENTI",
    sigla: "TE",
    codice_elettorale: 3130790060,
    codice_istat: 67007,
    codice_belfiore: "A885",
  },
  {
    id: 704,
    comune: "BISIGNANO",
    sigla: "CS",
    codice_elettorale: 3180250170,
    codice_istat: 78017,
    codice_belfiore: "A887",
  },
  {
    id: 705,
    comune: "BISTAGNO",
    sigla: "AL",
    codice_elettorale: 1010020170,
    codice_istat: 6017,
    codice_belfiore: "A889",
  },
  {
    id: 706,
    comune: "BISUSCHIO",
    sigla: "VA",
    codice_elettorale: 1030860120,
    codice_istat: 12015,
    codice_belfiore: "A891",
  },
  {
    id: 707,
    comune: "BITETTO",
    sigla: "BA",
    codice_elettorale: 3160090100,
    codice_istat: 72010,
    codice_belfiore: "A892",
  },
  {
    id: 708,
    comune: "BITONTO",
    sigla: "BA",
    codice_elettorale: 3160090110,
    codice_istat: 72011,
    codice_belfiore: "A893",
  },
  {
    id: 709,
    comune: "BITRITTO",
    sigla: "BA",
    codice_elettorale: 3160090120,
    codice_istat: 72012,
    codice_belfiore: "A894",
  },
  {
    id: 710,
    comune: "BITTI",
    sigla: "NU",
    codice_elettorale: 4200530090,
    codice_istat: 91009,
    codice_belfiore: "A895",
  },
  {
    id: 711,
    comune: "BIVONA",
    sigla: "AG",
    codice_elettorale: 4190010040,
    codice_istat: 84004,
    codice_belfiore: "A896",
  },
  {
    id: 712,
    comune: "BIVONGI",
    sigla: "RC",
    codice_elettorale: 3180670100,
    codice_istat: 80010,
    codice_belfiore: "A897",
  },
  {
    id: 713,
    comune: "BIZZARONE",
    sigla: "CO",
    codice_elettorale: 1030240240,
    codice_istat: 13024,
    codice_belfiore: "A898",
  },
  {
    id: 714,
    comune: "BLEGGIO SUPERIORE",
    sigla: "TN",
    codice_elettorale: 1040830170,
    codice_istat: 22017,
    codice_belfiore: "A902",
  },
  {
    id: 715,
    comune: "BLELLO",
    sigla: "BG",
    codice_elettorale: 1030120260,
    codice_istat: 16027,
    codice_belfiore: "A903",
  },
  {
    id: 716,
    comune: "BLERA",
    sigla: "VT",
    codice_elettorale: 2120910060,
    codice_istat: 56007,
    codice_belfiore: "A857",
  },
  {
    id: 717,
    comune: "BLESSAGNO",
    sigla: "CO",
    codice_elettorale: 1030240250,
    codice_istat: 13025,
    codice_belfiore: "A904",
  },
  {
    id: 718,
    comune: "BLEVIO",
    sigla: "CO",
    codice_elettorale: 1030240260,
    codice_istat: 13026,
    codice_belfiore: "A905",
  },
  {
    id: 719,
    comune: "BLUFI",
    sigla: "PA",
    codice_elettorale: 4190550101,
    codice_istat: 82082,
    codice_belfiore: "M268",
  },
  {
    id: 720,
    comune: "BOARA PISANI",
    sigla: "PD",
    codice_elettorale: 1050540120,
    codice_istat: 28012,
    codice_belfiore: "A906",
  },
  {
    id: 721,
    comune: "BOBBIO",
    sigla: "PC",
    codice_elettorale: 1080610050,
    codice_istat: 33005,
    codice_belfiore: "A909",
  },
  {
    id: 722,
    comune: "BOBBIO PELLICE",
    sigla: "TO",
    codice_elettorale: 1010810260,
    codice_istat: 1026,
    codice_belfiore: "A910",
  },
  {
    id: 723,
    comune: "BOCA",
    sigla: "NO",
    codice_elettorale: 1010520180,
    codice_istat: 3019,
    codice_belfiore: "A911",
  },
  {
    id: 724,
    comune: "BOCCHIGLIERO",
    sigla: "CS",
    codice_elettorale: 3180250180,
    codice_istat: 78018,
    codice_belfiore: "A912",
  },
  {
    id: 725,
    comune: "BOCCIOLETO",
    sigla: "VC",
    codice_elettorale: 1010880140,
    codice_istat: 2014,
    codice_belfiore: "A914",
  },
  {
    id: 726,
    comune: "BOCENAGO",
    sigla: "TN",
    codice_elettorale: 1040830180,
    codice_istat: 22018,
    codice_belfiore: "A916",
  },
  {
    id: 727,
    comune: "BODIO LOMNAGO",
    sigla: "VA",
    codice_elettorale: 1030860130,
    codice_istat: 12016,
    codice_belfiore: "A918",
  },
  {
    id: 728,
    comune: "BOFFALORA D'ADDA",
    sigla: "LO",
    codice_elettorale: 1030990030,
    codice_istat: 98003,
    codice_belfiore: "A919",
  },
  {
    id: 729,
    comune: "BOFFALORA SOPRA TICINO",
    sigla: "MI",
    codice_elettorale: 1030490260,
    codice_istat: 15026,
    codice_belfiore: "A920",
  },
  {
    id: 730,
    comune: "BOGLIASCO",
    sigla: "GE",
    codice_elettorale: 1070340040,
    codice_istat: 10004,
    codice_belfiore: "A922",
  },
  {
    id: 731,
    comune: "BOGNANCO",
    sigla: "VB",
    codice_elettorale: 1011020120,
    codice_istat: 103012,
    codice_belfiore: "A925",
  },
  {
    id: 732,
    comune: "BOGOGNO",
    sigla: "NO",
    codice_elettorale: 1010520200,
    codice_istat: 3021,
    codice_belfiore: "A929",
  },
  {
    id: 733,
    comune: "BOISSANO",
    sigla: "SV",
    codice_elettorale: 1070740110,
    codice_istat: 9011,
    codice_belfiore: "A931",
  },
  {
    id: 734,
    comune: "BOJANO",
    sigla: "CB",
    codice_elettorale: 3140190030,
    codice_istat: 70003,
    codice_belfiore: "A930",
  },
  {
    id: 735,
    comune: "BOLANO",
    sigla: "SP",
    codice_elettorale: 1070390040,
    codice_istat: 11004,
    codice_belfiore: "A932",
  },
  {
    id: 736,
    comune: "BOLGARE",
    sigla: "BG",
    codice_elettorale: 1030120270,
    codice_istat: 16028,
    codice_belfiore: "A937",
  },
  {
    id: 737,
    comune: "BOLLATE",
    sigla: "MI",
    codice_elettorale: 1030490270,
    codice_istat: 15027,
    codice_belfiore: "A940",
  },
  {
    id: 738,
    comune: "BOLLENGO",
    sigla: "TO",
    codice_elettorale: 1010810270,
    codice_istat: 1027,
    codice_belfiore: "A941",
  },
  {
    id: 739,
    comune: "BOLOGNA",
    sigla: "BO",
    codice_elettorale: 1080130060,
    codice_istat: 37006,
    codice_belfiore: "A944",
  },
  {
    id: 740,
    comune: "BOLOGNANO",
    sigla: "PE",
    codice_elettorale: 3130600030,
    codice_istat: 68003,
    codice_belfiore: "A945",
  },
  {
    id: 741,
    comune: "BOLOGNETTA",
    sigla: "PA",
    codice_elettorale: 4190550110,
    codice_istat: 82011,
    codice_belfiore: "A946",
  },
  {
    id: 742,
    comune: "BOLOGNOLA",
    sigla: "MC",
    codice_elettorale: 2110440050,
    codice_istat: 43005,
    codice_belfiore: "A947",
  },
  {
    id: 743,
    comune: "BOLOTANA",
    sigla: "NU",
    codice_elettorale: 4200530100,
    codice_istat: 91010,
    codice_belfiore: "A948",
  },
  {
    id: 744,
    comune: "BOLSENA",
    sigla: "VT",
    codice_elettorale: 2120910070,
    codice_istat: 56008,
    codice_belfiore: "A949",
  },
  {
    id: 745,
    comune: "BOLTIERE",
    sigla: "BG",
    codice_elettorale: 1030120280,
    codice_istat: 16029,
    codice_belfiore: "A950",
  },
  {
    id: 746,
    comune: "BOLZANO NOVARESE",
    sigla: "NO",
    codice_elettorale: 1010520210,
    codice_istat: 3022,
    codice_belfiore: "A953",
  },
  {
    id: 747,
    comune: "BOLZANO VICENTINO",
    sigla: "VI",
    codice_elettorale: 1050900130,
    codice_istat: 24013,
    codice_belfiore: "A954",
  },
  {
    id: 748,
    comune: "BOLZANO/BOZEN",
    sigla: "BZ",
    codice_elettorale: 1040140050,
    codice_istat: 21008,
    codice_belfiore: "A952",
  },
  {
    id: 749,
    comune: "BOMARZO",
    sigla: "VT",
    codice_elettorale: 2120910080,
    codice_istat: 56009,
    codice_belfiore: "A955",
  },
  {
    id: 750,
    comune: "BOMBA",
    sigla: "CH",
    codice_elettorale: 3130230060,
    codice_istat: 69006,
    codice_belfiore: "A956",
  },
  {
    id: 751,
    comune: "BOMPENSIERE",
    sigla: "CL",
    codice_elettorale: 4190180020,
    codice_istat: 85002,
    codice_belfiore: "A957",
  },
  {
    id: 752,
    comune: "BOMPIETRO",
    sigla: "PA",
    codice_elettorale: 4190550120,
    codice_istat: 82012,
    codice_belfiore: "A958",
  },
  {
    id: 753,
    comune: "BOMPORTO",
    sigla: "MO",
    codice_elettorale: 1080500020,
    codice_istat: 36002,
    codice_belfiore: "A959",
  },
  {
    id: 754,
    comune: "BONARCADO",
    sigla: "OR",
    codice_elettorale: 4200950150,
    codice_istat: 95015,
    codice_belfiore: "A960",
  },
  {
    id: 755,
    comune: "BONASSOLA",
    sigla: "SP",
    codice_elettorale: 1070390050,
    codice_istat: 11005,
    codice_belfiore: "A961",
  },
  {
    id: 756,
    comune: "BONATE SOPRA",
    sigla: "BG",
    codice_elettorale: 1030120290,
    codice_istat: 16030,
    codice_belfiore: "A963",
  },
  {
    id: 757,
    comune: "BONATE SOTTO",
    sigla: "BG",
    codice_elettorale: 1030120300,
    codice_istat: 16031,
    codice_belfiore: "A962",
  },
  {
    id: 758,
    comune: "BONAVIGO",
    sigla: "VR",
    codice_elettorale: 1050890090,
    codice_istat: 23009,
    codice_belfiore: "A964",
  },
  {
    id: 759,
    comune: "BONDENO",
    sigla: "FE",
    codice_elettorale: 1080290030,
    codice_istat: 38003,
    codice_belfiore: "A965",
  },
  {
    id: 760,
    comune: "BONDONE",
    sigla: "TN",
    codice_elettorale: 1040830201,
    codice_istat: 22021,
    codice_belfiore: "A968",
  },
  {
    id: 761,
    comune: "BONEA",
    sigla: "BN",
    codice_elettorale: 3150110090,
    codice_istat: 62009,
    codice_belfiore: "A970",
  },
  {
    id: 762,
    comune: "BONEFRO",
    sigla: "CB",
    codice_elettorale: 3140190040,
    codice_istat: 70004,
    codice_belfiore: "A971",
  },
  {
    id: 763,
    comune: "BONEMERSE",
    sigla: "CR",
    codice_elettorale: 1030260060,
    codice_istat: 19006,
    codice_belfiore: "A972",
  },
  {
    id: 764,
    comune: "BONIFATI",
    sigla: "CS",
    codice_elettorale: 3180250190,
    codice_istat: 78019,
    codice_belfiore: "A973",
  },
  {
    id: 765,
    comune: "BONITO",
    sigla: "AV",
    codice_elettorale: 3150080120,
    codice_istat: 64012,
    codice_belfiore: "A975",
  },
  {
    id: 766,
    comune: "BONNANARO",
    sigla: "SS",
    codice_elettorale: 4200730110,
    codice_istat: 90011,
    codice_belfiore: "A976",
  },
  {
    id: 767,
    comune: "BONO",
    sigla: "SS",
    codice_elettorale: 4200730120,
    codice_istat: 90012,
    codice_belfiore: "A977",
  },
  {
    id: 768,
    comune: "BONORVA",
    sigla: "SS",
    codice_elettorale: 4200730130,
    codice_istat: 90013,
    codice_belfiore: "A978",
  },
  {
    id: 769,
    comune: "BONVICINO",
    sigla: "CN",
    codice_elettorale: 1010270230,
    codice_istat: 4023,
    codice_belfiore: "A979",
  },
  {
    id: 770,
    comune: "BORBONA",
    sigla: "RI",
    codice_elettorale: 2120690060,
    codice_istat: 57006,
    codice_belfiore: "A981",
  },
  {
    id: 771,
    comune: "BORCA DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100070,
    codice_istat: 25007,
    codice_belfiore: "A982",
  },
  {
    id: 772,
    comune: "BORDANO",
    sigla: "UD",
    codice_elettorale: 1060850120,
    codice_istat: 30012,
    codice_belfiore: "A983",
  },
  {
    id: 773,
    comune: "BORDIGHERA",
    sigla: "IM",
    codice_elettorale: 1070370070,
    codice_istat: 8008,
    codice_belfiore: "A984",
  },
  {
    id: 774,
    comune: "BORDOLANO",
    sigla: "CR",
    codice_elettorale: 1030260070,
    codice_istat: 19007,
    codice_belfiore: "A986",
  },
  {
    id: 775,
    comune: "BORE",
    sigla: "PR",
    codice_elettorale: 1080560050,
    codice_istat: 34005,
    codice_belfiore: "A987",
  },
  {
    id: 776,
    comune: "BORETTO",
    sigla: "RE",
    codice_elettorale: 1080680050,
    codice_istat: 35005,
    codice_belfiore: "A988",
  },
  {
    id: 777,
    comune: "BORGARELLO",
    sigla: "PV",
    codice_elettorale: 1030570141,
    codice_istat: 18015,
    codice_belfiore: "A989",
  },
  {
    id: 778,
    comune: "BORGARO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010810280,
    codice_istat: 1028,
    codice_belfiore: "A990",
  },
  {
    id: 779,
    comune: "BORGETTO",
    sigla: "PA",
    codice_elettorale: 4190550130,
    codice_istat: 82013,
    codice_belfiore: "A991",
  },
  {
    id: 780,
    comune: "BORGHETTO D'ARROSCIA",
    sigla: "IM",
    codice_elettorale: 1070370080,
    codice_istat: 8009,
    codice_belfiore: "A993",
  },
  {
    id: 781,
    comune: "BORGHETTO DI BORBERA",
    sigla: "AL",
    codice_elettorale: 1010020180,
    codice_istat: 6018,
    codice_belfiore: "A998",
  },
  {
    id: 782,
    comune: "BORGHETTO DI VARA",
    sigla: "SP",
    codice_elettorale: 1070390060,
    codice_istat: 11006,
    codice_belfiore: "A992",
  },
  {
    id: 783,
    comune: "BORGHETTO LODIGIANO",
    sigla: "LO",
    codice_elettorale: 1030990040,
    codice_istat: 98004,
    codice_belfiore: "A995",
  },
  {
    id: 784,
    comune: "BORGHETTO SANTO SPIRITO",
    sigla: "SV",
    codice_elettorale: 1070740120,
    codice_istat: 9012,
    codice_belfiore: "A999",
  },
  {
    id: 785,
    comune: "BORGHI",
    sigla: "FC",
    codice_elettorale: 1080320030,
    codice_istat: 40004,
    codice_belfiore: "B001",
  },
  {
    id: 786,
    comune: "BORGIA",
    sigla: "CZ",
    codice_elettorale: 3180220110,
    codice_istat: 79011,
    codice_belfiore: "B002",
  },
  {
    id: 787,
    comune: "BORGIALLO",
    sigla: "TO",
    codice_elettorale: 1010810290,
    codice_istat: 1029,
    codice_belfiore: "B003",
  },
  {
    id: 788,
    comune: "BORGIO VEREZZI",
    sigla: "SV",
    codice_elettorale: 1070740130,
    codice_istat: 9013,
    codice_belfiore: "B005",
  },
  {
    id: 789,
    comune: "BORGO A MOZZANO",
    sigla: "LU",
    codice_elettorale: 2090430040,
    codice_istat: 46004,
    codice_belfiore: "B007",
  },
  {
    id: 790,
    comune: "BORGO CHIESE",
    sigla: "TN",
    codice_elettorale: 1040830204,
    codice_istat: 22238,
    codice_belfiore: "M352",
  },
  {
    id: 791,
    comune: "BORGO D'ALE",
    sigla: "VC",
    codice_elettorale: 1010880150,
    codice_istat: 2015,
    codice_belfiore: "B009",
  },
  {
    id: 792,
    comune: "BORGO D'ANAUNIA",
    sigla: "TN",
    codice_elettorale: 1040830205,
    codice_istat: 22252,
    codice_belfiore: "M429",
  },
  {
    id: 793,
    comune: "BORGO DI TERZO",
    sigla: "BG",
    codice_elettorale: 1030120310,
    codice_istat: 16032,
    codice_belfiore: "B010",
  },
  {
    id: 794,
    comune: "BORGO LARES",
    sigla: "TN",
    codice_elettorale: 1040830207,
    codice_istat: 22239,
    codice_belfiore: "M353",
  },
  {
    id: 795,
    comune: "BORGO MANTOVANO",
    sigla: "MN",
    codice_elettorale: 1030450048,
    codice_istat: 20072,
    codice_belfiore: "M396",
  },
  {
    id: 796,
    comune: "BORGO PACE",
    sigla: "PU",
    codice_elettorale: 2110590060,
    codice_istat: 41006,
    codice_belfiore: "B026",
  },
  {
    id: 797,
    comune: "BORGO PRIOLO",
    sigla: "PV",
    codice_elettorale: 1030570150,
    codice_istat: 18016,
    codice_belfiore: "B028",
  },
  {
    id: 798,
    comune: "BORGO SAN DALMAZZO",
    sigla: "CN",
    codice_elettorale: 1010270250,
    codice_istat: 4025,
    codice_belfiore: "B033",
  },
  {
    id: 799,
    comune: "BORGO SAN GIACOMO",
    sigla: "BS",
    codice_elettorale: 1030150170,
    codice_istat: 17020,
    codice_belfiore: "B035",
  },
  {
    id: 800,
    comune: "BORGO SAN GIOVANNI",
    sigla: "LO",
    codice_elettorale: 1030990050,
    codice_istat: 98005,
    codice_belfiore: "B017",
  },
  {
    id: 801,
    comune: "BORGO SAN LORENZO",
    sigla: "FI",
    codice_elettorale: 2090300040,
    codice_istat: 48004,
    codice_belfiore: "B036",
  },
  {
    id: 802,
    comune: "BORGO SAN MARTINO",
    sigla: "AL",
    codice_elettorale: 1010020200,
    codice_istat: 6020,
    codice_belfiore: "B037",
  },
  {
    id: 803,
    comune: "BORGO SAN SIRO",
    sigla: "PV",
    codice_elettorale: 1030570170,
    codice_istat: 18018,
    codice_belfiore: "B038",
  },
  {
    id: 804,
    comune: "BORGO TICINO",
    sigla: "NO",
    codice_elettorale: 1010520240,
    codice_istat: 3025,
    codice_belfiore: "B043",
  },
  {
    id: 805,
    comune: "BORGO TOSSIGNANO",
    sigla: "BO",
    codice_elettorale: 1080130070,
    codice_istat: 37007,
    codice_belfiore: "B044",
  },
  {
    id: 806,
    comune: "BORGO VAL DI TARO",
    sigla: "PR",
    codice_elettorale: 1080560060,
    codice_istat: 34006,
    codice_belfiore: "B042",
  },
  {
    id: 807,
    comune: "BORGO VALBELLUNA",
    sigla: "BL",
    codice_elettorale: 1050100075,
    codice_istat: 25074,
    codice_belfiore: "M421",
  },
  {
    id: 808,
    comune: "BORGO VALSUGANA",
    sigla: "TN",
    codice_elettorale: 1040830210,
    codice_istat: 22022,
    codice_belfiore: "B006",
  },
  {
    id: 809,
    comune: "BORGO VELINO",
    sigla: "RI",
    codice_elettorale: 2120690071,
    codice_istat: 57008,
    codice_belfiore: "A996",
  },
  {
    id: 810,
    comune: "BORGO VENETO",
    sigla: "PD",
    codice_elettorale: 1050540125,
    codice_istat: 28107,
    codice_belfiore: "M402",
  },
  {
    id: 811,
    comune: "BORGO VERCELLI",
    sigla: "VC",
    codice_elettorale: 1010880170,
    codice_istat: 2017,
    codice_belfiore: "B046",
  },
  {
    id: 812,
    comune: "BORGO VIRGILIO",
    sigla: "MN",
    codice_elettorale: 1030450065,
    codice_istat: 20071,
    codice_belfiore: "M340",
  },
  {
    id: 813,
    comune: "BORGOCARBONARA",
    sigla: "MN",
    codice_elettorale: 1030450055,
    codice_istat: 20073,
    codice_belfiore: "M406",
  },
  {
    id: 814,
    comune: "BORGOFRANCO D'IVREA",
    sigla: "TO",
    codice_elettorale: 1010810300,
    codice_istat: 1030,
    codice_belfiore: "B015",
  },
  {
    id: 815,
    comune: "BORGOLAVEZZARO",
    sigla: "NO",
    codice_elettorale: 1010520220,
    codice_istat: 3023,
    codice_belfiore: "B016",
  },
  {
    id: 816,
    comune: "BORGOMALE",
    sigla: "CN",
    codice_elettorale: 1010270240,
    codice_istat: 4024,
    codice_belfiore: "B018",
  },
  {
    id: 817,
    comune: "BORGOMANERO",
    sigla: "NO",
    codice_elettorale: 1010520230,
    codice_istat: 3024,
    codice_belfiore: "B019",
  },
  {
    id: 818,
    comune: "BORGOMARO",
    sigla: "IM",
    codice_elettorale: 1070370090,
    codice_istat: 8010,
    codice_belfiore: "B020",
  },
  {
    id: 819,
    comune: "BORGOMASINO",
    sigla: "TO",
    codice_elettorale: 1010810310,
    codice_istat: 1031,
    codice_belfiore: "B021",
  },
  {
    id: 820,
    comune: "BORGOMEZZAVALLE",
    sigla: "VB",
    codice_elettorale: 1011020125,
    codice_istat: 103078,
    codice_belfiore: "M370",
  },
  {
    id: 821,
    comune: "BORGONE SUSA",
    sigla: "TO",
    codice_elettorale: 1010810320,
    codice_istat: 1032,
    codice_belfiore: "B024",
  },
  {
    id: 822,
    comune: "BORGONOVO VAL TIDONE",
    sigla: "PC",
    codice_elettorale: 1080610060,
    codice_istat: 33006,
    codice_belfiore: "B025",
  },
  {
    id: 823,
    comune: "BORGORATTO ALESSANDRINO",
    sigla: "AL",
    codice_elettorale: 1010020190,
    codice_istat: 6019,
    codice_belfiore: "B029",
  },
  {
    id: 824,
    comune: "BORGORATTO MORMOROLO",
    sigla: "PV",
    codice_elettorale: 1030570160,
    codice_istat: 18017,
    codice_belfiore: "B030",
  },
  {
    id: 825,
    comune: "BORGORICCO",
    sigla: "PD",
    codice_elettorale: 1050540130,
    codice_istat: 28013,
    codice_belfiore: "B031",
  },
  {
    id: 826,
    comune: "BORGOROSE",
    sigla: "RI",
    codice_elettorale: 2120690072,
    codice_istat: 57007,
    codice_belfiore: "B008",
  },
  {
    id: 827,
    comune: "BORGOSATOLLO",
    sigla: "BS",
    codice_elettorale: 1030150180,
    codice_istat: 17021,
    codice_belfiore: "B040",
  },
  {
    id: 828,
    comune: "BORGOSESIA",
    sigla: "VC",
    codice_elettorale: 1010880160,
    codice_istat: 2016,
    codice_belfiore: "B041",
  },
  {
    id: 829,
    comune: "BORMIDA",
    sigla: "SV",
    codice_elettorale: 1070740140,
    codice_istat: 9014,
    codice_belfiore: "B048",
  },
  {
    id: 830,
    comune: "BORMIO",
    sigla: "SO",
    codice_elettorale: 1030770090,
    codice_istat: 14009,
    codice_belfiore: "B049",
  },
  {
    id: 831,
    comune: "BORNASCO",
    sigla: "PV",
    codice_elettorale: 1030570180,
    codice_istat: 18019,
    codice_belfiore: "B051",
  },
  {
    id: 832,
    comune: "BORNO",
    sigla: "BS",
    codice_elettorale: 1030150190,
    codice_istat: 17022,
    codice_belfiore: "B054",
  },
  {
    id: 833,
    comune: "BORONEDDU",
    sigla: "OR",
    codice_elettorale: 4200950160,
    codice_istat: 95016,
    codice_belfiore: "B055",
  },
  {
    id: 834,
    comune: "BORORE",
    sigla: "NU",
    codice_elettorale: 4200530110,
    codice_istat: 91011,
    codice_belfiore: "B056",
  },
  {
    id: 835,
    comune: "BORRELLO",
    sigla: "CH",
    codice_elettorale: 3130230070,
    codice_istat: 69007,
    codice_belfiore: "B057",
  },
  {
    id: 836,
    comune: "BORRIANA",
    sigla: "BI",
    codice_elettorale: 1010960060,
    codice_istat: 96006,
    codice_belfiore: "B058",
  },
  {
    id: 837,
    comune: "BORSO DEL GRAPPA",
    sigla: "TV",
    codice_elettorale: 1050840040,
    codice_istat: 26004,
    codice_belfiore: "B061",
  },
  {
    id: 838,
    comune: "BORTIGALI",
    sigla: "NU",
    codice_elettorale: 4200530120,
    codice_istat: 91012,
    codice_belfiore: "B062",
  },
  {
    id: 839,
    comune: "BORTIGIADAS",
    sigla: "SS",
    codice_elettorale: 4200730140,
    codice_istat: 90014,
    codice_belfiore: "B063",
  },
  {
    id: 840,
    comune: "BORUTTA",
    sigla: "SS",
    codice_elettorale: 4200730150,
    codice_istat: 90015,
    codice_belfiore: "B064",
  },
  {
    id: 841,
    comune: "BORZONASCA",
    sigla: "GE",
    codice_elettorale: 1070340050,
    codice_istat: 10005,
    codice_belfiore: "B067",
  },
  {
    id: 842,
    comune: "BOSA",
    sigla: "OR",
    codice_elettorale: 4200950166,
    codice_istat: 95079,
    codice_belfiore: "B068",
  },
  {
    id: 843,
    comune: "BOSARO",
    sigla: "RO",
    codice_elettorale: 1050710070,
    codice_istat: 29007,
    codice_belfiore: "B069",
  },
  {
    id: 844,
    comune: "BOSCHI SANT'ANNA",
    sigla: "VR",
    codice_elettorale: 1050890100,
    codice_istat: 23010,
    codice_belfiore: "B070",
  },
  {
    id: 845,
    comune: "BOSCO CHIESANUOVA",
    sigla: "VR",
    codice_elettorale: 1050890110,
    codice_istat: 23011,
    codice_belfiore: "B073",
  },
  {
    id: 846,
    comune: "BOSCO MARENGO",
    sigla: "AL",
    codice_elettorale: 1010020210,
    codice_istat: 6021,
    codice_belfiore: "B071",
  },
  {
    id: 847,
    comune: "BOSCONERO",
    sigla: "TO",
    codice_elettorale: 1010810330,
    codice_istat: 1033,
    codice_belfiore: "B075",
  },
  {
    id: 848,
    comune: "BOSCOREALE",
    sigla: "NA",
    codice_elettorale: 3150510080,
    codice_istat: 63008,
    codice_belfiore: "B076",
  },
  {
    id: 849,
    comune: "BOSCOTRECASE",
    sigla: "NA",
    codice_elettorale: 3150510090,
    codice_istat: 63009,
    codice_belfiore: "B077",
  },
  {
    id: 850,
    comune: "BOSIA",
    sigla: "CN",
    codice_elettorale: 1010270260,
    codice_istat: 4026,
    codice_belfiore: "B079",
  },
  {
    id: 851,
    comune: "BOSIO",
    sigla: "AL",
    codice_elettorale: 1010020220,
    codice_istat: 6022,
    codice_belfiore: "B080",
  },
  {
    id: 852,
    comune: "BOSISIO PARINI",
    sigla: "LC",
    codice_elettorale: 1030980090,
    codice_istat: 97009,
    codice_belfiore: "B081",
  },
  {
    id: 853,
    comune: "BOSNASCO",
    sigla: "PV",
    codice_elettorale: 1030570190,
    codice_istat: 18020,
    codice_belfiore: "B082",
  },
  {
    id: 854,
    comune: "BOSSICO",
    sigla: "BG",
    codice_elettorale: 1030120320,
    codice_istat: 16033,
    codice_belfiore: "B083",
  },
  {
    id: 855,
    comune: "BOSSOLASCO",
    sigla: "CN",
    codice_elettorale: 1010270270,
    codice_istat: 4027,
    codice_belfiore: "B084",
  },
  {
    id: 856,
    comune: "BOTRICELLO",
    sigla: "CZ",
    codice_elettorale: 3180220111,
    codice_istat: 79012,
    codice_belfiore: "B085",
  },
  {
    id: 857,
    comune: "BOTRUGNO",
    sigla: "LE",
    codice_elettorale: 3160410081,
    codice_istat: 75009,
    codice_belfiore: "B086",
  },
  {
    id: 858,
    comune: "BOTTANUCO",
    sigla: "BG",
    codice_elettorale: 1030120330,
    codice_istat: 16034,
    codice_belfiore: "B088",
  },
  {
    id: 859,
    comune: "BOTTICINO",
    sigla: "BS",
    codice_elettorale: 1030150200,
    codice_istat: 17023,
    codice_belfiore: "B091",
  },
  {
    id: 860,
    comune: "BOTTIDDA",
    sigla: "SS",
    codice_elettorale: 4200730160,
    codice_istat: 90016,
    codice_belfiore: "B094",
  },
  {
    id: 861,
    comune: "BOVA",
    sigla: "RC",
    codice_elettorale: 3180670110,
    codice_istat: 80011,
    codice_belfiore: "B097",
  },
  {
    id: 862,
    comune: "BOVA MARINA",
    sigla: "RC",
    codice_elettorale: 3180670130,
    codice_istat: 80013,
    codice_belfiore: "B099",
  },
  {
    id: 863,
    comune: "BOVALINO",
    sigla: "RC",
    codice_elettorale: 3180670120,
    codice_istat: 80012,
    codice_belfiore: "B098",
  },
  {
    id: 864,
    comune: "BOVEGNO",
    sigla: "BS",
    codice_elettorale: 1030150210,
    codice_istat: 17024,
    codice_belfiore: "B100",
  },
  {
    id: 865,
    comune: "BOVES",
    sigla: "CN",
    codice_elettorale: 1010270280,
    codice_istat: 4028,
    codice_belfiore: "B101",
  },
  {
    id: 866,
    comune: "BOVEZZO",
    sigla: "BS",
    codice_elettorale: 1030150220,
    codice_istat: 17025,
    codice_belfiore: "B102",
  },
  {
    id: 867,
    comune: "BOVILLE ERNICA",
    sigla: "FR",
    codice_elettorale: 2120330140,
    codice_istat: 60014,
    codice_belfiore: "A720",
  },
  {
    id: 868,
    comune: "BOVINO",
    sigla: "FG",
    codice_elettorale: 3160310070,
    codice_istat: 71007,
    codice_belfiore: "B104",
  },
  {
    id: 869,
    comune: "BOVISIO-MASCIAGO",
    sigla: "MB",
    codice_elettorale: 1031040100,
    codice_istat: 108010,
    codice_belfiore: "B105",
  },
  {
    id: 870,
    comune: "BOVOLENTA",
    sigla: "PD",
    codice_elettorale: 1050540140,
    codice_istat: 28014,
    codice_belfiore: "B106",
  },
  {
    id: 871,
    comune: "BOVOLONE",
    sigla: "VR",
    codice_elettorale: 1050890120,
    codice_istat: 23012,
    codice_belfiore: "B107",
  },
  {
    id: 872,
    comune: "BOZZOLE",
    sigla: "AL",
    codice_elettorale: 1010020230,
    codice_istat: 6023,
    codice_belfiore: "B109",
  },
  {
    id: 873,
    comune: "BOZZOLO",
    sigla: "MN",
    codice_elettorale: 1030450070,
    codice_istat: 20007,
    codice_belfiore: "B110",
  },
  {
    id: 874,
    comune: "BRA",
    sigla: "CN",
    codice_elettorale: 1010270290,
    codice_istat: 4029,
    codice_belfiore: "B111",
  },
  {
    id: 875,
    comune: "BRACCA",
    sigla: "BG",
    codice_elettorale: 1030120331,
    codice_istat: 16035,
    codice_belfiore: "B112",
  },
  {
    id: 876,
    comune: "BRACCIANO",
    sigla: "RM",
    codice_elettorale: 2120700130,
    codice_istat: 58013,
    codice_belfiore: "B114",
  },
  {
    id: 877,
    comune: "BRACIGLIANO",
    sigla: "SA",
    codice_elettorale: 3150720160,
    codice_istat: 65016,
    codice_belfiore: "B115",
  },
  {
    id: 878,
    comune: "BRAIES/PRAGS",
    sigla: "BZ",
    codice_elettorale: 1040140060,
    codice_istat: 21009,
    codice_belfiore: "B116",
  },
  {
    id: 879,
    comune: "BRALLO DI PREGOLA",
    sigla: "PV",
    codice_elettorale: 1030570191,
    codice_istat: 18021,
    codice_belfiore: "B117",
  },
  {
    id: 880,
    comune: "BRANCALEONE",
    sigla: "RC",
    codice_elettorale: 3180670140,
    codice_istat: 80014,
    codice_belfiore: "B118",
  },
  {
    id: 881,
    comune: "BRANDICO",
    sigla: "BS",
    codice_elettorale: 1030150230,
    codice_istat: 17026,
    codice_belfiore: "B120",
  },
  {
    id: 882,
    comune: "BRANDIZZO",
    sigla: "TO",
    codice_elettorale: 1010810340,
    codice_istat: 1034,
    codice_belfiore: "B121",
  },
  {
    id: 883,
    comune: "BRANZI",
    sigla: "BG",
    codice_elettorale: 1030120350,
    codice_istat: 16036,
    codice_belfiore: "B123",
  },
  {
    id: 884,
    comune: "BRAONE",
    sigla: "BS",
    codice_elettorale: 1030150240,
    codice_istat: 17027,
    codice_belfiore: "B124",
  },
  {
    id: 885,
    comune: "BREBBIA",
    sigla: "VA",
    codice_elettorale: 1030860140,
    codice_istat: 12017,
    codice_belfiore: "B126",
  },
  {
    id: 886,
    comune: "BREDA DI PIAVE",
    sigla: "TV",
    codice_elettorale: 1050840050,
    codice_istat: 26005,
    codice_belfiore: "B128",
  },
  {
    id: 887,
    comune: "BREGANZE",
    sigla: "VI",
    codice_elettorale: 1050900140,
    codice_istat: 24014,
    codice_belfiore: "B132",
  },
  {
    id: 888,
    comune: "BREGNANO",
    sigla: "CO",
    codice_elettorale: 1030240280,
    codice_istat: 13028,
    codice_belfiore: "B134",
  },
  {
    id: 889,
    comune: "BREMBATE",
    sigla: "BG",
    codice_elettorale: 1030120360,
    codice_istat: 16037,
    codice_belfiore: "B137",
  },
  {
    id: 890,
    comune: "BREMBATE DI SOPRA",
    sigla: "BG",
    codice_elettorale: 1030120370,
    codice_istat: 16038,
    codice_belfiore: "B138",
  },
  {
    id: 891,
    comune: "BREMBIO",
    sigla: "LO",
    codice_elettorale: 1030990060,
    codice_istat: 98006,
    codice_belfiore: "B141",
  },
  {
    id: 892,
    comune: "BREME",
    sigla: "PV",
    codice_elettorale: 1030570200,
    codice_istat: 18022,
    codice_belfiore: "B142",
  },
  {
    id: 893,
    comune: "BRENDOLA",
    sigla: "VI",
    codice_elettorale: 1050900150,
    codice_istat: 24015,
    codice_belfiore: "B143",
  },
  {
    id: 894,
    comune: "BRENNA",
    sigla: "CO",
    codice_elettorale: 1030240290,
    codice_istat: 13029,
    codice_belfiore: "B144",
  },
  {
    id: 895,
    comune: "BRENNERO/BRENNER",
    sigla: "BZ",
    codice_elettorale: 1040140070,
    codice_istat: 21010,
    codice_belfiore: "B145",
  },
  {
    id: 896,
    comune: "BRENO",
    sigla: "BS",
    codice_elettorale: 1030150250,
    codice_istat: 17028,
    codice_belfiore: "B149",
  },
  {
    id: 897,
    comune: "BRENTA",
    sigla: "VA",
    codice_elettorale: 1030860151,
    codice_istat: 12019,
    codice_belfiore: "B150",
  },
  {
    id: 898,
    comune: "BRENTINO BELLUNO",
    sigla: "VR",
    codice_elettorale: 1050890130,
    codice_istat: 23013,
    codice_belfiore: "B152",
  },
  {
    id: 899,
    comune: "BRENTONICO",
    sigla: "TN",
    codice_elettorale: 1040830240,
    codice_istat: 22025,
    codice_belfiore: "B153",
  },
  {
    id: 900,
    comune: "BRENZONE SUL GARDA",
    sigla: "VR",
    codice_elettorale: 1050890140,
    codice_istat: 23014,
    codice_belfiore: "B154",
  },
  {
    id: 901,
    comune: "BRESCELLO",
    sigla: "RE",
    codice_elettorale: 1080680060,
    codice_istat: 35006,
    codice_belfiore: "B156",
  },
  {
    id: 902,
    comune: "BRESCIA",
    sigla: "BS",
    codice_elettorale: 1030150260,
    codice_istat: 17029,
    codice_belfiore: "B157",
  },
  {
    id: 903,
    comune: "BRESIMO",
    sigla: "TN",
    codice_elettorale: 1040830250,
    codice_istat: 22026,
    codice_belfiore: "B158",
  },
  {
    id: 904,
    comune: "BRESSANA BOTTARONE",
    sigla: "PV",
    codice_elettorale: 1030570210,
    codice_istat: 18023,
    codice_belfiore: "B159",
  },
  {
    id: 905,
    comune: "BRESSANONE/BRIXEN",
    sigla: "BZ",
    codice_elettorale: 1040140080,
    codice_istat: 21011,
    codice_belfiore: "B160",
  },
  {
    id: 906,
    comune: "BRESSANVIDO",
    sigla: "VI",
    codice_elettorale: 1050900160,
    codice_istat: 24016,
    codice_belfiore: "B161",
  },
  {
    id: 907,
    comune: "BRESSO",
    sigla: "MI",
    codice_elettorale: 1030490320,
    codice_istat: 15032,
    codice_belfiore: "B162",
  },
  {
    id: 908,
    comune: "BREZZO DI BEDERO",
    sigla: "VA",
    codice_elettorale: 1030860152,
    codice_istat: 12020,
    codice_belfiore: "B166",
  },
  {
    id: 909,
    comune: "BRIAGLIA",
    sigla: "CN",
    codice_elettorale: 1010270300,
    codice_istat: 4030,
    codice_belfiore: "B167",
  },
  {
    id: 910,
    comune: "BRIATICO",
    sigla: "VV",
    codice_elettorale: 3181030030,
    codice_istat: 102003,
    codice_belfiore: "B169",
  },
  {
    id: 911,
    comune: "BRICHERASIO",
    sigla: "TO",
    codice_elettorale: 1010810350,
    codice_istat: 1035,
    codice_belfiore: "B171",
  },
  {
    id: 912,
    comune: "BRIENNO",
    sigla: "CO",
    codice_elettorale: 1030240300,
    codice_istat: 13030,
    codice_belfiore: "B172",
  },
  {
    id: 913,
    comune: "BRIENZA",
    sigla: "PZ",
    codice_elettorale: 3170640130,
    codice_istat: 76013,
    codice_belfiore: "B173",
  },
  {
    id: 914,
    comune: "BRIGA ALTA",
    sigla: "CN",
    codice_elettorale: 1010270310,
    codice_istat: 4031,
    codice_belfiore: "B175",
  },
  {
    id: 915,
    comune: "BRIGA NOVARESE",
    sigla: "NO",
    codice_elettorale: 1010520250,
    codice_istat: 3026,
    codice_belfiore: "B176",
  },
  {
    id: 916,
    comune: "BRIGNANO FRASCATA",
    sigla: "AL",
    codice_elettorale: 1010020240,
    codice_istat: 6024,
    codice_belfiore: "B179",
  },
  {
    id: 917,
    comune: "BRIGNANO GERA D'ADDA",
    sigla: "BG",
    codice_elettorale: 1030120390,
    codice_istat: 16040,
    codice_belfiore: "B178",
  },
  {
    id: 918,
    comune: "BRINDISI",
    sigla: "BR",
    codice_elettorale: 3160160010,
    codice_istat: 74001,
    codice_belfiore: "B180",
  },
  {
    id: 919,
    comune: "BRINDISI MONTAGNA",
    sigla: "PZ",
    codice_elettorale: 3170640140,
    codice_istat: 76014,
    codice_belfiore: "B181",
  },
  {
    id: 920,
    comune: "BRINZIO",
    sigla: "VA",
    codice_elettorale: 1030860160,
    codice_istat: 12021,
    codice_belfiore: "B182",
  },
  {
    id: 921,
    comune: "BRIONA",
    sigla: "NO",
    codice_elettorale: 1010520260,
    codice_istat: 3027,
    codice_belfiore: "B183",
  },
  {
    id: 922,
    comune: "BRIONE",
    sigla: "BS",
    codice_elettorale: 1030150270,
    codice_istat: 17030,
    codice_belfiore: "B184",
  },
  {
    id: 923,
    comune: "BRIOSCO",
    sigla: "MB",
    codice_elettorale: 1031040110,
    codice_istat: 108011,
    codice_belfiore: "B187",
  },
  {
    id: 924,
    comune: "BRISIGHELLA",
    sigla: "RA",
    codice_elettorale: 1080660040,
    codice_istat: 39004,
    codice_belfiore: "B188",
  },
  {
    id: 925,
    comune: "BRISSAGO-VALTRAVAGLIA",
    sigla: "VA",
    codice_elettorale: 1030860170,
    codice_istat: 12022,
    codice_belfiore: "B191",
  },
  {
    id: 926,
    comune: "BRISSOGNE",
    sigla: "AO",
    codice_elettorale: 1020040110,
    codice_istat: 7011,
    codice_belfiore: "B192",
  },
  {
    id: 927,
    comune: "BRITTOLI",
    sigla: "PE",
    codice_elettorale: 3130600040,
    codice_istat: 68004,
    codice_belfiore: "B193",
  },
  {
    id: 928,
    comune: "BRIVIO",
    sigla: "LC",
    codice_elettorale: 1030980100,
    codice_istat: 97010,
    codice_belfiore: "B194",
  },
  {
    id: 929,
    comune: "BROCCOSTELLA",
    sigla: "FR",
    codice_elettorale: 2120330150,
    codice_istat: 60015,
    codice_belfiore: "B195",
  },
  {
    id: 930,
    comune: "BROGLIANO",
    sigla: "VI",
    codice_elettorale: 1050900170,
    codice_istat: 24017,
    codice_belfiore: "B196",
  },
  {
    id: 931,
    comune: "BROGNATURO",
    sigla: "VV",
    codice_elettorale: 3181030040,
    codice_istat: 102004,
    codice_belfiore: "B197",
  },
  {
    id: 932,
    comune: "BROLO",
    sigla: "ME",
    codice_elettorale: 4190480070,
    codice_istat: 83007,
    codice_belfiore: "B198",
  },
  {
    id: 933,
    comune: "BRONDELLO",
    sigla: "CN",
    codice_elettorale: 1010270320,
    codice_istat: 4032,
    codice_belfiore: "B200",
  },
  {
    id: 934,
    comune: "BRONI",
    sigla: "PV",
    codice_elettorale: 1030570220,
    codice_istat: 18024,
    codice_belfiore: "B201",
  },
  {
    id: 935,
    comune: "BRONTE",
    sigla: "CT",
    codice_elettorale: 4190210090,
    codice_istat: 87009,
    codice_belfiore: "B202",
  },
  {
    id: 936,
    comune: "BRONZOLO/BRANZOLL",
    sigla: "BZ",
    codice_elettorale: 1040140090,
    codice_istat: 21012,
    codice_belfiore: "B203",
  },
  {
    id: 937,
    comune: "BROSSASCO",
    sigla: "CN",
    codice_elettorale: 1010270330,
    codice_istat: 4033,
    codice_belfiore: "B204",
  },
  {
    id: 938,
    comune: "BROSSO",
    sigla: "TO",
    codice_elettorale: 1010810360,
    codice_istat: 1036,
    codice_belfiore: "B205",
  },
  {
    id: 939,
    comune: "BROVELLO-CARPUGNINO",
    sigla: "VB",
    codice_elettorale: 1011020130,
    codice_istat: 103013,
    codice_belfiore: "B207",
  },
  {
    id: 940,
    comune: "BROZOLO",
    sigla: "TO",
    codice_elettorale: 1010810370,
    codice_istat: 1037,
    codice_belfiore: "B209",
  },
  {
    id: 941,
    comune: "BRUGHERIO",
    sigla: "MB",
    codice_elettorale: 1031040120,
    codice_istat: 108012,
    codice_belfiore: "B212",
  },
  {
    id: 942,
    comune: "BRUGINE",
    sigla: "PD",
    codice_elettorale: 1050540150,
    codice_istat: 28015,
    codice_belfiore: "B213",
  },
  {
    id: 943,
    comune: "BRUGNATO",
    sigla: "SP",
    codice_elettorale: 1070390070,
    codice_istat: 11007,
    codice_belfiore: "B214",
  },
  {
    id: 944,
    comune: "BRUGNERA",
    sigla: "PN",
    codice_elettorale: 1060930070,
    codice_istat: 93007,
    codice_belfiore: "B215",
  },
  {
    id: 945,
    comune: "BRUINO",
    sigla: "TO",
    codice_elettorale: 1010810380,
    codice_istat: 1038,
    codice_belfiore: "B216",
  },
  {
    id: 946,
    comune: "BRUMANO",
    sigla: "BG",
    codice_elettorale: 1030120400,
    codice_istat: 16041,
    codice_belfiore: "B217",
  },
  {
    id: 947,
    comune: "BRUNATE",
    sigla: "CO",
    codice_elettorale: 1030240320,
    codice_istat: 13032,
    codice_belfiore: "B218",
  },
  {
    id: 948,
    comune: "BRUNELLO",
    sigla: "VA",
    codice_elettorale: 1030860171,
    codice_istat: 12023,
    codice_belfiore: "B219",
  },
  {
    id: 949,
    comune: "BRUNICO/BRUNECK",
    sigla: "BZ",
    codice_elettorale: 1040140100,
    codice_istat: 21013,
    codice_belfiore: "B220",
  },
  {
    id: 950,
    comune: "BRUNO",
    sigla: "AT",
    codice_elettorale: 1010070100,
    codice_istat: 5010,
    codice_belfiore: "B221",
  },
  {
    id: 951,
    comune: "BRUSAPORTO",
    sigla: "BG",
    codice_elettorale: 1030120401,
    codice_istat: 16042,
    codice_belfiore: "B223",
  },
  {
    id: 952,
    comune: "BRUSASCO",
    sigla: "TO",
    codice_elettorale: 1010810390,
    codice_istat: 1039,
    codice_belfiore: "B225",
  },
  {
    id: 953,
    comune: "BRUSCIANO",
    sigla: "NA",
    codice_elettorale: 3150510100,
    codice_istat: 63010,
    codice_belfiore: "B227",
  },
  {
    id: 954,
    comune: "BRUSIMPIANO",
    sigla: "VA",
    codice_elettorale: 1030860180,
    codice_istat: 12024,
    codice_belfiore: "B228",
  },
  {
    id: 955,
    comune: "BRUSNENGO",
    sigla: "BI",
    codice_elettorale: 1010960070,
    codice_istat: 96007,
    codice_belfiore: "B229",
  },
  {
    id: 956,
    comune: "BRUSSON",
    sigla: "AO",
    codice_elettorale: 1020040120,
    codice_istat: 7012,
    codice_belfiore: "B230",
  },
  {
    id: 957,
    comune: "BRUZOLO",
    sigla: "TO",
    codice_elettorale: 1010810400,
    codice_istat: 1040,
    codice_belfiore: "B232",
  },
  {
    id: 958,
    comune: "BRUZZANO ZEFFIRIO",
    sigla: "RC",
    codice_elettorale: 3180670150,
    codice_istat: 80015,
    codice_belfiore: "B234",
  },
  {
    id: 959,
    comune: "BUBBIANO",
    sigla: "MI",
    codice_elettorale: 1030490350,
    codice_istat: 15035,
    codice_belfiore: "B235",
  },
  {
    id: 960,
    comune: "BUBBIO",
    sigla: "AT",
    codice_elettorale: 1010070110,
    codice_istat: 5011,
    codice_belfiore: "B236",
  },
  {
    id: 961,
    comune: "BUCCHERI",
    sigla: "SR",
    codice_elettorale: 4190760030,
    codice_istat: 89003,
    codice_belfiore: "B237",
  },
  {
    id: 962,
    comune: "BUCCHIANICO",
    sigla: "CH",
    codice_elettorale: 3130230080,
    codice_istat: 69008,
    codice_belfiore: "B238",
  },
  {
    id: 963,
    comune: "BUCCIANO",
    sigla: "BN",
    codice_elettorale: 3150110100,
    codice_istat: 62010,
    codice_belfiore: "B239",
  },
  {
    id: 964,
    comune: "BUCCINASCO",
    sigla: "MI",
    codice_elettorale: 1030490360,
    codice_istat: 15036,
    codice_belfiore: "B240",
  },
  {
    id: 965,
    comune: "BUCCINO",
    sigla: "SA",
    codice_elettorale: 3150720170,
    codice_istat: 65017,
    codice_belfiore: "B242",
  },
  {
    id: 966,
    comune: "BUCINE",
    sigla: "AR",
    codice_elettorale: 2090050050,
    codice_istat: 51005,
    codice_belfiore: "B243",
  },
  {
    id: 967,
    comune: "BUDDUSO'",
    sigla: "SS",
    codice_elettorale: 4200730170,
    codice_istat: 90017,
    codice_belfiore: "B246",
  },
  {
    id: 968,
    comune: "BUDOIA",
    sigla: "PN",
    codice_elettorale: 1060930080,
    codice_istat: 93008,
    codice_belfiore: "B247",
  },
  {
    id: 969,
    comune: "BUDONI",
    sigla: "SS",
    codice_elettorale: 4200730175,
    codice_istat: 90091,
    codice_belfiore: "B248",
  },
  {
    id: 970,
    comune: "BUDRIO",
    sigla: "BO",
    codice_elettorale: 1080130080,
    codice_istat: 37008,
    codice_belfiore: "B249",
  },
  {
    id: 971,
    comune: "BUGGERRU",
    sigla: "SU",
    codice_elettorale: 4201110060,
    codice_istat: 111006,
    codice_belfiore: "B250",
  },
  {
    id: 972,
    comune: "BUGGIANO",
    sigla: "PT",
    codice_elettorale: 2090630030,
    codice_istat: 47003,
    codice_belfiore: "B251",
  },
  {
    id: 973,
    comune: "BUGLIO IN MONTE",
    sigla: "SO",
    codice_elettorale: 1030770100,
    codice_istat: 14010,
    codice_belfiore: "B255",
  },
  {
    id: 974,
    comune: "BUGNARA",
    sigla: "AQ",
    codice_elettorale: 3130380120,
    codice_istat: 66012,
    codice_belfiore: "B256",
  },
  {
    id: 975,
    comune: "BUGUGGIATE",
    sigla: "VA",
    codice_elettorale: 1030860181,
    codice_istat: 12025,
    codice_belfiore: "B258",
  },
  {
    id: 976,
    comune: "BUJA",
    sigla: "UD",
    codice_elettorale: 1060850130,
    codice_istat: 30013,
    codice_belfiore: "B259",
  },
  {
    id: 977,
    comune: "BULCIAGO",
    sigla: "LC",
    codice_elettorale: 1030980110,
    codice_istat: 97011,
    codice_belfiore: "B261",
  },
  {
    id: 978,
    comune: "BULGAROGRASSO",
    sigla: "CO",
    codice_elettorale: 1030240340,
    codice_istat: 13034,
    codice_belfiore: "B262",
  },
  {
    id: 979,
    comune: "BULTEI",
    sigla: "SS",
    codice_elettorale: 4200730180,
    codice_istat: 90018,
    codice_belfiore: "B264",
  },
  {
    id: 980,
    comune: "BULZI",
    sigla: "SS",
    codice_elettorale: 4200730190,
    codice_istat: 90019,
    codice_belfiore: "B265",
  },
  {
    id: 981,
    comune: "BUONABITACOLO",
    sigla: "SA",
    codice_elettorale: 3150720180,
    codice_istat: 65018,
    codice_belfiore: "B266",
  },
  {
    id: 982,
    comune: "BUONALBERGO",
    sigla: "BN",
    codice_elettorale: 3150110110,
    codice_istat: 62011,
    codice_belfiore: "B267",
  },
  {
    id: 983,
    comune: "BUONCONVENTO",
    sigla: "SI",
    codice_elettorale: 2090750030,
    codice_istat: 52003,
    codice_belfiore: "B269",
  },
  {
    id: 984,
    comune: "BUONVICINO",
    sigla: "CS",
    codice_elettorale: 3180250200,
    codice_istat: 78020,
    codice_belfiore: "B270",
  },
  {
    id: 985,
    comune: "BURAGO DI MOLGORA",
    sigla: "MB",
    codice_elettorale: 1031040130,
    codice_istat: 108013,
    codice_belfiore: "B272",
  },
  {
    id: 986,
    comune: "BURCEI",
    sigla: "SU",
    codice_elettorale: 4201110070,
    codice_istat: 111007,
    codice_belfiore: "B274",
  },
  {
    id: 987,
    comune: "BURGIO",
    sigla: "AG",
    codice_elettorale: 4190010050,
    codice_istat: 84005,
    codice_belfiore: "B275",
  },
  {
    id: 988,
    comune: "BURGOS",
    sigla: "SS",
    codice_elettorale: 4200730200,
    codice_istat: 90020,
    codice_belfiore: "B276",
  },
  {
    id: 989,
    comune: "BURIASCO",
    sigla: "TO",
    codice_elettorale: 1010810410,
    codice_istat: 1041,
    codice_belfiore: "B278",
  },
  {
    id: 990,
    comune: "BUROLO",
    sigla: "TO",
    codice_elettorale: 1010810420,
    codice_istat: 1042,
    codice_belfiore: "B279",
  },
  {
    id: 991,
    comune: "BURONZO",
    sigla: "VC",
    codice_elettorale: 1010880210,
    codice_istat: 2021,
    codice_belfiore: "B280",
  },
  {
    id: 992,
    comune: "BUSACHI",
    sigla: "OR",
    codice_elettorale: 4200950170,
    codice_istat: 95017,
    codice_belfiore: "B281",
  },
  {
    id: 993,
    comune: "BUSALLA",
    sigla: "GE",
    codice_elettorale: 1070340060,
    codice_istat: 10006,
    codice_belfiore: "B282",
  },
  {
    id: 994,
    comune: "BUSANO",
    sigla: "TO",
    codice_elettorale: 1010810430,
    codice_istat: 1043,
    codice_belfiore: "B284",
  },
  {
    id: 995,
    comune: "BUSCA",
    sigla: "CN",
    codice_elettorale: 1010270340,
    codice_istat: 4034,
    codice_belfiore: "B285",
  },
  {
    id: 996,
    comune: "BUSCATE",
    sigla: "MI",
    codice_elettorale: 1030490380,
    codice_istat: 15038,
    codice_belfiore: "B286",
  },
  {
    id: 997,
    comune: "BUSCEMI",
    sigla: "SR",
    codice_elettorale: 4190760040,
    codice_istat: 89004,
    codice_belfiore: "B287",
  },
  {
    id: 998,
    comune: "BUSETO PALIZZOLO",
    sigla: "TP",
    codice_elettorale: 4190820020,
    codice_istat: 81002,
    codice_belfiore: "B288",
  },
  {
    id: 999,
    comune: "BUSNAGO",
    sigla: "MB",
    codice_elettorale: 1031040135,
    codice_istat: 108051,
    codice_belfiore: "B289",
  },
  {
    id: 1000,
    comune: "BUSSERO",
    sigla: "MI",
    codice_elettorale: 1030490400,
    codice_istat: 15040,
    codice_belfiore: "B292",
  },
  {
    id: 1001,
    comune: "BUSSETO",
    sigla: "PR",
    codice_elettorale: 1080560070,
    codice_istat: 34007,
    codice_belfiore: "B293",
  },
  {
    id: 1002,
    comune: "BUSSI SUL TIRINO",
    sigla: "PE",
    codice_elettorale: 3130600050,
    codice_istat: 68005,
    codice_belfiore: "B294",
  },
  {
    id: 1003,
    comune: "BUSSO",
    sigla: "CB",
    codice_elettorale: 3140190050,
    codice_istat: 70005,
    codice_belfiore: "B295",
  },
  {
    id: 1004,
    comune: "BUSSOLENGO",
    sigla: "VR",
    codice_elettorale: 1050890150,
    codice_istat: 23015,
    codice_belfiore: "B296",
  },
  {
    id: 1005,
    comune: "BUSSOLENO",
    sigla: "TO",
    codice_elettorale: 1010810440,
    codice_istat: 1044,
    codice_belfiore: "B297",
  },
  {
    id: 1006,
    comune: "BUSTO ARSIZIO",
    sigla: "VA",
    codice_elettorale: 1030860190,
    codice_istat: 12026,
    codice_belfiore: "B300",
  },
  {
    id: 1007,
    comune: "BUSTO GAROLFO",
    sigla: "MI",
    codice_elettorale: 1030490410,
    codice_istat: 15041,
    codice_belfiore: "B301",
  },
  {
    id: 1008,
    comune: "BUTERA",
    sigla: "CL",
    codice_elettorale: 4190180030,
    codice_istat: 85003,
    codice_belfiore: "B302",
  },
  {
    id: 1009,
    comune: "BUTI",
    sigla: "PI",
    codice_elettorale: 2090620030,
    codice_istat: 50002,
    codice_belfiore: "B303",
  },
  {
    id: 1010,
    comune: "BUTTAPIETRA",
    sigla: "VR",
    codice_elettorale: 1050890160,
    codice_istat: 23016,
    codice_belfiore: "B304",
  },
  {
    id: 1011,
    comune: "BUTTIGLIERA ALTA",
    sigla: "TO",
    codice_elettorale: 1010810450,
    codice_istat: 1045,
    codice_belfiore: "B305",
  },
  {
    id: 1012,
    comune: "BUTTIGLIERA D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070120,
    codice_istat: 5012,
    codice_belfiore: "B306",
  },
  {
    id: 1013,
    comune: "BUTTRIO",
    sigla: "UD",
    codice_elettorale: 1060850140,
    codice_istat: 30014,
    codice_belfiore: "B309",
  },
  {
    id: 1014,
    comune: "CABELLA LIGURE",
    sigla: "AL",
    codice_elettorale: 1010020250,
    codice_istat: 6025,
    codice_belfiore: "B311",
  },
  {
    id: 1015,
    comune: "CABIATE",
    sigla: "CO",
    codice_elettorale: 1030240350,
    codice_istat: 13035,
    codice_belfiore: "B313",
  },
  {
    id: 1016,
    comune: "CABRAS",
    sigla: "OR",
    codice_elettorale: 4200950180,
    codice_istat: 95018,
    codice_belfiore: "B314",
  },
  {
    id: 1017,
    comune: "CACCAMO",
    sigla: "PA",
    codice_elettorale: 4190550140,
    codice_istat: 82014,
    codice_belfiore: "B315",
  },
  {
    id: 1018,
    comune: "CACCURI",
    sigla: "KR",
    codice_elettorale: 3180970020,
    codice_istat: 101002,
    codice_belfiore: "B319",
  },
  {
    id: 1019,
    comune: "CADEGLIANO-VICONAGO",
    sigla: "VA",
    codice_elettorale: 1030860200,
    codice_istat: 12027,
    codice_belfiore: "B326",
  },
  {
    id: 1020,
    comune: "CADELBOSCO DI SOPRA",
    sigla: "RE",
    codice_elettorale: 1080680080,
    codice_istat: 35008,
    codice_belfiore: "B328",
  },
  {
    id: 1021,
    comune: "CADEO",
    sigla: "PC",
    codice_elettorale: 1080610070,
    codice_istat: 33007,
    codice_belfiore: "B332",
  },
  {
    id: 1022,
    comune: "CADERZONE TERME",
    sigla: "TN",
    codice_elettorale: 1040830280,
    codice_istat: 22029,
    codice_belfiore: "B335",
  },
  {
    id: 1023,
    comune: "CADONEGHE",
    sigla: "PD",
    codice_elettorale: 1050540160,
    codice_istat: 28016,
    codice_belfiore: "B345",
  },
  {
    id: 1024,
    comune: "CADORAGO",
    sigla: "CO",
    codice_elettorale: 1030240360,
    codice_istat: 13036,
    codice_belfiore: "B346",
  },
  {
    id: 1025,
    comune: "CADREZZATE CON OSMATE",
    sigla: "VA",
    codice_elettorale: 1030860211,
    codice_istat: 12143,
    codice_belfiore: "M425",
  },
  {
    id: 1026,
    comune: "CAERANO DI SAN MARCO",
    sigla: "TV",
    codice_elettorale: 1050840060,
    codice_istat: 26006,
    codice_belfiore: "B349",
  },
  {
    id: 1027,
    comune: "CAFASSE",
    sigla: "TO",
    codice_elettorale: 1010810460,
    codice_istat: 1046,
    codice_belfiore: "B350",
  },
  {
    id: 1028,
    comune: "CAGGIANO",
    sigla: "SA",
    codice_elettorale: 3150720190,
    codice_istat: 65019,
    codice_belfiore: "B351",
  },
  {
    id: 1029,
    comune: "CAGLI",
    sigla: "PU",
    codice_elettorale: 2110590070,
    codice_istat: 41007,
    codice_belfiore: "B352",
  },
  {
    id: 1030,
    comune: "CAGLIARI",
    sigla: "CA",
    codice_elettorale: 4200170090,
    codice_istat: 92009,
    codice_belfiore: "B354",
  },
  {
    id: 1031,
    comune: "CAGLIO",
    sigla: "CO",
    codice_elettorale: 1030240370,
    codice_istat: 13037,
    codice_belfiore: "B355",
  },
  {
    id: 1032,
    comune: "CAGNANO AMITERNO",
    sigla: "AQ",
    codice_elettorale: 3130380130,
    codice_istat: 66013,
    codice_belfiore: "B358",
  },
  {
    id: 1033,
    comune: "CAGNANO VARANO",
    sigla: "FG",
    codice_elettorale: 3160310080,
    codice_istat: 71008,
    codice_belfiore: "B357",
  },
  {
    id: 1034,
    comune: "CAIANELLO",
    sigla: "CE",
    codice_elettorale: 3150200080,
    codice_istat: 61008,
    codice_belfiore: "B361",
  },
  {
    id: 1035,
    comune: "CAIAZZO",
    sigla: "CE",
    codice_elettorale: 3150200090,
    codice_istat: 61009,
    codice_belfiore: "B362",
  },
  {
    id: 1036,
    comune: "CAINES/KUENS",
    sigla: "BZ",
    codice_elettorale: 1040140110,
    codice_istat: 21014,
    codice_belfiore: "B364",
  },
  {
    id: 1037,
    comune: "CAINO",
    sigla: "BS",
    codice_elettorale: 1030150271,
    codice_istat: 17031,
    codice_belfiore: "B365",
  },
  {
    id: 1038,
    comune: "CAIOLO",
    sigla: "SO",
    codice_elettorale: 1030770110,
    codice_istat: 14011,
    codice_belfiore: "B366",
  },
  {
    id: 1039,
    comune: "CAIRANO",
    sigla: "AV",
    codice_elettorale: 3150080130,
    codice_istat: 64013,
    codice_belfiore: "B367",
  },
  {
    id: 1040,
    comune: "CAIRATE",
    sigla: "VA",
    codice_elettorale: 1030860220,
    codice_istat: 12029,
    codice_belfiore: "B368",
  },
  {
    id: 1041,
    comune: "CAIRO MONTENOTTE",
    sigla: "SV",
    codice_elettorale: 1070740150,
    codice_istat: 9015,
    codice_belfiore: "B369",
  },
  {
    id: 1042,
    comune: "CAIVANO",
    sigla: "NA",
    codice_elettorale: 3150510110,
    codice_istat: 63011,
    codice_belfiore: "B371",
  },
  {
    id: 1043,
    comune: "CALABRITTO",
    sigla: "AV",
    codice_elettorale: 3150080140,
    codice_istat: 64014,
    codice_belfiore: "B374",
  },
  {
    id: 1044,
    comune: "CALALZO DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100080,
    codice_istat: 25008,
    codice_belfiore: "B375",
  },
  {
    id: 1045,
    comune: "CALAMANDRANA",
    sigla: "AT",
    codice_elettorale: 1010070130,
    codice_istat: 5013,
    codice_belfiore: "B376",
  },
  {
    id: 1046,
    comune: "CALAMONACI",
    sigla: "AG",
    codice_elettorale: 4190010060,
    codice_istat: 84006,
    codice_belfiore: "B377",
  },
  {
    id: 1047,
    comune: "CALANGIANUS",
    sigla: "SS",
    codice_elettorale: 4200730210,
    codice_istat: 90021,
    codice_belfiore: "B378",
  },
  {
    id: 1048,
    comune: "CALANNA",
    sigla: "RC",
    codice_elettorale: 3180670160,
    codice_istat: 80016,
    codice_belfiore: "B379",
  },
  {
    id: 1049,
    comune: "CALASCA-CASTIGLIONE",
    sigla: "VB",
    codice_elettorale: 1011020140,
    codice_istat: 103014,
    codice_belfiore: "B380",
  },
  {
    id: 1050,
    comune: "CALASCIBETTA",
    sigla: "EN",
    codice_elettorale: 4190280050,
    codice_istat: 86005,
    codice_belfiore: "B381",
  },
  {
    id: 1051,
    comune: "CALASCIO",
    sigla: "AQ",
    codice_elettorale: 3130380140,
    codice_istat: 66014,
    codice_belfiore: "B382",
  },
  {
    id: 1052,
    comune: "CALASETTA",
    sigla: "SU",
    codice_elettorale: 4201110080,
    codice_istat: 111008,
    codice_belfiore: "B383",
  },
  {
    id: 1053,
    comune: "CALATABIANO",
    sigla: "CT",
    codice_elettorale: 4190210100,
    codice_istat: 87010,
    codice_belfiore: "B384",
  },
  {
    id: 1054,
    comune: "CALATAFIMI SEGESTA",
    sigla: "TP",
    codice_elettorale: 4190820030,
    codice_istat: 81003,
    codice_belfiore: "B385",
  },
  {
    id: 1055,
    comune: "CALCATA",
    sigla: "VT",
    codice_elettorale: 2120910090,
    codice_istat: 56010,
    codice_belfiore: "B388",
  },
  {
    id: 1056,
    comune: "CALCERANICA AL LAGO",
    sigla: "TN",
    codice_elettorale: 1040830300,
    codice_istat: 22032,
    codice_belfiore: "B389",
  },
  {
    id: 1057,
    comune: "CALCI",
    sigla: "PI",
    codice_elettorale: 2090620040,
    codice_istat: 50003,
    codice_belfiore: "B390",
  },
  {
    id: 1058,
    comune: "CALCIANO",
    sigla: "MT",
    codice_elettorale: 3170470040,
    codice_istat: 77004,
    codice_belfiore: "B391",
  },
  {
    id: 1059,
    comune: "CALCINAIA",
    sigla: "PI",
    codice_elettorale: 2090620050,
    codice_istat: 50004,
    codice_belfiore: "B392",
  },
  {
    id: 1060,
    comune: "CALCINATE",
    sigla: "BG",
    codice_elettorale: 1030120410,
    codice_istat: 16043,
    codice_belfiore: "B393",
  },
  {
    id: 1061,
    comune: "CALCINATO",
    sigla: "BS",
    codice_elettorale: 1030150280,
    codice_istat: 17032,
    codice_belfiore: "B394",
  },
  {
    id: 1062,
    comune: "CALCIO",
    sigla: "BG",
    codice_elettorale: 1030120420,
    codice_istat: 16044,
    codice_belfiore: "B395",
  },
  {
    id: 1063,
    comune: "CALCO",
    sigla: "LC",
    codice_elettorale: 1030980120,
    codice_istat: 97012,
    codice_belfiore: "B396",
  },
  {
    id: 1064,
    comune: "CALDARO SULLA STRADA DEL VINO/KALTERN AN DER WEINSTRASSE",
    sigla: "BZ",
    codice_elettorale: 1040140120,
    codice_istat: 21015,
    codice_belfiore: "B397",
  },
  {
    id: 1065,
    comune: "CALDAROLA",
    sigla: "MC",
    codice_elettorale: 2110440060,
    codice_istat: 43006,
    codice_belfiore: "B398",
  },
  {
    id: 1066,
    comune: "CALDERARA DI RENO",
    sigla: "BO",
    codice_elettorale: 1080130090,
    codice_istat: 37009,
    codice_belfiore: "B399",
  },
  {
    id: 1067,
    comune: "CALDES",
    sigla: "TN",
    codice_elettorale: 1040830310,
    codice_istat: 22033,
    codice_belfiore: "B400",
  },
  {
    id: 1068,
    comune: "CALDIERO",
    sigla: "VR",
    codice_elettorale: 1050890170,
    codice_istat: 23017,
    codice_belfiore: "B402",
  },
  {
    id: 1069,
    comune: "CALDOGNO",
    sigla: "VI",
    codice_elettorale: 1050900180,
    codice_istat: 24018,
    codice_belfiore: "B403",
  },
  {
    id: 1070,
    comune: "CALDONAZZO",
    sigla: "TN",
    codice_elettorale: 1040830320,
    codice_istat: 22034,
    codice_belfiore: "B404",
  },
  {
    id: 1071,
    comune: "CALENDASCO",
    sigla: "PC",
    codice_elettorale: 1080610080,
    codice_istat: 33008,
    codice_belfiore: "B405",
  },
  {
    id: 1072,
    comune: "CALENZANO",
    sigla: "FI",
    codice_elettorale: 2090300050,
    codice_istat: 48005,
    codice_belfiore: "B406",
  },
  {
    id: 1073,
    comune: "CALESTANO",
    sigla: "PR",
    codice_elettorale: 1080560080,
    codice_istat: 34008,
    codice_belfiore: "B408",
  },
  {
    id: 1074,
    comune: "CALICE AL CORNOVIGLIO",
    sigla: "SP",
    codice_elettorale: 1070390080,
    codice_istat: 11008,
    codice_belfiore: "B410",
  },
  {
    id: 1075,
    comune: "CALICE LIGURE",
    sigla: "SV",
    codice_elettorale: 1070740160,
    codice_istat: 9016,
    codice_belfiore: "B409",
  },
  {
    id: 1076,
    comune: "CALIMERA",
    sigla: "LE",
    codice_elettorale: 3160410090,
    codice_istat: 75010,
    codice_belfiore: "B413",
  },
  {
    id: 1077,
    comune: "CALITRI",
    sigla: "AV",
    codice_elettorale: 3150080150,
    codice_istat: 64015,
    codice_belfiore: "B415",
  },
  {
    id: 1078,
    comune: "CALIZZANO",
    sigla: "SV",
    codice_elettorale: 1070740170,
    codice_istat: 9017,
    codice_belfiore: "B416",
  },
  {
    id: 1079,
    comune: "CALLABIANA",
    sigla: "BI",
    codice_elettorale: 1010960080,
    codice_istat: 96008,
    codice_belfiore: "B417",
  },
  {
    id: 1080,
    comune: "CALLIANO",
    sigla: "TN",
    codice_elettorale: 1040830330,
    codice_istat: 22035,
    codice_belfiore: "B419",
  },
  {
    id: 1081,
    comune: "CALLIANO MONFERRATO",
    sigla: "AT",
    codice_elettorale: 1010070140,
    codice_istat: 5014,
    codice_belfiore: "B418",
  },
  {
    id: 1082,
    comune: "CALOLZIOCORTE",
    sigla: "LC",
    codice_elettorale: 1030980130,
    codice_istat: 97013,
    codice_belfiore: "B423",
  },
  {
    id: 1083,
    comune: "CALOPEZZATI",
    sigla: "CS",
    codice_elettorale: 3180250210,
    codice_istat: 78021,
    codice_belfiore: "B424",
  },
  {
    id: 1084,
    comune: "CALOSSO",
    sigla: "AT",
    codice_elettorale: 1010070150,
    codice_istat: 5015,
    codice_belfiore: "B425",
  },
  {
    id: 1085,
    comune: "CALOVETO",
    sigla: "CS",
    codice_elettorale: 3180250220,
    codice_istat: 78022,
    codice_belfiore: "B426",
  },
  {
    id: 1086,
    comune: "CALTABELLOTTA",
    sigla: "AG",
    codice_elettorale: 4190010070,
    codice_istat: 84007,
    codice_belfiore: "B427",
  },
  {
    id: 1087,
    comune: "CALTAGIRONE",
    sigla: "CT",
    codice_elettorale: 4190210110,
    codice_istat: 87011,
    codice_belfiore: "B428",
  },
  {
    id: 1088,
    comune: "CALTANISSETTA",
    sigla: "CL",
    codice_elettorale: 4190180040,
    codice_istat: 85004,
    codice_belfiore: "B429",
  },
  {
    id: 1089,
    comune: "CALTAVUTURO",
    sigla: "PA",
    codice_elettorale: 4190550150,
    codice_istat: 82015,
    codice_belfiore: "B430",
  },
  {
    id: 1090,
    comune: "CALTIGNAGA",
    sigla: "NO",
    codice_elettorale: 1010520290,
    codice_istat: 3030,
    codice_belfiore: "B431",
  },
  {
    id: 1091,
    comune: "CALTO",
    sigla: "RO",
    codice_elettorale: 1050710080,
    codice_istat: 29008,
    codice_belfiore: "B432",
  },
  {
    id: 1092,
    comune: "CALTRANO",
    sigla: "VI",
    codice_elettorale: 1050900190,
    codice_istat: 24019,
    codice_belfiore: "B433",
  },
  {
    id: 1093,
    comune: "CALUSCO D'ADDA",
    sigla: "BG",
    codice_elettorale: 1030120440,
    codice_istat: 16046,
    codice_belfiore: "B434",
  },
  {
    id: 1094,
    comune: "CALUSO",
    sigla: "TO",
    codice_elettorale: 1010810470,
    codice_istat: 1047,
    codice_belfiore: "B435",
  },
  {
    id: 1095,
    comune: "CALVAGESE DELLA RIVIERA",
    sigla: "BS",
    codice_elettorale: 1030150290,
    codice_istat: 17033,
    codice_belfiore: "B436",
  },
  {
    id: 1096,
    comune: "CALVANICO",
    sigla: "SA",
    codice_elettorale: 3150720200,
    codice_istat: 65020,
    codice_belfiore: "B437",
  },
  {
    id: 1097,
    comune: "CALVATONE",
    sigla: "CR",
    codice_elettorale: 1030260090,
    codice_istat: 19009,
    codice_belfiore: "B439",
  },
  {
    id: 1098,
    comune: "CALVELLO",
    sigla: "PZ",
    codice_elettorale: 3170640150,
    codice_istat: 76015,
    codice_belfiore: "B440",
  },
  {
    id: 1099,
    comune: "CALVENE",
    sigla: "VI",
    codice_elettorale: 1050900200,
    codice_istat: 24020,
    codice_belfiore: "B441",
  },
  {
    id: 1100,
    comune: "CALVENZANO",
    sigla: "BG",
    codice_elettorale: 1030120450,
    codice_istat: 16047,
    codice_belfiore: "B442",
  },
  {
    id: 1101,
    comune: "CALVERA",
    sigla: "PZ",
    codice_elettorale: 3170640160,
    codice_istat: 76016,
    codice_belfiore: "B443",
  },
  {
    id: 1102,
    comune: "CALVI",
    sigla: "BN",
    codice_elettorale: 3150110120,
    codice_istat: 62012,
    codice_belfiore: "B444",
  },
  {
    id: 1103,
    comune: "CALVI DELL'UMBRIA",
    sigla: "TR",
    codice_elettorale: 2100800080,
    codice_istat: 55008,
    codice_belfiore: "B446",
  },
  {
    id: 1104,
    comune: "CALVI RISORTA",
    sigla: "CE",
    codice_elettorale: 3150200100,
    codice_istat: 61010,
    codice_belfiore: "B445",
  },
  {
    id: 1105,
    comune: "CALVIGNANO",
    sigla: "PV",
    codice_elettorale: 1030570230,
    codice_istat: 18025,
    codice_belfiore: "B447",
  },
  {
    id: 1106,
    comune: "CALVIGNASCO",
    sigla: "MI",
    codice_elettorale: 1030490420,
    codice_istat: 15042,
    codice_belfiore: "B448",
  },
  {
    id: 1107,
    comune: "CALVISANO",
    sigla: "BS",
    codice_elettorale: 1030150300,
    codice_istat: 17034,
    codice_belfiore: "B450",
  },
  {
    id: 1108,
    comune: "CALVIZZANO",
    sigla: "NA",
    codice_elettorale: 3150510120,
    codice_istat: 63012,
    codice_belfiore: "B452",
  },
  {
    id: 1109,
    comune: "CAMAGNA MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020260,
    codice_istat: 6026,
    codice_belfiore: "B453",
  },
  {
    id: 1110,
    comune: "CAMAIORE",
    sigla: "LU",
    codice_elettorale: 2090430050,
    codice_istat: 46005,
    codice_belfiore: "B455",
  },
  {
    id: 1111,
    comune: "CAMANDONA",
    sigla: "BI",
    codice_elettorale: 1010960090,
    codice_istat: 96009,
    codice_belfiore: "B457",
  },
  {
    id: 1112,
    comune: "CAMASTRA",
    sigla: "AG",
    codice_elettorale: 4190010080,
    codice_istat: 84008,
    codice_belfiore: "B460",
  },
  {
    id: 1113,
    comune: "CAMBIAGO",
    sigla: "MI",
    codice_elettorale: 1030490440,
    codice_istat: 15044,
    codice_belfiore: "B461",
  },
  {
    id: 1114,
    comune: "CAMBIANO",
    sigla: "TO",
    codice_elettorale: 1010810480,
    codice_istat: 1048,
    codice_belfiore: "B462",
  },
  {
    id: 1115,
    comune: "CAMBIASCA",
    sigla: "VB",
    codice_elettorale: 1011020150,
    codice_istat: 103015,
    codice_belfiore: "B463",
  },
  {
    id: 1116,
    comune: "CAMBURZANO",
    sigla: "BI",
    codice_elettorale: 1010960100,
    codice_istat: 96010,
    codice_belfiore: "B465",
  },
  {
    id: 1117,
    comune: "CAMERANA",
    sigla: "CN",
    codice_elettorale: 1010270350,
    codice_istat: 4035,
    codice_belfiore: "B467",
  },
  {
    id: 1118,
    comune: "CAMERANO",
    sigla: "AN",
    codice_elettorale: 2110030060,
    codice_istat: 42006,
    codice_belfiore: "B468",
  },
  {
    id: 1119,
    comune: "CAMERANO CASASCO",
    sigla: "AT",
    codice_elettorale: 1010070160,
    codice_istat: 5016,
    codice_belfiore: "B469",
  },
  {
    id: 1120,
    comune: "CAMERATA CORNELLO",
    sigla: "BG",
    codice_elettorale: 1030120460,
    codice_istat: 16048,
    codice_belfiore: "B471",
  },
  {
    id: 1121,
    comune: "CAMERATA NUOVA",
    sigla: "RM",
    codice_elettorale: 2120700140,
    codice_istat: 58014,
    codice_belfiore: "B472",
  },
  {
    id: 1122,
    comune: "CAMERATA PICENA",
    sigla: "AN",
    codice_elettorale: 2110030070,
    codice_istat: 42007,
    codice_belfiore: "B470",
  },
  {
    id: 1123,
    comune: "CAMERI",
    sigla: "NO",
    codice_elettorale: 1010520310,
    codice_istat: 3032,
    codice_belfiore: "B473",
  },
  {
    id: 1124,
    comune: "CAMERINO",
    sigla: "MC",
    codice_elettorale: 2110440070,
    codice_istat: 43007,
    codice_belfiore: "B474",
  },
  {
    id: 1125,
    comune: "CAMEROTA",
    sigla: "SA",
    codice_elettorale: 3150720210,
    codice_istat: 65021,
    codice_belfiore: "B476",
  },
  {
    id: 1126,
    comune: "CAMIGLIANO",
    sigla: "CE",
    codice_elettorale: 3150200110,
    codice_istat: 61011,
    codice_belfiore: "B477",
  },
  {
    id: 1127,
    comune: "CAMINI",
    sigla: "RC",
    codice_elettorale: 3180670170,
    codice_istat: 80017,
    codice_belfiore: "B481",
  },
  {
    id: 1128,
    comune: "CAMINO",
    sigla: "AL",
    codice_elettorale: 1010020270,
    codice_istat: 6027,
    codice_belfiore: "B482",
  },
  {
    id: 1129,
    comune: "CAMINO AL TAGLIAMENTO",
    sigla: "UD",
    codice_elettorale: 1060850150,
    codice_istat: 30015,
    codice_belfiore: "B483",
  },
  {
    id: 1130,
    comune: "CAMISANO",
    sigla: "CR",
    codice_elettorale: 1030260100,
    codice_istat: 19010,
    codice_belfiore: "B484",
  },
  {
    id: 1131,
    comune: "CAMISANO VICENTINO",
    sigla: "VI",
    codice_elettorale: 1050900210,
    codice_istat: 24021,
    codice_belfiore: "B485",
  },
  {
    id: 1132,
    comune: "CAMMARATA",
    sigla: "AG",
    codice_elettorale: 4190010090,
    codice_istat: 84009,
    codice_belfiore: "B486",
  },
  {
    id: 1133,
    comune: "CAMOGLI",
    sigla: "GE",
    codice_elettorale: 1070340070,
    codice_istat: 10007,
    codice_belfiore: "B490",
  },
  {
    id: 1134,
    comune: "CAMPAGNA",
    sigla: "SA",
    codice_elettorale: 3150720220,
    codice_istat: 65022,
    codice_belfiore: "B492",
  },
  {
    id: 1135,
    comune: "CAMPAGNA LUPIA",
    sigla: "VE",
    codice_elettorale: 1050870020,
    codice_istat: 27002,
    codice_belfiore: "B493",
  },
  {
    id: 1136,
    comune: "CAMPAGNANO DI ROMA",
    sigla: "RM",
    codice_elettorale: 2120700150,
    codice_istat: 58015,
    codice_belfiore: "B496",
  },
  {
    id: 1137,
    comune: "CAMPAGNATICO",
    sigla: "GR",
    codice_elettorale: 2090360020,
    codice_istat: 53002,
    codice_belfiore: "B497",
  },
  {
    id: 1138,
    comune: "CAMPAGNOLA CREMASCA",
    sigla: "CR",
    codice_elettorale: 1030260101,
    codice_istat: 19011,
    codice_belfiore: "B498",
  },
  {
    id: 1139,
    comune: "CAMPAGNOLA EMILIA",
    sigla: "RE",
    codice_elettorale: 1080680090,
    codice_istat: 35009,
    codice_belfiore: "B499",
  },
  {
    id: 1140,
    comune: "CAMPANA",
    sigla: "CS",
    codice_elettorale: 3180250230,
    codice_istat: 78023,
    codice_belfiore: "B500",
  },
  {
    id: 1141,
    comune: "CAMPARADA",
    sigla: "MB",
    codice_elettorale: 1031040140,
    codice_istat: 108014,
    codice_belfiore: "B501",
  },
  {
    id: 1142,
    comune: "CAMPEGINE",
    sigla: "RE",
    codice_elettorale: 1080680100,
    codice_istat: 35010,
    codice_belfiore: "B502",
  },
  {
    id: 1143,
    comune: "CAMPELLO SUL CLITUNNO",
    sigla: "PG",
    codice_elettorale: 2100580050,
    codice_istat: 54005,
    codice_belfiore: "B504",
  },
  {
    id: 1144,
    comune: "CAMPERTOGNO",
    sigla: "VC",
    codice_elettorale: 1010880250,
    codice_istat: 2025,
    codice_belfiore: "B505",
  },
  {
    id: 1145,
    comune: "CAMPI BISENZIO",
    sigla: "FI",
    codice_elettorale: 2090300060,
    codice_istat: 48006,
    codice_belfiore: "B507",
  },
  {
    id: 1146,
    comune: "CAMPI SALENTINA",
    sigla: "LE",
    codice_elettorale: 3160410100,
    codice_istat: 75011,
    codice_belfiore: "B506",
  },
  {
    id: 1147,
    comune: "CAMPIGLIA CERVO",
    sigla: "BI",
    codice_elettorale: 1010960111,
    codice_istat: 96086,
    codice_belfiore: "M373",
  },
  {
    id: 1148,
    comune: "CAMPIGLIA DEI BERICI",
    sigla: "VI",
    codice_elettorale: 1050900220,
    codice_istat: 24022,
    codice_belfiore: "B511",
  },
  {
    id: 1149,
    comune: "CAMPIGLIA MARITTIMA",
    sigla: "LI",
    codice_elettorale: 2090420020,
    codice_istat: 49002,
    codice_belfiore: "B509",
  },
  {
    id: 1150,
    comune: "CAMPIGLIONE FENILE",
    sigla: "TO",
    codice_elettorale: 1010810490,
    codice_istat: 1049,
    codice_belfiore: "B512",
  },
  {
    id: 1151,
    comune: "CAMPIONE D'ITALIA",
    sigla: "CO",
    codice_elettorale: 1030240390,
    codice_istat: 13040,
    codice_belfiore: "B513",
  },
  {
    id: 1152,
    comune: "CAMPITELLO DI FASSA",
    sigla: "TN",
    codice_elettorale: 1040830331,
    codice_istat: 22036,
    codice_belfiore: "B514",
  },
  {
    id: 1153,
    comune: "CAMPLI",
    sigla: "TE",
    codice_elettorale: 3130790070,
    codice_istat: 67008,
    codice_belfiore: "B515",
  },
  {
    id: 1154,
    comune: "CAMPO CALABRO",
    sigla: "RC",
    codice_elettorale: 3180670180,
    codice_istat: 80018,
    codice_belfiore: "B516",
  },
  {
    id: 1155,
    comune: "CAMPO DI GIOVE",
    sigla: "AQ",
    codice_elettorale: 3130380150,
    codice_istat: 66015,
    codice_belfiore: "B526",
  },
  {
    id: 1156,
    comune: "CAMPO DI TRENS/FREIENFELD",
    sigla: "BZ",
    codice_elettorale: 1040140130,
    codice_istat: 21016,
    codice_belfiore: "B529",
  },
  {
    id: 1157,
    comune: "CAMPO LIGURE",
    sigla: "GE",
    codice_elettorale: 1070340080,
    codice_istat: 10008,
    codice_belfiore: "B538",
  },
  {
    id: 1158,
    comune: "CAMPO NELL'ELBA",
    sigla: "LI",
    codice_elettorale: 2090420030,
    codice_istat: 49003,
    codice_belfiore: "B553",
  },
  {
    id: 1159,
    comune: "CAMPO SAN MARTINO",
    sigla: "PD",
    codice_elettorale: 1050540200,
    codice_istat: 28020,
    codice_belfiore: "B564",
  },
  {
    id: 1160,
    comune: "CAMPO TURES/SAND IN TAUFERS",
    sigla: "BZ",
    codice_elettorale: 1040140140,
    codice_istat: 21017,
    codice_belfiore: "B570",
  },
  {
    id: 1161,
    comune: "CAMPOBASSO",
    sigla: "CB",
    codice_elettorale: 3140190060,
    codice_istat: 70006,
    codice_belfiore: "B519",
  },
  {
    id: 1162,
    comune: "CAMPOBELLO DI LICATA",
    sigla: "AG",
    codice_elettorale: 4190010100,
    codice_istat: 84010,
    codice_belfiore: "B520",
  },
  {
    id: 1163,
    comune: "CAMPOBELLO DI MAZARA",
    sigla: "TP",
    codice_elettorale: 4190820040,
    codice_istat: 81004,
    codice_belfiore: "B521",
  },
  {
    id: 1164,
    comune: "CAMPOCHIARO",
    sigla: "CB",
    codice_elettorale: 3140190070,
    codice_istat: 70007,
    codice_belfiore: "B522",
  },
  {
    id: 1165,
    comune: "CAMPODARSEGO",
    sigla: "PD",
    codice_elettorale: 1050540170,
    codice_istat: 28017,
    codice_belfiore: "B524",
  },
  {
    id: 1166,
    comune: "CAMPODENNO",
    sigla: "TN",
    codice_elettorale: 1040830340,
    codice_istat: 22037,
    codice_belfiore: "B525",
  },
  {
    id: 1167,
    comune: "CAMPODIMELE",
    sigla: "LT",
    codice_elettorale: 2120400030,
    codice_istat: 59003,
    codice_belfiore: "B527",
  },
  {
    id: 1168,
    comune: "CAMPODIPIETRA",
    sigla: "CB",
    codice_elettorale: 3140190080,
    codice_istat: 70008,
    codice_belfiore: "B528",
  },
  {
    id: 1169,
    comune: "CAMPODOLCINO",
    sigla: "SO",
    codice_elettorale: 1030770120,
    codice_istat: 14012,
    codice_belfiore: "B530",
  },
  {
    id: 1170,
    comune: "CAMPODORO",
    sigla: "PD",
    codice_elettorale: 1050540180,
    codice_istat: 28018,
    codice_belfiore: "B531",
  },
  {
    id: 1171,
    comune: "CAMPOFELICE DI FITALIA",
    sigla: "PA",
    codice_elettorale: 4190550151,
    codice_istat: 82016,
    codice_belfiore: "B533",
  },
  {
    id: 1172,
    comune: "CAMPOFELICE DI ROCCELLA",
    sigla: "PA",
    codice_elettorale: 4190550160,
    codice_istat: 82017,
    codice_belfiore: "B532",
  },
  {
    id: 1173,
    comune: "CAMPOFILONE",
    sigla: "FM",
    codice_elettorale: 2111050040,
    codice_istat: 109004,
    codice_belfiore: "B534",
  },
  {
    id: 1174,
    comune: "CAMPOFIORITO",
    sigla: "PA",
    codice_elettorale: 4190550170,
    codice_istat: 82018,
    codice_belfiore: "B535",
  },
  {
    id: 1175,
    comune: "CAMPOFORMIDO",
    sigla: "UD",
    codice_elettorale: 1060850160,
    codice_istat: 30016,
    codice_belfiore: "B536",
  },
  {
    id: 1176,
    comune: "CAMPOFRANCO",
    sigla: "CL",
    codice_elettorale: 4190180050,
    codice_istat: 85005,
    codice_belfiore: "B537",
  },
  {
    id: 1177,
    comune: "CAMPOGALLIANO",
    sigla: "MO",
    codice_elettorale: 1080500030,
    codice_istat: 36003,
    codice_belfiore: "B539",
  },
  {
    id: 1178,
    comune: "CAMPOLATTARO",
    sigla: "BN",
    codice_elettorale: 3150110130,
    codice_istat: 62013,
    codice_belfiore: "B541",
  },
  {
    id: 1179,
    comune: "CAMPOLI APPENNINO",
    sigla: "FR",
    codice_elettorale: 2120330160,
    codice_istat: 60016,
    codice_belfiore: "B543",
  },
  {
    id: 1180,
    comune: "CAMPOLI DEL MONTE TABURNO",
    sigla: "BN",
    codice_elettorale: 3150110140,
    codice_istat: 62014,
    codice_belfiore: "B542",
  },
  {
    id: 1181,
    comune: "CAMPOLIETO",
    sigla: "CB",
    codice_elettorale: 3140190090,
    codice_istat: 70009,
    codice_belfiore: "B544",
  },
  {
    id: 1182,
    comune: "CAMPOLONGO MAGGIORE",
    sigla: "VE",
    codice_elettorale: 1050870030,
    codice_istat: 27003,
    codice_belfiore: "B546",
  },
  {
    id: 1183,
    comune: "CAMPOLONGO TAPOGLIANO",
    sigla: "UD",
    codice_elettorale: 1060850175,
    codice_istat: 30138,
    codice_belfiore: "M311",
  },
  {
    id: 1184,
    comune: "CAMPOMAGGIORE",
    sigla: "PZ",
    codice_elettorale: 3170640170,
    codice_istat: 76017,
    codice_belfiore: "B549",
  },
  {
    id: 1185,
    comune: "CAMPOMARINO",
    sigla: "CB",
    codice_elettorale: 3140190100,
    codice_istat: 70010,
    codice_belfiore: "B550",
  },
  {
    id: 1186,
    comune: "CAMPOMORONE",
    sigla: "GE",
    codice_elettorale: 1070340090,
    codice_istat: 10009,
    codice_belfiore: "B551",
  },
  {
    id: 1187,
    comune: "CAMPONOGARA",
    sigla: "VE",
    codice_elettorale: 1050870040,
    codice_istat: 27004,
    codice_belfiore: "B554",
  },
  {
    id: 1188,
    comune: "CAMPORA",
    sigla: "SA",
    codice_elettorale: 3150720230,
    codice_istat: 65023,
    codice_belfiore: "B555",
  },
  {
    id: 1189,
    comune: "CAMPOREALE",
    sigla: "PA",
    codice_elettorale: 4190550171,
    codice_istat: 82019,
    codice_belfiore: "B556",
  },
  {
    id: 1190,
    comune: "CAMPORGIANO",
    sigla: "LU",
    codice_elettorale: 2090430060,
    codice_istat: 46006,
    codice_belfiore: "B557",
  },
  {
    id: 1191,
    comune: "CAMPOROSSO",
    sigla: "IM",
    codice_elettorale: 1070370100,
    codice_istat: 8011,
    codice_belfiore: "B559",
  },
  {
    id: 1192,
    comune: "CAMPOROTONDO DI FIASTRONE",
    sigla: "MC",
    codice_elettorale: 2110440080,
    codice_istat: 43008,
    codice_belfiore: "B562",
  },
  {
    id: 1193,
    comune: "CAMPOROTONDO ETNEO",
    sigla: "CT",
    codice_elettorale: 4190210120,
    codice_istat: 87012,
    codice_belfiore: "B561",
  },
  {
    id: 1194,
    comune: "CAMPOSAMPIERO",
    sigla: "PD",
    codice_elettorale: 1050540190,
    codice_istat: 28019,
    codice_belfiore: "B563",
  },
  {
    id: 1195,
    comune: "CAMPOSANO",
    sigla: "NA",
    codice_elettorale: 3150510130,
    codice_istat: 63013,
    codice_belfiore: "B565",
  },
  {
    id: 1196,
    comune: "CAMPOSANTO",
    sigla: "MO",
    codice_elettorale: 1080500040,
    codice_istat: 36004,
    codice_belfiore: "B566",
  },
  {
    id: 1197,
    comune: "CAMPOSPINOSO",
    sigla: "PV",
    codice_elettorale: 1030570240,
    codice_istat: 18026,
    codice_belfiore: "B567",
  },
  {
    id: 1198,
    comune: "CAMPOTOSTO",
    sigla: "AQ",
    codice_elettorale: 3130380160,
    codice_istat: 66016,
    codice_belfiore: "B569",
  },
  {
    id: 1199,
    comune: "CAMUGNANO",
    sigla: "BO",
    codice_elettorale: 1080130100,
    codice_istat: 37010,
    codice_belfiore: "B572",
  },
  {
    id: 1200,
    comune: "CANAL SAN BOVO",
    sigla: "TN",
    codice_elettorale: 1040830350,
    codice_istat: 22038,
    codice_belfiore: "B577",
  },
  {
    id: 1201,
    comune: "CANALE",
    sigla: "CN",
    codice_elettorale: 1010270370,
    codice_istat: 4037,
    codice_belfiore: "B573",
  },
  {
    id: 1202,
    comune: "CANALE D'AGORDO",
    sigla: "BL",
    codice_elettorale: 1050100081,
    codice_istat: 25023,
    codice_belfiore: "B574",
  },
  {
    id: 1203,
    comune: "CANALE MONTERANO",
    sigla: "RM",
    codice_elettorale: 2120700160,
    codice_istat: 58016,
    codice_belfiore: "B576",
  },
  {
    id: 1204,
    comune: "CANARO",
    sigla: "RO",
    codice_elettorale: 1050710090,
    codice_istat: 29009,
    codice_belfiore: "B578",
  },
  {
    id: 1205,
    comune: "CANAZEI",
    sigla: "TN",
    codice_elettorale: 1040830360,
    codice_istat: 22039,
    codice_belfiore: "B579",
  },
  {
    id: 1206,
    comune: "CANCELLARA",
    sigla: "PZ",
    codice_elettorale: 3170640180,
    codice_istat: 76018,
    codice_belfiore: "B580",
  },
  {
    id: 1207,
    comune: "CANCELLO ED ARNONE",
    sigla: "CE",
    codice_elettorale: 3150200120,
    codice_istat: 61012,
    codice_belfiore: "B581",
  },
  {
    id: 1208,
    comune: "CANDA",
    sigla: "RO",
    codice_elettorale: 1050710100,
    codice_istat: 29010,
    codice_belfiore: "B582",
  },
  {
    id: 1209,
    comune: "CANDELA",
    sigla: "FG",
    codice_elettorale: 3160310090,
    codice_istat: 71009,
    codice_belfiore: "B584",
  },
  {
    id: 1210,
    comune: "CANDELO",
    sigla: "BI",
    codice_elettorale: 1010960120,
    codice_istat: 96012,
    codice_belfiore: "B586",
  },
  {
    id: 1211,
    comune: "CANDIA CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010810500,
    codice_istat: 1050,
    codice_belfiore: "B588",
  },
  {
    id: 1212,
    comune: "CANDIA LOMELLINA",
    sigla: "PV",
    codice_elettorale: 1030570250,
    codice_istat: 18027,
    codice_belfiore: "B587",
  },
  {
    id: 1213,
    comune: "CANDIANA",
    sigla: "PD",
    codice_elettorale: 1050540210,
    codice_istat: 28021,
    codice_belfiore: "B589",
  },
  {
    id: 1214,
    comune: "CANDIDA",
    sigla: "AV",
    codice_elettorale: 3150080160,
    codice_istat: 64016,
    codice_belfiore: "B590",
  },
  {
    id: 1215,
    comune: "CANDIDONI",
    sigla: "RC",
    codice_elettorale: 3180670190,
    codice_istat: 80019,
    codice_belfiore: "B591",
  },
  {
    id: 1216,
    comune: "CANDIOLO",
    sigla: "TO",
    codice_elettorale: 1010810510,
    codice_istat: 1051,
    codice_belfiore: "B592",
  },
  {
    id: 1217,
    comune: "CANEGRATE",
    sigla: "MI",
    codice_elettorale: 1030490460,
    codice_istat: 15046,
    codice_belfiore: "B593",
  },
  {
    id: 1218,
    comune: "CANELLI",
    sigla: "AT",
    codice_elettorale: 1010070170,
    codice_istat: 5017,
    codice_belfiore: "B594",
  },
  {
    id: 1219,
    comune: "CANEPINA",
    sigla: "VT",
    codice_elettorale: 2120910100,
    codice_istat: 56011,
    codice_belfiore: "B597",
  },
  {
    id: 1220,
    comune: "CANEVA",
    sigla: "PN",
    codice_elettorale: 1060930090,
    codice_istat: 93009,
    codice_belfiore: "B598",
  },
  {
    id: 1221,
    comune: "CANICATTI'",
    sigla: "AG",
    codice_elettorale: 4190010110,
    codice_istat: 84011,
    codice_belfiore: "B602",
  },
  {
    id: 1222,
    comune: "CANICATTINI BAGNI",
    sigla: "SR",
    codice_elettorale: 4190760050,
    codice_istat: 89005,
    codice_belfiore: "B603",
  },
  {
    id: 1223,
    comune: "CANINO",
    sigla: "VT",
    codice_elettorale: 2120910110,
    codice_istat: 56012,
    codice_belfiore: "B604",
  },
  {
    id: 1224,
    comune: "CANISCHIO",
    sigla: "TO",
    codice_elettorale: 1010810520,
    codice_istat: 1052,
    codice_belfiore: "B605",
  },
  {
    id: 1225,
    comune: "CANISTRO",
    sigla: "AQ",
    codice_elettorale: 3130380170,
    codice_istat: 66017,
    codice_belfiore: "B606",
  },
  {
    id: 1226,
    comune: "CANNA",
    sigla: "CS",
    codice_elettorale: 3180250240,
    codice_istat: 78024,
    codice_belfiore: "B607",
  },
  {
    id: 1227,
    comune: "CANNALONGA",
    sigla: "SA",
    codice_elettorale: 3150720240,
    codice_istat: 65024,
    codice_belfiore: "B608",
  },
  {
    id: 1228,
    comune: "CANNARA",
    sigla: "PG",
    codice_elettorale: 2100580060,
    codice_istat: 54006,
    codice_belfiore: "B609",
  },
  {
    id: 1229,
    comune: "CANNERO RIVIERA",
    sigla: "VB",
    codice_elettorale: 1011020160,
    codice_istat: 103016,
    codice_belfiore: "B610",
  },
  {
    id: 1230,
    comune: "CANNETO PAVESE",
    sigla: "PV",
    codice_elettorale: 1030570270,
    codice_istat: 18029,
    codice_belfiore: "B613",
  },
  {
    id: 1231,
    comune: "CANNETO SULL'OGLIO",
    sigla: "MN",
    codice_elettorale: 1030450080,
    codice_istat: 20008,
    codice_belfiore: "B612",
  },
  {
    id: 1232,
    comune: "CANNOBIO",
    sigla: "VB",
    codice_elettorale: 1011020170,
    codice_istat: 103017,
    codice_belfiore: "B615",
  },
  {
    id: 1233,
    comune: "CANNOLE",
    sigla: "LE",
    codice_elettorale: 3160410110,
    codice_istat: 75012,
    codice_belfiore: "B616",
  },
  {
    id: 1234,
    comune: "CANOLO",
    sigla: "RC",
    codice_elettorale: 3180670200,
    codice_istat: 80020,
    codice_belfiore: "B617",
  },
  {
    id: 1235,
    comune: "CANONICA D'ADDA",
    sigla: "BG",
    codice_elettorale: 1030120470,
    codice_istat: 16049,
    codice_belfiore: "B618",
  },
  {
    id: 1236,
    comune: "CANOSA DI PUGLIA",
    sigla: "BT",
    codice_elettorale: 3161060040,
    codice_istat: 110004,
    codice_belfiore: "B619",
  },
  {
    id: 1237,
    comune: "CANOSA SANNITA",
    sigla: "CH",
    codice_elettorale: 3130230100,
    codice_istat: 69010,
    codice_belfiore: "B620",
  },
  {
    id: 1238,
    comune: "CANOSIO",
    sigla: "CN",
    codice_elettorale: 1010270380,
    codice_istat: 4038,
    codice_belfiore: "B621",
  },
  {
    id: 1239,
    comune: "CANOSSA",
    sigla: "RE",
    codice_elettorale: 1080680180,
    codice_istat: 35018,
    codice_belfiore: "C669",
  },
  {
    id: 1240,
    comune: "CANSANO",
    sigla: "AQ",
    codice_elettorale: 3130380180,
    codice_istat: 66018,
    codice_belfiore: "B624",
  },
  {
    id: 1241,
    comune: "CANTAGALLO",
    sigla: "PO",
    codice_elettorale: 2091000010,
    codice_istat: 100001,
    codice_belfiore: "B626",
  },
  {
    id: 1242,
    comune: "CANTALICE",
    sigla: "RI",
    codice_elettorale: 2120690080,
    codice_istat: 57009,
    codice_belfiore: "B627",
  },
  {
    id: 1243,
    comune: "CANTALUPA",
    sigla: "TO",
    codice_elettorale: 1010810521,
    codice_istat: 1053,
    codice_belfiore: "B628",
  },
  {
    id: 1244,
    comune: "CANTALUPO IN SABINA",
    sigla: "RI",
    codice_elettorale: 2120690090,
    codice_istat: 57010,
    codice_belfiore: "B631",
  },
  {
    id: 1245,
    comune: "CANTALUPO LIGURE",
    sigla: "AL",
    codice_elettorale: 1010020280,
    codice_istat: 6028,
    codice_belfiore: "B629",
  },
  {
    id: 1246,
    comune: "CANTALUPO NEL SANNIO",
    sigla: "IS",
    codice_elettorale: 3140940050,
    codice_istat: 94005,
    codice_belfiore: "B630",
  },
  {
    id: 1247,
    comune: "CANTARANA",
    sigla: "AT",
    codice_elettorale: 1010070180,
    codice_istat: 5018,
    codice_belfiore: "B633",
  },
  {
    id: 1248,
    comune: "CANTELLO",
    sigla: "VA",
    codice_elettorale: 1030860230,
    codice_istat: 12030,
    codice_belfiore: "B634",
  },
  {
    id: 1249,
    comune: "CANTERANO",
    sigla: "RM",
    codice_elettorale: 2120700170,
    codice_istat: 58017,
    codice_belfiore: "B635",
  },
  {
    id: 1250,
    comune: "CANTIANO",
    sigla: "PU",
    codice_elettorale: 2110590080,
    codice_istat: 41008,
    codice_belfiore: "B636",
  },
  {
    id: 1251,
    comune: "CANTOIRA",
    sigla: "TO",
    codice_elettorale: 1010810530,
    codice_istat: 1054,
    codice_belfiore: "B637",
  },
  {
    id: 1252,
    comune: "CANTU'",
    sigla: "CO",
    codice_elettorale: 1030240400,
    codice_istat: 13041,
    codice_belfiore: "B639",
  },
  {
    id: 1253,
    comune: "CANZANO",
    sigla: "TE",
    codice_elettorale: 3130790080,
    codice_istat: 67009,
    codice_belfiore: "B640",
  },
  {
    id: 1254,
    comune: "CANZO",
    sigla: "CO",
    codice_elettorale: 1030240410,
    codice_istat: 13042,
    codice_belfiore: "B641",
  },
  {
    id: 1255,
    comune: "CAORLE",
    sigla: "VE",
    codice_elettorale: 1050870050,
    codice_istat: 27005,
    codice_belfiore: "B642",
  },
  {
    id: 1256,
    comune: "CAORSO",
    sigla: "PC",
    codice_elettorale: 1080610100,
    codice_istat: 33010,
    codice_belfiore: "B643",
  },
  {
    id: 1257,
    comune: "CAPACCIO PAESTUM",
    sigla: "SA",
    codice_elettorale: 3150720250,
    codice_istat: 65025,
    codice_belfiore: "B644",
  },
  {
    id: 1258,
    comune: "CAPACI",
    sigla: "PA",
    codice_elettorale: 4190550180,
    codice_istat: 82020,
    codice_belfiore: "B645",
  },
  {
    id: 1259,
    comune: "CAPALBIO",
    sigla: "GR",
    codice_elettorale: 2090360021,
    codice_istat: 53003,
    codice_belfiore: "B646",
  },
  {
    id: 1260,
    comune: "CAPANNOLI",
    sigla: "PI",
    codice_elettorale: 2090620060,
    codice_istat: 50005,
    codice_belfiore: "B647",
  },
  {
    id: 1261,
    comune: "CAPANNORI",
    sigla: "LU",
    codice_elettorale: 2090430070,
    codice_istat: 46007,
    codice_belfiore: "B648",
  },
  {
    id: 1262,
    comune: "CAPENA",
    sigla: "RM",
    codice_elettorale: 2120700180,
    codice_istat: 58018,
    codice_belfiore: "B649",
  },
  {
    id: 1263,
    comune: "CAPERGNANICA",
    sigla: "CR",
    codice_elettorale: 1030260110,
    codice_istat: 19012,
    codice_belfiore: "B650",
  },
  {
    id: 1264,
    comune: "CAPESTRANO",
    sigla: "AQ",
    codice_elettorale: 3130380190,
    codice_istat: 66019,
    codice_belfiore: "B651",
  },
  {
    id: 1265,
    comune: "CAPIAGO INTIMIANO",
    sigla: "CO",
    codice_elettorale: 1030240420,
    codice_istat: 13043,
    codice_belfiore: "B653",
  },
  {
    id: 1266,
    comune: "CAPISTRANO",
    sigla: "VV",
    codice_elettorale: 3181030050,
    codice_istat: 102005,
    codice_belfiore: "B655",
  },
  {
    id: 1267,
    comune: "CAPISTRELLO",
    sigla: "AQ",
    codice_elettorale: 3130380200,
    codice_istat: 66020,
    codice_belfiore: "B656",
  },
  {
    id: 1268,
    comune: "CAPITIGNANO",
    sigla: "AQ",
    codice_elettorale: 3130380210,
    codice_istat: 66021,
    codice_belfiore: "B658",
  },
  {
    id: 1269,
    comune: "CAPIZZI",
    sigla: "ME",
    codice_elettorale: 4190480080,
    codice_istat: 83008,
    codice_belfiore: "B660",
  },
  {
    id: 1270,
    comune: "CAPIZZONE",
    sigla: "BG",
    codice_elettorale: 1030120480,
    codice_istat: 16050,
    codice_belfiore: "B661",
  },
  {
    id: 1271,
    comune: "CAPO D'ORLANDO",
    sigla: "ME",
    codice_elettorale: 4190480090,
    codice_istat: 83009,
    codice_belfiore: "B666",
  },
  {
    id: 1272,
    comune: "CAPO DI PONTE",
    sigla: "BS",
    codice_elettorale: 1030150310,
    codice_istat: 17035,
    codice_belfiore: "B664",
  },
  {
    id: 1273,
    comune: "CAPODIMONTE",
    sigla: "VT",
    codice_elettorale: 2120910120,
    codice_istat: 56013,
    codice_belfiore: "B663",
  },
  {
    id: 1274,
    comune: "CAPODRISE",
    sigla: "CE",
    codice_elettorale: 3150200130,
    codice_istat: 61013,
    codice_belfiore: "B667",
  },
  {
    id: 1275,
    comune: "CAPOLIVERI",
    sigla: "LI",
    codice_elettorale: 2090420040,
    codice_istat: 49004,
    codice_belfiore: "B669",
  },
  {
    id: 1276,
    comune: "CAPOLONA",
    sigla: "AR",
    codice_elettorale: 2090050060,
    codice_istat: 51006,
    codice_belfiore: "B670",
  },
  {
    id: 1277,
    comune: "CAPONAGO",
    sigla: "MB",
    codice_elettorale: 1031040145,
    codice_istat: 108052,
    codice_belfiore: "B671",
  },
  {
    id: 1278,
    comune: "CAPORCIANO",
    sigla: "AQ",
    codice_elettorale: 3130380220,
    codice_istat: 66022,
    codice_belfiore: "B672",
  },
  {
    id: 1279,
    comune: "CAPOSELE",
    sigla: "AV",
    codice_elettorale: 3150080170,
    codice_istat: 64017,
    codice_belfiore: "B674",
  },
  {
    id: 1280,
    comune: "CAPOTERRA",
    sigla: "CA",
    codice_elettorale: 4200170110,
    codice_istat: 92011,
    codice_belfiore: "B675",
  },
  {
    id: 1281,
    comune: "CAPOVALLE",
    sigla: "BS",
    codice_elettorale: 1030150320,
    codice_istat: 17036,
    codice_belfiore: "B676",
  },
  {
    id: 1282,
    comune: "CAPPADOCIA",
    sigla: "AQ",
    codice_elettorale: 3130380230,
    codice_istat: 66023,
    codice_belfiore: "B677",
  },
  {
    id: 1283,
    comune: "CAPPELLA CANTONE",
    sigla: "CR",
    codice_elettorale: 1030260120,
    codice_istat: 19013,
    codice_belfiore: "B679",
  },
  {
    id: 1284,
    comune: "CAPPELLA DE' PICENARDI",
    sigla: "CR",
    codice_elettorale: 1030260130,
    codice_istat: 19014,
    codice_belfiore: "B680",
  },
  {
    id: 1285,
    comune: "CAPPELLA MAGGIORE",
    sigla: "TV",
    codice_elettorale: 1050840070,
    codice_istat: 26007,
    codice_belfiore: "B678",
  },
  {
    id: 1286,
    comune: "CAPPELLE SUL TAVO",
    sigla: "PE",
    codice_elettorale: 3130600060,
    codice_istat: 68006,
    codice_belfiore: "B681",
  },
  {
    id: 1287,
    comune: "CAPRACOTTA",
    sigla: "IS",
    codice_elettorale: 3140940060,
    codice_istat: 94006,
    codice_belfiore: "B682",
  },
  {
    id: 1288,
    comune: "CAPRAIA E LIMITE",
    sigla: "FI",
    codice_elettorale: 2090300080,
    codice_istat: 48008,
    codice_belfiore: "B684",
  },
  {
    id: 1289,
    comune: "CAPRAIA ISOLA",
    sigla: "LI",
    codice_elettorale: 2090420050,
    codice_istat: 49005,
    codice_belfiore: "B685",
  },
  {
    id: 1290,
    comune: "CAPRALBA",
    sigla: "CR",
    codice_elettorale: 1030260140,
    codice_istat: 19015,
    codice_belfiore: "B686",
  },
  {
    id: 1291,
    comune: "CAPRANICA",
    sigla: "VT",
    codice_elettorale: 2120910130,
    codice_istat: 56014,
    codice_belfiore: "B688",
  },
  {
    id: 1292,
    comune: "CAPRANICA PRENESTINA",
    sigla: "RM",
    codice_elettorale: 2120700190,
    codice_istat: 58019,
    codice_belfiore: "B687",
  },
  {
    id: 1293,
    comune: "CAPRARICA DI LECCE",
    sigla: "LE",
    codice_elettorale: 3160410120,
    codice_istat: 75013,
    codice_belfiore: "B690",
  },
  {
    id: 1294,
    comune: "CAPRAROLA",
    sigla: "VT",
    codice_elettorale: 2120910140,
    codice_istat: 56015,
    codice_belfiore: "B691",
  },
  {
    id: 1295,
    comune: "CAPRAUNA",
    sigla: "CN",
    codice_elettorale: 1010270390,
    codice_istat: 4039,
    codice_belfiore: "B692",
  },
  {
    id: 1296,
    comune: "CAPRESE MICHELANGELO",
    sigla: "AR",
    codice_elettorale: 2090050070,
    codice_istat: 51007,
    codice_belfiore: "B693",
  },
  {
    id: 1297,
    comune: "CAPREZZO",
    sigla: "VB",
    codice_elettorale: 1011020180,
    codice_istat: 103018,
    codice_belfiore: "B694",
  },
  {
    id: 1298,
    comune: "CAPRI",
    sigla: "NA",
    codice_elettorale: 3150510140,
    codice_istat: 63014,
    codice_belfiore: "B696",
  },
  {
    id: 1299,
    comune: "CAPRI LEONE",
    sigla: "ME",
    codice_elettorale: 4190480100,
    codice_istat: 83010,
    codice_belfiore: "B695",
  },
  {
    id: 1300,
    comune: "CAPRIANA",
    sigla: "TN",
    codice_elettorale: 1040830370,
    codice_istat: 22040,
    codice_belfiore: "B697",
  },
  {
    id: 1301,
    comune: "CAPRIANO DEL COLLE",
    sigla: "BS",
    codice_elettorale: 1030150330,
    codice_istat: 17037,
    codice_belfiore: "B698",
  },
  {
    id: 1302,
    comune: "CAPRIATA D'ORBA",
    sigla: "AL",
    codice_elettorale: 1010020290,
    codice_istat: 6029,
    codice_belfiore: "B701",
  },
  {
    id: 1303,
    comune: "CAPRIATE SAN GERVASIO",
    sigla: "BG",
    codice_elettorale: 1030120490,
    codice_istat: 16051,
    codice_belfiore: "B703",
  },
  {
    id: 1304,
    comune: "CAPRIATI A VOLTURNO",
    sigla: "CE",
    codice_elettorale: 3150200140,
    codice_istat: 61014,
    codice_belfiore: "B704",
  },
  {
    id: 1305,
    comune: "CAPRIE",
    sigla: "TO",
    codice_elettorale: 1010810540,
    codice_istat: 1055,
    codice_belfiore: "B705",
  },
  {
    id: 1306,
    comune: "CAPRIGLIA IRPINA",
    sigla: "AV",
    codice_elettorale: 3150080180,
    codice_istat: 64018,
    codice_belfiore: "B706",
  },
  {
    id: 1307,
    comune: "CAPRIGLIO",
    sigla: "AT",
    codice_elettorale: 1010070190,
    codice_istat: 5019,
    codice_belfiore: "B707",
  },
  {
    id: 1308,
    comune: "CAPRILE",
    sigla: "BI",
    codice_elettorale: 1010960130,
    codice_istat: 96013,
    codice_belfiore: "B708",
  },
  {
    id: 1309,
    comune: "CAPRINO BERGAMASCO",
    sigla: "BG",
    codice_elettorale: 1030120500,
    codice_istat: 16052,
    codice_belfiore: "B710",
  },
  {
    id: 1310,
    comune: "CAPRINO VERONESE",
    sigla: "VR",
    codice_elettorale: 1050890180,
    codice_istat: 23018,
    codice_belfiore: "B709",
  },
  {
    id: 1311,
    comune: "CAPRIOLO",
    sigla: "BS",
    codice_elettorale: 1030150340,
    codice_istat: 17038,
    codice_belfiore: "B711",
  },
  {
    id: 1312,
    comune: "CAPRIVA DEL FRIULI",
    sigla: "GO",
    codice_elettorale: 1060350010,
    codice_istat: 31001,
    codice_belfiore: "B712",
  },
  {
    id: 1313,
    comune: "CAPUA",
    sigla: "CE",
    codice_elettorale: 3150200150,
    codice_istat: 61015,
    codice_belfiore: "B715",
  },
  {
    id: 1314,
    comune: "CAPURSO",
    sigla: "BA",
    codice_elettorale: 3160090140,
    codice_istat: 72014,
    codice_belfiore: "B716",
  },
  {
    id: 1315,
    comune: "CARAFFA DEL BIANCO",
    sigla: "RC",
    codice_elettorale: 3180670210,
    codice_istat: 80021,
    codice_belfiore: "B718",
  },
  {
    id: 1316,
    comune: "CARAFFA DI CATANZARO",
    sigla: "CZ",
    codice_elettorale: 3180220160,
    codice_istat: 79017,
    codice_belfiore: "B717",
  },
  {
    id: 1317,
    comune: "CARAGLIO",
    sigla: "CN",
    codice_elettorale: 1010270400,
    codice_istat: 4040,
    codice_belfiore: "B719",
  },
  {
    id: 1318,
    comune: "CARAMAGNA PIEMONTE",
    sigla: "CN",
    codice_elettorale: 1010270410,
    codice_istat: 4041,
    codice_belfiore: "B720",
  },
  {
    id: 1319,
    comune: "CARAMANICO TERME",
    sigla: "PE",
    codice_elettorale: 3130600070,
    codice_istat: 68007,
    codice_belfiore: "B722",
  },
  {
    id: 1320,
    comune: "CARAPELLE",
    sigla: "FG",
    codice_elettorale: 3160310091,
    codice_istat: 71010,
    codice_belfiore: "B724",
  },
  {
    id: 1321,
    comune: "CARAPELLE CALVISIO",
    sigla: "AQ",
    codice_elettorale: 3130380240,
    codice_istat: 66024,
    codice_belfiore: "B725",
  },
  {
    id: 1322,
    comune: "CARASCO",
    sigla: "GE",
    codice_elettorale: 1070340100,
    codice_istat: 10010,
    codice_belfiore: "B726",
  },
  {
    id: 1323,
    comune: "CARASSAI",
    sigla: "AP",
    codice_elettorale: 2110060100,
    codice_istat: 44010,
    codice_belfiore: "B727",
  },
  {
    id: 1324,
    comune: "CARATE BRIANZA",
    sigla: "MB",
    codice_elettorale: 1031040150,
    codice_istat: 108015,
    codice_belfiore: "B729",
  },
  {
    id: 1325,
    comune: "CARATE URIO",
    sigla: "CO",
    codice_elettorale: 1030240430,
    codice_istat: 13044,
    codice_belfiore: "B730",
  },
  {
    id: 1326,
    comune: "CARAVAGGIO",
    sigla: "BG",
    codice_elettorale: 1030120510,
    codice_istat: 16053,
    codice_belfiore: "B731",
  },
  {
    id: 1327,
    comune: "CARAVATE",
    sigla: "VA",
    codice_elettorale: 1030860240,
    codice_istat: 12031,
    codice_belfiore: "B732",
  },
  {
    id: 1328,
    comune: "CARAVINO",
    sigla: "TO",
    codice_elettorale: 1010810550,
    codice_istat: 1056,
    codice_belfiore: "B733",
  },
  {
    id: 1329,
    comune: "CARAVONICA",
    sigla: "IM",
    codice_elettorale: 1070370110,
    codice_istat: 8012,
    codice_belfiore: "B734",
  },
  {
    id: 1330,
    comune: "CARBOGNANO",
    sigla: "VT",
    codice_elettorale: 2120910150,
    codice_istat: 56016,
    codice_belfiore: "B735",
  },
  {
    id: 1331,
    comune: "CARBONARA AL TICINO",
    sigla: "PV",
    codice_elettorale: 1030570280,
    codice_istat: 18030,
    codice_belfiore: "B741",
  },
  {
    id: 1332,
    comune: "CARBONARA DI NOLA",
    sigla: "NA",
    codice_elettorale: 3150510150,
    codice_istat: 63015,
    codice_belfiore: "B740",
  },
  {
    id: 1333,
    comune: "CARBONARA SCRIVIA",
    sigla: "AL",
    codice_elettorale: 1010020300,
    codice_istat: 6030,
    codice_belfiore: "B736",
  },
  {
    id: 1334,
    comune: "CARBONATE",
    sigla: "CO",
    codice_elettorale: 1030240431,
    codice_istat: 13045,
    codice_belfiore: "B742",
  },
  {
    id: 1335,
    comune: "CARBONE",
    sigla: "PZ",
    codice_elettorale: 3170640190,
    codice_istat: 76019,
    codice_belfiore: "B743",
  },
  {
    id: 1336,
    comune: "CARBONERA",
    sigla: "TV",
    codice_elettorale: 1050840080,
    codice_istat: 26008,
    codice_belfiore: "B744",
  },
  {
    id: 1337,
    comune: "CARBONIA",
    sigla: "SU",
    codice_elettorale: 4201110090,
    codice_istat: 111009,
    codice_belfiore: "B745",
  },
  {
    id: 1338,
    comune: "CARCARE",
    sigla: "SV",
    codice_elettorale: 1070740180,
    codice_istat: 9018,
    codice_belfiore: "B748",
  },
  {
    id: 1339,
    comune: "CARCERI",
    sigla: "PD",
    codice_elettorale: 1050540220,
    codice_istat: 28022,
    codice_belfiore: "B749",
  },
  {
    id: 1340,
    comune: "CARCOFORO",
    sigla: "VC",
    codice_elettorale: 1010880290,
    codice_istat: 2029,
    codice_belfiore: "B752",
  },
  {
    id: 1341,
    comune: "CARDANO AL CAMPO",
    sigla: "VA",
    codice_elettorale: 1030860250,
    codice_istat: 12032,
    codice_belfiore: "B754",
  },
  {
    id: 1342,
    comune: "CARDE'",
    sigla: "CN",
    codice_elettorale: 1010270420,
    codice_istat: 4042,
    codice_belfiore: "B755",
  },
  {
    id: 1343,
    comune: "CARDEDU",
    sigla: "NU",
    codice_elettorale: 4200530132,
    codice_istat: 91103,
    codice_belfiore: "M285",
  },
  {
    id: 1344,
    comune: "CARDETO",
    sigla: "RC",
    codice_elettorale: 3180670220,
    codice_istat: 80022,
    codice_belfiore: "B756",
  },
  {
    id: 1345,
    comune: "CARDINALE",
    sigla: "CZ",
    codice_elettorale: 3180220170,
    codice_istat: 79018,
    codice_belfiore: "B758",
  },
  {
    id: 1346,
    comune: "CARDITO",
    sigla: "NA",
    codice_elettorale: 3150510160,
    codice_istat: 63016,
    codice_belfiore: "B759",
  },
  {
    id: 1347,
    comune: "CAREGGINE",
    sigla: "LU",
    codice_elettorale: 2090430080,
    codice_istat: 46008,
    codice_belfiore: "B760",
  },
  {
    id: 1348,
    comune: "CAREMA",
    sigla: "TO",
    codice_elettorale: 1010810560,
    codice_istat: 1057,
    codice_belfiore: "B762",
  },
  {
    id: 1349,
    comune: "CARENNO",
    sigla: "LC",
    codice_elettorale: 1030980140,
    codice_istat: 97014,
    codice_belfiore: "B763",
  },
  {
    id: 1350,
    comune: "CARENTINO",
    sigla: "AL",
    codice_elettorale: 1010020301,
    codice_istat: 6031,
    codice_belfiore: "B765",
  },
  {
    id: 1351,
    comune: "CARERI",
    sigla: "RC",
    codice_elettorale: 3180670230,
    codice_istat: 80023,
    codice_belfiore: "B766",
  },
  {
    id: 1352,
    comune: "CARESANA",
    sigla: "VC",
    codice_elettorale: 1010880300,
    codice_istat: 2030,
    codice_belfiore: "B767",
  },
  {
    id: 1353,
    comune: "CARESANABLOT",
    sigla: "VC",
    codice_elettorale: 1010880310,
    codice_istat: 2031,
    codice_belfiore: "B768",
  },
  {
    id: 1354,
    comune: "CAREZZANO",
    sigla: "AL",
    codice_elettorale: 1010020310,
    codice_istat: 6032,
    codice_belfiore: "B769",
  },
  {
    id: 1355,
    comune: "CARFIZZI",
    sigla: "KR",
    codice_elettorale: 3180970030,
    codice_istat: 101003,
    codice_belfiore: "B771",
  },
  {
    id: 1356,
    comune: "CARGEGHE",
    sigla: "SS",
    codice_elettorale: 4200730220,
    codice_istat: 90022,
    codice_belfiore: "B772",
  },
  {
    id: 1357,
    comune: "CARIATI",
    sigla: "CS",
    codice_elettorale: 3180250250,
    codice_istat: 78025,
    codice_belfiore: "B774",
  },
  {
    id: 1358,
    comune: "CARIFE",
    sigla: "AV",
    codice_elettorale: 3150080190,
    codice_istat: 64019,
    codice_belfiore: "B776",
  },
  {
    id: 1359,
    comune: "CARIGNANO",
    sigla: "TO",
    codice_elettorale: 1010810570,
    codice_istat: 1058,
    codice_belfiore: "B777",
  },
  {
    id: 1360,
    comune: "CARIMATE",
    sigla: "CO",
    codice_elettorale: 1030240440,
    codice_istat: 13046,
    codice_belfiore: "B778",
  },
  {
    id: 1361,
    comune: "CARINARO",
    sigla: "CE",
    codice_elettorale: 3150200160,
    codice_istat: 61016,
    codice_belfiore: "B779",
  },
  {
    id: 1362,
    comune: "CARINI",
    sigla: "PA",
    codice_elettorale: 4190550190,
    codice_istat: 82021,
    codice_belfiore: "B780",
  },
  {
    id: 1363,
    comune: "CARINOLA",
    sigla: "CE",
    codice_elettorale: 3150200170,
    codice_istat: 61017,
    codice_belfiore: "B781",
  },
  {
    id: 1364,
    comune: "CARISIO",
    sigla: "VC",
    codice_elettorale: 1010880320,
    codice_istat: 2032,
    codice_belfiore: "B782",
  },
  {
    id: 1365,
    comune: "CARISOLO",
    sigla: "TN",
    codice_elettorale: 1040830390,
    codice_istat: 22042,
    codice_belfiore: "B783",
  },
  {
    id: 1366,
    comune: "CARLANTINO",
    sigla: "FG",
    codice_elettorale: 3160310100,
    codice_istat: 71011,
    codice_belfiore: "B784",
  },
  {
    id: 1367,
    comune: "CARLAZZO",
    sigla: "CO",
    codice_elettorale: 1030240450,
    codice_istat: 13047,
    codice_belfiore: "B785",
  },
  {
    id: 1368,
    comune: "CARLENTINI",
    sigla: "SR",
    codice_elettorale: 4190760060,
    codice_istat: 89006,
    codice_belfiore: "B787",
  },
  {
    id: 1369,
    comune: "CARLINO",
    sigla: "UD",
    codice_elettorale: 1060850180,
    codice_istat: 30018,
    codice_belfiore: "B788",
  },
  {
    id: 1370,
    comune: "CARLOFORTE",
    sigla: "SU",
    codice_elettorale: 4201110100,
    codice_istat: 111010,
    codice_belfiore: "B789",
  },
  {
    id: 1371,
    comune: "CARLOPOLI",
    sigla: "CZ",
    codice_elettorale: 3180220190,
    codice_istat: 79020,
    codice_belfiore: "B790",
  },
  {
    id: 1372,
    comune: "CARMAGNOLA",
    sigla: "TO",
    codice_elettorale: 1010810580,
    codice_istat: 1059,
    codice_belfiore: "B791",
  },
  {
    id: 1373,
    comune: "CARMIANO",
    sigla: "LE",
    codice_elettorale: 3160410130,
    codice_istat: 75014,
    codice_belfiore: "B792",
  },
  {
    id: 1374,
    comune: "CARMIGNANO",
    sigla: "PO",
    codice_elettorale: 2091000020,
    codice_istat: 100002,
    codice_belfiore: "B794",
  },
  {
    id: 1375,
    comune: "CARMIGNANO DI BRENTA",
    sigla: "PD",
    codice_elettorale: 1050540230,
    codice_istat: 28023,
    codice_belfiore: "B795",
  },
  {
    id: 1376,
    comune: "CARNAGO",
    sigla: "VA",
    codice_elettorale: 1030860260,
    codice_istat: 12033,
    codice_belfiore: "B796",
  },
  {
    id: 1377,
    comune: "CARNATE",
    sigla: "MB",
    codice_elettorale: 1031040160,
    codice_istat: 108016,
    codice_belfiore: "B798",
  },
  {
    id: 1378,
    comune: "CAROBBIO DEGLI ANGELI",
    sigla: "BG",
    codice_elettorale: 1030120530,
    codice_istat: 16055,
    codice_belfiore: "B801",
  },
  {
    id: 1379,
    comune: "CAROLEI",
    sigla: "CS",
    codice_elettorale: 3180250260,
    codice_istat: 78026,
    codice_belfiore: "B802",
  },
  {
    id: 1380,
    comune: "CARONA",
    sigla: "BG",
    codice_elettorale: 1030120540,
    codice_istat: 16056,
    codice_belfiore: "B803",
  },
  {
    id: 1381,
    comune: "CARONIA",
    sigla: "ME",
    codice_elettorale: 4190480110,
    codice_istat: 83011,
    codice_belfiore: "B804",
  },
  {
    id: 1382,
    comune: "CARONNO PERTUSELLA",
    sigla: "VA",
    codice_elettorale: 1030860270,
    codice_istat: 12034,
    codice_belfiore: "B805",
  },
  {
    id: 1383,
    comune: "CARONNO VARESINO",
    sigla: "VA",
    codice_elettorale: 1030860280,
    codice_istat: 12035,
    codice_belfiore: "B807",
  },
  {
    id: 1384,
    comune: "CAROSINO",
    sigla: "TA",
    codice_elettorale: 3160780020,
    codice_istat: 73002,
    codice_belfiore: "B808",
  },
  {
    id: 1385,
    comune: "CAROVIGNO",
    sigla: "BR",
    codice_elettorale: 3160160020,
    codice_istat: 74002,
    codice_belfiore: "B809",
  },
  {
    id: 1386,
    comune: "CAROVILLI",
    sigla: "IS",
    codice_elettorale: 3140940070,
    codice_istat: 94007,
    codice_belfiore: "B810",
  },
  {
    id: 1387,
    comune: "CARPANETO PIACENTINO",
    sigla: "PC",
    codice_elettorale: 1080610110,
    codice_istat: 33011,
    codice_belfiore: "B812",
  },
  {
    id: 1388,
    comune: "CARPANZANO",
    sigla: "CS",
    codice_elettorale: 3180250270,
    codice_istat: 78027,
    codice_belfiore: "B813",
  },
  {
    id: 1389,
    comune: "CARPEGNA",
    sigla: "PU",
    codice_elettorale: 2110590090,
    codice_istat: 41009,
    codice_belfiore: "B816",
  },
  {
    id: 1390,
    comune: "CARPENEDOLO",
    sigla: "BS",
    codice_elettorale: 1030150350,
    codice_istat: 17039,
    codice_belfiore: "B817",
  },
  {
    id: 1391,
    comune: "CARPENETO",
    sigla: "AL",
    codice_elettorale: 1010020320,
    codice_istat: 6033,
    codice_belfiore: "B818",
  },
  {
    id: 1392,
    comune: "CARPI",
    sigla: "MO",
    codice_elettorale: 1080500050,
    codice_istat: 36005,
    codice_belfiore: "B819",
  },
  {
    id: 1393,
    comune: "CARPIANO",
    sigla: "MI",
    codice_elettorale: 1030490500,
    codice_istat: 15050,
    codice_belfiore: "B820",
  },
  {
    id: 1394,
    comune: "CARPIGNANO SALENTINO",
    sigla: "LE",
    codice_elettorale: 3160410140,
    codice_istat: 75015,
    codice_belfiore: "B822",
  },
  {
    id: 1395,
    comune: "CARPIGNANO SESIA",
    sigla: "NO",
    codice_elettorale: 1010520350,
    codice_istat: 3036,
    codice_belfiore: "B823",
  },
  {
    id: 1396,
    comune: "CARPINETI",
    sigla: "RE",
    codice_elettorale: 1080680110,
    codice_istat: 35011,
    codice_belfiore: "B825",
  },
  {
    id: 1397,
    comune: "CARPINETO DELLA NORA",
    sigla: "PE",
    codice_elettorale: 3130600080,
    codice_istat: 68008,
    codice_belfiore: "B827",
  },
  {
    id: 1398,
    comune: "CARPINETO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700200,
    codice_istat: 58020,
    codice_belfiore: "B828",
  },
  {
    id: 1399,
    comune: "CARPINETO SINELLO",
    sigla: "CH",
    codice_elettorale: 3130230110,
    codice_istat: 69011,
    codice_belfiore: "B826",
  },
  {
    id: 1400,
    comune: "CARPINO",
    sigla: "FG",
    codice_elettorale: 3160310110,
    codice_istat: 71012,
    codice_belfiore: "B829",
  },
  {
    id: 1401,
    comune: "CARPINONE",
    sigla: "IS",
    codice_elettorale: 3140940080,
    codice_istat: 94008,
    codice_belfiore: "B830",
  },
  {
    id: 1402,
    comune: "CARRARA",
    sigla: "MS",
    codice_elettorale: 2090460030,
    codice_istat: 45003,
    codice_belfiore: "B832",
  },
  {
    id: 1403,
    comune: "CARRE'",
    sigla: "VI",
    codice_elettorale: 1050900240,
    codice_istat: 24024,
    codice_belfiore: "B835",
  },
  {
    id: 1404,
    comune: "CARREGA LIGURE",
    sigla: "AL",
    codice_elettorale: 1010020330,
    codice_istat: 6034,
    codice_belfiore: "B836",
  },
  {
    id: 1405,
    comune: "CARRO",
    sigla: "SP",
    codice_elettorale: 1070390090,
    codice_istat: 11009,
    codice_belfiore: "B838",
  },
  {
    id: 1406,
    comune: "CARRODANO",
    sigla: "SP",
    codice_elettorale: 1070390100,
    codice_istat: 11010,
    codice_belfiore: "B839",
  },
  {
    id: 1407,
    comune: "CARROSIO",
    sigla: "AL",
    codice_elettorale: 1010020340,
    codice_istat: 6035,
    codice_belfiore: "B840",
  },
  {
    id: 1408,
    comune: "CARRU'",
    sigla: "CN",
    codice_elettorale: 1010270430,
    codice_istat: 4043,
    codice_belfiore: "B841",
  },
  {
    id: 1409,
    comune: "CARSOLI",
    sigla: "AQ",
    codice_elettorale: 3130380250,
    codice_istat: 66025,
    codice_belfiore: "B842",
  },
  {
    id: 1410,
    comune: "CARTIGLIANO",
    sigla: "VI",
    codice_elettorale: 1050900250,
    codice_istat: 24025,
    codice_belfiore: "B844",
  },
  {
    id: 1411,
    comune: "CARTIGNANO",
    sigla: "CN",
    codice_elettorale: 1010270440,
    codice_istat: 4044,
    codice_belfiore: "B845",
  },
  {
    id: 1412,
    comune: "CARTOCETO",
    sigla: "PU",
    codice_elettorale: 2110590100,
    codice_istat: 41010,
    codice_belfiore: "B846",
  },
  {
    id: 1413,
    comune: "CARTOSIO",
    sigla: "AL",
    codice_elettorale: 1010020350,
    codice_istat: 6036,
    codice_belfiore: "B847",
  },
  {
    id: 1414,
    comune: "CARTURA",
    sigla: "PD",
    codice_elettorale: 1050540260,
    codice_istat: 28026,
    codice_belfiore: "B848",
  },
  {
    id: 1415,
    comune: "CARUGATE",
    sigla: "MI",
    codice_elettorale: 1030490510,
    codice_istat: 15051,
    codice_belfiore: "B850",
  },
  {
    id: 1416,
    comune: "CARUGO",
    sigla: "CO",
    codice_elettorale: 1030240460,
    codice_istat: 13048,
    codice_belfiore: "B851",
  },
  {
    id: 1417,
    comune: "CARUNCHIO",
    sigla: "CH",
    codice_elettorale: 3130230120,
    codice_istat: 69012,
    codice_belfiore: "B853",
  },
  {
    id: 1418,
    comune: "CARVICO",
    sigla: "BG",
    codice_elettorale: 1030120550,
    codice_istat: 16057,
    codice_belfiore: "B854",
  },
  {
    id: 1419,
    comune: "CARZANO",
    sigla: "TN",
    codice_elettorale: 1040830400,
    codice_istat: 22043,
    codice_belfiore: "B856",
  },
  {
    id: 1420,
    comune: "CASABONA",
    sigla: "KR",
    codice_elettorale: 3180970040,
    codice_istat: 101004,
    codice_belfiore: "B857",
  },
  {
    id: 1421,
    comune: "CASACALENDA",
    sigla: "CB",
    codice_elettorale: 3140190110,
    codice_istat: 70011,
    codice_belfiore: "B858",
  },
  {
    id: 1422,
    comune: "CASACANDITELLA",
    sigla: "CH",
    codice_elettorale: 3130230130,
    codice_istat: 69013,
    codice_belfiore: "B859",
  },
  {
    id: 1423,
    comune: "CASAGIOVE",
    sigla: "CE",
    codice_elettorale: 3150200180,
    codice_istat: 61018,
    codice_belfiore: "B860",
  },
  {
    id: 1424,
    comune: "CASAL CERMELLI",
    sigla: "AL",
    codice_elettorale: 1010020360,
    codice_istat: 6037,
    codice_belfiore: "B870",
  },
  {
    id: 1425,
    comune: "CASAL DI PRINCIPE",
    sigla: "CE",
    codice_elettorale: 3150200190,
    codice_istat: 61019,
    codice_belfiore: "B872",
  },
  {
    id: 1426,
    comune: "CASAL VELINO",
    sigla: "SA",
    codice_elettorale: 3150720280,
    codice_istat: 65028,
    codice_belfiore: "B895",
  },
  {
    id: 1427,
    comune: "CASALANGUIDA",
    sigla: "CH",
    codice_elettorale: 3130230140,
    codice_istat: 69014,
    codice_belfiore: "B861",
  },
  {
    id: 1428,
    comune: "CASALATTICO",
    sigla: "FR",
    codice_elettorale: 2120330170,
    codice_istat: 60017,
    codice_belfiore: "B862",
  },
  {
    id: 1429,
    comune: "CASALBELTRAME",
    sigla: "NO",
    codice_elettorale: 1010520360,
    codice_istat: 3037,
    codice_belfiore: "B864",
  },
  {
    id: 1430,
    comune: "CASALBORDINO",
    sigla: "CH",
    codice_elettorale: 3130230150,
    codice_istat: 69015,
    codice_belfiore: "B865",
  },
  {
    id: 1431,
    comune: "CASALBORE",
    sigla: "AV",
    codice_elettorale: 3150080200,
    codice_istat: 64020,
    codice_belfiore: "B866",
  },
  {
    id: 1432,
    comune: "CASALBORGONE",
    sigla: "TO",
    codice_elettorale: 1010810590,
    codice_istat: 1060,
    codice_belfiore: "B867",
  },
  {
    id: 1433,
    comune: "CASALBUONO",
    sigla: "SA",
    codice_elettorale: 3150720260,
    codice_istat: 65026,
    codice_belfiore: "B868",
  },
  {
    id: 1434,
    comune: "CASALBUTTANO ED UNITI",
    sigla: "CR",
    codice_elettorale: 1030260150,
    codice_istat: 19016,
    codice_belfiore: "B869",
  },
  {
    id: 1435,
    comune: "CASALCIPRANO",
    sigla: "CB",
    codice_elettorale: 3140190120,
    codice_istat: 70012,
    codice_belfiore: "B871",
  },
  {
    id: 1436,
    comune: "CASALDUNI",
    sigla: "BN",
    codice_elettorale: 3150110150,
    codice_istat: 62015,
    codice_belfiore: "B873",
  },
  {
    id: 1437,
    comune: "CASALE CORTE CERRO",
    sigla: "VB",
    codice_elettorale: 1011020190,
    codice_istat: 103019,
    codice_belfiore: "B876",
  },
  {
    id: 1438,
    comune: "CASALE CREMASCO-VIDOLASCO",
    sigla: "CR",
    codice_elettorale: 1030260160,
    codice_istat: 19017,
    codice_belfiore: "B881",
  },
  {
    id: 1439,
    comune: "CASALE DI SCODOSIA",
    sigla: "PD",
    codice_elettorale: 1050540270,
    codice_istat: 28027,
    codice_belfiore: "B877",
  },
  {
    id: 1440,
    comune: "CASALE LITTA",
    sigla: "VA",
    codice_elettorale: 1030860290,
    codice_istat: 12036,
    codice_belfiore: "B875",
  },
  {
    id: 1441,
    comune: "CASALE MARITTIMO",
    sigla: "PI",
    codice_elettorale: 2090620070,
    codice_istat: 50006,
    codice_belfiore: "B878",
  },
  {
    id: 1442,
    comune: "CASALE MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020380,
    codice_istat: 6039,
    codice_belfiore: "B885",
  },
  {
    id: 1443,
    comune: "CASALE SUL SILE",
    sigla: "TV",
    codice_elettorale: 1050840090,
    codice_istat: 26009,
    codice_belfiore: "B879",
  },
  {
    id: 1444,
    comune: "CASALECCHIO DI RENO",
    sigla: "BO",
    codice_elettorale: 1080130110,
    codice_istat: 37011,
    codice_belfiore: "B880",
  },
  {
    id: 1445,
    comune: "CASALEGGIO BOIRO",
    sigla: "AL",
    codice_elettorale: 1010020370,
    codice_istat: 6038,
    codice_belfiore: "B882",
  },
  {
    id: 1446,
    comune: "CASALEGGIO NOVARA",
    sigla: "NO",
    codice_elettorale: 1010520380,
    codice_istat: 3039,
    codice_belfiore: "B883",
  },
  {
    id: 1447,
    comune: "CASALEONE",
    sigla: "VR",
    codice_elettorale: 1050890190,
    codice_istat: 23019,
    codice_belfiore: "B886",
  },
  {
    id: 1448,
    comune: "CASALETTO CEREDANO",
    sigla: "CR",
    codice_elettorale: 1030260170,
    codice_istat: 19018,
    codice_belfiore: "B889",
  },
  {
    id: 1449,
    comune: "CASALETTO DI SOPRA",
    sigla: "CR",
    codice_elettorale: 1030260180,
    codice_istat: 19019,
    codice_belfiore: "B890",
  },
  {
    id: 1450,
    comune: "CASALETTO LODIGIANO",
    sigla: "LO",
    codice_elettorale: 1030990080,
    codice_istat: 98008,
    codice_belfiore: "B887",
  },
  {
    id: 1451,
    comune: "CASALETTO SPARTANO",
    sigla: "SA",
    codice_elettorale: 3150720270,
    codice_istat: 65027,
    codice_belfiore: "B888",
  },
  {
    id: 1452,
    comune: "CASALETTO VAPRIO",
    sigla: "CR",
    codice_elettorale: 1030260190,
    codice_istat: 19020,
    codice_belfiore: "B891",
  },
  {
    id: 1453,
    comune: "CASALFIUMANESE",
    sigla: "BO",
    codice_elettorale: 1080130120,
    codice_istat: 37012,
    codice_belfiore: "B892",
  },
  {
    id: 1454,
    comune: "CASALGRANDE",
    sigla: "RE",
    codice_elettorale: 1080680120,
    codice_istat: 35012,
    codice_belfiore: "B893",
  },
  {
    id: 1455,
    comune: "CASALGRASSO",
    sigla: "CN",
    codice_elettorale: 1010270450,
    codice_istat: 4045,
    codice_belfiore: "B894",
  },
  {
    id: 1456,
    comune: "CASALI DEL MANCO",
    sigla: "CS",
    codice_elettorale: 3180250275,
    codice_istat: 78156,
    codice_belfiore: "M385",
  },
  {
    id: 1457,
    comune: "CASALINCONTRADA",
    sigla: "CH",
    codice_elettorale: 3130230160,
    codice_istat: 69016,
    codice_belfiore: "B896",
  },
  {
    id: 1458,
    comune: "CASALINO",
    sigla: "NO",
    codice_elettorale: 1010520390,
    codice_istat: 3040,
    codice_belfiore: "B897",
  },
  {
    id: 1459,
    comune: "CASALMAGGIORE",
    sigla: "CR",
    codice_elettorale: 1030260200,
    codice_istat: 19021,
    codice_belfiore: "B898",
  },
  {
    id: 1460,
    comune: "CASALMAIOCCO",
    sigla: "LO",
    codice_elettorale: 1030990090,
    codice_istat: 98009,
    codice_belfiore: "B899",
  },
  {
    id: 1461,
    comune: "CASALMORANO",
    sigla: "CR",
    codice_elettorale: 1030260210,
    codice_istat: 19022,
    codice_belfiore: "B900",
  },
  {
    id: 1462,
    comune: "CASALMORO",
    sigla: "MN",
    codice_elettorale: 1030450100,
    codice_istat: 20010,
    codice_belfiore: "B901",
  },
  {
    id: 1463,
    comune: "CASALNOCETO",
    sigla: "AL",
    codice_elettorale: 1010020390,
    codice_istat: 6040,
    codice_belfiore: "B902",
  },
  {
    id: 1464,
    comune: "CASALNUOVO DI NAPOLI",
    sigla: "NA",
    codice_elettorale: 3150510170,
    codice_istat: 63017,
    codice_belfiore: "B905",
  },
  {
    id: 1465,
    comune: "CASALNUOVO MONTEROTARO",
    sigla: "FG",
    codice_elettorale: 3160310120,
    codice_istat: 71013,
    codice_belfiore: "B904",
  },
  {
    id: 1466,
    comune: "CASALOLDO",
    sigla: "MN",
    codice_elettorale: 1030450110,
    codice_istat: 20011,
    codice_belfiore: "B907",
  },
  {
    id: 1467,
    comune: "CASALPUSTERLENGO",
    sigla: "LO",
    codice_elettorale: 1030990100,
    codice_istat: 98010,
    codice_belfiore: "B910",
  },
  {
    id: 1468,
    comune: "CASALROMANO",
    sigla: "MN",
    codice_elettorale: 1030450120,
    codice_istat: 20012,
    codice_belfiore: "B911",
  },
  {
    id: 1469,
    comune: "CASALSERUGO",
    sigla: "PD",
    codice_elettorale: 1050540280,
    codice_istat: 28028,
    codice_belfiore: "B912",
  },
  {
    id: 1470,
    comune: "CASALUCE",
    sigla: "CE",
    codice_elettorale: 3150200200,
    codice_istat: 61020,
    codice_belfiore: "B916",
  },
  {
    id: 1471,
    comune: "CASALVECCHIO DI PUGLIA",
    sigla: "FG",
    codice_elettorale: 3160310130,
    codice_istat: 71014,
    codice_belfiore: "B917",
  },
  {
    id: 1472,
    comune: "CASALVECCHIO SICULO",
    sigla: "ME",
    codice_elettorale: 4190480120,
    codice_istat: 83012,
    codice_belfiore: "B918",
  },
  {
    id: 1473,
    comune: "CASALVIERI",
    sigla: "FR",
    codice_elettorale: 2120330180,
    codice_istat: 60018,
    codice_belfiore: "B919",
  },
  {
    id: 1474,
    comune: "CASALVOLONE",
    sigla: "NO",
    codice_elettorale: 1010520400,
    codice_istat: 3041,
    codice_belfiore: "B920",
  },
  {
    id: 1475,
    comune: "CASALZUIGNO",
    sigla: "VA",
    codice_elettorale: 1030860300,
    codice_istat: 12037,
    codice_belfiore: "B921",
  },
  {
    id: 1476,
    comune: "CASAMARCIANO",
    sigla: "NA",
    codice_elettorale: 3150510180,
    codice_istat: 63018,
    codice_belfiore: "B922",
  },
  {
    id: 1477,
    comune: "CASAMASSIMA",
    sigla: "BA",
    codice_elettorale: 3160090150,
    codice_istat: 72015,
    codice_belfiore: "B923",
  },
  {
    id: 1478,
    comune: "CASAMICCIOLA TERME",
    sigla: "NA",
    codice_elettorale: 3150510190,
    codice_istat: 63019,
    codice_belfiore: "B924",
  },
  {
    id: 1479,
    comune: "CASANDRINO",
    sigla: "NA",
    codice_elettorale: 3150510200,
    codice_istat: 63020,
    codice_belfiore: "B925",
  },
  {
    id: 1480,
    comune: "CASANOVA ELVO",
    sigla: "VC",
    codice_elettorale: 1010880330,
    codice_istat: 2033,
    codice_belfiore: "B928",
  },
  {
    id: 1481,
    comune: "CASANOVA LERRONE",
    sigla: "SV",
    codice_elettorale: 1070740190,
    codice_istat: 9019,
    codice_belfiore: "B927",
  },
  {
    id: 1482,
    comune: "CASANOVA LONATI",
    sigla: "PV",
    codice_elettorale: 1030570290,
    codice_istat: 18031,
    codice_belfiore: "B929",
  },
  {
    id: 1483,
    comune: "CASAPE",
    sigla: "RM",
    codice_elettorale: 2120700210,
    codice_istat: 58021,
    codice_belfiore: "B932",
  },
  {
    id: 1484,
    comune: "CASAPESENNA",
    sigla: "CE",
    codice_elettorale: 3150200201,
    codice_istat: 61103,
    codice_belfiore: "M260",
  },
  {
    id: 1485,
    comune: "CASAPINTA",
    sigla: "BI",
    codice_elettorale: 1010960140,
    codice_istat: 96014,
    codice_belfiore: "B933",
  },
  {
    id: 1486,
    comune: "CASAPROTA",
    sigla: "RI",
    codice_elettorale: 2120690100,
    codice_istat: 57011,
    codice_belfiore: "B934",
  },
  {
    id: 1487,
    comune: "CASAPULLA",
    sigla: "CE",
    codice_elettorale: 3150200210,
    codice_istat: 61021,
    codice_belfiore: "B935",
  },
  {
    id: 1488,
    comune: "CASARANO",
    sigla: "LE",
    codice_elettorale: 3160410150,
    codice_istat: 75016,
    codice_belfiore: "B936",
  },
  {
    id: 1489,
    comune: "CASARGO",
    sigla: "LC",
    codice_elettorale: 1030980150,
    codice_istat: 97015,
    codice_belfiore: "B937",
  },
  {
    id: 1490,
    comune: "CASARILE",
    sigla: "MI",
    codice_elettorale: 1030490550,
    codice_istat: 15055,
    codice_belfiore: "B938",
  },
  {
    id: 1491,
    comune: "CASARSA DELLA DELIZIA",
    sigla: "PN",
    codice_elettorale: 1060930100,
    codice_istat: 93010,
    codice_belfiore: "B940",
  },
  {
    id: 1492,
    comune: "CASARZA LIGURE",
    sigla: "GE",
    codice_elettorale: 1070340110,
    codice_istat: 10011,
    codice_belfiore: "B939",
  },
  {
    id: 1493,
    comune: "CASASCO",
    sigla: "AL",
    codice_elettorale: 1010020400,
    codice_istat: 6041,
    codice_belfiore: "B941",
  },
  {
    id: 1494,
    comune: "CASATENOVO",
    sigla: "LC",
    codice_elettorale: 1030980160,
    codice_istat: 97016,
    codice_belfiore: "B943",
  },
  {
    id: 1495,
    comune: "CASATISMA",
    sigla: "PV",
    codice_elettorale: 1030570300,
    codice_istat: 18032,
    codice_belfiore: "B945",
  },
  {
    id: 1496,
    comune: "CASAVATORE",
    sigla: "NA",
    codice_elettorale: 3150510210,
    codice_istat: 63021,
    codice_belfiore: "B946",
  },
  {
    id: 1497,
    comune: "CASAZZA",
    sigla: "BG",
    codice_elettorale: 1030120560,
    codice_istat: 16058,
    codice_belfiore: "B947",
  },
  {
    id: 1498,
    comune: "CASCIA",
    sigla: "PG",
    codice_elettorale: 2100580070,
    codice_istat: 54007,
    codice_belfiore: "B948",
  },
  {
    id: 1499,
    comune: "CASCIAGO",
    sigla: "VA",
    codice_elettorale: 1030860310,
    codice_istat: 12038,
    codice_belfiore: "B949",
  },
  {
    id: 1500,
    comune: "CASCIANA TERME LARI",
    sigla: "PI",
    codice_elettorale: 2090620075,
    codice_istat: 50040,
    codice_belfiore: "M327",
  },
  {
    id: 1501,
    comune: "CASCINA",
    sigla: "PI",
    codice_elettorale: 2090620080,
    codice_istat: 50008,
    codice_belfiore: "B950",
  },
  {
    id: 1502,
    comune: "CASCINETTE D'IVREA",
    sigla: "TO",
    codice_elettorale: 1010810600,
    codice_istat: 1061,
    codice_belfiore: "B953",
  },
  {
    id: 1503,
    comune: "CASEI GEROLA",
    sigla: "PV",
    codice_elettorale: 1030570310,
    codice_istat: 18033,
    codice_belfiore: "B954",
  },
  {
    id: 1504,
    comune: "CASELETTE",
    sigla: "TO",
    codice_elettorale: 1010810610,
    codice_istat: 1062,
    codice_belfiore: "B955",
  },
  {
    id: 1505,
    comune: "CASELLA",
    sigla: "GE",
    codice_elettorale: 1070340120,
    codice_istat: 10012,
    codice_belfiore: "B956",
  },
  {
    id: 1506,
    comune: "CASELLE IN PITTARI",
    sigla: "SA",
    codice_elettorale: 3150720290,
    codice_istat: 65029,
    codice_belfiore: "B959",
  },
  {
    id: 1507,
    comune: "CASELLE LANDI",
    sigla: "LO",
    codice_elettorale: 1030990110,
    codice_istat: 98011,
    codice_belfiore: "B961",
  },
  {
    id: 1508,
    comune: "CASELLE LURANI",
    sigla: "LO",
    codice_elettorale: 1030990120,
    codice_istat: 98012,
    codice_belfiore: "B958",
  },
  {
    id: 1509,
    comune: "CASELLE TORINESE",
    sigla: "TO",
    codice_elettorale: 1010810620,
    codice_istat: 1063,
    codice_belfiore: "B960",
  },
  {
    id: 1510,
    comune: "CASERTA",
    sigla: "CE",
    codice_elettorale: 3150200220,
    codice_istat: 61022,
    codice_belfiore: "B963",
  },
  {
    id: 1511,
    comune: "CASIER",
    sigla: "TV",
    codice_elettorale: 1050840100,
    codice_istat: 26010,
    codice_belfiore: "B965",
  },
  {
    id: 1512,
    comune: "CASIGNANA",
    sigla: "RC",
    codice_elettorale: 3180670240,
    codice_istat: 80024,
    codice_belfiore: "B966",
  },
  {
    id: 1513,
    comune: "CASINA",
    sigla: "RE",
    codice_elettorale: 1080680130,
    codice_istat: 35013,
    codice_belfiore: "B967",
  },
  {
    id: 1514,
    comune: "CASIRATE D'ADDA",
    sigla: "BG",
    codice_elettorale: 1030120570,
    codice_istat: 16059,
    codice_belfiore: "B971",
  },
  {
    id: 1515,
    comune: "CASLINO D'ERBA",
    sigla: "CO",
    codice_elettorale: 1030240500,
    codice_istat: 13052,
    codice_belfiore: "B974",
  },
  {
    id: 1516,
    comune: "CASNATE CON BERNATE",
    sigla: "CO",
    codice_elettorale: 1030240510,
    codice_istat: 13053,
    codice_belfiore: "B977",
  },
  {
    id: 1517,
    comune: "CASNIGO",
    sigla: "BG",
    codice_elettorale: 1030120580,
    codice_istat: 16060,
    codice_belfiore: "B978",
  },
  {
    id: 1518,
    comune: "CASOLA DI NAPOLI",
    sigla: "NA",
    codice_elettorale: 3150510220,
    codice_istat: 63022,
    codice_belfiore: "B980",
  },
  {
    id: 1519,
    comune: "CASOLA IN LUNIGIANA",
    sigla: "MS",
    codice_elettorale: 2090460040,
    codice_istat: 45004,
    codice_belfiore: "B979",
  },
  {
    id: 1520,
    comune: "CASOLA VALSENIO",
    sigla: "RA",
    codice_elettorale: 1080660050,
    codice_istat: 39005,
    codice_belfiore: "B982",
  },
  {
    id: 1521,
    comune: "CASOLE D'ELSA",
    sigla: "SI",
    codice_elettorale: 2090750040,
    codice_istat: 52004,
    codice_belfiore: "B984",
  },
  {
    id: 1522,
    comune: "CASOLI",
    sigla: "CH",
    codice_elettorale: 3130230170,
    codice_istat: 69017,
    codice_belfiore: "B985",
  },
  {
    id: 1523,
    comune: "CASORATE PRIMO",
    sigla: "PV",
    codice_elettorale: 1030570320,
    codice_istat: 18034,
    codice_belfiore: "B988",
  },
  {
    id: 1524,
    comune: "CASORATE SEMPIONE",
    sigla: "VA",
    codice_elettorale: 1030860320,
    codice_istat: 12039,
    codice_belfiore: "B987",
  },
  {
    id: 1525,
    comune: "CASOREZZO",
    sigla: "MI",
    codice_elettorale: 1030490580,
    codice_istat: 15058,
    codice_belfiore: "B989",
  },
  {
    id: 1526,
    comune: "CASORIA",
    sigla: "NA",
    codice_elettorale: 3150510230,
    codice_istat: 63023,
    codice_belfiore: "B990",
  },
  {
    id: 1527,
    comune: "CASORZO MONFERRATO",
    sigla: "AT",
    codice_elettorale: 1010070200,
    codice_istat: 5020,
    codice_belfiore: "B991",
  },
  {
    id: 1528,
    comune: "CASPERIA",
    sigla: "RI",
    codice_elettorale: 2120690110,
    codice_istat: 57012,
    codice_belfiore: "A472",
  },
  {
    id: 1529,
    comune: "CASPOGGIO",
    sigla: "SO",
    codice_elettorale: 1030770130,
    codice_istat: 14013,
    codice_belfiore: "B993",
  },
  {
    id: 1530,
    comune: "CASSACCO",
    sigla: "UD",
    codice_elettorale: 1060850190,
    codice_istat: 30019,
    codice_belfiore: "B994",
  },
  {
    id: 1531,
    comune: "CASSAGO BRIANZA",
    sigla: "LC",
    codice_elettorale: 1030980170,
    codice_istat: 97017,
    codice_belfiore: "B996",
  },
  {
    id: 1532,
    comune: "CASSANO ALL'IONIO",
    sigla: "CS",
    codice_elettorale: 3180250290,
    codice_istat: 78029,
    codice_belfiore: "C002",
  },
  {
    id: 1533,
    comune: "CASSANO D'ADDA",
    sigla: "MI",
    codice_elettorale: 1030490590,
    codice_istat: 15059,
    codice_belfiore: "C003",
  },
  {
    id: 1534,
    comune: "CASSANO DELLE MURGE",
    sigla: "BA",
    codice_elettorale: 3160090160,
    codice_istat: 72016,
    codice_belfiore: "B998",
  },
  {
    id: 1535,
    comune: "CASSANO IRPINO",
    sigla: "AV",
    codice_elettorale: 3150080210,
    codice_istat: 64021,
    codice_belfiore: "B997",
  },
  {
    id: 1536,
    comune: "CASSANO MAGNAGO",
    sigla: "VA",
    codice_elettorale: 1030860330,
    codice_istat: 12040,
    codice_belfiore: "C004",
  },
  {
    id: 1537,
    comune: "CASSANO SPINOLA",
    sigla: "AL",
    codice_elettorale: 1010020411,
    codice_istat: 6191,
    codice_belfiore: "M388",
  },
  {
    id: 1538,
    comune: "CASSANO VALCUVIA",
    sigla: "VA",
    codice_elettorale: 1030860340,
    codice_istat: 12041,
    codice_belfiore: "B999",
  },
  {
    id: 1539,
    comune: "CASSARO",
    sigla: "SR",
    codice_elettorale: 4190760070,
    codice_istat: 89007,
    codice_belfiore: "C006",
  },
  {
    id: 1540,
    comune: "CASSIGLIO",
    sigla: "BG",
    codice_elettorale: 1030120590,
    codice_istat: 16061,
    codice_belfiore: "C007",
  },
  {
    id: 1541,
    comune: "CASSINA DE' PECCHI",
    sigla: "MI",
    codice_elettorale: 1030490600,
    codice_istat: 15060,
    codice_belfiore: "C014",
  },
  {
    id: 1542,
    comune: "CASSINA RIZZARDI",
    sigla: "CO",
    codice_elettorale: 1030240530,
    codice_istat: 13055,
    codice_belfiore: "C020",
  },
  {
    id: 1543,
    comune: "CASSINA VALSASSINA",
    sigla: "LC",
    codice_elettorale: 1030980180,
    codice_istat: 97018,
    codice_belfiore: "C024",
  },
  {
    id: 1544,
    comune: "CASSINASCO",
    sigla: "AT",
    codice_elettorale: 1010070210,
    codice_istat: 5021,
    codice_belfiore: "C022",
  },
  {
    id: 1545,
    comune: "CASSINE",
    sigla: "AL",
    codice_elettorale: 1010020420,
    codice_istat: 6043,
    codice_belfiore: "C027",
  },
  {
    id: 1546,
    comune: "CASSINELLE",
    sigla: "AL",
    codice_elettorale: 1010020430,
    codice_istat: 6044,
    codice_belfiore: "C030",
  },
  {
    id: 1547,
    comune: "CASSINETTA DI LUGAGNANO",
    sigla: "MI",
    codice_elettorale: 1030490610,
    codice_istat: 15061,
    codice_belfiore: "C033",
  },
  {
    id: 1548,
    comune: "CASSINO",
    sigla: "FR",
    codice_elettorale: 2120330190,
    codice_istat: 60019,
    codice_belfiore: "C034",
  },
  {
    id: 1549,
    comune: "CASSOLA",
    sigla: "VI",
    codice_elettorale: 1050900260,
    codice_istat: 24026,
    codice_belfiore: "C037",
  },
  {
    id: 1550,
    comune: "CASSOLNOVO",
    sigla: "PV",
    codice_elettorale: 1030570330,
    codice_istat: 18035,
    codice_belfiore: "C038",
  },
  {
    id: 1551,
    comune: "CASTAGNARO",
    sigla: "VR",
    codice_elettorale: 1050890200,
    codice_istat: 23020,
    codice_belfiore: "C041",
  },
  {
    id: 1552,
    comune: "CASTAGNETO CARDUCCI",
    sigla: "LI",
    codice_elettorale: 2090420060,
    codice_istat: 49006,
    codice_belfiore: "C044",
  },
  {
    id: 1553,
    comune: "CASTAGNETO PO",
    sigla: "TO",
    codice_elettorale: 1010810630,
    codice_istat: 1064,
    codice_belfiore: "C045",
  },
  {
    id: 1554,
    comune: "CASTAGNITO",
    sigla: "CN",
    codice_elettorale: 1010270460,
    codice_istat: 4046,
    codice_belfiore: "C046",
  },
  {
    id: 1555,
    comune: "CASTAGNOLE DELLE LANZE",
    sigla: "AT",
    codice_elettorale: 1010070220,
    codice_istat: 5022,
    codice_belfiore: "C049",
  },
  {
    id: 1556,
    comune: "CASTAGNOLE MONFERRATO",
    sigla: "AT",
    codice_elettorale: 1010070230,
    codice_istat: 5023,
    codice_belfiore: "C047",
  },
  {
    id: 1557,
    comune: "CASTAGNOLE PIEMONTE",
    sigla: "TO",
    codice_elettorale: 1010810640,
    codice_istat: 1065,
    codice_belfiore: "C048",
  },
  {
    id: 1558,
    comune: "CASTANA",
    sigla: "PV",
    codice_elettorale: 1030570340,
    codice_istat: 18036,
    codice_belfiore: "C050",
  },
  {
    id: 1559,
    comune: "CASTANO PRIMO",
    sigla: "MI",
    codice_elettorale: 1030490620,
    codice_istat: 15062,
    codice_belfiore: "C052",
  },
  {
    id: 1560,
    comune: "CASTEGGIO",
    sigla: "PV",
    codice_elettorale: 1030570350,
    codice_istat: 18037,
    codice_belfiore: "C053",
  },
  {
    id: 1561,
    comune: "CASTEGNATO",
    sigla: "BS",
    codice_elettorale: 1030150360,
    codice_istat: 17040,
    codice_belfiore: "C055",
  },
  {
    id: 1562,
    comune: "CASTEGNERO",
    sigla: "VI",
    codice_elettorale: 1050900270,
    codice_istat: 24027,
    codice_belfiore: "C056",
  },
  {
    id: 1563,
    comune: "CASTEL BARONIA",
    sigla: "AV",
    codice_elettorale: 3150080220,
    codice_istat: 64022,
    codice_belfiore: "C058",
  },
  {
    id: 1564,
    comune: "CASTEL BOGLIONE",
    sigla: "AT",
    codice_elettorale: 1010070240,
    codice_istat: 5024,
    codice_belfiore: "C064",
  },
  {
    id: 1565,
    comune: "CASTEL BOLOGNESE",
    sigla: "RA",
    codice_elettorale: 1080660060,
    codice_istat: 39006,
    codice_belfiore: "C065",
  },
  {
    id: 1566,
    comune: "CASTEL CAMPAGNANO",
    sigla: "CE",
    codice_elettorale: 3150200230,
    codice_istat: 61023,
    codice_belfiore: "B494",
  },
  {
    id: 1567,
    comune: "CASTEL CASTAGNA",
    sigla: "TE",
    codice_elettorale: 3130790090,
    codice_istat: 67010,
    codice_belfiore: "C040",
  },
  {
    id: 1568,
    comune: "CASTEL CONDINO",
    sigla: "TN",
    codice_elettorale: 1040830430,
    codice_istat: 22045,
    codice_belfiore: "C183",
  },
  {
    id: 1569,
    comune: "CASTEL D'AIANO",
    sigla: "BO",
    codice_elettorale: 1080130130,
    codice_istat: 37013,
    codice_belfiore: "C075",
  },
  {
    id: 1570,
    comune: "CASTEL D'ARIO",
    sigla: "MN",
    codice_elettorale: 1030450140,
    codice_istat: 20014,
    codice_belfiore: "C076",
  },
  {
    id: 1571,
    comune: "CASTEL D'AZZANO",
    sigla: "VR",
    codice_elettorale: 1050890210,
    codice_istat: 23021,
    codice_belfiore: "C078",
  },
  {
    id: 1572,
    comune: "CASTEL DEL GIUDICE",
    sigla: "IS",
    codice_elettorale: 3140940090,
    codice_istat: 94009,
    codice_belfiore: "C082",
  },
  {
    id: 1573,
    comune: "CASTEL DEL MONTE",
    sigla: "AQ",
    codice_elettorale: 3130380260,
    codice_istat: 66026,
    codice_belfiore: "C083",
  },
  {
    id: 1574,
    comune: "CASTEL DEL PIANO",
    sigla: "GR",
    codice_elettorale: 2090360030,
    codice_istat: 53004,
    codice_belfiore: "C085",
  },
  {
    id: 1575,
    comune: "CASTEL DEL RIO",
    sigla: "BO",
    codice_elettorale: 1080130140,
    codice_istat: 37014,
    codice_belfiore: "C086",
  },
  {
    id: 1576,
    comune: "CASTEL DI CASIO",
    sigla: "BO",
    codice_elettorale: 1080130150,
    codice_istat: 37015,
    codice_belfiore: "B969",
  },
  {
    id: 1577,
    comune: "CASTEL DI IERI",
    sigla: "AQ",
    codice_elettorale: 3130380270,
    codice_istat: 66027,
    codice_belfiore: "C090",
  },
  {
    id: 1578,
    comune: "CASTEL DI IUDICA",
    sigla: "CT",
    codice_elettorale: 4190210130,
    codice_istat: 87013,
    codice_belfiore: "C091",
  },
  {
    id: 1579,
    comune: "CASTEL DI LAMA",
    sigla: "AP",
    codice_elettorale: 2110060110,
    codice_istat: 44011,
    codice_belfiore: "C093",
  },
  {
    id: 1580,
    comune: "CASTEL DI LUCIO",
    sigla: "ME",
    codice_elettorale: 4190480130,
    codice_istat: 83013,
    codice_belfiore: "C094",
  },
  {
    id: 1581,
    comune: "CASTEL DI SANGRO",
    sigla: "AQ",
    codice_elettorale: 3130380280,
    codice_istat: 66028,
    codice_belfiore: "C096",
  },
  {
    id: 1582,
    comune: "CASTEL DI SASSO",
    sigla: "CE",
    codice_elettorale: 3150200240,
    codice_istat: 61024,
    codice_belfiore: "C097",
  },
  {
    id: 1583,
    comune: "CASTEL DI TORA",
    sigla: "RI",
    codice_elettorale: 2120690120,
    codice_istat: 57013,
    codice_belfiore: "C098",
  },
  {
    id: 1584,
    comune: "CASTEL FOCOGNANO",
    sigla: "AR",
    codice_elettorale: 2090050080,
    codice_istat: 51008,
    codice_belfiore: "C102",
  },
  {
    id: 1585,
    comune: "CASTEL FRENTANO",
    sigla: "CH",
    codice_elettorale: 3130230180,
    codice_istat: 69018,
    codice_belfiore: "C114",
  },
  {
    id: 1586,
    comune: "CASTEL GABBIANO",
    sigla: "CR",
    codice_elettorale: 1030260230,
    codice_istat: 19024,
    codice_belfiore: "C115",
  },
  {
    id: 1587,
    comune: "CASTEL GANDOLFO",
    sigla: "RM",
    codice_elettorale: 2120700220,
    codice_istat: 58022,
    codice_belfiore: "C116",
  },
  {
    id: 1588,
    comune: "CASTEL GIORGIO",
    sigla: "TR",
    codice_elettorale: 2100800090,
    codice_istat: 55009,
    codice_belfiore: "C117",
  },
  {
    id: 1589,
    comune: "CASTEL GOFFREDO",
    sigla: "MN",
    codice_elettorale: 1030450150,
    codice_istat: 20015,
    codice_belfiore: "C118",
  },
  {
    id: 1590,
    comune: "CASTEL GUELFO DI BOLOGNA",
    sigla: "BO",
    codice_elettorale: 1080130160,
    codice_istat: 37016,
    codice_belfiore: "C121",
  },
  {
    id: 1591,
    comune: "CASTEL IVANO",
    sigla: "TN",
    codice_elettorale: 1040830435,
    codice_istat: 22240,
    codice_belfiore: "M354",
  },
  {
    id: 1592,
    comune: "CASTEL MADAMA",
    sigla: "RM",
    codice_elettorale: 2120700230,
    codice_istat: 58023,
    codice_belfiore: "C203",
  },
  {
    id: 1593,
    comune: "CASTEL MAGGIORE",
    sigla: "BO",
    codice_elettorale: 1080130190,
    codice_istat: 37019,
    codice_belfiore: "C204",
  },
  {
    id: 1594,
    comune: "CASTEL MELLA",
    sigla: "BS",
    codice_elettorale: 1030150380,
    codice_istat: 17042,
    codice_belfiore: "C208",
  },
  {
    id: 1595,
    comune: "CASTEL MORRONE",
    sigla: "CE",
    codice_elettorale: 3150200260,
    codice_istat: 61026,
    codice_belfiore: "C211",
  },
  {
    id: 1596,
    comune: "CASTEL RITALDI",
    sigla: "PG",
    codice_elettorale: 2100580080,
    codice_istat: 54008,
    codice_belfiore: "C252",
  },
  {
    id: 1597,
    comune: "CASTEL ROCCHERO",
    sigla: "AT",
    codice_elettorale: 1010070320,
    codice_istat: 5032,
    codice_belfiore: "C253",
  },
  {
    id: 1598,
    comune: "CASTEL ROZZONE",
    sigla: "BG",
    codice_elettorale: 1030120610,
    codice_istat: 16063,
    codice_belfiore: "C255",
  },
  {
    id: 1599,
    comune: "CASTEL SAN GIORGIO",
    sigla: "SA",
    codice_elettorale: 3150720340,
    codice_istat: 65034,
    codice_belfiore: "C259",
  },
  {
    id: 1600,
    comune: "CASTEL SAN GIOVANNI",
    sigla: "PC",
    codice_elettorale: 1080610130,
    codice_istat: 33013,
    codice_belfiore: "C261",
  },
  {
    id: 1601,
    comune: "CASTEL SAN LORENZO",
    sigla: "SA",
    codice_elettorale: 3150720350,
    codice_istat: 65035,
    codice_belfiore: "C262",
  },
  {
    id: 1602,
    comune: "CASTEL SAN NICCOLO'",
    sigla: "AR",
    codice_elettorale: 2090050100,
    codice_istat: 51010,
    codice_belfiore: "C263",
  },
  {
    id: 1603,
    comune: "CASTEL SAN PIETRO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700250,
    codice_istat: 58025,
    codice_belfiore: "C266",
  },
  {
    id: 1604,
    comune: "CASTEL SAN PIETRO TERME",
    sigla: "BO",
    codice_elettorale: 1080130200,
    codice_istat: 37020,
    codice_belfiore: "C265",
  },
  {
    id: 1605,
    comune: "CASTEL SAN VINCENZO",
    sigla: "IS",
    codice_elettorale: 3140940120,
    codice_istat: 94012,
    codice_belfiore: "C270",
  },
  {
    id: 1606,
    comune: "CASTEL SANT'ANGELO",
    sigla: "RI",
    codice_elettorale: 2120690140,
    codice_istat: 57015,
    codice_belfiore: "C268",
  },
  {
    id: 1607,
    comune: "CASTEL SANT'ELIA",
    sigla: "VT",
    codice_elettorale: 2120910160,
    codice_istat: 56017,
    codice_belfiore: "C269",
  },
  {
    id: 1608,
    comune: "CASTEL VISCARDO",
    sigla: "TR",
    codice_elettorale: 2100800100,
    codice_istat: 55010,
    codice_belfiore: "C289",
  },
  {
    id: 1609,
    comune: "CASTEL VITTORIO",
    sigla: "IM",
    codice_elettorale: 1070370140,
    codice_istat: 8015,
    codice_belfiore: "C110",
  },
  {
    id: 1610,
    comune: "CASTEL VOLTURNO",
    sigla: "CE",
    codice_elettorale: 3150200270,
    codice_istat: 61027,
    codice_belfiore: "C291",
  },
  {
    id: 1611,
    comune: "CASTELBALDO",
    sigla: "PD",
    codice_elettorale: 1050540290,
    codice_istat: 28029,
    codice_belfiore: "C057",
  },
  {
    id: 1612,
    comune: "CASTELBELFORTE",
    sigla: "MN",
    codice_elettorale: 1030450130,
    codice_istat: 20013,
    codice_belfiore: "C059",
  },
  {
    id: 1613,
    comune: "CASTELBELLINO",
    sigla: "AN",
    codice_elettorale: 2110030080,
    codice_istat: 42008,
    codice_belfiore: "C060",
  },
  {
    id: 1614,
    comune: "CASTELBELLO-CIARDES/KASTELBELL-TSCHARS",
    sigla: "BZ",
    codice_elettorale: 1040140150,
    codice_istat: 21018,
    codice_belfiore: "C062",
  },
  {
    id: 1615,
    comune: "CASTELBIANCO",
    sigla: "SV",
    codice_elettorale: 1070740200,
    codice_istat: 9020,
    codice_belfiore: "C063",
  },
  {
    id: 1616,
    comune: "CASTELBOTTACCIO",
    sigla: "CB",
    codice_elettorale: 3140190130,
    codice_istat: 70013,
    codice_belfiore: "C066",
  },
  {
    id: 1617,
    comune: "CASTELBUONO",
    sigla: "PA",
    codice_elettorale: 4190550200,
    codice_istat: 82022,
    codice_belfiore: "C067",
  },
  {
    id: 1618,
    comune: "CASTELCIVITA",
    sigla: "SA",
    codice_elettorale: 3150720300,
    codice_istat: 65030,
    codice_belfiore: "C069",
  },
  {
    id: 1619,
    comune: "CASTELCOVATI",
    sigla: "BS",
    codice_elettorale: 1030150370,
    codice_istat: 17041,
    codice_belfiore: "C072",
  },
  {
    id: 1620,
    comune: "CASTELCUCCO",
    sigla: "TV",
    codice_elettorale: 1050840110,
    codice_istat: 26011,
    codice_belfiore: "C073",
  },
  {
    id: 1621,
    comune: "CASTELDACCIA",
    sigla: "PA",
    codice_elettorale: 4190550210,
    codice_istat: 82023,
    codice_belfiore: "C074",
  },
  {
    id: 1622,
    comune: "CASTELDELCI",
    sigla: "RN",
    codice_elettorale: 1081010015,
    codice_istat: 99021,
    codice_belfiore: "C080",
  },
  {
    id: 1623,
    comune: "CASTELDELFINO",
    sigla: "CN",
    codice_elettorale: 1010270470,
    codice_istat: 4047,
    codice_belfiore: "C081",
  },
  {
    id: 1624,
    comune: "CASTELDIDONE",
    sigla: "CR",
    codice_elettorale: 1030260220,
    codice_istat: 19023,
    codice_belfiore: "C089",
  },
  {
    id: 1625,
    comune: "CASTELFIDARDO",
    sigla: "AN",
    codice_elettorale: 2110030100,
    codice_istat: 42010,
    codice_belfiore: "C100",
  },
  {
    id: 1626,
    comune: "CASTELFIORENTINO",
    sigla: "FI",
    codice_elettorale: 2090300100,
    codice_istat: 48010,
    codice_belfiore: "C101",
  },
  {
    id: 1627,
    comune: "CASTELFORTE",
    sigla: "LT",
    codice_elettorale: 2120400040,
    codice_istat: 59004,
    codice_belfiore: "C104",
  },
  {
    id: 1628,
    comune: "CASTELFRANCI",
    sigla: "AV",
    codice_elettorale: 3150080230,
    codice_istat: 64023,
    codice_belfiore: "C105",
  },
  {
    id: 1629,
    comune: "CASTELFRANCO DI SOTTO",
    sigla: "PI",
    codice_elettorale: 2090620090,
    codice_istat: 50009,
    codice_belfiore: "C113",
  },
  {
    id: 1630,
    comune: "CASTELFRANCO EMILIA",
    sigla: "MO",
    codice_elettorale: 1080500060,
    codice_istat: 36006,
    codice_belfiore: "C107",
  },
  {
    id: 1631,
    comune: "CASTELFRANCO IN MISCANO",
    sigla: "BN",
    codice_elettorale: 3150110160,
    codice_istat: 62016,
    codice_belfiore: "C106",
  },
  {
    id: 1632,
    comune: "CASTELFRANCO PIANDISCO'",
    sigla: "AR",
    codice_elettorale: 2090050095,
    codice_istat: 51040,
    codice_belfiore: "M322",
  },
  {
    id: 1633,
    comune: "CASTELFRANCO VENETO",
    sigla: "TV",
    codice_elettorale: 1050840120,
    codice_istat: 26012,
    codice_belfiore: "C111",
  },
  {
    id: 1634,
    comune: "CASTELGERUNDO",
    sigla: "LO",
    codice_elettorale: 1030990125,
    codice_istat: 98062,
    codice_belfiore: "M393",
  },
  {
    id: 1635,
    comune: "CASTELGOMBERTO",
    sigla: "VI",
    codice_elettorale: 1050900280,
    codice_istat: 24028,
    codice_belfiore: "C119",
  },
  {
    id: 1636,
    comune: "CASTELGRANDE",
    sigla: "PZ",
    codice_elettorale: 3170640210,
    codice_istat: 76021,
    codice_belfiore: "C120",
  },
  {
    id: 1637,
    comune: "CASTELGUGLIELMO",
    sigla: "RO",
    codice_elettorale: 1050710110,
    codice_istat: 29011,
    codice_belfiore: "C122",
  },
  {
    id: 1638,
    comune: "CASTELGUIDONE",
    sigla: "CH",
    codice_elettorale: 3130230190,
    codice_istat: 69019,
    codice_belfiore: "C123",
  },
  {
    id: 1639,
    comune: "CASTELL'ALFERO",
    sigla: "AT",
    codice_elettorale: 1010070250,
    codice_istat: 5025,
    codice_belfiore: "C127",
  },
  {
    id: 1640,
    comune: "CASTELL'ARQUATO",
    sigla: "PC",
    codice_elettorale: 1080610120,
    codice_istat: 33012,
    codice_belfiore: "C145",
  },
  {
    id: 1641,
    comune: "CASTELL'AZZARA",
    sigla: "GR",
    codice_elettorale: 2090360040,
    codice_istat: 53005,
    codice_belfiore: "C147",
  },
  {
    id: 1642,
    comune: "CASTELL'UMBERTO",
    sigla: "ME",
    codice_elettorale: 4190480140,
    codice_istat: 83014,
    codice_belfiore: "C051",
  },
  {
    id: 1643,
    comune: "CASTELLABATE",
    sigla: "SA",
    codice_elettorale: 3150720310,
    codice_istat: 65031,
    codice_belfiore: "C125",
  },
  {
    id: 1644,
    comune: "CASTELLAFIUME",
    sigla: "AQ",
    codice_elettorale: 3130380290,
    codice_istat: 66029,
    codice_belfiore: "C126",
  },
  {
    id: 1645,
    comune: "CASTELLALTO",
    sigla: "TE",
    codice_elettorale: 3130790100,
    codice_istat: 67011,
    codice_belfiore: "C128",
  },
  {
    id: 1646,
    comune: "CASTELLAMMARE DEL GOLFO",
    sigla: "TP",
    codice_elettorale: 4190820060,
    codice_istat: 81005,
    codice_belfiore: "C130",
  },
  {
    id: 1647,
    comune: "CASTELLAMMARE DI STABIA",
    sigla: "NA",
    codice_elettorale: 3150510240,
    codice_istat: 63024,
    codice_belfiore: "C129",
  },
  {
    id: 1648,
    comune: "CASTELLAMONTE",
    sigla: "TO",
    codice_elettorale: 1010810650,
    codice_istat: 1066,
    codice_belfiore: "C133",
  },
  {
    id: 1649,
    comune: "CASTELLANA GROTTE",
    sigla: "BA",
    codice_elettorale: 3160090170,
    codice_istat: 72017,
    codice_belfiore: "C134",
  },
  {
    id: 1650,
    comune: "CASTELLANA SICULA",
    sigla: "PA",
    codice_elettorale: 4190550220,
    codice_istat: 82024,
    codice_belfiore: "C135",
  },
  {
    id: 1651,
    comune: "CASTELLANETA",
    sigla: "TA",
    codice_elettorale: 3160780030,
    codice_istat: 73003,
    codice_belfiore: "C136",
  },
  {
    id: 1652,
    comune: "CASTELLANIA COPPI",
    sigla: "AL",
    codice_elettorale: 1010020440,
    codice_istat: 6045,
    codice_belfiore: "C137",
  },
  {
    id: 1653,
    comune: "CASTELLANZA",
    sigla: "VA",
    codice_elettorale: 1030860350,
    codice_istat: 12042,
    codice_belfiore: "C139",
  },
  {
    id: 1654,
    comune: "CASTELLAR GUIDOBONO",
    sigla: "AL",
    codice_elettorale: 1010020450,
    codice_istat: 6046,
    codice_belfiore: "C142",
  },
  {
    id: 1655,
    comune: "CASTELLARANO",
    sigla: "RE",
    codice_elettorale: 1080680140,
    codice_istat: 35014,
    codice_belfiore: "C141",
  },
  {
    id: 1656,
    comune: "CASTELLARO",
    sigla: "IM",
    codice_elettorale: 1070370130,
    codice_istat: 8014,
    codice_belfiore: "C143",
  },
  {
    id: 1657,
    comune: "CASTELLAZZO BORMIDA",
    sigla: "AL",
    codice_elettorale: 1010020460,
    codice_istat: 6047,
    codice_belfiore: "C148",
  },
  {
    id: 1658,
    comune: "CASTELLAZZO NOVARESE",
    sigla: "NO",
    codice_elettorale: 1010520410,
    codice_istat: 3042,
    codice_belfiore: "C149",
  },
  {
    id: 1659,
    comune: "CASTELLEONE",
    sigla: "CR",
    codice_elettorale: 1030260240,
    codice_istat: 19025,
    codice_belfiore: "C153",
  },
  {
    id: 1660,
    comune: "CASTELLEONE DI SUASA",
    sigla: "AN",
    codice_elettorale: 2110030110,
    codice_istat: 42011,
    codice_belfiore: "C152",
  },
  {
    id: 1661,
    comune: "CASTELLERO",
    sigla: "AT",
    codice_elettorale: 1010070260,
    codice_istat: 5026,
    codice_belfiore: "C154",
  },
  {
    id: 1662,
    comune: "CASTELLETTO CERVO",
    sigla: "BI",
    codice_elettorale: 1010960150,
    codice_istat: 96015,
    codice_belfiore: "C155",
  },
  {
    id: 1663,
    comune: "CASTELLETTO D'ERRO",
    sigla: "AL",
    codice_elettorale: 1010020470,
    codice_istat: 6048,
    codice_belfiore: "C156",
  },
  {
    id: 1664,
    comune: "CASTELLETTO D'ORBA",
    sigla: "AL",
    codice_elettorale: 1010020480,
    codice_istat: 6049,
    codice_belfiore: "C158",
  },
  {
    id: 1665,
    comune: "CASTELLETTO DI BRANDUZZO",
    sigla: "PV",
    codice_elettorale: 1030570360,
    codice_istat: 18038,
    codice_belfiore: "C157",
  },
  {
    id: 1666,
    comune: "CASTELLETTO MERLI",
    sigla: "AL",
    codice_elettorale: 1010020490,
    codice_istat: 6050,
    codice_belfiore: "C160",
  },
  {
    id: 1667,
    comune: "CASTELLETTO MOLINA",
    sigla: "AT",
    codice_elettorale: 1010070270,
    codice_istat: 5027,
    codice_belfiore: "C161",
  },
  {
    id: 1668,
    comune: "CASTELLETTO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020500,
    codice_istat: 6051,
    codice_belfiore: "C162",
  },
  {
    id: 1669,
    comune: "CASTELLETTO SOPRA TICINO",
    sigla: "NO",
    codice_elettorale: 1010520420,
    codice_istat: 3043,
    codice_belfiore: "C166",
  },
  {
    id: 1670,
    comune: "CASTELLETTO STURA",
    sigla: "CN",
    codice_elettorale: 1010270490,
    codice_istat: 4049,
    codice_belfiore: "C165",
  },
  {
    id: 1671,
    comune: "CASTELLETTO UZZONE",
    sigla: "CN",
    codice_elettorale: 1010270500,
    codice_istat: 4050,
    codice_belfiore: "C167",
  },
  {
    id: 1672,
    comune: "CASTELLI",
    sigla: "TE",
    codice_elettorale: 3130790110,
    codice_istat: 67012,
    codice_belfiore: "C169",
  },
  {
    id: 1673,
    comune: "CASTELLI CALEPIO",
    sigla: "BG",
    codice_elettorale: 1030120600,
    codice_istat: 16062,
    codice_belfiore: "C079",
  },
  {
    id: 1674,
    comune: "CASTELLINA IN CHIANTI",
    sigla: "SI",
    codice_elettorale: 2090750050,
    codice_istat: 52005,
    codice_belfiore: "C172",
  },
  {
    id: 1675,
    comune: "CASTELLINA MARITTIMA",
    sigla: "PI",
    codice_elettorale: 2090620100,
    codice_istat: 50010,
    codice_belfiore: "C174",
  },
  {
    id: 1676,
    comune: "CASTELLINALDO D'ALBA",
    sigla: "CN",
    codice_elettorale: 1010270510,
    codice_istat: 4051,
    codice_belfiore: "C173",
  },
  {
    id: 1677,
    comune: "CASTELLINO DEL BIFERNO",
    sigla: "CB",
    codice_elettorale: 3140190140,
    codice_istat: 70014,
    codice_belfiore: "C175",
  },
  {
    id: 1678,
    comune: "CASTELLINO TANARO",
    sigla: "CN",
    codice_elettorale: 1010270520,
    codice_istat: 4052,
    codice_belfiore: "C176",
  },
  {
    id: 1679,
    comune: "CASTELLIRI",
    sigla: "FR",
    codice_elettorale: 2120330200,
    codice_istat: 60020,
    codice_belfiore: "C177",
  },
  {
    id: 1680,
    comune: "CASTELLO CABIAGLIO",
    sigla: "VA",
    codice_elettorale: 1030860360,
    codice_istat: 12043,
    codice_belfiore: "B312",
  },
  {
    id: 1681,
    comune: "CASTELLO D'AGOGNA",
    sigla: "PV",
    codice_elettorale: 1030570370,
    codice_istat: 18039,
    codice_belfiore: "C184",
  },
  {
    id: 1682,
    comune: "CASTELLO D'ARGILE",
    sigla: "BO",
    codice_elettorale: 1080130170,
    codice_istat: 37017,
    codice_belfiore: "C185",
  },
  {
    id: 1683,
    comune: "CASTELLO DEL MATESE",
    sigla: "CE",
    codice_elettorale: 3150200250,
    codice_istat: 61025,
    codice_belfiore: "C178",
  },
  {
    id: 1684,
    comune: "CASTELLO DELL'ACQUA",
    sigla: "SO",
    codice_elettorale: 1030770140,
    codice_istat: 14014,
    codice_belfiore: "C186",
  },
  {
    id: 1685,
    comune: "CASTELLO DI ANNONE",
    sigla: "AT",
    codice_elettorale: 1010070280,
    codice_istat: 5028,
    codice_belfiore: "A300",
  },
  {
    id: 1686,
    comune: "CASTELLO DI BRIANZA",
    sigla: "LC",
    codice_elettorale: 1030980190,
    codice_istat: 97019,
    codice_belfiore: "C187",
  },
  {
    id: 1687,
    comune: "CASTELLO DI CISTERNA",
    sigla: "NA",
    codice_elettorale: 3150510250,
    codice_istat: 63025,
    codice_belfiore: "C188",
  },
  {
    id: 1688,
    comune: "CASTELLO DI GODEGO",
    sigla: "TV",
    codice_elettorale: 1050840130,
    codice_istat: 26013,
    codice_belfiore: "C190",
  },
  {
    id: 1689,
    comune: "CASTELLO TESINO",
    sigla: "TN",
    codice_elettorale: 1040830450,
    codice_istat: 22048,
    codice_belfiore: "C194",
  },
  {
    id: 1690,
    comune: "CASTELLO-MOLINA DI FIEMME",
    sigla: "TN",
    codice_elettorale: 1040830440,
    codice_istat: 22047,
    codice_belfiore: "C189",
  },
  {
    id: 1691,
    comune: "CASTELLUCCHIO",
    sigla: "MN",
    codice_elettorale: 1030450160,
    codice_istat: 20016,
    codice_belfiore: "C195",
  },
  {
    id: 1692,
    comune: "CASTELLUCCIO DEI SAURI",
    sigla: "FG",
    codice_elettorale: 3160310140,
    codice_istat: 71015,
    codice_belfiore: "C198",
  },
  {
    id: 1693,
    comune: "CASTELLUCCIO INFERIORE",
    sigla: "PZ",
    codice_elettorale: 3170640220,
    codice_istat: 76022,
    codice_belfiore: "C199",
  },
  {
    id: 1694,
    comune: "CASTELLUCCIO SUPERIORE",
    sigla: "PZ",
    codice_elettorale: 3170640230,
    codice_istat: 76023,
    codice_belfiore: "C201",
  },
  {
    id: 1695,
    comune: "CASTELLUCCIO VALMAGGIORE",
    sigla: "FG",
    codice_elettorale: 3160310150,
    codice_istat: 71016,
    codice_belfiore: "C202",
  },
  {
    id: 1696,
    comune: "CASTELMAGNO",
    sigla: "CN",
    codice_elettorale: 1010270530,
    codice_istat: 4053,
    codice_belfiore: "C205",
  },
  {
    id: 1697,
    comune: "CASTELMARTE",
    sigla: "CO",
    codice_elettorale: 1030240560,
    codice_istat: 13058,
    codice_belfiore: "C206",
  },
  {
    id: 1698,
    comune: "CASTELMASSA",
    sigla: "RO",
    codice_elettorale: 1050710120,
    codice_istat: 29012,
    codice_belfiore: "C207",
  },
  {
    id: 1699,
    comune: "CASTELMAURO",
    sigla: "CB",
    codice_elettorale: 3140190150,
    codice_istat: 70015,
    codice_belfiore: "C197",
  },
  {
    id: 1700,
    comune: "CASTELMEZZANO",
    sigla: "PZ",
    codice_elettorale: 3170640240,
    codice_istat: 76024,
    codice_belfiore: "C209",
  },
  {
    id: 1701,
    comune: "CASTELMOLA",
    sigla: "ME",
    codice_elettorale: 4190480150,
    codice_istat: 83015,
    codice_belfiore: "C210",
  },
  {
    id: 1702,
    comune: "CASTELNOVETTO",
    sigla: "PV",
    codice_elettorale: 1030570380,
    codice_istat: 18040,
    codice_belfiore: "C213",
  },
  {
    id: 1703,
    comune: "CASTELNOVO BARIANO",
    sigla: "RO",
    codice_elettorale: 1050710130,
    codice_istat: 29013,
    codice_belfiore: "C215",
  },
  {
    id: 1704,
    comune: "CASTELNOVO DEL FRIULI",
    sigla: "PN",
    codice_elettorale: 1060930110,
    codice_istat: 93011,
    codice_belfiore: "C217",
  },
  {
    id: 1705,
    comune: "CASTELNOVO DI SOTTO",
    sigla: "RE",
    codice_elettorale: 1080680150,
    codice_istat: 35015,
    codice_belfiore: "C218",
  },
  {
    id: 1706,
    comune: "CASTELNOVO NE' MONTI",
    sigla: "RE",
    codice_elettorale: 1080680160,
    codice_istat: 35016,
    codice_belfiore: "C219",
  },
  {
    id: 1707,
    comune: "CASTELNUOVO",
    sigla: "TN",
    codice_elettorale: 1040830460,
    codice_istat: 22049,
    codice_belfiore: "C216",
  },
  {
    id: 1708,
    comune: "CASTELNUOVO BELBO",
    sigla: "AT",
    codice_elettorale: 1010070290,
    codice_istat: 5029,
    codice_belfiore: "C226",
  },
  {
    id: 1709,
    comune: "CASTELNUOVO BERARDENGA",
    sigla: "SI",
    codice_elettorale: 2090750060,
    codice_istat: 52006,
    codice_belfiore: "C227",
  },
  {
    id: 1710,
    comune: "CASTELNUOVO BOCCA D'ADDA",
    sigla: "LO",
    codice_elettorale: 1030990130,
    codice_istat: 98013,
    codice_belfiore: "C228",
  },
  {
    id: 1711,
    comune: "CASTELNUOVO BORMIDA",
    sigla: "AL",
    codice_elettorale: 1010020510,
    codice_istat: 6052,
    codice_belfiore: "C229",
  },
  {
    id: 1712,
    comune: "CASTELNUOVO BOZZENTE",
    sigla: "CO",
    codice_elettorale: 1030240570,
    codice_istat: 13059,
    codice_belfiore: "C220",
  },
  {
    id: 1713,
    comune: "CASTELNUOVO CALCEA",
    sigla: "AT",
    codice_elettorale: 1010070300,
    codice_istat: 5030,
    codice_belfiore: "C230",
  },
  {
    id: 1714,
    comune: "CASTELNUOVO CILENTO",
    sigla: "SA",
    codice_elettorale: 3150720320,
    codice_istat: 65032,
    codice_belfiore: "C231",
  },
  {
    id: 1715,
    comune: "CASTELNUOVO DEL GARDA",
    sigla: "VR",
    codice_elettorale: 1050890220,
    codice_istat: 23022,
    codice_belfiore: "C225",
  },
  {
    id: 1716,
    comune: "CASTELNUOVO DELLA DAUNIA",
    sigla: "FG",
    codice_elettorale: 3160310160,
    codice_istat: 71017,
    codice_belfiore: "C222",
  },
  {
    id: 1717,
    comune: "CASTELNUOVO DI CEVA",
    sigla: "CN",
    codice_elettorale: 1010270540,
    codice_istat: 4054,
    codice_belfiore: "C214",
  },
  {
    id: 1718,
    comune: "CASTELNUOVO DI CONZA",
    sigla: "SA",
    codice_elettorale: 3150720330,
    codice_istat: 65033,
    codice_belfiore: "C235",
  },
  {
    id: 1719,
    comune: "CASTELNUOVO DI FARFA",
    sigla: "RI",
    codice_elettorale: 2120690130,
    codice_istat: 57014,
    codice_belfiore: "C224",
  },
  {
    id: 1720,
    comune: "CASTELNUOVO DI GARFAGNANA",
    sigla: "LU",
    codice_elettorale: 2090430090,
    codice_istat: 46009,
    codice_belfiore: "C236",
  },
  {
    id: 1721,
    comune: "CASTELNUOVO DI PORTO",
    sigla: "RM",
    codice_elettorale: 2120700240,
    codice_istat: 58024,
    codice_belfiore: "C237",
  },
  {
    id: 1722,
    comune: "CASTELNUOVO DI VAL DI CECINA",
    sigla: "PI",
    codice_elettorale: 2090620110,
    codice_istat: 50011,
    codice_belfiore: "C244",
  },
  {
    id: 1723,
    comune: "CASTELNUOVO DON BOSCO",
    sigla: "AT",
    codice_elettorale: 1010070310,
    codice_istat: 5031,
    codice_belfiore: "C232",
  },
  {
    id: 1724,
    comune: "CASTELNUOVO MAGRA",
    sigla: "SP",
    codice_elettorale: 1070390110,
    codice_istat: 11011,
    codice_belfiore: "C240",
  },
  {
    id: 1725,
    comune: "CASTELNUOVO NIGRA",
    sigla: "TO",
    codice_elettorale: 1010810660,
    codice_istat: 1067,
    codice_belfiore: "C241",
  },
  {
    id: 1726,
    comune: "CASTELNUOVO PARANO",
    sigla: "FR",
    codice_elettorale: 2120330210,
    codice_istat: 60021,
    codice_belfiore: "C223",
  },
  {
    id: 1727,
    comune: "CASTELNUOVO RANGONE",
    sigla: "MO",
    codice_elettorale: 1080500070,
    codice_istat: 36007,
    codice_belfiore: "C242",
  },
  {
    id: 1728,
    comune: "CASTELNUOVO SCRIVIA",
    sigla: "AL",
    codice_elettorale: 1010020520,
    codice_istat: 6053,
    codice_belfiore: "C243",
  },
  {
    id: 1729,
    comune: "CASTELPAGANO",
    sigla: "BN",
    codice_elettorale: 3150110170,
    codice_istat: 62017,
    codice_belfiore: "C245",
  },
  {
    id: 1730,
    comune: "CASTELPETROSO",
    sigla: "IS",
    codice_elettorale: 3140940100,
    codice_istat: 94010,
    codice_belfiore: "C246",
  },
  {
    id: 1731,
    comune: "CASTELPIZZUTO",
    sigla: "IS",
    codice_elettorale: 3140940110,
    codice_istat: 94011,
    codice_belfiore: "C247",
  },
  {
    id: 1732,
    comune: "CASTELPLANIO",
    sigla: "AN",
    codice_elettorale: 2110030120,
    codice_istat: 42012,
    codice_belfiore: "C248",
  },
  {
    id: 1733,
    comune: "CASTELPOTO",
    sigla: "BN",
    codice_elettorale: 3150110180,
    codice_istat: 62018,
    codice_belfiore: "C250",
  },
  {
    id: 1734,
    comune: "CASTELRAIMONDO",
    sigla: "MC",
    codice_elettorale: 2110440090,
    codice_istat: 43009,
    codice_belfiore: "C251",
  },
  {
    id: 1735,
    comune: "CASTELROTTO/KASTELRUTH",
    sigla: "BZ",
    codice_elettorale: 1040140160,
    codice_istat: 21019,
    codice_belfiore: "C254",
  },
  {
    id: 1736,
    comune: "CASTELSANTANGELO SUL NERA",
    sigla: "MC",
    codice_elettorale: 2110440100,
    codice_istat: 43010,
    codice_belfiore: "C267",
  },
  {
    id: 1737,
    comune: "CASTELSARACENO",
    sigla: "PZ",
    codice_elettorale: 3170640250,
    codice_istat: 76025,
    codice_belfiore: "C271",
  },
  {
    id: 1738,
    comune: "CASTELSARDO",
    sigla: "SS",
    codice_elettorale: 4200730230,
    codice_istat: 90023,
    codice_belfiore: "C272",
  },
  {
    id: 1739,
    comune: "CASTELSEPRIO",
    sigla: "VA",
    codice_elettorale: 1030860370,
    codice_istat: 12044,
    codice_belfiore: "C273",
  },
  {
    id: 1740,
    comune: "CASTELSILANO",
    sigla: "KR",
    codice_elettorale: 3180970050,
    codice_istat: 101005,
    codice_belfiore: "B968",
  },
  {
    id: 1741,
    comune: "CASTELSPINA",
    sigla: "AL",
    codice_elettorale: 1010020521,
    codice_istat: 6054,
    codice_belfiore: "C274",
  },
  {
    id: 1742,
    comune: "CASTELTERMINI",
    sigla: "AG",
    codice_elettorale: 4190010120,
    codice_istat: 84012,
    codice_belfiore: "C275",
  },
  {
    id: 1743,
    comune: "CASTELVECCANA",
    sigla: "VA",
    codice_elettorale: 1030860380,
    codice_istat: 12045,
    codice_belfiore: "C181",
  },
  {
    id: 1744,
    comune: "CASTELVECCHIO CALVISIO",
    sigla: "AQ",
    codice_elettorale: 3130380300,
    codice_istat: 66030,
    codice_belfiore: "C278",
  },
  {
    id: 1745,
    comune: "CASTELVECCHIO DI ROCCA BARBENA",
    sigla: "SV",
    codice_elettorale: 1070740210,
    codice_istat: 9021,
    codice_belfiore: "C276",
  },
  {
    id: 1746,
    comune: "CASTELVECCHIO SUBEQUO",
    sigla: "AQ",
    codice_elettorale: 3130380310,
    codice_istat: 66031,
    codice_belfiore: "C279",
  },
  {
    id: 1747,
    comune: "CASTELVENERE",
    sigla: "BN",
    codice_elettorale: 3150110190,
    codice_istat: 62019,
    codice_belfiore: "C280",
  },
  {
    id: 1748,
    comune: "CASTELVERDE",
    sigla: "CR",
    codice_elettorale: 1030260250,
    codice_istat: 19026,
    codice_belfiore: "B129",
  },
  {
    id: 1749,
    comune: "CASTELVERRINO",
    sigla: "IS",
    codice_elettorale: 3140940130,
    codice_istat: 94013,
    codice_belfiore: "C200",
  },
  {
    id: 1750,
    comune: "CASTELVETERE IN VAL FORTORE",
    sigla: "BN",
    codice_elettorale: 3150110200,
    codice_istat: 62020,
    codice_belfiore: "C284",
  },
  {
    id: 1751,
    comune: "CASTELVETERE SUL CALORE",
    sigla: "AV",
    codice_elettorale: 3150080240,
    codice_istat: 64024,
    codice_belfiore: "C283",
  },
  {
    id: 1752,
    comune: "CASTELVETRANO",
    sigla: "TP",
    codice_elettorale: 4190820070,
    codice_istat: 81006,
    codice_belfiore: "C286",
  },
  {
    id: 1753,
    comune: "CASTELVETRO DI MODENA",
    sigla: "MO",
    codice_elettorale: 1080500080,
    codice_istat: 36008,
    codice_belfiore: "C287",
  },
  {
    id: 1754,
    comune: "CASTELVETRO PIACENTINO",
    sigla: "PC",
    codice_elettorale: 1080610140,
    codice_istat: 33014,
    codice_belfiore: "C288",
  },
  {
    id: 1755,
    comune: "CASTELVISCONTI",
    sigla: "CR",
    codice_elettorale: 1030260260,
    codice_istat: 19027,
    codice_belfiore: "C290",
  },
  {
    id: 1756,
    comune: "CASTENASO",
    sigla: "BO",
    codice_elettorale: 1080130210,
    codice_istat: 37021,
    codice_belfiore: "C292",
  },
  {
    id: 1757,
    comune: "CASTENEDOLO",
    sigla: "BS",
    codice_elettorale: 1030150390,
    codice_istat: 17043,
    codice_belfiore: "C293",
  },
  {
    id: 1758,
    comune: "CASTIADAS",
    sigla: "SU",
    codice_elettorale: 4201110110,
    codice_istat: 111011,
    codice_belfiore: "M288",
  },
  {
    id: 1759,
    comune: "CASTIGLION FIBOCCHI",
    sigla: "AR",
    codice_elettorale: 2090050110,
    codice_istat: 51011,
    codice_belfiore: "C318",
  },
  {
    id: 1760,
    comune: "CASTIGLION FIORENTINO",
    sigla: "AR",
    codice_elettorale: 2090050120,
    codice_istat: 51012,
    codice_belfiore: "C319",
  },
  {
    id: 1761,
    comune: "CASTIGLIONE A CASAURIA",
    sigla: "PE",
    codice_elettorale: 3130600090,
    codice_istat: 68009,
    codice_belfiore: "C308",
  },
  {
    id: 1762,
    comune: "CASTIGLIONE CHIAVARESE",
    sigla: "GE",
    codice_elettorale: 1070340130,
    codice_istat: 10013,
    codice_belfiore: "C302",
  },
  {
    id: 1763,
    comune: "CASTIGLIONE COSENTINO",
    sigla: "CS",
    codice_elettorale: 3180250300,
    codice_istat: 78030,
    codice_belfiore: "C301",
  },
  {
    id: 1764,
    comune: "CASTIGLIONE D'ADDA",
    sigla: "LO",
    codice_elettorale: 1030990140,
    codice_istat: 98014,
    codice_belfiore: "C304",
  },
  {
    id: 1765,
    comune: "CASTIGLIONE D'ORCIA",
    sigla: "SI",
    codice_elettorale: 2090750070,
    codice_istat: 52007,
    codice_belfiore: "C313",
  },
  {
    id: 1766,
    comune: "CASTIGLIONE DEI PEPOLI",
    sigla: "BO",
    codice_elettorale: 1080130220,
    codice_istat: 37022,
    codice_belfiore: "C296",
  },
  {
    id: 1767,
    comune: "CASTIGLIONE DEL GENOVESI",
    sigla: "SA",
    codice_elettorale: 3150720360,
    codice_istat: 65036,
    codice_belfiore: "C306",
  },
  {
    id: 1768,
    comune: "CASTIGLIONE DEL LAGO",
    sigla: "PG",
    codice_elettorale: 2100580090,
    codice_istat: 54009,
    codice_belfiore: "C309",
  },
  {
    id: 1769,
    comune: "CASTIGLIONE DELLA PESCAIA",
    sigla: "GR",
    codice_elettorale: 2090360050,
    codice_istat: 53006,
    codice_belfiore: "C310",
  },
  {
    id: 1770,
    comune: "CASTIGLIONE DELLE STIVIERE",
    sigla: "MN",
    codice_elettorale: 1030450170,
    codice_istat: 20017,
    codice_belfiore: "C312",
  },
  {
    id: 1771,
    comune: "CASTIGLIONE DI GARFAGNANA",
    sigla: "LU",
    codice_elettorale: 2090430100,
    codice_istat: 46010,
    codice_belfiore: "C303",
  },
  {
    id: 1772,
    comune: "CASTIGLIONE DI SICILIA",
    sigla: "CT",
    codice_elettorale: 4190210140,
    codice_istat: 87014,
    codice_belfiore: "C297",
  },
  {
    id: 1773,
    comune: "CASTIGLIONE FALLETTO",
    sigla: "CN",
    codice_elettorale: 1010270550,
    codice_istat: 4055,
    codice_belfiore: "C314",
  },
  {
    id: 1774,
    comune: "CASTIGLIONE IN TEVERINA",
    sigla: "VT",
    codice_elettorale: 2120910170,
    codice_istat: 56018,
    codice_belfiore: "C315",
  },
  {
    id: 1775,
    comune: "CASTIGLIONE MESSER MARINO",
    sigla: "CH",
    codice_elettorale: 3130230200,
    codice_istat: 69020,
    codice_belfiore: "C298",
  },
  {
    id: 1776,
    comune: "CASTIGLIONE MESSER RAIMONDO",
    sigla: "TE",
    codice_elettorale: 3130790120,
    codice_istat: 67013,
    codice_belfiore: "C316",
  },
  {
    id: 1777,
    comune: "CASTIGLIONE OLONA",
    sigla: "VA",
    codice_elettorale: 1030860390,
    codice_istat: 12046,
    codice_belfiore: "C300",
  },
  {
    id: 1778,
    comune: "CASTIGLIONE TINELLA",
    sigla: "CN",
    codice_elettorale: 1010270560,
    codice_istat: 4056,
    codice_belfiore: "C317",
  },
  {
    id: 1779,
    comune: "CASTIGLIONE TORINESE",
    sigla: "TO",
    codice_elettorale: 1010810670,
    codice_istat: 1068,
    codice_belfiore: "C307",
  },
  {
    id: 1780,
    comune: "CASTIGNANO",
    sigla: "AP",
    codice_elettorale: 2110060120,
    codice_istat: 44012,
    codice_belfiore: "C321",
  },
  {
    id: 1781,
    comune: "CASTILENTI",
    sigla: "TE",
    codice_elettorale: 3130790130,
    codice_istat: 67014,
    codice_belfiore: "C322",
  },
  {
    id: 1782,
    comune: "CASTINO",
    sigla: "CN",
    codice_elettorale: 1010270570,
    codice_istat: 4057,
    codice_belfiore: "C323",
  },
  {
    id: 1783,
    comune: "CASTIONE ANDEVENNO",
    sigla: "SO",
    codice_elettorale: 1030770150,
    codice_istat: 14015,
    codice_belfiore: "C325",
  },
  {
    id: 1784,
    comune: "CASTIONE DELLA PRESOLANA",
    sigla: "BG",
    codice_elettorale: 1030120620,
    codice_istat: 16064,
    codice_belfiore: "C324",
  },
  {
    id: 1785,
    comune: "CASTIONS DI STRADA",
    sigla: "UD",
    codice_elettorale: 1060850200,
    codice_istat: 30020,
    codice_belfiore: "C327",
  },
  {
    id: 1786,
    comune: "CASTIRAGA VIDARDO",
    sigla: "LO",
    codice_elettorale: 1030990150,
    codice_istat: 98015,
    codice_belfiore: "C329",
  },
  {
    id: 1787,
    comune: "CASTO",
    sigla: "BS",
    codice_elettorale: 1030150400,
    codice_istat: 17044,
    codice_belfiore: "C330",
  },
  {
    id: 1788,
    comune: "CASTORANO",
    sigla: "AP",
    codice_elettorale: 2110060130,
    codice_istat: 44013,
    codice_belfiore: "C331",
  },
  {
    id: 1789,
    comune: "CASTREZZATO",
    sigla: "BS",
    codice_elettorale: 1030150410,
    codice_istat: 17045,
    codice_belfiore: "C332",
  },
  {
    id: 1790,
    comune: "CASTRI DI LECCE",
    sigla: "LE",
    codice_elettorale: 3160410160,
    codice_istat: 75017,
    codice_belfiore: "C334",
  },
  {
    id: 1791,
    comune: "CASTRIGNANO DE' GRECI",
    sigla: "LE",
    codice_elettorale: 3160410170,
    codice_istat: 75018,
    codice_belfiore: "C335",
  },
  {
    id: 1792,
    comune: "CASTRIGNANO DEL CAPO",
    sigla: "LE",
    codice_elettorale: 3160410180,
    codice_istat: 75019,
    codice_belfiore: "C336",
  },
  {
    id: 1793,
    comune: "CASTRO",
    sigla: "BG",
    codice_elettorale: 1030120630,
    codice_istat: 16065,
    codice_belfiore: "C337",
  },
  {
    id: 1794,
    comune: "CASTRO",
    sigla: "LE",
    codice_elettorale: 3160410181,
    codice_istat: 75096,
    codice_belfiore: "M261",
  },
  {
    id: 1795,
    comune: "CASTRO DEI VOLSCI",
    sigla: "FR",
    codice_elettorale: 2120330230,
    codice_istat: 60023,
    codice_belfiore: "C338",
  },
  {
    id: 1796,
    comune: "CASTROCARO TERME E TERRA DEL SOLE",
    sigla: "FC",
    codice_elettorale: 1080320040,
    codice_istat: 40005,
    codice_belfiore: "C339",
  },
  {
    id: 1797,
    comune: "CASTROCIELO",
    sigla: "FR",
    codice_elettorale: 2120330220,
    codice_istat: 60022,
    codice_belfiore: "C340",
  },
  {
    id: 1798,
    comune: "CASTROFILIPPO",
    sigla: "AG",
    codice_elettorale: 4190010130,
    codice_istat: 84013,
    codice_belfiore: "C341",
  },
  {
    id: 1799,
    comune: "CASTROLIBERO",
    sigla: "CS",
    codice_elettorale: 3180250310,
    codice_istat: 78031,
    codice_belfiore: "C108",
  },
  {
    id: 1800,
    comune: "CASTRONNO",
    sigla: "VA",
    codice_elettorale: 1030860400,
    codice_istat: 12047,
    codice_belfiore: "C343",
  },
  {
    id: 1801,
    comune: "CASTRONOVO DI SICILIA",
    sigla: "PA",
    codice_elettorale: 4190550230,
    codice_istat: 82025,
    codice_belfiore: "C344",
  },
  {
    id: 1802,
    comune: "CASTRONUOVO DI SANT'ANDREA",
    sigla: "PZ",
    codice_elettorale: 3170640260,
    codice_istat: 76026,
    codice_belfiore: "C345",
  },
  {
    id: 1803,
    comune: "CASTROPIGNANO",
    sigla: "CB",
    codice_elettorale: 3140190160,
    codice_istat: 70016,
    codice_belfiore: "C346",
  },
  {
    id: 1804,
    comune: "CASTROREALE",
    sigla: "ME",
    codice_elettorale: 4190480160,
    codice_istat: 83016,
    codice_belfiore: "C347",
  },
  {
    id: 1805,
    comune: "CASTROREGIO",
    sigla: "CS",
    codice_elettorale: 3180250320,
    codice_istat: 78032,
    codice_belfiore: "C348",
  },
  {
    id: 1806,
    comune: "CASTROVILLARI",
    sigla: "CS",
    codice_elettorale: 3180250330,
    codice_istat: 78033,
    codice_belfiore: "C349",
  },
  {
    id: 1807,
    comune: "CATANIA",
    sigla: "CT",
    codice_elettorale: 4190210150,
    codice_istat: 87015,
    codice_belfiore: "C351",
  },
  {
    id: 1808,
    comune: "CATANZARO",
    sigla: "CZ",
    codice_elettorale: 3180220220,
    codice_istat: 79023,
    codice_belfiore: "C352",
  },
  {
    id: 1809,
    comune: "CATENANUOVA",
    sigla: "EN",
    codice_elettorale: 4190280060,
    codice_istat: 86006,
    codice_belfiore: "C353",
  },
  {
    id: 1810,
    comune: "CATIGNANO",
    sigla: "PE",
    codice_elettorale: 3130600100,
    codice_istat: 68010,
    codice_belfiore: "C354",
  },
  {
    id: 1811,
    comune: "CATTOLICA",
    sigla: "RN",
    codice_elettorale: 1081010020,
    codice_istat: 99002,
    codice_belfiore: "C357",
  },
  {
    id: 1812,
    comune: "CATTOLICA ERACLEA",
    sigla: "AG",
    codice_elettorale: 4190010140,
    codice_istat: 84014,
    codice_belfiore: "C356",
  },
  {
    id: 1813,
    comune: "CAULONIA",
    sigla: "RC",
    codice_elettorale: 3180670250,
    codice_istat: 80025,
    codice_belfiore: "C285",
  },
  {
    id: 1814,
    comune: "CAUTANO",
    sigla: "BN",
    codice_elettorale: 3150110210,
    codice_istat: 62021,
    codice_belfiore: "C359",
  },
  {
    id: 1815,
    comune: "CAVA DE' TIRRENI",
    sigla: "SA",
    codice_elettorale: 3150720370,
    codice_istat: 65037,
    codice_belfiore: "C361",
  },
  {
    id: 1816,
    comune: "CAVA MANARA",
    sigla: "PV",
    codice_elettorale: 1030570390,
    codice_istat: 18041,
    codice_belfiore: "C360",
  },
  {
    id: 1817,
    comune: "CAVAGLIA'",
    sigla: "BI",
    codice_elettorale: 1010960160,
    codice_istat: 96016,
    codice_belfiore: "C363",
  },
  {
    id: 1818,
    comune: "CAVAGLIETTO",
    sigla: "NO",
    codice_elettorale: 1010520430,
    codice_istat: 3044,
    codice_belfiore: "C364",
  },
  {
    id: 1819,
    comune: "CAVAGLIO D'AGOGNA",
    sigla: "NO",
    codice_elettorale: 1010520440,
    codice_istat: 3045,
    codice_belfiore: "C365",
  },
  {
    id: 1820,
    comune: "CAVAGNOLO",
    sigla: "TO",
    codice_elettorale: 1010810671,
    codice_istat: 1069,
    codice_belfiore: "C369",
  },
  {
    id: 1821,
    comune: "CAVAION VERONESE",
    sigla: "VR",
    codice_elettorale: 1050890230,
    codice_istat: 23023,
    codice_belfiore: "C370",
  },
  {
    id: 1822,
    comune: "CAVALESE",
    sigla: "TN",
    codice_elettorale: 1040830470,
    codice_istat: 22050,
    codice_belfiore: "C372",
  },
  {
    id: 1823,
    comune: "CAVALLERLEONE",
    sigla: "CN",
    codice_elettorale: 1010270580,
    codice_istat: 4058,
    codice_belfiore: "C375",
  },
  {
    id: 1824,
    comune: "CAVALLERMAGGIORE",
    sigla: "CN",
    codice_elettorale: 1010270590,
    codice_istat: 4059,
    codice_belfiore: "C376",
  },
  {
    id: 1825,
    comune: "CAVALLINO",
    sigla: "LE",
    codice_elettorale: 3160410190,
    codice_istat: 75020,
    codice_belfiore: "C377",
  },
  {
    id: 1826,
    comune: "CAVALLINO-TREPORTI",
    sigla: "VE",
    codice_elettorale: 1050870054,
    codice_istat: 27044,
    codice_belfiore: "M308",
  },
  {
    id: 1827,
    comune: "CAVALLIRIO",
    sigla: "NO",
    codice_elettorale: 1010520460,
    codice_istat: 3047,
    codice_belfiore: "C378",
  },
  {
    id: 1828,
    comune: "CAVARENO",
    sigla: "TN",
    codice_elettorale: 1040830480,
    codice_istat: 22051,
    codice_belfiore: "C380",
  },
  {
    id: 1829,
    comune: "CAVARGNA",
    sigla: "CO",
    codice_elettorale: 1030240590,
    codice_istat: 13062,
    codice_belfiore: "C381",
  },
  {
    id: 1830,
    comune: "CAVARIA CON PREMEZZO",
    sigla: "VA",
    codice_elettorale: 1030860410,
    codice_istat: 12048,
    codice_belfiore: "C382",
  },
  {
    id: 1831,
    comune: "CAVARZERE",
    sigla: "VE",
    codice_elettorale: 1050870060,
    codice_istat: 27006,
    codice_belfiore: "C383",
  },
  {
    id: 1832,
    comune: "CAVASO DEL TOMBA",
    sigla: "TV",
    codice_elettorale: 1050840140,
    codice_istat: 26014,
    codice_belfiore: "C384",
  },
  {
    id: 1833,
    comune: "CAVASSO NUOVO",
    sigla: "PN",
    codice_elettorale: 1060930120,
    codice_istat: 93012,
    codice_belfiore: "C385",
  },
  {
    id: 1834,
    comune: "CAVATORE",
    sigla: "AL",
    codice_elettorale: 1010020530,
    codice_istat: 6055,
    codice_belfiore: "C387",
  },
  {
    id: 1835,
    comune: "CAVAZZO CARNICO",
    sigla: "UD",
    codice_elettorale: 1060850210,
    codice_istat: 30021,
    codice_belfiore: "C389",
  },
  {
    id: 1836,
    comune: "CAVE",
    sigla: "RM",
    codice_elettorale: 2120700260,
    codice_istat: 58026,
    codice_belfiore: "C390",
  },
  {
    id: 1837,
    comune: "CAVEDAGO",
    sigla: "TN",
    codice_elettorale: 1040830490,
    codice_istat: 22052,
    codice_belfiore: "C392",
  },
  {
    id: 1838,
    comune: "CAVEDINE",
    sigla: "TN",
    codice_elettorale: 1040830500,
    codice_istat: 22053,
    codice_belfiore: "C393",
  },
  {
    id: 1839,
    comune: "CAVENAGO D'ADDA",
    sigla: "LO",
    codice_elettorale: 1030990170,
    codice_istat: 98017,
    codice_belfiore: "C394",
  },
  {
    id: 1840,
    comune: "CAVENAGO DI BRIANZA",
    sigla: "MB",
    codice_elettorale: 1031040170,
    codice_istat: 108017,
    codice_belfiore: "C395",
  },
  {
    id: 1841,
    comune: "CAVERNAGO",
    sigla: "BG",
    codice_elettorale: 1030120640,
    codice_istat: 16066,
    codice_belfiore: "C396",
  },
  {
    id: 1842,
    comune: "CAVEZZO",
    sigla: "MO",
    codice_elettorale: 1080500090,
    codice_istat: 36009,
    codice_belfiore: "C398",
  },
  {
    id: 1843,
    comune: "CAVIZZANA",
    sigla: "TN",
    codice_elettorale: 1040830501,
    codice_istat: 22054,
    codice_belfiore: "C400",
  },
  {
    id: 1844,
    comune: "CAVOUR",
    sigla: "TO",
    codice_elettorale: 1010810680,
    codice_istat: 1070,
    codice_belfiore: "C404",
  },
  {
    id: 1845,
    comune: "CAVRIAGO",
    sigla: "RE",
    codice_elettorale: 1080680170,
    codice_istat: 35017,
    codice_belfiore: "C405",
  },
  {
    id: 1846,
    comune: "CAVRIANA",
    sigla: "MN",
    codice_elettorale: 1030450180,
    codice_istat: 20018,
    codice_belfiore: "C406",
  },
  {
    id: 1847,
    comune: "CAVRIGLIA",
    sigla: "AR",
    codice_elettorale: 2090050130,
    codice_istat: 51013,
    codice_belfiore: "C407",
  },
  {
    id: 1848,
    comune: "CAZZAGO BRABBIA",
    sigla: "VA",
    codice_elettorale: 1030860420,
    codice_istat: 12049,
    codice_belfiore: "C409",
  },
  {
    id: 1849,
    comune: "CAZZAGO SAN MARTINO",
    sigla: "BS",
    codice_elettorale: 1030150420,
    codice_istat: 17046,
    codice_belfiore: "C408",
  },
  {
    id: 1850,
    comune: "CAZZANO DI TRAMIGNA",
    sigla: "VR",
    codice_elettorale: 1050890240,
    codice_istat: 23024,
    codice_belfiore: "C412",
  },
  {
    id: 1851,
    comune: "CAZZANO SANT'ANDREA",
    sigla: "BG",
    codice_elettorale: 1030120641,
    codice_istat: 16067,
    codice_belfiore: "C410",
  },
  {
    id: 1852,
    comune: "CECCANO",
    sigla: "FR",
    codice_elettorale: 2120330240,
    codice_istat: 60024,
    codice_belfiore: "C413",
  },
  {
    id: 1853,
    comune: "CECIMA",
    sigla: "PV",
    codice_elettorale: 1030570391,
    codice_istat: 18042,
    codice_belfiore: "C414",
  },
  {
    id: 1854,
    comune: "CECINA",
    sigla: "LI",
    codice_elettorale: 2090420070,
    codice_istat: 49007,
    codice_belfiore: "C415",
  },
  {
    id: 1855,
    comune: "CEDEGOLO",
    sigla: "BS",
    codice_elettorale: 1030150430,
    codice_istat: 17047,
    codice_belfiore: "C417",
  },
  {
    id: 1856,
    comune: "CEDRASCO",
    sigla: "SO",
    codice_elettorale: 1030770160,
    codice_istat: 14016,
    codice_belfiore: "C418",
  },
  {
    id: 1857,
    comune: "CEFALA' DIANA",
    sigla: "PA",
    codice_elettorale: 4190550240,
    codice_istat: 82026,
    codice_belfiore: "C420",
  },
  {
    id: 1858,
    comune: "CEFALU'",
    sigla: "PA",
    codice_elettorale: 4190550250,
    codice_istat: 82027,
    codice_belfiore: "C421",
  },
  {
    id: 1859,
    comune: "CEGGIA",
    sigla: "VE",
    codice_elettorale: 1050870070,
    codice_istat: 27007,
    codice_belfiore: "C422",
  },
  {
    id: 1860,
    comune: "CEGLIE MESSAPICA",
    sigla: "BR",
    codice_elettorale: 3160160030,
    codice_istat: 74003,
    codice_belfiore: "C424",
  },
  {
    id: 1861,
    comune: "CELANO",
    sigla: "AQ",
    codice_elettorale: 3130380320,
    codice_istat: 66032,
    codice_belfiore: "C426",
  },
  {
    id: 1862,
    comune: "CELENZA SUL TRIGNO",
    sigla: "CH",
    codice_elettorale: 3130230210,
    codice_istat: 69021,
    codice_belfiore: "C428",
  },
  {
    id: 1863,
    comune: "CELENZA VALFORTORE",
    sigla: "FG",
    codice_elettorale: 3160310170,
    codice_istat: 71018,
    codice_belfiore: "C429",
  },
  {
    id: 1864,
    comune: "CELICO",
    sigla: "CS",
    codice_elettorale: 3180250340,
    codice_istat: 78034,
    codice_belfiore: "C430",
  },
  {
    id: 1865,
    comune: "CELLA DATI",
    sigla: "CR",
    codice_elettorale: 1030260270,
    codice_istat: 19028,
    codice_belfiore: "C435",
  },
  {
    id: 1866,
    comune: "CELLA MONTE",
    sigla: "AL",
    codice_elettorale: 1010020540,
    codice_istat: 6056,
    codice_belfiore: "C432",
  },
  {
    id: 1867,
    comune: "CELLAMARE",
    sigla: "BA",
    codice_elettorale: 3160090180,
    codice_istat: 72018,
    codice_belfiore: "C436",
  },
  {
    id: 1868,
    comune: "CELLARA",
    sigla: "CS",
    codice_elettorale: 3180250350,
    codice_istat: 78035,
    codice_belfiore: "C437",
  },
  {
    id: 1869,
    comune: "CELLARENGO",
    sigla: "AT",
    codice_elettorale: 1010070330,
    codice_istat: 5033,
    codice_belfiore: "C438",
  },
  {
    id: 1870,
    comune: "CELLATICA",
    sigla: "BS",
    codice_elettorale: 1030150440,
    codice_istat: 17048,
    codice_belfiore: "C439",
  },
  {
    id: 1871,
    comune: "CELLE DI BULGHERIA",
    sigla: "SA",
    codice_elettorale: 3150720380,
    codice_istat: 65038,
    codice_belfiore: "C444",
  },
  {
    id: 1872,
    comune: "CELLE DI MACRA",
    sigla: "CN",
    codice_elettorale: 1010270600,
    codice_istat: 4060,
    codice_belfiore: "C441",
  },
  {
    id: 1873,
    comune: "CELLE DI SAN VITO",
    sigla: "FG",
    codice_elettorale: 3160310180,
    codice_istat: 71019,
    codice_belfiore: "C442",
  },
  {
    id: 1874,
    comune: "CELLE ENOMONDO",
    sigla: "AT",
    codice_elettorale: 1010070340,
    codice_istat: 5034,
    codice_belfiore: "C440",
  },
  {
    id: 1875,
    comune: "CELLE LIGURE",
    sigla: "SV",
    codice_elettorale: 1070740220,
    codice_istat: 9022,
    codice_belfiore: "C443",
  },
  {
    id: 1876,
    comune: "CELLENO",
    sigla: "VT",
    codice_elettorale: 2120910180,
    codice_istat: 56019,
    codice_belfiore: "C446",
  },
  {
    id: 1877,
    comune: "CELLERE",
    sigla: "VT",
    codice_elettorale: 2120910190,
    codice_istat: 56020,
    codice_belfiore: "C447",
  },
  {
    id: 1878,
    comune: "CELLINO ATTANASIO",
    sigla: "TE",
    codice_elettorale: 3130790140,
    codice_istat: 67015,
    codice_belfiore: "C449",
  },
  {
    id: 1879,
    comune: "CELLINO SAN MARCO",
    sigla: "BR",
    codice_elettorale: 3160160040,
    codice_istat: 74004,
    codice_belfiore: "C448",
  },
  {
    id: 1880,
    comune: "CELLIO CON BREIA",
    sigla: "VC",
    codice_elettorale: 1010880385,
    codice_istat: 2171,
    codice_belfiore: "M398",
  },
  {
    id: 1881,
    comune: "CELLOLE",
    sigla: "CE",
    codice_elettorale: 3150200271,
    codice_istat: 61102,
    codice_belfiore: "M262",
  },
  {
    id: 1882,
    comune: "CEMBRA LISIGNAGO",
    sigla: "TN",
    codice_elettorale: 1040830515,
    codice_istat: 22241,
    codice_belfiore: "M355",
  },
  {
    id: 1883,
    comune: "CENADI",
    sigla: "CZ",
    codice_elettorale: 3180220230,
    codice_istat: 79024,
    codice_belfiore: "C453",
  },
  {
    id: 1884,
    comune: "CENATE SOPRA",
    sigla: "BG",
    codice_elettorale: 1030120650,
    codice_istat: 16068,
    codice_belfiore: "C456",
  },
  {
    id: 1885,
    comune: "CENATE SOTTO",
    sigla: "BG",
    codice_elettorale: 1030120660,
    codice_istat: 16069,
    codice_belfiore: "C457",
  },
  {
    id: 1886,
    comune: "CENCENIGHE AGORDINO",
    sigla: "BL",
    codice_elettorale: 1050100100,
    codice_istat: 25010,
    codice_belfiore: "C458",
  },
  {
    id: 1887,
    comune: "CENE",
    sigla: "BG",
    codice_elettorale: 1030120670,
    codice_istat: 16070,
    codice_belfiore: "C459",
  },
  {
    id: 1888,
    comune: "CENESELLI",
    sigla: "RO",
    codice_elettorale: 1050710140,
    codice_istat: 29014,
    codice_belfiore: "C461",
  },
  {
    id: 1889,
    comune: "CENGIO",
    sigla: "SV",
    codice_elettorale: 1070740230,
    codice_istat: 9023,
    codice_belfiore: "C463",
  },
  {
    id: 1890,
    comune: "CENTALLO",
    sigla: "CN",
    codice_elettorale: 1010270610,
    codice_istat: 4061,
    codice_belfiore: "C466",
  },
  {
    id: 1891,
    comune: "CENTO",
    sigla: "FE",
    codice_elettorale: 1080290040,
    codice_istat: 38004,
    codice_belfiore: "C469",
  },
  {
    id: 1892,
    comune: "CENTOLA",
    sigla: "SA",
    codice_elettorale: 3150720390,
    codice_istat: 65039,
    codice_belfiore: "C470",
  },
  {
    id: 1893,
    comune: "CENTRACHE",
    sigla: "CZ",
    codice_elettorale: 3180220240,
    codice_istat: 79025,
    codice_belfiore: "C472",
  },
  {
    id: 1894,
    comune: "CENTRO VALLE INTELVI",
    sigla: "CO",
    codice_elettorale: 1030240595,
    codice_istat: 13254,
    codice_belfiore: "M394",
  },
  {
    id: 1895,
    comune: "CENTURIPE",
    sigla: "EN",
    codice_elettorale: 4190280070,
    codice_istat: 86007,
    codice_belfiore: "C471",
  },
  {
    id: 1896,
    comune: "CEPAGATTI",
    sigla: "PE",
    codice_elettorale: 3130600110,
    codice_istat: 68011,
    codice_belfiore: "C474",
  },
  {
    id: 1897,
    comune: "CEPPALONI",
    sigla: "BN",
    codice_elettorale: 3150110220,
    codice_istat: 62022,
    codice_belfiore: "C476",
  },
  {
    id: 1898,
    comune: "CEPPO MORELLI",
    sigla: "VB",
    codice_elettorale: 1011020210,
    codice_istat: 103021,
    codice_belfiore: "C478",
  },
  {
    id: 1899,
    comune: "CEPRANO",
    sigla: "FR",
    codice_elettorale: 2120330250,
    codice_istat: 60025,
    codice_belfiore: "C479",
  },
  {
    id: 1900,
    comune: "CERAMI",
    sigla: "EN",
    codice_elettorale: 4190280080,
    codice_istat: 86008,
    codice_belfiore: "C480",
  },
  {
    id: 1901,
    comune: "CERANESI",
    sigla: "GE",
    codice_elettorale: 1070340140,
    codice_istat: 10014,
    codice_belfiore: "C481",
  },
  {
    id: 1902,
    comune: "CERANO",
    sigla: "NO",
    codice_elettorale: 1010520480,
    codice_istat: 3049,
    codice_belfiore: "C483",
  },
  {
    id: 1903,
    comune: "CERANO D'INTELVI",
    sigla: "CO",
    codice_elettorale: 1030240600,
    codice_istat: 13063,
    codice_belfiore: "C482",
  },
  {
    id: 1904,
    comune: "CERANOVA",
    sigla: "PV",
    codice_elettorale: 1030570400,
    codice_istat: 18043,
    codice_belfiore: "C484",
  },
  {
    id: 1905,
    comune: "CERASO",
    sigla: "SA",
    codice_elettorale: 3150720400,
    codice_istat: 65040,
    codice_belfiore: "C485",
  },
  {
    id: 1906,
    comune: "CERCEMAGGIORE",
    sigla: "CB",
    codice_elettorale: 3140190170,
    codice_istat: 70017,
    codice_belfiore: "C486",
  },
  {
    id: 1907,
    comune: "CERCENASCO",
    sigla: "TO",
    codice_elettorale: 1010810690,
    codice_istat: 1071,
    codice_belfiore: "C487",
  },
  {
    id: 1908,
    comune: "CERCEPICCOLA",
    sigla: "CB",
    codice_elettorale: 3140190180,
    codice_istat: 70018,
    codice_belfiore: "C488",
  },
  {
    id: 1909,
    comune: "CERCHIARA DI CALABRIA",
    sigla: "CS",
    codice_elettorale: 3180250360,
    codice_istat: 78036,
    codice_belfiore: "C489",
  },
  {
    id: 1910,
    comune: "CERCHIO",
    sigla: "AQ",
    codice_elettorale: 3130380330,
    codice_istat: 66033,
    codice_belfiore: "C492",
  },
  {
    id: 1911,
    comune: "CERCINO",
    sigla: "SO",
    codice_elettorale: 1030770170,
    codice_istat: 14017,
    codice_belfiore: "C493",
  },
  {
    id: 1912,
    comune: "CERCIVENTO",
    sigla: "UD",
    codice_elettorale: 1060850220,
    codice_istat: 30022,
    codice_belfiore: "C494",
  },
  {
    id: 1913,
    comune: "CERCOLA",
    sigla: "NA",
    codice_elettorale: 3150510260,
    codice_istat: 63026,
    codice_belfiore: "C495",
  },
  {
    id: 1914,
    comune: "CERDA",
    sigla: "PA",
    codice_elettorale: 4190550260,
    codice_istat: 82028,
    codice_belfiore: "C496",
  },
  {
    id: 1915,
    comune: "CEREA",
    sigla: "VR",
    codice_elettorale: 1050890250,
    codice_istat: 23025,
    codice_belfiore: "C498",
  },
  {
    id: 1916,
    comune: "CEREGNANO",
    sigla: "RO",
    codice_elettorale: 1050710150,
    codice_istat: 29015,
    codice_belfiore: "C500",
  },
  {
    id: 1917,
    comune: "CERENZIA",
    sigla: "KR",
    codice_elettorale: 3180970060,
    codice_istat: 101006,
    codice_belfiore: "C501",
  },
  {
    id: 1918,
    comune: "CERES",
    sigla: "TO",
    codice_elettorale: 1010810700,
    codice_istat: 1072,
    codice_belfiore: "C497",
  },
  {
    id: 1919,
    comune: "CERESARA",
    sigla: "MN",
    codice_elettorale: 1030450190,
    codice_istat: 20019,
    codice_belfiore: "C502",
  },
  {
    id: 1920,
    comune: "CERESETO",
    sigla: "AL",
    codice_elettorale: 1010020550,
    codice_istat: 6057,
    codice_belfiore: "C503",
  },
  {
    id: 1921,
    comune: "CERESOLE ALBA",
    sigla: "CN",
    codice_elettorale: 1010270620,
    codice_istat: 4062,
    codice_belfiore: "C504",
  },
  {
    id: 1922,
    comune: "CERESOLE REALE",
    sigla: "TO",
    codice_elettorale: 1010810710,
    codice_istat: 1073,
    codice_belfiore: "C505",
  },
  {
    id: 1923,
    comune: "CERETE",
    sigla: "BG",
    codice_elettorale: 1030120680,
    codice_istat: 16071,
    codice_belfiore: "C506",
  },
  {
    id: 1924,
    comune: "CERETTO LOMELLINA",
    sigla: "PV",
    codice_elettorale: 1030570401,
    codice_istat: 18044,
    codice_belfiore: "C508",
  },
  {
    id: 1925,
    comune: "CERGNAGO",
    sigla: "PV",
    codice_elettorale: 1030570410,
    codice_istat: 18045,
    codice_belfiore: "C509",
  },
  {
    id: 1926,
    comune: "CERIALE",
    sigla: "SV",
    codice_elettorale: 1070740240,
    codice_istat: 9024,
    codice_belfiore: "C510",
  },
  {
    id: 1927,
    comune: "CERIANA",
    sigla: "IM",
    codice_elettorale: 1070370150,
    codice_istat: 8016,
    codice_belfiore: "C511",
  },
  {
    id: 1928,
    comune: "CERIANO LAGHETTO",
    sigla: "MB",
    codice_elettorale: 1031040180,
    codice_istat: 108018,
    codice_belfiore: "C512",
  },
  {
    id: 1929,
    comune: "CERIGNALE",
    sigla: "PC",
    codice_elettorale: 1080610150,
    codice_istat: 33015,
    codice_belfiore: "C513",
  },
  {
    id: 1930,
    comune: "CERIGNOLA",
    sigla: "FG",
    codice_elettorale: 3160310190,
    codice_istat: 71020,
    codice_belfiore: "C514",
  },
  {
    id: 1931,
    comune: "CERISANO",
    sigla: "CS",
    codice_elettorale: 3180250370,
    codice_istat: 78037,
    codice_belfiore: "C515",
  },
  {
    id: 1932,
    comune: "CERMENATE",
    sigla: "CO",
    codice_elettorale: 1030240610,
    codice_istat: 13064,
    codice_belfiore: "C516",
  },
  {
    id: 1933,
    comune: "CERMES/TSCHERMS",
    sigla: "BZ",
    codice_elettorale: 1040140170,
    codice_istat: 21020,
    codice_belfiore: "A022",
  },
  {
    id: 1934,
    comune: "CERMIGNANO",
    sigla: "TE",
    codice_elettorale: 3130790150,
    codice_istat: 67016,
    codice_belfiore: "C517",
  },
  {
    id: 1935,
    comune: "CERNOBBIO",
    sigla: "CO",
    codice_elettorale: 1030240620,
    codice_istat: 13065,
    codice_belfiore: "C520",
  },
  {
    id: 1936,
    comune: "CERNUSCO LOMBARDONE",
    sigla: "LC",
    codice_elettorale: 1030980200,
    codice_istat: 97020,
    codice_belfiore: "C521",
  },
  {
    id: 1937,
    comune: "CERNUSCO SUL NAVIGLIO",
    sigla: "MI",
    codice_elettorale: 1030490700,
    codice_istat: 15070,
    codice_belfiore: "C523",
  },
  {
    id: 1938,
    comune: "CERRETO D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070350,
    codice_istat: 5035,
    codice_belfiore: "C528",
  },
  {
    id: 1939,
    comune: "CERRETO D'ESI",
    sigla: "AN",
    codice_elettorale: 2110030130,
    codice_istat: 42013,
    codice_belfiore: "C524",
  },
  {
    id: 1940,
    comune: "CERRETO DI SPOLETO",
    sigla: "PG",
    codice_elettorale: 2100580100,
    codice_istat: 54010,
    codice_belfiore: "C527",
  },
  {
    id: 1941,
    comune: "CERRETO GRUE",
    sigla: "AL",
    codice_elettorale: 1010020560,
    codice_istat: 6058,
    codice_belfiore: "C507",
  },
  {
    id: 1942,
    comune: "CERRETO GUIDI",
    sigla: "FI",
    codice_elettorale: 2090300110,
    codice_istat: 48011,
    codice_belfiore: "C529",
  },
  {
    id: 1943,
    comune: "CERRETO LAZIALE",
    sigla: "RM",
    codice_elettorale: 2120700270,
    codice_istat: 58027,
    codice_belfiore: "C518",
  },
  {
    id: 1944,
    comune: "CERRETO SANNITA",
    sigla: "BN",
    codice_elettorale: 3150110230,
    codice_istat: 62023,
    codice_belfiore: "C525",
  },
  {
    id: 1945,
    comune: "CERRETTO LANGHE",
    sigla: "CN",
    codice_elettorale: 1010270630,
    codice_istat: 4063,
    codice_belfiore: "C530",
  },
  {
    id: 1946,
    comune: "CERRINA MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020570,
    codice_istat: 6059,
    codice_belfiore: "C531",
  },
  {
    id: 1947,
    comune: "CERRIONE",
    sigla: "BI",
    codice_elettorale: 1010960180,
    codice_istat: 96018,
    codice_belfiore: "C532",
  },
  {
    id: 1948,
    comune: "CERRO AL LAMBRO",
    sigla: "MI",
    codice_elettorale: 1030490710,
    codice_istat: 15071,
    codice_belfiore: "C536",
  },
  {
    id: 1949,
    comune: "CERRO AL VOLTURNO",
    sigla: "IS",
    codice_elettorale: 3140940140,
    codice_istat: 94014,
    codice_belfiore: "C534",
  },
  {
    id: 1950,
    comune: "CERRO MAGGIORE",
    sigla: "MI",
    codice_elettorale: 1030490720,
    codice_istat: 15072,
    codice_belfiore: "C537",
  },
  {
    id: 1951,
    comune: "CERRO TANARO",
    sigla: "AT",
    codice_elettorale: 1010070360,
    codice_istat: 5036,
    codice_belfiore: "C533",
  },
  {
    id: 1952,
    comune: "CERRO VERONESE",
    sigla: "VR",
    codice_elettorale: 1050890260,
    codice_istat: 23026,
    codice_belfiore: "C538",
  },
  {
    id: 1953,
    comune: "CERSOSIMO",
    sigla: "PZ",
    codice_elettorale: 3170640270,
    codice_istat: 76027,
    codice_belfiore: "C539",
  },
  {
    id: 1954,
    comune: "CERTALDO",
    sigla: "FI",
    codice_elettorale: 2090300120,
    codice_istat: 48012,
    codice_belfiore: "C540",
  },
  {
    id: 1955,
    comune: "CERTOSA DI PAVIA",
    sigla: "PV",
    codice_elettorale: 1030570430,
    codice_istat: 18046,
    codice_belfiore: "C541",
  },
  {
    id: 1956,
    comune: "CERVA",
    sigla: "CZ",
    codice_elettorale: 3180220260,
    codice_istat: 79027,
    codice_belfiore: "C542",
  },
  {
    id: 1957,
    comune: "CERVARA DI ROMA",
    sigla: "RM",
    codice_elettorale: 2120700280,
    codice_istat: 58028,
    codice_belfiore: "C543",
  },
  {
    id: 1958,
    comune: "CERVARESE SANTA CROCE",
    sigla: "PD",
    codice_elettorale: 1050540300,
    codice_istat: 28030,
    codice_belfiore: "C544",
  },
  {
    id: 1959,
    comune: "CERVARO",
    sigla: "FR",
    codice_elettorale: 2120330260,
    codice_istat: 60026,
    codice_belfiore: "C545",
  },
  {
    id: 1960,
    comune: "CERVASCA",
    sigla: "CN",
    codice_elettorale: 1010270640,
    codice_istat: 4064,
    codice_belfiore: "C547",
  },
  {
    id: 1961,
    comune: "CERVATTO",
    sigla: "VC",
    codice_elettorale: 1010880410,
    codice_istat: 2041,
    codice_belfiore: "C548",
  },
  {
    id: 1962,
    comune: "CERVENO",
    sigla: "BS",
    codice_elettorale: 1030150450,
    codice_istat: 17049,
    codice_belfiore: "C549",
  },
  {
    id: 1963,
    comune: "CERVERE",
    sigla: "CN",
    codice_elettorale: 1010270650,
    codice_istat: 4065,
    codice_belfiore: "C550",
  },
  {
    id: 1964,
    comune: "CERVESINA",
    sigla: "PV",
    codice_elettorale: 1030570440,
    codice_istat: 18047,
    codice_belfiore: "C551",
  },
  {
    id: 1965,
    comune: "CERVETERI",
    sigla: "RM",
    codice_elettorale: 2120700290,
    codice_istat: 58029,
    codice_belfiore: "C552",
  },
  {
    id: 1966,
    comune: "CERVIA",
    sigla: "RA",
    codice_elettorale: 1080660070,
    codice_istat: 39007,
    codice_belfiore: "C553",
  },
  {
    id: 1967,
    comune: "CERVICATI",
    sigla: "CS",
    codice_elettorale: 3180250380,
    codice_istat: 78038,
    codice_belfiore: "C554",
  },
  {
    id: 1968,
    comune: "CERVIGNANO D'ADDA",
    sigla: "LO",
    codice_elettorale: 1030990180,
    codice_istat: 98018,
    codice_belfiore: "C555",
  },
  {
    id: 1969,
    comune: "CERVIGNANO DEL FRIULI",
    sigla: "UD",
    codice_elettorale: 1060850230,
    codice_istat: 30023,
    codice_belfiore: "C556",
  },
  {
    id: 1970,
    comune: "CERVINARA",
    sigla: "AV",
    codice_elettorale: 3150080250,
    codice_istat: 64025,
    codice_belfiore: "C557",
  },
  {
    id: 1971,
    comune: "CERVINO",
    sigla: "CE",
    codice_elettorale: 3150200280,
    codice_istat: 61028,
    codice_belfiore: "C558",
  },
  {
    id: 1972,
    comune: "CERVO",
    sigla: "IM",
    codice_elettorale: 1070370160,
    codice_istat: 8017,
    codice_belfiore: "C559",
  },
  {
    id: 1973,
    comune: "CERZETO",
    sigla: "CS",
    codice_elettorale: 3180250390,
    codice_istat: 78039,
    codice_belfiore: "C560",
  },
  {
    id: 1974,
    comune: "CESA",
    sigla: "CE",
    codice_elettorale: 3150200290,
    codice_istat: 61029,
    codice_belfiore: "C561",
  },
  {
    id: 1975,
    comune: "CESANA BRIANZA",
    sigla: "LC",
    codice_elettorale: 1030980210,
    codice_istat: 97021,
    codice_belfiore: "C563",
  },
  {
    id: 1976,
    comune: "CESANA TORINESE",
    sigla: "TO",
    codice_elettorale: 1010810720,
    codice_istat: 1074,
    codice_belfiore: "C564",
  },
  {
    id: 1977,
    comune: "CESANO BOSCONE",
    sigla: "MI",
    codice_elettorale: 1030490740,
    codice_istat: 15074,
    codice_belfiore: "C565",
  },
  {
    id: 1978,
    comune: "CESANO MADERNO",
    sigla: "MB",
    codice_elettorale: 1031040190,
    codice_istat: 108019,
    codice_belfiore: "C566",
  },
  {
    id: 1979,
    comune: "CESARA",
    sigla: "VB",
    codice_elettorale: 1011020220,
    codice_istat: 103022,
    codice_belfiore: "C567",
  },
  {
    id: 1980,
    comune: "CESARO'",
    sigla: "ME",
    codice_elettorale: 4190480170,
    codice_istat: 83017,
    codice_belfiore: "C568",
  },
  {
    id: 1981,
    comune: "CESATE",
    sigla: "MI",
    codice_elettorale: 1030490760,
    codice_istat: 15076,
    codice_belfiore: "C569",
  },
  {
    id: 1982,
    comune: "CESENA",
    sigla: "FC",
    codice_elettorale: 1080320060,
    codice_istat: 40007,
    codice_belfiore: "C573",
  },
  {
    id: 1983,
    comune: "CESENATICO",
    sigla: "FC",
    codice_elettorale: 1080320070,
    codice_istat: 40008,
    codice_belfiore: "C574",
  },
  {
    id: 1984,
    comune: "CESINALI",
    sigla: "AV",
    codice_elettorale: 3150080260,
    codice_istat: 64026,
    codice_belfiore: "C576",
  },
  {
    id: 1985,
    comune: "CESIO",
    sigla: "IM",
    codice_elettorale: 1070370170,
    codice_istat: 8018,
    codice_belfiore: "C578",
  },
  {
    id: 1986,
    comune: "CESIOMAGGIORE",
    sigla: "BL",
    codice_elettorale: 1050100110,
    codice_istat: 25011,
    codice_belfiore: "C577",
  },
  {
    id: 1987,
    comune: "CESSALTO",
    sigla: "TV",
    codice_elettorale: 1050840150,
    codice_istat: 26015,
    codice_belfiore: "C580",
  },
  {
    id: 1988,
    comune: "CESSANITI",
    sigla: "VV",
    codice_elettorale: 3181030060,
    codice_istat: 102006,
    codice_belfiore: "C581",
  },
  {
    id: 1989,
    comune: "CESSAPALOMBO",
    sigla: "MC",
    codice_elettorale: 2110440110,
    codice_istat: 43011,
    codice_belfiore: "C582",
  },
  {
    id: 1990,
    comune: "CESSOLE",
    sigla: "AT",
    codice_elettorale: 1010070370,
    codice_istat: 5037,
    codice_belfiore: "C583",
  },
  {
    id: 1991,
    comune: "CETARA",
    sigla: "SA",
    codice_elettorale: 3150720410,
    codice_istat: 65041,
    codice_belfiore: "C584",
  },
  {
    id: 1992,
    comune: "CETO",
    sigla: "BS",
    codice_elettorale: 1030150460,
    codice_istat: 17050,
    codice_belfiore: "C585",
  },
  {
    id: 1993,
    comune: "CETONA",
    sigla: "SI",
    codice_elettorale: 2090750080,
    codice_istat: 52008,
    codice_belfiore: "C587",
  },
  {
    id: 1994,
    comune: "CETRARO",
    sigla: "CS",
    codice_elettorale: 3180250400,
    codice_istat: 78040,
    codice_belfiore: "C588",
  },
  {
    id: 1995,
    comune: "CEVA",
    sigla: "CN",
    codice_elettorale: 1010270660,
    codice_istat: 4066,
    codice_belfiore: "C589",
  },
  {
    id: 1996,
    comune: "CEVO",
    sigla: "BS",
    codice_elettorale: 1030150461,
    codice_istat: 17051,
    codice_belfiore: "C591",
  },
  {
    id: 1997,
    comune: "CHALLAND-SAINT-ANSELME",
    sigla: "AO",
    codice_elettorale: 1020040130,
    codice_istat: 7013,
    codice_belfiore: "C593",
  },
  {
    id: 1998,
    comune: "CHALLAND-SAINT-VICTOR",
    sigla: "AO",
    codice_elettorale: 1020040140,
    codice_istat: 7014,
    codice_belfiore: "C594",
  },
  {
    id: 1999,
    comune: "CHAMBAVE",
    sigla: "AO",
    codice_elettorale: 1020040150,
    codice_istat: 7015,
    codice_belfiore: "C595",
  },
  {
    id: 2000,
    comune: "CHAMOIS",
    sigla: "AO",
    codice_elettorale: 1020040160,
    codice_istat: 7016,
    codice_belfiore: "B491",
  },
  {
    id: 2001,
    comune: "CHAMPDEPRAZ",
    sigla: "AO",
    codice_elettorale: 1020040170,
    codice_istat: 7017,
    codice_belfiore: "C596",
  },
  {
    id: 2002,
    comune: "CHAMPORCHER",
    sigla: "AO",
    codice_elettorale: 1020040180,
    codice_istat: 7018,
    codice_belfiore: "B540",
  },
  {
    id: 2003,
    comune: "CHARVENSOD",
    sigla: "AO",
    codice_elettorale: 1020040190,
    codice_istat: 7019,
    codice_belfiore: "C598",
  },
  {
    id: 2004,
    comune: "CHATILLON",
    sigla: "AO",
    codice_elettorale: 1020040200,
    codice_istat: 7020,
    codice_belfiore: "C294",
  },
  {
    id: 2005,
    comune: "CHERASCO",
    sigla: "CN",
    codice_elettorale: 1010270670,
    codice_istat: 4067,
    codice_belfiore: "C599",
  },
  {
    id: 2006,
    comune: "CHEREMULE",
    sigla: "SS",
    codice_elettorale: 4200730240,
    codice_istat: 90024,
    codice_belfiore: "C600",
  },
  {
    id: 2007,
    comune: "CHIALAMBERTO",
    sigla: "TO",
    codice_elettorale: 1010810730,
    codice_istat: 1075,
    codice_belfiore: "C604",
  },
  {
    id: 2008,
    comune: "CHIAMPO",
    sigla: "VI",
    codice_elettorale: 1050900290,
    codice_istat: 24029,
    codice_belfiore: "C605",
  },
  {
    id: 2009,
    comune: "CHIANCHE",
    sigla: "AV",
    codice_elettorale: 3150080270,
    codice_istat: 64027,
    codice_belfiore: "C606",
  },
  {
    id: 2010,
    comune: "CHIANCIANO TERME",
    sigla: "SI",
    codice_elettorale: 2090750090,
    codice_istat: 52009,
    codice_belfiore: "C608",
  },
  {
    id: 2011,
    comune: "CHIANNI",
    sigla: "PI",
    codice_elettorale: 2090620120,
    codice_istat: 50012,
    codice_belfiore: "C609",
  },
  {
    id: 2012,
    comune: "CHIANOCCO",
    sigla: "TO",
    codice_elettorale: 1010810740,
    codice_istat: 1076,
    codice_belfiore: "C610",
  },
  {
    id: 2013,
    comune: "CHIARAMONTE GULFI",
    sigla: "RG",
    codice_elettorale: 4190650020,
    codice_istat: 88002,
    codice_belfiore: "C612",
  },
  {
    id: 2014,
    comune: "CHIARAMONTI",
    sigla: "SS",
    codice_elettorale: 4200730250,
    codice_istat: 90025,
    codice_belfiore: "C613",
  },
  {
    id: 2015,
    comune: "CHIARANO",
    sigla: "TV",
    codice_elettorale: 1050840160,
    codice_istat: 26016,
    codice_belfiore: "C614",
  },
  {
    id: 2016,
    comune: "CHIARAVALLE",
    sigla: "AN",
    codice_elettorale: 2110030140,
    codice_istat: 42014,
    codice_belfiore: "C615",
  },
  {
    id: 2017,
    comune: "CHIARAVALLE CENTRALE",
    sigla: "CZ",
    codice_elettorale: 3180220280,
    codice_istat: 79029,
    codice_belfiore: "C616",
  },
  {
    id: 2018,
    comune: "CHIARI",
    sigla: "BS",
    codice_elettorale: 1030150470,
    codice_istat: 17052,
    codice_belfiore: "C618",
  },
  {
    id: 2019,
    comune: "CHIAROMONTE",
    sigla: "PZ",
    codice_elettorale: 3170640280,
    codice_istat: 76028,
    codice_belfiore: "C619",
  },
  {
    id: 2020,
    comune: "CHIAUCI",
    sigla: "IS",
    codice_elettorale: 3140940150,
    codice_istat: 94015,
    codice_belfiore: "C620",
  },
  {
    id: 2021,
    comune: "CHIAVARI",
    sigla: "GE",
    codice_elettorale: 1070340150,
    codice_istat: 10015,
    codice_belfiore: "C621",
  },
  {
    id: 2022,
    comune: "CHIAVENNA",
    sigla: "SO",
    codice_elettorale: 1030770180,
    codice_istat: 14018,
    codice_belfiore: "C623",
  },
  {
    id: 2023,
    comune: "CHIAVERANO",
    sigla: "TO",
    codice_elettorale: 1010810750,
    codice_istat: 1077,
    codice_belfiore: "C624",
  },
  {
    id: 2024,
    comune: "CHIENES/KIENS",
    sigla: "BZ",
    codice_elettorale: 1040140180,
    codice_istat: 21021,
    codice_belfiore: "C625",
  },
  {
    id: 2025,
    comune: "CHIERI",
    sigla: "TO",
    codice_elettorale: 1010810760,
    codice_istat: 1078,
    codice_belfiore: "C627",
  },
  {
    id: 2026,
    comune: "CHIES D'ALPAGO",
    sigla: "BL",
    codice_elettorale: 1050100120,
    codice_istat: 25012,
    codice_belfiore: "C630",
  },
  {
    id: 2027,
    comune: "CHIESA IN VALMALENCO",
    sigla: "SO",
    codice_elettorale: 1030770190,
    codice_istat: 14019,
    codice_belfiore: "C628",
  },
  {
    id: 2028,
    comune: "CHIESANUOVA",
    sigla: "TO",
    codice_elettorale: 1010810770,
    codice_istat: 1079,
    codice_belfiore: "C629",
  },
  {
    id: 2029,
    comune: "CHIESINA UZZANESE",
    sigla: "PT",
    codice_elettorale: 2090630031,
    codice_istat: 47022,
    codice_belfiore: "C631",
  },
  {
    id: 2030,
    comune: "CHIETI",
    sigla: "CH",
    codice_elettorale: 3130230220,
    codice_istat: 69022,
    codice_belfiore: "C632",
  },
  {
    id: 2031,
    comune: "CHIEUTI",
    sigla: "FG",
    codice_elettorale: 3160310200,
    codice_istat: 71021,
    codice_belfiore: "C633",
  },
  {
    id: 2032,
    comune: "CHIEVE",
    sigla: "CR",
    codice_elettorale: 1030260280,
    codice_istat: 19029,
    codice_belfiore: "C634",
  },
  {
    id: 2033,
    comune: "CHIGNOLO D'ISOLA",
    sigla: "BG",
    codice_elettorale: 1030120690,
    codice_istat: 16072,
    codice_belfiore: "C635",
  },
  {
    id: 2034,
    comune: "CHIGNOLO PO",
    sigla: "PV",
    codice_elettorale: 1030570450,
    codice_istat: 18048,
    codice_belfiore: "C637",
  },
  {
    id: 2035,
    comune: "CHIOGGIA",
    sigla: "VE",
    codice_elettorale: 1050870080,
    codice_istat: 27008,
    codice_belfiore: "C638",
  },
  {
    id: 2036,
    comune: "CHIOMONTE",
    sigla: "TO",
    codice_elettorale: 1010810780,
    codice_istat: 1080,
    codice_belfiore: "C639",
  },
  {
    id: 2037,
    comune: "CHIONS",
    sigla: "PN",
    codice_elettorale: 1060930130,
    codice_istat: 93013,
    codice_belfiore: "C640",
  },
  {
    id: 2038,
    comune: "CHIOPRIS-VISCONE",
    sigla: "UD",
    codice_elettorale: 1060850240,
    codice_istat: 30024,
    codice_belfiore: "C641",
  },
  {
    id: 2039,
    comune: "CHITIGNANO",
    sigla: "AR",
    codice_elettorale: 2090050140,
    codice_istat: 51014,
    codice_belfiore: "C648",
  },
  {
    id: 2040,
    comune: "CHIUDUNO",
    sigla: "BG",
    codice_elettorale: 1030120700,
    codice_istat: 16073,
    codice_belfiore: "C649",
  },
  {
    id: 2041,
    comune: "CHIUPPANO",
    sigla: "VI",
    codice_elettorale: 1050900300,
    codice_istat: 24030,
    codice_belfiore: "C650",
  },
  {
    id: 2042,
    comune: "CHIURO",
    sigla: "SO",
    codice_elettorale: 1030770200,
    codice_istat: 14020,
    codice_belfiore: "C651",
  },
  {
    id: 2043,
    comune: "CHIUSA DI PESIO",
    sigla: "CN",
    codice_elettorale: 1010270680,
    codice_istat: 4068,
    codice_belfiore: "C653",
  },
  {
    id: 2044,
    comune: "CHIUSA DI SAN MICHELE",
    sigla: "TO",
    codice_elettorale: 1010810790,
    codice_istat: 1081,
    codice_belfiore: "C655",
  },
  {
    id: 2045,
    comune: "CHIUSA SCLAFANI",
    sigla: "PA",
    codice_elettorale: 4190550270,
    codice_istat: 82029,
    codice_belfiore: "C654",
  },
  {
    id: 2046,
    comune: "CHIUSA/KLAUSEN",
    sigla: "BZ",
    codice_elettorale: 1040140190,
    codice_istat: 21022,
    codice_belfiore: "C652",
  },
  {
    id: 2047,
    comune: "CHIUSAFORTE",
    sigla: "UD",
    codice_elettorale: 1060850250,
    codice_istat: 30025,
    codice_belfiore: "C656",
  },
  {
    id: 2048,
    comune: "CHIUSANICO",
    sigla: "IM",
    codice_elettorale: 1070370180,
    codice_istat: 8019,
    codice_belfiore: "C657",
  },
  {
    id: 2049,
    comune: "CHIUSANO D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070380,
    codice_istat: 5038,
    codice_belfiore: "C658",
  },
  {
    id: 2050,
    comune: "CHIUSANO DI SAN DOMENICO",
    sigla: "AV",
    codice_elettorale: 3150080280,
    codice_istat: 64028,
    codice_belfiore: "C659",
  },
  {
    id: 2051,
    comune: "CHIUSAVECCHIA",
    sigla: "IM",
    codice_elettorale: 1070370190,
    codice_istat: 8020,
    codice_belfiore: "C660",
  },
  {
    id: 2052,
    comune: "CHIUSDINO",
    sigla: "SI",
    codice_elettorale: 2090750100,
    codice_istat: 52010,
    codice_belfiore: "C661",
  },
  {
    id: 2053,
    comune: "CHIUSI",
    sigla: "SI",
    codice_elettorale: 2090750110,
    codice_istat: 52011,
    codice_belfiore: "C662",
  },
  {
    id: 2054,
    comune: "CHIUSI DELLA VERNA",
    sigla: "AR",
    codice_elettorale: 2090050150,
    codice_istat: 51015,
    codice_belfiore: "C663",
  },
  {
    id: 2055,
    comune: "CHIVASSO",
    sigla: "TO",
    codice_elettorale: 1010810800,
    codice_istat: 1082,
    codice_belfiore: "C665",
  },
  {
    id: 2056,
    comune: "CIAMPINO",
    sigla: "RM",
    codice_elettorale: 2120700291,
    codice_istat: 58118,
    codice_belfiore: "M272",
  },
  {
    id: 2057,
    comune: "CIANCIANA",
    sigla: "AG",
    codice_elettorale: 4190010150,
    codice_istat: 84015,
    codice_belfiore: "C668",
  },
  {
    id: 2058,
    comune: "CIBIANA DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100130,
    codice_istat: 25013,
    codice_belfiore: "C672",
  },
  {
    id: 2059,
    comune: "CICAGNA",
    sigla: "GE",
    codice_elettorale: 1070340160,
    codice_istat: 10016,
    codice_belfiore: "C673",
  },
  {
    id: 2060,
    comune: "CICALA",
    sigla: "CZ",
    codice_elettorale: 3180220290,
    codice_istat: 79030,
    codice_belfiore: "C674",
  },
  {
    id: 2061,
    comune: "CICCIANO",
    sigla: "NA",
    codice_elettorale: 3150510270,
    codice_istat: 63027,
    codice_belfiore: "C675",
  },
  {
    id: 2062,
    comune: "CICERALE",
    sigla: "SA",
    codice_elettorale: 3150720420,
    codice_istat: 65042,
    codice_belfiore: "C676",
  },
  {
    id: 2063,
    comune: "CICILIANO",
    sigla: "RM",
    codice_elettorale: 2120700300,
    codice_istat: 58030,
    codice_belfiore: "C677",
  },
  {
    id: 2064,
    comune: "CICOGNOLO",
    sigla: "CR",
    codice_elettorale: 1030260290,
    codice_istat: 19030,
    codice_belfiore: "C678",
  },
  {
    id: 2065,
    comune: "CICONIO",
    sigla: "TO",
    codice_elettorale: 1010810810,
    codice_istat: 1083,
    codice_belfiore: "C679",
  },
  {
    id: 2066,
    comune: "CIGLIANO",
    sigla: "VC",
    codice_elettorale: 1010880420,
    codice_istat: 2042,
    codice_belfiore: "C680",
  },
  {
    id: 2067,
    comune: "CIGLIE'",
    sigla: "CN",
    codice_elettorale: 1010270690,
    codice_istat: 4069,
    codice_belfiore: "C681",
  },
  {
    id: 2068,
    comune: "CIGOGNOLA",
    sigla: "PV",
    codice_elettorale: 1030570460,
    codice_istat: 18049,
    codice_belfiore: "C684",
  },
  {
    id: 2069,
    comune: "CIGOLE",
    sigla: "BS",
    codice_elettorale: 1030150480,
    codice_istat: 17053,
    codice_belfiore: "C685",
  },
  {
    id: 2070,
    comune: "CILAVEGNA",
    sigla: "PV",
    codice_elettorale: 1030570470,
    codice_istat: 18050,
    codice_belfiore: "C686",
  },
  {
    id: 2071,
    comune: "CIMADOLMO",
    sigla: "TV",
    codice_elettorale: 1050840170,
    codice_istat: 26017,
    codice_belfiore: "C689",
  },
  {
    id: 2072,
    comune: "CIMBERGO",
    sigla: "BS",
    codice_elettorale: 1030150490,
    codice_istat: 17054,
    codice_belfiore: "C691",
  },
  {
    id: 2073,
    comune: "CIMINA'",
    sigla: "RC",
    codice_elettorale: 3180670260,
    codice_istat: 80026,
    codice_belfiore: "C695",
  },
  {
    id: 2074,
    comune: "CIMINNA",
    sigla: "PA",
    codice_elettorale: 4190550280,
    codice_istat: 82030,
    codice_belfiore: "C696",
  },
  {
    id: 2075,
    comune: "CIMITILE",
    sigla: "NA",
    codice_elettorale: 3150510280,
    codice_istat: 63028,
    codice_belfiore: "C697",
  },
  {
    id: 2076,
    comune: "CIMOLAIS",
    sigla: "PN",
    codice_elettorale: 1060930140,
    codice_istat: 93014,
    codice_belfiore: "C699",
  },
  {
    id: 2077,
    comune: "CIMONE",
    sigla: "TN",
    codice_elettorale: 1040830540,
    codice_istat: 22058,
    codice_belfiore: "C700",
  },
  {
    id: 2078,
    comune: "CINAGLIO",
    sigla: "AT",
    codice_elettorale: 1010070390,
    codice_istat: 5039,
    codice_belfiore: "C701",
  },
  {
    id: 2079,
    comune: "CINETO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700310,
    codice_istat: 58031,
    codice_belfiore: "C702",
  },
  {
    id: 2080,
    comune: "CINGIA DE' BOTTI",
    sigla: "CR",
    codice_elettorale: 1030260300,
    codice_istat: 19031,
    codice_belfiore: "C703",
  },
  {
    id: 2081,
    comune: "CINGOLI",
    sigla: "MC",
    codice_elettorale: 2110440120,
    codice_istat: 43012,
    codice_belfiore: "C704",
  },
  {
    id: 2082,
    comune: "CINIGIANO",
    sigla: "GR",
    codice_elettorale: 2090360060,
    codice_istat: 53007,
    codice_belfiore: "C705",
  },
  {
    id: 2083,
    comune: "CINISELLO BALSAMO",
    sigla: "MI",
    codice_elettorale: 1030490770,
    codice_istat: 15077,
    codice_belfiore: "C707",
  },
  {
    id: 2084,
    comune: "CINISI",
    sigla: "PA",
    codice_elettorale: 4190550290,
    codice_istat: 82031,
    codice_belfiore: "C708",
  },
  {
    id: 2085,
    comune: "CINO",
    sigla: "SO",
    codice_elettorale: 1030770210,
    codice_istat: 14021,
    codice_belfiore: "C709",
  },
  {
    id: 2086,
    comune: "CINQUEFRONDI",
    sigla: "RC",
    codice_elettorale: 3180670270,
    codice_istat: 80027,
    codice_belfiore: "C710",
  },
  {
    id: 2087,
    comune: "CINTANO",
    sigla: "TO",
    codice_elettorale: 1010810820,
    codice_istat: 1084,
    codice_belfiore: "C711",
  },
  {
    id: 2088,
    comune: "CINTE TESINO",
    sigla: "TN",
    codice_elettorale: 1040830550,
    codice_istat: 22059,
    codice_belfiore: "C712",
  },
  {
    id: 2089,
    comune: "CINTO CAOMAGGIORE",
    sigla: "VE",
    codice_elettorale: 1050870090,
    codice_istat: 27009,
    codice_belfiore: "C714",
  },
  {
    id: 2090,
    comune: "CINTO EUGANEO",
    sigla: "PD",
    codice_elettorale: 1050540310,
    codice_istat: 28031,
    codice_belfiore: "C713",
  },
  {
    id: 2091,
    comune: "CINZANO",
    sigla: "TO",
    codice_elettorale: 1010810830,
    codice_istat: 1085,
    codice_belfiore: "C715",
  },
  {
    id: 2092,
    comune: "CIORLANO",
    sigla: "CE",
    codice_elettorale: 3150200300,
    codice_istat: 61030,
    codice_belfiore: "C716",
  },
  {
    id: 2093,
    comune: "CIPRESSA",
    sigla: "IM",
    codice_elettorale: 1070370200,
    codice_istat: 8021,
    codice_belfiore: "C718",
  },
  {
    id: 2094,
    comune: "CIRCELLO",
    sigla: "BN",
    codice_elettorale: 3150110240,
    codice_istat: 62024,
    codice_belfiore: "C719",
  },
  {
    id: 2095,
    comune: "CIRIE'",
    sigla: "TO",
    codice_elettorale: 1010810840,
    codice_istat: 1086,
    codice_belfiore: "C722",
  },
  {
    id: 2096,
    comune: "CIRIGLIANO",
    sigla: "MT",
    codice_elettorale: 3170470050,
    codice_istat: 77005,
    codice_belfiore: "C723",
  },
  {
    id: 2097,
    comune: "CIRIMIDO",
    sigla: "CO",
    codice_elettorale: 1030240650,
    codice_istat: 13068,
    codice_belfiore: "C724",
  },
  {
    id: 2098,
    comune: "CIRO'",
    sigla: "KR",
    codice_elettorale: 3180970070,
    codice_istat: 101007,
    codice_belfiore: "C725",
  },
  {
    id: 2099,
    comune: "CIRO' MARINA",
    sigla: "KR",
    codice_elettorale: 3180970080,
    codice_istat: 101008,
    codice_belfiore: "C726",
  },
  {
    id: 2100,
    comune: "CIS",
    sigla: "TN",
    codice_elettorale: 1040830560,
    codice_istat: 22060,
    codice_belfiore: "C727",
  },
  {
    id: 2101,
    comune: "CISANO BERGAMASCO",
    sigla: "BG",
    codice_elettorale: 1030120710,
    codice_istat: 16074,
    codice_belfiore: "C728",
  },
  {
    id: 2102,
    comune: "CISANO SUL NEVA",
    sigla: "SV",
    codice_elettorale: 1070740250,
    codice_istat: 9025,
    codice_belfiore: "C729",
  },
  {
    id: 2103,
    comune: "CISERANO",
    sigla: "BG",
    codice_elettorale: 1030120720,
    codice_istat: 16075,
    codice_belfiore: "C730",
  },
  {
    id: 2104,
    comune: "CISLAGO",
    sigla: "VA",
    codice_elettorale: 1030860430,
    codice_istat: 12050,
    codice_belfiore: "C732",
  },
  {
    id: 2105,
    comune: "CISLIANO",
    sigla: "MI",
    codice_elettorale: 1030490780,
    codice_istat: 15078,
    codice_belfiore: "C733",
  },
  {
    id: 2106,
    comune: "CISON DI VALMARINO",
    sigla: "TV",
    codice_elettorale: 1050840180,
    codice_istat: 26018,
    codice_belfiore: "C735",
  },
  {
    id: 2107,
    comune: "CISSONE",
    sigla: "CN",
    codice_elettorale: 1010270700,
    codice_istat: 4070,
    codice_belfiore: "C738",
  },
  {
    id: 2108,
    comune: "CISTERNA D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070400,
    codice_istat: 5040,
    codice_belfiore: "C739",
  },
  {
    id: 2109,
    comune: "CISTERNA DI LATINA",
    sigla: "LT",
    codice_elettorale: 2120400050,
    codice_istat: 59005,
    codice_belfiore: "C740",
  },
  {
    id: 2110,
    comune: "CISTERNINO",
    sigla: "BR",
    codice_elettorale: 3160160050,
    codice_istat: 74005,
    codice_belfiore: "C741",
  },
  {
    id: 2111,
    comune: "CITERNA",
    sigla: "PG",
    codice_elettorale: 2100580110,
    codice_istat: 54011,
    codice_belfiore: "C742",
  },
  {
    id: 2112,
    comune: "CITTA' DELLA PIEVE",
    sigla: "PG",
    codice_elettorale: 2100580120,
    codice_istat: 54012,
    codice_belfiore: "C744",
  },
  {
    id: 2113,
    comune: "CITTA' DI CASTELLO",
    sigla: "PG",
    codice_elettorale: 2100580130,
    codice_istat: 54013,
    codice_belfiore: "C745",
  },
  {
    id: 2114,
    comune: "CITTA' SANT'ANGELO",
    sigla: "PE",
    codice_elettorale: 3130600120,
    codice_istat: 68012,
    codice_belfiore: "C750",
  },
  {
    id: 2115,
    comune: "CITTADELLA",
    sigla: "PD",
    codice_elettorale: 1050540320,
    codice_istat: 28032,
    codice_belfiore: "C743",
  },
  {
    id: 2116,
    comune: "CITTADUCALE",
    sigla: "RI",
    codice_elettorale: 2120690150,
    codice_istat: 57016,
    codice_belfiore: "C746",
  },
  {
    id: 2117,
    comune: "CITTANOVA",
    sigla: "RC",
    codice_elettorale: 3180670280,
    codice_istat: 80028,
    codice_belfiore: "C747",
  },
  {
    id: 2118,
    comune: "CITTAREALE",
    sigla: "RI",
    codice_elettorale: 2120690160,
    codice_istat: 57017,
    codice_belfiore: "C749",
  },
  {
    id: 2119,
    comune: "CITTIGLIO",
    sigla: "VA",
    codice_elettorale: 1030860440,
    codice_istat: 12051,
    codice_belfiore: "C751",
  },
  {
    id: 2120,
    comune: "CIVATE",
    sigla: "LC",
    codice_elettorale: 1030980220,
    codice_istat: 97022,
    codice_belfiore: "C752",
  },
  {
    id: 2121,
    comune: "CIVEZZA",
    sigla: "IM",
    codice_elettorale: 1070370210,
    codice_istat: 8022,
    codice_belfiore: "C755",
  },
  {
    id: 2122,
    comune: "CIVEZZANO",
    sigla: "TN",
    codice_elettorale: 1040830570,
    codice_istat: 22061,
    codice_belfiore: "C756",
  },
  {
    id: 2123,
    comune: "CIVIASCO",
    sigla: "VC",
    codice_elettorale: 1010880430,
    codice_istat: 2043,
    codice_belfiore: "C757",
  },
  {
    id: 2124,
    comune: "CIVIDALE DEL FRIULI",
    sigla: "UD",
    codice_elettorale: 1060850260,
    codice_istat: 30026,
    codice_belfiore: "C758",
  },
  {
    id: 2125,
    comune: "CIVIDATE AL PIANO",
    sigla: "BG",
    codice_elettorale: 1030120730,
    codice_istat: 16076,
    codice_belfiore: "C759",
  },
  {
    id: 2126,
    comune: "CIVIDATE CAMUNO",
    sigla: "BS",
    codice_elettorale: 1030150500,
    codice_istat: 17055,
    codice_belfiore: "C760",
  },
  {
    id: 2127,
    comune: "CIVITA",
    sigla: "CS",
    codice_elettorale: 3180250420,
    codice_istat: 78041,
    codice_belfiore: "C763",
  },
  {
    id: 2128,
    comune: "CIVITA CASTELLANA",
    sigla: "VT",
    codice_elettorale: 2120910200,
    codice_istat: 56021,
    codice_belfiore: "C765",
  },
  {
    id: 2129,
    comune: "CIVITA D'ANTINO",
    sigla: "AQ",
    codice_elettorale: 3130380340,
    codice_istat: 66034,
    codice_belfiore: "C766",
  },
  {
    id: 2130,
    comune: "CIVITACAMPOMARANO",
    sigla: "CB",
    codice_elettorale: 3140190190,
    codice_istat: 70019,
    codice_belfiore: "C764",
  },
  {
    id: 2131,
    comune: "CIVITALUPARELLA",
    sigla: "CH",
    codice_elettorale: 3130230230,
    codice_istat: 69023,
    codice_belfiore: "C768",
  },
  {
    id: 2132,
    comune: "CIVITANOVA DEL SANNIO",
    sigla: "IS",
    codice_elettorale: 3140940160,
    codice_istat: 94016,
    codice_belfiore: "C769",
  },
  {
    id: 2133,
    comune: "CIVITANOVA MARCHE",
    sigla: "MC",
    codice_elettorale: 2110440130,
    codice_istat: 43013,
    codice_belfiore: "C770",
  },
  {
    id: 2134,
    comune: "CIVITAQUANA",
    sigla: "PE",
    codice_elettorale: 3130600130,
    codice_istat: 68013,
    codice_belfiore: "C771",
  },
  {
    id: 2135,
    comune: "CIVITAVECCHIA",
    sigla: "RM",
    codice_elettorale: 2120700320,
    codice_istat: 58032,
    codice_belfiore: "C773",
  },
  {
    id: 2136,
    comune: "CIVITELLA ALFEDENA",
    sigla: "AQ",
    codice_elettorale: 3130380350,
    codice_istat: 66035,
    codice_belfiore: "C778",
  },
  {
    id: 2137,
    comune: "CIVITELLA CASANOVA",
    sigla: "PE",
    codice_elettorale: 3130600140,
    codice_istat: 68014,
    codice_belfiore: "C779",
  },
  {
    id: 2138,
    comune: "CIVITELLA D'AGLIANO",
    sigla: "VT",
    codice_elettorale: 2120910210,
    codice_istat: 56022,
    codice_belfiore: "C780",
  },
  {
    id: 2139,
    comune: "CIVITELLA DEL TRONTO",
    sigla: "TE",
    codice_elettorale: 3130790160,
    codice_istat: 67017,
    codice_belfiore: "C781",
  },
  {
    id: 2140,
    comune: "CIVITELLA DI ROMAGNA",
    sigla: "FC",
    codice_elettorale: 1080320080,
    codice_istat: 40009,
    codice_belfiore: "C777",
  },
  {
    id: 2141,
    comune: "CIVITELLA IN VAL DI CHIANA",
    sigla: "AR",
    codice_elettorale: 2090050160,
    codice_istat: 51016,
    codice_belfiore: "C774",
  },
  {
    id: 2142,
    comune: "CIVITELLA MESSER RAIMONDO",
    sigla: "CH",
    codice_elettorale: 3130230240,
    codice_istat: 69024,
    codice_belfiore: "C776",
  },
  {
    id: 2143,
    comune: "CIVITELLA PAGANICO",
    sigla: "GR",
    codice_elettorale: 2090360070,
    codice_istat: 53008,
    codice_belfiore: "C782",
  },
  {
    id: 2144,
    comune: "CIVITELLA ROVETO",
    sigla: "AQ",
    codice_elettorale: 3130380360,
    codice_istat: 66036,
    codice_belfiore: "C783",
  },
  {
    id: 2145,
    comune: "CIVITELLA SAN PAOLO",
    sigla: "RM",
    codice_elettorale: 2120700330,
    codice_istat: 58033,
    codice_belfiore: "C784",
  },
  {
    id: 2146,
    comune: "CIVO",
    sigla: "SO",
    codice_elettorale: 1030770220,
    codice_istat: 14022,
    codice_belfiore: "C785",
  },
  {
    id: 2147,
    comune: "CLAINO CON OSTENO",
    sigla: "CO",
    codice_elettorale: 1030240680,
    codice_istat: 13071,
    codice_belfiore: "C787",
  },
  {
    id: 2148,
    comune: "CLAUT",
    sigla: "PN",
    codice_elettorale: 1060930150,
    codice_istat: 93015,
    codice_belfiore: "C790",
  },
  {
    id: 2149,
    comune: "CLAUZETTO",
    sigla: "PN",
    codice_elettorale: 1060930160,
    codice_istat: 93016,
    codice_belfiore: "C791",
  },
  {
    id: 2150,
    comune: "CLAVESANA",
    sigla: "CN",
    codice_elettorale: 1010270710,
    codice_istat: 4071,
    codice_belfiore: "C792",
  },
  {
    id: 2151,
    comune: "CLAVIERE",
    sigla: "TO",
    codice_elettorale: 1010810850,
    codice_istat: 1087,
    codice_belfiore: "C793",
  },
  {
    id: 2152,
    comune: "CLES",
    sigla: "TN",
    codice_elettorale: 1040830580,
    codice_istat: 22062,
    codice_belfiore: "C794",
  },
  {
    id: 2153,
    comune: "CLETO",
    sigla: "CS",
    codice_elettorale: 3180250430,
    codice_istat: 78042,
    codice_belfiore: "C795",
  },
  {
    id: 2154,
    comune: "CLIVIO",
    sigla: "VA",
    codice_elettorale: 1030860441,
    codice_istat: 12052,
    codice_belfiore: "C796",
  },
  {
    id: 2155,
    comune: "CLUSONE",
    sigla: "BG",
    codice_elettorale: 1030120740,
    codice_istat: 16077,
    codice_belfiore: "C800",
  },
  {
    id: 2156,
    comune: "COASSOLO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010810860,
    codice_istat: 1088,
    codice_belfiore: "C801",
  },
  {
    id: 2157,
    comune: "COAZZE",
    sigla: "TO",
    codice_elettorale: 1010810870,
    codice_istat: 1089,
    codice_belfiore: "C803",
  },
  {
    id: 2158,
    comune: "COAZZOLO",
    sigla: "AT",
    codice_elettorale: 1010070410,
    codice_istat: 5041,
    codice_belfiore: "C804",
  },
  {
    id: 2159,
    comune: "COCCAGLIO",
    sigla: "BS",
    codice_elettorale: 1030150510,
    codice_istat: 17056,
    codice_belfiore: "C806",
  },
  {
    id: 2160,
    comune: "COCCONATO",
    sigla: "AT",
    codice_elettorale: 1010070420,
    codice_istat: 5042,
    codice_belfiore: "C807",
  },
  {
    id: 2161,
    comune: "COCQUIO-TREVISAGO",
    sigla: "VA",
    codice_elettorale: 1030860450,
    codice_istat: 12053,
    codice_belfiore: "C810",
  },
  {
    id: 2162,
    comune: "COCULLO",
    sigla: "AQ",
    codice_elettorale: 3130380370,
    codice_istat: 66037,
    codice_belfiore: "C811",
  },
  {
    id: 2163,
    comune: "CODEVIGO",
    sigla: "PD",
    codice_elettorale: 1050540330,
    codice_istat: 28033,
    codice_belfiore: "C812",
  },
  {
    id: 2164,
    comune: "CODEVILLA",
    sigla: "PV",
    codice_elettorale: 1030570480,
    codice_istat: 18051,
    codice_belfiore: "C813",
  },
  {
    id: 2165,
    comune: "CODIGORO",
    sigla: "FE",
    codice_elettorale: 1080290050,
    codice_istat: 38005,
    codice_belfiore: "C814",
  },
  {
    id: 2166,
    comune: "CODOGNE'",
    sigla: "TV",
    codice_elettorale: 1050840190,
    codice_istat: 26019,
    codice_belfiore: "C815",
  },
  {
    id: 2167,
    comune: "CODOGNO",
    sigla: "LO",
    codice_elettorale: 1030990190,
    codice_istat: 98019,
    codice_belfiore: "C816",
  },
  {
    id: 2168,
    comune: "CODROIPO",
    sigla: "UD",
    codice_elettorale: 1060850270,
    codice_istat: 30027,
    codice_belfiore: "C817",
  },
  {
    id: 2169,
    comune: "CODRONGIANOS",
    sigla: "SS",
    codice_elettorale: 4200730260,
    codice_istat: 90026,
    codice_belfiore: "C818",
  },
  {
    id: 2170,
    comune: "COGGIOLA",
    sigla: "BI",
    codice_elettorale: 1010960190,
    codice_istat: 96019,
    codice_belfiore: "C819",
  },
  {
    id: 2171,
    comune: "COGLIATE",
    sigla: "MB",
    codice_elettorale: 1031040200,
    codice_istat: 108020,
    codice_belfiore: "C820",
  },
  {
    id: 2172,
    comune: "COGNE",
    sigla: "AO",
    codice_elettorale: 1020040210,
    codice_istat: 7021,
    codice_belfiore: "C821",
  },
  {
    id: 2173,
    comune: "COGOLETO",
    sigla: "GE",
    codice_elettorale: 1070340170,
    codice_istat: 10017,
    codice_belfiore: "C823",
  },
  {
    id: 2174,
    comune: "COGOLLO DEL CENGIO",
    sigla: "VI",
    codice_elettorale: 1050900320,
    codice_istat: 24032,
    codice_belfiore: "C824",
  },
  {
    id: 2175,
    comune: "COGORNO",
    sigla: "GE",
    codice_elettorale: 1070340180,
    codice_istat: 10018,
    codice_belfiore: "C826",
  },
  {
    id: 2176,
    comune: "COLAZZA",
    sigla: "NO",
    codice_elettorale: 1010520491,
    codice_istat: 3051,
    codice_belfiore: "C829",
  },
  {
    id: 2177,
    comune: "COLCERESA",
    sigla: "VI",
    codice_elettorale: 1050900325,
    codice_istat: 24126,
    codice_belfiore: "M426",
  },
  {
    id: 2178,
    comune: "COLERE",
    sigla: "BG",
    codice_elettorale: 1030120750,
    codice_istat: 16078,
    codice_belfiore: "C835",
  },
  {
    id: 2179,
    comune: "COLFELICE",
    sigla: "FR",
    codice_elettorale: 2120330270,
    codice_istat: 60027,
    codice_belfiore: "C836",
  },
  {
    id: 2180,
    comune: "COLI",
    sigla: "PC",
    codice_elettorale: 1080610160,
    codice_istat: 33016,
    codice_belfiore: "C838",
  },
  {
    id: 2181,
    comune: "COLICO",
    sigla: "LC",
    codice_elettorale: 1030980230,
    codice_istat: 97023,
    codice_belfiore: "C839",
  },
  {
    id: 2182,
    comune: "COLLALTO SABINO",
    sigla: "RI",
    codice_elettorale: 2120690170,
    codice_istat: 57018,
    codice_belfiore: "C841",
  },
  {
    id: 2183,
    comune: "COLLARMELE",
    sigla: "AQ",
    codice_elettorale: 3130380380,
    codice_istat: 66038,
    codice_belfiore: "C844",
  },
  {
    id: 2184,
    comune: "COLLAZZONE",
    sigla: "PG",
    codice_elettorale: 2100580140,
    codice_istat: 54014,
    codice_belfiore: "C845",
  },
  {
    id: 2185,
    comune: "COLLE BRIANZA",
    sigla: "LC",
    codice_elettorale: 1030980240,
    codice_istat: 97024,
    codice_belfiore: "C851",
  },
  {
    id: 2186,
    comune: "COLLE D'ANCHISE",
    sigla: "CB",
    codice_elettorale: 3140190200,
    codice_istat: 70020,
    codice_belfiore: "C854",
  },
  {
    id: 2187,
    comune: "COLLE DI TORA",
    sigla: "RI",
    codice_elettorale: 2120690180,
    codice_istat: 57019,
    codice_belfiore: "C857",
  },
  {
    id: 2188,
    comune: "COLLE DI VAL D'ELSA",
    sigla: "SI",
    codice_elettorale: 2090750120,
    codice_istat: 52012,
    codice_belfiore: "C847",
  },
  {
    id: 2189,
    comune: "COLLE SAN MAGNO",
    sigla: "FR",
    codice_elettorale: 2120330290,
    codice_istat: 60029,
    codice_belfiore: "C870",
  },
  {
    id: 2190,
    comune: "COLLE SANNITA",
    sigla: "BN",
    codice_elettorale: 3150110250,
    codice_istat: 62025,
    codice_belfiore: "C846",
  },
  {
    id: 2191,
    comune: "COLLE SANTA LUCIA",
    sigla: "BL",
    codice_elettorale: 1050100140,
    codice_istat: 25014,
    codice_belfiore: "C872",
  },
  {
    id: 2192,
    comune: "COLLE UMBERTO",
    sigla: "TV",
    codice_elettorale: 1050840200,
    codice_istat: 26020,
    codice_belfiore: "C848",
  },
  {
    id: 2193,
    comune: "COLLEBEATO",
    sigla: "BS",
    codice_elettorale: 1030150520,
    codice_istat: 17057,
    codice_belfiore: "C850",
  },
  {
    id: 2194,
    comune: "COLLECCHIO",
    sigla: "PR",
    codice_elettorale: 1080560090,
    codice_istat: 34009,
    codice_belfiore: "C852",
  },
  {
    id: 2195,
    comune: "COLLECORVINO",
    sigla: "PE",
    codice_elettorale: 3130600150,
    codice_istat: 68015,
    codice_belfiore: "C853",
  },
  {
    id: 2196,
    comune: "COLLEDARA",
    sigla: "TE",
    codice_elettorale: 3130790170,
    codice_istat: 67018,
    codice_belfiore: "C311",
  },
  {
    id: 2197,
    comune: "COLLEDIMACINE",
    sigla: "CH",
    codice_elettorale: 3130230250,
    codice_istat: 69025,
    codice_belfiore: "C855",
  },
  {
    id: 2198,
    comune: "COLLEDIMEZZO",
    sigla: "CH",
    codice_elettorale: 3130230260,
    codice_istat: 69026,
    codice_belfiore: "C856",
  },
  {
    id: 2199,
    comune: "COLLEFERRO",
    sigla: "RM",
    codice_elettorale: 2120700340,
    codice_istat: 58034,
    codice_belfiore: "C858",
  },
  {
    id: 2200,
    comune: "COLLEGIOVE",
    sigla: "RI",
    codice_elettorale: 2120690190,
    codice_istat: 57020,
    codice_belfiore: "C859",
  },
  {
    id: 2201,
    comune: "COLLEGNO",
    sigla: "TO",
    codice_elettorale: 1010810880,
    codice_istat: 1090,
    codice_belfiore: "C860",
  },
  {
    id: 2202,
    comune: "COLLELONGO",
    sigla: "AQ",
    codice_elettorale: 3130380390,
    codice_istat: 66039,
    codice_belfiore: "C862",
  },
  {
    id: 2203,
    comune: "COLLEPARDO",
    sigla: "FR",
    codice_elettorale: 2120330280,
    codice_istat: 60028,
    codice_belfiore: "C864",
  },
  {
    id: 2204,
    comune: "COLLEPASSO",
    sigla: "LE",
    codice_elettorale: 3160410200,
    codice_istat: 75021,
    codice_belfiore: "C865",
  },
  {
    id: 2205,
    comune: "COLLEPIETRO",
    sigla: "AQ",
    codice_elettorale: 3130380400,
    codice_istat: 66040,
    codice_belfiore: "C866",
  },
  {
    id: 2206,
    comune: "COLLERETTO CASTELNUOVO",
    sigla: "TO",
    codice_elettorale: 1010810890,
    codice_istat: 1091,
    codice_belfiore: "C867",
  },
  {
    id: 2207,
    comune: "COLLERETTO GIACOSA",
    sigla: "TO",
    codice_elettorale: 1010810900,
    codice_istat: 1092,
    codice_belfiore: "C868",
  },
  {
    id: 2208,
    comune: "COLLESALVETTI",
    sigla: "LI",
    codice_elettorale: 2090420080,
    codice_istat: 49008,
    codice_belfiore: "C869",
  },
  {
    id: 2209,
    comune: "COLLESANO",
    sigla: "PA",
    codice_elettorale: 4190550300,
    codice_istat: 82032,
    codice_belfiore: "C871",
  },
  {
    id: 2210,
    comune: "COLLETORTO",
    sigla: "CB",
    codice_elettorale: 3140190210,
    codice_istat: 70021,
    codice_belfiore: "C875",
  },
  {
    id: 2211,
    comune: "COLLEVECCHIO",
    sigla: "RI",
    codice_elettorale: 2120690200,
    codice_istat: 57021,
    codice_belfiore: "C876",
  },
  {
    id: 2212,
    comune: "COLLI A VOLTURNO",
    sigla: "IS",
    codice_elettorale: 3140940170,
    codice_istat: 94017,
    codice_belfiore: "C878",
  },
  {
    id: 2213,
    comune: "COLLI AL METAURO",
    sigla: "PU",
    codice_elettorale: 2110590125,
    codice_istat: 41069,
    codice_belfiore: "M380",
  },
  {
    id: 2214,
    comune: "COLLI DEL TRONTO",
    sigla: "AP",
    codice_elettorale: 2110060140,
    codice_istat: 44014,
    codice_belfiore: "C877",
  },
  {
    id: 2215,
    comune: "COLLI SUL VELINO",
    sigla: "RI",
    codice_elettorale: 2120690201,
    codice_istat: 57022,
    codice_belfiore: "C880",
  },
  {
    id: 2216,
    comune: "COLLI VERDI",
    sigla: "PV",
    codice_elettorale: 1030570485,
    codice_istat: 18193,
    codice_belfiore: "M419",
  },
  {
    id: 2217,
    comune: "COLLIANO",
    sigla: "SA",
    codice_elettorale: 3150720430,
    codice_istat: 65043,
    codice_belfiore: "C879",
  },
  {
    id: 2218,
    comune: "COLLINAS",
    sigla: "SU",
    codice_elettorale: 4201110120,
    codice_istat: 111012,
    codice_belfiore: "C882",
  },
  {
    id: 2219,
    comune: "COLLIO",
    sigla: "BS",
    codice_elettorale: 1030150530,
    codice_istat: 17058,
    codice_belfiore: "C883",
  },
  {
    id: 2220,
    comune: "COLLOBIANO",
    sigla: "VC",
    codice_elettorale: 1010880450,
    codice_istat: 2045,
    codice_belfiore: "C884",
  },
  {
    id: 2221,
    comune: "COLLOREDO DI MONTE ALBANO",
    sigla: "UD",
    codice_elettorale: 1060850280,
    codice_istat: 30028,
    codice_belfiore: "C885",
  },
  {
    id: 2222,
    comune: "COLMURANO",
    sigla: "MC",
    codice_elettorale: 2110440140,
    codice_istat: 43014,
    codice_belfiore: "C886",
  },
  {
    id: 2223,
    comune: "COLOBRARO",
    sigla: "MT",
    codice_elettorale: 3170470060,
    codice_istat: 77006,
    codice_belfiore: "C888",
  },
  {
    id: 2224,
    comune: "COLOGNA VENETA",
    sigla: "VR",
    codice_elettorale: 1050890270,
    codice_istat: 23027,
    codice_belfiore: "C890",
  },
  {
    id: 2225,
    comune: "COLOGNE",
    sigla: "BS",
    codice_elettorale: 1030150540,
    codice_istat: 17059,
    codice_belfiore: "C893",
  },
  {
    id: 2226,
    comune: "COLOGNO AL SERIO",
    sigla: "BG",
    codice_elettorale: 1030120760,
    codice_istat: 16079,
    codice_belfiore: "C894",
  },
  {
    id: 2227,
    comune: "COLOGNO MONZESE",
    sigla: "MI",
    codice_elettorale: 1030490810,
    codice_istat: 15081,
    codice_belfiore: "C895",
  },
  {
    id: 2228,
    comune: "COLOGNOLA AI COLLI",
    sigla: "VR",
    codice_elettorale: 1050890280,
    codice_istat: 23028,
    codice_belfiore: "C897",
  },
  {
    id: 2229,
    comune: "COLONNA",
    sigla: "RM",
    codice_elettorale: 2120700350,
    codice_istat: 58035,
    codice_belfiore: "C900",
  },
  {
    id: 2230,
    comune: "COLONNELLA",
    sigla: "TE",
    codice_elettorale: 3130790180,
    codice_istat: 67019,
    codice_belfiore: "C901",
  },
  {
    id: 2231,
    comune: "COLONNO",
    sigla: "CO",
    codice_elettorale: 1030240710,
    codice_istat: 13074,
    codice_belfiore: "C902",
  },
  {
    id: 2232,
    comune: "COLORINA",
    sigla: "SO",
    codice_elettorale: 1030770230,
    codice_istat: 14023,
    codice_belfiore: "C903",
  },
  {
    id: 2233,
    comune: "COLORNO",
    sigla: "PR",
    codice_elettorale: 1080560100,
    codice_istat: 34010,
    codice_belfiore: "C904",
  },
  {
    id: 2234,
    comune: "COLOSIMI",
    sigla: "CS",
    codice_elettorale: 3180250440,
    codice_istat: 78043,
    codice_belfiore: "C905",
  },
  {
    id: 2235,
    comune: "COLTURANO",
    sigla: "MI",
    codice_elettorale: 1030490820,
    codice_istat: 15082,
    codice_belfiore: "C908",
  },
  {
    id: 2236,
    comune: "COLVERDE",
    sigla: "CO",
    codice_elettorale: 1030240715,
    codice_istat: 13251,
    codice_belfiore: "M336",
  },
  {
    id: 2237,
    comune: "COLZATE",
    sigla: "BG",
    codice_elettorale: 1030120770,
    codice_istat: 16080,
    codice_belfiore: "C910",
  },
  {
    id: 2238,
    comune: "COMABBIO",
    sigla: "VA",
    codice_elettorale: 1030860460,
    codice_istat: 12054,
    codice_belfiore: "C911",
  },
  {
    id: 2239,
    comune: "COMACCHIO",
    sigla: "FE",
    codice_elettorale: 1080290060,
    codice_istat: 38006,
    codice_belfiore: "C912",
  },
  {
    id: 2240,
    comune: "COMANO",
    sigla: "MS",
    codice_elettorale: 2090460050,
    codice_istat: 45005,
    codice_belfiore: "C914",
  },
  {
    id: 2241,
    comune: "COMANO TERME",
    sigla: "TN",
    codice_elettorale: 1040830595,
    codice_istat: 22228,
    codice_belfiore: "M314",
  },
  {
    id: 2242,
    comune: "COMAZZO",
    sigla: "LO",
    codice_elettorale: 1030990200,
    codice_istat: 98020,
    codice_belfiore: "C917",
  },
  {
    id: 2243,
    comune: "COMEGLIANS",
    sigla: "UD",
    codice_elettorale: 1060850290,
    codice_istat: 30029,
    codice_belfiore: "C918",
  },
  {
    id: 2244,
    comune: "COMELICO SUPERIORE",
    sigla: "BL",
    codice_elettorale: 1050100150,
    codice_istat: 25015,
    codice_belfiore: "C920",
  },
  {
    id: 2245,
    comune: "COMERIO",
    sigla: "VA",
    codice_elettorale: 1030860470,
    codice_istat: 12055,
    codice_belfiore: "C922",
  },
  {
    id: 2246,
    comune: "COMEZZANO-CIZZAGO",
    sigla: "BS",
    codice_elettorale: 1030150550,
    codice_istat: 17060,
    codice_belfiore: "C925",
  },
  {
    id: 2247,
    comune: "COMIGNAGO",
    sigla: "NO",
    codice_elettorale: 1010520500,
    codice_istat: 3052,
    codice_belfiore: "C926",
  },
  {
    id: 2248,
    comune: "COMISO",
    sigla: "RG",
    codice_elettorale: 4190650030,
    codice_istat: 88003,
    codice_belfiore: "C927",
  },
  {
    id: 2249,
    comune: "COMITINI",
    sigla: "AG",
    codice_elettorale: 4190010160,
    codice_istat: 84016,
    codice_belfiore: "C928",
  },
  {
    id: 2250,
    comune: "COMIZIANO",
    sigla: "NA",
    codice_elettorale: 3150510290,
    codice_istat: 63029,
    codice_belfiore: "C929",
  },
  {
    id: 2251,
    comune: "COMMESSAGGIO",
    sigla: "MN",
    codice_elettorale: 1030450200,
    codice_istat: 20020,
    codice_belfiore: "C930",
  },
  {
    id: 2252,
    comune: "COMMEZZADURA",
    sigla: "TN",
    codice_elettorale: 1040830600,
    codice_istat: 22064,
    codice_belfiore: "C931",
  },
  {
    id: 2253,
    comune: "COMO",
    sigla: "CO",
    codice_elettorale: 1030240720,
    codice_istat: 13075,
    codice_belfiore: "C933",
  },
  {
    id: 2254,
    comune: "COMPIANO",
    sigla: "PR",
    codice_elettorale: 1080560110,
    codice_istat: 34011,
    codice_belfiore: "C934",
  },
  {
    id: 2255,
    comune: "COMUN NUOVO",
    sigla: "BG",
    codice_elettorale: 1030120780,
    codice_istat: 16081,
    codice_belfiore: "C937",
  },
  {
    id: 2256,
    comune: "COMUNANZA",
    sigla: "AP",
    codice_elettorale: 2110060150,
    codice_istat: 44015,
    codice_belfiore: "C935",
  },
  {
    id: 2257,
    comune: "CONA",
    sigla: "VE",
    codice_elettorale: 1050870100,
    codice_istat: 27010,
    codice_belfiore: "C938",
  },
  {
    id: 2258,
    comune: "CONCA CASALE",
    sigla: "IS",
    codice_elettorale: 3140940180,
    codice_istat: 94018,
    codice_belfiore: "C941",
  },
  {
    id: 2259,
    comune: "CONCA DEI MARINI",
    sigla: "SA",
    codice_elettorale: 3150720440,
    codice_istat: 65044,
    codice_belfiore: "C940",
  },
  {
    id: 2260,
    comune: "CONCA DELLA CAMPANIA",
    sigla: "CE",
    codice_elettorale: 3150200310,
    codice_istat: 61031,
    codice_belfiore: "C939",
  },
  {
    id: 2261,
    comune: "CONCAMARISE",
    sigla: "VR",
    codice_elettorale: 1050890290,
    codice_istat: 23029,
    codice_belfiore: "C943",
  },
  {
    id: 2262,
    comune: "CONCERVIANO",
    sigla: "RI",
    codice_elettorale: 2120690210,
    codice_istat: 57023,
    codice_belfiore: "C946",
  },
  {
    id: 2263,
    comune: "CONCESIO",
    sigla: "BS",
    codice_elettorale: 1030150560,
    codice_istat: 17061,
    codice_belfiore: "C948",
  },
  {
    id: 2264,
    comune: "CONCORDIA SAGITTARIA",
    sigla: "VE",
    codice_elettorale: 1050870110,
    codice_istat: 27011,
    codice_belfiore: "C950",
  },
  {
    id: 2265,
    comune: "CONCORDIA SULLA SECCHIA",
    sigla: "MO",
    codice_elettorale: 1080500100,
    codice_istat: 36010,
    codice_belfiore: "C951",
  },
  {
    id: 2266,
    comune: "CONCOREZZO",
    sigla: "MB",
    codice_elettorale: 1031040210,
    codice_istat: 108021,
    codice_belfiore: "C952",
  },
  {
    id: 2267,
    comune: "CONDOFURI",
    sigla: "RC",
    codice_elettorale: 3180670290,
    codice_istat: 80029,
    codice_belfiore: "C954",
  },
  {
    id: 2268,
    comune: "CONDOVE",
    sigla: "TO",
    codice_elettorale: 1010810910,
    codice_istat: 1093,
    codice_belfiore: "C955",
  },
  {
    id: 2269,
    comune: "CONDRO'",
    sigla: "ME",
    codice_elettorale: 4190480180,
    codice_istat: 83018,
    codice_belfiore: "C956",
  },
  {
    id: 2270,
    comune: "CONEGLIANO",
    sigla: "TV",
    codice_elettorale: 1050840210,
    codice_istat: 26021,
    codice_belfiore: "C957",
  },
  {
    id: 2271,
    comune: "CONFIENZA",
    sigla: "PV",
    codice_elettorale: 1030570490,
    codice_istat: 18052,
    codice_belfiore: "C958",
  },
  {
    id: 2272,
    comune: "CONFIGNI",
    sigla: "RI",
    codice_elettorale: 2120690220,
    codice_istat: 57024,
    codice_belfiore: "C959",
  },
  {
    id: 2273,
    comune: "CONFLENTI",
    sigla: "CZ",
    codice_elettorale: 3180220310,
    codice_istat: 79033,
    codice_belfiore: "C960",
  },
  {
    id: 2274,
    comune: "CONIOLO",
    sigla: "AL",
    codice_elettorale: 1010020580,
    codice_istat: 6060,
    codice_belfiore: "C962",
  },
  {
    id: 2275,
    comune: "CONSELICE",
    sigla: "RA",
    codice_elettorale: 1080660080,
    codice_istat: 39008,
    codice_belfiore: "C963",
  },
  {
    id: 2276,
    comune: "CONSELVE",
    sigla: "PD",
    codice_elettorale: 1050540340,
    codice_istat: 28034,
    codice_belfiore: "C964",
  },
  {
    id: 2277,
    comune: "CONTA'",
    sigla: "TN",
    codice_elettorale: 1040830621,
    codice_istat: 22242,
    codice_belfiore: "M356",
  },
  {
    id: 2278,
    comune: "CONTESSA ENTELLINA",
    sigla: "PA",
    codice_elettorale: 4190550310,
    codice_istat: 82033,
    codice_belfiore: "C968",
  },
  {
    id: 2279,
    comune: "CONTIGLIANO",
    sigla: "RI",
    codice_elettorale: 2120690230,
    codice_istat: 57025,
    codice_belfiore: "C969",
  },
  {
    id: 2280,
    comune: "CONTRADA",
    sigla: "AV",
    codice_elettorale: 3150080290,
    codice_istat: 64029,
    codice_belfiore: "C971",
  },
  {
    id: 2281,
    comune: "CONTROGUERRA",
    sigla: "TE",
    codice_elettorale: 3130790190,
    codice_istat: 67020,
    codice_belfiore: "C972",
  },
  {
    id: 2282,
    comune: "CONTRONE",
    sigla: "SA",
    codice_elettorale: 3150720450,
    codice_istat: 65045,
    codice_belfiore: "C973",
  },
  {
    id: 2283,
    comune: "CONTURSI TERME",
    sigla: "SA",
    codice_elettorale: 3150720460,
    codice_istat: 65046,
    codice_belfiore: "C974",
  },
  {
    id: 2284,
    comune: "CONVERSANO",
    sigla: "BA",
    codice_elettorale: 3160090190,
    codice_istat: 72019,
    codice_belfiore: "C975",
  },
  {
    id: 2285,
    comune: "CONZA DELLA CAMPANIA",
    sigla: "AV",
    codice_elettorale: 3150080300,
    codice_istat: 64030,
    codice_belfiore: "C976",
  },
  {
    id: 2286,
    comune: "CONZANO",
    sigla: "AL",
    codice_elettorale: 1010020590,
    codice_istat: 6061,
    codice_belfiore: "C977",
  },
  {
    id: 2287,
    comune: "COPERTINO",
    sigla: "LE",
    codice_elettorale: 3160410210,
    codice_istat: 75022,
    codice_belfiore: "C978",
  },
  {
    id: 2288,
    comune: "COPIANO",
    sigla: "PV",
    codice_elettorale: 1030570500,
    codice_istat: 18053,
    codice_belfiore: "C979",
  },
  {
    id: 2289,
    comune: "COPPARO",
    sigla: "FE",
    codice_elettorale: 1080290070,
    codice_istat: 38007,
    codice_belfiore: "C980",
  },
  {
    id: 2290,
    comune: "CORANA",
    sigla: "PV",
    codice_elettorale: 1030570510,
    codice_istat: 18054,
    codice_belfiore: "C982",
  },
  {
    id: 2291,
    comune: "CORATO",
    sigla: "BA",
    codice_elettorale: 3160090200,
    codice_istat: 72020,
    codice_belfiore: "C983",
  },
  {
    id: 2292,
    comune: "CORBARA",
    sigla: "SA",
    codice_elettorale: 3150720470,
    codice_istat: 65047,
    codice_belfiore: "C984",
  },
  {
    id: 2293,
    comune: "CORBETTA",
    sigla: "MI",
    codice_elettorale: 1030490850,
    codice_istat: 15085,
    codice_belfiore: "C986",
  },
  {
    id: 2294,
    comune: "CORBOLA",
    sigla: "RO",
    codice_elettorale: 1050710170,
    codice_istat: 29017,
    codice_belfiore: "C987",
  },
  {
    id: 2295,
    comune: "CORCHIANO",
    sigla: "VT",
    codice_elettorale: 2120910220,
    codice_istat: 56023,
    codice_belfiore: "C988",
  },
  {
    id: 2296,
    comune: "CORCIANO",
    sigla: "PG",
    codice_elettorale: 2100580150,
    codice_istat: 54015,
    codice_belfiore: "C990",
  },
  {
    id: 2297,
    comune: "CORDENONS",
    sigla: "PN",
    codice_elettorale: 1060930170,
    codice_istat: 93017,
    codice_belfiore: "C991",
  },
  {
    id: 2298,
    comune: "CORDIGNANO",
    sigla: "TV",
    codice_elettorale: 1050840220,
    codice_istat: 26022,
    codice_belfiore: "C992",
  },
  {
    id: 2299,
    comune: "CORDOVADO",
    sigla: "PN",
    codice_elettorale: 1060930180,
    codice_istat: 93018,
    codice_belfiore: "C993",
  },
  {
    id: 2300,
    comune: "COREGLIA ANTELMINELLI",
    sigla: "LU",
    codice_elettorale: 2090430110,
    codice_istat: 46011,
    codice_belfiore: "C996",
  },
  {
    id: 2301,
    comune: "COREGLIA LIGURE",
    sigla: "GE",
    codice_elettorale: 1070340190,
    codice_istat: 10019,
    codice_belfiore: "C995",
  },
  {
    id: 2302,
    comune: "CORENO AUSONIO",
    sigla: "FR",
    codice_elettorale: 2120330300,
    codice_istat: 60030,
    codice_belfiore: "C998",
  },
  {
    id: 2303,
    comune: "CORFINIO",
    sigla: "AQ",
    codice_elettorale: 3130380410,
    codice_istat: 66041,
    codice_belfiore: "C999",
  },
  {
    id: 2304,
    comune: "CORI",
    sigla: "LT",
    codice_elettorale: 2120400060,
    codice_istat: 59006,
    codice_belfiore: "D003",
  },
  {
    id: 2305,
    comune: "CORIANO",
    sigla: "RN",
    codice_elettorale: 1081010030,
    codice_istat: 99003,
    codice_belfiore: "D004",
  },
  {
    id: 2306,
    comune: "CORIGLIANO D'OTRANTO",
    sigla: "LE",
    codice_elettorale: 3160410220,
    codice_istat: 75023,
    codice_belfiore: "D006",
  },
  {
    id: 2307,
    comune: "CORIGLIANO-ROSSANO",
    sigla: "CS",
    codice_elettorale: 3180250455,
    codice_istat: 78157,
    codice_belfiore: "M403",
  },
  {
    id: 2308,
    comune: "CORINALDO",
    sigla: "AN",
    codice_elettorale: 2110030150,
    codice_istat: 42015,
    codice_belfiore: "D007",
  },
  {
    id: 2309,
    comune: "CORIO",
    sigla: "TO",
    codice_elettorale: 1010810920,
    codice_istat: 1094,
    codice_belfiore: "D008",
  },
  {
    id: 2310,
    comune: "CORLEONE",
    sigla: "PA",
    codice_elettorale: 4190550320,
    codice_istat: 82034,
    codice_belfiore: "D009",
  },
  {
    id: 2311,
    comune: "CORLETO MONFORTE",
    sigla: "SA",
    codice_elettorale: 3150720480,
    codice_istat: 65048,
    codice_belfiore: "D011",
  },
  {
    id: 2312,
    comune: "CORLETO PERTICARA",
    sigla: "PZ",
    codice_elettorale: 3170640290,
    codice_istat: 76029,
    codice_belfiore: "D010",
  },
  {
    id: 2313,
    comune: "CORMANO",
    sigla: "MI",
    codice_elettorale: 1030490860,
    codice_istat: 15086,
    codice_belfiore: "D013",
  },
  {
    id: 2314,
    comune: "CORMONS",
    sigla: "GO",
    codice_elettorale: 1060350020,
    codice_istat: 31002,
    codice_belfiore: "D014",
  },
  {
    id: 2315,
    comune: "CORNA IMAGNA",
    sigla: "BG",
    codice_elettorale: 1030120790,
    codice_istat: 16082,
    codice_belfiore: "D015",
  },
  {
    id: 2316,
    comune: "CORNALBA",
    sigla: "BG",
    codice_elettorale: 1030120791,
    codice_istat: 16249,
    codice_belfiore: "D016",
  },
  {
    id: 2317,
    comune: "CORNALE E BASTIDA",
    sigla: "PV",
    codice_elettorale: 1030570521,
    codice_istat: 18191,
    codice_belfiore: "M338",
  },
  {
    id: 2318,
    comune: "CORNAREDO",
    sigla: "MI",
    codice_elettorale: 1030490870,
    codice_istat: 15087,
    codice_belfiore: "D018",
  },
  {
    id: 2319,
    comune: "CORNATE D'ADDA",
    sigla: "MB",
    codice_elettorale: 1031040215,
    codice_istat: 108053,
    codice_belfiore: "D019",
  },
  {
    id: 2320,
    comune: "CORNEDO ALL'ISARCO/KARNEID",
    sigla: "BZ",
    codice_elettorale: 1040140200,
    codice_istat: 21023,
    codice_belfiore: "B799",
  },
  {
    id: 2321,
    comune: "CORNEDO VICENTINO",
    sigla: "VI",
    codice_elettorale: 1050900340,
    codice_istat: 24034,
    codice_belfiore: "D020",
  },
  {
    id: 2322,
    comune: "CORNEGLIANO LAUDENSE",
    sigla: "LO",
    codice_elettorale: 1030990210,
    codice_istat: 98021,
    codice_belfiore: "D021",
  },
  {
    id: 2323,
    comune: "CORNELIANO D'ALBA",
    sigla: "CN",
    codice_elettorale: 1010270720,
    codice_istat: 4072,
    codice_belfiore: "D022",
  },
  {
    id: 2324,
    comune: "CORNIGLIO",
    sigla: "PR",
    codice_elettorale: 1080560120,
    codice_istat: 34012,
    codice_belfiore: "D026",
  },
  {
    id: 2325,
    comune: "CORNO DI ROSAZZO",
    sigla: "UD",
    codice_elettorale: 1060850300,
    codice_istat: 30030,
    codice_belfiore: "D027",
  },
  {
    id: 2326,
    comune: "CORNO GIOVINE",
    sigla: "LO",
    codice_elettorale: 1030990220,
    codice_istat: 98022,
    codice_belfiore: "D028",
  },
  {
    id: 2327,
    comune: "CORNOVECCHIO",
    sigla: "LO",
    codice_elettorale: 1030990230,
    codice_istat: 98023,
    codice_belfiore: "D029",
  },
  {
    id: 2328,
    comune: "CORNUDA",
    sigla: "TV",
    codice_elettorale: 1050840230,
    codice_istat: 26023,
    codice_belfiore: "D030",
  },
  {
    id: 2329,
    comune: "CORREGGIO",
    sigla: "RE",
    codice_elettorale: 1080680200,
    codice_istat: 35020,
    codice_belfiore: "D037",
  },
  {
    id: 2330,
    comune: "CORREZZANA",
    sigla: "MB",
    codice_elettorale: 1031040220,
    codice_istat: 108022,
    codice_belfiore: "D038",
  },
  {
    id: 2331,
    comune: "CORREZZOLA",
    sigla: "PD",
    codice_elettorale: 1050540350,
    codice_istat: 28035,
    codice_belfiore: "D040",
  },
  {
    id: 2332,
    comune: "CORRIDO",
    sigla: "CO",
    codice_elettorale: 1030240740,
    codice_istat: 13077,
    codice_belfiore: "D041",
  },
  {
    id: 2333,
    comune: "CORRIDONIA",
    sigla: "MC",
    codice_elettorale: 2110440150,
    codice_istat: 43015,
    codice_belfiore: "D042",
  },
  {
    id: 2334,
    comune: "CORROPOLI",
    sigla: "TE",
    codice_elettorale: 3130790200,
    codice_istat: 67021,
    codice_belfiore: "D043",
  },
  {
    id: 2335,
    comune: "CORSANO",
    sigla: "LE",
    codice_elettorale: 3160410230,
    codice_istat: 75024,
    codice_belfiore: "D044",
  },
  {
    id: 2336,
    comune: "CORSICO",
    sigla: "MI",
    codice_elettorale: 1030490930,
    codice_istat: 15093,
    codice_belfiore: "D045",
  },
  {
    id: 2337,
    comune: "CORSIONE",
    sigla: "AT",
    codice_elettorale: 1010070440,
    codice_istat: 5044,
    codice_belfiore: "D046",
  },
  {
    id: 2338,
    comune: "CORTACCIA SULLA STRADA DEL VINO/KURTATSCH AN DER WEINSTRASSE",
    sigla: "BZ",
    codice_elettorale: 1040140210,
    codice_istat: 21024,
    codice_belfiore: "D048",
  },
  {
    id: 2339,
    comune: "CORTALE",
    sigla: "CZ",
    codice_elettorale: 3180220320,
    codice_istat: 79034,
    codice_belfiore: "D049",
  },
  {
    id: 2340,
    comune: "CORTANDONE",
    sigla: "AT",
    codice_elettorale: 1010070450,
    codice_istat: 5045,
    codice_belfiore: "D050",
  },
  {
    id: 2341,
    comune: "CORTANZE",
    sigla: "AT",
    codice_elettorale: 1010070460,
    codice_istat: 5046,
    codice_belfiore: "D051",
  },
  {
    id: 2342,
    comune: "CORTAZZONE",
    sigla: "AT",
    codice_elettorale: 1010070470,
    codice_istat: 5047,
    codice_belfiore: "D052",
  },
  {
    id: 2343,
    comune: "CORTE BRUGNATELLA",
    sigla: "PC",
    codice_elettorale: 1080610170,
    codice_istat: 33017,
    codice_belfiore: "D054",
  },
  {
    id: 2344,
    comune: "CORTE DE' CORTESI CON CIGNONE",
    sigla: "CR",
    codice_elettorale: 1030260310,
    codice_istat: 19032,
    codice_belfiore: "D056",
  },
  {
    id: 2345,
    comune: "CORTE DE' FRATI",
    sigla: "CR",
    codice_elettorale: 1030260320,
    codice_istat: 19033,
    codice_belfiore: "D057",
  },
  {
    id: 2346,
    comune: "CORTE FRANCA",
    sigla: "BS",
    codice_elettorale: 1030150570,
    codice_istat: 17062,
    codice_belfiore: "D058",
  },
  {
    id: 2347,
    comune: "CORTE PALASIO",
    sigla: "LO",
    codice_elettorale: 1030990240,
    codice_istat: 98024,
    codice_belfiore: "D068",
  },
  {
    id: 2348,
    comune: "CORTEMAGGIORE",
    sigla: "PC",
    codice_elettorale: 1080610180,
    codice_istat: 33018,
    codice_belfiore: "D061",
  },
  {
    id: 2349,
    comune: "CORTEMILIA",
    sigla: "CN",
    codice_elettorale: 1010270730,
    codice_istat: 4073,
    codice_belfiore: "D062",
  },
  {
    id: 2350,
    comune: "CORTENO GOLGI",
    sigla: "BS",
    codice_elettorale: 1030150580,
    codice_istat: 17063,
    codice_belfiore: "D064",
  },
  {
    id: 2351,
    comune: "CORTENOVA",
    sigla: "LC",
    codice_elettorale: 1030980250,
    codice_istat: 97025,
    codice_belfiore: "D065",
  },
  {
    id: 2352,
    comune: "CORTENUOVA",
    sigla: "BG",
    codice_elettorale: 1030120800,
    codice_istat: 16083,
    codice_belfiore: "D066",
  },
  {
    id: 2353,
    comune: "CORTEOLONA E GENZONE",
    sigla: "PV",
    codice_elettorale: 1030570531,
    codice_istat: 18192,
    codice_belfiore: "M372",
  },
  {
    id: 2354,
    comune: "CORTIGLIONE",
    sigla: "AT",
    codice_elettorale: 1010070480,
    codice_istat: 5048,
    codice_belfiore: "D072",
  },
  {
    id: 2355,
    comune: "CORTINA D'AMPEZZO",
    sigla: "BL",
    codice_elettorale: 1050100160,
    codice_istat: 25016,
    codice_belfiore: "A266",
  },
  {
    id: 2356,
    comune: "CORTINA SULLA STRADA DEL VINO/KURTINIG AN DER WEINSTRASSE",
    sigla: "BZ",
    codice_elettorale: 1040140220,
    codice_istat: 21025,
    codice_belfiore: "D075",
  },
  {
    id: 2357,
    comune: "CORTINO",
    sigla: "TE",
    codice_elettorale: 3130790210,
    codice_istat: 67022,
    codice_belfiore: "D076",
  },
  {
    id: 2358,
    comune: "CORTONA",
    sigla: "AR",
    codice_elettorale: 2090050170,
    codice_istat: 51017,
    codice_belfiore: "D077",
  },
  {
    id: 2359,
    comune: "CORVARA",
    sigla: "PE",
    codice_elettorale: 3130600160,
    codice_istat: 68016,
    codice_belfiore: "D078",
  },
  {
    id: 2360,
    comune: "CORVARA IN BADIA/CORVARA",
    sigla: "BZ",
    codice_elettorale: 1040140230,
    codice_istat: 21026,
    codice_belfiore: "D079",
  },
  {
    id: 2361,
    comune: "CORVINO SAN QUIRICO",
    sigla: "PV",
    codice_elettorale: 1030570540,
    codice_istat: 18057,
    codice_belfiore: "D081",
  },
  {
    id: 2362,
    comune: "CORZANO",
    sigla: "BS",
    codice_elettorale: 1030150590,
    codice_istat: 17064,
    codice_belfiore: "D082",
  },
  {
    id: 2363,
    comune: "COSEANO",
    sigla: "UD",
    codice_elettorale: 1060850310,
    codice_istat: 30031,
    codice_belfiore: "D085",
  },
  {
    id: 2364,
    comune: "COSENZA",
    sigla: "CS",
    codice_elettorale: 3180250460,
    codice_istat: 78045,
    codice_belfiore: "D086",
  },
  {
    id: 2365,
    comune: "COSIO D'ARROSCIA",
    sigla: "IM",
    codice_elettorale: 1070370220,
    codice_istat: 8023,
    codice_belfiore: "D087",
  },
  {
    id: 2366,
    comune: "COSIO VALTELLINO",
    sigla: "SO",
    codice_elettorale: 1030770240,
    codice_istat: 14024,
    codice_belfiore: "D088",
  },
  {
    id: 2367,
    comune: "COSOLETO",
    sigla: "RC",
    codice_elettorale: 3180670300,
    codice_istat: 80030,
    codice_belfiore: "D089",
  },
  {
    id: 2368,
    comune: "COSSANO BELBO",
    sigla: "CN",
    codice_elettorale: 1010270740,
    codice_istat: 4074,
    codice_belfiore: "D093",
  },
  {
    id: 2369,
    comune: "COSSANO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010810930,
    codice_istat: 1095,
    codice_belfiore: "D092",
  },
  {
    id: 2370,
    comune: "COSSATO",
    sigla: "BI",
    codice_elettorale: 1010960200,
    codice_istat: 96020,
    codice_belfiore: "D094",
  },
  {
    id: 2371,
    comune: "COSSERIA",
    sigla: "SV",
    codice_elettorale: 1070740260,
    codice_istat: 9026,
    codice_belfiore: "D095",
  },
  {
    id: 2372,
    comune: "COSSIGNANO",
    sigla: "AP",
    codice_elettorale: 2110060160,
    codice_istat: 44016,
    codice_belfiore: "D096",
  },
  {
    id: 2373,
    comune: "COSSOGNO",
    sigla: "VB",
    codice_elettorale: 1011020230,
    codice_istat: 103023,
    codice_belfiore: "D099",
  },
  {
    id: 2374,
    comune: "COSSOINE",
    sigla: "SS",
    codice_elettorale: 4200730270,
    codice_istat: 90027,
    codice_belfiore: "D100",
  },
  {
    id: 2375,
    comune: "COSSOMBRATO",
    sigla: "AT",
    codice_elettorale: 1010070490,
    codice_istat: 5049,
    codice_belfiore: "D101",
  },
  {
    id: 2376,
    comune: "COSTA DE' NOBILI",
    sigla: "PV",
    codice_elettorale: 1030570550,
    codice_istat: 18058,
    codice_belfiore: "D109",
  },
  {
    id: 2377,
    comune: "COSTA DI MEZZATE",
    sigla: "BG",
    codice_elettorale: 1030120810,
    codice_istat: 16084,
    codice_belfiore: "D110",
  },
  {
    id: 2378,
    comune: "COSTA DI ROVIGO",
    sigla: "RO",
    codice_elettorale: 1050710180,
    codice_istat: 29018,
    codice_belfiore: "D105",
  },
  {
    id: 2379,
    comune: "COSTA MASNAGA",
    sigla: "LC",
    codice_elettorale: 1030980260,
    codice_istat: 97026,
    codice_belfiore: "D112",
  },
  {
    id: 2380,
    comune: "COSTA SERINA",
    sigla: "BG",
    codice_elettorale: 1030120811,
    codice_istat: 16247,
    codice_belfiore: "D111",
  },
  {
    id: 2381,
    comune: "COSTA VALLE IMAGNA",
    sigla: "BG",
    codice_elettorale: 1030120820,
    codice_istat: 16085,
    codice_belfiore: "D103",
  },
  {
    id: 2382,
    comune: "COSTA VESCOVATO",
    sigla: "AL",
    codice_elettorale: 1010020600,
    codice_istat: 6062,
    codice_belfiore: "D102",
  },
  {
    id: 2383,
    comune: "COSTA VOLPINO",
    sigla: "BG",
    codice_elettorale: 1030120830,
    codice_istat: 16086,
    codice_belfiore: "D117",
  },
  {
    id: 2384,
    comune: "COSTABISSARA",
    sigla: "VI",
    codice_elettorale: 1050900350,
    codice_istat: 24035,
    codice_belfiore: "D107",
  },
  {
    id: 2385,
    comune: "COSTACCIARO",
    sigla: "PG",
    codice_elettorale: 2100580160,
    codice_istat: 54016,
    codice_belfiore: "D108",
  },
  {
    id: 2386,
    comune: "COSTANZANA",
    sigla: "VC",
    codice_elettorale: 1010880470,
    codice_istat: 2047,
    codice_belfiore: "D113",
  },
  {
    id: 2387,
    comune: "COSTARAINERA",
    sigla: "IM",
    codice_elettorale: 1070370221,
    codice_istat: 8024,
    codice_belfiore: "D114",
  },
  {
    id: 2388,
    comune: "COSTERMANO SUL GARDA",
    sigla: "VR",
    codice_elettorale: 1050890300,
    codice_istat: 23030,
    codice_belfiore: "D118",
  },
  {
    id: 2389,
    comune: "COSTIGLIOLE D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070500,
    codice_istat: 5050,
    codice_belfiore: "D119",
  },
  {
    id: 2390,
    comune: "COSTIGLIOLE SALUZZO",
    sigla: "CN",
    codice_elettorale: 1010270750,
    codice_istat: 4075,
    codice_belfiore: "D120",
  },
  {
    id: 2391,
    comune: "COTIGNOLA",
    sigla: "RA",
    codice_elettorale: 1080660090,
    codice_istat: 39009,
    codice_belfiore: "D121",
  },
  {
    id: 2392,
    comune: "COTRONEI",
    sigla: "KR",
    codice_elettorale: 3180970090,
    codice_istat: 101009,
    codice_belfiore: "D123",
  },
  {
    id: 2393,
    comune: "COTTANELLO",
    sigla: "RI",
    codice_elettorale: 2120690240,
    codice_istat: 57026,
    codice_belfiore: "D124",
  },
  {
    id: 2394,
    comune: "COURMAYEUR",
    sigla: "AO",
    codice_elettorale: 1020040220,
    codice_istat: 7022,
    codice_belfiore: "D012",
  },
  {
    id: 2395,
    comune: "COVO",
    sigla: "BG",
    codice_elettorale: 1030120840,
    codice_istat: 16087,
    codice_belfiore: "D126",
  },
  {
    id: 2396,
    comune: "COZZO",
    sigla: "PV",
    codice_elettorale: 1030570560,
    codice_istat: 18059,
    codice_belfiore: "D127",
  },
  {
    id: 2397,
    comune: "CRACO",
    sigla: "MT",
    codice_elettorale: 3170470070,
    codice_istat: 77007,
    codice_belfiore: "D128",
  },
  {
    id: 2398,
    comune: "CRANDOLA VALSASSINA",
    sigla: "LC",
    codice_elettorale: 1030980270,
    codice_istat: 97027,
    codice_belfiore: "D131",
  },
  {
    id: 2399,
    comune: "CRAVAGLIANA",
    sigla: "VC",
    codice_elettorale: 1010880480,
    codice_istat: 2048,
    codice_belfiore: "D132",
  },
  {
    id: 2400,
    comune: "CRAVANZANA",
    sigla: "CN",
    codice_elettorale: 1010270760,
    codice_istat: 4076,
    codice_belfiore: "D133",
  },
  {
    id: 2401,
    comune: "CRAVEGGIA",
    sigla: "VB",
    codice_elettorale: 1011020240,
    codice_istat: 103024,
    codice_belfiore: "D134",
  },
  {
    id: 2402,
    comune: "CREAZZO",
    sigla: "VI",
    codice_elettorale: 1050900360,
    codice_istat: 24036,
    codice_belfiore: "D136",
  },
  {
    id: 2403,
    comune: "CRECCHIO",
    sigla: "CH",
    codice_elettorale: 3130230270,
    codice_istat: 69027,
    codice_belfiore: "D137",
  },
  {
    id: 2404,
    comune: "CREDARO",
    sigla: "BG",
    codice_elettorale: 1030120850,
    codice_istat: 16088,
    codice_belfiore: "D139",
  },
  {
    id: 2405,
    comune: "CREDERA RUBBIANO",
    sigla: "CR",
    codice_elettorale: 1030260330,
    codice_istat: 19034,
    codice_belfiore: "D141",
  },
  {
    id: 2406,
    comune: "CREMA",
    sigla: "CR",
    codice_elettorale: 1030260340,
    codice_istat: 19035,
    codice_belfiore: "D142",
  },
  {
    id: 2407,
    comune: "CREMELLA",
    sigla: "LC",
    codice_elettorale: 1030980280,
    codice_istat: 97028,
    codice_belfiore: "D143",
  },
  {
    id: 2408,
    comune: "CREMENAGA",
    sigla: "VA",
    codice_elettorale: 1030860480,
    codice_istat: 12056,
    codice_belfiore: "D144",
  },
  {
    id: 2409,
    comune: "CREMENO",
    sigla: "LC",
    codice_elettorale: 1030980290,
    codice_istat: 97029,
    codice_belfiore: "D145",
  },
  {
    id: 2410,
    comune: "CREMIA",
    sigla: "CO",
    codice_elettorale: 1030240780,
    codice_istat: 13083,
    codice_belfiore: "D147",
  },
  {
    id: 2411,
    comune: "CREMOLINO",
    sigla: "AL",
    codice_elettorale: 1010020610,
    codice_istat: 6063,
    codice_belfiore: "D149",
  },
  {
    id: 2412,
    comune: "CREMONA",
    sigla: "CR",
    codice_elettorale: 1030260350,
    codice_istat: 19036,
    codice_belfiore: "D150",
  },
  {
    id: 2413,
    comune: "CREMOSANO",
    sigla: "CR",
    codice_elettorale: 1030260360,
    codice_istat: 19037,
    codice_belfiore: "D151",
  },
  {
    id: 2414,
    comune: "CRESCENTINO",
    sigla: "VC",
    codice_elettorale: 1010880490,
    codice_istat: 2049,
    codice_belfiore: "D154",
  },
  {
    id: 2415,
    comune: "CRESPADORO",
    sigla: "VI",
    codice_elettorale: 1050900370,
    codice_istat: 24037,
    codice_belfiore: "D156",
  },
  {
    id: 2416,
    comune: "CRESPIATICA",
    sigla: "LO",
    codice_elettorale: 1030990250,
    codice_istat: 98025,
    codice_belfiore: "D159",
  },
  {
    id: 2417,
    comune: "CRESPINA LORENZANA",
    sigla: "PI",
    codice_elettorale: 2090620131,
    codice_istat: 50041,
    codice_belfiore: "M328",
  },
  {
    id: 2418,
    comune: "CRESPINO",
    sigla: "RO",
    codice_elettorale: 1050710190,
    codice_istat: 29019,
    codice_belfiore: "D161",
  },
  {
    id: 2419,
    comune: "CRESSA",
    sigla: "NO",
    codice_elettorale: 1010520530,
    codice_istat: 3055,
    codice_belfiore: "D162",
  },
  {
    id: 2420,
    comune: "CREVACUORE",
    sigla: "BI",
    codice_elettorale: 1010960210,
    codice_istat: 96021,
    codice_belfiore: "D165",
  },
  {
    id: 2421,
    comune: "CREVALCORE",
    sigla: "BO",
    codice_elettorale: 1080130240,
    codice_istat: 37024,
    codice_belfiore: "D166",
  },
  {
    id: 2422,
    comune: "CREVOLADOSSOLA",
    sigla: "VB",
    codice_elettorale: 1011020250,
    codice_istat: 103025,
    codice_belfiore: "D168",
  },
  {
    id: 2423,
    comune: "CRISPANO",
    sigla: "NA",
    codice_elettorale: 3150510300,
    codice_istat: 63030,
    codice_belfiore: "D170",
  },
  {
    id: 2424,
    comune: "CRISPIANO",
    sigla: "TA",
    codice_elettorale: 3160780040,
    codice_istat: 73004,
    codice_belfiore: "D171",
  },
  {
    id: 2425,
    comune: "CRISSOLO",
    sigla: "CN",
    codice_elettorale: 1010270770,
    codice_istat: 4077,
    codice_belfiore: "D172",
  },
  {
    id: 2426,
    comune: "CROCEFIESCHI",
    sigla: "GE",
    codice_elettorale: 1070340200,
    codice_istat: 10020,
    codice_belfiore: "D175",
  },
  {
    id: 2427,
    comune: "CROCETTA DEL MONTELLO",
    sigla: "TV",
    codice_elettorale: 1050840250,
    codice_istat: 26025,
    codice_belfiore: "C670",
  },
  {
    id: 2428,
    comune: "CRODO",
    sigla: "VB",
    codice_elettorale: 1011020260,
    codice_istat: 103026,
    codice_belfiore: "D177",
  },
  {
    id: 2429,
    comune: "CROGNALETO",
    sigla: "TE",
    codice_elettorale: 3130790220,
    codice_istat: 67023,
    codice_belfiore: "D179",
  },
  {
    id: 2430,
    comune: "CROPALATI",
    sigla: "CS",
    codice_elettorale: 3180250470,
    codice_istat: 78046,
    codice_belfiore: "D180",
  },
  {
    id: 2431,
    comune: "CROPANI",
    sigla: "CZ",
    codice_elettorale: 3180220340,
    codice_istat: 79036,
    codice_belfiore: "D181",
  },
  {
    id: 2432,
    comune: "CROSIA",
    sigla: "CS",
    codice_elettorale: 3180250480,
    codice_istat: 78047,
    codice_belfiore: "D184",
  },
  {
    id: 2433,
    comune: "CROSIO DELLA VALLE",
    sigla: "VA",
    codice_elettorale: 1030860490,
    codice_istat: 12057,
    codice_belfiore: "D185",
  },
  {
    id: 2434,
    comune: "CROTONE",
    sigla: "KR",
    codice_elettorale: 3180970100,
    codice_istat: 101010,
    codice_belfiore: "D122",
  },
  {
    id: 2435,
    comune: "CROTTA D'ADDA",
    sigla: "CR",
    codice_elettorale: 1030260370,
    codice_istat: 19038,
    codice_belfiore: "D186",
  },
  {
    id: 2436,
    comune: "CROVA",
    sigla: "VC",
    codice_elettorale: 1010880520,
    codice_istat: 2052,
    codice_belfiore: "D187",
  },
  {
    id: 2437,
    comune: "CROVIANA",
    sigla: "TN",
    codice_elettorale: 1040830640,
    codice_istat: 22068,
    codice_belfiore: "D188",
  },
  {
    id: 2438,
    comune: "CRUCOLI",
    sigla: "KR",
    codice_elettorale: 3180970110,
    codice_istat: 101011,
    codice_belfiore: "D189",
  },
  {
    id: 2439,
    comune: "CUASSO AL MONTE",
    sigla: "VA",
    codice_elettorale: 1030860500,
    codice_istat: 12058,
    codice_belfiore: "D192",
  },
  {
    id: 2440,
    comune: "CUCCARO VETERE",
    sigla: "SA",
    codice_elettorale: 3150720490,
    codice_istat: 65049,
    codice_belfiore: "D195",
  },
  {
    id: 2441,
    comune: "CUCCIAGO",
    sigla: "CO",
    codice_elettorale: 1030240790,
    codice_istat: 13084,
    codice_belfiore: "D196",
  },
  {
    id: 2442,
    comune: "CUCEGLIO",
    sigla: "TO",
    codice_elettorale: 1010810940,
    codice_istat: 1096,
    codice_belfiore: "D197",
  },
  {
    id: 2443,
    comune: "CUGGIONO",
    sigla: "MI",
    codice_elettorale: 1030490960,
    codice_istat: 15096,
    codice_belfiore: "D198",
  },
  {
    id: 2444,
    comune: "CUGLIATE-FABIASCO",
    sigla: "VA",
    codice_elettorale: 1030860501,
    codice_istat: 12059,
    codice_belfiore: "D199",
  },
  {
    id: 2445,
    comune: "CUGLIERI",
    sigla: "OR",
    codice_elettorale: 4200950190,
    codice_istat: 95019,
    codice_belfiore: "D200",
  },
  {
    id: 2446,
    comune: "CUGNOLI",
    sigla: "PE",
    codice_elettorale: 3130600170,
    codice_istat: 68017,
    codice_belfiore: "D201",
  },
  {
    id: 2447,
    comune: "CUMIANA",
    sigla: "TO",
    codice_elettorale: 1010810950,
    codice_istat: 1097,
    codice_belfiore: "D202",
  },
  {
    id: 2448,
    comune: "CUMIGNANO SUL NAVIGLIO",
    sigla: "CR",
    codice_elettorale: 1030260380,
    codice_istat: 19039,
    codice_belfiore: "D203",
  },
  {
    id: 2449,
    comune: "CUNARDO",
    sigla: "VA",
    codice_elettorale: 1030860510,
    codice_istat: 12060,
    codice_belfiore: "D204",
  },
  {
    id: 2450,
    comune: "CUNEO",
    sigla: "CN",
    codice_elettorale: 1010270780,
    codice_istat: 4078,
    codice_belfiore: "D205",
  },
  {
    id: 2451,
    comune: "CUNICO",
    sigla: "AT",
    codice_elettorale: 1010070510,
    codice_istat: 5051,
    codice_belfiore: "D207",
  },
  {
    id: 2452,
    comune: "CUORGNE'",
    sigla: "TO",
    codice_elettorale: 1010810960,
    codice_istat: 1098,
    codice_belfiore: "D208",
  },
  {
    id: 2453,
    comune: "CUPELLO",
    sigla: "CH",
    codice_elettorale: 3130230280,
    codice_istat: 69028,
    codice_belfiore: "D209",
  },
  {
    id: 2454,
    comune: "CUPRA MARITTIMA",
    sigla: "AP",
    codice_elettorale: 2110060170,
    codice_istat: 44017,
    codice_belfiore: "D210",
  },
  {
    id: 2455,
    comune: "CUPRAMONTANA",
    sigla: "AN",
    codice_elettorale: 2110030160,
    codice_istat: 42016,
    codice_belfiore: "D211",
  },
  {
    id: 2456,
    comune: "CURA CARPIGNANO",
    sigla: "PV",
    codice_elettorale: 1030570570,
    codice_istat: 18060,
    codice_belfiore: "B824",
  },
  {
    id: 2457,
    comune: "CURCURIS",
    sigla: "OR",
    codice_elettorale: 4200950191,
    codice_istat: 95077,
    codice_belfiore: "D214",
  },
  {
    id: 2458,
    comune: "CUREGGIO",
    sigla: "NO",
    codice_elettorale: 1010520560,
    codice_istat: 3058,
    codice_belfiore: "D216",
  },
  {
    id: 2459,
    comune: "CURIGLIA CON MONTEVIASCO",
    sigla: "VA",
    codice_elettorale: 1030860520,
    codice_istat: 12061,
    codice_belfiore: "D217",
  },
  {
    id: 2460,
    comune: "CURINGA",
    sigla: "CZ",
    codice_elettorale: 3180220370,
    codice_istat: 79039,
    codice_belfiore: "D218",
  },
  {
    id: 2461,
    comune: "CURINO",
    sigla: "BI",
    codice_elettorale: 1010960230,
    codice_istat: 96023,
    codice_belfiore: "D219",
  },
  {
    id: 2462,
    comune: "CURNO",
    sigla: "BG",
    codice_elettorale: 1030120860,
    codice_istat: 16089,
    codice_belfiore: "D221",
  },
  {
    id: 2463,
    comune: "CURON VENOSTA/GRAUN IM VINSCHGAU",
    sigla: "BZ",
    codice_elettorale: 1040140240,
    codice_istat: 21027,
    codice_belfiore: "D222",
  },
  {
    id: 2464,
    comune: "CURSI",
    sigla: "LE",
    codice_elettorale: 3160410240,
    codice_istat: 75025,
    codice_belfiore: "D223",
  },
  {
    id: 2465,
    comune: "CURTAROLO",
    sigla: "PD",
    codice_elettorale: 1050540360,
    codice_istat: 28036,
    codice_belfiore: "D226",
  },
  {
    id: 2466,
    comune: "CURTATONE",
    sigla: "MN",
    codice_elettorale: 1030450210,
    codice_istat: 20021,
    codice_belfiore: "D227",
  },
  {
    id: 2467,
    comune: "CURTI",
    sigla: "CE",
    codice_elettorale: 3150200320,
    codice_istat: 61032,
    codice_belfiore: "D228",
  },
  {
    id: 2468,
    comune: "CUSAGO",
    sigla: "MI",
    codice_elettorale: 1030490970,
    codice_istat: 15097,
    codice_belfiore: "D229",
  },
  {
    id: 2469,
    comune: "CUSANO MILANINO",
    sigla: "MI",
    codice_elettorale: 1030490980,
    codice_istat: 15098,
    codice_belfiore: "D231",
  },
  {
    id: 2470,
    comune: "CUSANO MUTRI",
    sigla: "BN",
    codice_elettorale: 3150110260,
    codice_istat: 62026,
    codice_belfiore: "D230",
  },
  {
    id: 2471,
    comune: "CUSINO",
    sigla: "CO",
    codice_elettorale: 1030240800,
    codice_istat: 13085,
    codice_belfiore: "D232",
  },
  {
    id: 2472,
    comune: "CUSIO",
    sigla: "BG",
    codice_elettorale: 1030120870,
    codice_istat: 16090,
    codice_belfiore: "D233",
  },
  {
    id: 2473,
    comune: "CUSTONACI",
    sigla: "TP",
    codice_elettorale: 4190820080,
    codice_istat: 81007,
    codice_belfiore: "D234",
  },
  {
    id: 2474,
    comune: "CUTRO",
    sigla: "KR",
    codice_elettorale: 3180970120,
    codice_istat: 101012,
    codice_belfiore: "D236",
  },
  {
    id: 2475,
    comune: "CUTROFIANO",
    sigla: "LE",
    codice_elettorale: 3160410250,
    codice_istat: 75026,
    codice_belfiore: "D237",
  },
  {
    id: 2476,
    comune: "CUVEGLIO",
    sigla: "VA",
    codice_elettorale: 1030860521,
    codice_istat: 12062,
    codice_belfiore: "D238",
  },
  {
    id: 2477,
    comune: "CUVIO",
    sigla: "VA",
    codice_elettorale: 1030860530,
    codice_istat: 12063,
    codice_belfiore: "D239",
  },
  {
    id: 2478,
    comune: "DAIRAGO",
    sigla: "MI",
    codice_elettorale: 1030490981,
    codice_istat: 15099,
    codice_belfiore: "D244",
  },
  {
    id: 2479,
    comune: "DALMINE",
    sigla: "BG",
    codice_elettorale: 1030120880,
    codice_istat: 16091,
    codice_belfiore: "D245",
  },
  {
    id: 2480,
    comune: "DAMBEL",
    sigla: "TN",
    codice_elettorale: 1040830670,
    codice_istat: 22071,
    codice_belfiore: "D246",
  },
  {
    id: 2481,
    comune: "DANTA DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100170,
    codice_istat: 25017,
    codice_belfiore: "D247",
  },
  {
    id: 2482,
    comune: "DARFO BOARIO TERME",
    sigla: "BS",
    codice_elettorale: 1030150600,
    codice_istat: 17065,
    codice_belfiore: "D251",
  },
  {
    id: 2483,
    comune: "DASA'",
    sigla: "VV",
    codice_elettorale: 3181030070,
    codice_istat: 102007,
    codice_belfiore: "D253",
  },
  {
    id: 2484,
    comune: "DAVAGNA",
    sigla: "GE",
    codice_elettorale: 1070340210,
    codice_istat: 10021,
    codice_belfiore: "D255",
  },
  {
    id: 2485,
    comune: "DAVERIO",
    sigla: "VA",
    codice_elettorale: 1030860540,
    codice_istat: 12064,
    codice_belfiore: "D256",
  },
  {
    id: 2486,
    comune: "DAVOLI",
    sigla: "CZ",
    codice_elettorale: 3180220400,
    codice_istat: 79042,
    codice_belfiore: "D257",
  },
  {
    id: 2487,
    comune: "DAZIO",
    sigla: "SO",
    codice_elettorale: 1030770250,
    codice_istat: 14025,
    codice_belfiore: "D258",
  },
  {
    id: 2488,
    comune: "DECIMOMANNU",
    sigla: "CA",
    codice_elettorale: 4200170150,
    codice_istat: 92015,
    codice_belfiore: "D259",
  },
  {
    id: 2489,
    comune: "DECIMOPUTZU",
    sigla: "SU",
    codice_elettorale: 4201110130,
    codice_istat: 111013,
    codice_belfiore: "D260",
  },
  {
    id: 2490,
    comune: "DECOLLATURA",
    sigla: "CZ",
    codice_elettorale: 3180220410,
    codice_istat: 79043,
    codice_belfiore: "D261",
  },
  {
    id: 2491,
    comune: "DEGO",
    sigla: "SV",
    codice_elettorale: 1070740270,
    codice_istat: 9027,
    codice_belfiore: "D264",
  },
  {
    id: 2492,
    comune: "DEIVA MARINA",
    sigla: "SP",
    codice_elettorale: 1070390120,
    codice_istat: 11012,
    codice_belfiore: "D265",
  },
  {
    id: 2493,
    comune: "DELEBIO",
    sigla: "SO",
    codice_elettorale: 1030770260,
    codice_istat: 14026,
    codice_belfiore: "D266",
  },
  {
    id: 2494,
    comune: "DELIA",
    sigla: "CL",
    codice_elettorale: 4190180060,
    codice_istat: 85006,
    codice_belfiore: "D267",
  },
  {
    id: 2495,
    comune: "DELIANUOVA",
    sigla: "RC",
    codice_elettorale: 3180670310,
    codice_istat: 80031,
    codice_belfiore: "D268",
  },
  {
    id: 2496,
    comune: "DELICETO",
    sigla: "FG",
    codice_elettorale: 3160310210,
    codice_istat: 71022,
    codice_belfiore: "D269",
  },
  {
    id: 2497,
    comune: "DELLO",
    sigla: "BS",
    codice_elettorale: 1030150610,
    codice_istat: 17066,
    codice_belfiore: "D270",
  },
  {
    id: 2498,
    comune: "DEMONTE",
    sigla: "CN",
    codice_elettorale: 1010270790,
    codice_istat: 4079,
    codice_belfiore: "D271",
  },
  {
    id: 2499,
    comune: "DENICE",
    sigla: "AL",
    codice_elettorale: 1010020630,
    codice_istat: 6065,
    codice_belfiore: "D272",
  },
  {
    id: 2500,
    comune: "DENNO",
    sigla: "TN",
    codice_elettorale: 1040830700,
    codice_istat: 22074,
    codice_belfiore: "D273",
  },
  {
    id: 2501,
    comune: "DERNICE",
    sigla: "AL",
    codice_elettorale: 1010020640,
    codice_istat: 6066,
    codice_belfiore: "D277",
  },
  {
    id: 2502,
    comune: "DEROVERE",
    sigla: "CR",
    codice_elettorale: 1030260390,
    codice_istat: 19040,
    codice_belfiore: "D278",
  },
  {
    id: 2503,
    comune: "DERUTA",
    sigla: "PG",
    codice_elettorale: 2100580170,
    codice_istat: 54017,
    codice_belfiore: "D279",
  },
  {
    id: 2504,
    comune: "DERVIO",
    sigla: "LC",
    codice_elettorale: 1030980300,
    codice_istat: 97030,
    codice_belfiore: "D280",
  },
  {
    id: 2505,
    comune: "DESANA",
    sigla: "VC",
    codice_elettorale: 1010880540,
    codice_istat: 2054,
    codice_belfiore: "D281",
  },
  {
    id: 2506,
    comune: "DESENZANO DEL GARDA",
    sigla: "BS",
    codice_elettorale: 1030150620,
    codice_istat: 17067,
    codice_belfiore: "D284",
  },
  {
    id: 2507,
    comune: "DESIO",
    sigla: "MB",
    codice_elettorale: 1031040230,
    codice_istat: 108023,
    codice_belfiore: "D286",
  },
  {
    id: 2508,
    comune: "DESULO",
    sigla: "NU",
    codice_elettorale: 4200530150,
    codice_istat: 91016,
    codice_belfiore: "D287",
  },
  {
    id: 2509,
    comune: "DIAMANTE",
    sigla: "CS",
    codice_elettorale: 3180250490,
    codice_istat: 78048,
    codice_belfiore: "D289",
  },
  {
    id: 2510,
    comune: "DIANO ARENTINO",
    sigla: "IM",
    codice_elettorale: 1070370230,
    codice_istat: 8025,
    codice_belfiore: "D293",
  },
  {
    id: 2511,
    comune: "DIANO CASTELLO",
    sigla: "IM",
    codice_elettorale: 1070370240,
    codice_istat: 8026,
    codice_belfiore: "D296",
  },
  {
    id: 2512,
    comune: "DIANO D'ALBA",
    sigla: "CN",
    codice_elettorale: 1010270800,
    codice_istat: 4080,
    codice_belfiore: "D291",
  },
  {
    id: 2513,
    comune: "DIANO MARINA",
    sigla: "IM",
    codice_elettorale: 1070370250,
    codice_istat: 8027,
    codice_belfiore: "D297",
  },
  {
    id: 2514,
    comune: "DIANO SAN PIETRO",
    sigla: "IM",
    codice_elettorale: 1070370260,
    codice_istat: 8028,
    codice_belfiore: "D298",
  },
  {
    id: 2515,
    comune: "DICOMANO",
    sigla: "FI",
    codice_elettorale: 2090300130,
    codice_istat: 48013,
    codice_belfiore: "D299",
  },
  {
    id: 2516,
    comune: "DIGNANO",
    sigla: "UD",
    codice_elettorale: 1060850320,
    codice_istat: 30032,
    codice_belfiore: "D300",
  },
  {
    id: 2517,
    comune: "DIMARO FOLGARIDA",
    sigla: "TN",
    codice_elettorale: 1040830715,
    codice_istat: 22233,
    codice_belfiore: "M366",
  },
  {
    id: 2518,
    comune: "DINAMI",
    sigla: "VV",
    codice_elettorale: 3181030080,
    codice_istat: 102008,
    codice_belfiore: "D303",
  },
  {
    id: 2519,
    comune: "DIPIGNANO",
    sigla: "CS",
    codice_elettorale: 3180250500,
    codice_istat: 78049,
    codice_belfiore: "D304",
  },
  {
    id: 2520,
    comune: "DISO",
    sigla: "LE",
    codice_elettorale: 3160410260,
    codice_istat: 75027,
    codice_belfiore: "D305",
  },
  {
    id: 2521,
    comune: "DIVIGNANO",
    sigla: "NO",
    codice_elettorale: 1010520580,
    codice_istat: 3060,
    codice_belfiore: "D309",
  },
  {
    id: 2522,
    comune: "DIZZASCO",
    sigla: "CO",
    codice_elettorale: 1030240820,
    codice_istat: 13087,
    codice_belfiore: "D310",
  },
  {
    id: 2523,
    comune: "DOBBIACO/TOBLACH",
    sigla: "BZ",
    codice_elettorale: 1040140250,
    codice_istat: 21028,
    codice_belfiore: "D311",
  },
  {
    id: 2524,
    comune: "DOBERDO' DEL LAGO",
    sigla: "GO",
    codice_elettorale: 1060350030,
    codice_istat: 31003,
    codice_belfiore: "D312",
  },
  {
    id: 2525,
    comune: "DOGLIANI",
    sigla: "CN",
    codice_elettorale: 1010270810,
    codice_istat: 4081,
    codice_belfiore: "D314",
  },
  {
    id: 2526,
    comune: "DOGLIOLA",
    sigla: "CH",
    codice_elettorale: 3130230290,
    codice_istat: 69029,
    codice_belfiore: "D315",
  },
  {
    id: 2527,
    comune: "DOGNA",
    sigla: "UD",
    codice_elettorale: 1060850330,
    codice_istat: 30033,
    codice_belfiore: "D316",
  },
  {
    id: 2528,
    comune: "DOLCE'",
    sigla: "VR",
    codice_elettorale: 1050890310,
    codice_istat: 23031,
    codice_belfiore: "D317",
  },
  {
    id: 2529,
    comune: "DOLCEACQUA",
    sigla: "IM",
    codice_elettorale: 1070370270,
    codice_istat: 8029,
    codice_belfiore: "D318",
  },
  {
    id: 2530,
    comune: "DOLCEDO",
    sigla: "IM",
    codice_elettorale: 1070370280,
    codice_istat: 8030,
    codice_belfiore: "D319",
  },
  {
    id: 2531,
    comune: "DOLEGNA DEL COLLIO",
    sigla: "GO",
    codice_elettorale: 1060350040,
    codice_istat: 31004,
    codice_belfiore: "D321",
  },
  {
    id: 2532,
    comune: "DOLIANOVA",
    sigla: "SU",
    codice_elettorale: 4201110140,
    codice_istat: 111014,
    codice_belfiore: "D323",
  },
  {
    id: 2533,
    comune: "DOLO",
    sigla: "VE",
    codice_elettorale: 1050870120,
    codice_istat: 27012,
    codice_belfiore: "D325",
  },
  {
    id: 2534,
    comune: "DOLZAGO",
    sigla: "LC",
    codice_elettorale: 1030980310,
    codice_istat: 97031,
    codice_belfiore: "D327",
  },
  {
    id: 2535,
    comune: "DOMANICO",
    sigla: "CS",
    codice_elettorale: 3180250510,
    codice_istat: 78050,
    codice_belfiore: "D328",
  },
  {
    id: 2536,
    comune: "DOMASO",
    sigla: "CO",
    codice_elettorale: 1030240840,
    codice_istat: 13089,
    codice_belfiore: "D329",
  },
  {
    id: 2537,
    comune: "DOMEGGE DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100180,
    codice_istat: 25018,
    codice_belfiore: "D330",
  },
  {
    id: 2538,
    comune: "DOMICELLA",
    sigla: "AV",
    codice_elettorale: 3150080310,
    codice_istat: 64031,
    codice_belfiore: "D331",
  },
  {
    id: 2539,
    comune: "DOMODOSSOLA",
    sigla: "VB",
    codice_elettorale: 1011020280,
    codice_istat: 103028,
    codice_belfiore: "D332",
  },
  {
    id: 2540,
    comune: "DOMUS DE MARIA",
    sigla: "SU",
    codice_elettorale: 4201110150,
    codice_istat: 111015,
    codice_belfiore: "D333",
  },
  {
    id: 2541,
    comune: "DOMUSNOVAS",
    sigla: "SU",
    codice_elettorale: 4201110160,
    codice_istat: 111016,
    codice_belfiore: "D334",
  },
  {
    id: 2542,
    comune: "DONATO",
    sigla: "BI",
    codice_elettorale: 1010960240,
    codice_istat: 96024,
    codice_belfiore: "D339",
  },
  {
    id: 2543,
    comune: "DONGO",
    sigla: "CO",
    codice_elettorale: 1030240850,
    codice_istat: 13090,
    codice_belfiore: "D341",
  },
  {
    id: 2544,
    comune: "DONNAS",
    sigla: "AO",
    codice_elettorale: 1020040230,
    codice_istat: 7023,
    codice_belfiore: "D338",
  },
  {
    id: 2545,
    comune: "DONORI",
    sigla: "SU",
    codice_elettorale: 4201110170,
    codice_istat: 111017,
    codice_belfiore: "D344",
  },
  {
    id: 2546,
    comune: "DORGALI",
    sigla: "NU",
    codice_elettorale: 4200530160,
    codice_istat: 91017,
    codice_belfiore: "D345",
  },
  {
    id: 2547,
    comune: "DORIO",
    sigla: "LC",
    codice_elettorale: 1030980320,
    codice_istat: 97032,
    codice_belfiore: "D346",
  },
  {
    id: 2548,
    comune: "DORMELLETTO",
    sigla: "NO",
    codice_elettorale: 1010520600,
    codice_istat: 3062,
    codice_belfiore: "D347",
  },
  {
    id: 2549,
    comune: "DORNO",
    sigla: "PV",
    codice_elettorale: 1030570580,
    codice_istat: 18061,
    codice_belfiore: "D348",
  },
  {
    id: 2550,
    comune: "DORZANO",
    sigla: "BI",
    codice_elettorale: 1010960250,
    codice_istat: 96025,
    codice_belfiore: "D350",
  },
  {
    id: 2551,
    comune: "DOSOLO",
    sigla: "MN",
    codice_elettorale: 1030450220,
    codice_istat: 20022,
    codice_belfiore: "D351",
  },
  {
    id: 2552,
    comune: "DOSSENA",
    sigla: "BG",
    codice_elettorale: 1030120890,
    codice_istat: 16092,
    codice_belfiore: "D352",
  },
  {
    id: 2553,
    comune: "DOSSO DEL LIRO",
    sigla: "CO",
    codice_elettorale: 1030240870,
    codice_istat: 13092,
    codice_belfiore: "D355",
  },
  {
    id: 2554,
    comune: "DOUES",
    sigla: "AO",
    codice_elettorale: 1020040240,
    codice_istat: 7024,
    codice_belfiore: "D356",
  },
  {
    id: 2555,
    comune: "DOVADOLA",
    sigla: "FC",
    codice_elettorale: 1080320100,
    codice_istat: 40011,
    codice_belfiore: "D357",
  },
  {
    id: 2556,
    comune: "DOVERA",
    sigla: "CR",
    codice_elettorale: 1030260400,
    codice_istat: 19041,
    codice_belfiore: "D358",
  },
  {
    id: 2557,
    comune: "DOZZA",
    sigla: "BO",
    codice_elettorale: 1080130250,
    codice_istat: 37025,
    codice_belfiore: "D360",
  },
  {
    id: 2558,
    comune: "DRAGONI",
    sigla: "CE",
    codice_elettorale: 3150200330,
    codice_istat: 61033,
    codice_belfiore: "D361",
  },
  {
    id: 2559,
    comune: "DRAPIA",
    sigla: "VV",
    codice_elettorale: 3181030090,
    codice_istat: 102009,
    codice_belfiore: "D364",
  },
  {
    id: 2560,
    comune: "DRENA",
    sigla: "TN",
    codice_elettorale: 1040830730,
    codice_istat: 22078,
    codice_belfiore: "D365",
  },
  {
    id: 2561,
    comune: "DRENCHIA",
    sigla: "UD",
    codice_elettorale: 1060850340,
    codice_istat: 30034,
    codice_belfiore: "D366",
  },
  {
    id: 2562,
    comune: "DRESANO",
    sigla: "MI",
    codice_elettorale: 1030491000,
    codice_istat: 15101,
    codice_belfiore: "D367",
  },
  {
    id: 2563,
    comune: "DRO",
    sigla: "TN",
    codice_elettorale: 1040830740,
    codice_istat: 22079,
    codice_belfiore: "D371",
  },
  {
    id: 2564,
    comune: "DRONERO",
    sigla: "CN",
    codice_elettorale: 1010270820,
    codice_istat: 4082,
    codice_belfiore: "D372",
  },
  {
    id: 2565,
    comune: "DRUENTO",
    sigla: "TO",
    codice_elettorale: 1010810970,
    codice_istat: 1099,
    codice_belfiore: "D373",
  },
  {
    id: 2566,
    comune: "DRUOGNO",
    sigla: "VB",
    codice_elettorale: 1011020290,
    codice_istat: 103029,
    codice_belfiore: "D374",
  },
  {
    id: 2567,
    comune: "DUALCHI",
    sigla: "NU",
    codice_elettorale: 4200530170,
    codice_istat: 91018,
    codice_belfiore: "D376",
  },
  {
    id: 2568,
    comune: "DUBINO",
    sigla: "SO",
    codice_elettorale: 1030770270,
    codice_istat: 14027,
    codice_belfiore: "D377",
  },
  {
    id: 2569,
    comune: "DUE CARRARE",
    sigla: "PD",
    codice_elettorale: 1050540361,
    codice_istat: 28106,
    codice_belfiore: "M300",
  },
  {
    id: 2570,
    comune: "DUEVILLE",
    sigla: "VI",
    codice_elettorale: 1050900380,
    codice_istat: 24038,
    codice_belfiore: "D379",
  },
  {
    id: 2571,
    comune: "DUGENTA",
    sigla: "BN",
    codice_elettorale: 3150110261,
    codice_istat: 62027,
    codice_belfiore: "D380",
  },
  {
    id: 2572,
    comune: "DUINO AURISINA",
    sigla: "TS",
    codice_elettorale: 1060920010,
    codice_istat: 32001,
    codice_belfiore: "D383",
  },
  {
    id: 2573,
    comune: "DUMENZA",
    sigla: "VA",
    codice_elettorale: 1030860550,
    codice_istat: 12065,
    codice_belfiore: "D384",
  },
  {
    id: 2574,
    comune: "DUNO",
    sigla: "VA",
    codice_elettorale: 1030860551,
    codice_istat: 12066,
    codice_belfiore: "D385",
  },
  {
    id: 2575,
    comune: "DURAZZANO",
    sigla: "BN",
    codice_elettorale: 3150110270,
    codice_istat: 62028,
    codice_belfiore: "D386",
  },
  {
    id: 2576,
    comune: "DURONIA",
    sigla: "CB",
    codice_elettorale: 3140190220,
    codice_istat: 70022,
    codice_belfiore: "C772",
  },
  {
    id: 2577,
    comune: "DUSINO SAN MICHELE",
    sigla: "AT",
    codice_elettorale: 1010070520,
    codice_istat: 5052,
    codice_belfiore: "D388",
  },
  {
    id: 2578,
    comune: "EBOLI",
    sigla: "SA",
    codice_elettorale: 3150720500,
    codice_istat: 65050,
    codice_belfiore: "D390",
  },
  {
    id: 2579,
    comune: "EDOLO",
    sigla: "BS",
    codice_elettorale: 1030150630,
    codice_istat: 17068,
    codice_belfiore: "D391",
  },
  {
    id: 2580,
    comune: "EGNA/NEUMARKT",
    sigla: "BZ",
    codice_elettorale: 1040140260,
    codice_istat: 21029,
    codice_belfiore: "D392",
  },
  {
    id: 2581,
    comune: "ELICE",
    sigla: "PE",
    codice_elettorale: 3130600180,
    codice_istat: 68018,
    codice_belfiore: "D394",
  },
  {
    id: 2582,
    comune: "ELINI",
    sigla: "NU",
    codice_elettorale: 4200530171,
    codice_istat: 91019,
    codice_belfiore: "D395",
  },
  {
    id: 2583,
    comune: "ELLO",
    sigla: "LC",
    codice_elettorale: 1030980330,
    codice_istat: 97033,
    codice_belfiore: "D398",
  },
  {
    id: 2584,
    comune: "ELMAS",
    sigla: "CA",
    codice_elettorale: 4200170201,
    codice_istat: 92108,
    codice_belfiore: "D399",
  },
  {
    id: 2585,
    comune: "ELVA",
    sigla: "CN",
    codice_elettorale: 1010270830,
    codice_istat: 4083,
    codice_belfiore: "D401",
  },
  {
    id: 2586,
    comune: "EMARESE",
    sigla: "AO",
    codice_elettorale: 1020040250,
    codice_istat: 7025,
    codice_belfiore: "D402",
  },
  {
    id: 2587,
    comune: "EMPOLI",
    sigla: "FI",
    codice_elettorale: 2090300140,
    codice_istat: 48014,
    codice_belfiore: "D403",
  },
  {
    id: 2588,
    comune: "ENDINE GAIANO",
    sigla: "BG",
    codice_elettorale: 1030120900,
    codice_istat: 16093,
    codice_belfiore: "D406",
  },
  {
    id: 2589,
    comune: "ENEGO",
    sigla: "VI",
    codice_elettorale: 1050900390,
    codice_istat: 24039,
    codice_belfiore: "D407",
  },
  {
    id: 2590,
    comune: "ENEMONZO",
    sigla: "UD",
    codice_elettorale: 1060850350,
    codice_istat: 30035,
    codice_belfiore: "D408",
  },
  {
    id: 2591,
    comune: "ENNA",
    sigla: "EN",
    codice_elettorale: 4190280090,
    codice_istat: 86009,
    codice_belfiore: "C342",
  },
  {
    id: 2592,
    comune: "ENTRACQUE",
    sigla: "CN",
    codice_elettorale: 1010270840,
    codice_istat: 4084,
    codice_belfiore: "D410",
  },
  {
    id: 2593,
    comune: "ENTRATICO",
    sigla: "BG",
    codice_elettorale: 1030120910,
    codice_istat: 16094,
    codice_belfiore: "D411",
  },
  {
    id: 2594,
    comune: "ENVIE",
    sigla: "CN",
    codice_elettorale: 1010270850,
    codice_istat: 4085,
    codice_belfiore: "D412",
  },
  {
    id: 2595,
    comune: "EPISCOPIA",
    sigla: "PZ",
    codice_elettorale: 3170640300,
    codice_istat: 76030,
    codice_belfiore: "D414",
  },
  {
    id: 2596,
    comune: "ERACLEA",
    sigla: "VE",
    codice_elettorale: 1050870130,
    codice_istat: 27013,
    codice_belfiore: "D415",
  },
  {
    id: 2597,
    comune: "ERBA",
    sigla: "CO",
    codice_elettorale: 1030240890,
    codice_istat: 13095,
    codice_belfiore: "D416",
  },
  {
    id: 2598,
    comune: "ERBE'",
    sigla: "VR",
    codice_elettorale: 1050890320,
    codice_istat: 23032,
    codice_belfiore: "D419",
  },
  {
    id: 2599,
    comune: "ERBEZZO",
    sigla: "VR",
    codice_elettorale: 1050890330,
    codice_istat: 23033,
    codice_belfiore: "D420",
  },
  {
    id: 2600,
    comune: "ERBUSCO",
    sigla: "BS",
    codice_elettorale: 1030150640,
    codice_istat: 17069,
    codice_belfiore: "D421",
  },
  {
    id: 2601,
    comune: "ERCHIE",
    sigla: "BR",
    codice_elettorale: 3160160060,
    codice_istat: 74006,
    codice_belfiore: "D422",
  },
  {
    id: 2602,
    comune: "ERCOLANO",
    sigla: "NA",
    codice_elettorale: 3150510301,
    codice_istat: 63064,
    codice_belfiore: "H243",
  },
  {
    id: 2603,
    comune: "ERICE",
    sigla: "TP",
    codice_elettorale: 4190820090,
    codice_istat: 81008,
    codice_belfiore: "D423",
  },
  {
    id: 2604,
    comune: "ERLI",
    sigla: "SV",
    codice_elettorale: 1070740280,
    codice_istat: 9028,
    codice_belfiore: "D424",
  },
  {
    id: 2605,
    comune: "ERTO E CASSO",
    sigla: "PN",
    codice_elettorale: 1060930190,
    codice_istat: 93019,
    codice_belfiore: "D426",
  },
  {
    id: 2606,
    comune: "ERULA",
    sigla: "SS",
    codice_elettorale: 4200730271,
    codice_istat: 90088,
    codice_belfiore: "M292",
  },
  {
    id: 2607,
    comune: "ERVE",
    sigla: "LC",
    codice_elettorale: 1030980340,
    codice_istat: 97034,
    codice_belfiore: "D428",
  },
  {
    id: 2608,
    comune: "ESANATOGLIA",
    sigla: "MC",
    codice_elettorale: 2110440160,
    codice_istat: 43016,
    codice_belfiore: "D429",
  },
  {
    id: 2609,
    comune: "ESCALAPLANO",
    sigla: "SU",
    codice_elettorale: 4201110180,
    codice_istat: 111018,
    codice_belfiore: "D430",
  },
  {
    id: 2610,
    comune: "ESCOLCA",
    sigla: "SU",
    codice_elettorale: 4201110190,
    codice_istat: 111019,
    codice_belfiore: "D431",
  },
  {
    id: 2611,
    comune: "ESINE",
    sigla: "BS",
    codice_elettorale: 1030150650,
    codice_istat: 17070,
    codice_belfiore: "D434",
  },
  {
    id: 2612,
    comune: "ESINO LARIO",
    sigla: "LC",
    codice_elettorale: 1030980350,
    codice_istat: 97035,
    codice_belfiore: "D436",
  },
  {
    id: 2613,
    comune: "ESPERIA",
    sigla: "FR",
    codice_elettorale: 2120330310,
    codice_istat: 60031,
    codice_belfiore: "D440",
  },
  {
    id: 2614,
    comune: "ESPORLATU",
    sigla: "SS",
    codice_elettorale: 4200730280,
    codice_istat: 90028,
    codice_belfiore: "D441",
  },
  {
    id: 2615,
    comune: "ESTE",
    sigla: "PD",
    codice_elettorale: 1050540370,
    codice_istat: 28037,
    codice_belfiore: "D442",
  },
  {
    id: 2616,
    comune: "ESTERZILI",
    sigla: "SU",
    codice_elettorale: 4201110200,
    codice_istat: 111020,
    codice_belfiore: "D443",
  },
  {
    id: 2617,
    comune: "ETROUBLES",
    sigla: "AO",
    codice_elettorale: 1020040260,
    codice_istat: 7026,
    codice_belfiore: "D444",
  },
  {
    id: 2618,
    comune: "EUPILIO",
    sigla: "CO",
    codice_elettorale: 1030240910,
    codice_istat: 13097,
    codice_belfiore: "D445",
  },
  {
    id: 2619,
    comune: "EXILLES",
    sigla: "TO",
    codice_elettorale: 1010810980,
    codice_istat: 1100,
    codice_belfiore: "D433",
  },
  {
    id: 2620,
    comune: "FABBRICA CURONE",
    sigla: "AL",
    codice_elettorale: 1010020650,
    codice_istat: 6067,
    codice_belfiore: "D447",
  },
  {
    id: 2621,
    comune: "FABBRICHE DI VERGEMOLI",
    sigla: "LU",
    codice_elettorale: 2090430125,
    codice_istat: 46036,
    codice_belfiore: "M319",
  },
  {
    id: 2622,
    comune: "FABBRICO",
    sigla: "RE",
    codice_elettorale: 1080680210,
    codice_istat: 35021,
    codice_belfiore: "D450",
  },
  {
    id: 2623,
    comune: "FABRIANO",
    sigla: "AN",
    codice_elettorale: 2110030170,
    codice_istat: 42017,
    codice_belfiore: "D451",
  },
  {
    id: 2624,
    comune: "FABRICA DI ROMA",
    sigla: "VT",
    codice_elettorale: 2120910230,
    codice_istat: 56024,
    codice_belfiore: "D452",
  },
  {
    id: 2625,
    comune: "FABRIZIA",
    sigla: "VV",
    codice_elettorale: 3181030100,
    codice_istat: 102010,
    codice_belfiore: "D453",
  },
  {
    id: 2626,
    comune: "FABRO",
    sigla: "TR",
    codice_elettorale: 2100800110,
    codice_istat: 55011,
    codice_belfiore: "D454",
  },
  {
    id: 2627,
    comune: "FAEDIS",
    sigla: "UD",
    codice_elettorale: 1060850360,
    codice_istat: 30036,
    codice_belfiore: "D455",
  },
  {
    id: 2628,
    comune: "FAEDO VALTELLINO",
    sigla: "SO",
    codice_elettorale: 1030770280,
    codice_istat: 14028,
    codice_belfiore: "D456",
  },
  {
    id: 2629,
    comune: "FAENZA",
    sigla: "RA",
    codice_elettorale: 1080660100,
    codice_istat: 39010,
    codice_belfiore: "D458",
  },
  {
    id: 2630,
    comune: "FAETO",
    sigla: "FG",
    codice_elettorale: 3160310220,
    codice_istat: 71023,
    codice_belfiore: "D459",
  },
  {
    id: 2631,
    comune: "FAGAGNA",
    sigla: "UD",
    codice_elettorale: 1060850370,
    codice_istat: 30037,
    codice_belfiore: "D461",
  },
  {
    id: 2632,
    comune: "FAGGETO LARIO",
    sigla: "CO",
    codice_elettorale: 1030240920,
    codice_istat: 13098,
    codice_belfiore: "D462",
  },
  {
    id: 2633,
    comune: "FAGGIANO",
    sigla: "TA",
    codice_elettorale: 3160780050,
    codice_istat: 73005,
    codice_belfiore: "D463",
  },
  {
    id: 2634,
    comune: "FAGNANO ALTO",
    sigla: "AQ",
    codice_elettorale: 3130380420,
    codice_istat: 66042,
    codice_belfiore: "D465",
  },
  {
    id: 2635,
    comune: "FAGNANO CASTELLO",
    sigla: "CS",
    codice_elettorale: 3180250520,
    codice_istat: 78051,
    codice_belfiore: "D464",
  },
  {
    id: 2636,
    comune: "FAGNANO OLONA",
    sigla: "VA",
    codice_elettorale: 1030860560,
    codice_istat: 12067,
    codice_belfiore: "D467",
  },
  {
    id: 2637,
    comune: "FAI DELLA PAGANELLA",
    sigla: "TN",
    codice_elettorale: 1040830760,
    codice_istat: 22081,
    codice_belfiore: "D468",
  },
  {
    id: 2638,
    comune: "FAICCHIO",
    sigla: "BN",
    codice_elettorale: 3150110280,
    codice_istat: 62029,
    codice_belfiore: "D469",
  },
  {
    id: 2639,
    comune: "FALCADE",
    sigla: "BL",
    codice_elettorale: 1050100190,
    codice_istat: 25019,
    codice_belfiore: "D470",
  },
  {
    id: 2640,
    comune: "FALCIANO DEL MASSICO",
    sigla: "CE",
    codice_elettorale: 3150200331,
    codice_istat: 61101,
    codice_belfiore: "D471",
  },
  {
    id: 2641,
    comune: "FALCONARA ALBANESE",
    sigla: "CS",
    codice_elettorale: 3180250530,
    codice_istat: 78052,
    codice_belfiore: "D473",
  },
  {
    id: 2642,
    comune: "FALCONARA MARITTIMA",
    sigla: "AN",
    codice_elettorale: 2110030180,
    codice_istat: 42018,
    codice_belfiore: "D472",
  },
  {
    id: 2643,
    comune: "FALCONE",
    sigla: "ME",
    codice_elettorale: 4190480190,
    codice_istat: 83019,
    codice_belfiore: "D474",
  },
  {
    id: 2644,
    comune: "FALERIA",
    sigla: "VT",
    codice_elettorale: 2120910240,
    codice_istat: 56025,
    codice_belfiore: "D475",
  },
  {
    id: 2645,
    comune: "FALERNA",
    sigla: "CZ",
    codice_elettorale: 3180220450,
    codice_istat: 79047,
    codice_belfiore: "D476",
  },
  {
    id: 2646,
    comune: "FALERONE",
    sigla: "FM",
    codice_elettorale: 2111050050,
    codice_istat: 109005,
    codice_belfiore: "D477",
  },
  {
    id: 2647,
    comune: "FALLO",
    sigla: "CH",
    codice_elettorale: 3130230291,
    codice_istat: 69104,
    codice_belfiore: "D480",
  },
  {
    id: 2648,
    comune: "FALOPPIO",
    sigla: "CO",
    codice_elettorale: 1030240930,
    codice_istat: 13099,
    codice_belfiore: "D482",
  },
  {
    id: 2649,
    comune: "FALVATERRA",
    sigla: "FR",
    codice_elettorale: 2120330320,
    codice_istat: 60032,
    codice_belfiore: "D483",
  },
  {
    id: 2650,
    comune: "FALZES/PFALZEN",
    sigla: "BZ",
    codice_elettorale: 1040140270,
    codice_istat: 21030,
    codice_belfiore: "D484",
  },
  {
    id: 2651,
    comune: "FANANO",
    sigla: "MO",
    codice_elettorale: 1080500110,
    codice_istat: 36011,
    codice_belfiore: "D486",
  },
  {
    id: 2652,
    comune: "FANNA",
    sigla: "PN",
    codice_elettorale: 1060930200,
    codice_istat: 93020,
    codice_belfiore: "D487",
  },
  {
    id: 2653,
    comune: "FANO",
    sigla: "PU",
    codice_elettorale: 2110590130,
    codice_istat: 41013,
    codice_belfiore: "D488",
  },
  {
    id: 2654,
    comune: "FANO ADRIANO",
    sigla: "TE",
    codice_elettorale: 3130790230,
    codice_istat: 67024,
    codice_belfiore: "D489",
  },
  {
    id: 2655,
    comune: "FARA FILIORUM PETRI",
    sigla: "CH",
    codice_elettorale: 3130230300,
    codice_istat: 69030,
    codice_belfiore: "D494",
  },
  {
    id: 2656,
    comune: "FARA GERA D'ADDA",
    sigla: "BG",
    codice_elettorale: 1030120930,
    codice_istat: 16096,
    codice_belfiore: "D490",
  },
  {
    id: 2657,
    comune: "FARA IN SABINA",
    sigla: "RI",
    codice_elettorale: 2120690250,
    codice_istat: 57027,
    codice_belfiore: "D493",
  },
  {
    id: 2658,
    comune: "FARA NOVARESE",
    sigla: "NO",
    codice_elettorale: 1010520630,
    codice_istat: 3065,
    codice_belfiore: "D492",
  },
  {
    id: 2659,
    comune: "FARA OLIVANA CON SOLA",
    sigla: "BG",
    codice_elettorale: 1030120940,
    codice_istat: 16097,
    codice_belfiore: "D491",
  },
  {
    id: 2660,
    comune: "FARA SAN MARTINO",
    sigla: "CH",
    codice_elettorale: 3130230310,
    codice_istat: 69031,
    codice_belfiore: "D495",
  },
  {
    id: 2661,
    comune: "FARA VICENTINO",
    sigla: "VI",
    codice_elettorale: 1050900400,
    codice_istat: 24040,
    codice_belfiore: "D496",
  },
  {
    id: 2662,
    comune: "FARDELLA",
    sigla: "PZ",
    codice_elettorale: 3170640310,
    codice_istat: 76031,
    codice_belfiore: "D497",
  },
  {
    id: 2663,
    comune: "FARIGLIANO",
    sigla: "CN",
    codice_elettorale: 1010270860,
    codice_istat: 4086,
    codice_belfiore: "D499",
  },
  {
    id: 2664,
    comune: "FARINDOLA",
    sigla: "PE",
    codice_elettorale: 3130600190,
    codice_istat: 68019,
    codice_belfiore: "D501",
  },
  {
    id: 2665,
    comune: "FARINI",
    sigla: "PC",
    codice_elettorale: 1080610190,
    codice_istat: 33019,
    codice_belfiore: "D502",
  },
  {
    id: 2666,
    comune: "FARNESE",
    sigla: "VT",
    codice_elettorale: 2120910250,
    codice_istat: 56026,
    codice_belfiore: "D503",
  },
  {
    id: 2667,
    comune: "FARRA D'ISONZO",
    sigla: "GO",
    codice_elettorale: 1060350050,
    codice_istat: 31005,
    codice_belfiore: "D504",
  },
  {
    id: 2668,
    comune: "FARRA DI SOLIGO",
    sigla: "TV",
    codice_elettorale: 1050840260,
    codice_istat: 26026,
    codice_belfiore: "D505",
  },
  {
    id: 2669,
    comune: "FASANO",
    sigla: "BR",
    codice_elettorale: 3160160070,
    codice_istat: 74007,
    codice_belfiore: "D508",
  },
  {
    id: 2670,
    comune: "FASCIA",
    sigla: "GE",
    codice_elettorale: 1070340220,
    codice_istat: 10022,
    codice_belfiore: "D509",
  },
  {
    id: 2671,
    comune: "FAUGLIA",
    sigla: "PI",
    codice_elettorale: 2090620140,
    codice_istat: 50014,
    codice_belfiore: "D510",
  },
  {
    id: 2672,
    comune: "FAULE",
    sigla: "CN",
    codice_elettorale: 1010270870,
    codice_istat: 4087,
    codice_belfiore: "D511",
  },
  {
    id: 2673,
    comune: "FAVALE DI MALVARO",
    sigla: "GE",
    codice_elettorale: 1070340230,
    codice_istat: 10023,
    codice_belfiore: "D512",
  },
  {
    id: 2674,
    comune: "FAVARA",
    sigla: "AG",
    codice_elettorale: 4190010170,
    codice_istat: 84017,
    codice_belfiore: "D514",
  },
  {
    id: 2675,
    comune: "FAVIGNANA",
    sigla: "TP",
    codice_elettorale: 4190820100,
    codice_istat: 81009,
    codice_belfiore: "D518",
  },
  {
    id: 2676,
    comune: "FAVRIA",
    sigla: "TO",
    codice_elettorale: 1010810990,
    codice_istat: 1101,
    codice_belfiore: "D520",
  },
  {
    id: 2677,
    comune: "FEISOGLIO",
    sigla: "CN",
    codice_elettorale: 1010270880,
    codice_istat: 4088,
    codice_belfiore: "D523",
  },
  {
    id: 2678,
    comune: "FELETTO",
    sigla: "TO",
    codice_elettorale: 1010811000,
    codice_istat: 1102,
    codice_belfiore: "D524",
  },
  {
    id: 2679,
    comune: "FELINO",
    sigla: "PR",
    codice_elettorale: 1080560130,
    codice_istat: 34013,
    codice_belfiore: "D526",
  },
  {
    id: 2680,
    comune: "FELITTO",
    sigla: "SA",
    codice_elettorale: 3150720510,
    codice_istat: 65051,
    codice_belfiore: "D527",
  },
  {
    id: 2681,
    comune: "FELIZZANO",
    sigla: "AL",
    codice_elettorale: 1010020660,
    codice_istat: 6068,
    codice_belfiore: "D528",
  },
  {
    id: 2682,
    comune: "FELTRE",
    sigla: "BL",
    codice_elettorale: 1050100210,
    codice_istat: 25021,
    codice_belfiore: "D530",
  },
  {
    id: 2683,
    comune: "FENEGRO'",
    sigla: "CO",
    codice_elettorale: 1030240940,
    codice_istat: 13100,
    codice_belfiore: "D531",
  },
  {
    id: 2684,
    comune: "FENESTRELLE",
    sigla: "TO",
    codice_elettorale: 1010811010,
    codice_istat: 1103,
    codice_belfiore: "D532",
  },
  {
    id: 2685,
    comune: "FERENTILLO",
    sigla: "TR",
    codice_elettorale: 2100800120,
    codice_istat: 55012,
    codice_belfiore: "D538",
  },
  {
    id: 2686,
    comune: "FERENTINO",
    sigla: "FR",
    codice_elettorale: 2120330330,
    codice_istat: 60033,
    codice_belfiore: "D539",
  },
  {
    id: 2687,
    comune: "FERLA",
    sigla: "SR",
    codice_elettorale: 4190760080,
    codice_istat: 89008,
    codice_belfiore: "D540",
  },
  {
    id: 2688,
    comune: "FERMIGNANO",
    sigla: "PU",
    codice_elettorale: 2110590140,
    codice_istat: 41014,
    codice_belfiore: "D541",
  },
  {
    id: 2689,
    comune: "FERMO",
    sigla: "FM",
    codice_elettorale: 2111050060,
    codice_istat: 109006,
    codice_belfiore: "D542",
  },
  {
    id: 2690,
    comune: "FERNO",
    sigla: "VA",
    codice_elettorale: 1030860570,
    codice_istat: 12068,
    codice_belfiore: "D543",
  },
  {
    id: 2691,
    comune: "FEROLETO ANTICO",
    sigla: "CZ",
    codice_elettorale: 3180220460,
    codice_istat: 79048,
    codice_belfiore: "D544",
  },
  {
    id: 2692,
    comune: "FEROLETO DELLA CHIESA",
    sigla: "RC",
    codice_elettorale: 3180670320,
    codice_istat: 80032,
    codice_belfiore: "D545",
  },
  {
    id: 2693,
    comune: "FERRANDINA",
    sigla: "MT",
    codice_elettorale: 3170470080,
    codice_istat: 77008,
    codice_belfiore: "D547",
  },
  {
    id: 2694,
    comune: "FERRARA",
    sigla: "FE",
    codice_elettorale: 1080290080,
    codice_istat: 38008,
    codice_belfiore: "D548",
  },
  {
    id: 2695,
    comune: "FERRARA DI MONTE BALDO",
    sigla: "VR",
    codice_elettorale: 1050890340,
    codice_istat: 23034,
    codice_belfiore: "D549",
  },
  {
    id: 2696,
    comune: "FERRAZZANO",
    sigla: "CB",
    codice_elettorale: 3140190230,
    codice_istat: 70023,
    codice_belfiore: "D550",
  },
  {
    id: 2697,
    comune: "FERRERA DI VARESE",
    sigla: "VA",
    codice_elettorale: 1030860580,
    codice_istat: 12069,
    codice_belfiore: "D551",
  },
  {
    id: 2698,
    comune: "FERRERA ERBOGNONE",
    sigla: "PV",
    codice_elettorale: 1030570590,
    codice_istat: 18062,
    codice_belfiore: "D552",
  },
  {
    id: 2699,
    comune: "FERRERE",
    sigla: "AT",
    codice_elettorale: 1010070530,
    codice_istat: 5053,
    codice_belfiore: "D554",
  },
  {
    id: 2700,
    comune: "FERRIERE",
    sigla: "PC",
    codice_elettorale: 1080610200,
    codice_istat: 33020,
    codice_belfiore: "D555",
  },
  {
    id: 2701,
    comune: "FERRUZZANO",
    sigla: "RC",
    codice_elettorale: 3180670330,
    codice_istat: 80033,
    codice_belfiore: "D557",
  },
  {
    id: 2702,
    comune: "FIAMIGNANO",
    sigla: "RI",
    codice_elettorale: 2120690260,
    codice_istat: 57028,
    codice_belfiore: "D560",
  },
  {
    id: 2703,
    comune: "FIANO",
    sigla: "TO",
    codice_elettorale: 1010811020,
    codice_istat: 1104,
    codice_belfiore: "D562",
  },
  {
    id: 2704,
    comune: "FIANO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700360,
    codice_istat: 58036,
    codice_belfiore: "D561",
  },
  {
    id: 2705,
    comune: "FIASTRA",
    sigla: "MC",
    codice_elettorale: 2110440170,
    codice_istat: 43017,
    codice_belfiore: "D564",
  },
  {
    id: 2706,
    comune: "FIAVE'",
    sigla: "TN",
    codice_elettorale: 1040830780,
    codice_istat: 22083,
    codice_belfiore: "D565",
  },
  {
    id: 2707,
    comune: "FICARAZZI",
    sigla: "PA",
    codice_elettorale: 4190550330,
    codice_istat: 82035,
    codice_belfiore: "D567",
  },
  {
    id: 2708,
    comune: "FICAROLO",
    sigla: "RO",
    codice_elettorale: 1050710210,
    codice_istat: 29021,
    codice_belfiore: "D568",
  },
  {
    id: 2709,
    comune: "FICARRA",
    sigla: "ME",
    codice_elettorale: 4190480200,
    codice_istat: 83020,
    codice_belfiore: "D569",
  },
  {
    id: 2710,
    comune: "FICULLE",
    sigla: "TR",
    codice_elettorale: 2100800130,
    codice_istat: 55013,
    codice_belfiore: "D570",
  },
  {
    id: 2711,
    comune: "FIDENZA",
    sigla: "PR",
    codice_elettorale: 1080560140,
    codice_istat: 34014,
    codice_belfiore: "B034",
  },
  {
    id: 2712,
    comune: "FIE' ALLO SCILIAR/VOLS AM SCHLERN",
    sigla: "BZ",
    codice_elettorale: 1040140280,
    codice_istat: 21031,
    codice_belfiore: "D571",
  },
  {
    id: 2713,
    comune: "FIEROZZO",
    sigla: "TN",
    codice_elettorale: 1040830800,
    codice_istat: 22085,
    codice_belfiore: "D573",
  },
  {
    id: 2714,
    comune: "FIESCO",
    sigla: "CR",
    codice_elettorale: 1030260420,
    codice_istat: 19043,
    codice_belfiore: "D574",
  },
  {
    id: 2715,
    comune: "FIESOLE",
    sigla: "FI",
    codice_elettorale: 2090300150,
    codice_istat: 48015,
    codice_belfiore: "D575",
  },
  {
    id: 2716,
    comune: "FIESSE",
    sigla: "BS",
    codice_elettorale: 1030150660,
    codice_istat: 17071,
    codice_belfiore: "D576",
  },
  {
    id: 2717,
    comune: "FIESSO D'ARTICO",
    sigla: "VE",
    codice_elettorale: 1050870140,
    codice_istat: 27014,
    codice_belfiore: "D578",
  },
  {
    id: 2718,
    comune: "FIESSO UMBERTIANO",
    sigla: "RO",
    codice_elettorale: 1050710220,
    codice_istat: 29022,
    codice_belfiore: "D577",
  },
  {
    id: 2719,
    comune: "FIGINO SERENZA",
    sigla: "CO",
    codice_elettorale: 1030240950,
    codice_istat: 13101,
    codice_belfiore: "D579",
  },
  {
    id: 2720,
    comune: "FIGLINE E INCISA VALDARNO",
    sigla: "FI",
    codice_elettorale: 2090300155,
    codice_istat: 48052,
    codice_belfiore: "M321",
  },
  {
    id: 2721,
    comune: "FIGLINE VEGLIATURO",
    sigla: "CS",
    codice_elettorale: 3180250540,
    codice_istat: 78053,
    codice_belfiore: "D582",
  },
  {
    id: 2722,
    comune: "FILACCIANO",
    sigla: "RM",
    codice_elettorale: 2120700370,
    codice_istat: 58037,
    codice_belfiore: "D586",
  },
  {
    id: 2723,
    comune: "FILADELFIA",
    sigla: "VV",
    codice_elettorale: 3181030110,
    codice_istat: 102011,
    codice_belfiore: "D587",
  },
  {
    id: 2724,
    comune: "FILAGO",
    sigla: "BG",
    codice_elettorale: 1030120941,
    codice_istat: 16098,
    codice_belfiore: "D588",
  },
  {
    id: 2725,
    comune: "FILANDARI",
    sigla: "VV",
    codice_elettorale: 3181030120,
    codice_istat: 102012,
    codice_belfiore: "D589",
  },
  {
    id: 2726,
    comune: "FILATTIERA",
    sigla: "MS",
    codice_elettorale: 2090460060,
    codice_istat: 45006,
    codice_belfiore: "D590",
  },
  {
    id: 2727,
    comune: "FILETTINO",
    sigla: "FR",
    codice_elettorale: 2120330340,
    codice_istat: 60034,
    codice_belfiore: "D591",
  },
  {
    id: 2728,
    comune: "FILETTO",
    sigla: "CH",
    codice_elettorale: 3130230320,
    codice_istat: 69032,
    codice_belfiore: "D592",
  },
  {
    id: 2729,
    comune: "FILIANO",
    sigla: "PZ",
    codice_elettorale: 3170640311,
    codice_istat: 76032,
    codice_belfiore: "D593",
  },
  {
    id: 2730,
    comune: "FILIGHERA",
    sigla: "PV",
    codice_elettorale: 1030570600,
    codice_istat: 18063,
    codice_belfiore: "D594",
  },
  {
    id: 2731,
    comune: "FILIGNANO",
    sigla: "IS",
    codice_elettorale: 3140940190,
    codice_istat: 94019,
    codice_belfiore: "D595",
  },
  {
    id: 2732,
    comune: "FILOGASO",
    sigla: "VV",
    codice_elettorale: 3181030130,
    codice_istat: 102013,
    codice_belfiore: "D596",
  },
  {
    id: 2733,
    comune: "FILOTTRANO",
    sigla: "AN",
    codice_elettorale: 2110030190,
    codice_istat: 42019,
    codice_belfiore: "D597",
  },
  {
    id: 2734,
    comune: "FINALE EMILIA",
    sigla: "MO",
    codice_elettorale: 1080500120,
    codice_istat: 36012,
    codice_belfiore: "D599",
  },
  {
    id: 2735,
    comune: "FINALE LIGURE",
    sigla: "SV",
    codice_elettorale: 1070740290,
    codice_istat: 9029,
    codice_belfiore: "D600",
  },
  {
    id: 2736,
    comune: "FINO DEL MONTE",
    sigla: "BG",
    codice_elettorale: 1030120950,
    codice_istat: 16099,
    codice_belfiore: "D604",
  },
  {
    id: 2737,
    comune: "FINO MORNASCO",
    sigla: "CO",
    codice_elettorale: 1030240960,
    codice_istat: 13102,
    codice_belfiore: "D605",
  },
  {
    id: 2738,
    comune: "FIORANO AL SERIO",
    sigla: "BG",
    codice_elettorale: 1030120960,
    codice_istat: 16100,
    codice_belfiore: "D606",
  },
  {
    id: 2739,
    comune: "FIORANO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010811030,
    codice_istat: 1105,
    codice_belfiore: "D608",
  },
  {
    id: 2740,
    comune: "FIORANO MODENESE",
    sigla: "MO",
    codice_elettorale: 1080500130,
    codice_istat: 36013,
    codice_belfiore: "D607",
  },
  {
    id: 2741,
    comune: "FIORENZUOLA D'ARDA",
    sigla: "PC",
    codice_elettorale: 1080610210,
    codice_istat: 33021,
    codice_belfiore: "D611",
  },
  {
    id: 2742,
    comune: "FIRENZE",
    sigla: "FI",
    codice_elettorale: 2090300170,
    codice_istat: 48017,
    codice_belfiore: "D612",
  },
  {
    id: 2743,
    comune: "FIRENZUOLA",
    sigla: "FI",
    codice_elettorale: 2090300180,
    codice_istat: 48018,
    codice_belfiore: "D613",
  },
  {
    id: 2744,
    comune: "FIRMO",
    sigla: "CS",
    codice_elettorale: 3180250550,
    codice_istat: 78054,
    codice_belfiore: "D614",
  },
  {
    id: 2745,
    comune: "FISCAGLIA",
    sigla: "FE",
    codice_elettorale: 1080290085,
    codice_istat: 38027,
    codice_belfiore: "M323",
  },
  {
    id: 2746,
    comune: "FISCIANO",
    sigla: "SA",
    codice_elettorale: 3150720520,
    codice_istat: 65052,
    codice_belfiore: "D615",
  },
  {
    id: 2747,
    comune: "FIUGGI",
    sigla: "FR",
    codice_elettorale: 2120330350,
    codice_istat: 60035,
    codice_belfiore: "A310",
  },
  {
    id: 2748,
    comune: "FIUMALBO",
    sigla: "MO",
    codice_elettorale: 1080500140,
    codice_istat: 36014,
    codice_belfiore: "D617",
  },
  {
    id: 2749,
    comune: "FIUMARA",
    sigla: "RC",
    codice_elettorale: 3180670340,
    codice_istat: 80034,
    codice_belfiore: "D619",
  },
  {
    id: 2750,
    comune: "FIUME VENETO",
    sigla: "PN",
    codice_elettorale: 1060930210,
    codice_istat: 93021,
    codice_belfiore: "D621",
  },
  {
    id: 2751,
    comune: "FIUMEDINISI",
    sigla: "ME",
    codice_elettorale: 4190480210,
    codice_istat: 83021,
    codice_belfiore: "D622",
  },
  {
    id: 2752,
    comune: "FIUMEFREDDO BRUZIO",
    sigla: "CS",
    codice_elettorale: 3180250560,
    codice_istat: 78055,
    codice_belfiore: "D624",
  },
  {
    id: 2753,
    comune: "FIUMEFREDDO DI SICILIA",
    sigla: "CT",
    codice_elettorale: 4190210160,
    codice_istat: 87016,
    codice_belfiore: "D623",
  },
  {
    id: 2754,
    comune: "FIUMICELLO VILLA VICENTINA",
    sigla: "UD",
    codice_elettorale: 1060850385,
    codice_istat: 30190,
    codice_belfiore: "M400",
  },
  {
    id: 2755,
    comune: "FIUMICINO",
    sigla: "RM",
    codice_elettorale: 2120700371,
    codice_istat: 58120,
    codice_belfiore: "M297",
  },
  {
    id: 2756,
    comune: "FIUMINATA",
    sigla: "MC",
    codice_elettorale: 2110440190,
    codice_istat: 43019,
    codice_belfiore: "D628",
  },
  {
    id: 2757,
    comune: "FIVIZZANO",
    sigla: "MS",
    codice_elettorale: 2090460070,
    codice_istat: 45007,
    codice_belfiore: "D629",
  },
  {
    id: 2758,
    comune: "FLAIBANO",
    sigla: "UD",
    codice_elettorale: 1060850390,
    codice_istat: 30039,
    codice_belfiore: "D630",
  },
  {
    id: 2759,
    comune: "FLERO",
    sigla: "BS",
    codice_elettorale: 1030150661,
    codice_istat: 17072,
    codice_belfiore: "D634",
  },
  {
    id: 2760,
    comune: "FLORESTA",
    sigla: "ME",
    codice_elettorale: 4190480220,
    codice_istat: 83022,
    codice_belfiore: "D635",
  },
  {
    id: 2761,
    comune: "FLORIDIA",
    sigla: "SR",
    codice_elettorale: 4190760090,
    codice_istat: 89009,
    codice_belfiore: "D636",
  },
  {
    id: 2762,
    comune: "FLORINAS",
    sigla: "SS",
    codice_elettorale: 4200730290,
    codice_istat: 90029,
    codice_belfiore: "D637",
  },
  {
    id: 2763,
    comune: "FLUMERI",
    sigla: "AV",
    codice_elettorale: 3150080320,
    codice_istat: 64032,
    codice_belfiore: "D638",
  },
  {
    id: 2764,
    comune: "FLUMINIMAGGIORE",
    sigla: "SU",
    codice_elettorale: 4201110210,
    codice_istat: 111021,
    codice_belfiore: "D639",
  },
  {
    id: 2765,
    comune: "FLUSSIO",
    sigla: "OR",
    codice_elettorale: 4200950196,
    codice_istat: 95080,
    codice_belfiore: "D640",
  },
  {
    id: 2766,
    comune: "FOBELLO",
    sigla: "VC",
    codice_elettorale: 1010880570,
    codice_istat: 2057,
    codice_belfiore: "D641",
  },
  {
    id: 2767,
    comune: "FOGGIA",
    sigla: "FG",
    codice_elettorale: 3160310230,
    codice_istat: 71024,
    codice_belfiore: "D643",
  },
  {
    id: 2768,
    comune: "FOGLIANISE",
    sigla: "BN",
    codice_elettorale: 3150110290,
    codice_istat: 62030,
    codice_belfiore: "D644",
  },
  {
    id: 2769,
    comune: "FOGLIANO REDIPUGLIA",
    sigla: "GO",
    codice_elettorale: 1060350060,
    codice_istat: 31006,
    codice_belfiore: "D645",
  },
  {
    id: 2770,
    comune: "FOGLIZZO",
    sigla: "TO",
    codice_elettorale: 1010811040,
    codice_istat: 1106,
    codice_belfiore: "D646",
  },
  {
    id: 2771,
    comune: "FOIANO DELLA CHIANA",
    sigla: "AR",
    codice_elettorale: 2090050180,
    codice_istat: 51018,
    codice_belfiore: "D649",
  },
  {
    id: 2772,
    comune: "FOIANO DI VAL FORTORE",
    sigla: "BN",
    codice_elettorale: 3150110300,
    codice_istat: 62031,
    codice_belfiore: "D650",
  },
  {
    id: 2773,
    comune: "FOLGARIA",
    sigla: "TN",
    codice_elettorale: 1040830820,
    codice_istat: 22087,
    codice_belfiore: "D651",
  },
  {
    id: 2774,
    comune: "FOLIGNANO",
    sigla: "AP",
    codice_elettorale: 2110060200,
    codice_istat: 44020,
    codice_belfiore: "D652",
  },
  {
    id: 2775,
    comune: "FOLIGNO",
    sigla: "PG",
    codice_elettorale: 2100580180,
    codice_istat: 54018,
    codice_belfiore: "D653",
  },
  {
    id: 2776,
    comune: "FOLLINA",
    sigla: "TV",
    codice_elettorale: 1050840270,
    codice_istat: 26027,
    codice_belfiore: "D654",
  },
  {
    id: 2777,
    comune: "FOLLO",
    sigla: "SP",
    codice_elettorale: 1070390130,
    codice_istat: 11013,
    codice_belfiore: "D655",
  },
  {
    id: 2778,
    comune: "FOLLONICA",
    sigla: "GR",
    codice_elettorale: 2090360080,
    codice_istat: 53009,
    codice_belfiore: "D656",
  },
  {
    id: 2779,
    comune: "FOMBIO",
    sigla: "LO",
    codice_elettorale: 1030990260,
    codice_istat: 98026,
    codice_belfiore: "D660",
  },
  {
    id: 2780,
    comune: "FONDACHELLI-FANTINA",
    sigla: "ME",
    codice_elettorale: 4190480230,
    codice_istat: 83023,
    codice_belfiore: "D661",
  },
  {
    id: 2781,
    comune: "FONDI",
    sigla: "LT",
    codice_elettorale: 2120400070,
    codice_istat: 59007,
    codice_belfiore: "D662",
  },
  {
    id: 2782,
    comune: "FONNI",
    sigla: "NU",
    codice_elettorale: 4200530220,
    codice_istat: 91024,
    codice_belfiore: "D665",
  },
  {
    id: 2783,
    comune: "FONTAINEMORE",
    sigla: "AO",
    codice_elettorale: 1020040280,
    codice_istat: 7028,
    codice_belfiore: "D666",
  },
  {
    id: 2784,
    comune: "FONTANA LIRI",
    sigla: "FR",
    codice_elettorale: 2120330360,
    codice_istat: 60036,
    codice_belfiore: "D667",
  },
  {
    id: 2785,
    comune: "FONTANAFREDDA",
    sigla: "PN",
    codice_elettorale: 1060930220,
    codice_istat: 93022,
    codice_belfiore: "D670",
  },
  {
    id: 2786,
    comune: "FONTANAROSA",
    sigla: "AV",
    codice_elettorale: 3150080330,
    codice_istat: 64033,
    codice_belfiore: "D671",
  },
  {
    id: 2787,
    comune: "FONTANELICE",
    sigla: "BO",
    codice_elettorale: 1080130260,
    codice_istat: 37026,
    codice_belfiore: "D668",
  },
  {
    id: 2788,
    comune: "FONTANELLA",
    sigla: "BG",
    codice_elettorale: 1030120970,
    codice_istat: 16101,
    codice_belfiore: "D672",
  },
  {
    id: 2789,
    comune: "FONTANELLATO",
    sigla: "PR",
    codice_elettorale: 1080560150,
    codice_istat: 34015,
    codice_belfiore: "D673",
  },
  {
    id: 2790,
    comune: "FONTANELLE",
    sigla: "TV",
    codice_elettorale: 1050840280,
    codice_istat: 26028,
    codice_belfiore: "D674",
  },
  {
    id: 2791,
    comune: "FONTANETO D'AGOGNA",
    sigla: "NO",
    codice_elettorale: 1010520640,
    codice_istat: 3066,
    codice_belfiore: "D675",
  },
  {
    id: 2792,
    comune: "FONTANETTO PO",
    sigla: "VC",
    codice_elettorale: 1010880580,
    codice_istat: 2058,
    codice_belfiore: "D676",
  },
  {
    id: 2793,
    comune: "FONTANIGORDA",
    sigla: "GE",
    codice_elettorale: 1070340240,
    codice_istat: 10024,
    codice_belfiore: "D677",
  },
  {
    id: 2794,
    comune: "FONTANILE",
    sigla: "AT",
    codice_elettorale: 1010070540,
    codice_istat: 5054,
    codice_belfiore: "D678",
  },
  {
    id: 2795,
    comune: "FONTANIVA",
    sigla: "PD",
    codice_elettorale: 1050540380,
    codice_istat: 28038,
    codice_belfiore: "D679",
  },
  {
    id: 2796,
    comune: "FONTE",
    sigla: "TV",
    codice_elettorale: 1050840290,
    codice_istat: 26029,
    codice_belfiore: "D680",
  },
  {
    id: 2797,
    comune: "FONTE NUOVA",
    sigla: "RM",
    codice_elettorale: 2120700375,
    codice_istat: 58122,
    codice_belfiore: "M309",
  },
  {
    id: 2798,
    comune: "FONTECCHIO",
    sigla: "AQ",
    codice_elettorale: 3130380430,
    codice_istat: 66043,
    codice_belfiore: "D681",
  },
  {
    id: 2799,
    comune: "FONTECHIARI",
    sigla: "FR",
    codice_elettorale: 2120330370,
    codice_istat: 60037,
    codice_belfiore: "D682",
  },
  {
    id: 2800,
    comune: "FONTEGRECA",
    sigla: "CE",
    codice_elettorale: 3150200340,
    codice_istat: 61034,
    codice_belfiore: "D683",
  },
  {
    id: 2801,
    comune: "FONTENO",
    sigla: "BG",
    codice_elettorale: 1030120980,
    codice_istat: 16102,
    codice_belfiore: "D684",
  },
  {
    id: 2802,
    comune: "FONTEVIVO",
    sigla: "PR",
    codice_elettorale: 1080560160,
    codice_istat: 34016,
    codice_belfiore: "D685",
  },
  {
    id: 2803,
    comune: "FONZASO",
    sigla: "BL",
    codice_elettorale: 1050100220,
    codice_istat: 25022,
    codice_belfiore: "D686",
  },
  {
    id: 2804,
    comune: "FOPPOLO",
    sigla: "BG",
    codice_elettorale: 1030120990,
    codice_istat: 16103,
    codice_belfiore: "D688",
  },
  {
    id: 2805,
    comune: "FORANO",
    sigla: "RI",
    codice_elettorale: 2120690270,
    codice_istat: 57029,
    codice_belfiore: "D689",
  },
  {
    id: 2806,
    comune: "FORCE",
    sigla: "AP",
    codice_elettorale: 2110060210,
    codice_istat: 44021,
    codice_belfiore: "D691",
  },
  {
    id: 2807,
    comune: "FORCHIA",
    sigla: "BN",
    codice_elettorale: 3150110310,
    codice_istat: 62032,
    codice_belfiore: "D693",
  },
  {
    id: 2808,
    comune: "FORCOLA",
    sigla: "SO",
    codice_elettorale: 1030770290,
    codice_istat: 14029,
    codice_belfiore: "D694",
  },
  {
    id: 2809,
    comune: "FORDONGIANUS",
    sigla: "OR",
    codice_elettorale: 4200950200,
    codice_istat: 95020,
    codice_belfiore: "D695",
  },
  {
    id: 2810,
    comune: "FORENZA",
    sigla: "PZ",
    codice_elettorale: 3170640320,
    codice_istat: 76033,
    codice_belfiore: "D696",
  },
  {
    id: 2811,
    comune: "FORESTO SPARSO",
    sigla: "BG",
    codice_elettorale: 1030121000,
    codice_istat: 16104,
    codice_belfiore: "D697",
  },
  {
    id: 2812,
    comune: "FORGARIA NEL FRIULI",
    sigla: "UD",
    codice_elettorale: 1060850391,
    codice_istat: 30137,
    codice_belfiore: "D700",
  },
  {
    id: 2813,
    comune: "FORINO",
    sigla: "AV",
    codice_elettorale: 3150080340,
    codice_istat: 64034,
    codice_belfiore: "D701",
  },
  {
    id: 2814,
    comune: "FORIO",
    sigla: "NA",
    codice_elettorale: 3150510310,
    codice_istat: 63031,
    codice_belfiore: "D702",
  },
  {
    id: 2815,
    comune: "FORLI'",
    sigla: "FC",
    codice_elettorale: 1080320110,
    codice_istat: 40012,
    codice_belfiore: "D704",
  },
  {
    id: 2816,
    comune: "FORLI' DEL SANNIO",
    sigla: "IS",
    codice_elettorale: 3140940200,
    codice_istat: 94020,
    codice_belfiore: "D703",
  },
  {
    id: 2817,
    comune: "FORLIMPOPOLI",
    sigla: "FC",
    codice_elettorale: 1080320120,
    codice_istat: 40013,
    codice_belfiore: "D705",
  },
  {
    id: 2818,
    comune: "FORMAZZA",
    sigla: "VB",
    codice_elettorale: 1011020310,
    codice_istat: 103031,
    codice_belfiore: "D706",
  },
  {
    id: 2819,
    comune: "FORMELLO",
    sigla: "RM",
    codice_elettorale: 2120700380,
    codice_istat: 58038,
    codice_belfiore: "D707",
  },
  {
    id: 2820,
    comune: "FORMIA",
    sigla: "LT",
    codice_elettorale: 2120400080,
    codice_istat: 59008,
    codice_belfiore: "D708",
  },
  {
    id: 2821,
    comune: "FORMICOLA",
    sigla: "CE",
    codice_elettorale: 3150200350,
    codice_istat: 61035,
    codice_belfiore: "D709",
  },
  {
    id: 2822,
    comune: "FORMIGARA",
    sigla: "CR",
    codice_elettorale: 1030260430,
    codice_istat: 19044,
    codice_belfiore: "D710",
  },
  {
    id: 2823,
    comune: "FORMIGINE",
    sigla: "MO",
    codice_elettorale: 1080500150,
    codice_istat: 36015,
    codice_belfiore: "D711",
  },
  {
    id: 2824,
    comune: "FORMIGLIANA",
    sigla: "VC",
    codice_elettorale: 1010880590,
    codice_istat: 2059,
    codice_belfiore: "D712",
  },
  {
    id: 2825,
    comune: "FORNACE",
    sigla: "TN",
    codice_elettorale: 1040830840,
    codice_istat: 22089,
    codice_belfiore: "D714",
  },
  {
    id: 2826,
    comune: "FORNELLI",
    sigla: "IS",
    codice_elettorale: 3140940210,
    codice_istat: 94021,
    codice_belfiore: "D715",
  },
  {
    id: 2827,
    comune: "FORNI AVOLTRI",
    sigla: "UD",
    codice_elettorale: 1060850400,
    codice_istat: 30040,
    codice_belfiore: "D718",
  },
  {
    id: 2828,
    comune: "FORNI DI SOPRA",
    sigla: "UD",
    codice_elettorale: 1060850410,
    codice_istat: 30041,
    codice_belfiore: "D719",
  },
  {
    id: 2829,
    comune: "FORNI DI SOTTO",
    sigla: "UD",
    codice_elettorale: 1060850420,
    codice_istat: 30042,
    codice_belfiore: "D720",
  },
  {
    id: 2830,
    comune: "FORNO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010811050,
    codice_istat: 1107,
    codice_belfiore: "D725",
  },
  {
    id: 2831,
    comune: "FORNOVO DI TARO",
    sigla: "PR",
    codice_elettorale: 1080560170,
    codice_istat: 34017,
    codice_belfiore: "D728",
  },
  {
    id: 2832,
    comune: "FORNOVO SAN GIOVANNI",
    sigla: "BG",
    codice_elettorale: 1030121010,
    codice_istat: 16105,
    codice_belfiore: "D727",
  },
  {
    id: 2833,
    comune: "FORTE DEI MARMI",
    sigla: "LU",
    codice_elettorale: 2090430130,
    codice_istat: 46013,
    codice_belfiore: "D730",
  },
  {
    id: 2834,
    comune: "FORTEZZA/FRANZENSFESTE",
    sigla: "BZ",
    codice_elettorale: 1040140290,
    codice_istat: 21032,
    codice_belfiore: "D731",
  },
  {
    id: 2835,
    comune: "FORTUNAGO",
    sigla: "PV",
    codice_elettorale: 1030570610,
    codice_istat: 18064,
    codice_belfiore: "D732",
  },
  {
    id: 2836,
    comune: "FORZA D'AGRO'",
    sigla: "ME",
    codice_elettorale: 4190480240,
    codice_istat: 83024,
    codice_belfiore: "D733",
  },
  {
    id: 2837,
    comune: "FOSCIANDORA",
    sigla: "LU",
    codice_elettorale: 2090430140,
    codice_istat: 46014,
    codice_belfiore: "D734",
  },
  {
    id: 2838,
    comune: "FOSDINOVO",
    sigla: "MS",
    codice_elettorale: 2090460080,
    codice_istat: 45008,
    codice_belfiore: "D735",
  },
  {
    id: 2839,
    comune: "FOSSA",
    sigla: "AQ",
    codice_elettorale: 3130380440,
    codice_istat: 66044,
    codice_belfiore: "D736",
  },
  {
    id: 2840,
    comune: "FOSSACESIA",
    sigla: "CH",
    codice_elettorale: 3130230330,
    codice_istat: 69033,
    codice_belfiore: "D738",
  },
  {
    id: 2841,
    comune: "FOSSALTA DI PIAVE",
    sigla: "VE",
    codice_elettorale: 1050870150,
    codice_istat: 27015,
    codice_belfiore: "D740",
  },
  {
    id: 2842,
    comune: "FOSSALTA DI PORTOGRUARO",
    sigla: "VE",
    codice_elettorale: 1050870160,
    codice_istat: 27016,
    codice_belfiore: "D741",
  },
  {
    id: 2843,
    comune: "FOSSALTO",
    sigla: "CB",
    codice_elettorale: 3140190240,
    codice_istat: 70024,
    codice_belfiore: "D737",
  },
  {
    id: 2844,
    comune: "FOSSANO",
    sigla: "CN",
    codice_elettorale: 1010270890,
    codice_istat: 4089,
    codice_belfiore: "D742",
  },
  {
    id: 2845,
    comune: "FOSSATO DI VICO",
    sigla: "PG",
    codice_elettorale: 2100580190,
    codice_istat: 54019,
    codice_belfiore: "D745",
  },
  {
    id: 2846,
    comune: "FOSSATO SERRALTA",
    sigla: "CZ",
    codice_elettorale: 3180220500,
    codice_istat: 79052,
    codice_belfiore: "D744",
  },
  {
    id: 2847,
    comune: "FOSSO'",
    sigla: "VE",
    codice_elettorale: 1050870170,
    codice_istat: 27017,
    codice_belfiore: "D748",
  },
  {
    id: 2848,
    comune: "FOSSOMBRONE",
    sigla: "PU",
    codice_elettorale: 2110590150,
    codice_istat: 41015,
    codice_belfiore: "D749",
  },
  {
    id: 2849,
    comune: "FOZA",
    sigla: "VI",
    codice_elettorale: 1050900410,
    codice_istat: 24041,
    codice_belfiore: "D750",
  },
  {
    id: 2850,
    comune: "FRABOSA SOPRANA",
    sigla: "CN",
    codice_elettorale: 1010270900,
    codice_istat: 4090,
    codice_belfiore: "D751",
  },
  {
    id: 2851,
    comune: "FRABOSA SOTTANA",
    sigla: "CN",
    codice_elettorale: 1010270910,
    codice_istat: 4091,
    codice_belfiore: "D752",
  },
  {
    id: 2852,
    comune: "FRACONALTO",
    sigla: "AL",
    codice_elettorale: 1010020670,
    codice_istat: 6069,
    codice_belfiore: "D559",
  },
  {
    id: 2853,
    comune: "FRAGAGNANO",
    sigla: "TA",
    codice_elettorale: 3160780060,
    codice_istat: 73006,
    codice_belfiore: "D754",
  },
  {
    id: 2854,
    comune: "FRAGNETO L'ABATE",
    sigla: "BN",
    codice_elettorale: 3150110320,
    codice_istat: 62033,
    codice_belfiore: "D755",
  },
  {
    id: 2855,
    comune: "FRAGNETO MONFORTE",
    sigla: "BN",
    codice_elettorale: 3150110330,
    codice_istat: 62034,
    codice_belfiore: "D756",
  },
  {
    id: 2856,
    comune: "FRAINE",
    sigla: "CH",
    codice_elettorale: 3130230340,
    codice_istat: 69034,
    codice_belfiore: "D757",
  },
  {
    id: 2857,
    comune: "FRAMURA",
    sigla: "SP",
    codice_elettorale: 1070390140,
    codice_istat: 11014,
    codice_belfiore: "D758",
  },
  {
    id: 2858,
    comune: "FRANCAVILLA AL MARE",
    sigla: "CH",
    codice_elettorale: 3130230350,
    codice_istat: 69035,
    codice_belfiore: "D763",
  },
  {
    id: 2859,
    comune: "FRANCAVILLA ANGITOLA",
    sigla: "VV",
    codice_elettorale: 3181030140,
    codice_istat: 102014,
    codice_belfiore: "D762",
  },
  {
    id: 2860,
    comune: "FRANCAVILLA BISIO",
    sigla: "AL",
    codice_elettorale: 1010020680,
    codice_istat: 6070,
    codice_belfiore: "D759",
  },
  {
    id: 2861,
    comune: "FRANCAVILLA D'ETE",
    sigla: "FM",
    codice_elettorale: 2111050070,
    codice_istat: 109007,
    codice_belfiore: "D760",
  },
  {
    id: 2862,
    comune: "FRANCAVILLA DI SICILIA",
    sigla: "ME",
    codice_elettorale: 4190480250,
    codice_istat: 83025,
    codice_belfiore: "D765",
  },
  {
    id: 2863,
    comune: "FRANCAVILLA FONTANA",
    sigla: "BR",
    codice_elettorale: 3160160080,
    codice_istat: 74008,
    codice_belfiore: "D761",
  },
  {
    id: 2864,
    comune: "FRANCAVILLA IN SINNI",
    sigla: "PZ",
    codice_elettorale: 3170640330,
    codice_istat: 76034,
    codice_belfiore: "D766",
  },
  {
    id: 2865,
    comune: "FRANCAVILLA MARITTIMA",
    sigla: "CS",
    codice_elettorale: 3180250570,
    codice_istat: 78056,
    codice_belfiore: "D764",
  },
  {
    id: 2866,
    comune: "FRANCICA",
    sigla: "VV",
    codice_elettorale: 3181030150,
    codice_istat: 102015,
    codice_belfiore: "D767",
  },
  {
    id: 2867,
    comune: "FRANCOFONTE",
    sigla: "SR",
    codice_elettorale: 4190760100,
    codice_istat: 89010,
    codice_belfiore: "D768",
  },
  {
    id: 2868,
    comune: "FRANCOLISE",
    sigla: "CE",
    codice_elettorale: 3150200360,
    codice_istat: 61036,
    codice_belfiore: "D769",
  },
  {
    id: 2869,
    comune: "FRASCARO",
    sigla: "AL",
    codice_elettorale: 1010020690,
    codice_istat: 6071,
    codice_belfiore: "D770",
  },
  {
    id: 2870,
    comune: "FRASCAROLO",
    sigla: "PV",
    codice_elettorale: 1030570620,
    codice_istat: 18065,
    codice_belfiore: "D771",
  },
  {
    id: 2871,
    comune: "FRASCATI",
    sigla: "RM",
    codice_elettorale: 2120700390,
    codice_istat: 58039,
    codice_belfiore: "D773",
  },
  {
    id: 2872,
    comune: "FRASCINETO",
    sigla: "CS",
    codice_elettorale: 3180250580,
    codice_istat: 78057,
    codice_belfiore: "D774",
  },
  {
    id: 2873,
    comune: "FRASSILONGO",
    sigla: "TN",
    codice_elettorale: 1040830850,
    codice_istat: 22090,
    codice_belfiore: "D775",
  },
  {
    id: 2874,
    comune: "FRASSINELLE POLESINE",
    sigla: "RO",
    codice_elettorale: 1050710230,
    codice_istat: 29023,
    codice_belfiore: "D776",
  },
  {
    id: 2875,
    comune: "FRASSINELLO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020700,
    codice_istat: 6072,
    codice_belfiore: "D777",
  },
  {
    id: 2876,
    comune: "FRASSINETO PO",
    sigla: "AL",
    codice_elettorale: 1010020710,
    codice_istat: 6073,
    codice_belfiore: "D780",
  },
  {
    id: 2877,
    comune: "FRASSINETTO",
    sigla: "TO",
    codice_elettorale: 1010811060,
    codice_istat: 1108,
    codice_belfiore: "D781",
  },
  {
    id: 2878,
    comune: "FRASSINO",
    sigla: "CN",
    codice_elettorale: 1010270920,
    codice_istat: 4092,
    codice_belfiore: "D782",
  },
  {
    id: 2879,
    comune: "FRASSINORO",
    sigla: "MO",
    codice_elettorale: 1080500160,
    codice_istat: 36016,
    codice_belfiore: "D783",
  },
  {
    id: 2880,
    comune: "FRASSO SABINO",
    sigla: "RI",
    codice_elettorale: 2120690280,
    codice_istat: 57030,
    codice_belfiore: "D785",
  },
  {
    id: 2881,
    comune: "FRASSO TELESINO",
    sigla: "BN",
    codice_elettorale: 3150110340,
    codice_istat: 62035,
    codice_belfiore: "D784",
  },
  {
    id: 2882,
    comune: "FRATTA POLESINE",
    sigla: "RO",
    codice_elettorale: 1050710240,
    codice_istat: 29024,
    codice_belfiore: "D788",
  },
  {
    id: 2883,
    comune: "FRATTA TODINA",
    sigla: "PG",
    codice_elettorale: 2100580200,
    codice_istat: 54020,
    codice_belfiore: "D787",
  },
  {
    id: 2884,
    comune: "FRATTAMAGGIORE",
    sigla: "NA",
    codice_elettorale: 3150510320,
    codice_istat: 63032,
    codice_belfiore: "D789",
  },
  {
    id: 2885,
    comune: "FRATTAMINORE",
    sigla: "NA",
    codice_elettorale: 3150510330,
    codice_istat: 63033,
    codice_belfiore: "D790",
  },
  {
    id: 2886,
    comune: "FRATTE ROSA",
    sigla: "PU",
    codice_elettorale: 2110590160,
    codice_istat: 41016,
    codice_belfiore: "D791",
  },
  {
    id: 2887,
    comune: "FRAZZANO'",
    sigla: "ME",
    codice_elettorale: 4190480260,
    codice_istat: 83026,
    codice_belfiore: "D793",
  },
  {
    id: 2888,
    comune: "FREGONA",
    sigla: "TV",
    codice_elettorale: 1050840300,
    codice_istat: 26030,
    codice_belfiore: "D794",
  },
  {
    id: 2889,
    comune: "FRESAGRANDINARIA",
    sigla: "CH",
    codice_elettorale: 3130230360,
    codice_istat: 69036,
    codice_belfiore: "D796",
  },
  {
    id: 2890,
    comune: "FRESONARA",
    sigla: "AL",
    codice_elettorale: 1010020720,
    codice_istat: 6074,
    codice_belfiore: "D797",
  },
  {
    id: 2891,
    comune: "FRIGENTO",
    sigla: "AV",
    codice_elettorale: 3150080350,
    codice_istat: 64035,
    codice_belfiore: "D798",
  },
  {
    id: 2892,
    comune: "FRIGNANO",
    sigla: "CE",
    codice_elettorale: 3150200370,
    codice_istat: 61037,
    codice_belfiore: "D799",
  },
  {
    id: 2893,
    comune: "FRINCO",
    sigla: "AT",
    codice_elettorale: 1010070550,
    codice_istat: 5055,
    codice_belfiore: "D802",
  },
  {
    id: 2894,
    comune: "FRISA",
    sigla: "CH",
    codice_elettorale: 3130230370,
    codice_istat: 69037,
    codice_belfiore: "D803",
  },
  {
    id: 2895,
    comune: "FRISANCO",
    sigla: "PN",
    codice_elettorale: 1060930240,
    codice_istat: 93024,
    codice_belfiore: "D804",
  },
  {
    id: 2896,
    comune: "FRONT",
    sigla: "TO",
    codice_elettorale: 1010811070,
    codice_istat: 1109,
    codice_belfiore: "D805",
  },
  {
    id: 2897,
    comune: "FRONTINO",
    sigla: "PU",
    codice_elettorale: 2110590170,
    codice_istat: 41017,
    codice_belfiore: "D807",
  },
  {
    id: 2898,
    comune: "FRONTONE",
    sigla: "PU",
    codice_elettorale: 2110590180,
    codice_istat: 41018,
    codice_belfiore: "D808",
  },
  {
    id: 2899,
    comune: "FROSINONE",
    sigla: "FR",
    codice_elettorale: 2120330380,
    codice_istat: 60038,
    codice_belfiore: "D810",
  },
  {
    id: 2900,
    comune: "FROSOLONE",
    sigla: "IS",
    codice_elettorale: 3140940220,
    codice_istat: 94022,
    codice_belfiore: "D811",
  },
  {
    id: 2901,
    comune: "FROSSASCO",
    sigla: "TO",
    codice_elettorale: 1010811080,
    codice_istat: 1110,
    codice_belfiore: "D812",
  },
  {
    id: 2902,
    comune: "FRUGAROLO",
    sigla: "AL",
    codice_elettorale: 1010020730,
    codice_istat: 6075,
    codice_belfiore: "D813",
  },
  {
    id: 2903,
    comune: "FUBINE MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020740,
    codice_istat: 6076,
    codice_belfiore: "D814",
  },
  {
    id: 2904,
    comune: "FUCECCHIO",
    sigla: "FI",
    codice_elettorale: 2090300190,
    codice_istat: 48019,
    codice_belfiore: "D815",
  },
  {
    id: 2905,
    comune: "FUIPIANO VALLE IMAGNA",
    sigla: "BG",
    codice_elettorale: 1030121020,
    codice_istat: 16106,
    codice_belfiore: "D817",
  },
  {
    id: 2906,
    comune: "FUMANE",
    sigla: "VR",
    codice_elettorale: 1050890350,
    codice_istat: 23035,
    codice_belfiore: "D818",
  },
  {
    id: 2907,
    comune: "FUMONE",
    sigla: "FR",
    codice_elettorale: 2120330390,
    codice_istat: 60039,
    codice_belfiore: "D819",
  },
  {
    id: 2908,
    comune: "FUNES/VILLNOSS",
    sigla: "BZ",
    codice_elettorale: 1040140300,
    codice_istat: 21033,
    codice_belfiore: "D821",
  },
  {
    id: 2909,
    comune: "FURCI",
    sigla: "CH",
    codice_elettorale: 3130230380,
    codice_istat: 69038,
    codice_belfiore: "D823",
  },
  {
    id: 2910,
    comune: "FURCI SICULO",
    sigla: "ME",
    codice_elettorale: 4190480270,
    codice_istat: 83027,
    codice_belfiore: "D824",
  },
  {
    id: 2911,
    comune: "FURNARI",
    sigla: "ME",
    codice_elettorale: 4190480280,
    codice_istat: 83028,
    codice_belfiore: "D825",
  },
  {
    id: 2912,
    comune: "FURORE",
    sigla: "SA",
    codice_elettorale: 3150720530,
    codice_istat: 65053,
    codice_belfiore: "D826",
  },
  {
    id: 2913,
    comune: "FURTEI",
    sigla: "SU",
    codice_elettorale: 4201110220,
    codice_istat: 111022,
    codice_belfiore: "D827",
  },
  {
    id: 2914,
    comune: "FUSCALDO",
    sigla: "CS",
    codice_elettorale: 3180250590,
    codice_istat: 78058,
    codice_belfiore: "D828",
  },
  {
    id: 2915,
    comune: "FUSIGNANO",
    sigla: "RA",
    codice_elettorale: 1080660110,
    codice_istat: 39011,
    codice_belfiore: "D829",
  },
  {
    id: 2916,
    comune: "FUSINE",
    sigla: "SO",
    codice_elettorale: 1030770300,
    codice_istat: 14030,
    codice_belfiore: "D830",
  },
  {
    id: 2917,
    comune: "FUTANI",
    sigla: "SA",
    codice_elettorale: 3150720540,
    codice_istat: 65054,
    codice_belfiore: "D832",
  },
  {
    id: 2918,
    comune: "FENIS",
    sigla: "AO",
    codice_elettorale: 1020040270,
    codice_istat: 7027,
    codice_belfiore: "D537",
  },
  {
    id: 2919,
    comune: "GABBIONETA BINANUOVA",
    sigla: "CR",
    codice_elettorale: 1030260440,
    codice_istat: 19045,
    codice_belfiore: "D834",
  },
  {
    id: 2920,
    comune: "GABIANO",
    sigla: "AL",
    codice_elettorale: 1010020750,
    codice_istat: 6077,
    codice_belfiore: "D835",
  },
  {
    id: 2921,
    comune: "GABICCE MARE",
    sigla: "PU",
    codice_elettorale: 2110590190,
    codice_istat: 41019,
    codice_belfiore: "D836",
  },
  {
    id: 2922,
    comune: "GABY",
    sigla: "AO",
    codice_elettorale: 1020040281,
    codice_istat: 7029,
    codice_belfiore: "D839",
  },
  {
    id: 2923,
    comune: "GADESCO PIEVE DELMONA",
    sigla: "CR",
    codice_elettorale: 1030260450,
    codice_istat: 19046,
    codice_belfiore: "D841",
  },
  {
    id: 2924,
    comune: "GADONI",
    sigla: "NU",
    codice_elettorale: 4200530230,
    codice_istat: 91025,
    codice_belfiore: "D842",
  },
  {
    id: 2925,
    comune: "GAETA",
    sigla: "LT",
    codice_elettorale: 2120400090,
    codice_istat: 59009,
    codice_belfiore: "D843",
  },
  {
    id: 2926,
    comune: "GAGGI",
    sigla: "ME",
    codice_elettorale: 4190480290,
    codice_istat: 83029,
    codice_belfiore: "D844",
  },
  {
    id: 2927,
    comune: "GAGGIANO",
    sigla: "MI",
    codice_elettorale: 1030491020,
    codice_istat: 15103,
    codice_belfiore: "D845",
  },
  {
    id: 2928,
    comune: "GAGGIO MONTANO",
    sigla: "BO",
    codice_elettorale: 1080130270,
    codice_istat: 37027,
    codice_belfiore: "D847",
  },
  {
    id: 2929,
    comune: "GAGLIANICO",
    sigla: "BI",
    codice_elettorale: 1010960260,
    codice_istat: 96026,
    codice_belfiore: "D848",
  },
  {
    id: 2930,
    comune: "GAGLIANO ATERNO",
    sigla: "AQ",
    codice_elettorale: 3130380450,
    codice_istat: 66045,
    codice_belfiore: "D850",
  },
  {
    id: 2931,
    comune: "GAGLIANO CASTELFERRATO",
    sigla: "EN",
    codice_elettorale: 4190280100,
    codice_istat: 86010,
    codice_belfiore: "D849",
  },
  {
    id: 2932,
    comune: "GAGLIANO DEL CAPO",
    sigla: "LE",
    codice_elettorale: 3160410270,
    codice_istat: 75028,
    codice_belfiore: "D851",
  },
  {
    id: 2933,
    comune: "GAGLIATO",
    sigla: "CZ",
    codice_elettorale: 3180220530,
    codice_istat: 79055,
    codice_belfiore: "D852",
  },
  {
    id: 2934,
    comune: "GAGLIOLE",
    sigla: "MC",
    codice_elettorale: 2110440200,
    codice_istat: 43020,
    codice_belfiore: "D853",
  },
  {
    id: 2935,
    comune: "GAIARINE",
    sigla: "TV",
    codice_elettorale: 1050840310,
    codice_istat: 26031,
    codice_belfiore: "D854",
  },
  {
    id: 2936,
    comune: "GAIBA",
    sigla: "RO",
    codice_elettorale: 1050710250,
    codice_istat: 29025,
    codice_belfiore: "D855",
  },
  {
    id: 2937,
    comune: "GAIOLA",
    sigla: "CN",
    codice_elettorale: 1010270930,
    codice_istat: 4093,
    codice_belfiore: "D856",
  },
  {
    id: 2938,
    comune: "GAIOLE IN CHIANTI",
    sigla: "SI",
    codice_elettorale: 2090750130,
    codice_istat: 52013,
    codice_belfiore: "D858",
  },
  {
    id: 2939,
    comune: "GAIRO",
    sigla: "NU",
    codice_elettorale: 4200530240,
    codice_istat: 91026,
    codice_belfiore: "D859",
  },
  {
    id: 2940,
    comune: "GAIS/GAIS",
    sigla: "BZ",
    codice_elettorale: 1040140310,
    codice_istat: 21034,
    codice_belfiore: "D860",
  },
  {
    id: 2941,
    comune: "GALATI MAMERTINO",
    sigla: "ME",
    codice_elettorale: 4190480300,
    codice_istat: 83030,
    codice_belfiore: "D861",
  },
  {
    id: 2942,
    comune: "GALATINA",
    sigla: "LE",
    codice_elettorale: 3160410280,
    codice_istat: 75029,
    codice_belfiore: "D862",
  },
  {
    id: 2943,
    comune: "GALATONE",
    sigla: "LE",
    codice_elettorale: 3160410290,
    codice_istat: 75030,
    codice_belfiore: "D863",
  },
  {
    id: 2944,
    comune: "GALATRO",
    sigla: "RC",
    codice_elettorale: 3180670350,
    codice_istat: 80035,
    codice_belfiore: "D864",
  },
  {
    id: 2945,
    comune: "GALBIATE",
    sigla: "LC",
    codice_elettorale: 1030980360,
    codice_istat: 97036,
    codice_belfiore: "D865",
  },
  {
    id: 2946,
    comune: "GALEATA",
    sigla: "FC",
    codice_elettorale: 1080320130,
    codice_istat: 40014,
    codice_belfiore: "D867",
  },
  {
    id: 2947,
    comune: "GALGAGNANO",
    sigla: "LO",
    codice_elettorale: 1030990270,
    codice_istat: 98027,
    codice_belfiore: "D868",
  },
  {
    id: 2948,
    comune: "GALLARATE",
    sigla: "VA",
    codice_elettorale: 1030860590,
    codice_istat: 12070,
    codice_belfiore: "D869",
  },
  {
    id: 2949,
    comune: "GALLESE",
    sigla: "VT",
    codice_elettorale: 2120910260,
    codice_istat: 56027,
    codice_belfiore: "D870",
  },
  {
    id: 2950,
    comune: "GALLIATE",
    sigla: "NO",
    codice_elettorale: 1010520660,
    codice_istat: 3068,
    codice_belfiore: "D872",
  },
  {
    id: 2951,
    comune: "GALLIATE LOMBARDO",
    sigla: "VA",
    codice_elettorale: 1030860600,
    codice_istat: 12071,
    codice_belfiore: "D871",
  },
  {
    id: 2952,
    comune: "GALLIAVOLA",
    sigla: "PV",
    codice_elettorale: 1030570630,
    codice_istat: 18066,
    codice_belfiore: "D873",
  },
  {
    id: 2953,
    comune: "GALLICANO",
    sigla: "LU",
    codice_elettorale: 2090430150,
    codice_istat: 46015,
    codice_belfiore: "D874",
  },
  {
    id: 2954,
    comune: "GALLICANO NEL LAZIO",
    sigla: "RM",
    codice_elettorale: 2120700400,
    codice_istat: 58040,
    codice_belfiore: "D875",
  },
  {
    id: 2955,
    comune: "GALLICCHIO",
    sigla: "PZ",
    codice_elettorale: 3170640340,
    codice_istat: 76035,
    codice_belfiore: "D876",
  },
  {
    id: 2956,
    comune: "GALLIERA",
    sigla: "BO",
    codice_elettorale: 1080130280,
    codice_istat: 37028,
    codice_belfiore: "D878",
  },
  {
    id: 2957,
    comune: "GALLIERA VENETA",
    sigla: "PD",
    codice_elettorale: 1050540390,
    codice_istat: 28039,
    codice_belfiore: "D879",
  },
  {
    id: 2958,
    comune: "GALLINARO",
    sigla: "FR",
    codice_elettorale: 2120330400,
    codice_istat: 60040,
    codice_belfiore: "D881",
  },
  {
    id: 2959,
    comune: "GALLIO",
    sigla: "VI",
    codice_elettorale: 1050900420,
    codice_istat: 24042,
    codice_belfiore: "D882",
  },
  {
    id: 2960,
    comune: "GALLIPOLI",
    sigla: "LE",
    codice_elettorale: 3160410300,
    codice_istat: 75031,
    codice_belfiore: "D883",
  },
  {
    id: 2961,
    comune: "GALLO MATESE",
    sigla: "CE",
    codice_elettorale: 3150200380,
    codice_istat: 61038,
    codice_belfiore: "D884",
  },
  {
    id: 2962,
    comune: "GALLODORO",
    sigla: "ME",
    codice_elettorale: 4190480301,
    codice_istat: 83031,
    codice_belfiore: "D885",
  },
  {
    id: 2963,
    comune: "GALLUCCIO",
    sigla: "CE",
    codice_elettorale: 3150200390,
    codice_istat: 61039,
    codice_belfiore: "D886",
  },
  {
    id: 2964,
    comune: "GALTELLI'",
    sigla: "NU",
    codice_elettorale: 4200530250,
    codice_istat: 91027,
    codice_belfiore: "D888",
  },
  {
    id: 2965,
    comune: "GALZIGNANO TERME",
    sigla: "PD",
    codice_elettorale: 1050540400,
    codice_istat: 28040,
    codice_belfiore: "D889",
  },
  {
    id: 2966,
    comune: "GAMALERO",
    sigla: "AL",
    codice_elettorale: 1010020760,
    codice_istat: 6078,
    codice_belfiore: "D890",
  },
  {
    id: 2967,
    comune: "GAMBARA",
    sigla: "BS",
    codice_elettorale: 1030150670,
    codice_istat: 17073,
    codice_belfiore: "D891",
  },
  {
    id: 2968,
    comune: "GAMBARANA",
    sigla: "PV",
    codice_elettorale: 1030570640,
    codice_istat: 18067,
    codice_belfiore: "D892",
  },
  {
    id: 2969,
    comune: "GAMBASCA",
    sigla: "CN",
    codice_elettorale: 1010270940,
    codice_istat: 4094,
    codice_belfiore: "D894",
  },
  {
    id: 2970,
    comune: "GAMBASSI TERME",
    sigla: "FI",
    codice_elettorale: 2090300200,
    codice_istat: 48020,
    codice_belfiore: "D895",
  },
  {
    id: 2971,
    comune: "GAMBATESA",
    sigla: "CB",
    codice_elettorale: 3140190250,
    codice_istat: 70025,
    codice_belfiore: "D896",
  },
  {
    id: 2972,
    comune: "GAMBELLARA",
    sigla: "VI",
    codice_elettorale: 1050900430,
    codice_istat: 24043,
    codice_belfiore: "D897",
  },
  {
    id: 2973,
    comune: "GAMBERALE",
    sigla: "CH",
    codice_elettorale: 3130230390,
    codice_istat: 69039,
    codice_belfiore: "D898",
  },
  {
    id: 2974,
    comune: "GAMBETTOLA",
    sigla: "FC",
    codice_elettorale: 1080320140,
    codice_istat: 40015,
    codice_belfiore: "D899",
  },
  {
    id: 2975,
    comune: "GAMBOLO'",
    sigla: "PV",
    codice_elettorale: 1030570650,
    codice_istat: 18068,
    codice_belfiore: "D901",
  },
  {
    id: 2976,
    comune: "GAMBUGLIANO",
    sigla: "VI",
    codice_elettorale: 1050900440,
    codice_istat: 24044,
    codice_belfiore: "D902",
  },
  {
    id: 2977,
    comune: "GANDELLINO",
    sigla: "BG",
    codice_elettorale: 1030121021,
    codice_istat: 16107,
    codice_belfiore: "D903",
  },
  {
    id: 2978,
    comune: "GANDINO",
    sigla: "BG",
    codice_elettorale: 1030121030,
    codice_istat: 16108,
    codice_belfiore: "D905",
  },
  {
    id: 2979,
    comune: "GANDOSSO",
    sigla: "BG",
    codice_elettorale: 1030121040,
    codice_istat: 16109,
    codice_belfiore: "D906",
  },
  {
    id: 2980,
    comune: "GANGI",
    sigla: "PA",
    codice_elettorale: 4190550340,
    codice_istat: 82036,
    codice_belfiore: "D907",
  },
  {
    id: 2981,
    comune: "GARAGUSO",
    sigla: "MT",
    codice_elettorale: 3170470090,
    codice_istat: 77009,
    codice_belfiore: "D909",
  },
  {
    id: 2982,
    comune: "GARBAGNA",
    sigla: "AL",
    codice_elettorale: 1010020770,
    codice_istat: 6079,
    codice_belfiore: "D910",
  },
  {
    id: 2983,
    comune: "GARBAGNA NOVARESE",
    sigla: "NO",
    codice_elettorale: 1010520670,
    codice_istat: 3069,
    codice_belfiore: "D911",
  },
  {
    id: 2984,
    comune: "GARBAGNATE MILANESE",
    sigla: "MI",
    codice_elettorale: 1030491040,
    codice_istat: 15105,
    codice_belfiore: "D912",
  },
  {
    id: 2985,
    comune: "GARBAGNATE MONASTERO",
    sigla: "LC",
    codice_elettorale: 1030980370,
    codice_istat: 97037,
    codice_belfiore: "D913",
  },
  {
    id: 2986,
    comune: "GARDA",
    sigla: "VR",
    codice_elettorale: 1050890360,
    codice_istat: 23036,
    codice_belfiore: "D915",
  },
  {
    id: 2987,
    comune: "GARDONE RIVIERA",
    sigla: "BS",
    codice_elettorale: 1030150680,
    codice_istat: 17074,
    codice_belfiore: "D917",
  },
  {
    id: 2988,
    comune: "GARDONE VAL TROMPIA",
    sigla: "BS",
    codice_elettorale: 1030150690,
    codice_istat: 17075,
    codice_belfiore: "D918",
  },
  {
    id: 2989,
    comune: "GARESSIO",
    sigla: "CN",
    codice_elettorale: 1010270950,
    codice_istat: 4095,
    codice_belfiore: "D920",
  },
  {
    id: 2990,
    comune: "GARGALLO",
    sigla: "NO",
    codice_elettorale: 1010520680,
    codice_istat: 3070,
    codice_belfiore: "D921",
  },
  {
    id: 2991,
    comune: "GARGAZZONE/GARGAZON",
    sigla: "BZ",
    codice_elettorale: 1040140320,
    codice_istat: 21035,
    codice_belfiore: "D923",
  },
  {
    id: 2992,
    comune: "GARGNANO",
    sigla: "BS",
    codice_elettorale: 1030150700,
    codice_istat: 17076,
    codice_belfiore: "D924",
  },
  {
    id: 2993,
    comune: "GARLASCO",
    sigla: "PV",
    codice_elettorale: 1030570660,
    codice_istat: 18069,
    codice_belfiore: "D925",
  },
  {
    id: 2994,
    comune: "GARLATE",
    sigla: "LC",
    codice_elettorale: 1030980380,
    codice_istat: 97038,
    codice_belfiore: "D926",
  },
  {
    id: 2995,
    comune: "GARLENDA",
    sigla: "SV",
    codice_elettorale: 1070740300,
    codice_istat: 9030,
    codice_belfiore: "D927",
  },
  {
    id: 2996,
    comune: "GARNIGA TERME",
    sigla: "TN",
    codice_elettorale: 1040830860,
    codice_istat: 22091,
    codice_belfiore: "D928",
  },
  {
    id: 2997,
    comune: "GARZENO",
    sigla: "CO",
    codice_elettorale: 1030241000,
    codice_istat: 13106,
    codice_belfiore: "D930",
  },
  {
    id: 2998,
    comune: "GARZIGLIANA",
    sigla: "TO",
    codice_elettorale: 1010811090,
    codice_istat: 1111,
    codice_belfiore: "D931",
  },
  {
    id: 2999,
    comune: "GASPERINA",
    sigla: "CZ",
    codice_elettorale: 3180220540,
    codice_istat: 79056,
    codice_belfiore: "D932",
  },
  {
    id: 3000,
    comune: "GASSINO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010811100,
    codice_istat: 1112,
    codice_belfiore: "D933",
  },
  {
    id: 3001,
    comune: "GATTATICO",
    sigla: "RE",
    codice_elettorale: 1080680220,
    codice_istat: 35022,
    codice_belfiore: "D934",
  },
  {
    id: 3002,
    comune: "GATTEO",
    sigla: "FC",
    codice_elettorale: 1080320150,
    codice_istat: 40016,
    codice_belfiore: "D935",
  },
  {
    id: 3003,
    comune: "GATTICO-VERUNO",
    sigla: "NO",
    codice_elettorale: 1010520695,
    codice_istat: 3166,
    codice_belfiore: "M416",
  },
  {
    id: 3004,
    comune: "GATTINARA",
    sigla: "VC",
    codice_elettorale: 1010880610,
    codice_istat: 2061,
    codice_belfiore: "D938",
  },
  {
    id: 3005,
    comune: "GAVARDO",
    sigla: "BS",
    codice_elettorale: 1030150710,
    codice_istat: 17077,
    codice_belfiore: "D940",
  },
  {
    id: 3006,
    comune: "GAVELLO",
    sigla: "RO",
    codice_elettorale: 1050710260,
    codice_istat: 29026,
    codice_belfiore: "D942",
  },
  {
    id: 3007,
    comune: "GAVERINA TERME",
    sigla: "BG",
    codice_elettorale: 1030121050,
    codice_istat: 16110,
    codice_belfiore: "D943",
  },
  {
    id: 3008,
    comune: "GAVI",
    sigla: "AL",
    codice_elettorale: 1010020790,
    codice_istat: 6081,
    codice_belfiore: "D944",
  },
  {
    id: 3009,
    comune: "GAVIGNANO",
    sigla: "RM",
    codice_elettorale: 2120700410,
    codice_istat: 58041,
    codice_belfiore: "D945",
  },
  {
    id: 3010,
    comune: "GAVIRATE",
    sigla: "VA",
    codice_elettorale: 1030860610,
    codice_istat: 12072,
    codice_belfiore: "D946",
  },
  {
    id: 3011,
    comune: "GAVOI",
    sigla: "NU",
    codice_elettorale: 4200530260,
    codice_istat: 91028,
    codice_belfiore: "D947",
  },
  {
    id: 3012,
    comune: "GAVORRANO",
    sigla: "GR",
    codice_elettorale: 2090360090,
    codice_istat: 53010,
    codice_belfiore: "D948",
  },
  {
    id: 3013,
    comune: "GAZOLDO DEGLI IPPOLITI",
    sigla: "MN",
    codice_elettorale: 1030450240,
    codice_istat: 20024,
    codice_belfiore: "D949",
  },
  {
    id: 3014,
    comune: "GAZZADA SCHIANNO",
    sigla: "VA",
    codice_elettorale: 1030860620,
    codice_istat: 12073,
    codice_belfiore: "D951",
  },
  {
    id: 3015,
    comune: "GAZZANIGA",
    sigla: "BG",
    codice_elettorale: 1030121060,
    codice_istat: 16111,
    codice_belfiore: "D952",
  },
  {
    id: 3016,
    comune: "GAZZO",
    sigla: "PD",
    codice_elettorale: 1050540410,
    codice_istat: 28041,
    codice_belfiore: "D956",
  },
  {
    id: 3017,
    comune: "GAZZO VERONESE",
    sigla: "VR",
    codice_elettorale: 1050890370,
    codice_istat: 23037,
    codice_belfiore: "D957",
  },
  {
    id: 3018,
    comune: "GAZZOLA",
    sigla: "PC",
    codice_elettorale: 1080610220,
    codice_istat: 33022,
    codice_belfiore: "D958",
  },
  {
    id: 3019,
    comune: "GAZZUOLO",
    sigla: "MN",
    codice_elettorale: 1030450250,
    codice_istat: 20025,
    codice_belfiore: "D959",
  },
  {
    id: 3020,
    comune: "GELA",
    sigla: "CL",
    codice_elettorale: 4190180070,
    codice_istat: 85007,
    codice_belfiore: "D960",
  },
  {
    id: 3021,
    comune: "GEMMANO",
    sigla: "RN",
    codice_elettorale: 1081010040,
    codice_istat: 99004,
    codice_belfiore: "D961",
  },
  {
    id: 3022,
    comune: "GEMONA DEL FRIULI",
    sigla: "UD",
    codice_elettorale: 1060850430,
    codice_istat: 30043,
    codice_belfiore: "D962",
  },
  {
    id: 3023,
    comune: "GEMONIO",
    sigla: "VA",
    codice_elettorale: 1030860630,
    codice_istat: 12074,
    codice_belfiore: "D963",
  },
  {
    id: 3024,
    comune: "GENAZZANO",
    sigla: "RM",
    codice_elettorale: 2120700420,
    codice_istat: 58042,
    codice_belfiore: "D964",
  },
  {
    id: 3025,
    comune: "GENGA",
    sigla: "AN",
    codice_elettorale: 2110030200,
    codice_istat: 42020,
    codice_belfiore: "D965",
  },
  {
    id: 3026,
    comune: "GENIVOLTA",
    sigla: "CR",
    codice_elettorale: 1030260460,
    codice_istat: 19047,
    codice_belfiore: "D966",
  },
  {
    id: 3027,
    comune: "GENOLA",
    sigla: "CN",
    codice_elettorale: 1010270960,
    codice_istat: 4096,
    codice_belfiore: "D967",
  },
  {
    id: 3028,
    comune: "GENONI",
    sigla: "SU",
    codice_elettorale: 4201110230,
    codice_istat: 111023,
    codice_belfiore: "D968",
  },
  {
    id: 3029,
    comune: "GENOVA",
    sigla: "GE",
    codice_elettorale: 1070340250,
    codice_istat: 10025,
    codice_belfiore: "D969",
  },
  {
    id: 3030,
    comune: "GENURI",
    sigla: "SU",
    codice_elettorale: 4201110240,
    codice_istat: 111024,
    codice_belfiore: "D970",
  },
  {
    id: 3031,
    comune: "GENZANO DI LUCANIA",
    sigla: "PZ",
    codice_elettorale: 3170640350,
    codice_istat: 76036,
    codice_belfiore: "D971",
  },
  {
    id: 3032,
    comune: "GENZANO DI ROMA",
    sigla: "RM",
    codice_elettorale: 2120700430,
    codice_istat: 58043,
    codice_belfiore: "D972",
  },
  {
    id: 3033,
    comune: "GERA LARIO",
    sigla: "CO",
    codice_elettorale: 1030241010,
    codice_istat: 13107,
    codice_belfiore: "D974",
  },
  {
    id: 3034,
    comune: "GERACE",
    sigla: "RC",
    codice_elettorale: 3180670360,
    codice_istat: 80036,
    codice_belfiore: "D975",
  },
  {
    id: 3035,
    comune: "GERACI SICULO",
    sigla: "PA",
    codice_elettorale: 4190550350,
    codice_istat: 82037,
    codice_belfiore: "D977",
  },
  {
    id: 3036,
    comune: "GERANO",
    sigla: "RM",
    codice_elettorale: 2120700440,
    codice_istat: 58044,
    codice_belfiore: "D978",
  },
  {
    id: 3037,
    comune: "GERENZAGO",
    sigla: "PV",
    codice_elettorale: 1030570680,
    codice_istat: 18071,
    codice_belfiore: "D980",
  },
  {
    id: 3038,
    comune: "GERENZANO",
    sigla: "VA",
    codice_elettorale: 1030860640,
    codice_istat: 12075,
    codice_belfiore: "D981",
  },
  {
    id: 3039,
    comune: "GERGEI",
    sigla: "SU",
    codice_elettorale: 4201110250,
    codice_istat: 111025,
    codice_belfiore: "D982",
  },
  {
    id: 3040,
    comune: "GERMAGNANO",
    sigla: "TO",
    codice_elettorale: 1010811110,
    codice_istat: 1113,
    codice_belfiore: "D983",
  },
  {
    id: 3041,
    comune: "GERMAGNO",
    sigla: "VB",
    codice_elettorale: 1011020320,
    codice_istat: 103032,
    codice_belfiore: "D984",
  },
  {
    id: 3042,
    comune: "GERMIGNAGA",
    sigla: "VA",
    codice_elettorale: 1030860650,
    codice_istat: 12076,
    codice_belfiore: "D987",
  },
  {
    id: 3043,
    comune: "GEROCARNE",
    sigla: "VV",
    codice_elettorale: 3181030160,
    codice_istat: 102016,
    codice_belfiore: "D988",
  },
  {
    id: 3044,
    comune: "GEROLA ALTA",
    sigla: "SO",
    codice_elettorale: 1030770310,
    codice_istat: 14031,
    codice_belfiore: "D990",
  },
  {
    id: 3045,
    comune: "GERRE DE' CAPRIOLI",
    sigla: "CR",
    codice_elettorale: 1030260470,
    codice_istat: 19048,
    codice_belfiore: "D993",
  },
  {
    id: 3046,
    comune: "GESICO",
    sigla: "SU",
    codice_elettorale: 4201110260,
    codice_istat: 111026,
    codice_belfiore: "D994",
  },
  {
    id: 3047,
    comune: "GESSATE",
    sigla: "MI",
    codice_elettorale: 1030491050,
    codice_istat: 15106,
    codice_belfiore: "D995",
  },
  {
    id: 3048,
    comune: "GESSOPALENA",
    sigla: "CH",
    codice_elettorale: 3130230400,
    codice_istat: 69040,
    codice_belfiore: "D996",
  },
  {
    id: 3049,
    comune: "GESTURI",
    sigla: "SU",
    codice_elettorale: 4201110270,
    codice_istat: 111027,
    codice_belfiore: "D997",
  },
  {
    id: 3050,
    comune: "GESUALDO",
    sigla: "AV",
    codice_elettorale: 3150080360,
    codice_istat: 64036,
    codice_belfiore: "D998",
  },
  {
    id: 3051,
    comune: "GHEDI",
    sigla: "BS",
    codice_elettorale: 1030150720,
    codice_istat: 17078,
    codice_belfiore: "D999",
  },
  {
    id: 3052,
    comune: "GHEMME",
    sigla: "NO",
    codice_elettorale: 1010520700,
    codice_istat: 3073,
    codice_belfiore: "E001",
  },
  {
    id: 3053,
    comune: "GHIFFA",
    sigla: "VB",
    codice_elettorale: 1011020330,
    codice_istat: 103033,
    codice_belfiore: "E003",
  },
  {
    id: 3054,
    comune: "GHILARZA",
    sigla: "OR",
    codice_elettorale: 4200950210,
    codice_istat: 95021,
    codice_belfiore: "E004",
  },
  {
    id: 3055,
    comune: "GHISALBA",
    sigla: "BG",
    codice_elettorale: 1030121080,
    codice_istat: 16113,
    codice_belfiore: "E006",
  },
  {
    id: 3056,
    comune: "GHISLARENGO",
    sigla: "VC",
    codice_elettorale: 1010880620,
    codice_istat: 2062,
    codice_belfiore: "E007",
  },
  {
    id: 3057,
    comune: "GIACCIANO CON BARUCHELLA",
    sigla: "RO",
    codice_elettorale: 1050710270,
    codice_istat: 29027,
    codice_belfiore: "E008",
  },
  {
    id: 3058,
    comune: "GIAGLIONE",
    sigla: "TO",
    codice_elettorale: 1010811120,
    codice_istat: 1114,
    codice_belfiore: "E009",
  },
  {
    id: 3059,
    comune: "GIANICO",
    sigla: "BS",
    codice_elettorale: 1030150730,
    codice_istat: 17079,
    codice_belfiore: "E010",
  },
  {
    id: 3060,
    comune: "GIANO DELL'UMBRIA",
    sigla: "PG",
    codice_elettorale: 2100580210,
    codice_istat: 54021,
    codice_belfiore: "E012",
  },
  {
    id: 3061,
    comune: "GIANO VETUSTO",
    sigla: "CE",
    codice_elettorale: 3150200400,
    codice_istat: 61040,
    codice_belfiore: "E011",
  },
  {
    id: 3062,
    comune: "GIARDINELLO",
    sigla: "PA",
    codice_elettorale: 4190550360,
    codice_istat: 82038,
    codice_belfiore: "E013",
  },
  {
    id: 3063,
    comune: "GIARDINI NAXOS",
    sigla: "ME",
    codice_elettorale: 4190480310,
    codice_istat: 83032,
    codice_belfiore: "E014",
  },
  {
    id: 3064,
    comune: "GIAROLE",
    sigla: "AL",
    codice_elettorale: 1010020800,
    codice_istat: 6082,
    codice_belfiore: "E015",
  },
  {
    id: 3065,
    comune: "GIARRATANA",
    sigla: "RG",
    codice_elettorale: 4190650040,
    codice_istat: 88004,
    codice_belfiore: "E016",
  },
  {
    id: 3066,
    comune: "GIARRE",
    sigla: "CT",
    codice_elettorale: 4190210170,
    codice_istat: 87017,
    codice_belfiore: "E017",
  },
  {
    id: 3067,
    comune: "GIAVE",
    sigla: "SS",
    codice_elettorale: 4200730300,
    codice_istat: 90030,
    codice_belfiore: "E019",
  },
  {
    id: 3068,
    comune: "GIAVENO",
    sigla: "TO",
    codice_elettorale: 1010811130,
    codice_istat: 1115,
    codice_belfiore: "E020",
  },
  {
    id: 3069,
    comune: "GIAVERA DEL MONTELLO",
    sigla: "TV",
    codice_elettorale: 1050840311,
    codice_istat: 26032,
    codice_belfiore: "E021",
  },
  {
    id: 3070,
    comune: "GIBA",
    sigla: "SU",
    codice_elettorale: 4201110280,
    codice_istat: 111028,
    codice_belfiore: "E022",
  },
  {
    id: 3071,
    comune: "GIBELLINA",
    sigla: "TP",
    codice_elettorale: 4190820110,
    codice_istat: 81010,
    codice_belfiore: "E023",
  },
  {
    id: 3072,
    comune: "GIFFLENGA",
    sigla: "BI",
    codice_elettorale: 1010960270,
    codice_istat: 96027,
    codice_belfiore: "E024",
  },
  {
    id: 3073,
    comune: "GIFFONE",
    sigla: "RC",
    codice_elettorale: 3180670370,
    codice_istat: 80037,
    codice_belfiore: "E025",
  },
  {
    id: 3074,
    comune: "GIFFONI SEI CASALI",
    sigla: "SA",
    codice_elettorale: 3150720550,
    codice_istat: 65055,
    codice_belfiore: "E026",
  },
  {
    id: 3075,
    comune: "GIFFONI VALLE PIANA",
    sigla: "SA",
    codice_elettorale: 3150720560,
    codice_istat: 65056,
    codice_belfiore: "E027",
  },
  {
    id: 3076,
    comune: "GIGNESE",
    sigla: "VB",
    codice_elettorale: 1011020340,
    codice_istat: 103034,
    codice_belfiore: "E028",
  },
  {
    id: 3077,
    comune: "GIGNOD",
    sigla: "AO",
    codice_elettorale: 1020040290,
    codice_istat: 7030,
    codice_belfiore: "E029",
  },
  {
    id: 3078,
    comune: "GILDONE",
    sigla: "CB",
    codice_elettorale: 3140190260,
    codice_istat: 70026,
    codice_belfiore: "E030",
  },
  {
    id: 3079,
    comune: "GIMIGLIANO",
    sigla: "CZ",
    codice_elettorale: 3180220560,
    codice_istat: 79058,
    codice_belfiore: "E031",
  },
  {
    id: 3080,
    comune: "GINESTRA",
    sigla: "PZ",
    codice_elettorale: 3170640351,
    codice_istat: 76099,
    codice_belfiore: "E033",
  },
  {
    id: 3081,
    comune: "GINESTRA DEGLI SCHIAVONI",
    sigla: "BN",
    codice_elettorale: 3150110350,
    codice_istat: 62036,
    codice_belfiore: "E034",
  },
  {
    id: 3082,
    comune: "GINOSA",
    sigla: "TA",
    codice_elettorale: 3160780070,
    codice_istat: 73007,
    codice_belfiore: "E036",
  },
  {
    id: 3083,
    comune: "GIOI",
    sigla: "SA",
    codice_elettorale: 3150720570,
    codice_istat: 65057,
    codice_belfiore: "E037",
  },
  {
    id: 3084,
    comune: "GIOIA DEI MARSI",
    sigla: "AQ",
    codice_elettorale: 3130380460,
    codice_istat: 66046,
    codice_belfiore: "E040",
  },
  {
    id: 3085,
    comune: "GIOIA DEL COLLE",
    sigla: "BA",
    codice_elettorale: 3160090210,
    codice_istat: 72021,
    codice_belfiore: "E038",
  },
  {
    id: 3086,
    comune: "GIOIA SANNITICA",
    sigla: "CE",
    codice_elettorale: 3150200410,
    codice_istat: 61041,
    codice_belfiore: "E039",
  },
  {
    id: 3087,
    comune: "GIOIA TAURO",
    sigla: "RC",
    codice_elettorale: 3180670380,
    codice_istat: 80038,
    codice_belfiore: "E041",
  },
  {
    id: 3088,
    comune: "GIOIOSA IONICA",
    sigla: "RC",
    codice_elettorale: 3180670390,
    codice_istat: 80039,
    codice_belfiore: "E044",
  },
  {
    id: 3089,
    comune: "GIOIOSA MAREA",
    sigla: "ME",
    codice_elettorale: 4190480320,
    codice_istat: 83033,
    codice_belfiore: "E043",
  },
  {
    id: 3090,
    comune: "GIOVE",
    sigla: "TR",
    codice_elettorale: 2100800140,
    codice_istat: 55014,
    codice_belfiore: "E045",
  },
  {
    id: 3091,
    comune: "GIOVINAZZO",
    sigla: "BA",
    codice_elettorale: 3160090220,
    codice_istat: 72022,
    codice_belfiore: "E047",
  },
  {
    id: 3092,
    comune: "GIOVO",
    sigla: "TN",
    codice_elettorale: 1040830870,
    codice_istat: 22092,
    codice_belfiore: "E048",
  },
  {
    id: 3093,
    comune: "GIRASOLE",
    sigla: "NU",
    codice_elettorale: 4200530290,
    codice_istat: 91031,
    codice_belfiore: "E049",
  },
  {
    id: 3094,
    comune: "GIRIFALCO",
    sigla: "CZ",
    codice_elettorale: 3180220570,
    codice_istat: 79059,
    codice_belfiore: "E050",
  },
  {
    id: 3095,
    comune: "GISSI",
    sigla: "CH",
    codice_elettorale: 3130230410,
    codice_istat: 69041,
    codice_belfiore: "E052",
  },
  {
    id: 3096,
    comune: "GIUGGIANELLO",
    sigla: "LE",
    codice_elettorale: 3160410310,
    codice_istat: 75032,
    codice_belfiore: "E053",
  },
  {
    id: 3097,
    comune: "GIUGLIANO IN CAMPANIA",
    sigla: "NA",
    codice_elettorale: 3150510340,
    codice_istat: 63034,
    codice_belfiore: "E054",
  },
  {
    id: 3098,
    comune: "GIULIANA",
    sigla: "PA",
    codice_elettorale: 4190550370,
    codice_istat: 82039,
    codice_belfiore: "E055",
  },
  {
    id: 3099,
    comune: "GIULIANO DI ROMA",
    sigla: "FR",
    codice_elettorale: 2120330410,
    codice_istat: 60041,
    codice_belfiore: "E057",
  },
  {
    id: 3100,
    comune: "GIULIANO TEATINO",
    sigla: "CH",
    codice_elettorale: 3130230420,
    codice_istat: 69042,
    codice_belfiore: "E056",
  },
  {
    id: 3101,
    comune: "GIULIANOVA",
    sigla: "TE",
    codice_elettorale: 3130790240,
    codice_istat: 67025,
    codice_belfiore: "E058",
  },
  {
    id: 3102,
    comune: "GIUNGANO",
    sigla: "SA",
    codice_elettorale: 3150720580,
    codice_istat: 65058,
    codice_belfiore: "E060",
  },
  {
    id: 3103,
    comune: "GIURDIGNANO",
    sigla: "LE",
    codice_elettorale: 3160410320,
    codice_istat: 75033,
    codice_belfiore: "E061",
  },
  {
    id: 3104,
    comune: "GIUSSAGO",
    sigla: "PV",
    codice_elettorale: 1030570690,
    codice_istat: 18072,
    codice_belfiore: "E062",
  },
  {
    id: 3105,
    comune: "GIUSSANO",
    sigla: "MB",
    codice_elettorale: 1031040240,
    codice_istat: 108024,
    codice_belfiore: "E063",
  },
  {
    id: 3106,
    comune: "GIUSTENICE",
    sigla: "SV",
    codice_elettorale: 1070740310,
    codice_istat: 9031,
    codice_belfiore: "E064",
  },
  {
    id: 3107,
    comune: "GIUSTINO",
    sigla: "TN",
    codice_elettorale: 1040830880,
    codice_istat: 22093,
    codice_belfiore: "E065",
  },
  {
    id: 3108,
    comune: "GIUSVALLA",
    sigla: "SV",
    codice_elettorale: 1070740320,
    codice_istat: 9032,
    codice_belfiore: "E066",
  },
  {
    id: 3109,
    comune: "GIVOLETTO",
    sigla: "TO",
    codice_elettorale: 1010811140,
    codice_istat: 1116,
    codice_belfiore: "E067",
  },
  {
    id: 3110,
    comune: "GIZZERIA",
    sigla: "CZ",
    codice_elettorale: 3180220580,
    codice_istat: 79060,
    codice_belfiore: "E068",
  },
  {
    id: 3111,
    comune: "GLORENZA/GLURNS",
    sigla: "BZ",
    codice_elettorale: 1040140330,
    codice_istat: 21036,
    codice_belfiore: "E069",
  },
  {
    id: 3112,
    comune: "GODEGA DI SANT'URBANO",
    sigla: "TV",
    codice_elettorale: 1050840320,
    codice_istat: 26033,
    codice_belfiore: "E071",
  },
  {
    id: 3113,
    comune: "GODIASCO SALICE TERME",
    sigla: "PV",
    codice_elettorale: 1030570700,
    codice_istat: 18073,
    codice_belfiore: "E072",
  },
  {
    id: 3114,
    comune: "GODRANO",
    sigla: "PA",
    codice_elettorale: 4190550380,
    codice_istat: 82040,
    codice_belfiore: "E074",
  },
  {
    id: 3115,
    comune: "GOITO",
    sigla: "MN",
    codice_elettorale: 1030450260,
    codice_istat: 20026,
    codice_belfiore: "E078",
  },
  {
    id: 3116,
    comune: "GOLASECCA",
    sigla: "VA",
    codice_elettorale: 1030860660,
    codice_istat: 12077,
    codice_belfiore: "E079",
  },
  {
    id: 3117,
    comune: "GOLFERENZO",
    sigla: "PV",
    codice_elettorale: 1030570710,
    codice_istat: 18074,
    codice_belfiore: "E081",
  },
  {
    id: 3118,
    comune: "GOLFO ARANCI",
    sigla: "SS",
    codice_elettorale: 4200730301,
    codice_istat: 90083,
    codice_belfiore: "M274",
  },
  {
    id: 3119,
    comune: "GOMBITO",
    sigla: "CR",
    codice_elettorale: 1030260480,
    codice_istat: 19049,
    codice_belfiore: "E082",
  },
  {
    id: 3120,
    comune: "GONARS",
    sigla: "UD",
    codice_elettorale: 1060850440,
    codice_istat: 30044,
    codice_belfiore: "E083",
  },
  {
    id: 3121,
    comune: "GONI",
    sigla: "SU",
    codice_elettorale: 4201110290,
    codice_istat: 111029,
    codice_belfiore: "E084",
  },
  {
    id: 3122,
    comune: "GONNESA",
    sigla: "SU",
    codice_elettorale: 4201110300,
    codice_istat: 111030,
    codice_belfiore: "E086",
  },
  {
    id: 3123,
    comune: "GONNOSCODINA",
    sigla: "OR",
    codice_elettorale: 4200950220,
    codice_istat: 95022,
    codice_belfiore: "E087",
  },
  {
    id: 3124,
    comune: "GONNOSFANADIGA",
    sigla: "SU",
    codice_elettorale: 4201110310,
    codice_istat: 111031,
    codice_belfiore: "E085",
  },
  {
    id: 3125,
    comune: "GONNOSNO'",
    sigla: "OR",
    codice_elettorale: 4200950230,
    codice_istat: 95023,
    codice_belfiore: "D585",
  },
  {
    id: 3126,
    comune: "GONNOSTRAMATZA",
    sigla: "OR",
    codice_elettorale: 4200950240,
    codice_istat: 95024,
    codice_belfiore: "E088",
  },
  {
    id: 3127,
    comune: "GONZAGA",
    sigla: "MN",
    codice_elettorale: 1030450270,
    codice_istat: 20027,
    codice_belfiore: "E089",
  },
  {
    id: 3128,
    comune: "GORDONA",
    sigla: "SO",
    codice_elettorale: 1030770320,
    codice_istat: 14032,
    codice_belfiore: "E090",
  },
  {
    id: 3129,
    comune: "GORGA",
    sigla: "RM",
    codice_elettorale: 2120700450,
    codice_istat: 58045,
    codice_belfiore: "E091",
  },
  {
    id: 3130,
    comune: "GORGO AL MONTICANO",
    sigla: "TV",
    codice_elettorale: 1050840330,
    codice_istat: 26034,
    codice_belfiore: "E092",
  },
  {
    id: 3131,
    comune: "GORGOGLIONE",
    sigla: "MT",
    codice_elettorale: 3170470100,
    codice_istat: 77010,
    codice_belfiore: "E093",
  },
  {
    id: 3132,
    comune: "GORGONZOLA",
    sigla: "MI",
    codice_elettorale: 1030491070,
    codice_istat: 15108,
    codice_belfiore: "E094",
  },
  {
    id: 3133,
    comune: "GORIANO SICOLI",
    sigla: "AQ",
    codice_elettorale: 3130380470,
    codice_istat: 66047,
    codice_belfiore: "E096",
  },
  {
    id: 3134,
    comune: "GORIZIA",
    sigla: "GO",
    codice_elettorale: 1060350070,
    codice_istat: 31007,
    codice_belfiore: "E098",
  },
  {
    id: 3135,
    comune: "GORLA MAGGIORE",
    sigla: "VA",
    codice_elettorale: 1030860670,
    codice_istat: 12078,
    codice_belfiore: "E101",
  },
  {
    id: 3136,
    comune: "GORLA MINORE",
    sigla: "VA",
    codice_elettorale: 1030860680,
    codice_istat: 12079,
    codice_belfiore: "E102",
  },
  {
    id: 3137,
    comune: "GORLAGO",
    sigla: "BG",
    codice_elettorale: 1030121090,
    codice_istat: 16114,
    codice_belfiore: "E100",
  },
  {
    id: 3138,
    comune: "GORLE",
    sigla: "BG",
    codice_elettorale: 1030121100,
    codice_istat: 16115,
    codice_belfiore: "E103",
  },
  {
    id: 3139,
    comune: "GORNATE OLONA",
    sigla: "VA",
    codice_elettorale: 1030860690,
    codice_istat: 12080,
    codice_belfiore: "E104",
  },
  {
    id: 3140,
    comune: "GORNO",
    sigla: "BG",
    codice_elettorale: 1030121110,
    codice_istat: 16116,
    codice_belfiore: "E106",
  },
  {
    id: 3141,
    comune: "GORO",
    sigla: "FE",
    codice_elettorale: 1080290091,
    codice_istat: 38025,
    codice_belfiore: "E107",
  },
  {
    id: 3142,
    comune: "GORRETO",
    sigla: "GE",
    codice_elettorale: 1070340260,
    codice_istat: 10026,
    codice_belfiore: "E109",
  },
  {
    id: 3143,
    comune: "GORZEGNO",
    sigla: "CN",
    codice_elettorale: 1010270970,
    codice_istat: 4097,
    codice_belfiore: "E111",
  },
  {
    id: 3144,
    comune: "GOSALDO",
    sigla: "BL",
    codice_elettorale: 1050100250,
    codice_istat: 25025,
    codice_belfiore: "E113",
  },
  {
    id: 3145,
    comune: "GOSSOLENGO",
    sigla: "PC",
    codice_elettorale: 1080610230,
    codice_istat: 33023,
    codice_belfiore: "E114",
  },
  {
    id: 3146,
    comune: "GOTTASECCA",
    sigla: "CN",
    codice_elettorale: 1010270980,
    codice_istat: 4098,
    codice_belfiore: "E115",
  },
  {
    id: 3147,
    comune: "GOTTOLENGO",
    sigla: "BS",
    codice_elettorale: 1030150740,
    codice_istat: 17080,
    codice_belfiore: "E116",
  },
  {
    id: 3148,
    comune: "GOVONE",
    sigla: "CN",
    codice_elettorale: 1010270990,
    codice_istat: 4099,
    codice_belfiore: "E118",
  },
  {
    id: 3149,
    comune: "GOZZANO",
    sigla: "NO",
    codice_elettorale: 1010520730,
    codice_istat: 3076,
    codice_belfiore: "E120",
  },
  {
    id: 3150,
    comune: "GRADARA",
    sigla: "PU",
    codice_elettorale: 2110590200,
    codice_istat: 41020,
    codice_belfiore: "E122",
  },
  {
    id: 3151,
    comune: "GRADISCA D'ISONZO",
    sigla: "GO",
    codice_elettorale: 1060350080,
    codice_istat: 31008,
    codice_belfiore: "E124",
  },
  {
    id: 3152,
    comune: "GRADO",
    sigla: "GO",
    codice_elettorale: 1060350090,
    codice_istat: 31009,
    codice_belfiore: "E125",
  },
  {
    id: 3153,
    comune: "GRADOLI",
    sigla: "VT",
    codice_elettorale: 2120910270,
    codice_istat: 56028,
    codice_belfiore: "E126",
  },
  {
    id: 3154,
    comune: "GRAFFIGNANA",
    sigla: "LO",
    codice_elettorale: 1030990280,
    codice_istat: 98028,
    codice_belfiore: "E127",
  },
  {
    id: 3155,
    comune: "GRAFFIGNANO",
    sigla: "VT",
    codice_elettorale: 2120910280,
    codice_istat: 56029,
    codice_belfiore: "E128",
  },
  {
    id: 3156,
    comune: "GRAGLIA",
    sigla: "BI",
    codice_elettorale: 1010960280,
    codice_istat: 96028,
    codice_belfiore: "E130",
  },
  {
    id: 3157,
    comune: "GRAGNANO",
    sigla: "NA",
    codice_elettorale: 3150510350,
    codice_istat: 63035,
    codice_belfiore: "E131",
  },
  {
    id: 3158,
    comune: "GRAGNANO TREBBIENSE",
    sigla: "PC",
    codice_elettorale: 1080610240,
    codice_istat: 33024,
    codice_belfiore: "E132",
  },
  {
    id: 3159,
    comune: "GRAMMICHELE",
    sigla: "CT",
    codice_elettorale: 4190210180,
    codice_istat: 87018,
    codice_belfiore: "E133",
  },
  {
    id: 3160,
    comune: "GRANA MONFERRATO",
    sigla: "AT",
    codice_elettorale: 1010070560,
    codice_istat: 5056,
    codice_belfiore: "E134",
  },
  {
    id: 3161,
    comune: "GRANAROLO DELL'EMILIA",
    sigla: "BO",
    codice_elettorale: 1080130300,
    codice_istat: 37030,
    codice_belfiore: "E136",
  },
  {
    id: 3162,
    comune: "GRANDATE",
    sigla: "CO",
    codice_elettorale: 1030241030,
    codice_istat: 13110,
    codice_belfiore: "E139",
  },
  {
    id: 3163,
    comune: "GRANDOLA ED UNITI",
    sigla: "CO",
    codice_elettorale: 1030241040,
    codice_istat: 13111,
    codice_belfiore: "E141",
  },
  {
    id: 3164,
    comune: "GRANITI",
    sigla: "ME",
    codice_elettorale: 4190480330,
    codice_istat: 83034,
    codice_belfiore: "E142",
  },
  {
    id: 3165,
    comune: "GRANOZZO CON MONTICELLO",
    sigla: "NO",
    codice_elettorale: 1010520740,
    codice_istat: 3077,
    codice_belfiore: "E143",
  },
  {
    id: 3166,
    comune: "GRANTOLA",
    sigla: "VA",
    codice_elettorale: 1030860691,
    codice_istat: 12081,
    codice_belfiore: "E144",
  },
  {
    id: 3167,
    comune: "GRANTORTO",
    sigla: "PD",
    codice_elettorale: 1050540420,
    codice_istat: 28042,
    codice_belfiore: "E145",
  },
  {
    id: 3168,
    comune: "GRANZE",
    sigla: "PD",
    codice_elettorale: 1050540430,
    codice_istat: 28043,
    codice_belfiore: "E146",
  },
  {
    id: 3169,
    comune: "GRASSANO",
    sigla: "MT",
    codice_elettorale: 3170470110,
    codice_istat: 77011,
    codice_belfiore: "E147",
  },
  {
    id: 3170,
    comune: "GRASSOBBIO",
    sigla: "BG",
    codice_elettorale: 1030121120,
    codice_istat: 16117,
    codice_belfiore: "E148",
  },
  {
    id: 3171,
    comune: "GRATTERI",
    sigla: "PA",
    codice_elettorale: 4190550390,
    codice_istat: 82041,
    codice_belfiore: "E149",
  },
  {
    id: 3172,
    comune: "GRAVEDONA ED UNITI",
    sigla: "CO",
    codice_elettorale: 1030241055,
    codice_istat: 13249,
    codice_belfiore: "M315",
  },
  {
    id: 3173,
    comune: "GRAVELLONA LOMELLINA",
    sigla: "PV",
    codice_elettorale: 1030570720,
    codice_istat: 18075,
    codice_belfiore: "E152",
  },
  {
    id: 3174,
    comune: "GRAVELLONA TOCE",
    sigla: "VB",
    codice_elettorale: 1011020350,
    codice_istat: 103035,
    codice_belfiore: "E153",
  },
  {
    id: 3175,
    comune: "GRAVERE",
    sigla: "TO",
    codice_elettorale: 1010811150,
    codice_istat: 1117,
    codice_belfiore: "E154",
  },
  {
    id: 3176,
    comune: "GRAVINA DI CATANIA",
    sigla: "CT",
    codice_elettorale: 4190210190,
    codice_istat: 87019,
    codice_belfiore: "E156",
  },
  {
    id: 3177,
    comune: "GRAVINA IN PUGLIA",
    sigla: "BA",
    codice_elettorale: 3160090230,
    codice_istat: 72023,
    codice_belfiore: "E155",
  },
  {
    id: 3178,
    comune: "GRAZZANISE",
    sigla: "CE",
    codice_elettorale: 3150200420,
    codice_istat: 61042,
    codice_belfiore: "E158",
  },
  {
    id: 3179,
    comune: "GRAZZANO BADOGLIO",
    sigla: "AT",
    codice_elettorale: 1010070570,
    codice_istat: 5057,
    codice_belfiore: "E159",
  },
  {
    id: 3180,
    comune: "GRECCIO",
    sigla: "RI",
    codice_elettorale: 2120690290,
    codice_istat: 57031,
    codice_belfiore: "E160",
  },
  {
    id: 3181,
    comune: "GRECI",
    sigla: "AV",
    codice_elettorale: 3150080370,
    codice_istat: 64037,
    codice_belfiore: "E161",
  },
  {
    id: 3182,
    comune: "GREGGIO",
    sigla: "VC",
    codice_elettorale: 1010880650,
    codice_istat: 2065,
    codice_belfiore: "E163",
  },
  {
    id: 3183,
    comune: "GREMIASCO",
    sigla: "AL",
    codice_elettorale: 1010020810,
    codice_istat: 6083,
    codice_belfiore: "E164",
  },
  {
    id: 3184,
    comune: "GRESSAN",
    sigla: "AO",
    codice_elettorale: 1020040300,
    codice_istat: 7031,
    codice_belfiore: "E165",
  },
  {
    id: 3185,
    comune: "GRESSONEY-LA-TRINITE'",
    sigla: "AO",
    codice_elettorale: 1020040310,
    codice_istat: 7032,
    codice_belfiore: "E167",
  },
  {
    id: 3186,
    comune: "GRESSONEY-SAINT-JEAN",
    sigla: "AO",
    codice_elettorale: 1020040320,
    codice_istat: 7033,
    codice_belfiore: "E168",
  },
  {
    id: 3187,
    comune: "GREVE IN CHIANTI",
    sigla: "FI",
    codice_elettorale: 2090300210,
    codice_istat: 48021,
    codice_belfiore: "E169",
  },
  {
    id: 3188,
    comune: "GREZZAGO",
    sigla: "MI",
    codice_elettorale: 1030491090,
    codice_istat: 15110,
    codice_belfiore: "E170",
  },
  {
    id: 3189,
    comune: "GREZZANA",
    sigla: "VR",
    codice_elettorale: 1050890380,
    codice_istat: 23038,
    codice_belfiore: "E171",
  },
  {
    id: 3190,
    comune: "GRIANTE",
    sigla: "CO",
    codice_elettorale: 1030241060,
    codice_istat: 13113,
    codice_belfiore: "E172",
  },
  {
    id: 3191,
    comune: "GRICIGNANO DI AVERSA",
    sigla: "CE",
    codice_elettorale: 3150200430,
    codice_istat: 61043,
    codice_belfiore: "E173",
  },
  {
    id: 3192,
    comune: "GRIGNASCO",
    sigla: "NO",
    codice_elettorale: 1010520760,
    codice_istat: 3079,
    codice_belfiore: "E177",
  },
  {
    id: 3193,
    comune: "GRIGNO",
    sigla: "TN",
    codice_elettorale: 1040830900,
    codice_istat: 22095,
    codice_belfiore: "E178",
  },
  {
    id: 3194,
    comune: "GRIMACCO",
    sigla: "UD",
    codice_elettorale: 1060850450,
    codice_istat: 30045,
    codice_belfiore: "E179",
  },
  {
    id: 3195,
    comune: "GRIMALDI",
    sigla: "CS",
    codice_elettorale: 3180250600,
    codice_istat: 78059,
    codice_belfiore: "E180",
  },
  {
    id: 3196,
    comune: "GRINZANE CAVOUR",
    sigla: "CN",
    codice_elettorale: 1010271000,
    codice_istat: 4100,
    codice_belfiore: "E182",
  },
  {
    id: 3197,
    comune: "GRISIGNANO DI ZOCCO",
    sigla: "VI",
    codice_elettorale: 1050900460,
    codice_istat: 24046,
    codice_belfiore: "E184",
  },
  {
    id: 3198,
    comune: "GRISOLIA",
    sigla: "CS",
    codice_elettorale: 3180250610,
    codice_istat: 78060,
    codice_belfiore: "E185",
  },
  {
    id: 3199,
    comune: "GRIZZANA MORANDI",
    sigla: "BO",
    codice_elettorale: 1080130310,
    codice_istat: 37031,
    codice_belfiore: "E187",
  },
  {
    id: 3200,
    comune: "GROGNARDO",
    sigla: "AL",
    codice_elettorale: 1010020820,
    codice_istat: 6084,
    codice_belfiore: "E188",
  },
  {
    id: 3201,
    comune: "GROMO",
    sigla: "BG",
    codice_elettorale: 1030121130,
    codice_istat: 16118,
    codice_belfiore: "E189",
  },
  {
    id: 3202,
    comune: "GRONDONA",
    sigla: "AL",
    codice_elettorale: 1010020830,
    codice_istat: 6085,
    codice_belfiore: "E191",
  },
  {
    id: 3203,
    comune: "GRONE",
    sigla: "BG",
    codice_elettorale: 1030121140,
    codice_istat: 16119,
    codice_belfiore: "E192",
  },
  {
    id: 3204,
    comune: "GRONTARDO",
    sigla: "CR",
    codice_elettorale: 1030260490,
    codice_istat: 19050,
    codice_belfiore: "E193",
  },
  {
    id: 3205,
    comune: "GROPELLO CAIROLI",
    sigla: "PV",
    codice_elettorale: 1030570730,
    codice_istat: 18076,
    codice_belfiore: "E195",
  },
  {
    id: 3206,
    comune: "GROPPARELLO",
    sigla: "PC",
    codice_elettorale: 1080610250,
    codice_istat: 33025,
    codice_belfiore: "E196",
  },
  {
    id: 3207,
    comune: "GROSCAVALLO",
    sigla: "TO",
    codice_elettorale: 1010811160,
    codice_istat: 1118,
    codice_belfiore: "E199",
  },
  {
    id: 3208,
    comune: "GROSIO",
    sigla: "SO",
    codice_elettorale: 1030770330,
    codice_istat: 14033,
    codice_belfiore: "E200",
  },
  {
    id: 3209,
    comune: "GROSOTTO",
    sigla: "SO",
    codice_elettorale: 1030770340,
    codice_istat: 14034,
    codice_belfiore: "E201",
  },
  {
    id: 3210,
    comune: "GROSSETO",
    sigla: "GR",
    codice_elettorale: 2090360100,
    codice_istat: 53011,
    codice_belfiore: "E202",
  },
  {
    id: 3211,
    comune: "GROSSO",
    sigla: "TO",
    codice_elettorale: 1010811170,
    codice_istat: 1119,
    codice_belfiore: "E203",
  },
  {
    id: 3212,
    comune: "GROTTAFERRATA",
    sigla: "RM",
    codice_elettorale: 2120700460,
    codice_istat: 58046,
    codice_belfiore: "E204",
  },
  {
    id: 3213,
    comune: "GROTTAGLIE",
    sigla: "TA",
    codice_elettorale: 3160780080,
    codice_istat: 73008,
    codice_belfiore: "E205",
  },
  {
    id: 3214,
    comune: "GROTTAMINARDA",
    sigla: "AV",
    codice_elettorale: 3150080380,
    codice_istat: 64038,
    codice_belfiore: "E206",
  },
  {
    id: 3215,
    comune: "GROTTAMMARE",
    sigla: "AP",
    codice_elettorale: 2110060230,
    codice_istat: 44023,
    codice_belfiore: "E207",
  },
  {
    id: 3216,
    comune: "GROTTAZZOLINA",
    sigla: "FM",
    codice_elettorale: 2111050080,
    codice_istat: 109008,
    codice_belfiore: "E208",
  },
  {
    id: 3217,
    comune: "GROTTE",
    sigla: "AG",
    codice_elettorale: 4190010180,
    codice_istat: 84018,
    codice_belfiore: "E209",
  },
  {
    id: 3218,
    comune: "GROTTE DI CASTRO",
    sigla: "VT",
    codice_elettorale: 2120910290,
    codice_istat: 56030,
    codice_belfiore: "E210",
  },
  {
    id: 3219,
    comune: "GROTTERIA",
    sigla: "RC",
    codice_elettorale: 3180670400,
    codice_istat: 80040,
    codice_belfiore: "E212",
  },
  {
    id: 3220,
    comune: "GROTTOLE",
    sigla: "MT",
    codice_elettorale: 3170470120,
    codice_istat: 77012,
    codice_belfiore: "E213",
  },
  {
    id: 3221,
    comune: "GROTTOLELLA",
    sigla: "AV",
    codice_elettorale: 3150080390,
    codice_istat: 64039,
    codice_belfiore: "E214",
  },
  {
    id: 3222,
    comune: "GRUARO",
    sigla: "VE",
    codice_elettorale: 1050870180,
    codice_istat: 27018,
    codice_belfiore: "E215",
  },
  {
    id: 3223,
    comune: "GRUGLIASCO",
    sigla: "TO",
    codice_elettorale: 1010811180,
    codice_istat: 1120,
    codice_belfiore: "E216",
  },
  {
    id: 3224,
    comune: "GRUMELLO CREMONESE ED UNITI",
    sigla: "CR",
    codice_elettorale: 1030260500,
    codice_istat: 19051,
    codice_belfiore: "E217",
  },
  {
    id: 3225,
    comune: "GRUMELLO DEL MONTE",
    sigla: "BG",
    codice_elettorale: 1030121150,
    codice_istat: 16120,
    codice_belfiore: "E219",
  },
  {
    id: 3226,
    comune: "GRUMENTO NOVA",
    sigla: "PZ",
    codice_elettorale: 3170640360,
    codice_istat: 76037,
    codice_belfiore: "E221",
  },
  {
    id: 3227,
    comune: "GRUMO APPULA",
    sigla: "BA",
    codice_elettorale: 3160090240,
    codice_istat: 72024,
    codice_belfiore: "E223",
  },
  {
    id: 3228,
    comune: "GRUMO NEVANO",
    sigla: "NA",
    codice_elettorale: 3150510360,
    codice_istat: 63036,
    codice_belfiore: "E224",
  },
  {
    id: 3229,
    comune: "GRUMOLO DELLE ABBADESSE",
    sigla: "VI",
    codice_elettorale: 1050900470,
    codice_istat: 24047,
    codice_belfiore: "E226",
  },
  {
    id: 3230,
    comune: "GUAGNANO",
    sigla: "LE",
    codice_elettorale: 3160410330,
    codice_istat: 75034,
    codice_belfiore: "E227",
  },
  {
    id: 3231,
    comune: "GUALDO",
    sigla: "MC",
    codice_elettorale: 2110440210,
    codice_istat: 43021,
    codice_belfiore: "E228",
  },
  {
    id: 3232,
    comune: "GUALDO CATTANEO",
    sigla: "PG",
    codice_elettorale: 2100580220,
    codice_istat: 54022,
    codice_belfiore: "E229",
  },
  {
    id: 3233,
    comune: "GUALDO TADINO",
    sigla: "PG",
    codice_elettorale: 2100580230,
    codice_istat: 54023,
    codice_belfiore: "E230",
  },
  {
    id: 3234,
    comune: "GUALTIERI",
    sigla: "RE",
    codice_elettorale: 1080680230,
    codice_istat: 35023,
    codice_belfiore: "E232",
  },
  {
    id: 3235,
    comune: "GUALTIERI SICAMINO'",
    sigla: "ME",
    codice_elettorale: 4190480340,
    codice_istat: 83035,
    codice_belfiore: "E233",
  },
  {
    id: 3236,
    comune: "GUAMAGGIORE",
    sigla: "SU",
    codice_elettorale: 4201110320,
    codice_istat: 111032,
    codice_belfiore: "E234",
  },
  {
    id: 3237,
    comune: "GUANZATE",
    sigla: "CO",
    codice_elettorale: 1030241070,
    codice_istat: 13114,
    codice_belfiore: "E235",
  },
  {
    id: 3238,
    comune: "GUARCINO",
    sigla: "FR",
    codice_elettorale: 2120330420,
    codice_istat: 60042,
    codice_belfiore: "E236",
  },
  {
    id: 3239,
    comune: "GUARDA VENETA",
    sigla: "RO",
    codice_elettorale: 1050710280,
    codice_istat: 29028,
    codice_belfiore: "E240",
  },
  {
    id: 3240,
    comune: "GUARDABOSONE",
    sigla: "VC",
    codice_elettorale: 1010880660,
    codice_istat: 2066,
    codice_belfiore: "E237",
  },
  {
    id: 3241,
    comune: "GUARDAMIGLIO",
    sigla: "LO",
    codice_elettorale: 1030990290,
    codice_istat: 98029,
    codice_belfiore: "E238",
  },
  {
    id: 3242,
    comune: "GUARDAVALLE",
    sigla: "CZ",
    codice_elettorale: 3180220590,
    codice_istat: 79061,
    codice_belfiore: "E239",
  },
  {
    id: 3243,
    comune: "GUARDEA",
    sigla: "TR",
    codice_elettorale: 2100800150,
    codice_istat: 55015,
    codice_belfiore: "E241",
  },
  {
    id: 3244,
    comune: "GUARDIA LOMBARDI",
    sigla: "AV",
    codice_elettorale: 3150080400,
    codice_istat: 64040,
    codice_belfiore: "E245",
  },
  {
    id: 3245,
    comune: "GUARDIA PERTICARA",
    sigla: "PZ",
    codice_elettorale: 3170640370,
    codice_istat: 76038,
    codice_belfiore: "E246",
  },
  {
    id: 3246,
    comune: "GUARDIA PIEMONTESE",
    sigla: "CS",
    codice_elettorale: 3180250620,
    codice_istat: 78061,
    codice_belfiore: "E242",
  },
  {
    id: 3247,
    comune: "GUARDIA SANFRAMONDI",
    sigla: "BN",
    codice_elettorale: 3150110360,
    codice_istat: 62037,
    codice_belfiore: "E249",
  },
  {
    id: 3248,
    comune: "GUARDIAGRELE",
    sigla: "CH",
    codice_elettorale: 3130230430,
    codice_istat: 69043,
    codice_belfiore: "E243",
  },
  {
    id: 3249,
    comune: "GUARDIALFIERA",
    sigla: "CB",
    codice_elettorale: 3140190270,
    codice_istat: 70027,
    codice_belfiore: "E244",
  },
  {
    id: 3250,
    comune: "GUARDIAREGIA",
    sigla: "CB",
    codice_elettorale: 3140190280,
    codice_istat: 70028,
    codice_belfiore: "E248",
  },
  {
    id: 3251,
    comune: "GUARDISTALLO",
    sigla: "PI",
    codice_elettorale: 2090620150,
    codice_istat: 50015,
    codice_belfiore: "E250",
  },
  {
    id: 3252,
    comune: "GUARENE",
    sigla: "CN",
    codice_elettorale: 1010271010,
    codice_istat: 4101,
    codice_belfiore: "E251",
  },
  {
    id: 3253,
    comune: "GUASILA",
    sigla: "SU",
    codice_elettorale: 4201110330,
    codice_istat: 111033,
    codice_belfiore: "E252",
  },
  {
    id: 3254,
    comune: "GUASTALLA",
    sigla: "RE",
    codice_elettorale: 1080680240,
    codice_istat: 35024,
    codice_belfiore: "E253",
  },
  {
    id: 3255,
    comune: "GUAZZORA",
    sigla: "AL",
    codice_elettorale: 1010020840,
    codice_istat: 6086,
    codice_belfiore: "E255",
  },
  {
    id: 3256,
    comune: "GUBBIO",
    sigla: "PG",
    codice_elettorale: 2100580240,
    codice_istat: 54024,
    codice_belfiore: "E256",
  },
  {
    id: 3257,
    comune: "GUDO VISCONTI",
    sigla: "MI",
    codice_elettorale: 1030491110,
    codice_istat: 15112,
    codice_belfiore: "E258",
  },
  {
    id: 3258,
    comune: "GUGLIONESI",
    sigla: "CB",
    codice_elettorale: 3140190290,
    codice_istat: 70029,
    codice_belfiore: "E259",
  },
  {
    id: 3259,
    comune: "GUIDIZZOLO",
    sigla: "MN",
    codice_elettorale: 1030450280,
    codice_istat: 20028,
    codice_belfiore: "E261",
  },
  {
    id: 3260,
    comune: "GUIDONIA MONTECELIO",
    sigla: "RM",
    codice_elettorale: 2120700470,
    codice_istat: 58047,
    codice_belfiore: "E263",
  },
  {
    id: 3261,
    comune: "GUIGLIA",
    sigla: "MO",
    codice_elettorale: 1080500170,
    codice_istat: 36017,
    codice_belfiore: "E264",
  },
  {
    id: 3262,
    comune: "GUILMI",
    sigla: "CH",
    codice_elettorale: 3130230440,
    codice_istat: 69044,
    codice_belfiore: "E266",
  },
  {
    id: 3263,
    comune: "GURRO",
    sigla: "VB",
    codice_elettorale: 1011020360,
    codice_istat: 103036,
    codice_belfiore: "E269",
  },
  {
    id: 3264,
    comune: "GUSPINI",
    sigla: "SU",
    codice_elettorale: 4201110340,
    codice_istat: 111034,
    codice_belfiore: "E270",
  },
  {
    id: 3265,
    comune: "GUSSAGO",
    sigla: "BS",
    codice_elettorale: 1030150750,
    codice_istat: 17081,
    codice_belfiore: "E271",
  },
  {
    id: 3266,
    comune: "GUSSOLA",
    sigla: "CR",
    codice_elettorale: 1030260510,
    codice_istat: 19052,
    codice_belfiore: "E272",
  },
  {
    id: 3267,
    comune: "HONE",
    sigla: "AO",
    codice_elettorale: 1020040330,
    codice_istat: 7034,
    codice_belfiore: "E273",
  },
  {
    id: 3268,
    comune: "IDRO",
    sigla: "BS",
    codice_elettorale: 1030150760,
    codice_istat: 17082,
    codice_belfiore: "E280",
  },
  {
    id: 3269,
    comune: "IGLESIAS",
    sigla: "SU",
    codice_elettorale: 4201110350,
    codice_istat: 111035,
    codice_belfiore: "E281",
  },
  {
    id: 3270,
    comune: "IGLIANO",
    sigla: "CN",
    codice_elettorale: 1010271020,
    codice_istat: 4102,
    codice_belfiore: "E282",
  },
  {
    id: 3271,
    comune: "ILBONO",
    sigla: "NU",
    codice_elettorale: 4200530300,
    codice_istat: 91032,
    codice_belfiore: "E283",
  },
  {
    id: 3272,
    comune: "ILLASI",
    sigla: "VR",
    codice_elettorale: 1050890390,
    codice_istat: 23039,
    codice_belfiore: "E284",
  },
  {
    id: 3273,
    comune: "ILLORAI",
    sigla: "SS",
    codice_elettorale: 4200730310,
    codice_istat: 90031,
    codice_belfiore: "E285",
  },
  {
    id: 3274,
    comune: "IMBERSAGO",
    sigla: "LC",
    codice_elettorale: 1030980390,
    codice_istat: 97039,
    codice_belfiore: "E287",
  },
  {
    id: 3275,
    comune: "IMER",
    sigla: "TN",
    codice_elettorale: 1040830920,
    codice_istat: 22097,
    codice_belfiore: "E288",
  },
  {
    id: 3276,
    comune: "IMOLA",
    sigla: "BO",
    codice_elettorale: 1080130320,
    codice_istat: 37032,
    codice_belfiore: "E289",
  },
  {
    id: 3277,
    comune: "IMPERIA",
    sigla: "IM",
    codice_elettorale: 1070370290,
    codice_istat: 8031,
    codice_belfiore: "E290",
  },
  {
    id: 3278,
    comune: "IMPRUNETA",
    sigla: "FI",
    codice_elettorale: 2090300220,
    codice_istat: 48022,
    codice_belfiore: "E291",
  },
  {
    id: 3279,
    comune: "INARZO",
    sigla: "VA",
    codice_elettorale: 1030860692,
    codice_istat: 12082,
    codice_belfiore: "E292",
  },
  {
    id: 3280,
    comune: "INCISA SCAPACCINO",
    sigla: "AT",
    codice_elettorale: 1010070580,
    codice_istat: 5058,
    codice_belfiore: "E295",
  },
  {
    id: 3281,
    comune: "INCUDINE",
    sigla: "BS",
    codice_elettorale: 1030150770,
    codice_istat: 17083,
    codice_belfiore: "E297",
  },
  {
    id: 3282,
    comune: "INDUNO OLONA",
    sigla: "VA",
    codice_elettorale: 1030860700,
    codice_istat: 12083,
    codice_belfiore: "E299",
  },
  {
    id: 3283,
    comune: "INGRIA",
    sigla: "TO",
    codice_elettorale: 1010811190,
    codice_istat: 1121,
    codice_belfiore: "E301",
  },
  {
    id: 3284,
    comune: "INTRAGNA",
    sigla: "VB",
    codice_elettorale: 1011020370,
    codice_istat: 103037,
    codice_belfiore: "E304",
  },
  {
    id: 3285,
    comune: "INTROBIO",
    sigla: "LC",
    codice_elettorale: 1030980400,
    codice_istat: 97040,
    codice_belfiore: "E305",
  },
  {
    id: 3286,
    comune: "INTROD",
    sigla: "AO",
    codice_elettorale: 1020040340,
    codice_istat: 7035,
    codice_belfiore: "E306",
  },
  {
    id: 3287,
    comune: "INTRODACQUA",
    sigla: "AQ",
    codice_elettorale: 3130380480,
    codice_istat: 66048,
    codice_belfiore: "E307",
  },
  {
    id: 3288,
    comune: "INVERIGO",
    sigla: "CO",
    codice_elettorale: 1030241110,
    codice_istat: 13118,
    codice_belfiore: "E309",
  },
  {
    id: 3289,
    comune: "INVERNO E MONTELEONE",
    sigla: "PV",
    codice_elettorale: 1030570740,
    codice_istat: 18077,
    codice_belfiore: "E310",
  },
  {
    id: 3290,
    comune: "INVERSO PINASCA",
    sigla: "TO",
    codice_elettorale: 1010811200,
    codice_istat: 1122,
    codice_belfiore: "E311",
  },
  {
    id: 3291,
    comune: "INVERUNO",
    sigla: "MI",
    codice_elettorale: 1030491120,
    codice_istat: 15113,
    codice_belfiore: "E313",
  },
  {
    id: 3292,
    comune: "INVORIO",
    sigla: "NO",
    codice_elettorale: 1010520790,
    codice_istat: 3082,
    codice_belfiore: "E314",
  },
  {
    id: 3293,
    comune: "INZAGO",
    sigla: "MI",
    codice_elettorale: 1030491130,
    codice_istat: 15114,
    codice_belfiore: "E317",
  },
  {
    id: 3294,
    comune: "IONADI",
    sigla: "VV",
    codice_elettorale: 3181030170,
    codice_istat: 102017,
    codice_belfiore: "E321",
  },
  {
    id: 3295,
    comune: "IRGOLI",
    sigla: "NU",
    codice_elettorale: 4200530310,
    codice_istat: 91033,
    codice_belfiore: "E323",
  },
  {
    id: 3296,
    comune: "IRMA",
    sigla: "BS",
    codice_elettorale: 1030150771,
    codice_istat: 17084,
    codice_belfiore: "E325",
  },
  {
    id: 3297,
    comune: "IRSINA",
    sigla: "MT",
    codice_elettorale: 3170470130,
    codice_istat: 77013,
    codice_belfiore: "E326",
  },
  {
    id: 3298,
    comune: "ISASCA",
    sigla: "CN",
    codice_elettorale: 1010271030,
    codice_istat: 4103,
    codice_belfiore: "E327",
  },
  {
    id: 3299,
    comune: "ISCA SULLO IONIO",
    sigla: "CZ",
    codice_elettorale: 3180220610,
    codice_istat: 79063,
    codice_belfiore: "E328",
  },
  {
    id: 3300,
    comune: "ISCHIA",
    sigla: "NA",
    codice_elettorale: 3150510370,
    codice_istat: 63037,
    codice_belfiore: "E329",
  },
  {
    id: 3301,
    comune: "ISCHIA DI CASTRO",
    sigla: "VT",
    codice_elettorale: 2120910300,
    codice_istat: 56031,
    codice_belfiore: "E330",
  },
  {
    id: 3302,
    comune: "ISCHITELLA",
    sigla: "FG",
    codice_elettorale: 3160310240,
    codice_istat: 71025,
    codice_belfiore: "E332",
  },
  {
    id: 3303,
    comune: "ISEO",
    sigla: "BS",
    codice_elettorale: 1030150780,
    codice_istat: 17085,
    codice_belfiore: "E333",
  },
  {
    id: 3304,
    comune: "ISERA",
    sigla: "TN",
    codice_elettorale: 1040830930,
    codice_istat: 22098,
    codice_belfiore: "E334",
  },
  {
    id: 3305,
    comune: "ISERNIA",
    sigla: "IS",
    codice_elettorale: 3140940230,
    codice_istat: 94023,
    codice_belfiore: "E335",
  },
  {
    id: 3306,
    comune: "ISILI",
    sigla: "SU",
    codice_elettorale: 4201110360,
    codice_istat: 111036,
    codice_belfiore: "E336",
  },
  {
    id: 3307,
    comune: "ISNELLO",
    sigla: "PA",
    codice_elettorale: 4190550400,
    codice_istat: 82042,
    codice_belfiore: "E337",
  },
  {
    id: 3308,
    comune: "ISOLA D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070590,
    codice_istat: 5059,
    codice_belfiore: "E338",
  },
  {
    id: 3309,
    comune: "ISOLA DEL CANTONE",
    sigla: "GE",
    codice_elettorale: 1070340270,
    codice_istat: 10027,
    codice_belfiore: "E341",
  },
  {
    id: 3310,
    comune: "ISOLA DEL GIGLIO",
    sigla: "GR",
    codice_elettorale: 2090360110,
    codice_istat: 53012,
    codice_belfiore: "E348",
  },
  {
    id: 3311,
    comune: "ISOLA DEL GRAN SASSO D'ITALIA",
    sigla: "TE",
    codice_elettorale: 3130790250,
    codice_istat: 67026,
    codice_belfiore: "E343",
  },
  {
    id: 3312,
    comune: "ISOLA DEL LIRI",
    sigla: "FR",
    codice_elettorale: 2120330430,
    codice_istat: 60043,
    codice_belfiore: "E340",
  },
  {
    id: 3313,
    comune: "ISOLA DEL PIANO",
    sigla: "PU",
    codice_elettorale: 2110590210,
    codice_istat: 41021,
    codice_belfiore: "E351",
  },
  {
    id: 3314,
    comune: "ISOLA DELLA SCALA",
    sigla: "VR",
    codice_elettorale: 1050890400,
    codice_istat: 23040,
    codice_belfiore: "E349",
  },
  {
    id: 3315,
    comune: "ISOLA DELLE FEMMINE",
    sigla: "PA",
    codice_elettorale: 4190550410,
    codice_istat: 82043,
    codice_belfiore: "E350",
  },
  {
    id: 3316,
    comune: "ISOLA DI CAPO RIZZUTO",
    sigla: "KR",
    codice_elettorale: 3180970130,
    codice_istat: 101013,
    codice_belfiore: "E339",
  },
  {
    id: 3317,
    comune: "ISOLA DI FONDRA",
    sigla: "BG",
    codice_elettorale: 1030121160,
    codice_istat: 16121,
    codice_belfiore: "E353",
  },
  {
    id: 3318,
    comune: "ISOLA DOVARESE",
    sigla: "CR",
    codice_elettorale: 1030260520,
    codice_istat: 19053,
    codice_belfiore: "E356",
  },
  {
    id: 3319,
    comune: "ISOLA RIZZA",
    sigla: "VR",
    codice_elettorale: 1050890410,
    codice_istat: 23041,
    codice_belfiore: "E358",
  },
  {
    id: 3320,
    comune: "ISOLA SANT'ANTONIO",
    sigla: "AL",
    codice_elettorale: 1010020850,
    codice_istat: 6087,
    codice_belfiore: "E360",
  },
  {
    id: 3321,
    comune: "ISOLA VICENTINA",
    sigla: "VI",
    codice_elettorale: 1050900480,
    codice_istat: 24048,
    codice_belfiore: "E354",
  },
  {
    id: 3322,
    comune: "ISOLABELLA",
    sigla: "TO",
    codice_elettorale: 1010811210,
    codice_istat: 1123,
    codice_belfiore: "E345",
  },
  {
    id: 3323,
    comune: "ISOLABONA",
    sigla: "IM",
    codice_elettorale: 1070370300,
    codice_istat: 8032,
    codice_belfiore: "E346",
  },
  {
    id: 3324,
    comune: "ISOLE TREMITI",
    sigla: "FG",
    codice_elettorale: 3160310250,
    codice_istat: 71026,
    codice_belfiore: "E363",
  },
  {
    id: 3325,
    comune: "ISORELLA",
    sigla: "BS",
    codice_elettorale: 1030150790,
    codice_istat: 17086,
    codice_belfiore: "E364",
  },
  {
    id: 3326,
    comune: "ISPANI",
    sigla: "SA",
    codice_elettorale: 3150720590,
    codice_istat: 65059,
    codice_belfiore: "E365",
  },
  {
    id: 3327,
    comune: "ISPICA",
    sigla: "RG",
    codice_elettorale: 4190650050,
    codice_istat: 88005,
    codice_belfiore: "E366",
  },
  {
    id: 3328,
    comune: "ISPRA",
    sigla: "VA",
    codice_elettorale: 1030860710,
    codice_istat: 12084,
    codice_belfiore: "E367",
  },
  {
    id: 3329,
    comune: "ISSIGLIO",
    sigla: "TO",
    codice_elettorale: 1010811220,
    codice_istat: 1124,
    codice_belfiore: "E368",
  },
  {
    id: 3330,
    comune: "ISSIME",
    sigla: "AO",
    codice_elettorale: 1020040350,
    codice_istat: 7036,
    codice_belfiore: "E369",
  },
  {
    id: 3331,
    comune: "ISSO",
    sigla: "BG",
    codice_elettorale: 1030121170,
    codice_istat: 16122,
    codice_belfiore: "E370",
  },
  {
    id: 3332,
    comune: "ISSOGNE",
    sigla: "AO",
    codice_elettorale: 1020040360,
    codice_istat: 7037,
    codice_belfiore: "E371",
  },
  {
    id: 3333,
    comune: "ISTRANA",
    sigla: "TV",
    codice_elettorale: 1050840340,
    codice_istat: 26035,
    codice_belfiore: "E373",
  },
  {
    id: 3334,
    comune: "ITALA",
    sigla: "ME",
    codice_elettorale: 4190480350,
    codice_istat: 83036,
    codice_belfiore: "E374",
  },
  {
    id: 3335,
    comune: "ITRI",
    sigla: "LT",
    codice_elettorale: 2120400100,
    codice_istat: 59010,
    codice_belfiore: "E375",
  },
  {
    id: 3336,
    comune: "ITTIREDDU",
    sigla: "SS",
    codice_elettorale: 4200730320,
    codice_istat: 90032,
    codice_belfiore: "E376",
  },
  {
    id: 3337,
    comune: "ITTIRI",
    sigla: "SS",
    codice_elettorale: 4200730330,
    codice_istat: 90033,
    codice_belfiore: "E377",
  },
  {
    id: 3338,
    comune: "IVREA",
    sigla: "TO",
    codice_elettorale: 1010811230,
    codice_istat: 1125,
    codice_belfiore: "E379",
  },
  {
    id: 3339,
    comune: "IZANO",
    sigla: "CR",
    codice_elettorale: 1030260530,
    codice_istat: 19054,
    codice_belfiore: "E380",
  },
  {
    id: 3340,
    comune: "JACURSO",
    sigla: "CZ",
    codice_elettorale: 3180220630,
    codice_istat: 79065,
    codice_belfiore: "E274",
  },
  {
    id: 3341,
    comune: "JELSI",
    sigla: "CB",
    codice_elettorale: 3140190300,
    codice_istat: 70030,
    codice_belfiore: "E381",
  },
  {
    id: 3342,
    comune: "JENNE",
    sigla: "RM",
    codice_elettorale: 2120700480,
    codice_istat: 58048,
    codice_belfiore: "E382",
  },
  {
    id: 3343,
    comune: "JERAGO CON ORAGO",
    sigla: "VA",
    codice_elettorale: 1030860720,
    codice_istat: 12085,
    codice_belfiore: "E386",
  },
  {
    id: 3344,
    comune: "JERZU",
    sigla: "NU",
    codice_elettorale: 4200530330,
    codice_istat: 91035,
    codice_belfiore: "E387",
  },
  {
    id: 3345,
    comune: "JESI",
    sigla: "AN",
    codice_elettorale: 2110030210,
    codice_istat: 42021,
    codice_belfiore: "E388",
  },
  {
    id: 3346,
    comune: "JESOLO",
    sigla: "VE",
    codice_elettorale: 1050870190,
    codice_istat: 27019,
    codice_belfiore: "C388",
  },
  {
    id: 3347,
    comune: "JOLANDA DI SAVOIA",
    sigla: "FE",
    codice_elettorale: 1080290100,
    codice_istat: 38010,
    codice_belfiore: "E320",
  },
  {
    id: 3348,
    comune: "JOPPOLO",
    sigla: "VV",
    codice_elettorale: 3181030180,
    codice_istat: 102018,
    codice_belfiore: "E389",
  },
  {
    id: 3349,
    comune: "JOPPOLO GIANCAXIO",
    sigla: "AG",
    codice_elettorale: 4190010190,
    codice_istat: 84019,
    codice_belfiore: "E390",
  },
  {
    id: 3350,
    comune: "JOVENCAN",
    sigla: "AO",
    codice_elettorale: 1020040370,
    codice_istat: 7038,
    codice_belfiore: "E391",
  },
  {
    id: 3351,
    comune: "L'AQUILA",
    sigla: "AQ",
    codice_elettorale: 3130380490,
    codice_istat: 66049,
    codice_belfiore: "A345",
  },
  {
    id: 3352,
    comune: "LA CASSA",
    sigla: "TO",
    codice_elettorale: 1010811240,
    codice_istat: 1126,
    codice_belfiore: "E394",
  },
  {
    id: 3353,
    comune: "LA LOGGIA",
    sigla: "TO",
    codice_elettorale: 1010811250,
    codice_istat: 1127,
    codice_belfiore: "E423",
  },
  {
    id: 3354,
    comune: "LA MADDALENA",
    sigla: "SS",
    codice_elettorale: 4200730350,
    codice_istat: 90035,
    codice_belfiore: "E425",
  },
  {
    id: 3355,
    comune: "LA MAGDELEINE",
    sigla: "AO",
    codice_elettorale: 1020040380,
    codice_istat: 7039,
    codice_belfiore: "A308",
  },
  {
    id: 3356,
    comune: "LA MORRA",
    sigla: "CN",
    codice_elettorale: 1010271050,
    codice_istat: 4105,
    codice_belfiore: "E430",
  },
  {
    id: 3357,
    comune: "LA SALLE",
    sigla: "AO",
    codice_elettorale: 1020040390,
    codice_istat: 7040,
    codice_belfiore: "E458",
  },
  {
    id: 3358,
    comune: "LA SPEZIA",
    sigla: "SP",
    codice_elettorale: 1070390150,
    codice_istat: 11015,
    codice_belfiore: "E463",
  },
  {
    id: 3359,
    comune: "LA THUILE",
    sigla: "AO",
    codice_elettorale: 1020040400,
    codice_istat: 7041,
    codice_belfiore: "E470",
  },
  {
    id: 3360,
    comune: "LA VALLE AGORDINA",
    sigla: "BL",
    codice_elettorale: 1050100270,
    codice_istat: 25027,
    codice_belfiore: "E490",
  },
  {
    id: 3361,
    comune: "LA VALLE/WENGEN",
    sigla: "BZ",
    codice_elettorale: 1040140401,
    codice_istat: 21117,
    codice_belfiore: "E491",
  },
  {
    id: 3362,
    comune: "LA VALLETTA BRIANZA",
    sigla: "LC",
    codice_elettorale: 1030980415,
    codice_istat: 97092,
    codice_belfiore: "M348",
  },
  {
    id: 3363,
    comune: "LABICO",
    sigla: "RM",
    codice_elettorale: 2120700490,
    codice_istat: 58049,
    codice_belfiore: "E392",
  },
  {
    id: 3364,
    comune: "LABRO",
    sigla: "RI",
    codice_elettorale: 2120690300,
    codice_istat: 57032,
    codice_belfiore: "E393",
  },
  {
    id: 3365,
    comune: "LACCHIARELLA",
    sigla: "MI",
    codice_elettorale: 1030491140,
    codice_istat: 15115,
    codice_belfiore: "E395",
  },
  {
    id: 3366,
    comune: "LACCO AMENO",
    sigla: "NA",
    codice_elettorale: 3150510380,
    codice_istat: 63038,
    codice_belfiore: "E396",
  },
  {
    id: 3367,
    comune: "LACEDONIA",
    sigla: "AV",
    codice_elettorale: 3150080410,
    codice_istat: 64041,
    codice_belfiore: "E397",
  },
  {
    id: 3368,
    comune: "LACES/LATSCH",
    sigla: "BZ",
    codice_elettorale: 1040140340,
    codice_istat: 21037,
    codice_belfiore: "E398",
  },
  {
    id: 3369,
    comune: "LACONI",
    sigla: "OR",
    codice_elettorale: 4200950246,
    codice_istat: 95082,
    codice_belfiore: "E400",
  },
  {
    id: 3370,
    comune: "LADISPOLI",
    sigla: "RM",
    codice_elettorale: 2120700491,
    codice_istat: 58116,
    codice_belfiore: "M212",
  },
  {
    id: 3371,
    comune: "LAERRU",
    sigla: "SS",
    codice_elettorale: 4200730340,
    codice_istat: 90034,
    codice_belfiore: "E401",
  },
  {
    id: 3372,
    comune: "LAGANADI",
    sigla: "RC",
    codice_elettorale: 3180670410,
    codice_istat: 80041,
    codice_belfiore: "E402",
  },
  {
    id: 3373,
    comune: "LAGHI",
    sigla: "VI",
    codice_elettorale: 1050900490,
    codice_istat: 24049,
    codice_belfiore: "E403",
  },
  {
    id: 3374,
    comune: "LAGLIO",
    sigla: "CO",
    codice_elettorale: 1030241120,
    codice_istat: 13119,
    codice_belfiore: "E405",
  },
  {
    id: 3375,
    comune: "LAGNASCO",
    sigla: "CN",
    codice_elettorale: 1010271040,
    codice_istat: 4104,
    codice_belfiore: "E406",
  },
  {
    id: 3376,
    comune: "LAGO",
    sigla: "CS",
    codice_elettorale: 3180250630,
    codice_istat: 78062,
    codice_belfiore: "E407",
  },
  {
    id: 3377,
    comune: "LAGONEGRO",
    sigla: "PZ",
    codice_elettorale: 3170640380,
    codice_istat: 76039,
    codice_belfiore: "E409",
  },
  {
    id: 3378,
    comune: "LAGOSANTO",
    sigla: "FE",
    codice_elettorale: 1080290110,
    codice_istat: 38011,
    codice_belfiore: "E410",
  },
  {
    id: 3379,
    comune: "LAGUNDO/ALGUND",
    sigla: "BZ",
    codice_elettorale: 1040140350,
    codice_istat: 21038,
    codice_belfiore: "E412",
  },
  {
    id: 3380,
    comune: "LAIGUEGLIA",
    sigla: "SV",
    codice_elettorale: 1070740330,
    codice_istat: 9033,
    codice_belfiore: "E414",
  },
  {
    id: 3381,
    comune: "LAINATE",
    sigla: "MI",
    codice_elettorale: 1030491150,
    codice_istat: 15116,
    codice_belfiore: "E415",
  },
  {
    id: 3382,
    comune: "LAINO",
    sigla: "CO",
    codice_elettorale: 1030241130,
    codice_istat: 13120,
    codice_belfiore: "E416",
  },
  {
    id: 3383,
    comune: "LAINO BORGO",
    sigla: "CS",
    codice_elettorale: 3180250640,
    codice_istat: 78063,
    codice_belfiore: "E417",
  },
  {
    id: 3384,
    comune: "LAINO CASTELLO",
    sigla: "CS",
    codice_elettorale: 3180250650,
    codice_istat: 78064,
    codice_belfiore: "E419",
  },
  {
    id: 3385,
    comune: "LAION/LAJEN",
    sigla: "BZ",
    codice_elettorale: 1040140360,
    codice_istat: 21039,
    codice_belfiore: "E420",
  },
  {
    id: 3386,
    comune: "LAIVES/LEIFERS",
    sigla: "BZ",
    codice_elettorale: 1040140370,
    codice_istat: 21040,
    codice_belfiore: "E421",
  },
  {
    id: 3387,
    comune: "LAJATICO",
    sigla: "PI",
    codice_elettorale: 2090620160,
    codice_istat: 50016,
    codice_belfiore: "E413",
  },
  {
    id: 3388,
    comune: "LALLIO",
    sigla: "BG",
    codice_elettorale: 1030121180,
    codice_istat: 16123,
    codice_belfiore: "E422",
  },
  {
    id: 3389,
    comune: "LAMA DEI PELIGNI",
    sigla: "CH",
    codice_elettorale: 3130230450,
    codice_istat: 69045,
    codice_belfiore: "E424",
  },
  {
    id: 3390,
    comune: "LAMA MOCOGNO",
    sigla: "MO",
    codice_elettorale: 1080500180,
    codice_istat: 36018,
    codice_belfiore: "E426",
  },
  {
    id: 3391,
    comune: "LAMBRUGO",
    sigla: "CO",
    codice_elettorale: 1030241140,
    codice_istat: 13121,
    codice_belfiore: "E428",
  },
  {
    id: 3392,
    comune: "LAMEZIA TERME",
    sigla: "CZ",
    codice_elettorale: 3180220641,
    codice_istat: 79160,
    codice_belfiore: "M208",
  },
  {
    id: 3393,
    comune: "LAMON",
    sigla: "BL",
    codice_elettorale: 1050100260,
    codice_istat: 25026,
    codice_belfiore: "E429",
  },
  {
    id: 3394,
    comune: "LAMPEDUSA E LINOSA",
    sigla: "AG",
    codice_elettorale: 4190010200,
    codice_istat: 84020,
    codice_belfiore: "E431",
  },
  {
    id: 3395,
    comune: "LAMPORECCHIO",
    sigla: "PT",
    codice_elettorale: 2090630050,
    codice_istat: 47005,
    codice_belfiore: "E432",
  },
  {
    id: 3396,
    comune: "LAMPORO",
    sigla: "VC",
    codice_elettorale: 1010880670,
    codice_istat: 2067,
    codice_belfiore: "E433",
  },
  {
    id: 3397,
    comune: "LANA/LANA",
    sigla: "BZ",
    codice_elettorale: 1040140380,
    codice_istat: 21041,
    codice_belfiore: "E434",
  },
  {
    id: 3398,
    comune: "LANCIANO",
    sigla: "CH",
    codice_elettorale: 3130230460,
    codice_istat: 69046,
    codice_belfiore: "E435",
  },
  {
    id: 3399,
    comune: "LANDIONA",
    sigla: "NO",
    codice_elettorale: 1010520800,
    codice_istat: 3083,
    codice_belfiore: "E436",
  },
  {
    id: 3400,
    comune: "LANDRIANO",
    sigla: "PV",
    codice_elettorale: 1030570750,
    codice_istat: 18078,
    codice_belfiore: "E437",
  },
  {
    id: 3401,
    comune: "LANGHIRANO",
    sigla: "PR",
    codice_elettorale: 1080560180,
    codice_istat: 34018,
    codice_belfiore: "E438",
  },
  {
    id: 3402,
    comune: "LANGOSCO",
    sigla: "PV",
    codice_elettorale: 1030570760,
    codice_istat: 18079,
    codice_belfiore: "E439",
  },
  {
    id: 3403,
    comune: "LANUSEI",
    sigla: "NU",
    codice_elettorale: 4200530350,
    codice_istat: 91037,
    codice_belfiore: "E441",
  },
  {
    id: 3404,
    comune: "LANUVIO",
    sigla: "RM",
    codice_elettorale: 2120700500,
    codice_istat: 58050,
    codice_belfiore: "C767",
  },
  {
    id: 3405,
    comune: "LANZADA",
    sigla: "SO",
    codice_elettorale: 1030770360,
    codice_istat: 14036,
    codice_belfiore: "E443",
  },
  {
    id: 3406,
    comune: "LANZO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010811260,
    codice_istat: 1128,
    codice_belfiore: "E445",
  },
  {
    id: 3407,
    comune: "LAPEDONA",
    sigla: "FM",
    codice_elettorale: 2111050090,
    codice_istat: 109009,
    codice_belfiore: "E447",
  },
  {
    id: 3408,
    comune: "LAPIO",
    sigla: "AV",
    codice_elettorale: 3150080420,
    codice_istat: 64042,
    codice_belfiore: "E448",
  },
  {
    id: 3409,
    comune: "LAPPANO",
    sigla: "CS",
    codice_elettorale: 3180250660,
    codice_istat: 78065,
    codice_belfiore: "E450",
  },
  {
    id: 3410,
    comune: "LARCIANO",
    sigla: "PT",
    codice_elettorale: 2090630060,
    codice_istat: 47006,
    codice_belfiore: "E451",
  },
  {
    id: 3411,
    comune: "LARDIRAGO",
    sigla: "PV",
    codice_elettorale: 1030570770,
    codice_istat: 18080,
    codice_belfiore: "E454",
  },
  {
    id: 3412,
    comune: "LARIANO",
    sigla: "RM",
    codice_elettorale: 2120700501,
    codice_istat: 58115,
    codice_belfiore: "M207",
  },
  {
    id: 3413,
    comune: "LARINO",
    sigla: "CB",
    codice_elettorale: 3140190310,
    codice_istat: 70031,
    codice_belfiore: "E456",
  },
  {
    id: 3414,
    comune: "LAS PLASSAS",
    sigla: "SU",
    codice_elettorale: 4201110370,
    codice_istat: 111037,
    codice_belfiore: "E464",
  },
  {
    id: 3415,
    comune: "LASA/LAAS",
    sigla: "BZ",
    codice_elettorale: 1040140390,
    codice_istat: 21042,
    codice_belfiore: "E457",
  },
  {
    id: 3416,
    comune: "LASCARI",
    sigla: "PA",
    codice_elettorale: 4190550420,
    codice_istat: 82044,
    codice_belfiore: "E459",
  },
  {
    id: 3417,
    comune: "LASNIGO",
    sigla: "CO",
    codice_elettorale: 1030241160,
    codice_istat: 13123,
    codice_belfiore: "E462",
  },
  {
    id: 3418,
    comune: "LASTEBASSE",
    sigla: "VI",
    codice_elettorale: 1050900500,
    codice_istat: 24050,
    codice_belfiore: "E465",
  },
  {
    id: 3419,
    comune: "LASTRA A SIGNA",
    sigla: "FI",
    codice_elettorale: 2090300240,
    codice_istat: 48024,
    codice_belfiore: "E466",
  },
  {
    id: 3420,
    comune: "LATERA",
    sigla: "VT",
    codice_elettorale: 2120910310,
    codice_istat: 56032,
    codice_belfiore: "E467",
  },
  {
    id: 3421,
    comune: "LATERINA PERGINE VALDARNO",
    sigla: "AR",
    codice_elettorale: 2090050191,
    codice_istat: 51042,
    codice_belfiore: "M392",
  },
  {
    id: 3422,
    comune: "LATERZA",
    sigla: "TA",
    codice_elettorale: 3160780090,
    codice_istat: 73009,
    codice_belfiore: "E469",
  },
  {
    id: 3423,
    comune: "LATIANO",
    sigla: "BR",
    codice_elettorale: 3160160090,
    codice_istat: 74009,
    codice_belfiore: "E471",
  },
  {
    id: 3424,
    comune: "LATINA",
    sigla: "LT",
    codice_elettorale: 2120400110,
    codice_istat: 59011,
    codice_belfiore: "E472",
  },
  {
    id: 3425,
    comune: "LATISANA",
    sigla: "UD",
    codice_elettorale: 1060850460,
    codice_istat: 30046,
    codice_belfiore: "E473",
  },
  {
    id: 3426,
    comune: "LATRONICO",
    sigla: "PZ",
    codice_elettorale: 3170640390,
    codice_istat: 76040,
    codice_belfiore: "E474",
  },
  {
    id: 3427,
    comune: "LATTARICO",
    sigla: "CS",
    codice_elettorale: 3180250670,
    codice_istat: 78066,
    codice_belfiore: "E475",
  },
  {
    id: 3428,
    comune: "LAUCO",
    sigla: "UD",
    codice_elettorale: 1060850470,
    codice_istat: 30047,
    codice_belfiore: "E476",
  },
  {
    id: 3429,
    comune: "LAUREANA CILENTO",
    sigla: "SA",
    codice_elettorale: 3150720600,
    codice_istat: 65060,
    codice_belfiore: "E480",
  },
  {
    id: 3430,
    comune: "LAUREANA DI BORRELLO",
    sigla: "RC",
    codice_elettorale: 3180670420,
    codice_istat: 80042,
    codice_belfiore: "E479",
  },
  {
    id: 3431,
    comune: "LAUREGNO/LAUREIN",
    sigla: "BZ",
    codice_elettorale: 1040140400,
    codice_istat: 21043,
    codice_belfiore: "E481",
  },
  {
    id: 3432,
    comune: "LAURENZANA",
    sigla: "PZ",
    codice_elettorale: 3170640400,
    codice_istat: 76041,
    codice_belfiore: "E482",
  },
  {
    id: 3433,
    comune: "LAURIA",
    sigla: "PZ",
    codice_elettorale: 3170640410,
    codice_istat: 76042,
    codice_belfiore: "E483",
  },
  {
    id: 3434,
    comune: "LAURIANO",
    sigla: "TO",
    codice_elettorale: 1010811270,
    codice_istat: 1129,
    codice_belfiore: "E484",
  },
  {
    id: 3435,
    comune: "LAURINO",
    sigla: "SA",
    codice_elettorale: 3150720610,
    codice_istat: 65061,
    codice_belfiore: "E485",
  },
  {
    id: 3436,
    comune: "LAURITO",
    sigla: "SA",
    codice_elettorale: 3150720620,
    codice_istat: 65062,
    codice_belfiore: "E486",
  },
  {
    id: 3437,
    comune: "LAURO",
    sigla: "AV",
    codice_elettorale: 3150080430,
    codice_istat: 64043,
    codice_belfiore: "E487",
  },
  {
    id: 3438,
    comune: "LAVAGNA",
    sigla: "GE",
    codice_elettorale: 1070340280,
    codice_istat: 10028,
    codice_belfiore: "E488",
  },
  {
    id: 3439,
    comune: "LAVAGNO",
    sigla: "VR",
    codice_elettorale: 1050890420,
    codice_istat: 23042,
    codice_belfiore: "E489",
  },
  {
    id: 3440,
    comune: "LAVARONE",
    sigla: "TN",
    codice_elettorale: 1040830950,
    codice_istat: 22102,
    codice_belfiore: "E492",
  },
  {
    id: 3441,
    comune: "LAVELLO",
    sigla: "PZ",
    codice_elettorale: 3170640420,
    codice_istat: 76043,
    codice_belfiore: "E493",
  },
  {
    id: 3442,
    comune: "LAVENA PONTE TRESA",
    sigla: "VA",
    codice_elettorale: 1030860730,
    codice_istat: 12086,
    codice_belfiore: "E494",
  },
  {
    id: 3443,
    comune: "LAVENO-MOMBELLO",
    sigla: "VA",
    codice_elettorale: 1030860740,
    codice_istat: 12087,
    codice_belfiore: "E496",
  },
  {
    id: 3444,
    comune: "LAVENONE",
    sigla: "BS",
    codice_elettorale: 1030150800,
    codice_istat: 17087,
    codice_belfiore: "E497",
  },
  {
    id: 3445,
    comune: "LAVIANO",
    sigla: "SA",
    codice_elettorale: 3150720630,
    codice_istat: 65063,
    codice_belfiore: "E498",
  },
  {
    id: 3446,
    comune: "LAVIS",
    sigla: "TN",
    codice_elettorale: 1040830960,
    codice_istat: 22103,
    codice_belfiore: "E500",
  },
  {
    id: 3447,
    comune: "LAZISE",
    sigla: "VR",
    codice_elettorale: 1050890430,
    codice_istat: 23043,
    codice_belfiore: "E502",
  },
  {
    id: 3448,
    comune: "LAZZATE",
    sigla: "MB",
    codice_elettorale: 1031040250,
    codice_istat: 108025,
    codice_belfiore: "E504",
  },
  {
    id: 3449,
    comune: "LECCE",
    sigla: "LE",
    codice_elettorale: 3160410340,
    codice_istat: 75035,
    codice_belfiore: "E506",
  },
  {
    id: 3450,
    comune: "LECCE NEI MARSI",
    sigla: "AQ",
    codice_elettorale: 3130380500,
    codice_istat: 66050,
    codice_belfiore: "E505",
  },
  {
    id: 3451,
    comune: "LECCO",
    sigla: "LC",
    codice_elettorale: 1030980420,
    codice_istat: 97042,
    codice_belfiore: "E507",
  },
  {
    id: 3452,
    comune: "LEDRO",
    sigla: "TN",
    codice_elettorale: 1040830965,
    codice_istat: 22229,
    codice_belfiore: "M313",
  },
  {
    id: 3453,
    comune: "LEFFE",
    sigla: "BG",
    codice_elettorale: 1030121190,
    codice_istat: 16124,
    codice_belfiore: "E509",
  },
  {
    id: 3454,
    comune: "LEGGIUNO",
    sigla: "VA",
    codice_elettorale: 1030860750,
    codice_istat: 12088,
    codice_belfiore: "E510",
  },
  {
    id: 3455,
    comune: "LEGNAGO",
    sigla: "VR",
    codice_elettorale: 1050890440,
    codice_istat: 23044,
    codice_belfiore: "E512",
  },
  {
    id: 3456,
    comune: "LEGNANO",
    sigla: "MI",
    codice_elettorale: 1030491170,
    codice_istat: 15118,
    codice_belfiore: "E514",
  },
  {
    id: 3457,
    comune: "LEGNARO",
    sigla: "PD",
    codice_elettorale: 1050540440,
    codice_istat: 28044,
    codice_belfiore: "E515",
  },
  {
    id: 3458,
    comune: "LEI",
    sigla: "NU",
    codice_elettorale: 4200530360,
    codice_istat: 91038,
    codice_belfiore: "E517",
  },
  {
    id: 3459,
    comune: "LEINI",
    sigla: "TO",
    codice_elettorale: 1010811280,
    codice_istat: 1130,
    codice_belfiore: "E518",
  },
  {
    id: 3460,
    comune: "LEIVI",
    sigla: "GE",
    codice_elettorale: 1070340290,
    codice_istat: 10029,
    codice_belfiore: "E519",
  },
  {
    id: 3461,
    comune: "LEMIE",
    sigla: "TO",
    codice_elettorale: 1010811290,
    codice_istat: 1131,
    codice_belfiore: "E520",
  },
  {
    id: 3462,
    comune: "LENDINARA",
    sigla: "RO",
    codice_elettorale: 1050710290,
    codice_istat: 29029,
    codice_belfiore: "E522",
  },
  {
    id: 3463,
    comune: "LENI",
    sigla: "ME",
    codice_elettorale: 4190480360,
    codice_istat: 83037,
    codice_belfiore: "E523",
  },
  {
    id: 3464,
    comune: "LENNA",
    sigla: "BG",
    codice_elettorale: 1030121191,
    codice_istat: 16125,
    codice_belfiore: "E524",
  },
  {
    id: 3465,
    comune: "LENO",
    sigla: "BS",
    codice_elettorale: 1030150810,
    codice_istat: 17088,
    codice_belfiore: "E526",
  },
  {
    id: 3466,
    comune: "LENOLA",
    sigla: "LT",
    codice_elettorale: 2120400120,
    codice_istat: 59012,
    codice_belfiore: "E527",
  },
  {
    id: 3467,
    comune: "LENTA",
    sigla: "VC",
    codice_elettorale: 1010880680,
    codice_istat: 2068,
    codice_belfiore: "E528",
  },
  {
    id: 3468,
    comune: "LENTATE SUL SEVESO",
    sigla: "MB",
    codice_elettorale: 1031040255,
    codice_istat: 108054,
    codice_belfiore: "E530",
  },
  {
    id: 3469,
    comune: "LENTELLA",
    sigla: "CH",
    codice_elettorale: 3130230470,
    codice_istat: 69047,
    codice_belfiore: "E531",
  },
  {
    id: 3470,
    comune: "LENTINI",
    sigla: "SR",
    codice_elettorale: 4190760110,
    codice_istat: 89011,
    codice_belfiore: "E532",
  },
  {
    id: 3471,
    comune: "LEONESSA",
    sigla: "RI",
    codice_elettorale: 2120690310,
    codice_istat: 57033,
    codice_belfiore: "E535",
  },
  {
    id: 3472,
    comune: "LEONFORTE",
    sigla: "EN",
    codice_elettorale: 4190280110,
    codice_istat: 86011,
    codice_belfiore: "E536",
  },
  {
    id: 3473,
    comune: "LEPORANO",
    sigla: "TA",
    codice_elettorale: 3160780100,
    codice_istat: 73010,
    codice_belfiore: "E537",
  },
  {
    id: 3474,
    comune: "LEQUILE",
    sigla: "LE",
    codice_elettorale: 3160410350,
    codice_istat: 75036,
    codice_belfiore: "E538",
  },
  {
    id: 3475,
    comune: "LEQUIO BERRIA",
    sigla: "CN",
    codice_elettorale: 1010271060,
    codice_istat: 4106,
    codice_belfiore: "E540",
  },
  {
    id: 3476,
    comune: "LEQUIO TANARO",
    sigla: "CN",
    codice_elettorale: 1010271070,
    codice_istat: 4107,
    codice_belfiore: "E539",
  },
  {
    id: 3477,
    comune: "LERCARA FRIDDI",
    sigla: "PA",
    codice_elettorale: 4190550430,
    codice_istat: 82045,
    codice_belfiore: "E541",
  },
  {
    id: 3478,
    comune: "LERICI",
    sigla: "SP",
    codice_elettorale: 1070390160,
    codice_istat: 11016,
    codice_belfiore: "E542",
  },
  {
    id: 3479,
    comune: "LERMA",
    sigla: "AL",
    codice_elettorale: 1010020860,
    codice_istat: 6088,
    codice_belfiore: "E543",
  },
  {
    id: 3480,
    comune: "LESA",
    sigla: "NO",
    codice_elettorale: 1010520810,
    codice_istat: 3084,
    codice_belfiore: "E544",
  },
  {
    id: 3481,
    comune: "LESEGNO",
    sigla: "CN",
    codice_elettorale: 1010271080,
    codice_istat: 4108,
    codice_belfiore: "E546",
  },
  {
    id: 3482,
    comune: "LESIGNANO DE' BAGNI",
    sigla: "PR",
    codice_elettorale: 1080560190,
    codice_istat: 34019,
    codice_belfiore: "E547",
  },
  {
    id: 3483,
    comune: "LESINA",
    sigla: "FG",
    codice_elettorale: 3160310260,
    codice_istat: 71027,
    codice_belfiore: "E549",
  },
  {
    id: 3484,
    comune: "LESMO",
    sigla: "MB",
    codice_elettorale: 1031040260,
    codice_istat: 108026,
    codice_belfiore: "E550",
  },
  {
    id: 3485,
    comune: "LESSOLO",
    sigla: "TO",
    codice_elettorale: 1010811300,
    codice_istat: 1132,
    codice_belfiore: "E551",
  },
  {
    id: 3486,
    comune: "LESSONA",
    sigla: "BI",
    codice_elettorale: 1010960291,
    codice_istat: 96085,
    codice_belfiore: "M371",
  },
  {
    id: 3487,
    comune: "LESTIZZA",
    sigla: "UD",
    codice_elettorale: 1060850480,
    codice_istat: 30048,
    codice_belfiore: "E553",
  },
  {
    id: 3488,
    comune: "LETINO",
    sigla: "CE",
    codice_elettorale: 3150200440,
    codice_istat: 61044,
    codice_belfiore: "E554",
  },
  {
    id: 3489,
    comune: "LETOJANNI",
    sigla: "ME",
    codice_elettorale: 4190480370,
    codice_istat: 83038,
    codice_belfiore: "E555",
  },
  {
    id: 3490,
    comune: "LETTERE",
    sigla: "NA",
    codice_elettorale: 3150510390,
    codice_istat: 63039,
    codice_belfiore: "E557",
  },
  {
    id: 3491,
    comune: "LETTOMANOPPELLO",
    sigla: "PE",
    codice_elettorale: 3130600200,
    codice_istat: 68020,
    codice_belfiore: "E558",
  },
  {
    id: 3492,
    comune: "LETTOPALENA",
    sigla: "CH",
    codice_elettorale: 3130230480,
    codice_istat: 69048,
    codice_belfiore: "E559",
  },
  {
    id: 3493,
    comune: "LEVANTO",
    sigla: "SP",
    codice_elettorale: 1070390170,
    codice_istat: 11017,
    codice_belfiore: "E560",
  },
  {
    id: 3494,
    comune: "LEVATE",
    sigla: "BG",
    codice_elettorale: 1030121200,
    codice_istat: 16126,
    codice_belfiore: "E562",
  },
  {
    id: 3495,
    comune: "LEVERANO",
    sigla: "LE",
    codice_elettorale: 3160410360,
    codice_istat: 75037,
    codice_belfiore: "E563",
  },
  {
    id: 3496,
    comune: "LEVICE",
    sigla: "CN",
    codice_elettorale: 1010271090,
    codice_istat: 4109,
    codice_belfiore: "E564",
  },
  {
    id: 3497,
    comune: "LEVICO TERME",
    sigla: "TN",
    codice_elettorale: 1040830970,
    codice_istat: 22104,
    codice_belfiore: "E565",
  },
  {
    id: 3498,
    comune: "LEVONE",
    sigla: "TO",
    codice_elettorale: 1010811310,
    codice_istat: 1133,
    codice_belfiore: "E566",
  },
  {
    id: 3499,
    comune: "LEZZENO",
    sigla: "CO",
    codice_elettorale: 1030241190,
    codice_istat: 13126,
    codice_belfiore: "E569",
  },
  {
    id: 3500,
    comune: "LIBERI",
    sigla: "CE",
    codice_elettorale: 3150200450,
    codice_istat: 61045,
    codice_belfiore: "E570",
  },
  {
    id: 3501,
    comune: "LIBRIZZI",
    sigla: "ME",
    codice_elettorale: 4190480380,
    codice_istat: 83039,
    codice_belfiore: "E571",
  },
  {
    id: 3502,
    comune: "LICATA",
    sigla: "AG",
    codice_elettorale: 4190010210,
    codice_istat: 84021,
    codice_belfiore: "E573",
  },
  {
    id: 3503,
    comune: "LICCIANA NARDI",
    sigla: "MS",
    codice_elettorale: 2090460090,
    codice_istat: 45009,
    codice_belfiore: "E574",
  },
  {
    id: 3504,
    comune: "LICENZA",
    sigla: "RM",
    codice_elettorale: 2120700510,
    codice_istat: 58051,
    codice_belfiore: "E576",
  },
  {
    id: 3505,
    comune: "LICODIA EUBEA",
    sigla: "CT",
    codice_elettorale: 4190210200,
    codice_istat: 87020,
    codice_belfiore: "E578",
  },
  {
    id: 3506,
    comune: "LIERNA",
    sigla: "LC",
    codice_elettorale: 1030980430,
    codice_istat: 97043,
    codice_belfiore: "E581",
  },
  {
    id: 3507,
    comune: "LIGNANA",
    sigla: "VC",
    codice_elettorale: 1010880700,
    codice_istat: 2070,
    codice_belfiore: "E583",
  },
  {
    id: 3508,
    comune: "LIGNANO SABBIADORO",
    sigla: "UD",
    codice_elettorale: 1060850490,
    codice_istat: 30049,
    codice_belfiore: "E584",
  },
  {
    id: 3509,
    comune: "LILLIANES",
    sigla: "AO",
    codice_elettorale: 1020040410,
    codice_istat: 7042,
    codice_belfiore: "E587",
  },
  {
    id: 3510,
    comune: "LIMANA",
    sigla: "BL",
    codice_elettorale: 1050100290,
    codice_istat: 25029,
    codice_belfiore: "E588",
  },
  {
    id: 3511,
    comune: "LIMATOLA",
    sigla: "BN",
    codice_elettorale: 3150110370,
    codice_istat: 62038,
    codice_belfiore: "E589",
  },
  {
    id: 3512,
    comune: "LIMBADI",
    sigla: "VV",
    codice_elettorale: 3181030190,
    codice_istat: 102019,
    codice_belfiore: "E590",
  },
  {
    id: 3513,
    comune: "LIMBIATE",
    sigla: "MB",
    codice_elettorale: 1031040270,
    codice_istat: 108027,
    codice_belfiore: "E591",
  },
  {
    id: 3514,
    comune: "LIMENA",
    sigla: "PD",
    codice_elettorale: 1050540450,
    codice_istat: 28045,
    codice_belfiore: "E592",
  },
  {
    id: 3515,
    comune: "LIMIDO COMASCO",
    sigla: "CO",
    codice_elettorale: 1030241220,
    codice_istat: 13128,
    codice_belfiore: "E593",
  },
  {
    id: 3516,
    comune: "LIMINA",
    sigla: "ME",
    codice_elettorale: 4190480390,
    codice_istat: 83040,
    codice_belfiore: "E594",
  },
  {
    id: 3517,
    comune: "LIMONE PIEMONTE",
    sigla: "CN",
    codice_elettorale: 1010271100,
    codice_istat: 4110,
    codice_belfiore: "E597",
  },
  {
    id: 3518,
    comune: "LIMONE SUL GARDA",
    sigla: "BS",
    codice_elettorale: 1030150820,
    codice_istat: 17089,
    codice_belfiore: "E596",
  },
  {
    id: 3519,
    comune: "LIMOSANO",
    sigla: "CB",
    codice_elettorale: 3140190320,
    codice_istat: 70032,
    codice_belfiore: "E599",
  },
  {
    id: 3520,
    comune: "LINAROLO",
    sigla: "PV",
    codice_elettorale: 1030570780,
    codice_istat: 18081,
    codice_belfiore: "E600",
  },
  {
    id: 3521,
    comune: "LINGUAGLOSSA",
    sigla: "CT",
    codice_elettorale: 4190210210,
    codice_istat: 87021,
    codice_belfiore: "E602",
  },
  {
    id: 3522,
    comune: "LIONI",
    sigla: "AV",
    codice_elettorale: 3150080440,
    codice_istat: 64044,
    codice_belfiore: "E605",
  },
  {
    id: 3523,
    comune: "LIPARI",
    sigla: "ME",
    codice_elettorale: 4190480400,
    codice_istat: 83041,
    codice_belfiore: "E606",
  },
  {
    id: 3524,
    comune: "LIPOMO",
    sigla: "CO",
    codice_elettorale: 1030241230,
    codice_istat: 13129,
    codice_belfiore: "E607",
  },
  {
    id: 3525,
    comune: "LIRIO",
    sigla: "PV",
    codice_elettorale: 1030570790,
    codice_istat: 18082,
    codice_belfiore: "E608",
  },
  {
    id: 3526,
    comune: "LISCATE",
    sigla: "MI",
    codice_elettorale: 1030491210,
    codice_istat: 15122,
    codice_belfiore: "E610",
  },
  {
    id: 3527,
    comune: "LISCIA",
    sigla: "CH",
    codice_elettorale: 3130230490,
    codice_istat: 69049,
    codice_belfiore: "E611",
  },
  {
    id: 3528,
    comune: "LISCIANO NICCONE",
    sigla: "PG",
    codice_elettorale: 2100580250,
    codice_istat: 54025,
    codice_belfiore: "E613",
  },
  {
    id: 3529,
    comune: "LISIO",
    sigla: "CN",
    codice_elettorale: 1010271110,
    codice_istat: 4111,
    codice_belfiore: "E615",
  },
  {
    id: 3530,
    comune: "LISSONE",
    sigla: "MB",
    codice_elettorale: 1031040280,
    codice_istat: 108028,
    codice_belfiore: "E617",
  },
  {
    id: 3531,
    comune: "LIVERI",
    sigla: "NA",
    codice_elettorale: 3150510400,
    codice_istat: 63040,
    codice_belfiore: "E620",
  },
  {
    id: 3532,
    comune: "LIVIGNO",
    sigla: "SO",
    codice_elettorale: 1030770370,
    codice_istat: 14037,
    codice_belfiore: "E621",
  },
  {
    id: 3533,
    comune: "LIVINALLONGO DEL COL DI LANA",
    sigla: "BL",
    codice_elettorale: 1050100300,
    codice_istat: 25030,
    codice_belfiore: "E622",
  },
  {
    id: 3534,
    comune: "LIVO",
    sigla: "TN",
    codice_elettorale: 1040830990,
    codice_istat: 22106,
    codice_belfiore: "E624",
  },
  {
    id: 3535,
    comune: "LIVO",
    sigla: "CO",
    codice_elettorale: 1030241240,
    codice_istat: 13130,
    codice_belfiore: "E623",
  },
  {
    id: 3536,
    comune: "LIVORNO",
    sigla: "LI",
    codice_elettorale: 2090420090,
    codice_istat: 49009,
    codice_belfiore: "E625",
  },
  {
    id: 3537,
    comune: "LIVORNO FERRARIS",
    sigla: "VC",
    codice_elettorale: 1010880710,
    codice_istat: 2071,
    codice_belfiore: "E626",
  },
  {
    id: 3538,
    comune: "LIVRAGA",
    sigla: "LO",
    codice_elettorale: 1030990300,
    codice_istat: 98030,
    codice_belfiore: "E627",
  },
  {
    id: 3539,
    comune: "LIZZANELLO",
    sigla: "LE",
    codice_elettorale: 3160410370,
    codice_istat: 75038,
    codice_belfiore: "E629",
  },
  {
    id: 3540,
    comune: "LIZZANO",
    sigla: "TA",
    codice_elettorale: 3160780110,
    codice_istat: 73011,
    codice_belfiore: "E630",
  },
  {
    id: 3541,
    comune: "LIZZANO IN BELVEDERE",
    sigla: "BO",
    codice_elettorale: 1080130330,
    codice_istat: 37033,
    codice_belfiore: "A771",
  },
  {
    id: 3542,
    comune: "LOANO",
    sigla: "SV",
    codice_elettorale: 1070740340,
    codice_istat: 9034,
    codice_belfiore: "E632",
  },
  {
    id: 3543,
    comune: "LOAZZOLO",
    sigla: "AT",
    codice_elettorale: 1010070600,
    codice_istat: 5060,
    codice_belfiore: "E633",
  },
  {
    id: 3544,
    comune: "LOCANA",
    sigla: "TO",
    codice_elettorale: 1010811320,
    codice_istat: 1134,
    codice_belfiore: "E635",
  },
  {
    id: 3545,
    comune: "LOCATE DI TRIULZI",
    sigla: "MI",
    codice_elettorale: 1030491240,
    codice_istat: 15125,
    codice_belfiore: "E639",
  },
  {
    id: 3546,
    comune: "LOCATE VARESINO",
    sigla: "CO",
    codice_elettorale: 1030241250,
    codice_istat: 13131,
    codice_belfiore: "E638",
  },
  {
    id: 3547,
    comune: "LOCATELLO",
    sigla: "BG",
    codice_elettorale: 1030121210,
    codice_istat: 16127,
    codice_belfiore: "E640",
  },
  {
    id: 3548,
    comune: "LOCERI",
    sigla: "NU",
    codice_elettorale: 4200530370,
    codice_istat: 91039,
    codice_belfiore: "E644",
  },
  {
    id: 3549,
    comune: "LOCOROTONDO",
    sigla: "BA",
    codice_elettorale: 3160090250,
    codice_istat: 72025,
    codice_belfiore: "E645",
  },
  {
    id: 3550,
    comune: "LOCRI",
    sigla: "RC",
    codice_elettorale: 3180670430,
    codice_istat: 80043,
    codice_belfiore: "D976",
  },
  {
    id: 3551,
    comune: "LOCULI",
    sigla: "NU",
    codice_elettorale: 4200530380,
    codice_istat: 91040,
    codice_belfiore: "E646",
  },
  {
    id: 3552,
    comune: "LODE'",
    sigla: "NU",
    codice_elettorale: 4200530390,
    codice_istat: 91041,
    codice_belfiore: "E647",
  },
  {
    id: 3553,
    comune: "LODI",
    sigla: "LO",
    codice_elettorale: 1030990310,
    codice_istat: 98031,
    codice_belfiore: "E648",
  },
  {
    id: 3554,
    comune: "LODI VECCHIO",
    sigla: "LO",
    codice_elettorale: 1030990320,
    codice_istat: 98032,
    codice_belfiore: "E651",
  },
  {
    id: 3555,
    comune: "LODINE",
    sigla: "NU",
    codice_elettorale: 4200530391,
    codice_istat: 91104,
    codice_belfiore: "E649",
  },
  {
    id: 3556,
    comune: "LODRINO",
    sigla: "BS",
    codice_elettorale: 1030150830,
    codice_istat: 17090,
    codice_belfiore: "E652",
  },
  {
    id: 3557,
    comune: "LOGRATO",
    sigla: "BS",
    codice_elettorale: 1030150840,
    codice_istat: 17091,
    codice_belfiore: "E654",
  },
  {
    id: 3558,
    comune: "LOIANO",
    sigla: "BO",
    codice_elettorale: 1080130340,
    codice_istat: 37034,
    codice_belfiore: "E655",
  },
  {
    id: 3559,
    comune: "LOIRI PORTO SAN PAOLO",
    sigla: "SS",
    codice_elettorale: 4200730351,
    codice_istat: 90084,
    codice_belfiore: "M275",
  },
  {
    id: 3560,
    comune: "LOMAGNA",
    sigla: "LC",
    codice_elettorale: 1030980440,
    codice_istat: 97044,
    codice_belfiore: "E656",
  },
  {
    id: 3561,
    comune: "LOMAZZO",
    sigla: "CO",
    codice_elettorale: 1030241270,
    codice_istat: 13133,
    codice_belfiore: "E659",
  },
  {
    id: 3562,
    comune: "LOMBARDORE",
    sigla: "TO",
    codice_elettorale: 1010811330,
    codice_istat: 1135,
    codice_belfiore: "E660",
  },
  {
    id: 3563,
    comune: "LOMBRIASCO",
    sigla: "TO",
    codice_elettorale: 1010811340,
    codice_istat: 1136,
    codice_belfiore: "E661",
  },
  {
    id: 3564,
    comune: "LOMELLO",
    sigla: "PV",
    codice_elettorale: 1030570800,
    codice_istat: 18083,
    codice_belfiore: "E662",
  },
  {
    id: 3565,
    comune: "LONA-LASES",
    sigla: "TN",
    codice_elettorale: 1040831010,
    codice_istat: 22108,
    codice_belfiore: "E664",
  },
  {
    id: 3566,
    comune: "LONATE CEPPINO",
    sigla: "VA",
    codice_elettorale: 1030860760,
    codice_istat: 12089,
    codice_belfiore: "E665",
  },
  {
    id: 3567,
    comune: "LONATE POZZOLO",
    sigla: "VA",
    codice_elettorale: 1030860770,
    codice_istat: 12090,
    codice_belfiore: "E666",
  },
  {
    id: 3568,
    comune: "LONATO DEL GARDA",
    sigla: "BS",
    codice_elettorale: 1030150850,
    codice_istat: 17092,
    codice_belfiore: "M312",
  },
  {
    id: 3569,
    comune: "LONDA",
    sigla: "FI",
    codice_elettorale: 2090300250,
    codice_istat: 48025,
    codice_belfiore: "E668",
  },
  {
    id: 3570,
    comune: "LONGANO",
    sigla: "IS",
    codice_elettorale: 3140940240,
    codice_istat: 94024,
    codice_belfiore: "E669",
  },
  {
    id: 3571,
    comune: "LONGARE",
    sigla: "VI",
    codice_elettorale: 1050900510,
    codice_istat: 24051,
    codice_belfiore: "E671",
  },
  {
    id: 3572,
    comune: "LONGARONE",
    sigla: "BL",
    codice_elettorale: 1050100311,
    codice_istat: 25071,
    codice_belfiore: "M342",
  },
  {
    id: 3573,
    comune: "LONGHENA",
    sigla: "BS",
    codice_elettorale: 1030150860,
    codice_istat: 17093,
    codice_belfiore: "E673",
  },
  {
    id: 3574,
    comune: "LONGI",
    sigla: "ME",
    codice_elettorale: 4190480410,
    codice_istat: 83042,
    codice_belfiore: "E674",
  },
  {
    id: 3575,
    comune: "LONGIANO",
    sigla: "FC",
    codice_elettorale: 1080320170,
    codice_istat: 40018,
    codice_belfiore: "E675",
  },
  {
    id: 3576,
    comune: "LONGOBARDI",
    sigla: "CS",
    codice_elettorale: 3180250680,
    codice_istat: 78067,
    codice_belfiore: "E677",
  },
  {
    id: 3577,
    comune: "LONGOBUCCO",
    sigla: "CS",
    codice_elettorale: 3180250690,
    codice_istat: 78068,
    codice_belfiore: "E678",
  },
  {
    id: 3578,
    comune: "LONGONE AL SEGRINO",
    sigla: "CO",
    codice_elettorale: 1030241280,
    codice_istat: 13134,
    codice_belfiore: "E679",
  },
  {
    id: 3579,
    comune: "LONGONE SABINO",
    sigla: "RI",
    codice_elettorale: 2120690320,
    codice_istat: 57034,
    codice_belfiore: "E681",
  },
  {
    id: 3580,
    comune: "LONIGO",
    sigla: "VI",
    codice_elettorale: 1050900520,
    codice_istat: 24052,
    codice_belfiore: "E682",
  },
  {
    id: 3581,
    comune: "LORANZE'",
    sigla: "TO",
    codice_elettorale: 1010811350,
    codice_istat: 1137,
    codice_belfiore: "E683",
  },
  {
    id: 3582,
    comune: "LOREGGIA",
    sigla: "PD",
    codice_elettorale: 1050540460,
    codice_istat: 28046,
    codice_belfiore: "E684",
  },
  {
    id: 3583,
    comune: "LOREGLIA",
    sigla: "VB",
    codice_elettorale: 1011020380,
    codice_istat: 103038,
    codice_belfiore: "E685",
  },
  {
    id: 3584,
    comune: "LORENZAGO DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100320,
    codice_istat: 25032,
    codice_belfiore: "E687",
  },
  {
    id: 3585,
    comune: "LOREO",
    sigla: "RO",
    codice_elettorale: 1050710300,
    codice_istat: 29030,
    codice_belfiore: "E689",
  },
  {
    id: 3586,
    comune: "LORETO",
    sigla: "AN",
    codice_elettorale: 2110030220,
    codice_istat: 42022,
    codice_belfiore: "E690",
  },
  {
    id: 3587,
    comune: "LORETO APRUTINO",
    sigla: "PE",
    codice_elettorale: 3130600210,
    codice_istat: 68021,
    codice_belfiore: "E691",
  },
  {
    id: 3588,
    comune: "LORIA",
    sigla: "TV",
    codice_elettorale: 1050840350,
    codice_istat: 26036,
    codice_belfiore: "E692",
  },
  {
    id: 3589,
    comune: "LORO CIUFFENNA",
    sigla: "AR",
    codice_elettorale: 2090050200,
    codice_istat: 51020,
    codice_belfiore: "E693",
  },
  {
    id: 3590,
    comune: "LORO PICENO",
    sigla: "MC",
    codice_elettorale: 2110440220,
    codice_istat: 43022,
    codice_belfiore: "E694",
  },
  {
    id: 3591,
    comune: "LORSICA",
    sigla: "GE",
    codice_elettorale: 1070340300,
    codice_istat: 10030,
    codice_belfiore: "E695",
  },
  {
    id: 3592,
    comune: "LOSINE",
    sigla: "BS",
    codice_elettorale: 1030150870,
    codice_istat: 17094,
    codice_belfiore: "E698",
  },
  {
    id: 3593,
    comune: "LOTZORAI",
    sigla: "NU",
    codice_elettorale: 4200530400,
    codice_istat: 91042,
    codice_belfiore: "E700",
  },
  {
    id: 3594,
    comune: "LOVERE",
    sigla: "BG",
    codice_elettorale: 1030121220,
    codice_istat: 16128,
    codice_belfiore: "E704",
  },
  {
    id: 3595,
    comune: "LOVERO",
    sigla: "SO",
    codice_elettorale: 1030770380,
    codice_istat: 14038,
    codice_belfiore: "E705",
  },
  {
    id: 3596,
    comune: "LOZIO",
    sigla: "BS",
    codice_elettorale: 1030150880,
    codice_istat: 17095,
    codice_belfiore: "E706",
  },
  {
    id: 3597,
    comune: "LOZZA",
    sigla: "VA",
    codice_elettorale: 1030860780,
    codice_istat: 12091,
    codice_belfiore: "E707",
  },
  {
    id: 3598,
    comune: "LOZZO ATESTINO",
    sigla: "PD",
    codice_elettorale: 1050540470,
    codice_istat: 28047,
    codice_belfiore: "E709",
  },
  {
    id: 3599,
    comune: "LOZZO DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100330,
    codice_istat: 25033,
    codice_belfiore: "E708",
  },
  {
    id: 3600,
    comune: "LOZZOLO",
    sigla: "VC",
    codice_elettorale: 1010880720,
    codice_istat: 2072,
    codice_belfiore: "E711",
  },
  {
    id: 3601,
    comune: "LU E CUCCARO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020875,
    codice_istat: 6193,
    codice_belfiore: "M420",
  },
  {
    id: 3602,
    comune: "LUBRIANO",
    sigla: "VT",
    codice_elettorale: 2120910320,
    codice_istat: 56033,
    codice_belfiore: "E713",
  },
  {
    id: 3603,
    comune: "LUCCA",
    sigla: "LU",
    codice_elettorale: 2090430170,
    codice_istat: 46017,
    codice_belfiore: "E715",
  },
  {
    id: 3604,
    comune: "LUCCA SICULA",
    sigla: "AG",
    codice_elettorale: 4190010220,
    codice_istat: 84022,
    codice_belfiore: "E714",
  },
  {
    id: 3605,
    comune: "LUCERA",
    sigla: "FG",
    codice_elettorale: 3160310270,
    codice_istat: 71028,
    codice_belfiore: "E716",
  },
  {
    id: 3606,
    comune: "LUCIGNANO",
    sigla: "AR",
    codice_elettorale: 2090050210,
    codice_istat: 51021,
    codice_belfiore: "E718",
  },
  {
    id: 3607,
    comune: "LUCINASCO",
    sigla: "IM",
    codice_elettorale: 1070370301,
    codice_istat: 8033,
    codice_belfiore: "E719",
  },
  {
    id: 3608,
    comune: "LUCITO",
    sigla: "CB",
    codice_elettorale: 3140190330,
    codice_istat: 70033,
    codice_belfiore: "E722",
  },
  {
    id: 3609,
    comune: "LUCO DEI MARSI",
    sigla: "AQ",
    codice_elettorale: 3130380510,
    codice_istat: 66051,
    codice_belfiore: "E723",
  },
  {
    id: 3610,
    comune: "LUCOLI",
    sigla: "AQ",
    codice_elettorale: 3130380520,
    codice_istat: 66052,
    codice_belfiore: "E724",
  },
  {
    id: 3611,
    comune: "LUGAGNANO VAL D'ARDA",
    sigla: "PC",
    codice_elettorale: 1080610260,
    codice_istat: 33026,
    codice_belfiore: "E726",
  },
  {
    id: 3612,
    comune: "LUGNANO IN TEVERINA",
    sigla: "TR",
    codice_elettorale: 2100800160,
    codice_istat: 55016,
    codice_belfiore: "E729",
  },
  {
    id: 3613,
    comune: "LUGO",
    sigla: "RA",
    codice_elettorale: 1080660120,
    codice_istat: 39012,
    codice_belfiore: "E730",
  },
  {
    id: 3614,
    comune: "LUGO DI VICENZA",
    sigla: "VI",
    codice_elettorale: 1050900530,
    codice_istat: 24053,
    codice_belfiore: "E731",
  },
  {
    id: 3615,
    comune: "LUINO",
    sigla: "VA",
    codice_elettorale: 1030860790,
    codice_istat: 12092,
    codice_belfiore: "E734",
  },
  {
    id: 3616,
    comune: "LUISAGO",
    sigla: "CO",
    codice_elettorale: 1030241290,
    codice_istat: 13135,
    codice_belfiore: "E735",
  },
  {
    id: 3617,
    comune: "LULA",
    sigla: "NU",
    codice_elettorale: 4200530410,
    codice_istat: 91043,
    codice_belfiore: "E736",
  },
  {
    id: 3618,
    comune: "LUMARZO",
    sigla: "GE",
    codice_elettorale: 1070340310,
    codice_istat: 10031,
    codice_belfiore: "E737",
  },
  {
    id: 3619,
    comune: "LUMEZZANE",
    sigla: "BS",
    codice_elettorale: 1030150890,
    codice_istat: 17096,
    codice_belfiore: "E738",
  },
  {
    id: 3620,
    comune: "LUNAMATRONA",
    sigla: "SU",
    codice_elettorale: 4201110380,
    codice_istat: 111038,
    codice_belfiore: "E742",
  },
  {
    id: 3621,
    comune: "LUNANO",
    sigla: "PU",
    codice_elettorale: 2110590220,
    codice_istat: 41022,
    codice_belfiore: "E743",
  },
  {
    id: 3622,
    comune: "LUNGAVILLA",
    sigla: "PV",
    codice_elettorale: 1030570810,
    codice_istat: 18084,
    codice_belfiore: "B387",
  },
  {
    id: 3623,
    comune: "LUNGRO",
    sigla: "CS",
    codice_elettorale: 3180250700,
    codice_istat: 78069,
    codice_belfiore: "E745",
  },
  {
    id: 3624,
    comune: "LUNI",
    sigla: "SP",
    codice_elettorale: 1070390200,
    codice_istat: 11020,
    codice_belfiore: "G143",
  },
  {
    id: 3625,
    comune: "LUOGOSANO",
    sigla: "AV",
    codice_elettorale: 3150080450,
    codice_istat: 64045,
    codice_belfiore: "E746",
  },
  {
    id: 3626,
    comune: "LUOGOSANTO",
    sigla: "SS",
    codice_elettorale: 4200730360,
    codice_istat: 90036,
    codice_belfiore: "E747",
  },
  {
    id: 3627,
    comune: "LUPARA",
    sigla: "CB",
    codice_elettorale: 3140190340,
    codice_istat: 70034,
    codice_belfiore: "E748",
  },
  {
    id: 3628,
    comune: "LURAGO D'ERBA",
    sigla: "CO",
    codice_elettorale: 1030241300,
    codice_istat: 13136,
    codice_belfiore: "E749",
  },
  {
    id: 3629,
    comune: "LURAGO MARINONE",
    sigla: "CO",
    codice_elettorale: 1030241310,
    codice_istat: 13137,
    codice_belfiore: "E750",
  },
  {
    id: 3630,
    comune: "LURANO",
    sigla: "BG",
    codice_elettorale: 1030121230,
    codice_istat: 16129,
    codice_belfiore: "E751",
  },
  {
    id: 3631,
    comune: "LURAS",
    sigla: "SS",
    codice_elettorale: 4200730370,
    codice_istat: 90037,
    codice_belfiore: "E752",
  },
  {
    id: 3632,
    comune: "LURATE CACCIVIO",
    sigla: "CO",
    codice_elettorale: 1030241320,
    codice_istat: 13138,
    codice_belfiore: "E753",
  },
  {
    id: 3633,
    comune: "LUSCIANO",
    sigla: "CE",
    codice_elettorale: 3150200460,
    codice_istat: 61046,
    codice_belfiore: "E754",
  },
  {
    id: 3634,
    comune: "LUSERNA",
    sigla: "TN",
    codice_elettorale: 1040831020,
    codice_istat: 22109,
    codice_belfiore: "E757",
  },
  {
    id: 3635,
    comune: "LUSERNA SAN GIOVANNI",
    sigla: "TO",
    codice_elettorale: 1010811370,
    codice_istat: 1139,
    codice_belfiore: "E758",
  },
  {
    id: 3636,
    comune: "LUSERNETTA",
    sigla: "TO",
    codice_elettorale: 1010811380,
    codice_istat: 1140,
    codice_belfiore: "E759",
  },
  {
    id: 3637,
    comune: "LUSEVERA",
    sigla: "UD",
    codice_elettorale: 1060850510,
    codice_istat: 30051,
    codice_belfiore: "E760",
  },
  {
    id: 3638,
    comune: "LUSIA",
    sigla: "RO",
    codice_elettorale: 1050710310,
    codice_istat: 29031,
    codice_belfiore: "E761",
  },
  {
    id: 3639,
    comune: "LUSIANA CONCO",
    sigla: "VI",
    codice_elettorale: 1050900541,
    codice_istat: 24127,
    codice_belfiore: "M427",
  },
  {
    id: 3640,
    comune: "LUSIGLIE'",
    sigla: "TO",
    codice_elettorale: 1010811390,
    codice_istat: 1141,
    codice_belfiore: "E763",
  },
  {
    id: 3641,
    comune: "LUSON/LUSEN",
    sigla: "BZ",
    codice_elettorale: 1040140410,
    codice_istat: 21044,
    codice_belfiore: "E764",
  },
  {
    id: 3642,
    comune: "LUSTRA",
    sigla: "SA",
    codice_elettorale: 3150720640,
    codice_istat: 65064,
    codice_belfiore: "E767",
  },
  {
    id: 3643,
    comune: "LUVINATE",
    sigla: "VA",
    codice_elettorale: 1030860791,
    codice_istat: 12093,
    codice_belfiore: "E769",
  },
  {
    id: 3644,
    comune: "LUZZANA",
    sigla: "BG",
    codice_elettorale: 1030121240,
    codice_istat: 16130,
    codice_belfiore: "E770",
  },
  {
    id: 3645,
    comune: "LUZZARA",
    sigla: "RE",
    codice_elettorale: 1080680260,
    codice_istat: 35026,
    codice_belfiore: "E772",
  },
  {
    id: 3646,
    comune: "LUZZI",
    sigla: "CS",
    codice_elettorale: 3180250710,
    codice_istat: 78070,
    codice_belfiore: "E773",
  },
  {
    id: 3647,
    comune: "MACCAGNO CON PINO E VEDDASCA",
    sigla: "VA",
    codice_elettorale: 1030860801,
    codice_istat: 12142,
    codice_belfiore: "M339",
  },
  {
    id: 3648,
    comune: "MACCASTORNA",
    sigla: "LO",
    codice_elettorale: 1030990330,
    codice_istat: 98033,
    codice_belfiore: "E777",
  },
  {
    id: 3649,
    comune: "MACCHIA D'ISERNIA",
    sigla: "IS",
    codice_elettorale: 3140940250,
    codice_istat: 94025,
    codice_belfiore: "E778",
  },
  {
    id: 3650,
    comune: "MACCHIA VALFORTORE",
    sigla: "CB",
    codice_elettorale: 3140190350,
    codice_istat: 70035,
    codice_belfiore: "E780",
  },
  {
    id: 3651,
    comune: "MACCHIAGODENA",
    sigla: "IS",
    codice_elettorale: 3140940260,
    codice_istat: 94026,
    codice_belfiore: "E779",
  },
  {
    id: 3652,
    comune: "MACELLO",
    sigla: "TO",
    codice_elettorale: 1010811400,
    codice_istat: 1142,
    codice_belfiore: "E782",
  },
  {
    id: 3653,
    comune: "MACERATA",
    sigla: "MC",
    codice_elettorale: 2110440230,
    codice_istat: 43023,
    codice_belfiore: "E783",
  },
  {
    id: 3654,
    comune: "MACERATA CAMPANIA",
    sigla: "CE",
    codice_elettorale: 3150200470,
    codice_istat: 61047,
    codice_belfiore: "E784",
  },
  {
    id: 3655,
    comune: "MACERATA FELTRIA",
    sigla: "PU",
    codice_elettorale: 2110590230,
    codice_istat: 41023,
    codice_belfiore: "E785",
  },
  {
    id: 3656,
    comune: "MACHERIO",
    sigla: "MB",
    codice_elettorale: 1031040290,
    codice_istat: 108029,
    codice_belfiore: "E786",
  },
  {
    id: 3657,
    comune: "MACLODIO",
    sigla: "BS",
    codice_elettorale: 1030150900,
    codice_istat: 17097,
    codice_belfiore: "E787",
  },
  {
    id: 3658,
    comune: "MACOMER",
    sigla: "NU",
    codice_elettorale: 4200530420,
    codice_istat: 91044,
    codice_belfiore: "E788",
  },
  {
    id: 3659,
    comune: "MACRA",
    sigla: "CN",
    codice_elettorale: 1010271120,
    codice_istat: 4112,
    codice_belfiore: "E789",
  },
  {
    id: 3660,
    comune: "MACUGNAGA",
    sigla: "VB",
    codice_elettorale: 1011020390,
    codice_istat: 103039,
    codice_belfiore: "E790",
  },
  {
    id: 3661,
    comune: "MADDALONI",
    sigla: "CE",
    codice_elettorale: 3150200480,
    codice_istat: 61048,
    codice_belfiore: "E791",
  },
  {
    id: 3662,
    comune: "MADESIMO",
    sigla: "SO",
    codice_elettorale: 1030770381,
    codice_istat: 14035,
    codice_belfiore: "E342",
  },
  {
    id: 3663,
    comune: "MADIGNANO",
    sigla: "CR",
    codice_elettorale: 1030260540,
    codice_istat: 19055,
    codice_belfiore: "E793",
  },
  {
    id: 3664,
    comune: "MADONE",
    sigla: "BG",
    codice_elettorale: 1030121250,
    codice_istat: 16131,
    codice_belfiore: "E794",
  },
  {
    id: 3665,
    comune: "MADONNA DEL SASSO",
    sigla: "VB",
    codice_elettorale: 1011020400,
    codice_istat: 103040,
    codice_belfiore: "E795",
  },
  {
    id: 3666,
    comune: "MADRUZZO",
    sigla: "TN",
    codice_elettorale: 1040831031,
    codice_istat: 22243,
    codice_belfiore: "M357",
  },
  {
    id: 3667,
    comune: "MAENZA",
    sigla: "LT",
    codice_elettorale: 2120400130,
    codice_istat: 59013,
    codice_belfiore: "E798",
  },
  {
    id: 3668,
    comune: "MAFALDA",
    sigla: "CB",
    codice_elettorale: 3140190360,
    codice_istat: 70036,
    codice_belfiore: "E799",
  },
  {
    id: 3669,
    comune: "MAGASA",
    sigla: "BS",
    codice_elettorale: 1030150910,
    codice_istat: 17098,
    codice_belfiore: "E800",
  },
  {
    id: 3670,
    comune: "MAGENTA",
    sigla: "MI",
    codice_elettorale: 1030491290,
    codice_istat: 15130,
    codice_belfiore: "E801",
  },
  {
    id: 3671,
    comune: "MAGGIORA",
    sigla: "NO",
    codice_elettorale: 1010520840,
    codice_istat: 3088,
    codice_belfiore: "E803",
  },
  {
    id: 3672,
    comune: "MAGHERNO",
    sigla: "PV",
    codice_elettorale: 1030570820,
    codice_istat: 18085,
    codice_belfiore: "E804",
  },
  {
    id: 3673,
    comune: "MAGIONE",
    sigla: "PG",
    codice_elettorale: 2100580260,
    codice_istat: 54026,
    codice_belfiore: "E805",
  },
  {
    id: 3674,
    comune: "MAGISANO",
    sigla: "CZ",
    codice_elettorale: 3180220660,
    codice_istat: 79068,
    codice_belfiore: "E806",
  },
  {
    id: 3675,
    comune: "MAGLIANO ALFIERI",
    sigla: "CN",
    codice_elettorale: 1010271130,
    codice_istat: 4113,
    codice_belfiore: "E809",
  },
  {
    id: 3676,
    comune: "MAGLIANO ALPI",
    sigla: "CN",
    codice_elettorale: 1010271140,
    codice_istat: 4114,
    codice_belfiore: "E808",
  },
  {
    id: 3677,
    comune: "MAGLIANO DE' MARSI",
    sigla: "AQ",
    codice_elettorale: 3130380530,
    codice_istat: 66053,
    codice_belfiore: "E811",
  },
  {
    id: 3678,
    comune: "MAGLIANO DI TENNA",
    sigla: "FM",
    codice_elettorale: 2111050100,
    codice_istat: 109010,
    codice_belfiore: "E807",
  },
  {
    id: 3679,
    comune: "MAGLIANO IN TOSCANA",
    sigla: "GR",
    codice_elettorale: 2090360120,
    codice_istat: 53013,
    codice_belfiore: "E810",
  },
  {
    id: 3680,
    comune: "MAGLIANO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700511,
    codice_istat: 58052,
    codice_belfiore: "E813",
  },
  {
    id: 3681,
    comune: "MAGLIANO SABINA",
    sigla: "RI",
    codice_elettorale: 2120690330,
    codice_istat: 57035,
    codice_belfiore: "E812",
  },
  {
    id: 3682,
    comune: "MAGLIANO VETERE",
    sigla: "SA",
    codice_elettorale: 3150720650,
    codice_istat: 65065,
    codice_belfiore: "E814",
  },
  {
    id: 3683,
    comune: "MAGLIE",
    sigla: "LE",
    codice_elettorale: 3160410380,
    codice_istat: 75039,
    codice_belfiore: "E815",
  },
  {
    id: 3684,
    comune: "MAGLIOLO",
    sigla: "SV",
    codice_elettorale: 1070740350,
    codice_istat: 9035,
    codice_belfiore: "E816",
  },
  {
    id: 3685,
    comune: "MAGLIONE",
    sigla: "TO",
    codice_elettorale: 1010811410,
    codice_istat: 1143,
    codice_belfiore: "E817",
  },
  {
    id: 3686,
    comune: "MAGNACAVALLO",
    sigla: "MN",
    codice_elettorale: 1030450290,
    codice_istat: 20029,
    codice_belfiore: "E818",
  },
  {
    id: 3687,
    comune: "MAGNAGO",
    sigla: "MI",
    codice_elettorale: 1030491300,
    codice_istat: 15131,
    codice_belfiore: "E819",
  },
  {
    id: 3688,
    comune: "MAGNANO",
    sigla: "BI",
    codice_elettorale: 1010960300,
    codice_istat: 96030,
    codice_belfiore: "E821",
  },
  {
    id: 3689,
    comune: "MAGNANO IN RIVIERA",
    sigla: "UD",
    codice_elettorale: 1060850520,
    codice_istat: 30052,
    codice_belfiore: "E820",
  },
  {
    id: 3690,
    comune: "MAGOMADAS",
    sigla: "OR",
    codice_elettorale: 4200950247,
    codice_istat: 95083,
    codice_belfiore: "E825",
  },
  {
    id: 3691,
    comune: "MAGRE' SULLA STRADA DEL VINO/MARGREID AN DER WEINSTRASSE",
    sigla: "BZ",
    codice_elettorale: 1040140420,
    codice_istat: 21045,
    codice_belfiore: "E829",
  },
  {
    id: 3692,
    comune: "MAGREGLIO",
    sigla: "CO",
    codice_elettorale: 1030241330,
    codice_istat: 13139,
    codice_belfiore: "E830",
  },
  {
    id: 3693,
    comune: "MAIDA",
    sigla: "CZ",
    codice_elettorale: 3180220670,
    codice_istat: 79069,
    codice_belfiore: "E834",
  },
  {
    id: 3694,
    comune: "MAIERA'",
    sigla: "CS",
    codice_elettorale: 3180250720,
    codice_istat: 78071,
    codice_belfiore: "E835",
  },
  {
    id: 3695,
    comune: "MAIERATO",
    sigla: "VV",
    codice_elettorale: 3181030200,
    codice_istat: 102020,
    codice_belfiore: "E836",
  },
  {
    id: 3696,
    comune: "MAIOLATI SPONTINI",
    sigla: "AN",
    codice_elettorale: 2110030230,
    codice_istat: 42023,
    codice_belfiore: "E837",
  },
  {
    id: 3697,
    comune: "MAIOLO",
    sigla: "RN",
    codice_elettorale: 1081010045,
    codice_istat: 99022,
    codice_belfiore: "E838",
  },
  {
    id: 3698,
    comune: "MAIORI",
    sigla: "SA",
    codice_elettorale: 3150720660,
    codice_istat: 65066,
    codice_belfiore: "E839",
  },
  {
    id: 3699,
    comune: "MAIRAGO",
    sigla: "LO",
    codice_elettorale: 1030990340,
    codice_istat: 98034,
    codice_belfiore: "E840",
  },
  {
    id: 3700,
    comune: "MAIRANO",
    sigla: "BS",
    codice_elettorale: 1030150920,
    codice_istat: 17099,
    codice_belfiore: "E841",
  },
  {
    id: 3701,
    comune: "MAISSANA",
    sigla: "SP",
    codice_elettorale: 1070390180,
    codice_istat: 11018,
    codice_belfiore: "E842",
  },
  {
    id: 3702,
    comune: "MAJANO",
    sigla: "UD",
    codice_elettorale: 1060850530,
    codice_istat: 30053,
    codice_belfiore: "E833",
  },
  {
    id: 3703,
    comune: "MALAGNINO",
    sigla: "CR",
    codice_elettorale: 1030260550,
    codice_istat: 19056,
    codice_belfiore: "E843",
  },
  {
    id: 3704,
    comune: "MALALBERGO",
    sigla: "BO",
    codice_elettorale: 1080130350,
    codice_istat: 37035,
    codice_belfiore: "E844",
  },
  {
    id: 3705,
    comune: "MALBORGHETTO VALBRUNA",
    sigla: "UD",
    codice_elettorale: 1060850540,
    codice_istat: 30054,
    codice_belfiore: "E847",
  },
  {
    id: 3706,
    comune: "MALCESINE",
    sigla: "VR",
    codice_elettorale: 1050890450,
    codice_istat: 23045,
    codice_belfiore: "E848",
  },
  {
    id: 3707,
    comune: "MALE'",
    sigla: "TN",
    codice_elettorale: 1040831040,
    codice_istat: 22110,
    codice_belfiore: "E850",
  },
  {
    id: 3708,
    comune: "MALEGNO",
    sigla: "BS",
    codice_elettorale: 1030150930,
    codice_istat: 17100,
    codice_belfiore: "E851",
  },
  {
    id: 3709,
    comune: "MALEO",
    sigla: "LO",
    codice_elettorale: 1030990350,
    codice_istat: 98035,
    codice_belfiore: "E852",
  },
  {
    id: 3710,
    comune: "MALESCO",
    sigla: "VB",
    codice_elettorale: 1011020410,
    codice_istat: 103041,
    codice_belfiore: "E853",
  },
  {
    id: 3711,
    comune: "MALETTO",
    sigla: "CT",
    codice_elettorale: 4190210220,
    codice_istat: 87022,
    codice_belfiore: "E854",
  },
  {
    id: 3712,
    comune: "MALFA",
    sigla: "ME",
    codice_elettorale: 4190480420,
    codice_istat: 83043,
    codice_belfiore: "E855",
  },
  {
    id: 3713,
    comune: "MALGRATE",
    sigla: "LC",
    codice_elettorale: 1030980450,
    codice_istat: 97045,
    codice_belfiore: "E858",
  },
  {
    id: 3714,
    comune: "MALITO",
    sigla: "CS",
    codice_elettorale: 3180250730,
    codice_istat: 78072,
    codice_belfiore: "E859",
  },
  {
    id: 3715,
    comune: "MALLARE",
    sigla: "SV",
    codice_elettorale: 1070740360,
    codice_istat: 9036,
    codice_belfiore: "E860",
  },
  {
    id: 3716,
    comune: "MALLES VENOSTA/MALS",
    sigla: "BZ",
    codice_elettorale: 1040140430,
    codice_istat: 21046,
    codice_belfiore: "E862",
  },
  {
    id: 3717,
    comune: "MALNATE",
    sigla: "VA",
    codice_elettorale: 1030860820,
    codice_istat: 12096,
    codice_belfiore: "E863",
  },
  {
    id: 3718,
    comune: "MALO",
    sigla: "VI",
    codice_elettorale: 1050900550,
    codice_istat: 24055,
    codice_belfiore: "E864",
  },
  {
    id: 3719,
    comune: "MALONNO",
    sigla: "BS",
    codice_elettorale: 1030150940,
    codice_istat: 17101,
    codice_belfiore: "E865",
  },
  {
    id: 3720,
    comune: "MALTIGNANO",
    sigla: "AP",
    codice_elettorale: 2110060270,
    codice_istat: 44027,
    codice_belfiore: "E868",
  },
  {
    id: 3721,
    comune: "MALVAGNA",
    sigla: "ME",
    codice_elettorale: 4190480430,
    codice_istat: 83044,
    codice_belfiore: "E869",
  },
  {
    id: 3722,
    comune: "MALVICINO",
    sigla: "AL",
    codice_elettorale: 1010020880,
    codice_istat: 6090,
    codice_belfiore: "E870",
  },
  {
    id: 3723,
    comune: "MALVITO",
    sigla: "CS",
    codice_elettorale: 3180250740,
    codice_istat: 78073,
    codice_belfiore: "E872",
  },
  {
    id: 3724,
    comune: "MAMMOLA",
    sigla: "RC",
    codice_elettorale: 3180670440,
    codice_istat: 80044,
    codice_belfiore: "E873",
  },
  {
    id: 3725,
    comune: "MAMOIADA",
    sigla: "NU",
    codice_elettorale: 4200530440,
    codice_istat: 91046,
    codice_belfiore: "E874",
  },
  {
    id: 3726,
    comune: "MANCIANO",
    sigla: "GR",
    codice_elettorale: 2090360130,
    codice_istat: 53014,
    codice_belfiore: "E875",
  },
  {
    id: 3727,
    comune: "MANDANICI",
    sigla: "ME",
    codice_elettorale: 4190480440,
    codice_istat: 83045,
    codice_belfiore: "E876",
  },
  {
    id: 3728,
    comune: "MANDAS",
    sigla: "SU",
    codice_elettorale: 4201110390,
    codice_istat: 111039,
    codice_belfiore: "E877",
  },
  {
    id: 3729,
    comune: "MANDATORICCIO",
    sigla: "CS",
    codice_elettorale: 3180250750,
    codice_istat: 78074,
    codice_belfiore: "E878",
  },
  {
    id: 3730,
    comune: "MANDELA",
    sigla: "RM",
    codice_elettorale: 2120700520,
    codice_istat: 58053,
    codice_belfiore: "B632",
  },
  {
    id: 3731,
    comune: "MANDELLO DEL LARIO",
    sigla: "LC",
    codice_elettorale: 1030980460,
    codice_istat: 97046,
    codice_belfiore: "E879",
  },
  {
    id: 3732,
    comune: "MANDELLO VITTA",
    sigla: "NO",
    codice_elettorale: 1010520851,
    codice_istat: 3090,
    codice_belfiore: "E880",
  },
  {
    id: 3733,
    comune: "MANDURIA",
    sigla: "TA",
    codice_elettorale: 3160780120,
    codice_istat: 73012,
    codice_belfiore: "E882",
  },
  {
    id: 3734,
    comune: "MANERBA DEL GARDA",
    sigla: "BS",
    codice_elettorale: 1030150950,
    codice_istat: 17102,
    codice_belfiore: "E883",
  },
  {
    id: 3735,
    comune: "MANERBIO",
    sigla: "BS",
    codice_elettorale: 1030150960,
    codice_istat: 17103,
    codice_belfiore: "E884",
  },
  {
    id: 3736,
    comune: "MANFREDONIA",
    sigla: "FG",
    codice_elettorale: 3160310280,
    codice_istat: 71029,
    codice_belfiore: "E885",
  },
  {
    id: 3737,
    comune: "MANGO",
    sigla: "CN",
    codice_elettorale: 1010271150,
    codice_istat: 4115,
    codice_belfiore: "E887",
  },
  {
    id: 3738,
    comune: "MANGONE",
    sigla: "CS",
    codice_elettorale: 3180250760,
    codice_istat: 78075,
    codice_belfiore: "E888",
  },
  {
    id: 3739,
    comune: "MANIACE",
    sigla: "CT",
    codice_elettorale: 4190210221,
    codice_istat: 87057,
    codice_belfiore: "M283",
  },
  {
    id: 3740,
    comune: "MANIAGO",
    sigla: "PN",
    codice_elettorale: 1060930250,
    codice_istat: 93025,
    codice_belfiore: "E889",
  },
  {
    id: 3741,
    comune: "MANOCALZATI",
    sigla: "AV",
    codice_elettorale: 3150080460,
    codice_istat: 64046,
    codice_belfiore: "E891",
  },
  {
    id: 3742,
    comune: "MANOPPELLO",
    sigla: "PE",
    codice_elettorale: 3130600220,
    codice_istat: 68022,
    codice_belfiore: "E892",
  },
  {
    id: 3743,
    comune: "MANSUE'",
    sigla: "TV",
    codice_elettorale: 1050840360,
    codice_istat: 26037,
    codice_belfiore: "E893",
  },
  {
    id: 3744,
    comune: "MANTA",
    sigla: "CN",
    codice_elettorale: 1010271160,
    codice_istat: 4116,
    codice_belfiore: "E894",
  },
  {
    id: 3745,
    comune: "MANTELLO",
    sigla: "SO",
    codice_elettorale: 1030770390,
    codice_istat: 14039,
    codice_belfiore: "E896",
  },
  {
    id: 3746,
    comune: "MANTOVA",
    sigla: "MN",
    codice_elettorale: 1030450300,
    codice_istat: 20030,
    codice_belfiore: "E897",
  },
  {
    id: 3747,
    comune: "MANZANO",
    sigla: "UD",
    codice_elettorale: 1060850550,
    codice_istat: 30055,
    codice_belfiore: "E899",
  },
  {
    id: 3748,
    comune: "MANZIANA",
    sigla: "RM",
    codice_elettorale: 2120700530,
    codice_istat: 58054,
    codice_belfiore: "E900",
  },
  {
    id: 3749,
    comune: "MAPELLO",
    sigla: "BG",
    codice_elettorale: 1030121260,
    codice_istat: 16132,
    codice_belfiore: "E901",
  },
  {
    id: 3750,
    comune: "MAPPANO",
    sigla: "TO",
    codice_elettorale: 1010811415,
    codice_istat: 1316,
    codice_belfiore: "M316",
  },
  {
    id: 3751,
    comune: "MARA",
    sigla: "SS",
    codice_elettorale: 4200730380,
    codice_istat: 90038,
    codice_belfiore: "E902",
  },
  {
    id: 3752,
    comune: "MARACALAGONIS",
    sigla: "CA",
    codice_elettorale: 4200170370,
    codice_istat: 92037,
    codice_belfiore: "E903",
  },
  {
    id: 3753,
    comune: "MARANELLO",
    sigla: "MO",
    codice_elettorale: 1080500190,
    codice_istat: 36019,
    codice_belfiore: "E904",
  },
  {
    id: 3754,
    comune: "MARANO DI NAPOLI",
    sigla: "NA",
    codice_elettorale: 3150510410,
    codice_istat: 63041,
    codice_belfiore: "E906",
  },
  {
    id: 3755,
    comune: "MARANO DI VALPOLICELLA",
    sigla: "VR",
    codice_elettorale: 1050890460,
    codice_istat: 23046,
    codice_belfiore: "E911",
  },
  {
    id: 3756,
    comune: "MARANO EQUO",
    sigla: "RM",
    codice_elettorale: 2120700540,
    codice_istat: 58055,
    codice_belfiore: "E908",
  },
  {
    id: 3757,
    comune: "MARANO LAGUNARE",
    sigla: "UD",
    codice_elettorale: 1060850560,
    codice_istat: 30056,
    codice_belfiore: "E910",
  },
  {
    id: 3758,
    comune: "MARANO MARCHESATO",
    sigla: "CS",
    codice_elettorale: 3180250770,
    codice_istat: 78076,
    codice_belfiore: "E914",
  },
  {
    id: 3759,
    comune: "MARANO PRINCIPATO",
    sigla: "CS",
    codice_elettorale: 3180250780,
    codice_istat: 78077,
    codice_belfiore: "E915",
  },
  {
    id: 3760,
    comune: "MARANO SUL PANARO",
    sigla: "MO",
    codice_elettorale: 1080500200,
    codice_istat: 36020,
    codice_belfiore: "E905",
  },
  {
    id: 3761,
    comune: "MARANO TICINO",
    sigla: "NO",
    codice_elettorale: 1010520860,
    codice_istat: 3091,
    codice_belfiore: "E907",
  },
  {
    id: 3762,
    comune: "MARANO VICENTINO",
    sigla: "VI",
    codice_elettorale: 1050900560,
    codice_istat: 24056,
    codice_belfiore: "E912",
  },
  {
    id: 3763,
    comune: "MARANZANA",
    sigla: "AT",
    codice_elettorale: 1010070610,
    codice_istat: 5061,
    codice_belfiore: "E917",
  },
  {
    id: 3764,
    comune: "MARATEA",
    sigla: "PZ",
    codice_elettorale: 3170640430,
    codice_istat: 76044,
    codice_belfiore: "E919",
  },
  {
    id: 3765,
    comune: "MARCALLO CON CASONE",
    sigla: "MI",
    codice_elettorale: 1030491330,
    codice_istat: 15134,
    codice_belfiore: "E921",
  },
  {
    id: 3766,
    comune: "MARCARIA",
    sigla: "MN",
    codice_elettorale: 1030450310,
    codice_istat: 20031,
    codice_belfiore: "E922",
  },
  {
    id: 3767,
    comune: "MARCEDUSA",
    sigla: "CZ",
    codice_elettorale: 3180220690,
    codice_istat: 79071,
    codice_belfiore: "E923",
  },
  {
    id: 3768,
    comune: "MARCELLINA",
    sigla: "RM",
    codice_elettorale: 2120700550,
    codice_istat: 58056,
    codice_belfiore: "E924",
  },
  {
    id: 3769,
    comune: "MARCELLINARA",
    sigla: "CZ",
    codice_elettorale: 3180220700,
    codice_istat: 79072,
    codice_belfiore: "E925",
  },
  {
    id: 3770,
    comune: "MARCETELLI",
    sigla: "RI",
    codice_elettorale: 2120690340,
    codice_istat: 57036,
    codice_belfiore: "E927",
  },
  {
    id: 3771,
    comune: "MARCHENO",
    sigla: "BS",
    codice_elettorale: 1030150970,
    codice_istat: 17104,
    codice_belfiore: "E928",
  },
  {
    id: 3772,
    comune: "MARCHIROLO",
    sigla: "VA",
    codice_elettorale: 1030860821,
    codice_istat: 12097,
    codice_belfiore: "E929",
  },
  {
    id: 3773,
    comune: "MARCIANA",
    sigla: "LI",
    codice_elettorale: 2090420100,
    codice_istat: 49010,
    codice_belfiore: "E930",
  },
  {
    id: 3774,
    comune: "MARCIANA MARINA",
    sigla: "LI",
    codice_elettorale: 2090420110,
    codice_istat: 49011,
    codice_belfiore: "E931",
  },
  {
    id: 3775,
    comune: "MARCIANISE",
    sigla: "CE",
    codice_elettorale: 3150200490,
    codice_istat: 61049,
    codice_belfiore: "E932",
  },
  {
    id: 3776,
    comune: "MARCIANO DELLA CHIANA",
    sigla: "AR",
    codice_elettorale: 2090050220,
    codice_istat: 51022,
    codice_belfiore: "E933",
  },
  {
    id: 3777,
    comune: "MARCIGNAGO",
    sigla: "PV",
    codice_elettorale: 1030570830,
    codice_istat: 18086,
    codice_belfiore: "E934",
  },
  {
    id: 3778,
    comune: "MARCON",
    sigla: "VE",
    codice_elettorale: 1050870200,
    codice_istat: 27020,
    codice_belfiore: "E936",
  },
  {
    id: 3779,
    comune: "MAREBBE/ENNEBERG",
    sigla: "BZ",
    codice_elettorale: 1040140440,
    codice_istat: 21047,
    codice_belfiore: "E938",
  },
  {
    id: 3780,
    comune: "MARENE",
    sigla: "CN",
    codice_elettorale: 1010271170,
    codice_istat: 4117,
    codice_belfiore: "E939",
  },
  {
    id: 3781,
    comune: "MARENO DI PIAVE",
    sigla: "TV",
    codice_elettorale: 1050840370,
    codice_istat: 26038,
    codice_belfiore: "E940",
  },
  {
    id: 3782,
    comune: "MARENTINO",
    sigla: "TO",
    codice_elettorale: 1010811420,
    codice_istat: 1144,
    codice_belfiore: "E941",
  },
  {
    id: 3783,
    comune: "MARETTO",
    sigla: "AT",
    codice_elettorale: 1010070620,
    codice_istat: 5062,
    codice_belfiore: "E944",
  },
  {
    id: 3784,
    comune: "MARGARITA",
    sigla: "CN",
    codice_elettorale: 1010271180,
    codice_istat: 4118,
    codice_belfiore: "E945",
  },
  {
    id: 3785,
    comune: "MARGHERITA DI SAVOIA",
    sigla: "BT",
    codice_elettorale: 3161060050,
    codice_istat: 110005,
    codice_belfiore: "E946",
  },
  {
    id: 3786,
    comune: "MARGNO",
    sigla: "LC",
    codice_elettorale: 1030980470,
    codice_istat: 97047,
    codice_belfiore: "E947",
  },
  {
    id: 3787,
    comune: "MARIANA MANTOVANA",
    sigla: "MN",
    codice_elettorale: 1030450320,
    codice_istat: 20032,
    codice_belfiore: "E949",
  },
  {
    id: 3788,
    comune: "MARIANO COMENSE",
    sigla: "CO",
    codice_elettorale: 1030241370,
    codice_istat: 13143,
    codice_belfiore: "E951",
  },
  {
    id: 3789,
    comune: "MARIANO DEL FRIULI",
    sigla: "GO",
    codice_elettorale: 1060350100,
    codice_istat: 31010,
    codice_belfiore: "E952",
  },
  {
    id: 3790,
    comune: "MARIANOPOLI",
    sigla: "CL",
    codice_elettorale: 4190180080,
    codice_istat: 85008,
    codice_belfiore: "E953",
  },
  {
    id: 3791,
    comune: "MARIGLIANELLA",
    sigla: "NA",
    codice_elettorale: 3150510420,
    codice_istat: 63042,
    codice_belfiore: "E954",
  },
  {
    id: 3792,
    comune: "MARIGLIANO",
    sigla: "NA",
    codice_elettorale: 3150510430,
    codice_istat: 63043,
    codice_belfiore: "E955",
  },
  {
    id: 3793,
    comune: "MARINA DI GIOIOSA IONICA",
    sigla: "RC",
    codice_elettorale: 3180670450,
    codice_istat: 80045,
    codice_belfiore: "E956",
  },
  {
    id: 3794,
    comune: "MARINEO",
    sigla: "PA",
    codice_elettorale: 4190550440,
    codice_istat: 82046,
    codice_belfiore: "E957",
  },
  {
    id: 3795,
    comune: "MARINO",
    sigla: "RM",
    codice_elettorale: 2120700560,
    codice_istat: 58057,
    codice_belfiore: "E958",
  },
  {
    id: 3796,
    comune: "MARLENGO/MARLING",
    sigla: "BZ",
    codice_elettorale: 1040140450,
    codice_istat: 21048,
    codice_belfiore: "E959",
  },
  {
    id: 3797,
    comune: "MARLIANA",
    sigla: "PT",
    codice_elettorale: 2090630070,
    codice_istat: 47007,
    codice_belfiore: "E960",
  },
  {
    id: 3798,
    comune: "MARMENTINO",
    sigla: "BS",
    codice_elettorale: 1030150971,
    codice_istat: 17105,
    codice_belfiore: "E961",
  },
  {
    id: 3799,
    comune: "MARMIROLO",
    sigla: "MN",
    codice_elettorale: 1030450330,
    codice_istat: 20033,
    codice_belfiore: "E962",
  },
  {
    id: 3800,
    comune: "MARMORA",
    sigla: "CN",
    codice_elettorale: 1010271190,
    codice_istat: 4119,
    codice_belfiore: "E963",
  },
  {
    id: 3801,
    comune: "MARNATE",
    sigla: "VA",
    codice_elettorale: 1030860830,
    codice_istat: 12098,
    codice_belfiore: "E965",
  },
  {
    id: 3802,
    comune: "MARONE",
    sigla: "BS",
    codice_elettorale: 1030150980,
    codice_istat: 17106,
    codice_belfiore: "E967",
  },
  {
    id: 3803,
    comune: "MAROPATI",
    sigla: "RC",
    codice_elettorale: 3180670460,
    codice_istat: 80046,
    codice_belfiore: "E968",
  },
  {
    id: 3804,
    comune: "MAROSTICA",
    sigla: "VI",
    codice_elettorale: 1050900570,
    codice_istat: 24057,
    codice_belfiore: "E970",
  },
  {
    id: 3805,
    comune: "MARRADI",
    sigla: "FI",
    codice_elettorale: 2090300260,
    codice_istat: 48026,
    codice_belfiore: "E971",
  },
  {
    id: 3806,
    comune: "MARRUBIU",
    sigla: "OR",
    codice_elettorale: 4200950250,
    codice_istat: 95025,
    codice_belfiore: "E972",
  },
  {
    id: 3807,
    comune: "MARSAGLIA",
    sigla: "CN",
    codice_elettorale: 1010271200,
    codice_istat: 4120,
    codice_belfiore: "E973",
  },
  {
    id: 3808,
    comune: "MARSALA",
    sigla: "TP",
    codice_elettorale: 4190820120,
    codice_istat: 81011,
    codice_belfiore: "E974",
  },
  {
    id: 3809,
    comune: "MARSCIANO",
    sigla: "PG",
    codice_elettorale: 2100580270,
    codice_istat: 54027,
    codice_belfiore: "E975",
  },
  {
    id: 3810,
    comune: "MARSICO NUOVO",
    sigla: "PZ",
    codice_elettorale: 3170640440,
    codice_istat: 76045,
    codice_belfiore: "E976",
  },
  {
    id: 3811,
    comune: "MARSICOVETERE",
    sigla: "PZ",
    codice_elettorale: 3170640450,
    codice_istat: 76046,
    codice_belfiore: "E977",
  },
  {
    id: 3812,
    comune: "MARTA",
    sigla: "VT",
    codice_elettorale: 2120910330,
    codice_istat: 56034,
    codice_belfiore: "E978",
  },
  {
    id: 3813,
    comune: "MARTANO",
    sigla: "LE",
    codice_elettorale: 3160410390,
    codice_istat: 75040,
    codice_belfiore: "E979",
  },
  {
    id: 3814,
    comune: "MARTELLAGO",
    sigla: "VE",
    codice_elettorale: 1050870210,
    codice_istat: 27021,
    codice_belfiore: "E980",
  },
  {
    id: 3815,
    comune: "MARTELLO/MARTELL",
    sigla: "BZ",
    codice_elettorale: 1040140460,
    codice_istat: 21049,
    codice_belfiore: "E981",
  },
  {
    id: 3816,
    comune: "MARTIGNACCO",
    sigla: "UD",
    codice_elettorale: 1060850570,
    codice_istat: 30057,
    codice_belfiore: "E982",
  },
  {
    id: 3817,
    comune: "MARTIGNANA DI PO",
    sigla: "CR",
    codice_elettorale: 1030260560,
    codice_istat: 19057,
    codice_belfiore: "E983",
  },
  {
    id: 3818,
    comune: "MARTIGNANO",
    sigla: "LE",
    codice_elettorale: 3160410400,
    codice_istat: 75041,
    codice_belfiore: "E984",
  },
  {
    id: 3819,
    comune: "MARTINA FRANCA",
    sigla: "TA",
    codice_elettorale: 3160780130,
    codice_istat: 73013,
    codice_belfiore: "E986",
  },
  {
    id: 3820,
    comune: "MARTINENGO",
    sigla: "BG",
    codice_elettorale: 1030121280,
    codice_istat: 16133,
    codice_belfiore: "E987",
  },
  {
    id: 3821,
    comune: "MARTINIANA PO",
    sigla: "CN",
    codice_elettorale: 1010271210,
    codice_istat: 4121,
    codice_belfiore: "E988",
  },
  {
    id: 3822,
    comune: "MARTINSICURO",
    sigla: "TE",
    codice_elettorale: 3130790251,
    codice_istat: 67047,
    codice_belfiore: "E989",
  },
  {
    id: 3823,
    comune: "MARTIRANO",
    sigla: "CZ",
    codice_elettorale: 3180220701,
    codice_istat: 79073,
    codice_belfiore: "E990",
  },
  {
    id: 3824,
    comune: "MARTIRANO LOMBARDO",
    sigla: "CZ",
    codice_elettorale: 3180220710,
    codice_istat: 79074,
    codice_belfiore: "E991",
  },
  {
    id: 3825,
    comune: "MARTIS",
    sigla: "SS",
    codice_elettorale: 4200730390,
    codice_istat: 90039,
    codice_belfiore: "E992",
  },
  {
    id: 3826,
    comune: "MARTONE",
    sigla: "RC",
    codice_elettorale: 3180670470,
    codice_istat: 80047,
    codice_belfiore: "E993",
  },
  {
    id: 3827,
    comune: "MARUDO",
    sigla: "LO",
    codice_elettorale: 1030990360,
    codice_istat: 98036,
    codice_belfiore: "E994",
  },
  {
    id: 3828,
    comune: "MARUGGIO",
    sigla: "TA",
    codice_elettorale: 3160780140,
    codice_istat: 73014,
    codice_belfiore: "E995",
  },
  {
    id: 3829,
    comune: "MARZABOTTO",
    sigla: "BO",
    codice_elettorale: 1080130360,
    codice_istat: 37036,
    codice_belfiore: "B689",
  },
  {
    id: 3830,
    comune: "MARZANO",
    sigla: "PV",
    codice_elettorale: 1030570840,
    codice_istat: 18087,
    codice_belfiore: "E999",
  },
  {
    id: 3831,
    comune: "MARZANO APPIO",
    sigla: "CE",
    codice_elettorale: 3150200500,
    codice_istat: 61050,
    codice_belfiore: "E998",
  },
  {
    id: 3832,
    comune: "MARZANO DI NOLA",
    sigla: "AV",
    codice_elettorale: 3150080470,
    codice_istat: 64047,
    codice_belfiore: "E997",
  },
  {
    id: 3833,
    comune: "MARZI",
    sigla: "CS",
    codice_elettorale: 3180250790,
    codice_istat: 78078,
    codice_belfiore: "F001",
  },
  {
    id: 3834,
    comune: "MARZIO",
    sigla: "VA",
    codice_elettorale: 1030860840,
    codice_istat: 12099,
    codice_belfiore: "F002",
  },
  {
    id: 3835,
    comune: "MASAINAS",
    sigla: "SU",
    codice_elettorale: 4201110400,
    codice_istat: 111040,
    codice_belfiore: "M270",
  },
  {
    id: 3836,
    comune: "MASATE",
    sigla: "MI",
    codice_elettorale: 1030491350,
    codice_istat: 15136,
    codice_belfiore: "F003",
  },
  {
    id: 3837,
    comune: "MASCALI",
    sigla: "CT",
    codice_elettorale: 4190210230,
    codice_istat: 87023,
    codice_belfiore: "F004",
  },
  {
    id: 3838,
    comune: "MASCALUCIA",
    sigla: "CT",
    codice_elettorale: 4190210240,
    codice_istat: 87024,
    codice_belfiore: "F005",
  },
  {
    id: 3839,
    comune: "MASCHITO",
    sigla: "PZ",
    codice_elettorale: 3170640460,
    codice_istat: 76047,
    codice_belfiore: "F006",
  },
  {
    id: 3840,
    comune: "MASCIAGO PRIMO",
    sigla: "VA",
    codice_elettorale: 1030860850,
    codice_istat: 12100,
    codice_belfiore: "F007",
  },
  {
    id: 3841,
    comune: "MASER",
    sigla: "TV",
    codice_elettorale: 1050840380,
    codice_istat: 26039,
    codice_belfiore: "F009",
  },
  {
    id: 3842,
    comune: "MASERA",
    sigla: "VB",
    codice_elettorale: 1011020420,
    codice_istat: 103042,
    codice_belfiore: "F010",
  },
  {
    id: 3843,
    comune: "MASERA' DI PADOVA",
    sigla: "PD",
    codice_elettorale: 1050540480,
    codice_istat: 28048,
    codice_belfiore: "F011",
  },
  {
    id: 3844,
    comune: "MASERADA SUL PIAVE",
    sigla: "TV",
    codice_elettorale: 1050840390,
    codice_istat: 26040,
    codice_belfiore: "F012",
  },
  {
    id: 3845,
    comune: "MASI",
    sigla: "PD",
    codice_elettorale: 1050540490,
    codice_istat: 28049,
    codice_belfiore: "F013",
  },
  {
    id: 3846,
    comune: "MASI TORELLO",
    sigla: "FE",
    codice_elettorale: 1080290111,
    codice_istat: 38012,
    codice_belfiore: "F016",
  },
  {
    id: 3847,
    comune: "MASIO",
    sigla: "AL",
    codice_elettorale: 1010020890,
    codice_istat: 6091,
    codice_belfiore: "F015",
  },
  {
    id: 3848,
    comune: "MASLIANICO",
    sigla: "CO",
    codice_elettorale: 1030241380,
    codice_istat: 13144,
    codice_belfiore: "F017",
  },
  {
    id: 3849,
    comune: "MASONE",
    sigla: "GE",
    codice_elettorale: 1070340320,
    codice_istat: 10032,
    codice_belfiore: "F020",
  },
  {
    id: 3850,
    comune: "MASSA",
    sigla: "MS",
    codice_elettorale: 2090460100,
    codice_istat: 45010,
    codice_belfiore: "F023",
  },
  {
    id: 3851,
    comune: "MASSA D'ALBE",
    sigla: "AQ",
    codice_elettorale: 3130380540,
    codice_istat: 66054,
    codice_belfiore: "F022",
  },
  {
    id: 3852,
    comune: "MASSA DI SOMMA",
    sigla: "NA",
    codice_elettorale: 3150510431,
    codice_istat: 63092,
    codice_belfiore: "M289",
  },
  {
    id: 3853,
    comune: "MASSA E COZZILE",
    sigla: "PT",
    codice_elettorale: 2090630080,
    codice_istat: 47008,
    codice_belfiore: "F025",
  },
  {
    id: 3854,
    comune: "MASSA FERMANA",
    sigla: "FM",
    codice_elettorale: 2111050110,
    codice_istat: 109011,
    codice_belfiore: "F021",
  },
  {
    id: 3855,
    comune: "MASSA LOMBARDA",
    sigla: "RA",
    codice_elettorale: 1080660130,
    codice_istat: 39013,
    codice_belfiore: "F029",
  },
  {
    id: 3856,
    comune: "MASSA LUBRENSE",
    sigla: "NA",
    codice_elettorale: 3150510440,
    codice_istat: 63044,
    codice_belfiore: "F030",
  },
  {
    id: 3857,
    comune: "MASSA MARITTIMA",
    sigla: "GR",
    codice_elettorale: 2090360140,
    codice_istat: 53015,
    codice_belfiore: "F032",
  },
  {
    id: 3858,
    comune: "MASSA MARTANA",
    sigla: "PG",
    codice_elettorale: 2100580280,
    codice_istat: 54028,
    codice_belfiore: "F024",
  },
  {
    id: 3859,
    comune: "MASSAFRA",
    sigla: "TA",
    codice_elettorale: 3160780150,
    codice_istat: 73015,
    codice_belfiore: "F027",
  },
  {
    id: 3860,
    comune: "MASSALENGO",
    sigla: "LO",
    codice_elettorale: 1030990370,
    codice_istat: 98037,
    codice_belfiore: "F028",
  },
  {
    id: 3861,
    comune: "MASSANZAGO",
    sigla: "PD",
    codice_elettorale: 1050540500,
    codice_istat: 28050,
    codice_belfiore: "F033",
  },
  {
    id: 3862,
    comune: "MASSAROSA",
    sigla: "LU",
    codice_elettorale: 2090430180,
    codice_istat: 46018,
    codice_belfiore: "F035",
  },
  {
    id: 3863,
    comune: "MASSAZZA",
    sigla: "BI",
    codice_elettorale: 1010960310,
    codice_istat: 96031,
    codice_belfiore: "F037",
  },
  {
    id: 3864,
    comune: "MASSELLO",
    sigla: "TO",
    codice_elettorale: 1010811430,
    codice_istat: 1145,
    codice_belfiore: "F041",
  },
  {
    id: 3865,
    comune: "MASSERANO",
    sigla: "BI",
    codice_elettorale: 1010960320,
    codice_istat: 96032,
    codice_belfiore: "F042",
  },
  {
    id: 3866,
    comune: "MASSIGNANO",
    sigla: "AP",
    codice_elettorale: 2110060290,
    codice_istat: 44029,
    codice_belfiore: "F044",
  },
  {
    id: 3867,
    comune: "MASSIMENO",
    sigla: "TN",
    codice_elettorale: 1040831060,
    codice_istat: 22112,
    codice_belfiore: "F045",
  },
  {
    id: 3868,
    comune: "MASSIMINO",
    sigla: "SV",
    codice_elettorale: 1070740370,
    codice_istat: 9037,
    codice_belfiore: "F046",
  },
  {
    id: 3869,
    comune: "MASSINO VISCONTI",
    sigla: "NO",
    codice_elettorale: 1010520880,
    codice_istat: 3093,
    codice_belfiore: "F047",
  },
  {
    id: 3870,
    comune: "MASSIOLA",
    sigla: "VB",
    codice_elettorale: 1011020430,
    codice_istat: 103043,
    codice_belfiore: "F048",
  },
  {
    id: 3871,
    comune: "MASULLAS",
    sigla: "OR",
    codice_elettorale: 4200950260,
    codice_istat: 95026,
    codice_belfiore: "F050",
  },
  {
    id: 3872,
    comune: "MATELICA",
    sigla: "MC",
    codice_elettorale: 2110440240,
    codice_istat: 43024,
    codice_belfiore: "F051",
  },
  {
    id: 3873,
    comune: "MATERA",
    sigla: "MT",
    codice_elettorale: 3170470140,
    codice_istat: 77014,
    codice_belfiore: "F052",
  },
  {
    id: 3874,
    comune: "MATHI",
    sigla: "TO",
    codice_elettorale: 1010811440,
    codice_istat: 1146,
    codice_belfiore: "F053",
  },
  {
    id: 3875,
    comune: "MATINO",
    sigla: "LE",
    codice_elettorale: 3160410410,
    codice_istat: 75042,
    codice_belfiore: "F054",
  },
  {
    id: 3876,
    comune: "MATRICE",
    sigla: "CB",
    codice_elettorale: 3140190370,
    codice_istat: 70037,
    codice_belfiore: "F055",
  },
  {
    id: 3877,
    comune: "MATTIE",
    sigla: "TO",
    codice_elettorale: 1010811450,
    codice_istat: 1147,
    codice_belfiore: "F058",
  },
  {
    id: 3878,
    comune: "MATTINATA",
    sigla: "FG",
    codice_elettorale: 3160310291,
    codice_istat: 71031,
    codice_belfiore: "F059",
  },
  {
    id: 3879,
    comune: "MAZARA DEL VALLO",
    sigla: "TP",
    codice_elettorale: 4190820130,
    codice_istat: 81012,
    codice_belfiore: "F061",
  },
  {
    id: 3880,
    comune: "MAZZANO",
    sigla: "BS",
    codice_elettorale: 1030150990,
    codice_istat: 17107,
    codice_belfiore: "F063",
  },
  {
    id: 3881,
    comune: "MAZZANO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700570,
    codice_istat: 58058,
    codice_belfiore: "F064",
  },
  {
    id: 3882,
    comune: "MAZZARINO",
    sigla: "CL",
    codice_elettorale: 4190180090,
    codice_istat: 85009,
    codice_belfiore: "F065",
  },
  {
    id: 3883,
    comune: "MAZZARRA' SANT'ANDREA",
    sigla: "ME",
    codice_elettorale: 4190480450,
    codice_istat: 83046,
    codice_belfiore: "F066",
  },
  {
    id: 3884,
    comune: "MAZZARRONE",
    sigla: "CT",
    codice_elettorale: 4190210241,
    codice_istat: 87056,
    codice_belfiore: "M271",
  },
  {
    id: 3885,
    comune: "MAZZE'",
    sigla: "TO",
    codice_elettorale: 1010811460,
    codice_istat: 1148,
    codice_belfiore: "F067",
  },
  {
    id: 3886,
    comune: "MAZZIN",
    sigla: "TN",
    codice_elettorale: 1040831070,
    codice_istat: 22113,
    codice_belfiore: "F068",
  },
  {
    id: 3887,
    comune: "MAZZO DI VALTELLINA",
    sigla: "SO",
    codice_elettorale: 1030770400,
    codice_istat: 14040,
    codice_belfiore: "F070",
  },
  {
    id: 3888,
    comune: "MEANA DI SUSA",
    sigla: "TO",
    codice_elettorale: 1010811470,
    codice_istat: 1149,
    codice_belfiore: "F074",
  },
  {
    id: 3889,
    comune: "MEANA SARDO",
    sigla: "NU",
    codice_elettorale: 4200530450,
    codice_istat: 91047,
    codice_belfiore: "F073",
  },
  {
    id: 3890,
    comune: "MEDA",
    sigla: "MB",
    codice_elettorale: 1031040300,
    codice_istat: 108030,
    codice_belfiore: "F078",
  },
  {
    id: 3891,
    comune: "MEDE",
    sigla: "PV",
    codice_elettorale: 1030570850,
    codice_istat: 18088,
    codice_belfiore: "F080",
  },
  {
    id: 3892,
    comune: "MEDEA",
    sigla: "GO",
    codice_elettorale: 1060350101,
    codice_istat: 31011,
    codice_belfiore: "F081",
  },
  {
    id: 3893,
    comune: "MEDESANO",
    sigla: "PR",
    codice_elettorale: 1080560200,
    codice_istat: 34020,
    codice_belfiore: "F082",
  },
  {
    id: 3894,
    comune: "MEDICINA",
    sigla: "BO",
    codice_elettorale: 1080130370,
    codice_istat: 37037,
    codice_belfiore: "F083",
  },
  {
    id: 3895,
    comune: "MEDIGLIA",
    sigla: "MI",
    codice_elettorale: 1030491380,
    codice_istat: 15139,
    codice_belfiore: "F084",
  },
  {
    id: 3896,
    comune: "MEDOLAGO",
    sigla: "BG",
    codice_elettorale: 1030121281,
    codice_istat: 16250,
    codice_belfiore: "F085",
  },
  {
    id: 3897,
    comune: "MEDOLE",
    sigla: "MN",
    codice_elettorale: 1030450340,
    codice_istat: 20034,
    codice_belfiore: "F086",
  },
  {
    id: 3898,
    comune: "MEDOLLA",
    sigla: "MO",
    codice_elettorale: 1080500210,
    codice_istat: 36021,
    codice_belfiore: "F087",
  },
  {
    id: 3899,
    comune: "MEDUNA DI LIVENZA",
    sigla: "TV",
    codice_elettorale: 1050840400,
    codice_istat: 26041,
    codice_belfiore: "F088",
  },
  {
    id: 3900,
    comune: "MEDUNO",
    sigla: "PN",
    codice_elettorale: 1060930260,
    codice_istat: 93026,
    codice_belfiore: "F089",
  },
  {
    id: 3901,
    comune: "MEGLIADINO SAN VITALE",
    sigla: "PD",
    codice_elettorale: 1050540520,
    codice_istat: 28052,
    codice_belfiore: "F092",
  },
  {
    id: 3902,
    comune: "MEINA",
    sigla: "NO",
    codice_elettorale: 1010520890,
    codice_istat: 3095,
    codice_belfiore: "F093",
  },
  {
    id: 3903,
    comune: "MELARA",
    sigla: "RO",
    codice_elettorale: 1050710320,
    codice_istat: 29032,
    codice_belfiore: "F095",
  },
  {
    id: 3904,
    comune: "MELAZZO",
    sigla: "AL",
    codice_elettorale: 1010020900,
    codice_istat: 6092,
    codice_belfiore: "F096",
  },
  {
    id: 3905,
    comune: "MELDOLA",
    sigla: "FC",
    codice_elettorale: 1080320180,
    codice_istat: 40019,
    codice_belfiore: "F097",
  },
  {
    id: 3906,
    comune: "MELE",
    sigla: "GE",
    codice_elettorale: 1070340330,
    codice_istat: 10033,
    codice_belfiore: "F098",
  },
  {
    id: 3907,
    comune: "MELEGNANO",
    sigla: "MI",
    codice_elettorale: 1030491390,
    codice_istat: 15140,
    codice_belfiore: "F100",
  },
  {
    id: 3908,
    comune: "MELENDUGNO",
    sigla: "LE",
    codice_elettorale: 3160410420,
    codice_istat: 75043,
    codice_belfiore: "F101",
  },
  {
    id: 3909,
    comune: "MELETI",
    sigla: "LO",
    codice_elettorale: 1030990380,
    codice_istat: 98038,
    codice_belfiore: "F102",
  },
  {
    id: 3910,
    comune: "MELFI",
    sigla: "PZ",
    codice_elettorale: 3170640470,
    codice_istat: 76048,
    codice_belfiore: "F104",
  },
  {
    id: 3911,
    comune: "MELICUCCA'",
    sigla: "RC",
    codice_elettorale: 3180670480,
    codice_istat: 80048,
    codice_belfiore: "F105",
  },
  {
    id: 3912,
    comune: "MELICUCCO",
    sigla: "RC",
    codice_elettorale: 3180670490,
    codice_istat: 80049,
    codice_belfiore: "F106",
  },
  {
    id: 3913,
    comune: "MELILLI",
    sigla: "SR",
    codice_elettorale: 4190760120,
    codice_istat: 89012,
    codice_belfiore: "F107",
  },
  {
    id: 3914,
    comune: "MELISSA",
    sigla: "KR",
    codice_elettorale: 3180970140,
    codice_istat: 101014,
    codice_belfiore: "F108",
  },
  {
    id: 3915,
    comune: "MELISSANO",
    sigla: "LE",
    codice_elettorale: 3160410430,
    codice_istat: 75044,
    codice_belfiore: "F109",
  },
  {
    id: 3916,
    comune: "MELITO DI NAPOLI",
    sigla: "NA",
    codice_elettorale: 3150510450,
    codice_istat: 63045,
    codice_belfiore: "F111",
  },
  {
    id: 3917,
    comune: "MELITO DI PORTO SALVO",
    sigla: "RC",
    codice_elettorale: 3180670500,
    codice_istat: 80050,
    codice_belfiore: "F112",
  },
  {
    id: 3918,
    comune: "MELITO IRPINO",
    sigla: "AV",
    codice_elettorale: 3150080480,
    codice_istat: 64048,
    codice_belfiore: "F110",
  },
  {
    id: 3919,
    comune: "MELIZZANO",
    sigla: "BN",
    codice_elettorale: 3150110380,
    codice_istat: 62039,
    codice_belfiore: "F113",
  },
  {
    id: 3920,
    comune: "MELLE",
    sigla: "CN",
    codice_elettorale: 1010271220,
    codice_istat: 4122,
    codice_belfiore: "F114",
  },
  {
    id: 3921,
    comune: "MELLO",
    sigla: "SO",
    codice_elettorale: 1030770410,
    codice_istat: 14041,
    codice_belfiore: "F115",
  },
  {
    id: 3922,
    comune: "MELPIGNANO",
    sigla: "LE",
    codice_elettorale: 3160410440,
    codice_istat: 75045,
    codice_belfiore: "F117",
  },
  {
    id: 3923,
    comune: "MELTINA/MOLTEN",
    sigla: "BZ",
    codice_elettorale: 1040140470,
    codice_istat: 21050,
    codice_belfiore: "F118",
  },
  {
    id: 3924,
    comune: "MELZO",
    sigla: "MI",
    codice_elettorale: 1030491410,
    codice_istat: 15142,
    codice_belfiore: "F119",
  },
  {
    id: 3925,
    comune: "MENAGGIO",
    sigla: "CO",
    codice_elettorale: 1030241390,
    codice_istat: 13145,
    codice_belfiore: "F120",
  },
  {
    id: 3926,
    comune: "MENCONICO",
    sigla: "PV",
    codice_elettorale: 1030570860,
    codice_istat: 18089,
    codice_belfiore: "F122",
  },
  {
    id: 3927,
    comune: "MENDATICA",
    sigla: "IM",
    codice_elettorale: 1070370310,
    codice_istat: 8034,
    codice_belfiore: "F123",
  },
  {
    id: 3928,
    comune: "MENDICINO",
    sigla: "CS",
    codice_elettorale: 3180250800,
    codice_istat: 78079,
    codice_belfiore: "F125",
  },
  {
    id: 3929,
    comune: "MENFI",
    sigla: "AG",
    codice_elettorale: 4190010230,
    codice_istat: 84023,
    codice_belfiore: "F126",
  },
  {
    id: 3930,
    comune: "MENTANA",
    sigla: "RM",
    codice_elettorale: 2120700580,
    codice_istat: 58059,
    codice_belfiore: "F127",
  },
  {
    id: 3931,
    comune: "MEOLO",
    sigla: "VE",
    codice_elettorale: 1050870220,
    codice_istat: 27022,
    codice_belfiore: "F130",
  },
  {
    id: 3932,
    comune: "MERANA",
    sigla: "AL",
    codice_elettorale: 1010020910,
    codice_istat: 6093,
    codice_belfiore: "F131",
  },
  {
    id: 3933,
    comune: "MERANO/MERAN",
    sigla: "BZ",
    codice_elettorale: 1040140480,
    codice_istat: 21051,
    codice_belfiore: "F132",
  },
  {
    id: 3934,
    comune: "MERATE",
    sigla: "LC",
    codice_elettorale: 1030980480,
    codice_istat: 97048,
    codice_belfiore: "F133",
  },
  {
    id: 3935,
    comune: "MERCALLO",
    sigla: "VA",
    codice_elettorale: 1030860860,
    codice_istat: 12101,
    codice_belfiore: "F134",
  },
  {
    id: 3936,
    comune: "MERCATELLO SUL METAURO",
    sigla: "PU",
    codice_elettorale: 2110590250,
    codice_istat: 41025,
    codice_belfiore: "F135",
  },
  {
    id: 3937,
    comune: "MERCATINO CONCA",
    sigla: "PU",
    codice_elettorale: 2110590260,
    codice_istat: 41026,
    codice_belfiore: "F136",
  },
  {
    id: 3938,
    comune: "MERCATO SAN SEVERINO",
    sigla: "SA",
    codice_elettorale: 3150720670,
    codice_istat: 65067,
    codice_belfiore: "F138",
  },
  {
    id: 3939,
    comune: "MERCATO SARACENO",
    sigla: "FC",
    codice_elettorale: 1080320190,
    codice_istat: 40020,
    codice_belfiore: "F139",
  },
  {
    id: 3940,
    comune: "MERCENASCO",
    sigla: "TO",
    codice_elettorale: 1010811480,
    codice_istat: 1150,
    codice_belfiore: "F140",
  },
  {
    id: 3941,
    comune: "MERCOGLIANO",
    sigla: "AV",
    codice_elettorale: 3150080490,
    codice_istat: 64049,
    codice_belfiore: "F141",
  },
  {
    id: 3942,
    comune: "MERETO DI TOMBA",
    sigla: "UD",
    codice_elettorale: 1060850580,
    codice_istat: 30058,
    codice_belfiore: "F144",
  },
  {
    id: 3943,
    comune: "MERGO",
    sigla: "AN",
    codice_elettorale: 2110030240,
    codice_istat: 42024,
    codice_belfiore: "F145",
  },
  {
    id: 3944,
    comune: "MERGOZZO",
    sigla: "VB",
    codice_elettorale: 1011020440,
    codice_istat: 103044,
    codice_belfiore: "F146",
  },
  {
    id: 3945,
    comune: "MERI'",
    sigla: "ME",
    codice_elettorale: 4190480460,
    codice_istat: 83047,
    codice_belfiore: "F147",
  },
  {
    id: 3946,
    comune: "MERLARA",
    sigla: "PD",
    codice_elettorale: 1050540530,
    codice_istat: 28053,
    codice_belfiore: "F148",
  },
  {
    id: 3947,
    comune: "MERLINO",
    sigla: "LO",
    codice_elettorale: 1030990390,
    codice_istat: 98039,
    codice_belfiore: "F149",
  },
  {
    id: 3948,
    comune: "MERONE",
    sigla: "CO",
    codice_elettorale: 1030241410,
    codice_istat: 13147,
    codice_belfiore: "F151",
  },
  {
    id: 3949,
    comune: "MESAGNE",
    sigla: "BR",
    codice_elettorale: 3160160100,
    codice_istat: 74010,
    codice_belfiore: "F152",
  },
  {
    id: 3950,
    comune: "MESE",
    sigla: "SO",
    codice_elettorale: 1030770430,
    codice_istat: 14043,
    codice_belfiore: "F153",
  },
  {
    id: 3951,
    comune: "MESENZANA",
    sigla: "VA",
    codice_elettorale: 1030860861,
    codice_istat: 12102,
    codice_belfiore: "F154",
  },
  {
    id: 3952,
    comune: "MESERO",
    sigla: "MI",
    codice_elettorale: 1030491430,
    codice_istat: 15144,
    codice_belfiore: "F155",
  },
  {
    id: 3953,
    comune: "MESOLA",
    sigla: "FE",
    codice_elettorale: 1080290130,
    codice_istat: 38014,
    codice_belfiore: "F156",
  },
  {
    id: 3954,
    comune: "MESORACA",
    sigla: "KR",
    codice_elettorale: 3180970150,
    codice_istat: 101015,
    codice_belfiore: "F157",
  },
  {
    id: 3955,
    comune: "MESSINA",
    sigla: "ME",
    codice_elettorale: 4190480470,
    codice_istat: 83048,
    codice_belfiore: "F158",
  },
  {
    id: 3956,
    comune: "MESTRINO",
    sigla: "PD",
    codice_elettorale: 1050540540,
    codice_istat: 28054,
    codice_belfiore: "F161",
  },
  {
    id: 3957,
    comune: "META",
    sigla: "NA",
    codice_elettorale: 3150510460,
    codice_istat: 63046,
    codice_belfiore: "F162",
  },
  {
    id: 3958,
    comune: "MEZZAGO",
    sigla: "MB",
    codice_elettorale: 1031040310,
    codice_istat: 108031,
    codice_belfiore: "F165",
  },
  {
    id: 3959,
    comune: "MEZZANA",
    sigla: "TN",
    codice_elettorale: 1040831080,
    codice_istat: 22114,
    codice_belfiore: "F168",
  },
  {
    id: 3960,
    comune: "MEZZANA BIGLI",
    sigla: "PV",
    codice_elettorale: 1030570870,
    codice_istat: 18090,
    codice_belfiore: "F170",
  },
  {
    id: 3961,
    comune: "MEZZANA MORTIGLIENGO",
    sigla: "BI",
    codice_elettorale: 1010960330,
    codice_istat: 96033,
    codice_belfiore: "F167",
  },
  {
    id: 3962,
    comune: "MEZZANA RABATTONE",
    sigla: "PV",
    codice_elettorale: 1030570880,
    codice_istat: 18091,
    codice_belfiore: "F171",
  },
  {
    id: 3963,
    comune: "MEZZANE DI SOTTO",
    sigla: "VR",
    codice_elettorale: 1050890470,
    codice_istat: 23047,
    codice_belfiore: "F172",
  },
  {
    id: 3964,
    comune: "MEZZANEGO",
    sigla: "GE",
    codice_elettorale: 1070340340,
    codice_istat: 10034,
    codice_belfiore: "F173",
  },
  {
    id: 3965,
    comune: "MEZZANINO",
    sigla: "PV",
    codice_elettorale: 1030570890,
    codice_istat: 18092,
    codice_belfiore: "F175",
  },
  {
    id: 3966,
    comune: "MEZZANO",
    sigla: "TN",
    codice_elettorale: 1040831090,
    codice_istat: 22115,
    codice_belfiore: "F176",
  },
  {
    id: 3967,
    comune: "MEZZENILE",
    sigla: "TO",
    codice_elettorale: 1010811500,
    codice_istat: 1152,
    codice_belfiore: "F182",
  },
  {
    id: 3968,
    comune: "MEZZOCORONA",
    sigla: "TN",
    codice_elettorale: 1040831100,
    codice_istat: 22116,
    codice_belfiore: "F183",
  },
  {
    id: 3969,
    comune: "MEZZOJUSO",
    sigla: "PA",
    codice_elettorale: 4190550450,
    codice_istat: 82047,
    codice_belfiore: "F184",
  },
  {
    id: 3970,
    comune: "MEZZOLDO",
    sigla: "BG",
    codice_elettorale: 1030121290,
    codice_istat: 16134,
    codice_belfiore: "F186",
  },
  {
    id: 3971,
    comune: "MEZZOLOMBARDO",
    sigla: "TN",
    codice_elettorale: 1040831110,
    codice_istat: 22117,
    codice_belfiore: "F187",
  },
  {
    id: 3972,
    comune: "MEZZOMERICO",
    sigla: "NO",
    codice_elettorale: 1010520910,
    codice_istat: 3097,
    codice_belfiore: "F188",
  },
  {
    id: 3973,
    comune: "MIAGLIANO",
    sigla: "BI",
    codice_elettorale: 1010960340,
    codice_istat: 96034,
    codice_belfiore: "F189",
  },
  {
    id: 3974,
    comune: "MIANE",
    sigla: "TV",
    codice_elettorale: 1050840410,
    codice_istat: 26042,
    codice_belfiore: "F190",
  },
  {
    id: 3975,
    comune: "MIASINO",
    sigla: "NO",
    codice_elettorale: 1010520920,
    codice_istat: 3098,
    codice_belfiore: "F191",
  },
  {
    id: 3976,
    comune: "MIAZZINA",
    sigla: "VB",
    codice_elettorale: 1011020450,
    codice_istat: 103045,
    codice_belfiore: "F192",
  },
  {
    id: 3977,
    comune: "MICIGLIANO",
    sigla: "RI",
    codice_elettorale: 2120690350,
    codice_istat: 57037,
    codice_belfiore: "F193",
  },
  {
    id: 3978,
    comune: "MIGGIANO",
    sigla: "LE",
    codice_elettorale: 3160410450,
    codice_istat: 75046,
    codice_belfiore: "F194",
  },
  {
    id: 3979,
    comune: "MIGLIANICO",
    sigla: "CH",
    codice_elettorale: 3130230500,
    codice_istat: 69050,
    codice_belfiore: "F196",
  },
  {
    id: 3980,
    comune: "MIGLIERINA",
    sigla: "CZ",
    codice_elettorale: 3180220740,
    codice_istat: 79077,
    codice_belfiore: "F200",
  },
  {
    id: 3981,
    comune: "MIGLIONICO",
    sigla: "MT",
    codice_elettorale: 3170470150,
    codice_istat: 77015,
    codice_belfiore: "F201",
  },
  {
    id: 3982,
    comune: "MIGNANEGO",
    sigla: "GE",
    codice_elettorale: 1070340350,
    codice_istat: 10035,
    codice_belfiore: "F202",
  },
  {
    id: 3983,
    comune: "MIGNANO MONTE LUNGO",
    sigla: "CE",
    codice_elettorale: 3150200510,
    codice_istat: 61051,
    codice_belfiore: "F203",
  },
  {
    id: 3984,
    comune: "MILANO",
    sigla: "MI",
    codice_elettorale: 1030491450,
    codice_istat: 15146,
    codice_belfiore: "F205",
  },
  {
    id: 3985,
    comune: "MILAZZO",
    sigla: "ME",
    codice_elettorale: 4190480480,
    codice_istat: 83049,
    codice_belfiore: "F206",
  },
  {
    id: 3986,
    comune: "MILENA",
    sigla: "CL",
    codice_elettorale: 4190180100,
    codice_istat: 85010,
    codice_belfiore: "E618",
  },
  {
    id: 3987,
    comune: "MILETO",
    sigla: "VV",
    codice_elettorale: 3181030210,
    codice_istat: 102021,
    codice_belfiore: "F207",
  },
  {
    id: 3988,
    comune: "MILIS",
    sigla: "OR",
    codice_elettorale: 4200950270,
    codice_istat: 95027,
    codice_belfiore: "F208",
  },
  {
    id: 3989,
    comune: "MILITELLO IN VAL DI CATANIA",
    sigla: "CT",
    codice_elettorale: 4190210250,
    codice_istat: 87025,
    codice_belfiore: "F209",
  },
  {
    id: 3990,
    comune: "MILITELLO ROSMARINO",
    sigla: "ME",
    codice_elettorale: 4190480490,
    codice_istat: 83050,
    codice_belfiore: "F210",
  },
  {
    id: 3991,
    comune: "MILLESIMO",
    sigla: "SV",
    codice_elettorale: 1070740380,
    codice_istat: 9038,
    codice_belfiore: "F213",
  },
  {
    id: 3992,
    comune: "MILO",
    sigla: "CT",
    codice_elettorale: 4190210251,
    codice_istat: 87026,
    codice_belfiore: "F214",
  },
  {
    id: 3993,
    comune: "MILZANO",
    sigla: "BS",
    codice_elettorale: 1030151000,
    codice_istat: 17108,
    codice_belfiore: "F216",
  },
  {
    id: 3994,
    comune: "MINEO",
    sigla: "CT",
    codice_elettorale: 4190210260,
    codice_istat: 87027,
    codice_belfiore: "F217",
  },
  {
    id: 3995,
    comune: "MINERBE",
    sigla: "VR",
    codice_elettorale: 1050890480,
    codice_istat: 23048,
    codice_belfiore: "F218",
  },
  {
    id: 3996,
    comune: "MINERBIO",
    sigla: "BO",
    codice_elettorale: 1080130380,
    codice_istat: 37038,
    codice_belfiore: "F219",
  },
  {
    id: 3997,
    comune: "MINERVINO DI LECCE",
    sigla: "LE",
    codice_elettorale: 3160410460,
    codice_istat: 75047,
    codice_belfiore: "F221",
  },
  {
    id: 3998,
    comune: "MINERVINO MURGE",
    sigla: "BT",
    codice_elettorale: 3161060060,
    codice_istat: 110006,
    codice_belfiore: "F220",
  },
  {
    id: 3999,
    comune: "MINORI",
    sigla: "SA",
    codice_elettorale: 3150720680,
    codice_istat: 65068,
    codice_belfiore: "F223",
  },
  {
    id: 4000,
    comune: "MINTURNO",
    sigla: "LT",
    codice_elettorale: 2120400140,
    codice_istat: 59014,
    codice_belfiore: "F224",
  },
  {
    id: 4001,
    comune: "MINUCCIANO",
    sigla: "LU",
    codice_elettorale: 2090430190,
    codice_istat: 46019,
    codice_belfiore: "F225",
  },
  {
    id: 4002,
    comune: "MIOGLIA",
    sigla: "SV",
    codice_elettorale: 1070740390,
    codice_istat: 9039,
    codice_belfiore: "F226",
  },
  {
    id: 4003,
    comune: "MIRA",
    sigla: "VE",
    codice_elettorale: 1050870230,
    codice_istat: 27023,
    codice_belfiore: "F229",
  },
  {
    id: 4004,
    comune: "MIRABELLA ECLANO",
    sigla: "AV",
    codice_elettorale: 3150080500,
    codice_istat: 64050,
    codice_belfiore: "F230",
  },
  {
    id: 4005,
    comune: "MIRABELLA IMBACCARI",
    sigla: "CT",
    codice_elettorale: 4190210270,
    codice_istat: 87028,
    codice_belfiore: "F231",
  },
  {
    id: 4006,
    comune: "MIRABELLO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020920,
    codice_istat: 6094,
    codice_belfiore: "F232",
  },
  {
    id: 4007,
    comune: "MIRABELLO SANNITICO",
    sigla: "CB",
    codice_elettorale: 3140190380,
    codice_istat: 70038,
    codice_belfiore: "F233",
  },
  {
    id: 4008,
    comune: "MIRADOLO TERME",
    sigla: "PV",
    codice_elettorale: 1030570900,
    codice_istat: 18093,
    codice_belfiore: "F238",
  },
  {
    id: 4009,
    comune: "MIRANDA",
    sigla: "IS",
    codice_elettorale: 3140940270,
    codice_istat: 94027,
    codice_belfiore: "F239",
  },
  {
    id: 4010,
    comune: "MIRANDOLA",
    sigla: "MO",
    codice_elettorale: 1080500220,
    codice_istat: 36022,
    codice_belfiore: "F240",
  },
  {
    id: 4011,
    comune: "MIRANO",
    sigla: "VE",
    codice_elettorale: 1050870240,
    codice_istat: 27024,
    codice_belfiore: "F241",
  },
  {
    id: 4012,
    comune: "MIRTO",
    sigla: "ME",
    codice_elettorale: 4190480500,
    codice_istat: 83051,
    codice_belfiore: "F242",
  },
  {
    id: 4013,
    comune: "MISANO ADRIATICO",
    sigla: "RN",
    codice_elettorale: 1081010050,
    codice_istat: 99005,
    codice_belfiore: "F244",
  },
  {
    id: 4014,
    comune: "MISANO DI GERA D'ADDA",
    sigla: "BG",
    codice_elettorale: 1030121300,
    codice_istat: 16135,
    codice_belfiore: "F243",
  },
  {
    id: 4015,
    comune: "MISILISCEMI",
    sigla: "TP",
    codice_elettorale: 4190820135,
    codice_istat: 81025,
    codice_belfiore: "M432",
  },
  {
    id: 4016,
    comune: "MISILMERI",
    sigla: "PA",
    codice_elettorale: 4190550460,
    codice_istat: 82048,
    codice_belfiore: "F246",
  },
  {
    id: 4017,
    comune: "MISINTO",
    sigla: "MB",
    codice_elettorale: 1031040320,
    codice_istat: 108032,
    codice_belfiore: "F247",
  },
  {
    id: 4018,
    comune: "MISSAGLIA",
    sigla: "LC",
    codice_elettorale: 1030980490,
    codice_istat: 97049,
    codice_belfiore: "F248",
  },
  {
    id: 4019,
    comune: "MISSANELLO",
    sigla: "PZ",
    codice_elettorale: 3170640480,
    codice_istat: 76049,
    codice_belfiore: "F249",
  },
  {
    id: 4020,
    comune: "MISTERBIANCO",
    sigla: "CT",
    codice_elettorale: 4190210280,
    codice_istat: 87029,
    codice_belfiore: "F250",
  },
  {
    id: 4021,
    comune: "MISTRETTA",
    sigla: "ME",
    codice_elettorale: 4190480510,
    codice_istat: 83052,
    codice_belfiore: "F251",
  },
  {
    id: 4022,
    comune: "MOASCA",
    sigla: "AT",
    codice_elettorale: 1010070630,
    codice_istat: 5063,
    codice_belfiore: "F254",
  },
  {
    id: 4023,
    comune: "MOCONESI",
    sigla: "GE",
    codice_elettorale: 1070340360,
    codice_istat: 10036,
    codice_belfiore: "F256",
  },
  {
    id: 4024,
    comune: "MODENA",
    sigla: "MO",
    codice_elettorale: 1080500230,
    codice_istat: 36023,
    codice_belfiore: "F257",
  },
  {
    id: 4025,
    comune: "MODICA",
    sigla: "RG",
    codice_elettorale: 4190650060,
    codice_istat: 88006,
    codice_belfiore: "F258",
  },
  {
    id: 4026,
    comune: "MODIGLIANA",
    sigla: "FC",
    codice_elettorale: 1080320210,
    codice_istat: 40022,
    codice_belfiore: "F259",
  },
  {
    id: 4027,
    comune: "MODOLO",
    sigla: "OR",
    codice_elettorale: 4200950276,
    codice_istat: 95084,
    codice_belfiore: "F261",
  },
  {
    id: 4028,
    comune: "MODUGNO",
    sigla: "BA",
    codice_elettorale: 3160090270,
    codice_istat: 72027,
    codice_belfiore: "F262",
  },
  {
    id: 4029,
    comune: "MOENA",
    sigla: "TN",
    codice_elettorale: 1040831120,
    codice_istat: 22118,
    codice_belfiore: "F263",
  },
  {
    id: 4030,
    comune: "MOGGIO",
    sigla: "LC",
    codice_elettorale: 1030980500,
    codice_istat: 97050,
    codice_belfiore: "F265",
  },
  {
    id: 4031,
    comune: "MOGGIO UDINESE",
    sigla: "UD",
    codice_elettorale: 1060850590,
    codice_istat: 30059,
    codice_belfiore: "F266",
  },
  {
    id: 4032,
    comune: "MOGLIA",
    sigla: "MN",
    codice_elettorale: 1030450350,
    codice_istat: 20035,
    codice_belfiore: "F267",
  },
  {
    id: 4033,
    comune: "MOGLIANO",
    sigla: "MC",
    codice_elettorale: 2110440250,
    codice_istat: 43025,
    codice_belfiore: "F268",
  },
  {
    id: 4034,
    comune: "MOGLIANO VENETO",
    sigla: "TV",
    codice_elettorale: 1050840420,
    codice_istat: 26043,
    codice_belfiore: "F269",
  },
  {
    id: 4035,
    comune: "MOGORELLA",
    sigla: "OR",
    codice_elettorale: 4200950280,
    codice_istat: 95028,
    codice_belfiore: "F270",
  },
  {
    id: 4036,
    comune: "MOGORO",
    sigla: "OR",
    codice_elettorale: 4200950290,
    codice_istat: 95029,
    codice_belfiore: "F272",
  },
  {
    id: 4037,
    comune: "MOIANO",
    sigla: "BN",
    codice_elettorale: 3150110390,
    codice_istat: 62040,
    codice_belfiore: "F274",
  },
  {
    id: 4038,
    comune: "MOIMACCO",
    sigla: "UD",
    codice_elettorale: 1060850600,
    codice_istat: 30060,
    codice_belfiore: "F275",
  },
  {
    id: 4039,
    comune: "MOIO ALCANTARA",
    sigla: "ME",
    codice_elettorale: 4190480520,
    codice_istat: 83053,
    codice_belfiore: "F277",
  },
  {
    id: 4040,
    comune: "MOIO DE' CALVI",
    sigla: "BG",
    codice_elettorale: 1030121301,
    codice_istat: 16136,
    codice_belfiore: "F276",
  },
  {
    id: 4041,
    comune: "MOIO DELLA CIVITELLA",
    sigla: "SA",
    codice_elettorale: 3150720690,
    codice_istat: 65069,
    codice_belfiore: "F278",
  },
  {
    id: 4042,
    comune: "MOIOLA",
    sigla: "CN",
    codice_elettorale: 1010271230,
    codice_istat: 4123,
    codice_belfiore: "F279",
  },
  {
    id: 4043,
    comune: "MOLA DI BARI",
    sigla: "BA",
    codice_elettorale: 3160090280,
    codice_istat: 72028,
    codice_belfiore: "F280",
  },
  {
    id: 4044,
    comune: "MOLARE",
    sigla: "AL",
    codice_elettorale: 1010020930,
    codice_istat: 6095,
    codice_belfiore: "F281",
  },
  {
    id: 4045,
    comune: "MOLAZZANA",
    sigla: "LU",
    codice_elettorale: 2090430200,
    codice_istat: 46020,
    codice_belfiore: "F283",
  },
  {
    id: 4046,
    comune: "MOLFETTA",
    sigla: "BA",
    codice_elettorale: 3160090290,
    codice_istat: 72029,
    codice_belfiore: "F284",
  },
  {
    id: 4047,
    comune: "MOLINA ATERNO",
    sigla: "AQ",
    codice_elettorale: 3130380550,
    codice_istat: 66055,
    codice_belfiore: "M255",
  },
  {
    id: 4048,
    comune: "MOLINARA",
    sigla: "BN",
    codice_elettorale: 3150110400,
    codice_istat: 62041,
    codice_belfiore: "F287",
  },
  {
    id: 4049,
    comune: "MOLINELLA",
    sigla: "BO",
    codice_elettorale: 1080130390,
    codice_istat: 37039,
    codice_belfiore: "F288",
  },
  {
    id: 4050,
    comune: "MOLINI DI TRIORA",
    sigla: "IM",
    codice_elettorale: 1070370320,
    codice_istat: 8035,
    codice_belfiore: "F290",
  },
  {
    id: 4051,
    comune: "MOLINO DEI TORTI",
    sigla: "AL",
    codice_elettorale: 1010020940,
    codice_istat: 6096,
    codice_belfiore: "F293",
  },
  {
    id: 4052,
    comune: "MOLISE",
    sigla: "CB",
    codice_elettorale: 3140190390,
    codice_istat: 70039,
    codice_belfiore: "F294",
  },
  {
    id: 4053,
    comune: "MOLITERNO",
    sigla: "PZ",
    codice_elettorale: 3170640490,
    codice_istat: 76050,
    codice_belfiore: "F295",
  },
  {
    id: 4054,
    comune: "MOLLIA",
    sigla: "VC",
    codice_elettorale: 1010880770,
    codice_istat: 2078,
    codice_belfiore: "F297",
  },
  {
    id: 4055,
    comune: "MOLOCHIO",
    sigla: "RC",
    codice_elettorale: 3180670510,
    codice_istat: 80051,
    codice_belfiore: "F301",
  },
  {
    id: 4056,
    comune: "MOLTENO",
    sigla: "LC",
    codice_elettorale: 1030980510,
    codice_istat: 97051,
    codice_belfiore: "F304",
  },
  {
    id: 4057,
    comune: "MOLTRASIO",
    sigla: "CO",
    codice_elettorale: 1030241460,
    codice_istat: 13152,
    codice_belfiore: "F305",
  },
  {
    id: 4058,
    comune: "MOLVENO",
    sigla: "TN",
    codice_elettorale: 1040831140,
    codice_istat: 22120,
    codice_belfiore: "F307",
  },
  {
    id: 4059,
    comune: "MOMBALDONE",
    sigla: "AT",
    codice_elettorale: 1010070640,
    codice_istat: 5064,
    codice_belfiore: "F308",
  },
  {
    id: 4060,
    comune: "MOMBARCARO",
    sigla: "CN",
    codice_elettorale: 1010271240,
    codice_istat: 4124,
    codice_belfiore: "F309",
  },
  {
    id: 4061,
    comune: "MOMBAROCCIO",
    sigla: "PU",
    codice_elettorale: 2110590270,
    codice_istat: 41027,
    codice_belfiore: "F310",
  },
  {
    id: 4062,
    comune: "MOMBARUZZO",
    sigla: "AT",
    codice_elettorale: 1010070650,
    codice_istat: 5065,
    codice_belfiore: "F311",
  },
  {
    id: 4063,
    comune: "MOMBASIGLIO",
    sigla: "CN",
    codice_elettorale: 1010271250,
    codice_istat: 4125,
    codice_belfiore: "F312",
  },
  {
    id: 4064,
    comune: "MOMBELLO DI TORINO",
    sigla: "TO",
    codice_elettorale: 1010811510,
    codice_istat: 1153,
    codice_belfiore: "F315",
  },
  {
    id: 4065,
    comune: "MOMBELLO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010020950,
    codice_istat: 6097,
    codice_belfiore: "F313",
  },
  {
    id: 4066,
    comune: "MOMBERCELLI",
    sigla: "AT",
    codice_elettorale: 1010070660,
    codice_istat: 5066,
    codice_belfiore: "F316",
  },
  {
    id: 4067,
    comune: "MOMO",
    sigla: "NO",
    codice_elettorale: 1010520940,
    codice_istat: 3100,
    codice_belfiore: "F317",
  },
  {
    id: 4068,
    comune: "MOMPANTERO",
    sigla: "TO",
    codice_elettorale: 1010811520,
    codice_istat: 1154,
    codice_belfiore: "F318",
  },
  {
    id: 4069,
    comune: "MOMPEO",
    sigla: "RI",
    codice_elettorale: 2120690360,
    codice_istat: 57038,
    codice_belfiore: "F319",
  },
  {
    id: 4070,
    comune: "MOMPERONE",
    sigla: "AL",
    codice_elettorale: 1010020960,
    codice_istat: 6098,
    codice_belfiore: "F320",
  },
  {
    id: 4071,
    comune: "MONACILIONI",
    sigla: "CB",
    codice_elettorale: 3140190400,
    codice_istat: 70040,
    codice_belfiore: "F322",
  },
  {
    id: 4072,
    comune: "MONALE",
    sigla: "AT",
    codice_elettorale: 1010070670,
    codice_istat: 5067,
    codice_belfiore: "F323",
  },
  {
    id: 4073,
    comune: "MONASTERACE",
    sigla: "RC",
    codice_elettorale: 3180670520,
    codice_istat: 80052,
    codice_belfiore: "F324",
  },
  {
    id: 4074,
    comune: "MONASTERO BORMIDA",
    sigla: "AT",
    codice_elettorale: 1010070680,
    codice_istat: 5068,
    codice_belfiore: "F325",
  },
  {
    id: 4075,
    comune: "MONASTERO DI LANZO",
    sigla: "TO",
    codice_elettorale: 1010811530,
    codice_istat: 1155,
    codice_belfiore: "F327",
  },
  {
    id: 4076,
    comune: "MONASTERO DI VASCO",
    sigla: "CN",
    codice_elettorale: 1010271260,
    codice_istat: 4126,
    codice_belfiore: "F326",
  },
  {
    id: 4077,
    comune: "MONASTEROLO CASOTTO",
    sigla: "CN",
    codice_elettorale: 1010271270,
    codice_istat: 4127,
    codice_belfiore: "F329",
  },
  {
    id: 4078,
    comune: "MONASTEROLO DEL CASTELLO",
    sigla: "BG",
    codice_elettorale: 1030121310,
    codice_istat: 16137,
    codice_belfiore: "F328",
  },
  {
    id: 4079,
    comune: "MONASTEROLO DI SAVIGLIANO",
    sigla: "CN",
    codice_elettorale: 1010271280,
    codice_istat: 4128,
    codice_belfiore: "F330",
  },
  {
    id: 4080,
    comune: "MONASTIER DI TREVISO",
    sigla: "TV",
    codice_elettorale: 1050840430,
    codice_istat: 26044,
    codice_belfiore: "F332",
  },
  {
    id: 4081,
    comune: "MONASTIR",
    sigla: "SU",
    codice_elettorale: 4201110410,
    codice_istat: 111041,
    codice_belfiore: "F333",
  },
  {
    id: 4082,
    comune: "MONCALIERI",
    sigla: "TO",
    codice_elettorale: 1010811540,
    codice_istat: 1156,
    codice_belfiore: "F335",
  },
  {
    id: 4083,
    comune: "MONCALVO",
    sigla: "AT",
    codice_elettorale: 1010070690,
    codice_istat: 5069,
    codice_belfiore: "F336",
  },
  {
    id: 4084,
    comune: "MONCENISIO",
    sigla: "TO",
    codice_elettorale: 1010811550,
    codice_istat: 1157,
    codice_belfiore: "D553",
  },
  {
    id: 4085,
    comune: "MONCESTINO",
    sigla: "AL",
    codice_elettorale: 1010020970,
    codice_istat: 6099,
    codice_belfiore: "F337",
  },
  {
    id: 4086,
    comune: "MONCHIERO",
    sigla: "CN",
    codice_elettorale: 1010271290,
    codice_istat: 4129,
    codice_belfiore: "F338",
  },
  {
    id: 4087,
    comune: "MONCHIO DELLE CORTI",
    sigla: "PR",
    codice_elettorale: 1080560220,
    codice_istat: 34022,
    codice_belfiore: "F340",
  },
  {
    id: 4088,
    comune: "MONCRIVELLO",
    sigla: "VC",
    codice_elettorale: 1010880780,
    codice_istat: 2079,
    codice_belfiore: "F342",
  },
  {
    id: 4089,
    comune: "MONCUCCO TORINESE",
    sigla: "AT",
    codice_elettorale: 1010070700,
    codice_istat: 5070,
    codice_belfiore: "F343",
  },
  {
    id: 4090,
    comune: "MONDAINO",
    sigla: "RN",
    codice_elettorale: 1081010060,
    codice_istat: 99006,
    codice_belfiore: "F346",
  },
  {
    id: 4091,
    comune: "MONDAVIO",
    sigla: "PU",
    codice_elettorale: 2110590280,
    codice_istat: 41028,
    codice_belfiore: "F347",
  },
  {
    id: 4092,
    comune: "MONDOLFO",
    sigla: "PU",
    codice_elettorale: 2110590290,
    codice_istat: 41029,
    codice_belfiore: "F348",
  },
  {
    id: 4093,
    comune: "MONDOVI'",
    sigla: "CN",
    codice_elettorale: 1010271300,
    codice_istat: 4130,
    codice_belfiore: "F351",
  },
  {
    id: 4094,
    comune: "MONDRAGONE",
    sigla: "CE",
    codice_elettorale: 3150200520,
    codice_istat: 61052,
    codice_belfiore: "F352",
  },
  {
    id: 4095,
    comune: "MONEGLIA",
    sigla: "GE",
    codice_elettorale: 1070340370,
    codice_istat: 10037,
    codice_belfiore: "F354",
  },
  {
    id: 4096,
    comune: "MONESIGLIO",
    sigla: "CN",
    codice_elettorale: 1010271310,
    codice_istat: 4131,
    codice_belfiore: "F355",
  },
  {
    id: 4097,
    comune: "MONFALCONE",
    sigla: "GO",
    codice_elettorale: 1060350110,
    codice_istat: 31012,
    codice_belfiore: "F356",
  },
  {
    id: 4098,
    comune: "MONFORTE D'ALBA",
    sigla: "CN",
    codice_elettorale: 1010271320,
    codice_istat: 4132,
    codice_belfiore: "F358",
  },
  {
    id: 4099,
    comune: "MONFORTE SAN GIORGIO",
    sigla: "ME",
    codice_elettorale: 4190480530,
    codice_istat: 83054,
    codice_belfiore: "F359",
  },
  {
    id: 4100,
    comune: "MONFUMO",
    sigla: "TV",
    codice_elettorale: 1050840440,
    codice_istat: 26045,
    codice_belfiore: "F360",
  },
  {
    id: 4101,
    comune: "MONGARDINO",
    sigla: "AT",
    codice_elettorale: 1010070710,
    codice_istat: 5071,
    codice_belfiore: "F361",
  },
  {
    id: 4102,
    comune: "MONGHIDORO",
    sigla: "BO",
    codice_elettorale: 1080130400,
    codice_istat: 37040,
    codice_belfiore: "F363",
  },
  {
    id: 4103,
    comune: "MONGIANA",
    sigla: "VV",
    codice_elettorale: 3181030220,
    codice_istat: 102022,
    codice_belfiore: "F364",
  },
  {
    id: 4104,
    comune: "MONGIARDINO LIGURE",
    sigla: "AL",
    codice_elettorale: 1010020980,
    codice_istat: 6100,
    codice_belfiore: "F365",
  },
  {
    id: 4105,
    comune: "MONGIUFFI MELIA",
    sigla: "ME",
    codice_elettorale: 4190480540,
    codice_istat: 83055,
    codice_belfiore: "F368",
  },
  {
    id: 4106,
    comune: "MONGRANDO",
    sigla: "BI",
    codice_elettorale: 1010960350,
    codice_istat: 96035,
    codice_belfiore: "F369",
  },
  {
    id: 4107,
    comune: "MONGRASSANO",
    sigla: "CS",
    codice_elettorale: 3180250810,
    codice_istat: 78080,
    codice_belfiore: "F370",
  },
  {
    id: 4108,
    comune: "MONGUELFO-TESIDO/WELSBERG-TAISTEN",
    sigla: "BZ",
    codice_elettorale: 1040140490,
    codice_istat: 21052,
    codice_belfiore: "F371",
  },
  {
    id: 4109,
    comune: "MONGUZZO",
    sigla: "CO",
    codice_elettorale: 1030241470,
    codice_istat: 13153,
    codice_belfiore: "F372",
  },
  {
    id: 4110,
    comune: "MONIGA DEL GARDA",
    sigla: "BS",
    codice_elettorale: 1030151010,
    codice_istat: 17109,
    codice_belfiore: "F373",
  },
  {
    id: 4111,
    comune: "MONLEALE",
    sigla: "AL",
    codice_elettorale: 1010020990,
    codice_istat: 6101,
    codice_belfiore: "F374",
  },
  {
    id: 4112,
    comune: "MONNO",
    sigla: "BS",
    codice_elettorale: 1030151020,
    codice_istat: 17110,
    codice_belfiore: "F375",
  },
  {
    id: 4113,
    comune: "MONOPOLI",
    sigla: "BA",
    codice_elettorale: 3160090300,
    codice_istat: 72030,
    codice_belfiore: "F376",
  },
  {
    id: 4114,
    comune: "MONREALE",
    sigla: "PA",
    codice_elettorale: 4190550470,
    codice_istat: 82049,
    codice_belfiore: "F377",
  },
  {
    id: 4115,
    comune: "MONRUPINO",
    sigla: "TS",
    codice_elettorale: 1060920020,
    codice_istat: 32002,
    codice_belfiore: "F378",
  },
  {
    id: 4116,
    comune: "MONSAMPIETRO MORICO",
    sigla: "FM",
    codice_elettorale: 2111050120,
    codice_istat: 109012,
    codice_belfiore: "F379",
  },
  {
    id: 4117,
    comune: "MONSAMPOLO DEL TRONTO",
    sigla: "AP",
    codice_elettorale: 2110060310,
    codice_istat: 44031,
    codice_belfiore: "F380",
  },
  {
    id: 4118,
    comune: "MONSANO",
    sigla: "AN",
    codice_elettorale: 2110030250,
    codice_istat: 42025,
    codice_belfiore: "F381",
  },
  {
    id: 4119,
    comune: "MONSELICE",
    sigla: "PD",
    codice_elettorale: 1050540550,
    codice_istat: 28055,
    codice_belfiore: "F382",
  },
  {
    id: 4120,
    comune: "MONSERRATO",
    sigla: "CA",
    codice_elettorale: 4200170381,
    codice_istat: 92109,
    codice_belfiore: "F383",
  },
  {
    id: 4121,
    comune: "MONSUMMANO TERME",
    sigla: "PT",
    codice_elettorale: 2090630090,
    codice_istat: 47009,
    codice_belfiore: "F384",
  },
  {
    id: 4122,
    comune: "MONTA'",
    sigla: "CN",
    codice_elettorale: 1010271330,
    codice_istat: 4133,
    codice_belfiore: "F385",
  },
  {
    id: 4123,
    comune: "MONTABONE",
    sigla: "AT",
    codice_elettorale: 1010070720,
    codice_istat: 5072,
    codice_belfiore: "F386",
  },
  {
    id: 4124,
    comune: "MONTACUTO",
    sigla: "AL",
    codice_elettorale: 1010021000,
    codice_istat: 6102,
    codice_belfiore: "F387",
  },
  {
    id: 4125,
    comune: "MONTAFIA",
    sigla: "AT",
    codice_elettorale: 1010070730,
    codice_istat: 5073,
    codice_belfiore: "F390",
  },
  {
    id: 4126,
    comune: "MONTAGANO",
    sigla: "CB",
    codice_elettorale: 3140190410,
    codice_istat: 70041,
    codice_belfiore: "F391",
  },
  {
    id: 4127,
    comune: "MONTAGNA IN VALTELLINA",
    sigla: "SO",
    codice_elettorale: 1030770440,
    codice_istat: 14044,
    codice_belfiore: "F393",
  },
  {
    id: 4128,
    comune: "MONTAGNA SULLA STRADA DEL VINO/MONTAN AN DER WEINSTRASSE",
    sigla: "BZ",
    codice_elettorale: 1040140500,
    codice_istat: 21053,
    codice_belfiore: "F392",
  },
  {
    id: 4129,
    comune: "MONTAGNANA",
    sigla: "PD",
    codice_elettorale: 1050540560,
    codice_istat: 28056,
    codice_belfiore: "F394",
  },
  {
    id: 4130,
    comune: "MONTAGNAREALE",
    sigla: "ME",
    codice_elettorale: 4190480550,
    codice_istat: 83056,
    codice_belfiore: "F395",
  },
  {
    id: 4131,
    comune: "MONTAGUTO",
    sigla: "AV",
    codice_elettorale: 3150080510,
    codice_istat: 64051,
    codice_belfiore: "F397",
  },
  {
    id: 4132,
    comune: "MONTAIONE",
    sigla: "FI",
    codice_elettorale: 2090300270,
    codice_istat: 48027,
    codice_belfiore: "F398",
  },
  {
    id: 4133,
    comune: "MONTALBANO ELICONA",
    sigla: "ME",
    codice_elettorale: 4190480560,
    codice_istat: 83057,
    codice_belfiore: "F400",
  },
  {
    id: 4134,
    comune: "MONTALBANO JONICO",
    sigla: "MT",
    codice_elettorale: 3170470160,
    codice_istat: 77016,
    codice_belfiore: "F399",
  },
  {
    id: 4135,
    comune: "MONTALCINO",
    sigla: "SI",
    codice_elettorale: 2090750141,
    codice_istat: 52037,
    codice_belfiore: "M378",
  },
  {
    id: 4136,
    comune: "MONTALDEO",
    sigla: "AL",
    codice_elettorale: 1010021010,
    codice_istat: 6103,
    codice_belfiore: "F403",
  },
  {
    id: 4137,
    comune: "MONTALDO BORMIDA",
    sigla: "AL",
    codice_elettorale: 1010021020,
    codice_istat: 6104,
    codice_belfiore: "F404",
  },
  {
    id: 4138,
    comune: "MONTALDO DI MONDOVI'",
    sigla: "CN",
    codice_elettorale: 1010271340,
    codice_istat: 4134,
    codice_belfiore: "F405",
  },
  {
    id: 4139,
    comune: "MONTALDO ROERO",
    sigla: "CN",
    codice_elettorale: 1010271350,
    codice_istat: 4135,
    codice_belfiore: "F408",
  },
  {
    id: 4140,
    comune: "MONTALDO SCARAMPI",
    sigla: "AT",
    codice_elettorale: 1010070740,
    codice_istat: 5074,
    codice_belfiore: "F409",
  },
  {
    id: 4141,
    comune: "MONTALDO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010811560,
    codice_istat: 1158,
    codice_belfiore: "F407",
  },
  {
    id: 4142,
    comune: "MONTALE",
    sigla: "PT",
    codice_elettorale: 2090630100,
    codice_istat: 47010,
    codice_belfiore: "F410",
  },
  {
    id: 4143,
    comune: "MONTALENGHE",
    sigla: "TO",
    codice_elettorale: 1010811570,
    codice_istat: 1159,
    codice_belfiore: "F411",
  },
  {
    id: 4144,
    comune: "MONTALLEGRO",
    sigla: "AG",
    codice_elettorale: 4190010240,
    codice_istat: 84024,
    codice_belfiore: "F414",
  },
  {
    id: 4145,
    comune: "MONTALTO CARPASIO",
    sigla: "IM",
    codice_elettorale: 1070370325,
    codice_istat: 8068,
    codice_belfiore: "M387",
  },
  {
    id: 4146,
    comune: "MONTALTO DELLE MARCHE",
    sigla: "AP",
    codice_elettorale: 2110060320,
    codice_istat: 44032,
    codice_belfiore: "F415",
  },
  {
    id: 4147,
    comune: "MONTALTO DI CASTRO",
    sigla: "VT",
    codice_elettorale: 2120910340,
    codice_istat: 56035,
    codice_belfiore: "F419",
  },
  {
    id: 4148,
    comune: "MONTALTO DORA",
    sigla: "TO",
    codice_elettorale: 1010811580,
    codice_istat: 1160,
    codice_belfiore: "F420",
  },
  {
    id: 4149,
    comune: "MONTALTO PAVESE",
    sigla: "PV",
    codice_elettorale: 1030570910,
    codice_istat: 18094,
    codice_belfiore: "F417",
  },
  {
    id: 4150,
    comune: "MONTALTO UFFUGO",
    sigla: "CS",
    codice_elettorale: 3180250820,
    codice_istat: 78081,
    codice_belfiore: "F416",
  },
  {
    id: 4151,
    comune: "MONTANARO",
    sigla: "TO",
    codice_elettorale: 1010811590,
    codice_istat: 1161,
    codice_belfiore: "F422",
  },
  {
    id: 4152,
    comune: "MONTANASO LOMBARDO",
    sigla: "LO",
    codice_elettorale: 1030990400,
    codice_istat: 98040,
    codice_belfiore: "F423",
  },
  {
    id: 4153,
    comune: "MONTANERA",
    sigla: "CN",
    codice_elettorale: 1010271360,
    codice_istat: 4136,
    codice_belfiore: "F424",
  },
  {
    id: 4154,
    comune: "MONTANO ANTILIA",
    sigla: "SA",
    codice_elettorale: 3150720700,
    codice_istat: 65070,
    codice_belfiore: "F426",
  },
  {
    id: 4155,
    comune: "MONTANO LUCINO",
    sigla: "CO",
    codice_elettorale: 1030241480,
    codice_istat: 13154,
    codice_belfiore: "F427",
  },
  {
    id: 4156,
    comune: "MONTAPPONE",
    sigla: "FM",
    codice_elettorale: 2111050130,
    codice_istat: 109013,
    codice_belfiore: "F428",
  },
  {
    id: 4157,
    comune: "MONTAQUILA",
    sigla: "IS",
    codice_elettorale: 3140940280,
    codice_istat: 94028,
    codice_belfiore: "F429",
  },
  {
    id: 4158,
    comune: "MONTASOLA",
    sigla: "RI",
    codice_elettorale: 2120690370,
    codice_istat: 57039,
    codice_belfiore: "F430",
  },
  {
    id: 4159,
    comune: "MONTAURO",
    sigla: "CZ",
    codice_elettorale: 3180220770,
    codice_istat: 79080,
    codice_belfiore: "F432",
  },
  {
    id: 4160,
    comune: "MONTAZZOLI",
    sigla: "CH",
    codice_elettorale: 3130230510,
    codice_istat: 69051,
    codice_belfiore: "F433",
  },
  {
    id: 4161,
    comune: "MONTE ARGENTARIO",
    sigla: "GR",
    codice_elettorale: 2090360150,
    codice_istat: 53016,
    codice_belfiore: "F437",
  },
  {
    id: 4162,
    comune: "MONTE CASTELLO DI VIBIO",
    sigla: "PG",
    codice_elettorale: 2100580290,
    codice_istat: 54029,
    codice_belfiore: "F456",
  },
  {
    id: 4163,
    comune: "MONTE CAVALLO",
    sigla: "MC",
    codice_elettorale: 2110440270,
    codice_istat: 43027,
    codice_belfiore: "F460",
  },
  {
    id: 4164,
    comune: "MONTE CERIGNONE",
    sigla: "PU",
    codice_elettorale: 2110590310,
    codice_istat: 41031,
    codice_belfiore: "F467",
  },
  {
    id: 4165,
    comune: "MONTE COMPATRI",
    sigla: "RM",
    codice_elettorale: 2120700590,
    codice_istat: 58060,
    codice_belfiore: "F477",
  },
  {
    id: 4166,
    comune: "MONTE CREMASCO",
    sigla: "CR",
    codice_elettorale: 1030260570,
    codice_istat: 19058,
    codice_belfiore: "F434",
  },
  {
    id: 4167,
    comune: "MONTE DI MALO",
    sigla: "VI",
    codice_elettorale: 1050900630,
    codice_istat: 24063,
    codice_belfiore: "F486",
  },
  {
    id: 4168,
    comune: "MONTE DI PROCIDA",
    sigla: "NA",
    codice_elettorale: 3150510470,
    codice_istat: 63047,
    codice_belfiore: "F488",
  },
  {
    id: 4169,
    comune: "MONTE GIBERTO",
    sigla: "FM",
    codice_elettorale: 2111050160,
    codice_istat: 109016,
    codice_belfiore: "F517",
  },
  {
    id: 4170,
    comune: "MONTE GRIMANO TERME",
    sigla: "PU",
    codice_elettorale: 2110590350,
    codice_istat: 41035,
    codice_belfiore: "F524",
  },
  {
    id: 4171,
    comune: "MONTE ISOLA",
    sigla: "BS",
    codice_elettorale: 1030151030,
    codice_istat: 17111,
    codice_belfiore: "F532",
  },
  {
    id: 4172,
    comune: "MONTE MARENZO",
    sigla: "LC",
    codice_elettorale: 1030980520,
    codice_istat: 97052,
    codice_belfiore: "F561",
  },
  {
    id: 4173,
    comune: "MONTE PORZIO",
    sigla: "PU",
    codice_elettorale: 2110590380,
    codice_istat: 41038,
    codice_belfiore: "F589",
  },
  {
    id: 4174,
    comune: "MONTE PORZIO CATONE",
    sigla: "RM",
    codice_elettorale: 2120700630,
    codice_istat: 58064,
    codice_belfiore: "F590",
  },
  {
    id: 4175,
    comune: "MONTE RINALDO",
    sigla: "FM",
    codice_elettorale: 2111050210,
    codice_istat: 109021,
    codice_belfiore: "F599",
  },
  {
    id: 4176,
    comune: "MONTE ROBERTO",
    sigla: "AN",
    codice_elettorale: 2110030290,
    codice_istat: 42029,
    codice_belfiore: "F600",
  },
  {
    id: 4177,
    comune: "MONTE ROMANO",
    sigla: "VT",
    codice_elettorale: 2120910360,
    codice_istat: 56037,
    codice_belfiore: "F603",
  },
  {
    id: 4178,
    comune: "MONTE SAN BIAGIO",
    sigla: "LT",
    codice_elettorale: 2120400150,
    codice_istat: 59015,
    codice_belfiore: "F616",
  },
  {
    id: 4179,
    comune: "MONTE SAN GIACOMO",
    sigla: "SA",
    codice_elettorale: 3150720750,
    codice_istat: 65075,
    codice_belfiore: "F618",
  },
  {
    id: 4180,
    comune: "MONTE SAN GIOVANNI CAMPANO",
    sigla: "FR",
    codice_elettorale: 2120330440,
    codice_istat: 60044,
    codice_belfiore: "F620",
  },
  {
    id: 4181,
    comune: "MONTE SAN GIOVANNI IN SABINA",
    sigla: "RI",
    codice_elettorale: 2120690410,
    codice_istat: 57043,
    codice_belfiore: "F619",
  },
  {
    id: 4182,
    comune: "MONTE SAN GIUSTO",
    sigla: "MC",
    codice_elettorale: 2110440310,
    codice_istat: 43031,
    codice_belfiore: "F621",
  },
  {
    id: 4183,
    comune: "MONTE SAN MARTINO",
    sigla: "MC",
    codice_elettorale: 2110440320,
    codice_istat: 43032,
    codice_belfiore: "F622",
  },
  {
    id: 4184,
    comune: "MONTE SAN PIETRANGELI",
    sigla: "FM",
    codice_elettorale: 2111050230,
    codice_istat: 109023,
    codice_belfiore: "F626",
  },
  {
    id: 4185,
    comune: "MONTE SAN PIETRO",
    sigla: "BO",
    codice_elettorale: 1080130420,
    codice_istat: 37042,
    codice_belfiore: "F627",
  },
  {
    id: 4186,
    comune: "MONTE SAN SAVINO",
    sigla: "AR",
    codice_elettorale: 2090050250,
    codice_istat: 51025,
    codice_belfiore: "F628",
  },
  {
    id: 4187,
    comune: "MONTE SAN VITO",
    sigla: "AN",
    codice_elettorale: 2110030300,
    codice_istat: 42030,
    codice_belfiore: "F634",
  },
  {
    id: 4188,
    comune: "MONTE SANT'ANGELO",
    sigla: "FG",
    codice_elettorale: 3160310310,
    codice_istat: 71033,
    codice_belfiore: "F631",
  },
  {
    id: 4189,
    comune: "MONTE SANTA MARIA TIBERINA",
    sigla: "PG",
    codice_elettorale: 2100580320,
    codice_istat: 54032,
    codice_belfiore: "F629",
  },
  {
    id: 4190,
    comune: "MONTE URANO",
    sigla: "FM",
    codice_elettorale: 2111050240,
    codice_istat: 109024,
    codice_belfiore: "F653",
  },
  {
    id: 4191,
    comune: "MONTE VIDON COMBATTE",
    sigla: "FM",
    codice_elettorale: 2111050250,
    codice_istat: 109025,
    codice_belfiore: "F664",
  },
  {
    id: 4192,
    comune: "MONTE VIDON CORRADO",
    sigla: "FM",
    codice_elettorale: 2111050260,
    codice_istat: 109026,
    codice_belfiore: "F665",
  },
  {
    id: 4193,
    comune: "MONTEBELLO DELLA BATTAGLIA",
    sigla: "PV",
    codice_elettorale: 1030570920,
    codice_istat: 18095,
    codice_belfiore: "F440",
  },
  {
    id: 4194,
    comune: "MONTEBELLO DI BERTONA",
    sigla: "PE",
    codice_elettorale: 3130600230,
    codice_istat: 68023,
    codice_belfiore: "F441",
  },
  {
    id: 4195,
    comune: "MONTEBELLO JONICO",
    sigla: "RC",
    codice_elettorale: 3180670530,
    codice_istat: 80053,
    codice_belfiore: "D746",
  },
  {
    id: 4196,
    comune: "MONTEBELLO SUL SANGRO",
    sigla: "CH",
    codice_elettorale: 3130230511,
    codice_istat: 69009,
    codice_belfiore: "B268",
  },
  {
    id: 4197,
    comune: "MONTEBELLO VICENTINO",
    sigla: "VI",
    codice_elettorale: 1050900600,
    codice_istat: 24060,
    codice_belfiore: "F442",
  },
  {
    id: 4198,
    comune: "MONTEBELLUNA",
    sigla: "TV",
    codice_elettorale: 1050840450,
    codice_istat: 26046,
    codice_belfiore: "F443",
  },
  {
    id: 4199,
    comune: "MONTEBRUNO",
    sigla: "GE",
    codice_elettorale: 1070340380,
    codice_istat: 10038,
    codice_belfiore: "F445",
  },
  {
    id: 4200,
    comune: "MONTEBUONO",
    sigla: "RI",
    codice_elettorale: 2120690380,
    codice_istat: 57040,
    codice_belfiore: "F446",
  },
  {
    id: 4201,
    comune: "MONTECALVO IN FOGLIA",
    sigla: "PU",
    codice_elettorale: 2110590300,
    codice_istat: 41030,
    codice_belfiore: "F450",
  },
  {
    id: 4202,
    comune: "MONTECALVO IRPINO",
    sigla: "AV",
    codice_elettorale: 3150080520,
    codice_istat: 64052,
    codice_belfiore: "F448",
  },
  {
    id: 4203,
    comune: "MONTECALVO VERSIGGIA",
    sigla: "PV",
    codice_elettorale: 1030570930,
    codice_istat: 18096,
    codice_belfiore: "F449",
  },
  {
    id: 4204,
    comune: "MONTECARLO",
    sigla: "LU",
    codice_elettorale: 2090430210,
    codice_istat: 46021,
    codice_belfiore: "F452",
  },
  {
    id: 4205,
    comune: "MONTECAROTTO",
    sigla: "AN",
    codice_elettorale: 2110030260,
    codice_istat: 42026,
    codice_belfiore: "F453",
  },
  {
    id: 4206,
    comune: "MONTECASSIANO",
    sigla: "MC",
    codice_elettorale: 2110440260,
    codice_istat: 43026,
    codice_belfiore: "F454",
  },
  {
    id: 4207,
    comune: "MONTECASTELLO",
    sigla: "AL",
    codice_elettorale: 1010021021,
    codice_istat: 6105,
    codice_belfiore: "F455",
  },
  {
    id: 4208,
    comune: "MONTECASTRILLI",
    sigla: "TR",
    codice_elettorale: 2100800170,
    codice_istat: 55017,
    codice_belfiore: "F457",
  },
  {
    id: 4209,
    comune: "MONTECATINI VAL DI CECINA",
    sigla: "PI",
    codice_elettorale: 2090620190,
    codice_istat: 50019,
    codice_belfiore: "F458",
  },
  {
    id: 4210,
    comune: "MONTECATINI-TERME",
    sigla: "PT",
    codice_elettorale: 2090630110,
    codice_istat: 47011,
    codice_belfiore: "A561",
  },
  {
    id: 4211,
    comune: "MONTECCHIA DI CROSARA",
    sigla: "VR",
    codice_elettorale: 1050890490,
    codice_istat: 23049,
    codice_belfiore: "F461",
  },
  {
    id: 4212,
    comune: "MONTECCHIO",
    sigla: "TR",
    codice_elettorale: 2100800180,
    codice_istat: 55018,
    codice_belfiore: "F462",
  },
  {
    id: 4213,
    comune: "MONTECCHIO EMILIA",
    sigla: "RE",
    codice_elettorale: 1080680270,
    codice_istat: 35027,
    codice_belfiore: "F463",
  },
  {
    id: 4214,
    comune: "MONTECCHIO MAGGIORE",
    sigla: "VI",
    codice_elettorale: 1050900610,
    codice_istat: 24061,
    codice_belfiore: "F464",
  },
  {
    id: 4215,
    comune: "MONTECCHIO PRECALCINO",
    sigla: "VI",
    codice_elettorale: 1050900620,
    codice_istat: 24062,
    codice_belfiore: "F465",
  },
  {
    id: 4216,
    comune: "MONTECHIARO D'ACQUI",
    sigla: "AL",
    codice_elettorale: 1010021030,
    codice_istat: 6106,
    codice_belfiore: "F469",
  },
  {
    id: 4217,
    comune: "MONTECHIARO D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070750,
    codice_istat: 5075,
    codice_belfiore: "F468",
  },
  {
    id: 4218,
    comune: "MONTECHIARUGOLO",
    sigla: "PR",
    codice_elettorale: 1080560230,
    codice_istat: 34023,
    codice_belfiore: "F473",
  },
  {
    id: 4219,
    comune: "MONTECILFONE",
    sigla: "CB",
    codice_elettorale: 3140190420,
    codice_istat: 70042,
    codice_belfiore: "F475",
  },
  {
    id: 4220,
    comune: "MONTECOPIOLO",
    sigla: "RN",
    codice_elettorale: 1081010072,
    codice_istat: 99030,
    codice_belfiore: "F478",
  },
  {
    id: 4221,
    comune: "MONTECORICE",
    sigla: "SA",
    codice_elettorale: 3150720710,
    codice_istat: 65071,
    codice_belfiore: "F479",
  },
  {
    id: 4222,
    comune: "MONTECORVINO PUGLIANO",
    sigla: "SA",
    codice_elettorale: 3150720720,
    codice_istat: 65072,
    codice_belfiore: "F480",
  },
  {
    id: 4223,
    comune: "MONTECORVINO ROVELLA",
    sigla: "SA",
    codice_elettorale: 3150720730,
    codice_istat: 65073,
    codice_belfiore: "F481",
  },
  {
    id: 4224,
    comune: "MONTECOSARO",
    sigla: "MC",
    codice_elettorale: 2110440280,
    codice_istat: 43028,
    codice_belfiore: "F482",
  },
  {
    id: 4225,
    comune: "MONTECRESTESE",
    sigla: "VB",
    codice_elettorale: 1011020460,
    codice_istat: 103046,
    codice_belfiore: "F483",
  },
  {
    id: 4226,
    comune: "MONTECRETO",
    sigla: "MO",
    codice_elettorale: 1080500240,
    codice_istat: 36024,
    codice_belfiore: "F484",
  },
  {
    id: 4227,
    comune: "MONTEDINOVE",
    sigla: "AP",
    codice_elettorale: 2110060340,
    codice_istat: 44034,
    codice_belfiore: "F487",
  },
  {
    id: 4228,
    comune: "MONTEDORO",
    sigla: "CL",
    codice_elettorale: 4190180110,
    codice_istat: 85011,
    codice_belfiore: "F489",
  },
  {
    id: 4229,
    comune: "MONTEFALCIONE",
    sigla: "AV",
    codice_elettorale: 3150080530,
    codice_istat: 64053,
    codice_belfiore: "F491",
  },
  {
    id: 4230,
    comune: "MONTEFALCO",
    sigla: "PG",
    codice_elettorale: 2100580300,
    codice_istat: 54030,
    codice_belfiore: "F492",
  },
  {
    id: 4231,
    comune: "MONTEFALCONE APPENNINO",
    sigla: "FM",
    codice_elettorale: 2111050140,
    codice_istat: 109014,
    codice_belfiore: "F493",
  },
  {
    id: 4232,
    comune: "MONTEFALCONE DI VAL FORTORE",
    sigla: "BN",
    codice_elettorale: 3150110410,
    codice_istat: 62042,
    codice_belfiore: "F494",
  },
  {
    id: 4233,
    comune: "MONTEFALCONE NEL SANNIO",
    sigla: "CB",
    codice_elettorale: 3140190430,
    codice_istat: 70043,
    codice_belfiore: "F495",
  },
  {
    id: 4234,
    comune: "MONTEFANO",
    sigla: "MC",
    codice_elettorale: 2110440290,
    codice_istat: 43029,
    codice_belfiore: "F496",
  },
  {
    id: 4235,
    comune: "MONTEFELCINO",
    sigla: "PU",
    codice_elettorale: 2110590340,
    codice_istat: 41034,
    codice_belfiore: "F497",
  },
  {
    id: 4236,
    comune: "MONTEFERRANTE",
    sigla: "CH",
    codice_elettorale: 3130230520,
    codice_istat: 69052,
    codice_belfiore: "F498",
  },
  {
    id: 4237,
    comune: "MONTEFIASCONE",
    sigla: "VT",
    codice_elettorale: 2120910350,
    codice_istat: 56036,
    codice_belfiore: "F499",
  },
  {
    id: 4238,
    comune: "MONTEFINO",
    sigla: "TE",
    codice_elettorale: 3130790260,
    codice_istat: 67027,
    codice_belfiore: "F500",
  },
  {
    id: 4239,
    comune: "MONTEFIORE CONCA",
    sigla: "RN",
    codice_elettorale: 1081010080,
    codice_istat: 99008,
    codice_belfiore: "F502",
  },
  {
    id: 4240,
    comune: "MONTEFIORE DELL'ASO",
    sigla: "AP",
    codice_elettorale: 2110060360,
    codice_istat: 44036,
    codice_belfiore: "F501",
  },
  {
    id: 4241,
    comune: "MONTEFIORINO",
    sigla: "MO",
    codice_elettorale: 1080500250,
    codice_istat: 36025,
    codice_belfiore: "F503",
  },
  {
    id: 4242,
    comune: "MONTEFLAVIO",
    sigla: "RM",
    codice_elettorale: 2120700600,
    codice_istat: 58061,
    codice_belfiore: "F504",
  },
  {
    id: 4243,
    comune: "MONTEFORTE CILENTO",
    sigla: "SA",
    codice_elettorale: 3150720740,
    codice_istat: 65074,
    codice_belfiore: "F507",
  },
  {
    id: 4244,
    comune: "MONTEFORTE D'ALPONE",
    sigla: "VR",
    codice_elettorale: 1050890500,
    codice_istat: 23050,
    codice_belfiore: "F508",
  },
  {
    id: 4245,
    comune: "MONTEFORTE IRPINO",
    sigla: "AV",
    codice_elettorale: 3150080540,
    codice_istat: 64054,
    codice_belfiore: "F506",
  },
  {
    id: 4246,
    comune: "MONTEFORTINO",
    sigla: "FM",
    codice_elettorale: 2111050150,
    codice_istat: 109015,
    codice_belfiore: "F509",
  },
  {
    id: 4247,
    comune: "MONTEFRANCO",
    sigla: "TR",
    codice_elettorale: 2100800190,
    codice_istat: 55019,
    codice_belfiore: "F510",
  },
  {
    id: 4248,
    comune: "MONTEFREDANE",
    sigla: "AV",
    codice_elettorale: 3150080550,
    codice_istat: 64055,
    codice_belfiore: "F511",
  },
  {
    id: 4249,
    comune: "MONTEFUSCO",
    sigla: "AV",
    codice_elettorale: 3150080560,
    codice_istat: 64056,
    codice_belfiore: "F512",
  },
  {
    id: 4250,
    comune: "MONTEGABBIONE",
    sigla: "TR",
    codice_elettorale: 2100800200,
    codice_istat: 55020,
    codice_belfiore: "F513",
  },
  {
    id: 4251,
    comune: "MONTEGALDA",
    sigla: "VI",
    codice_elettorale: 1050900640,
    codice_istat: 24064,
    codice_belfiore: "F514",
  },
  {
    id: 4252,
    comune: "MONTEGALDELLA",
    sigla: "VI",
    codice_elettorale: 1050900650,
    codice_istat: 24065,
    codice_belfiore: "F515",
  },
  {
    id: 4253,
    comune: "MONTEGALLO",
    sigla: "AP",
    codice_elettorale: 2110060380,
    codice_istat: 44038,
    codice_belfiore: "F516",
  },
  {
    id: 4254,
    comune: "MONTEGIOCO",
    sigla: "AL",
    codice_elettorale: 1010021040,
    codice_istat: 6107,
    codice_belfiore: "F518",
  },
  {
    id: 4255,
    comune: "MONTEGIORDANO",
    sigla: "CS",
    codice_elettorale: 3180250830,
    codice_istat: 78082,
    codice_belfiore: "F519",
  },
  {
    id: 4256,
    comune: "MONTEGIORGIO",
    sigla: "FM",
    codice_elettorale: 2111050170,
    codice_istat: 109017,
    codice_belfiore: "F520",
  },
  {
    id: 4257,
    comune: "MONTEGRANARO",
    sigla: "FM",
    codice_elettorale: 2111050180,
    codice_istat: 109018,
    codice_belfiore: "F522",
  },
  {
    id: 4258,
    comune: "MONTEGRIDOLFO",
    sigla: "RN",
    codice_elettorale: 1081010090,
    codice_istat: 99009,
    codice_belfiore: "F523",
  },
  {
    id: 4259,
    comune: "MONTEGRINO VALTRAVAGLIA",
    sigla: "VA",
    codice_elettorale: 1030860870,
    codice_istat: 12103,
    codice_belfiore: "F526",
  },
  {
    id: 4260,
    comune: "MONTEGROSSO D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070760,
    codice_istat: 5076,
    codice_belfiore: "F527",
  },
  {
    id: 4261,
    comune: "MONTEGROSSO PIAN LATTE",
    sigla: "IM",
    codice_elettorale: 1070370340,
    codice_istat: 8037,
    codice_belfiore: "F528",
  },
  {
    id: 4262,
    comune: "MONTEGROTTO TERME",
    sigla: "PD",
    codice_elettorale: 1050540570,
    codice_istat: 28057,
    codice_belfiore: "F529",
  },
  {
    id: 4263,
    comune: "MONTEIASI",
    sigla: "TA",
    codice_elettorale: 3160780160,
    codice_istat: 73016,
    codice_belfiore: "F531",
  },
  {
    id: 4264,
    comune: "MONTELABBATE",
    sigla: "PU",
    codice_elettorale: 2110590360,
    codice_istat: 41036,
    codice_belfiore: "F533",
  },
  {
    id: 4265,
    comune: "MONTELANICO",
    sigla: "RM",
    codice_elettorale: 2120700610,
    codice_istat: 58062,
    codice_belfiore: "F534",
  },
  {
    id: 4266,
    comune: "MONTELAPIANO",
    sigla: "CH",
    codice_elettorale: 3130230530,
    codice_istat: 69053,
    codice_belfiore: "F535",
  },
  {
    id: 4267,
    comune: "MONTELEONE D'ORVIETO",
    sigla: "TR",
    codice_elettorale: 2100800210,
    codice_istat: 55021,
    codice_belfiore: "F543",
  },
  {
    id: 4268,
    comune: "MONTELEONE DI FERMO",
    sigla: "FM",
    codice_elettorale: 2111050190,
    codice_istat: 109019,
    codice_belfiore: "F536",
  },
  {
    id: 4269,
    comune: "MONTELEONE DI PUGLIA",
    sigla: "FG",
    codice_elettorale: 3160310300,
    codice_istat: 71032,
    codice_belfiore: "F538",
  },
  {
    id: 4270,
    comune: "MONTELEONE DI SPOLETO",
    sigla: "PG",
    codice_elettorale: 2100580310,
    codice_istat: 54031,
    codice_belfiore: "F540",
  },
  {
    id: 4271,
    comune: "MONTELEONE ROCCA DORIA",
    sigla: "SS",
    codice_elettorale: 4200730400,
    codice_istat: 90040,
    codice_belfiore: "F542",
  },
  {
    id: 4272,
    comune: "MONTELEONE SABINO",
    sigla: "RI",
    codice_elettorale: 2120690390,
    codice_istat: 57041,
    codice_belfiore: "F541",
  },
  {
    id: 4273,
    comune: "MONTELEPRE",
    sigla: "PA",
    codice_elettorale: 4190550480,
    codice_istat: 82050,
    codice_belfiore: "F544",
  },
  {
    id: 4274,
    comune: "MONTELIBRETTI",
    sigla: "RM",
    codice_elettorale: 2120700620,
    codice_istat: 58063,
    codice_belfiore: "F545",
  },
  {
    id: 4275,
    comune: "MONTELLA",
    sigla: "AV",
    codice_elettorale: 3150080570,
    codice_istat: 64057,
    codice_belfiore: "F546",
  },
  {
    id: 4276,
    comune: "MONTELLO",
    sigla: "BG",
    codice_elettorale: 1030121311,
    codice_istat: 16139,
    codice_belfiore: "F547",
  },
  {
    id: 4277,
    comune: "MONTELONGO",
    sigla: "CB",
    codice_elettorale: 3140190440,
    codice_istat: 70044,
    codice_belfiore: "F548",
  },
  {
    id: 4278,
    comune: "MONTELPARO",
    sigla: "FM",
    codice_elettorale: 2111050200,
    codice_istat: 109020,
    codice_belfiore: "F549",
  },
  {
    id: 4279,
    comune: "MONTELUPO ALBESE",
    sigla: "CN",
    codice_elettorale: 1010271370,
    codice_istat: 4137,
    codice_belfiore: "F550",
  },
  {
    id: 4280,
    comune: "MONTELUPO FIORENTINO",
    sigla: "FI",
    codice_elettorale: 2090300280,
    codice_istat: 48028,
    codice_belfiore: "F551",
  },
  {
    id: 4281,
    comune: "MONTELUPONE",
    sigla: "MC",
    codice_elettorale: 2110440300,
    codice_istat: 43030,
    codice_belfiore: "F552",
  },
  {
    id: 4282,
    comune: "MONTEMAGGIORE BELSITO",
    sigla: "PA",
    codice_elettorale: 4190550490,
    codice_istat: 82051,
    codice_belfiore: "F553",
  },
  {
    id: 4283,
    comune: "MONTEMAGNO",
    sigla: "AT",
    codice_elettorale: 1010070770,
    codice_istat: 5077,
    codice_belfiore: "F556",
  },
  {
    id: 4284,
    comune: "MONTEMALE DI CUNEO",
    sigla: "CN",
    codice_elettorale: 1010271380,
    codice_istat: 4138,
    codice_belfiore: "F558",
  },
  {
    id: 4285,
    comune: "MONTEMARANO",
    sigla: "AV",
    codice_elettorale: 3150080580,
    codice_istat: 64058,
    codice_belfiore: "F559",
  },
  {
    id: 4286,
    comune: "MONTEMARCIANO",
    sigla: "AN",
    codice_elettorale: 2110030270,
    codice_istat: 42027,
    codice_belfiore: "F560",
  },
  {
    id: 4287,
    comune: "MONTEMARZINO",
    sigla: "AL",
    codice_elettorale: 1010021050,
    codice_istat: 6108,
    codice_belfiore: "F562",
  },
  {
    id: 4288,
    comune: "MONTEMESOLA",
    sigla: "TA",
    codice_elettorale: 3160780170,
    codice_istat: 73017,
    codice_belfiore: "F563",
  },
  {
    id: 4289,
    comune: "MONTEMEZZO",
    sigla: "CO",
    codice_elettorale: 1030241490,
    codice_istat: 13155,
    codice_belfiore: "F564",
  },
  {
    id: 4290,
    comune: "MONTEMIGNAIO",
    sigla: "AR",
    codice_elettorale: 2090050230,
    codice_istat: 51023,
    codice_belfiore: "F565",
  },
  {
    id: 4291,
    comune: "MONTEMILETTO",
    sigla: "AV",
    codice_elettorale: 3150080590,
    codice_istat: 64059,
    codice_belfiore: "F566",
  },
  {
    id: 4292,
    comune: "MONTEMILONE",
    sigla: "PZ",
    codice_elettorale: 3170640500,
    codice_istat: 76051,
    codice_belfiore: "F568",
  },
  {
    id: 4293,
    comune: "MONTEMITRO",
    sigla: "CB",
    codice_elettorale: 3140190450,
    codice_istat: 70045,
    codice_belfiore: "F569",
  },
  {
    id: 4294,
    comune: "MONTEMONACO",
    sigla: "AP",
    codice_elettorale: 2110060440,
    codice_istat: 44044,
    codice_belfiore: "F570",
  },
  {
    id: 4295,
    comune: "MONTEMURLO",
    sigla: "PO",
    codice_elettorale: 2091000030,
    codice_istat: 100003,
    codice_belfiore: "F572",
  },
  {
    id: 4296,
    comune: "MONTEMURRO",
    sigla: "PZ",
    codice_elettorale: 3170640510,
    codice_istat: 76052,
    codice_belfiore: "F573",
  },
  {
    id: 4297,
    comune: "MONTENARS",
    sigla: "UD",
    codice_elettorale: 1060850610,
    codice_istat: 30061,
    codice_belfiore: "F574",
  },
  {
    id: 4298,
    comune: "MONTENERO DI BISACCIA",
    sigla: "CB",
    codice_elettorale: 3140190460,
    codice_istat: 70046,
    codice_belfiore: "F576",
  },
  {
    id: 4299,
    comune: "MONTENERO SABINO",
    sigla: "RI",
    codice_elettorale: 2120690400,
    codice_istat: 57042,
    codice_belfiore: "F579",
  },
  {
    id: 4300,
    comune: "MONTENERO VAL COCCHIARA",
    sigla: "IS",
    codice_elettorale: 3140940290,
    codice_istat: 94029,
    codice_belfiore: "F580",
  },
  {
    id: 4301,
    comune: "MONTENERODOMO",
    sigla: "CH",
    codice_elettorale: 3130230540,
    codice_istat: 69054,
    codice_belfiore: "F578",
  },
  {
    id: 4302,
    comune: "MONTEODORISIO",
    sigla: "CH",
    codice_elettorale: 3130230550,
    codice_istat: 69055,
    codice_belfiore: "F582",
  },
  {
    id: 4303,
    comune: "MONTEPAONE",
    sigla: "CZ",
    codice_elettorale: 3180220780,
    codice_istat: 79081,
    codice_belfiore: "F586",
  },
  {
    id: 4304,
    comune: "MONTEPARANO",
    sigla: "TA",
    codice_elettorale: 3160780180,
    codice_istat: 73018,
    codice_belfiore: "F587",
  },
  {
    id: 4305,
    comune: "MONTEPRANDONE",
    sigla: "AP",
    codice_elettorale: 2110060450,
    codice_istat: 44045,
    codice_belfiore: "F591",
  },
  {
    id: 4306,
    comune: "MONTEPULCIANO",
    sigla: "SI",
    codice_elettorale: 2090750150,
    codice_istat: 52015,
    codice_belfiore: "F592",
  },
  {
    id: 4307,
    comune: "MONTERCHI",
    sigla: "AR",
    codice_elettorale: 2090050240,
    codice_istat: 51024,
    codice_belfiore: "F594",
  },
  {
    id: 4308,
    comune: "MONTEREALE",
    sigla: "AQ",
    codice_elettorale: 3130380560,
    codice_istat: 66056,
    codice_belfiore: "F595",
  },
  {
    id: 4309,
    comune: "MONTEREALE VALCELLINA",
    sigla: "PN",
    codice_elettorale: 1060930270,
    codice_istat: 93027,
    codice_belfiore: "F596",
  },
  {
    id: 4310,
    comune: "MONTERENZIO",
    sigla: "BO",
    codice_elettorale: 1080130410,
    codice_istat: 37041,
    codice_belfiore: "F597",
  },
  {
    id: 4311,
    comune: "MONTERIGGIONI",
    sigla: "SI",
    codice_elettorale: 2090750160,
    codice_istat: 52016,
    codice_belfiore: "F598",
  },
  {
    id: 4312,
    comune: "MONTERODUNI",
    sigla: "IS",
    codice_elettorale: 3140940300,
    codice_istat: 94030,
    codice_belfiore: "F601",
  },
  {
    id: 4313,
    comune: "MONTERONI D'ARBIA",
    sigla: "SI",
    codice_elettorale: 2090750170,
    codice_istat: 52017,
    codice_belfiore: "F605",
  },
  {
    id: 4314,
    comune: "MONTERONI DI LECCE",
    sigla: "LE",
    codice_elettorale: 3160410470,
    codice_istat: 75048,
    codice_belfiore: "F604",
  },
  {
    id: 4315,
    comune: "MONTEROSI",
    sigla: "VT",
    codice_elettorale: 2120910370,
    codice_istat: 56038,
    codice_belfiore: "F606",
  },
  {
    id: 4316,
    comune: "MONTEROSSO AL MARE",
    sigla: "SP",
    codice_elettorale: 1070390190,
    codice_istat: 11019,
    codice_belfiore: "F609",
  },
  {
    id: 4317,
    comune: "MONTEROSSO ALMO",
    sigla: "RG",
    codice_elettorale: 4190650070,
    codice_istat: 88007,
    codice_belfiore: "F610",
  },
  {
    id: 4318,
    comune: "MONTEROSSO CALABRO",
    sigla: "VV",
    codice_elettorale: 3181030230,
    codice_istat: 102023,
    codice_belfiore: "F607",
  },
  {
    id: 4319,
    comune: "MONTEROSSO GRANA",
    sigla: "CN",
    codice_elettorale: 1010271390,
    codice_istat: 4139,
    codice_belfiore: "F608",
  },
  {
    id: 4320,
    comune: "MONTEROTONDO",
    sigla: "RM",
    codice_elettorale: 2120700640,
    codice_istat: 58065,
    codice_belfiore: "F611",
  },
  {
    id: 4321,
    comune: "MONTEROTONDO MARITTIMO",
    sigla: "GR",
    codice_elettorale: 2090360151,
    codice_istat: 53027,
    codice_belfiore: "F612",
  },
  {
    id: 4322,
    comune: "MONTERUBBIANO",
    sigla: "FM",
    codice_elettorale: 2111050220,
    codice_istat: 109022,
    codice_belfiore: "F614",
  },
  {
    id: 4323,
    comune: "MONTESANO SALENTINO",
    sigla: "LE",
    codice_elettorale: 3160410480,
    codice_istat: 75049,
    codice_belfiore: "F623",
  },
  {
    id: 4324,
    comune: "MONTESANO SULLA MARCELLANA",
    sigla: "SA",
    codice_elettorale: 3150720760,
    codice_istat: 65076,
    codice_belfiore: "F625",
  },
  {
    id: 4325,
    comune: "MONTESARCHIO",
    sigla: "BN",
    codice_elettorale: 3150110420,
    codice_istat: 62043,
    codice_belfiore: "F636",
  },
  {
    id: 4326,
    comune: "MONTESCAGLIOSO",
    sigla: "MT",
    codice_elettorale: 3170470170,
    codice_istat: 77017,
    codice_belfiore: "F637",
  },
  {
    id: 4327,
    comune: "MONTESCANO",
    sigla: "PV",
    codice_elettorale: 1030570940,
    codice_istat: 18097,
    codice_belfiore: "F638",
  },
  {
    id: 4328,
    comune: "MONTESCHENO",
    sigla: "VB",
    codice_elettorale: 1011020470,
    codice_istat: 103047,
    codice_belfiore: "F639",
  },
  {
    id: 4329,
    comune: "MONTESCUDAIO",
    sigla: "PI",
    codice_elettorale: 2090620200,
    codice_istat: 50020,
    codice_belfiore: "F640",
  },
  {
    id: 4330,
    comune: "MONTESCUDO - MONTE COLOMBO",
    sigla: "RN",
    codice_elettorale: 1081010101,
    codice_istat: 99029,
    codice_belfiore: "M368",
  },
  {
    id: 4331,
    comune: "MONTESE",
    sigla: "MO",
    codice_elettorale: 1080500260,
    codice_istat: 36026,
    codice_belfiore: "F642",
  },
  {
    id: 4332,
    comune: "MONTESEGALE",
    sigla: "PV",
    codice_elettorale: 1030570950,
    codice_istat: 18098,
    codice_belfiore: "F644",
  },
  {
    id: 4333,
    comune: "MONTESILVANO",
    sigla: "PE",
    codice_elettorale: 3130600240,
    codice_istat: 68024,
    codice_belfiore: "F646",
  },
  {
    id: 4334,
    comune: "MONTESPERTOLI",
    sigla: "FI",
    codice_elettorale: 2090300300,
    codice_istat: 48030,
    codice_belfiore: "F648",
  },
  {
    id: 4335,
    comune: "MONTEU DA PO",
    sigla: "TO",
    codice_elettorale: 1010811600,
    codice_istat: 1162,
    codice_belfiore: "F651",
  },
  {
    id: 4336,
    comune: "MONTEU ROERO",
    sigla: "CN",
    codice_elettorale: 1010271400,
    codice_istat: 4140,
    codice_belfiore: "F654",
  },
  {
    id: 4337,
    comune: "MONTEVAGO",
    sigla: "AG",
    codice_elettorale: 4190010250,
    codice_istat: 84025,
    codice_belfiore: "F655",
  },
  {
    id: 4338,
    comune: "MONTEVARCHI",
    sigla: "AR",
    codice_elettorale: 2090050260,
    codice_istat: 51026,
    codice_belfiore: "F656",
  },
  {
    id: 4339,
    comune: "MONTEVECCHIA",
    sigla: "LC",
    codice_elettorale: 1030980530,
    codice_istat: 97053,
    codice_belfiore: "F657",
  },
  {
    id: 4340,
    comune: "MONTEVERDE",
    sigla: "AV",
    codice_elettorale: 3150080600,
    codice_istat: 64060,
    codice_belfiore: "F660",
  },
  {
    id: 4341,
    comune: "MONTEVERDI MARITTIMO",
    sigla: "PI",
    codice_elettorale: 2090620210,
    codice_istat: 50021,
    codice_belfiore: "F661",
  },
  {
    id: 4342,
    comune: "MONTEVIALE",
    sigla: "VI",
    codice_elettorale: 1050900660,
    codice_istat: 24066,
    codice_belfiore: "F662",
  },
  {
    id: 4343,
    comune: "MONTEZEMOLO",
    sigla: "CN",
    codice_elettorale: 1010271410,
    codice_istat: 4141,
    codice_belfiore: "F666",
  },
  {
    id: 4344,
    comune: "MONTI",
    sigla: "SS",
    codice_elettorale: 4200730410,
    codice_istat: 90041,
    codice_belfiore: "F667",
  },
  {
    id: 4345,
    comune: "MONTIANO",
    sigla: "FC",
    codice_elettorale: 1080320270,
    codice_istat: 40028,
    codice_belfiore: "F668",
  },
  {
    id: 4346,
    comune: "MONTICELLI BRUSATI",
    sigla: "BS",
    codice_elettorale: 1030151040,
    codice_istat: 17112,
    codice_belfiore: "F672",
  },
  {
    id: 4347,
    comune: "MONTICELLI D'ONGINA",
    sigla: "PC",
    codice_elettorale: 1080610270,
    codice_istat: 33027,
    codice_belfiore: "F671",
  },
  {
    id: 4348,
    comune: "MONTICELLI PAVESE",
    sigla: "PV",
    codice_elettorale: 1030570960,
    codice_istat: 18099,
    codice_belfiore: "F670",
  },
  {
    id: 4349,
    comune: "MONTICELLO BRIANZA",
    sigla: "LC",
    codice_elettorale: 1030980540,
    codice_istat: 97054,
    codice_belfiore: "F674",
  },
  {
    id: 4350,
    comune: "MONTICELLO CONTE OTTO",
    sigla: "VI",
    codice_elettorale: 1050900670,
    codice_istat: 24067,
    codice_belfiore: "F675",
  },
  {
    id: 4351,
    comune: "MONTICELLO D'ALBA",
    sigla: "CN",
    codice_elettorale: 1010271420,
    codice_istat: 4142,
    codice_belfiore: "F669",
  },
  {
    id: 4352,
    comune: "MONTICHIARI",
    sigla: "BS",
    codice_elettorale: 1030151050,
    codice_istat: 17113,
    codice_belfiore: "F471",
  },
  {
    id: 4353,
    comune: "MONTICIANO",
    sigla: "SI",
    codice_elettorale: 2090750180,
    codice_istat: 52018,
    codice_belfiore: "F676",
  },
  {
    id: 4354,
    comune: "MONTIERI",
    sigla: "GR",
    codice_elettorale: 2090360160,
    codice_istat: 53017,
    codice_belfiore: "F677",
  },
  {
    id: 4355,
    comune: "MONTIGLIO MONFERRATO",
    sigla: "AT",
    codice_elettorale: 1010070781,
    codice_istat: 5121,
    codice_belfiore: "M302",
  },
  {
    id: 4356,
    comune: "MONTIGNOSO",
    sigla: "MS",
    codice_elettorale: 2090460110,
    codice_istat: 45011,
    codice_belfiore: "F679",
  },
  {
    id: 4357,
    comune: "MONTIRONE",
    sigla: "BS",
    codice_elettorale: 1030151051,
    codice_istat: 17114,
    codice_belfiore: "F680",
  },
  {
    id: 4358,
    comune: "MONTJOVET",
    sigla: "AO",
    codice_elettorale: 1020040420,
    codice_istat: 7043,
    codice_belfiore: "F367",
  },
  {
    id: 4359,
    comune: "MONTODINE",
    sigla: "CR",
    codice_elettorale: 1030260580,
    codice_istat: 19059,
    codice_belfiore: "F681",
  },
  {
    id: 4360,
    comune: "MONTOGGIO",
    sigla: "GE",
    codice_elettorale: 1070340390,
    codice_istat: 10039,
    codice_belfiore: "F682",
  },
  {
    id: 4361,
    comune: "MONTONE",
    sigla: "PG",
    codice_elettorale: 2100580330,
    codice_istat: 54033,
    codice_belfiore: "F685",
  },
  {
    id: 4362,
    comune: "MONTOPOLI DI SABINA",
    sigla: "RI",
    codice_elettorale: 2120690420,
    codice_istat: 57044,
    codice_belfiore: "F687",
  },
  {
    id: 4363,
    comune: "MONTOPOLI IN VAL D'ARNO",
    sigla: "PI",
    codice_elettorale: 2090620220,
    codice_istat: 50022,
    codice_belfiore: "F686",
  },
  {
    id: 4364,
    comune: "MONTORFANO",
    sigla: "CO",
    codice_elettorale: 1030241510,
    codice_istat: 13157,
    codice_belfiore: "F688",
  },
  {
    id: 4365,
    comune: "MONTORIO AL VOMANO",
    sigla: "TE",
    codice_elettorale: 3130790270,
    codice_istat: 67028,
    codice_belfiore: "F690",
  },
  {
    id: 4366,
    comune: "MONTORIO NEI FRENTANI",
    sigla: "CB",
    codice_elettorale: 3140190470,
    codice_istat: 70047,
    codice_belfiore: "F689",
  },
  {
    id: 4367,
    comune: "MONTORIO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700650,
    codice_istat: 58066,
    codice_belfiore: "F692",
  },
  {
    id: 4368,
    comune: "MONTORO",
    sigla: "AV",
    codice_elettorale: 3150080605,
    codice_istat: 64121,
    codice_belfiore: "M330",
  },
  {
    id: 4369,
    comune: "MONTORSO VICENTINO",
    sigla: "VI",
    codice_elettorale: 1050900680,
    codice_istat: 24068,
    codice_belfiore: "F696",
  },
  {
    id: 4370,
    comune: "MONTOTTONE",
    sigla: "FM",
    codice_elettorale: 2111050270,
    codice_istat: 109027,
    codice_belfiore: "F697",
  },
  {
    id: 4371,
    comune: "MONTRESTA",
    sigla: "OR",
    codice_elettorale: 4200950296,
    codice_istat: 95085,
    codice_belfiore: "F698",
  },
  {
    id: 4372,
    comune: "MONTU' BECCARIA",
    sigla: "PV",
    codice_elettorale: 1030570970,
    codice_istat: 18100,
    codice_belfiore: "F701",
  },
  {
    id: 4373,
    comune: "MONVALLE",
    sigla: "VA",
    codice_elettorale: 1030860880,
    codice_istat: 12104,
    codice_belfiore: "F703",
  },
  {
    id: 4374,
    comune: "MONZA",
    sigla: "MB",
    codice_elettorale: 1031040330,
    codice_istat: 108033,
    codice_belfiore: "F704",
  },
  {
    id: 4375,
    comune: "MONZAMBANO",
    sigla: "MN",
    codice_elettorale: 1030450360,
    codice_istat: 20036,
    codice_belfiore: "F705",
  },
  {
    id: 4376,
    comune: "MONZUNO",
    sigla: "BO",
    codice_elettorale: 1080130440,
    codice_istat: 37044,
    codice_belfiore: "F706",
  },
  {
    id: 4377,
    comune: "MORANO CALABRO",
    sigla: "CS",
    codice_elettorale: 3180250840,
    codice_istat: 78083,
    codice_belfiore: "F708",
  },
  {
    id: 4378,
    comune: "MORANO SUL PO",
    sigla: "AL",
    codice_elettorale: 1010021060,
    codice_istat: 6109,
    codice_belfiore: "F707",
  },
  {
    id: 4379,
    comune: "MORANSENGO-TONENGO",
    sigla: "AT",
    codice_elettorale: 1010070792,
    codice_istat: 5122,
    codice_belfiore: "M434",
  },
  {
    id: 4380,
    comune: "MORARO",
    sigla: "GO",
    codice_elettorale: 1060350111,
    codice_istat: 31013,
    codice_belfiore: "F710",
  },
  {
    id: 4381,
    comune: "MORAZZONE",
    sigla: "VA",
    codice_elettorale: 1030860890,
    codice_istat: 12105,
    codice_belfiore: "F711",
  },
  {
    id: 4382,
    comune: "MORBEGNO",
    sigla: "SO",
    codice_elettorale: 1030770450,
    codice_istat: 14045,
    codice_belfiore: "F712",
  },
  {
    id: 4383,
    comune: "MORBELLO",
    sigla: "AL",
    codice_elettorale: 1010021070,
    codice_istat: 6110,
    codice_belfiore: "F713",
  },
  {
    id: 4384,
    comune: "MORCIANO DI LEUCA",
    sigla: "LE",
    codice_elettorale: 3160410490,
    codice_istat: 75050,
    codice_belfiore: "F716",
  },
  {
    id: 4385,
    comune: "MORCIANO DI ROMAGNA",
    sigla: "RN",
    codice_elettorale: 1081010110,
    codice_istat: 99011,
    codice_belfiore: "F715",
  },
  {
    id: 4386,
    comune: "MORCONE",
    sigla: "BN",
    codice_elettorale: 3150110430,
    codice_istat: 62044,
    codice_belfiore: "F717",
  },
  {
    id: 4387,
    comune: "MORDANO",
    sigla: "BO",
    codice_elettorale: 1080130450,
    codice_istat: 37045,
    codice_belfiore: "F718",
  },
  {
    id: 4388,
    comune: "MORENGO",
    sigla: "BG",
    codice_elettorale: 1030121330,
    codice_istat: 16140,
    codice_belfiore: "F720",
  },
  {
    id: 4389,
    comune: "MORES",
    sigla: "SS",
    codice_elettorale: 4200730420,
    codice_istat: 90042,
    codice_belfiore: "F721",
  },
  {
    id: 4390,
    comune: "MORESCO",
    sigla: "FM",
    codice_elettorale: 2111050280,
    codice_istat: 109028,
    codice_belfiore: "F722",
  },
  {
    id: 4391,
    comune: "MORETTA",
    sigla: "CN",
    codice_elettorale: 1010271430,
    codice_istat: 4143,
    codice_belfiore: "F723",
  },
  {
    id: 4392,
    comune: "MORFASSO",
    sigla: "PC",
    codice_elettorale: 1080610280,
    codice_istat: 33028,
    codice_belfiore: "F724",
  },
  {
    id: 4393,
    comune: "MORGANO",
    sigla: "TV",
    codice_elettorale: 1050840460,
    codice_istat: 26047,
    codice_belfiore: "F725",
  },
  {
    id: 4394,
    comune: "MORGEX",
    sigla: "AO",
    codice_elettorale: 1020040430,
    codice_istat: 7044,
    codice_belfiore: "F726",
  },
  {
    id: 4395,
    comune: "MORGONGIORI",
    sigla: "OR",
    codice_elettorale: 4200950300,
    codice_istat: 95030,
    codice_belfiore: "F727",
  },
  {
    id: 4396,
    comune: "MORI",
    sigla: "TN",
    codice_elettorale: 1040831160,
    codice_istat: 22123,
    codice_belfiore: "F728",
  },
  {
    id: 4397,
    comune: "MORIAGO DELLA BATTAGLIA",
    sigla: "TV",
    codice_elettorale: 1050840470,
    codice_istat: 26048,
    codice_belfiore: "F729",
  },
  {
    id: 4398,
    comune: "MORICONE",
    sigla: "RM",
    codice_elettorale: 2120700660,
    codice_istat: 58067,
    codice_belfiore: "F730",
  },
  {
    id: 4399,
    comune: "MORIGERATI",
    sigla: "SA",
    codice_elettorale: 3150720770,
    codice_istat: 65077,
    codice_belfiore: "F731",
  },
  {
    id: 4400,
    comune: "MORIMONDO",
    sigla: "MI",
    codice_elettorale: 1030491490,
    codice_istat: 15150,
    codice_belfiore: "D033",
  },
  {
    id: 4401,
    comune: "MORINO",
    sigla: "AQ",
    codice_elettorale: 3130380570,
    codice_istat: 66057,
    codice_belfiore: "F732",
  },
  {
    id: 4402,
    comune: "MORIONDO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010811610,
    codice_istat: 1163,
    codice_belfiore: "F733",
  },
  {
    id: 4403,
    comune: "MORLUPO",
    sigla: "RM",
    codice_elettorale: 2120700670,
    codice_istat: 58068,
    codice_belfiore: "F734",
  },
  {
    id: 4404,
    comune: "MORMANNO",
    sigla: "CS",
    codice_elettorale: 3180250850,
    codice_istat: 78084,
    codice_belfiore: "F735",
  },
  {
    id: 4405,
    comune: "MORNAGO",
    sigla: "VA",
    codice_elettorale: 1030860900,
    codice_istat: 12106,
    codice_belfiore: "F736",
  },
  {
    id: 4406,
    comune: "MORNESE",
    sigla: "AL",
    codice_elettorale: 1010021080,
    codice_istat: 6111,
    codice_belfiore: "F737",
  },
  {
    id: 4407,
    comune: "MORNICO AL SERIO",
    sigla: "BG",
    codice_elettorale: 1030121340,
    codice_istat: 16141,
    codice_belfiore: "F738",
  },
  {
    id: 4408,
    comune: "MORNICO LOSANA",
    sigla: "PV",
    codice_elettorale: 1030570980,
    codice_istat: 18101,
    codice_belfiore: "F739",
  },
  {
    id: 4409,
    comune: "MOROLO",
    sigla: "FR",
    codice_elettorale: 2120330450,
    codice_istat: 60045,
    codice_belfiore: "F740",
  },
  {
    id: 4410,
    comune: "MOROZZO",
    sigla: "CN",
    codice_elettorale: 1010271440,
    codice_istat: 4144,
    codice_belfiore: "F743",
  },
  {
    id: 4411,
    comune: "MORRA DE SANCTIS",
    sigla: "AV",
    codice_elettorale: 3150080630,
    codice_istat: 64063,
    codice_belfiore: "F744",
  },
  {
    id: 4412,
    comune: "MORRO D'ALBA",
    sigla: "AN",
    codice_elettorale: 2110030310,
    codice_istat: 42031,
    codice_belfiore: "F745",
  },
  {
    id: 4413,
    comune: "MORRO D'ORO",
    sigla: "TE",
    codice_elettorale: 3130790280,
    codice_istat: 67029,
    codice_belfiore: "F747",
  },
  {
    id: 4414,
    comune: "MORRO REATINO",
    sigla: "RI",
    codice_elettorale: 2120690430,
    codice_istat: 57045,
    codice_belfiore: "F746",
  },
  {
    id: 4415,
    comune: "MORRONE DEL SANNIO",
    sigla: "CB",
    codice_elettorale: 3140190480,
    codice_istat: 70048,
    codice_belfiore: "F748",
  },
  {
    id: 4416,
    comune: "MORROVALLE",
    sigla: "MC",
    codice_elettorale: 2110440330,
    codice_istat: 43033,
    codice_belfiore: "F749",
  },
  {
    id: 4417,
    comune: "MORSANO AL TAGLIAMENTO",
    sigla: "PN",
    codice_elettorale: 1060930280,
    codice_istat: 93028,
    codice_belfiore: "F750",
  },
  {
    id: 4418,
    comune: "MORSASCO",
    sigla: "AL",
    codice_elettorale: 1010021090,
    codice_istat: 6112,
    codice_belfiore: "F751",
  },
  {
    id: 4419,
    comune: "MORTARA",
    sigla: "PV",
    codice_elettorale: 1030570990,
    codice_istat: 18102,
    codice_belfiore: "F754",
  },
  {
    id: 4420,
    comune: "MORTEGLIANO",
    sigla: "UD",
    codice_elettorale: 1060850620,
    codice_istat: 30062,
    codice_belfiore: "F756",
  },
  {
    id: 4421,
    comune: "MORTERONE",
    sigla: "LC",
    codice_elettorale: 1030980550,
    codice_istat: 97055,
    codice_belfiore: "F758",
  },
  {
    id: 4422,
    comune: "MORUZZO",
    sigla: "UD",
    codice_elettorale: 1060850630,
    codice_istat: 30063,
    codice_belfiore: "F760",
  },
  {
    id: 4423,
    comune: "MOSCAZZANO",
    sigla: "CR",
    codice_elettorale: 1030260590,
    codice_istat: 19060,
    codice_belfiore: "F761",
  },
  {
    id: 4424,
    comune: "MOSCHIANO",
    sigla: "AV",
    codice_elettorale: 3150080631,
    codice_istat: 64064,
    codice_belfiore: "F762",
  },
  {
    id: 4425,
    comune: "MOSCIANO SANT'ANGELO",
    sigla: "TE",
    codice_elettorale: 3130790290,
    codice_istat: 67030,
    codice_belfiore: "F764",
  },
  {
    id: 4426,
    comune: "MOSCUFO",
    sigla: "PE",
    codice_elettorale: 3130600250,
    codice_istat: 68025,
    codice_belfiore: "F765",
  },
  {
    id: 4427,
    comune: "MOSO IN PASSIRIA/MOOS IN PASSEIER",
    sigla: "BZ",
    codice_elettorale: 1040140510,
    codice_istat: 21054,
    codice_belfiore: "F766",
  },
  {
    id: 4428,
    comune: "MOSSA",
    sigla: "GO",
    codice_elettorale: 1060350112,
    codice_istat: 31014,
    codice_belfiore: "F767",
  },
  {
    id: 4429,
    comune: "MOTTA BALUFFI",
    sigla: "CR",
    codice_elettorale: 1030260600,
    codice_istat: 19061,
    codice_belfiore: "F771",
  },
  {
    id: 4430,
    comune: "MOTTA CAMASTRA",
    sigla: "ME",
    codice_elettorale: 4190480570,
    codice_istat: 83058,
    codice_belfiore: "F772",
  },
  {
    id: 4431,
    comune: "MOTTA D'AFFERMO",
    sigla: "ME",
    codice_elettorale: 4190480580,
    codice_istat: 83059,
    codice_belfiore: "F773",
  },
  {
    id: 4432,
    comune: "MOTTA DE' CONTI",
    sigla: "VC",
    codice_elettorale: 1010880810,
    codice_istat: 2082,
    codice_belfiore: "F774",
  },
  {
    id: 4433,
    comune: "MOTTA DI LIVENZA",
    sigla: "TV",
    codice_elettorale: 1050840480,
    codice_istat: 26049,
    codice_belfiore: "F770",
  },
  {
    id: 4434,
    comune: "MOTTA MONTECORVINO",
    sigla: "FG",
    codice_elettorale: 3160310320,
    codice_istat: 71034,
    codice_belfiore: "F777",
  },
  {
    id: 4435,
    comune: "MOTTA SAN GIOVANNI",
    sigla: "RC",
    codice_elettorale: 3180670540,
    codice_istat: 80054,
    codice_belfiore: "F779",
  },
  {
    id: 4436,
    comune: "MOTTA SANT'ANASTASIA",
    sigla: "CT",
    codice_elettorale: 4190210290,
    codice_istat: 87030,
    codice_belfiore: "F781",
  },
  {
    id: 4437,
    comune: "MOTTA SANTA LUCIA",
    sigla: "CZ",
    codice_elettorale: 3180220800,
    codice_istat: 79083,
    codice_belfiore: "F780",
  },
  {
    id: 4438,
    comune: "MOTTA VISCONTI",
    sigla: "MI",
    codice_elettorale: 1030491500,
    codice_istat: 15151,
    codice_belfiore: "F783",
  },
  {
    id: 4439,
    comune: "MOTTAFOLLONE",
    sigla: "CS",
    codice_elettorale: 3180250860,
    codice_istat: 78085,
    codice_belfiore: "F775",
  },
  {
    id: 4440,
    comune: "MOTTALCIATA",
    sigla: "BI",
    codice_elettorale: 1010960370,
    codice_istat: 96037,
    codice_belfiore: "F776",
  },
  {
    id: 4441,
    comune: "MOTTEGGIANA",
    sigla: "MN",
    codice_elettorale: 1030450370,
    codice_istat: 20037,
    codice_belfiore: "B012",
  },
  {
    id: 4442,
    comune: "MOTTOLA",
    sigla: "TA",
    codice_elettorale: 3160780190,
    codice_istat: 73019,
    codice_belfiore: "F784",
  },
  {
    id: 4443,
    comune: "MOZZAGROGNA",
    sigla: "CH",
    codice_elettorale: 3130230560,
    codice_istat: 69056,
    codice_belfiore: "F785",
  },
  {
    id: 4444,
    comune: "MOZZANICA",
    sigla: "BG",
    codice_elettorale: 1030121350,
    codice_istat: 16142,
    codice_belfiore: "F786",
  },
  {
    id: 4445,
    comune: "MOZZATE",
    sigla: "CO",
    codice_elettorale: 1030241521,
    codice_istat: 13159,
    codice_belfiore: "F788",
  },
  {
    id: 4446,
    comune: "MOZZECANE",
    sigla: "VR",
    codice_elettorale: 1050890510,
    codice_istat: 23051,
    codice_belfiore: "F789",
  },
  {
    id: 4447,
    comune: "MOZZO",
    sigla: "BG",
    codice_elettorale: 1030121360,
    codice_istat: 16143,
    codice_belfiore: "F791",
  },
  {
    id: 4448,
    comune: "MUCCIA",
    sigla: "MC",
    codice_elettorale: 2110440340,
    codice_istat: 43034,
    codice_belfiore: "F793",
  },
  {
    id: 4449,
    comune: "MUGGIA",
    sigla: "TS",
    codice_elettorale: 1060920030,
    codice_istat: 32003,
    codice_belfiore: "F795",
  },
  {
    id: 4450,
    comune: "MUGGIO'",
    sigla: "MB",
    codice_elettorale: 1031040340,
    codice_istat: 108034,
    codice_belfiore: "F797",
  },
  {
    id: 4451,
    comune: "MUGNANO DEL CARDINALE",
    sigla: "AV",
    codice_elettorale: 3150080640,
    codice_istat: 64065,
    codice_belfiore: "F798",
  },
  {
    id: 4452,
    comune: "MUGNANO DI NAPOLI",
    sigla: "NA",
    codice_elettorale: 3150510480,
    codice_istat: 63048,
    codice_belfiore: "F799",
  },
  {
    id: 4453,
    comune: "MULAZZANO",
    sigla: "LO",
    codice_elettorale: 1030990410,
    codice_istat: 98041,
    codice_belfiore: "F801",
  },
  {
    id: 4454,
    comune: "MULAZZO",
    sigla: "MS",
    codice_elettorale: 2090460120,
    codice_istat: 45012,
    codice_belfiore: "F802",
  },
  {
    id: 4455,
    comune: "MURA",
    sigla: "BS",
    codice_elettorale: 1030151060,
    codice_istat: 17115,
    codice_belfiore: "F806",
  },
  {
    id: 4456,
    comune: "MURAVERA",
    sigla: "SU",
    codice_elettorale: 4201110420,
    codice_istat: 111042,
    codice_belfiore: "F808",
  },
  {
    id: 4457,
    comune: "MURAZZANO",
    sigla: "CN",
    codice_elettorale: 1010271450,
    codice_istat: 4145,
    codice_belfiore: "F809",
  },
  {
    id: 4458,
    comune: "MURELLO",
    sigla: "CN",
    codice_elettorale: 1010271460,
    codice_istat: 4146,
    codice_belfiore: "F811",
  },
  {
    id: 4459,
    comune: "MURIALDO",
    sigla: "SV",
    codice_elettorale: 1070740400,
    codice_istat: 9040,
    codice_belfiore: "F813",
  },
  {
    id: 4460,
    comune: "MURISENGO",
    sigla: "AL",
    codice_elettorale: 1010021100,
    codice_istat: 6113,
    codice_belfiore: "F814",
  },
  {
    id: 4461,
    comune: "MURLO",
    sigla: "SI",
    codice_elettorale: 2090750190,
    codice_istat: 52019,
    codice_belfiore: "F815",
  },
  {
    id: 4462,
    comune: "MURO LECCESE",
    sigla: "LE",
    codice_elettorale: 3160410500,
    codice_istat: 75051,
    codice_belfiore: "F816",
  },
  {
    id: 4463,
    comune: "MURO LUCANO",
    sigla: "PZ",
    codice_elettorale: 3170640520,
    codice_istat: 76053,
    codice_belfiore: "F817",
  },
  {
    id: 4464,
    comune: "MUROS",
    sigla: "SS",
    codice_elettorale: 4200730430,
    codice_istat: 90043,
    codice_belfiore: "F818",
  },
  {
    id: 4465,
    comune: "MUSCOLINE",
    sigla: "BS",
    codice_elettorale: 1030151070,
    codice_istat: 17116,
    codice_belfiore: "F820",
  },
  {
    id: 4466,
    comune: "MUSEI",
    sigla: "SU",
    codice_elettorale: 4201110430,
    codice_istat: 111043,
    codice_belfiore: "F822",
  },
  {
    id: 4467,
    comune: "MUSILE DI PIAVE",
    sigla: "VE",
    codice_elettorale: 1050870250,
    codice_istat: 27025,
    codice_belfiore: "F826",
  },
  {
    id: 4468,
    comune: "MUSSO",
    sigla: "CO",
    codice_elettorale: 1030241530,
    codice_istat: 13160,
    codice_belfiore: "F828",
  },
  {
    id: 4469,
    comune: "MUSSOLENTE",
    sigla: "VI",
    codice_elettorale: 1050900700,
    codice_istat: 24070,
    codice_belfiore: "F829",
  },
  {
    id: 4470,
    comune: "MUSSOMELI",
    sigla: "CL",
    codice_elettorale: 4190180120,
    codice_istat: 85012,
    codice_belfiore: "F830",
  },
  {
    id: 4471,
    comune: "MUZZANA DEL TURGNANO",
    sigla: "UD",
    codice_elettorale: 1060850640,
    codice_istat: 30064,
    codice_belfiore: "F832",
  },
  {
    id: 4472,
    comune: "MUZZANO",
    sigla: "BI",
    codice_elettorale: 1010960380,
    codice_istat: 96038,
    codice_belfiore: "F833",
  },
  {
    id: 4473,
    comune: "NAGO-TORBOLE",
    sigla: "TN",
    codice_elettorale: 1040831161,
    codice_istat: 22124,
    codice_belfiore: "F835",
  },
  {
    id: 4474,
    comune: "NALLES/NALS",
    sigla: "BZ",
    codice_elettorale: 1040140520,
    codice_istat: 21055,
    codice_belfiore: "F836",
  },
  {
    id: 4475,
    comune: "NANTO",
    sigla: "VI",
    codice_elettorale: 1050900710,
    codice_istat: 24071,
    codice_belfiore: "F838",
  },
  {
    id: 4476,
    comune: "NAPOLI",
    sigla: "NA",
    codice_elettorale: 3150510490,
    codice_istat: 63049,
    codice_belfiore: "F839",
  },
  {
    id: 4477,
    comune: "NARBOLIA",
    sigla: "OR",
    codice_elettorale: 4200950310,
    codice_istat: 95031,
    codice_belfiore: "F840",
  },
  {
    id: 4478,
    comune: "NARCAO",
    sigla: "SU",
    codice_elettorale: 4201110440,
    codice_istat: 111044,
    codice_belfiore: "F841",
  },
  {
    id: 4479,
    comune: "NARDO'",
    sigla: "LE",
    codice_elettorale: 3160410510,
    codice_istat: 75052,
    codice_belfiore: "F842",
  },
  {
    id: 4480,
    comune: "NARDODIPACE",
    sigla: "VV",
    codice_elettorale: 3181030240,
    codice_istat: 102024,
    codice_belfiore: "F843",
  },
  {
    id: 4481,
    comune: "NARNI",
    sigla: "TR",
    codice_elettorale: 2100800220,
    codice_istat: 55022,
    codice_belfiore: "F844",
  },
  {
    id: 4482,
    comune: "NARO",
    sigla: "AG",
    codice_elettorale: 4190010260,
    codice_istat: 84026,
    codice_belfiore: "F845",
  },
  {
    id: 4483,
    comune: "NARZOLE",
    sigla: "CN",
    codice_elettorale: 1010271470,
    codice_istat: 4147,
    codice_belfiore: "F846",
  },
  {
    id: 4484,
    comune: "NASINO",
    sigla: "SV",
    codice_elettorale: 1070740410,
    codice_istat: 9041,
    codice_belfiore: "F847",
  },
  {
    id: 4485,
    comune: "NASO",
    sigla: "ME",
    codice_elettorale: 4190480590,
    codice_istat: 83060,
    codice_belfiore: "F848",
  },
  {
    id: 4486,
    comune: "NATURNO/NATURNS",
    sigla: "BZ",
    codice_elettorale: 1040140530,
    codice_istat: 21056,
    codice_belfiore: "F849",
  },
  {
    id: 4487,
    comune: "NAVE",
    sigla: "BS",
    codice_elettorale: 1030151080,
    codice_istat: 17117,
    codice_belfiore: "F851",
  },
  {
    id: 4488,
    comune: "NAVELLI",
    sigla: "AQ",
    codice_elettorale: 3130380580,
    codice_istat: 66058,
    codice_belfiore: "F852",
  },
  {
    id: 4489,
    comune: "NAZ-SCIAVES/NATZ-SCHABS",
    sigla: "BZ",
    codice_elettorale: 1040140540,
    codice_istat: 21057,
    codice_belfiore: "F856",
  },
  {
    id: 4490,
    comune: "NAZZANO",
    sigla: "RM",
    codice_elettorale: 2120700680,
    codice_istat: 58069,
    codice_belfiore: "F857",
  },
  {
    id: 4491,
    comune: "NE",
    sigla: "GE",
    codice_elettorale: 1070340400,
    codice_istat: 10040,
    codice_belfiore: "F858",
  },
  {
    id: 4492,
    comune: "NEBBIUNO",
    sigla: "NO",
    codice_elettorale: 1010520970,
    codice_istat: 3103,
    codice_belfiore: "F859",
  },
  {
    id: 4493,
    comune: "NEGRAR DI VALPOLICELLA",
    sigla: "VR",
    codice_elettorale: 1050890520,
    codice_istat: 23052,
    codice_belfiore: "F861",
  },
  {
    id: 4494,
    comune: "NEIRONE",
    sigla: "GE",
    codice_elettorale: 1070340410,
    codice_istat: 10041,
    codice_belfiore: "F862",
  },
  {
    id: 4495,
    comune: "NEIVE",
    sigla: "CN",
    codice_elettorale: 1010271480,
    codice_istat: 4148,
    codice_belfiore: "F863",
  },
  {
    id: 4496,
    comune: "NEMBRO",
    sigla: "BG",
    codice_elettorale: 1030121370,
    codice_istat: 16144,
    codice_belfiore: "F864",
  },
  {
    id: 4497,
    comune: "NEMI",
    sigla: "RM",
    codice_elettorale: 2120700690,
    codice_istat: 58070,
    codice_belfiore: "F865",
  },
  {
    id: 4498,
    comune: "NEMOLI",
    sigla: "PZ",
    codice_elettorale: 3170640530,
    codice_istat: 76054,
    codice_belfiore: "F866",
  },
  {
    id: 4499,
    comune: "NEONELI",
    sigla: "OR",
    codice_elettorale: 4200950320,
    codice_istat: 95032,
    codice_belfiore: "F867",
  },
  {
    id: 4500,
    comune: "NEPI",
    sigla: "VT",
    codice_elettorale: 2120910380,
    codice_istat: 56039,
    codice_belfiore: "F868",
  },
  {
    id: 4501,
    comune: "NERETO",
    sigla: "TE",
    codice_elettorale: 3130790300,
    codice_istat: 67031,
    codice_belfiore: "F870",
  },
  {
    id: 4502,
    comune: "NEROLA",
    sigla: "RM",
    codice_elettorale: 2120700700,
    codice_istat: 58071,
    codice_belfiore: "F871",
  },
  {
    id: 4503,
    comune: "NERVESA DELLA BATTAGLIA",
    sigla: "TV",
    codice_elettorale: 1050840490,
    codice_istat: 26050,
    codice_belfiore: "F872",
  },
  {
    id: 4504,
    comune: "NERVIANO",
    sigla: "MI",
    codice_elettorale: 1030491530,
    codice_istat: 15154,
    codice_belfiore: "F874",
  },
  {
    id: 4505,
    comune: "NESPOLO",
    sigla: "RI",
    codice_elettorale: 2120690440,
    codice_istat: 57046,
    codice_belfiore: "F876",
  },
  {
    id: 4506,
    comune: "NESSO",
    sigla: "CO",
    codice_elettorale: 1030241540,
    codice_istat: 13161,
    codice_belfiore: "F877",
  },
  {
    id: 4507,
    comune: "NETRO",
    sigla: "BI",
    codice_elettorale: 1010960390,
    codice_istat: 96039,
    codice_belfiore: "F878",
  },
  {
    id: 4508,
    comune: "NETTUNO",
    sigla: "RM",
    codice_elettorale: 2120700710,
    codice_istat: 58072,
    codice_belfiore: "F880",
  },
  {
    id: 4509,
    comune: "NEVIANO",
    sigla: "LE",
    codice_elettorale: 3160410520,
    codice_istat: 75053,
    codice_belfiore: "F881",
  },
  {
    id: 4510,
    comune: "NEVIANO DEGLI ARDUINI",
    sigla: "PR",
    codice_elettorale: 1080560240,
    codice_istat: 34024,
    codice_belfiore: "F882",
  },
  {
    id: 4511,
    comune: "NEVIGLIE",
    sigla: "CN",
    codice_elettorale: 1010271490,
    codice_istat: 4149,
    codice_belfiore: "F883",
  },
  {
    id: 4512,
    comune: "NIARDO",
    sigla: "BS",
    codice_elettorale: 1030151090,
    codice_istat: 17118,
    codice_belfiore: "F884",
  },
  {
    id: 4513,
    comune: "NIBBIOLA",
    sigla: "NO",
    codice_elettorale: 1010520980,
    codice_istat: 3104,
    codice_belfiore: "F886",
  },
  {
    id: 4514,
    comune: "NIBIONNO",
    sigla: "LC",
    codice_elettorale: 1030980560,
    codice_istat: 97056,
    codice_belfiore: "F887",
  },
  {
    id: 4515,
    comune: "NICHELINO",
    sigla: "TO",
    codice_elettorale: 1010811620,
    codice_istat: 1164,
    codice_belfiore: "F889",
  },
  {
    id: 4516,
    comune: "NICOLOSI",
    sigla: "CT",
    codice_elettorale: 4190210300,
    codice_istat: 87031,
    codice_belfiore: "F890",
  },
  {
    id: 4517,
    comune: "NICORVO",
    sigla: "PV",
    codice_elettorale: 1030571000,
    codice_istat: 18103,
    codice_belfiore: "F891",
  },
  {
    id: 4518,
    comune: "NICOSIA",
    sigla: "EN",
    codice_elettorale: 4190280120,
    codice_istat: 86012,
    codice_belfiore: "F892",
  },
  {
    id: 4519,
    comune: "NICOTERA",
    sigla: "VV",
    codice_elettorale: 3181030250,
    codice_istat: 102025,
    codice_belfiore: "F893",
  },
  {
    id: 4520,
    comune: "NIELLA BELBO",
    sigla: "CN",
    codice_elettorale: 1010271500,
    codice_istat: 4150,
    codice_belfiore: "F894",
  },
  {
    id: 4521,
    comune: "NIELLA TANARO",
    sigla: "CN",
    codice_elettorale: 1010271510,
    codice_istat: 4151,
    codice_belfiore: "F895",
  },
  {
    id: 4522,
    comune: "NIMIS",
    sigla: "UD",
    codice_elettorale: 1060850650,
    codice_istat: 30065,
    codice_belfiore: "F898",
  },
  {
    id: 4523,
    comune: "NISCEMI",
    sigla: "CL",
    codice_elettorale: 4190180130,
    codice_istat: 85013,
    codice_belfiore: "F899",
  },
  {
    id: 4524,
    comune: "NISSORIA",
    sigla: "EN",
    codice_elettorale: 4190280130,
    codice_istat: 86013,
    codice_belfiore: "F900",
  },
  {
    id: 4525,
    comune: "NIZZA DI SICILIA",
    sigla: "ME",
    codice_elettorale: 4190480600,
    codice_istat: 83061,
    codice_belfiore: "F901",
  },
  {
    id: 4526,
    comune: "NIZZA MONFERRATO",
    sigla: "AT",
    codice_elettorale: 1010070800,
    codice_istat: 5080,
    codice_belfiore: "F902",
  },
  {
    id: 4527,
    comune: "NOALE",
    sigla: "VE",
    codice_elettorale: 1050870260,
    codice_istat: 27026,
    codice_belfiore: "F904",
  },
  {
    id: 4528,
    comune: "NOASCA",
    sigla: "TO",
    codice_elettorale: 1010811630,
    codice_istat: 1165,
    codice_belfiore: "F906",
  },
  {
    id: 4529,
    comune: "NOCARA",
    sigla: "CS",
    codice_elettorale: 3180250870,
    codice_istat: 78086,
    codice_belfiore: "F907",
  },
  {
    id: 4530,
    comune: "NOCCIANO",
    sigla: "PE",
    codice_elettorale: 3130600260,
    codice_istat: 68026,
    codice_belfiore: "F908",
  },
  {
    id: 4531,
    comune: "NOCERA INFERIORE",
    sigla: "SA",
    codice_elettorale: 3150720780,
    codice_istat: 65078,
    codice_belfiore: "F912",
  },
  {
    id: 4532,
    comune: "NOCERA SUPERIORE",
    sigla: "SA",
    codice_elettorale: 3150720790,
    codice_istat: 65079,
    codice_belfiore: "F913",
  },
  {
    id: 4533,
    comune: "NOCERA TERINESE",
    sigla: "CZ",
    codice_elettorale: 3180220840,
    codice_istat: 79087,
    codice_belfiore: "F910",
  },
  {
    id: 4534,
    comune: "NOCERA UMBRA",
    sigla: "PG",
    codice_elettorale: 2100580340,
    codice_istat: 54034,
    codice_belfiore: "F911",
  },
  {
    id: 4535,
    comune: "NOCETO",
    sigla: "PR",
    codice_elettorale: 1080560250,
    codice_istat: 34025,
    codice_belfiore: "F914",
  },
  {
    id: 4536,
    comune: "NOCI",
    sigla: "BA",
    codice_elettorale: 3160090310,
    codice_istat: 72031,
    codice_belfiore: "F915",
  },
  {
    id: 4537,
    comune: "NOCIGLIA",
    sigla: "LE",
    codice_elettorale: 3160410530,
    codice_istat: 75054,
    codice_belfiore: "F916",
  },
  {
    id: 4538,
    comune: "NOEPOLI",
    sigla: "PZ",
    codice_elettorale: 3170640540,
    codice_istat: 76055,
    codice_belfiore: "F917",
  },
  {
    id: 4539,
    comune: "NOGARA",
    sigla: "VR",
    codice_elettorale: 1050890530,
    codice_istat: 23053,
    codice_belfiore: "F918",
  },
  {
    id: 4540,
    comune: "NOGAREDO",
    sigla: "TN",
    codice_elettorale: 1040831181,
    codice_istat: 22127,
    codice_belfiore: "F920",
  },
  {
    id: 4541,
    comune: "NOGAROLE ROCCA",
    sigla: "VR",
    codice_elettorale: 1050890540,
    codice_istat: 23054,
    codice_belfiore: "F921",
  },
  {
    id: 4542,
    comune: "NOGAROLE VICENTINO",
    sigla: "VI",
    codice_elettorale: 1050900720,
    codice_istat: 24072,
    codice_belfiore: "F922",
  },
  {
    id: 4543,
    comune: "NOICATTARO",
    sigla: "BA",
    codice_elettorale: 3160090320,
    codice_istat: 72032,
    codice_belfiore: "F923",
  },
  {
    id: 4544,
    comune: "NOLA",
    sigla: "NA",
    codice_elettorale: 3150510500,
    codice_istat: 63050,
    codice_belfiore: "F924",
  },
  {
    id: 4545,
    comune: "NOLE",
    sigla: "TO",
    codice_elettorale: 1010811640,
    codice_istat: 1166,
    codice_belfiore: "F925",
  },
  {
    id: 4546,
    comune: "NOLI",
    sigla: "SV",
    codice_elettorale: 1070740420,
    codice_istat: 9042,
    codice_belfiore: "F926",
  },
  {
    id: 4547,
    comune: "NOMAGLIO",
    sigla: "TO",
    codice_elettorale: 1010811641,
    codice_istat: 1167,
    codice_belfiore: "F927",
  },
  {
    id: 4548,
    comune: "NOMI",
    sigla: "TN",
    codice_elettorale: 1040831190,
    codice_istat: 22128,
    codice_belfiore: "F929",
  },
  {
    id: 4549,
    comune: "NONANTOLA",
    sigla: "MO",
    codice_elettorale: 1080500270,
    codice_istat: 36027,
    codice_belfiore: "F930",
  },
  {
    id: 4550,
    comune: "NONE",
    sigla: "TO",
    codice_elettorale: 1010811650,
    codice_istat: 1168,
    codice_belfiore: "F931",
  },
  {
    id: 4551,
    comune: "NONIO",
    sigla: "VB",
    codice_elettorale: 1011020480,
    codice_istat: 103048,
    codice_belfiore: "F932",
  },
  {
    id: 4552,
    comune: "NORAGUGUME",
    sigla: "NU",
    codice_elettorale: 4200530480,
    codice_istat: 91050,
    codice_belfiore: "F933",
  },
  {
    id: 4553,
    comune: "NORBELLO",
    sigla: "OR",
    codice_elettorale: 4200950330,
    codice_istat: 95033,
    codice_belfiore: "F934",
  },
  {
    id: 4554,
    comune: "NORCIA",
    sigla: "PG",
    codice_elettorale: 2100580350,
    codice_istat: 54035,
    codice_belfiore: "F935",
  },
  {
    id: 4555,
    comune: "NORMA",
    sigla: "LT",
    codice_elettorale: 2120400160,
    codice_istat: 59016,
    codice_belfiore: "F937",
  },
  {
    id: 4556,
    comune: "NOSATE",
    sigla: "MI",
    codice_elettorale: 1030491540,
    codice_istat: 15155,
    codice_belfiore: "F939",
  },
  {
    id: 4557,
    comune: "NOTARESCO",
    sigla: "TE",
    codice_elettorale: 3130790310,
    codice_istat: 67032,
    codice_belfiore: "F942",
  },
  {
    id: 4558,
    comune: "NOTO",
    sigla: "SR",
    codice_elettorale: 4190760130,
    codice_istat: 89013,
    codice_belfiore: "F943",
  },
  {
    id: 4559,
    comune: "NOVA LEVANTE/WELSCHNOFEN",
    sigla: "BZ",
    codice_elettorale: 1040140550,
    codice_istat: 21058,
    codice_belfiore: "F949",
  },
  {
    id: 4560,
    comune: "NOVA MILANESE",
    sigla: "MB",
    codice_elettorale: 1031040350,
    codice_istat: 108035,
    codice_belfiore: "F944",
  },
  {
    id: 4561,
    comune: "NOVA PONENTE/DEUTSCHNOFEN",
    sigla: "BZ",
    codice_elettorale: 1040140560,
    codice_istat: 21059,
    codice_belfiore: "F950",
  },
  {
    id: 4562,
    comune: "NOVA SIRI",
    sigla: "MT",
    codice_elettorale: 3170470180,
    codice_istat: 77018,
    codice_belfiore: "A942",
  },
  {
    id: 4563,
    comune: "NOVAFELTRIA",
    sigla: "RN",
    codice_elettorale: 1081010115,
    codice_istat: 99023,
    codice_belfiore: "F137",
  },
  {
    id: 4564,
    comune: "NOVALEDO",
    sigla: "TN",
    codice_elettorale: 1040831200,
    codice_istat: 22129,
    codice_belfiore: "F947",
  },
  {
    id: 4565,
    comune: "NOVALESA",
    sigla: "TO",
    codice_elettorale: 1010811660,
    codice_istat: 1169,
    codice_belfiore: "F948",
  },
  {
    id: 4566,
    comune: "NOVARA",
    sigla: "NO",
    codice_elettorale: 1010521000,
    codice_istat: 3106,
    codice_belfiore: "F952",
  },
  {
    id: 4567,
    comune: "NOVARA DI SICILIA",
    sigla: "ME",
    codice_elettorale: 4190480610,
    codice_istat: 83062,
    codice_belfiore: "F951",
  },
  {
    id: 4568,
    comune: "NOVATE MEZZOLA",
    sigla: "SO",
    codice_elettorale: 1030770460,
    codice_istat: 14046,
    codice_belfiore: "F956",
  },
  {
    id: 4569,
    comune: "NOVATE MILANESE",
    sigla: "MI",
    codice_elettorale: 1030491560,
    codice_istat: 15157,
    codice_belfiore: "F955",
  },
  {
    id: 4570,
    comune: "NOVE",
    sigla: "VI",
    codice_elettorale: 1050900730,
    codice_istat: 24073,
    codice_belfiore: "F957",
  },
  {
    id: 4571,
    comune: "NOVEDRATE",
    sigla: "CO",
    codice_elettorale: 1030241560,
    codice_istat: 13163,
    codice_belfiore: "F958",
  },
  {
    id: 4572,
    comune: "NOVELLA",
    sigla: "TN",
    codice_elettorale: 1040831196,
    codice_istat: 22253,
    codice_belfiore: "M430",
  },
  {
    id: 4573,
    comune: "NOVELLARA",
    sigla: "RE",
    codice_elettorale: 1080680280,
    codice_istat: 35028,
    codice_belfiore: "F960",
  },
  {
    id: 4574,
    comune: "NOVELLO",
    sigla: "CN",
    codice_elettorale: 1010271520,
    codice_istat: 4152,
    codice_belfiore: "F961",
  },
  {
    id: 4575,
    comune: "NOVENTA DI PIAVE",
    sigla: "VE",
    codice_elettorale: 1050870270,
    codice_istat: 27027,
    codice_belfiore: "F963",
  },
  {
    id: 4576,
    comune: "NOVENTA PADOVANA",
    sigla: "PD",
    codice_elettorale: 1050540580,
    codice_istat: 28058,
    codice_belfiore: "F962",
  },
  {
    id: 4577,
    comune: "NOVENTA VICENTINA",
    sigla: "VI",
    codice_elettorale: 1050900740,
    codice_istat: 24074,
    codice_belfiore: "F964",
  },
  {
    id: 4578,
    comune: "NOVI DI MODENA",
    sigla: "MO",
    codice_elettorale: 1080500280,
    codice_istat: 36028,
    codice_belfiore: "F966",
  },
  {
    id: 4579,
    comune: "NOVI LIGURE",
    sigla: "AL",
    codice_elettorale: 1010021110,
    codice_istat: 6114,
    codice_belfiore: "F965",
  },
  {
    id: 4580,
    comune: "NOVI VELIA",
    sigla: "SA",
    codice_elettorale: 3150720800,
    codice_istat: 65080,
    codice_belfiore: "F967",
  },
  {
    id: 4581,
    comune: "NOVIGLIO",
    sigla: "MI",
    codice_elettorale: 1030491570,
    codice_istat: 15158,
    codice_belfiore: "F968",
  },
  {
    id: 4582,
    comune: "NOVOLI",
    sigla: "LE",
    codice_elettorale: 3160410540,
    codice_istat: 75055,
    codice_belfiore: "F970",
  },
  {
    id: 4583,
    comune: "NUCETTO",
    sigla: "CN",
    codice_elettorale: 1010271530,
    codice_istat: 4153,
    codice_belfiore: "F972",
  },
  {
    id: 4584,
    comune: "NUGHEDU SAN NICOLO'",
    sigla: "SS",
    codice_elettorale: 4200730440,
    codice_istat: 90044,
    codice_belfiore: "F975",
  },
  {
    id: 4585,
    comune: "NUGHEDU SANTA VITTORIA",
    sigla: "OR",
    codice_elettorale: 4200950340,
    codice_istat: 95034,
    codice_belfiore: "F974",
  },
  {
    id: 4586,
    comune: "NULE",
    sigla: "SS",
    codice_elettorale: 4200730450,
    codice_istat: 90045,
    codice_belfiore: "F976",
  },
  {
    id: 4587,
    comune: "NULVI",
    sigla: "SS",
    codice_elettorale: 4200730460,
    codice_istat: 90046,
    codice_belfiore: "F977",
  },
  {
    id: 4588,
    comune: "NUMANA",
    sigla: "AN",
    codice_elettorale: 2110030320,
    codice_istat: 42032,
    codice_belfiore: "F978",
  },
  {
    id: 4589,
    comune: "NUORO",
    sigla: "NU",
    codice_elettorale: 4200530490,
    codice_istat: 91051,
    codice_belfiore: "F979",
  },
  {
    id: 4590,
    comune: "NURACHI",
    sigla: "OR",
    codice_elettorale: 4200950350,
    codice_istat: 95035,
    codice_belfiore: "F980",
  },
  {
    id: 4591,
    comune: "NURAGUS",
    sigla: "SU",
    codice_elettorale: 4201110450,
    codice_istat: 111045,
    codice_belfiore: "F981",
  },
  {
    id: 4592,
    comune: "NURALLAO",
    sigla: "SU",
    codice_elettorale: 4201110460,
    codice_istat: 111046,
    codice_belfiore: "F982",
  },
  {
    id: 4593,
    comune: "NURAMINIS",
    sigla: "SU",
    codice_elettorale: 4201110470,
    codice_istat: 111047,
    codice_belfiore: "F983",
  },
  {
    id: 4594,
    comune: "NURECI",
    sigla: "OR",
    codice_elettorale: 4200950360,
    codice_istat: 95036,
    codice_belfiore: "F985",
  },
  {
    id: 4595,
    comune: "NURRI",
    sigla: "SU",
    codice_elettorale: 4201110480,
    codice_istat: 111048,
    codice_belfiore: "F986",
  },
  {
    id: 4596,
    comune: "NUS",
    sigla: "AO",
    codice_elettorale: 1020040440,
    codice_istat: 7045,
    codice_belfiore: "F987",
  },
  {
    id: 4597,
    comune: "NUSCO",
    sigla: "AV",
    codice_elettorale: 3150080650,
    codice_istat: 64066,
    codice_belfiore: "F988",
  },
  {
    id: 4598,
    comune: "NUVOLENTO",
    sigla: "BS",
    codice_elettorale: 1030151100,
    codice_istat: 17119,
    codice_belfiore: "F989",
  },
  {
    id: 4599,
    comune: "NUVOLERA",
    sigla: "BS",
    codice_elettorale: 1030151110,
    codice_istat: 17120,
    codice_belfiore: "F990",
  },
  {
    id: 4600,
    comune: "NUXIS",
    sigla: "SU",
    codice_elettorale: 4201110490,
    codice_istat: 111049,
    codice_belfiore: "F991",
  },
  {
    id: 4601,
    comune: "OCCHIEPPO INFERIORE",
    sigla: "BI",
    codice_elettorale: 1010960400,
    codice_istat: 96040,
    codice_belfiore: "F992",
  },
  {
    id: 4602,
    comune: "OCCHIEPPO SUPERIORE",
    sigla: "BI",
    codice_elettorale: 1010960410,
    codice_istat: 96041,
    codice_belfiore: "F993",
  },
  {
    id: 4603,
    comune: "OCCHIOBELLO",
    sigla: "RO",
    codice_elettorale: 1050710330,
    codice_istat: 29033,
    codice_belfiore: "F994",
  },
  {
    id: 4604,
    comune: "OCCIMIANO",
    sigla: "AL",
    codice_elettorale: 1010021120,
    codice_istat: 6115,
    codice_belfiore: "F995",
  },
  {
    id: 4605,
    comune: "OCRE",
    sigla: "AQ",
    codice_elettorale: 3130380590,
    codice_istat: 66059,
    codice_belfiore: "F996",
  },
  {
    id: 4606,
    comune: "ODALENGO GRANDE",
    sigla: "AL",
    codice_elettorale: 1010021130,
    codice_istat: 6116,
    codice_belfiore: "F997",
  },
  {
    id: 4607,
    comune: "ODALENGO PICCOLO",
    sigla: "AL",
    codice_elettorale: 1010021140,
    codice_istat: 6117,
    codice_belfiore: "F998",
  },
  {
    id: 4608,
    comune: "ODERZO",
    sigla: "TV",
    codice_elettorale: 1050840500,
    codice_istat: 26051,
    codice_belfiore: "F999",
  },
  {
    id: 4609,
    comune: "ODOLO",
    sigla: "BS",
    codice_elettorale: 1030151120,
    codice_istat: 17121,
    codice_belfiore: "G001",
  },
  {
    id: 4610,
    comune: "OFENA",
    sigla: "AQ",
    codice_elettorale: 3130380600,
    codice_istat: 66060,
    codice_belfiore: "G002",
  },
  {
    id: 4611,
    comune: "OFFAGNA",
    sigla: "AN",
    codice_elettorale: 2110030330,
    codice_istat: 42033,
    codice_belfiore: "G003",
  },
  {
    id: 4612,
    comune: "OFFANENGO",
    sigla: "CR",
    codice_elettorale: 1030260610,
    codice_istat: 19062,
    codice_belfiore: "G004",
  },
  {
    id: 4613,
    comune: "OFFIDA",
    sigla: "AP",
    codice_elettorale: 2110060540,
    codice_istat: 44054,
    codice_belfiore: "G005",
  },
  {
    id: 4614,
    comune: "OFFLAGA",
    sigla: "BS",
    codice_elettorale: 1030151130,
    codice_istat: 17122,
    codice_belfiore: "G006",
  },
  {
    id: 4615,
    comune: "OGGEBBIO",
    sigla: "VB",
    codice_elettorale: 1011020490,
    codice_istat: 103049,
    codice_belfiore: "G007",
  },
  {
    id: 4616,
    comune: "OGGIONA CON SANTO STEFANO",
    sigla: "VA",
    codice_elettorale: 1030860910,
    codice_istat: 12107,
    codice_belfiore: "G008",
  },
  {
    id: 4617,
    comune: "OGGIONO",
    sigla: "LC",
    codice_elettorale: 1030980570,
    codice_istat: 97057,
    codice_belfiore: "G009",
  },
  {
    id: 4618,
    comune: "OGLIANICO",
    sigla: "TO",
    codice_elettorale: 1010811670,
    codice_istat: 1170,
    codice_belfiore: "G010",
  },
  {
    id: 4619,
    comune: "OGLIASTRO CILENTO",
    sigla: "SA",
    codice_elettorale: 3150720810,
    codice_istat: 65081,
    codice_belfiore: "G011",
  },
  {
    id: 4620,
    comune: "OLBIA",
    sigla: "SS",
    codice_elettorale: 4200730470,
    codice_istat: 90047,
    codice_belfiore: "G015",
  },
  {
    id: 4621,
    comune: "OLCENENGO",
    sigla: "VC",
    codice_elettorale: 1010880870,
    codice_istat: 2088,
    codice_belfiore: "G016",
  },
  {
    id: 4622,
    comune: "OLDENICO",
    sigla: "VC",
    codice_elettorale: 1010880880,
    codice_istat: 2089,
    codice_belfiore: "G018",
  },
  {
    id: 4623,
    comune: "OLEGGIO",
    sigla: "NO",
    codice_elettorale: 1010521020,
    codice_istat: 3108,
    codice_belfiore: "G019",
  },
  {
    id: 4624,
    comune: "OLEGGIO CASTELLO",
    sigla: "NO",
    codice_elettorale: 1010521030,
    codice_istat: 3109,
    codice_belfiore: "G020",
  },
  {
    id: 4625,
    comune: "OLEVANO DI LOMELLINA",
    sigla: "PV",
    codice_elettorale: 1030571010,
    codice_istat: 18104,
    codice_belfiore: "G021",
  },
  {
    id: 4626,
    comune: "OLEVANO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700720,
    codice_istat: 58073,
    codice_belfiore: "G022",
  },
  {
    id: 4627,
    comune: "OLEVANO SUL TUSCIANO",
    sigla: "SA",
    codice_elettorale: 3150720820,
    codice_istat: 65082,
    codice_belfiore: "G023",
  },
  {
    id: 4628,
    comune: "OLGIATE COMASCO",
    sigla: "CO",
    codice_elettorale: 1030241590,
    codice_istat: 13165,
    codice_belfiore: "G025",
  },
  {
    id: 4629,
    comune: "OLGIATE MOLGORA",
    sigla: "LC",
    codice_elettorale: 1030980580,
    codice_istat: 97058,
    codice_belfiore: "G026",
  },
  {
    id: 4630,
    comune: "OLGIATE OLONA",
    sigla: "VA",
    codice_elettorale: 1030860920,
    codice_istat: 12108,
    codice_belfiore: "G028",
  },
  {
    id: 4631,
    comune: "OLGINATE",
    sigla: "LC",
    codice_elettorale: 1030980590,
    codice_istat: 97059,
    codice_belfiore: "G030",
  },
  {
    id: 4632,
    comune: "OLIENA",
    sigla: "NU",
    codice_elettorale: 4200530530,
    codice_istat: 91055,
    codice_belfiore: "G031",
  },
  {
    id: 4633,
    comune: "OLIVA GESSI",
    sigla: "PV",
    codice_elettorale: 1030571020,
    codice_istat: 18105,
    codice_belfiore: "G032",
  },
  {
    id: 4634,
    comune: "OLIVADI",
    sigla: "CZ",
    codice_elettorale: 3180220850,
    codice_istat: 79088,
    codice_belfiore: "G034",
  },
  {
    id: 4635,
    comune: "OLIVERI",
    sigla: "ME",
    codice_elettorale: 4190480620,
    codice_istat: 83063,
    codice_belfiore: "G036",
  },
  {
    id: 4636,
    comune: "OLIVETO CITRA",
    sigla: "SA",
    codice_elettorale: 3150720830,
    codice_istat: 65083,
    codice_belfiore: "G039",
  },
  {
    id: 4637,
    comune: "OLIVETO LARIO",
    sigla: "LC",
    codice_elettorale: 1030980600,
    codice_istat: 97060,
    codice_belfiore: "G040",
  },
  {
    id: 4638,
    comune: "OLIVETO LUCANO",
    sigla: "MT",
    codice_elettorale: 3170470190,
    codice_istat: 77019,
    codice_belfiore: "G037",
  },
  {
    id: 4639,
    comune: "OLIVETTA SAN MICHELE",
    sigla: "IM",
    codice_elettorale: 1070370350,
    codice_istat: 8038,
    codice_belfiore: "G041",
  },
  {
    id: 4640,
    comune: "OLIVOLA",
    sigla: "AL",
    codice_elettorale: 1010021150,
    codice_istat: 6118,
    codice_belfiore: "G042",
  },
  {
    id: 4641,
    comune: "OLLASTRA",
    sigla: "OR",
    codice_elettorale: 4200950370,
    codice_istat: 95037,
    codice_belfiore: "G043",
  },
  {
    id: 4642,
    comune: "OLLOLAI",
    sigla: "NU",
    codice_elettorale: 4200530540,
    codice_istat: 91056,
    codice_belfiore: "G044",
  },
  {
    id: 4643,
    comune: "OLLOMONT",
    sigla: "AO",
    codice_elettorale: 1020040450,
    codice_istat: 7046,
    codice_belfiore: "G045",
  },
  {
    id: 4644,
    comune: "OLMEDO",
    sigla: "SS",
    codice_elettorale: 4200730480,
    codice_istat: 90048,
    codice_belfiore: "G046",
  },
  {
    id: 4645,
    comune: "OLMENETA",
    sigla: "CR",
    codice_elettorale: 1030260620,
    codice_istat: 19063,
    codice_belfiore: "G047",
  },
  {
    id: 4646,
    comune: "OLMO AL BREMBO",
    sigla: "BG",
    codice_elettorale: 1030121390,
    codice_istat: 16145,
    codice_belfiore: "G049",
  },
  {
    id: 4647,
    comune: "OLMO GENTILE",
    sigla: "AT",
    codice_elettorale: 1010070810,
    codice_istat: 5081,
    codice_belfiore: "G048",
  },
  {
    id: 4648,
    comune: "OLTRE IL COLLE",
    sigla: "BG",
    codice_elettorale: 1030121400,
    codice_istat: 16146,
    codice_belfiore: "G050",
  },
  {
    id: 4649,
    comune: "OLTRESSENDA ALTA",
    sigla: "BG",
    codice_elettorale: 1030121401,
    codice_istat: 16147,
    codice_belfiore: "G054",
  },
  {
    id: 4650,
    comune: "OLTRONA DI SAN MAMETTE",
    sigla: "CO",
    codice_elettorale: 1030241620,
    codice_istat: 13169,
    codice_belfiore: "G056",
  },
  {
    id: 4651,
    comune: "OLZAI",
    sigla: "NU",
    codice_elettorale: 4200530550,
    codice_istat: 91057,
    codice_belfiore: "G058",
  },
  {
    id: 4652,
    comune: "OME",
    sigla: "BS",
    codice_elettorale: 1030151140,
    codice_istat: 17123,
    codice_belfiore: "G061",
  },
  {
    id: 4653,
    comune: "OMEGNA",
    sigla: "VB",
    codice_elettorale: 1011020500,
    codice_istat: 103050,
    codice_belfiore: "G062",
  },
  {
    id: 4654,
    comune: "OMIGNANO",
    sigla: "SA",
    codice_elettorale: 3150720840,
    codice_istat: 65084,
    codice_belfiore: "G063",
  },
  {
    id: 4655,
    comune: "ONANI'",
    sigla: "NU",
    codice_elettorale: 4200530560,
    codice_istat: 91058,
    codice_belfiore: "G064",
  },
  {
    id: 4656,
    comune: "ONANO",
    sigla: "VT",
    codice_elettorale: 2120910390,
    codice_istat: 56040,
    codice_belfiore: "G065",
  },
  {
    id: 4657,
    comune: "ONCINO",
    sigla: "CN",
    codice_elettorale: 1010271540,
    codice_istat: 4154,
    codice_belfiore: "G066",
  },
  {
    id: 4658,
    comune: "ONETA",
    sigla: "BG",
    codice_elettorale: 1030121410,
    codice_istat: 16148,
    codice_belfiore: "G068",
  },
  {
    id: 4659,
    comune: "ONIFAI",
    sigla: "NU",
    codice_elettorale: 4200530570,
    codice_istat: 91059,
    codice_belfiore: "G070",
  },
  {
    id: 4660,
    comune: "ONIFERI",
    sigla: "NU",
    codice_elettorale: 4200530580,
    codice_istat: 91060,
    codice_belfiore: "G071",
  },
  {
    id: 4661,
    comune: "ONO SAN PIETRO",
    sigla: "BS",
    codice_elettorale: 1030151150,
    codice_istat: 17124,
    codice_belfiore: "G074",
  },
  {
    id: 4662,
    comune: "ONORE",
    sigla: "BG",
    codice_elettorale: 1030121411,
    codice_istat: 16149,
    codice_belfiore: "G075",
  },
  {
    id: 4663,
    comune: "ONZO",
    sigla: "SV",
    codice_elettorale: 1070740430,
    codice_istat: 9043,
    codice_belfiore: "G076",
  },
  {
    id: 4664,
    comune: "OPERA",
    sigla: "MI",
    codice_elettorale: 1030491580,
    codice_istat: 15159,
    codice_belfiore: "G078",
  },
  {
    id: 4665,
    comune: "OPI",
    sigla: "AQ",
    codice_elettorale: 3130380610,
    codice_istat: 66061,
    codice_belfiore: "G079",
  },
  {
    id: 4666,
    comune: "OPPEANO",
    sigla: "VR",
    codice_elettorale: 1050890550,
    codice_istat: 23055,
    codice_belfiore: "G080",
  },
  {
    id: 4667,
    comune: "OPPIDO LUCANO",
    sigla: "PZ",
    codice_elettorale: 3170640550,
    codice_istat: 76056,
    codice_belfiore: "G081",
  },
  {
    id: 4668,
    comune: "OPPIDO MAMERTINA",
    sigla: "RC",
    codice_elettorale: 3180670550,
    codice_istat: 80055,
    codice_belfiore: "G082",
  },
  {
    id: 4669,
    comune: "ORA/AUER",
    sigla: "BZ",
    codice_elettorale: 1040140570,
    codice_istat: 21060,
    codice_belfiore: "G083",
  },
  {
    id: 4670,
    comune: "ORANI",
    sigla: "NU",
    codice_elettorale: 4200530590,
    codice_istat: 91061,
    codice_belfiore: "G084",
  },
  {
    id: 4671,
    comune: "ORATINO",
    sigla: "CB",
    codice_elettorale: 3140190490,
    codice_istat: 70049,
    codice_belfiore: "G086",
  },
  {
    id: 4672,
    comune: "ORBASSANO",
    sigla: "TO",
    codice_elettorale: 1010811680,
    codice_istat: 1171,
    codice_belfiore: "G087",
  },
  {
    id: 4673,
    comune: "ORBETELLO",
    sigla: "GR",
    codice_elettorale: 2090360170,
    codice_istat: 53018,
    codice_belfiore: "G088",
  },
  {
    id: 4674,
    comune: "ORCIANO PISANO",
    sigla: "PI",
    codice_elettorale: 2090620221,
    codice_istat: 50023,
    codice_belfiore: "G090",
  },
  {
    id: 4675,
    comune: "ORCO FEGLINO",
    sigla: "SV",
    codice_elettorale: 1070740440,
    codice_istat: 9044,
    codice_belfiore: "D522",
  },
  {
    id: 4676,
    comune: "ORDONA",
    sigla: "FG",
    codice_elettorale: 3160310321,
    codice_istat: 71063,
    codice_belfiore: "M266",
  },
  {
    id: 4677,
    comune: "ORERO",
    sigla: "GE",
    codice_elettorale: 1070340420,
    codice_istat: 10042,
    codice_belfiore: "G093",
  },
  {
    id: 4678,
    comune: "ORGIANO",
    sigla: "VI",
    codice_elettorale: 1050900750,
    codice_istat: 24075,
    codice_belfiore: "G095",
  },
  {
    id: 4679,
    comune: "ORGOSOLO",
    sigla: "NU",
    codice_elettorale: 4200530600,
    codice_istat: 91062,
    codice_belfiore: "G097",
  },
  {
    id: 4680,
    comune: "ORIA",
    sigla: "BR",
    codice_elettorale: 3160160110,
    codice_istat: 74011,
    codice_belfiore: "G098",
  },
  {
    id: 4681,
    comune: "ORICOLA",
    sigla: "AQ",
    codice_elettorale: 3130380620,
    codice_istat: 66062,
    codice_belfiore: "G102",
  },
  {
    id: 4682,
    comune: "ORIGGIO",
    sigla: "VA",
    codice_elettorale: 1030860930,
    codice_istat: 12109,
    codice_belfiore: "G103",
  },
  {
    id: 4683,
    comune: "ORINO",
    sigla: "VA",
    codice_elettorale: 1030860931,
    codice_istat: 12110,
    codice_belfiore: "G105",
  },
  {
    id: 4684,
    comune: "ORIO AL SERIO",
    sigla: "BG",
    codice_elettorale: 1030121420,
    codice_istat: 16150,
    codice_belfiore: "G108",
  },
  {
    id: 4685,
    comune: "ORIO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010811690,
    codice_istat: 1172,
    codice_belfiore: "G109",
  },
  {
    id: 4686,
    comune: "ORIO LITTA",
    sigla: "LO",
    codice_elettorale: 1030990420,
    codice_istat: 98042,
    codice_belfiore: "G107",
  },
  {
    id: 4687,
    comune: "ORIOLO",
    sigla: "CS",
    codice_elettorale: 3180250880,
    codice_istat: 78087,
    codice_belfiore: "G110",
  },
  {
    id: 4688,
    comune: "ORIOLO ROMANO",
    sigla: "VT",
    codice_elettorale: 2120910400,
    codice_istat: 56041,
    codice_belfiore: "G111",
  },
  {
    id: 4689,
    comune: "ORISTANO",
    sigla: "OR",
    codice_elettorale: 4200950380,
    codice_istat: 95038,
    codice_belfiore: "G113",
  },
  {
    id: 4690,
    comune: "ORMEA",
    sigla: "CN",
    codice_elettorale: 1010271550,
    codice_istat: 4155,
    codice_belfiore: "G114",
  },
  {
    id: 4691,
    comune: "ORMELLE",
    sigla: "TV",
    codice_elettorale: 1050840510,
    codice_istat: 26052,
    codice_belfiore: "G115",
  },
  {
    id: 4692,
    comune: "ORNAGO",
    sigla: "MB",
    codice_elettorale: 1031040360,
    codice_istat: 108036,
    codice_belfiore: "G116",
  },
  {
    id: 4693,
    comune: "ORNAVASSO",
    sigla: "VB",
    codice_elettorale: 1011020510,
    codice_istat: 103051,
    codice_belfiore: "G117",
  },
  {
    id: 4694,
    comune: "ORNICA",
    sigla: "BG",
    codice_elettorale: 1030121430,
    codice_istat: 16151,
    codice_belfiore: "G118",
  },
  {
    id: 4695,
    comune: "OROSEI",
    sigla: "NU",
    codice_elettorale: 4200530610,
    codice_istat: 91063,
    codice_belfiore: "G119",
  },
  {
    id: 4696,
    comune: "OROTELLI",
    sigla: "NU",
    codice_elettorale: 4200530620,
    codice_istat: 91064,
    codice_belfiore: "G120",
  },
  {
    id: 4697,
    comune: "ORRIA",
    sigla: "SA",
    codice_elettorale: 3150720850,
    codice_istat: 65085,
    codice_belfiore: "G121",
  },
  {
    id: 4698,
    comune: "ORROLI",
    sigla: "SU",
    codice_elettorale: 4201110500,
    codice_istat: 111050,
    codice_belfiore: "G122",
  },
  {
    id: 4699,
    comune: "ORSAGO",
    sigla: "TV",
    codice_elettorale: 1050840520,
    codice_istat: 26053,
    codice_belfiore: "G123",
  },
  {
    id: 4700,
    comune: "ORSARA BORMIDA",
    sigla: "AL",
    codice_elettorale: 1010021160,
    codice_istat: 6119,
    codice_belfiore: "G124",
  },
  {
    id: 4701,
    comune: "ORSARA DI PUGLIA",
    sigla: "FG",
    codice_elettorale: 3160310330,
    codice_istat: 71035,
    codice_belfiore: "G125",
  },
  {
    id: 4702,
    comune: "ORSENIGO",
    sigla: "CO",
    codice_elettorale: 1030241630,
    codice_istat: 13170,
    codice_belfiore: "G126",
  },
  {
    id: 4703,
    comune: "ORSOGNA",
    sigla: "CH",
    codice_elettorale: 3130230570,
    codice_istat: 69057,
    codice_belfiore: "G128",
  },
  {
    id: 4704,
    comune: "ORSOMARSO",
    sigla: "CS",
    codice_elettorale: 3180250890,
    codice_istat: 78088,
    codice_belfiore: "G129",
  },
  {
    id: 4705,
    comune: "ORTA DI ATELLA",
    sigla: "CE",
    codice_elettorale: 3150200530,
    codice_istat: 61053,
    codice_belfiore: "G130",
  },
  {
    id: 4706,
    comune: "ORTA NOVA",
    sigla: "FG",
    codice_elettorale: 3160310340,
    codice_istat: 71036,
    codice_belfiore: "G131",
  },
  {
    id: 4707,
    comune: "ORTA SAN GIULIO",
    sigla: "NO",
    codice_elettorale: 1010521060,
    codice_istat: 3112,
    codice_belfiore: "G134",
  },
  {
    id: 4708,
    comune: "ORTACESUS",
    sigla: "SU",
    codice_elettorale: 4201110510,
    codice_istat: 111051,
    codice_belfiore: "G133",
  },
  {
    id: 4709,
    comune: "ORTE",
    sigla: "VT",
    codice_elettorale: 2120910410,
    codice_istat: 56042,
    codice_belfiore: "G135",
  },
  {
    id: 4710,
    comune: "ORTELLE",
    sigla: "LE",
    codice_elettorale: 3160410550,
    codice_istat: 75056,
    codice_belfiore: "G136",
  },
  {
    id: 4711,
    comune: "ORTEZZANO",
    sigla: "FM",
    codice_elettorale: 2111050290,
    codice_istat: 109029,
    codice_belfiore: "G137",
  },
  {
    id: 4712,
    comune: "ORTIGNANO RAGGIOLO",
    sigla: "AR",
    codice_elettorale: 2090050270,
    codice_istat: 51027,
    codice_belfiore: "G139",
  },
  {
    id: 4713,
    comune: "ORTISEI/ST. ULRICH",
    sigla: "BZ",
    codice_elettorale: 1040140580,
    codice_istat: 21061,
    codice_belfiore: "G140",
  },
  {
    id: 4714,
    comune: "ORTONA",
    sigla: "CH",
    codice_elettorale: 3130230580,
    codice_istat: 69058,
    codice_belfiore: "G141",
  },
  {
    id: 4715,
    comune: "ORTONA DEI MARSI",
    sigla: "AQ",
    codice_elettorale: 3130380630,
    codice_istat: 66063,
    codice_belfiore: "G142",
  },
  {
    id: 4716,
    comune: "ORTOVERO",
    sigla: "SV",
    codice_elettorale: 1070740450,
    codice_istat: 9045,
    codice_belfiore: "G144",
  },
  {
    id: 4717,
    comune: "ORTUCCHIO",
    sigla: "AQ",
    codice_elettorale: 3130380640,
    codice_istat: 66064,
    codice_belfiore: "G145",
  },
  {
    id: 4718,
    comune: "ORTUERI",
    sigla: "NU",
    codice_elettorale: 4200530640,
    codice_istat: 91066,
    codice_belfiore: "G146",
  },
  {
    id: 4719,
    comune: "ORUNE",
    sigla: "NU",
    codice_elettorale: 4200530650,
    codice_istat: 91067,
    codice_belfiore: "G147",
  },
  {
    id: 4720,
    comune: "ORVIETO",
    sigla: "TR",
    codice_elettorale: 2100800230,
    codice_istat: 55023,
    codice_belfiore: "G148",
  },
  {
    id: 4721,
    comune: "ORVINIO",
    sigla: "RI",
    codice_elettorale: 2120690450,
    codice_istat: 57047,
    codice_belfiore: "B595",
  },
  {
    id: 4722,
    comune: "ORZINUOVI",
    sigla: "BS",
    codice_elettorale: 1030151160,
    codice_istat: 17125,
    codice_belfiore: "G149",
  },
  {
    id: 4723,
    comune: "ORZIVECCHI",
    sigla: "BS",
    codice_elettorale: 1030151170,
    codice_istat: 17126,
    codice_belfiore: "G150",
  },
  {
    id: 4724,
    comune: "OSASCO",
    sigla: "TO",
    codice_elettorale: 1010811700,
    codice_istat: 1173,
    codice_belfiore: "G151",
  },
  {
    id: 4725,
    comune: "OSASIO",
    sigla: "TO",
    codice_elettorale: 1010811710,
    codice_istat: 1174,
    codice_belfiore: "G152",
  },
  {
    id: 4726,
    comune: "OSCHIRI",
    sigla: "SS",
    codice_elettorale: 4200730490,
    codice_istat: 90049,
    codice_belfiore: "G153",
  },
  {
    id: 4727,
    comune: "OSIDDA",
    sigla: "NU",
    codice_elettorale: 4200530660,
    codice_istat: 91068,
    codice_belfiore: "G154",
  },
  {
    id: 4728,
    comune: "OSIGLIA",
    sigla: "SV",
    codice_elettorale: 1070740460,
    codice_istat: 9046,
    codice_belfiore: "G155",
  },
  {
    id: 4729,
    comune: "OSILO",
    sigla: "SS",
    codice_elettorale: 4200730500,
    codice_istat: 90050,
    codice_belfiore: "G156",
  },
  {
    id: 4730,
    comune: "OSIMO",
    sigla: "AN",
    codice_elettorale: 2110030340,
    codice_istat: 42034,
    codice_belfiore: "G157",
  },
  {
    id: 4731,
    comune: "OSINI",
    sigla: "NU",
    codice_elettorale: 4200530670,
    codice_istat: 91069,
    codice_belfiore: "G158",
  },
  {
    id: 4732,
    comune: "OSIO SOPRA",
    sigla: "BG",
    codice_elettorale: 1030121440,
    codice_istat: 16152,
    codice_belfiore: "G159",
  },
  {
    id: 4733,
    comune: "OSIO SOTTO",
    sigla: "BG",
    codice_elettorale: 1030121450,
    codice_istat: 16153,
    codice_belfiore: "G160",
  },
  {
    id: 4734,
    comune: "OSNAGO",
    sigla: "LC",
    codice_elettorale: 1030980610,
    codice_istat: 97061,
    codice_belfiore: "G161",
  },
  {
    id: 4735,
    comune: "OSOPPO",
    sigla: "UD",
    codice_elettorale: 1060850660,
    codice_istat: 30066,
    codice_belfiore: "G163",
  },
  {
    id: 4736,
    comune: "OSPEDALETTI",
    sigla: "IM",
    codice_elettorale: 1070370360,
    codice_istat: 8039,
    codice_belfiore: "G164",
  },
  {
    id: 4737,
    comune: "OSPEDALETTO",
    sigla: "TN",
    codice_elettorale: 1040831201,
    codice_istat: 22130,
    codice_belfiore: "G168",
  },
  {
    id: 4738,
    comune: "OSPEDALETTO D'ALPINOLO",
    sigla: "AV",
    codice_elettorale: 3150080660,
    codice_istat: 64067,
    codice_belfiore: "G165",
  },
  {
    id: 4739,
    comune: "OSPEDALETTO EUGANEO",
    sigla: "PD",
    codice_elettorale: 1050540590,
    codice_istat: 28059,
    codice_belfiore: "G167",
  },
  {
    id: 4740,
    comune: "OSPEDALETTO LODIGIANO",
    sigla: "LO",
    codice_elettorale: 1030990430,
    codice_istat: 98043,
    codice_belfiore: "G166",
  },
  {
    id: 4741,
    comune: "OSPITALE DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100350,
    codice_istat: 25035,
    codice_belfiore: "G169",
  },
  {
    id: 4742,
    comune: "OSPITALETTO",
    sigla: "BS",
    codice_elettorale: 1030151180,
    codice_istat: 17127,
    codice_belfiore: "G170",
  },
  {
    id: 4743,
    comune: "OSSAGO LODIGIANO",
    sigla: "LO",
    codice_elettorale: 1030990440,
    codice_istat: 98044,
    codice_belfiore: "G171",
  },
  {
    id: 4744,
    comune: "OSSANA",
    sigla: "TN",
    codice_elettorale: 1040831210,
    codice_istat: 22131,
    codice_belfiore: "G173",
  },
  {
    id: 4745,
    comune: "OSSI",
    sigla: "SS",
    codice_elettorale: 4200730510,
    codice_istat: 90051,
    codice_belfiore: "G178",
  },
  {
    id: 4746,
    comune: "OSSIMO",
    sigla: "BS",
    codice_elettorale: 1030151190,
    codice_istat: 17128,
    codice_belfiore: "G179",
  },
  {
    id: 4747,
    comune: "OSSONA",
    sigla: "MI",
    codice_elettorale: 1030491630,
    codice_istat: 15164,
    codice_belfiore: "G181",
  },
  {
    id: 4748,
    comune: "OSTANA",
    sigla: "CN",
    codice_elettorale: 1010271560,
    codice_istat: 4156,
    codice_belfiore: "G183",
  },
  {
    id: 4749,
    comune: "OSTELLATO",
    sigla: "FE",
    codice_elettorale: 1080290150,
    codice_istat: 38017,
    codice_belfiore: "G184",
  },
  {
    id: 4750,
    comune: "OSTIANO",
    sigla: "CR",
    codice_elettorale: 1030260630,
    codice_istat: 19064,
    codice_belfiore: "G185",
  },
  {
    id: 4751,
    comune: "OSTIGLIA",
    sigla: "MN",
    codice_elettorale: 1030450380,
    codice_istat: 20038,
    codice_belfiore: "G186",
  },
  {
    id: 4752,
    comune: "OSTRA",
    sigla: "AN",
    codice_elettorale: 2110030350,
    codice_istat: 42035,
    codice_belfiore: "F401",
  },
  {
    id: 4753,
    comune: "OSTRA VETERE",
    sigla: "AN",
    codice_elettorale: 2110030360,
    codice_istat: 42036,
    codice_belfiore: "F581",
  },
  {
    id: 4754,
    comune: "OSTUNI",
    sigla: "BR",
    codice_elettorale: 3160160120,
    codice_istat: 74012,
    codice_belfiore: "G187",
  },
  {
    id: 4755,
    comune: "OTRANTO",
    sigla: "LE",
    codice_elettorale: 3160410560,
    codice_istat: 75057,
    codice_belfiore: "G188",
  },
  {
    id: 4756,
    comune: "OTRICOLI",
    sigla: "TR",
    codice_elettorale: 2100800240,
    codice_istat: 55024,
    codice_belfiore: "G189",
  },
  {
    id: 4757,
    comune: "OTTANA",
    sigla: "NU",
    codice_elettorale: 4200530680,
    codice_istat: 91070,
    codice_belfiore: "G191",
  },
  {
    id: 4758,
    comune: "OTTATI",
    sigla: "SA",
    codice_elettorale: 3150720860,
    codice_istat: 65086,
    codice_belfiore: "G192",
  },
  {
    id: 4759,
    comune: "OTTAVIANO",
    sigla: "NA",
    codice_elettorale: 3150510510,
    codice_istat: 63051,
    codice_belfiore: "G190",
  },
  {
    id: 4760,
    comune: "OTTIGLIO",
    sigla: "AL",
    codice_elettorale: 1010021170,
    codice_istat: 6120,
    codice_belfiore: "G193",
  },
  {
    id: 4761,
    comune: "OTTOBIANO",
    sigla: "PV",
    codice_elettorale: 1030571030,
    codice_istat: 18106,
    codice_belfiore: "G194",
  },
  {
    id: 4762,
    comune: "OTTONE",
    sigla: "PC",
    codice_elettorale: 1080610300,
    codice_istat: 33030,
    codice_belfiore: "G195",
  },
  {
    id: 4763,
    comune: "OULX",
    sigla: "TO",
    codice_elettorale: 1010811711,
    codice_istat: 1175,
    codice_belfiore: "G196",
  },
  {
    id: 4764,
    comune: "OVADA",
    sigla: "AL",
    codice_elettorale: 1010021180,
    codice_istat: 6121,
    codice_belfiore: "G197",
  },
  {
    id: 4765,
    comune: "OVARO",
    sigla: "UD",
    codice_elettorale: 1060850670,
    codice_istat: 30067,
    codice_belfiore: "G198",
  },
  {
    id: 4766,
    comune: "OVIGLIO",
    sigla: "AL",
    codice_elettorale: 1010021190,
    codice_istat: 6122,
    codice_belfiore: "G199",
  },
  {
    id: 4767,
    comune: "OVINDOLI",
    sigla: "AQ",
    codice_elettorale: 3130380650,
    codice_istat: 66065,
    codice_belfiore: "G200",
  },
  {
    id: 4768,
    comune: "OVODDA",
    sigla: "NU",
    codice_elettorale: 4200530690,
    codice_istat: 91071,
    codice_belfiore: "G201",
  },
  {
    id: 4769,
    comune: "OYACE",
    sigla: "AO",
    codice_elettorale: 1020040460,
    codice_istat: 7047,
    codice_belfiore: "G012",
  },
  {
    id: 4770,
    comune: "OZEGNA",
    sigla: "TO",
    codice_elettorale: 1010811720,
    codice_istat: 1176,
    codice_belfiore: "G202",
  },
  {
    id: 4771,
    comune: "OZIERI",
    sigla: "SS",
    codice_elettorale: 4200730520,
    codice_istat: 90052,
    codice_belfiore: "G203",
  },
  {
    id: 4772,
    comune: "OZZANO DELL'EMILIA",
    sigla: "BO",
    codice_elettorale: 1080130460,
    codice_istat: 37046,
    codice_belfiore: "G205",
  },
  {
    id: 4773,
    comune: "OZZANO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021200,
    codice_istat: 6123,
    codice_belfiore: "G204",
  },
  {
    id: 4774,
    comune: "OZZERO",
    sigla: "MI",
    codice_elettorale: 1030491640,
    codice_istat: 15165,
    codice_belfiore: "G206",
  },
  {
    id: 4775,
    comune: "PABILLONIS",
    sigla: "SU",
    codice_elettorale: 4201110520,
    codice_istat: 111052,
    codice_belfiore: "G207",
  },
  {
    id: 4776,
    comune: "PACE DEL MELA",
    sigla: "ME",
    codice_elettorale: 4190480630,
    codice_istat: 83064,
    codice_belfiore: "G209",
  },
  {
    id: 4777,
    comune: "PACECO",
    sigla: "TP",
    codice_elettorale: 4190820140,
    codice_istat: 81013,
    codice_belfiore: "G208",
  },
  {
    id: 4778,
    comune: "PACENTRO",
    sigla: "AQ",
    codice_elettorale: 3130380660,
    codice_istat: 66066,
    codice_belfiore: "G210",
  },
  {
    id: 4779,
    comune: "PACHINO",
    sigla: "SR",
    codice_elettorale: 4190760140,
    codice_istat: 89014,
    codice_belfiore: "G211",
  },
  {
    id: 4780,
    comune: "PACIANO",
    sigla: "PG",
    codice_elettorale: 2100580360,
    codice_istat: 54036,
    codice_belfiore: "G212",
  },
  {
    id: 4781,
    comune: "PADENGHE SUL GARDA",
    sigla: "BS",
    codice_elettorale: 1030151200,
    codice_istat: 17129,
    codice_belfiore: "G213",
  },
  {
    id: 4782,
    comune: "PADERNA",
    sigla: "AL",
    codice_elettorale: 1010021210,
    codice_istat: 6124,
    codice_belfiore: "G215",
  },
  {
    id: 4783,
    comune: "PADERNO D'ADDA",
    sigla: "LC",
    codice_elettorale: 1030980620,
    codice_istat: 97062,
    codice_belfiore: "G218",
  },
  {
    id: 4784,
    comune: "PADERNO DUGNANO",
    sigla: "MI",
    codice_elettorale: 1030491650,
    codice_istat: 15166,
    codice_belfiore: "G220",
  },
  {
    id: 4785,
    comune: "PADERNO FRANCIACORTA",
    sigla: "BS",
    codice_elettorale: 1030151210,
    codice_istat: 17130,
    codice_belfiore: "G217",
  },
  {
    id: 4786,
    comune: "PADERNO PONCHIELLI",
    sigla: "CR",
    codice_elettorale: 1030260640,
    codice_istat: 19065,
    codice_belfiore: "G222",
  },
  {
    id: 4787,
    comune: "PADOVA",
    sigla: "PD",
    codice_elettorale: 1050540600,
    codice_istat: 28060,
    codice_belfiore: "G224",
  },
  {
    id: 4788,
    comune: "PADRIA",
    sigla: "SS",
    codice_elettorale: 4200730530,
    codice_istat: 90053,
    codice_belfiore: "G225",
  },
  {
    id: 4789,
    comune: "PADRU",
    sigla: "SS",
    codice_elettorale: 4200730532,
    codice_istat: 90090,
    codice_belfiore: "M301",
  },
  {
    id: 4790,
    comune: "PADULA",
    sigla: "SA",
    codice_elettorale: 3150720870,
    codice_istat: 65087,
    codice_belfiore: "G226",
  },
  {
    id: 4791,
    comune: "PADULI",
    sigla: "BN",
    codice_elettorale: 3150110440,
    codice_istat: 62045,
    codice_belfiore: "G227",
  },
  {
    id: 4792,
    comune: "PAESANA",
    sigla: "CN",
    codice_elettorale: 1010271570,
    codice_istat: 4157,
    codice_belfiore: "G228",
  },
  {
    id: 4793,
    comune: "PAESE",
    sigla: "TV",
    codice_elettorale: 1050840540,
    codice_istat: 26055,
    codice_belfiore: "G229",
  },
  {
    id: 4794,
    comune: "PAGANI",
    sigla: "SA",
    codice_elettorale: 3150720880,
    codice_istat: 65088,
    codice_belfiore: "G230",
  },
  {
    id: 4795,
    comune: "PAGANICO SABINO",
    sigla: "RI",
    codice_elettorale: 2120690460,
    codice_istat: 57048,
    codice_belfiore: "G232",
  },
  {
    id: 4796,
    comune: "PAGAZZANO",
    sigla: "BG",
    codice_elettorale: 1030121460,
    codice_istat: 16154,
    codice_belfiore: "G233",
  },
  {
    id: 4797,
    comune: "PAGLIARA",
    sigla: "ME",
    codice_elettorale: 4190480640,
    codice_istat: 83065,
    codice_belfiore: "G234",
  },
  {
    id: 4798,
    comune: "PAGLIETA",
    sigla: "CH",
    codice_elettorale: 3130230590,
    codice_istat: 69059,
    codice_belfiore: "G237",
  },
  {
    id: 4799,
    comune: "PAGNACCO",
    sigla: "UD",
    codice_elettorale: 1060850680,
    codice_istat: 30068,
    codice_belfiore: "G238",
  },
  {
    id: 4800,
    comune: "PAGNO",
    sigla: "CN",
    codice_elettorale: 1010271580,
    codice_istat: 4158,
    codice_belfiore: "G240",
  },
  {
    id: 4801,
    comune: "PAGNONA",
    sigla: "LC",
    codice_elettorale: 1030980630,
    codice_istat: 97063,
    codice_belfiore: "G241",
  },
  {
    id: 4802,
    comune: "PAGO DEL VALLO DI LAURO",
    sigla: "AV",
    codice_elettorale: 3150080670,
    codice_istat: 64068,
    codice_belfiore: "G242",
  },
  {
    id: 4803,
    comune: "PAGO VEIANO",
    sigla: "BN",
    codice_elettorale: 3150110450,
    codice_istat: 62046,
    codice_belfiore: "G243",
  },
  {
    id: 4804,
    comune: "PAISCO LOVENO",
    sigla: "BS",
    codice_elettorale: 1030151220,
    codice_istat: 17131,
    codice_belfiore: "G247",
  },
  {
    id: 4805,
    comune: "PAITONE",
    sigla: "BS",
    codice_elettorale: 1030151230,
    codice_istat: 17132,
    codice_belfiore: "G248",
  },
  {
    id: 4806,
    comune: "PALADINA",
    sigla: "BG",
    codice_elettorale: 1030121470,
    codice_istat: 16155,
    codice_belfiore: "G249",
  },
  {
    id: 4807,
    comune: "PALAGANO",
    sigla: "MO",
    codice_elettorale: 1080500281,
    codice_istat: 36029,
    codice_belfiore: "G250",
  },
  {
    id: 4808,
    comune: "PALAGIANELLO",
    sigla: "TA",
    codice_elettorale: 3160780200,
    codice_istat: 73020,
    codice_belfiore: "G251",
  },
  {
    id: 4809,
    comune: "PALAGIANO",
    sigla: "TA",
    codice_elettorale: 3160780210,
    codice_istat: 73021,
    codice_belfiore: "G252",
  },
  {
    id: 4810,
    comune: "PALAGONIA",
    sigla: "CT",
    codice_elettorale: 4190210310,
    codice_istat: 87032,
    codice_belfiore: "G253",
  },
  {
    id: 4811,
    comune: "PALAIA",
    sigla: "PI",
    codice_elettorale: 2090620230,
    codice_istat: 50024,
    codice_belfiore: "G254",
  },
  {
    id: 4812,
    comune: "PALANZANO",
    sigla: "PR",
    codice_elettorale: 1080560260,
    codice_istat: 34026,
    codice_belfiore: "G255",
  },
  {
    id: 4813,
    comune: "PALATA",
    sigla: "CB",
    codice_elettorale: 3140190500,
    codice_istat: 70050,
    codice_belfiore: "G257",
  },
  {
    id: 4814,
    comune: "PALAU",
    sigla: "SS",
    codice_elettorale: 4200730531,
    codice_istat: 90054,
    codice_belfiore: "G258",
  },
  {
    id: 4815,
    comune: "PALAZZAGO",
    sigla: "BG",
    codice_elettorale: 1030121480,
    codice_istat: 16156,
    codice_belfiore: "G259",
  },
  {
    id: 4816,
    comune: "PALAZZO ADRIANO",
    sigla: "PA",
    codice_elettorale: 4190550500,
    codice_istat: 82052,
    codice_belfiore: "G263",
  },
  {
    id: 4817,
    comune: "PALAZZO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010811730,
    codice_istat: 1177,
    codice_belfiore: "G262",
  },
  {
    id: 4818,
    comune: "PALAZZO PIGNANO",
    sigla: "CR",
    codice_elettorale: 1030260650,
    codice_istat: 19066,
    codice_belfiore: "G260",
  },
  {
    id: 4819,
    comune: "PALAZZO SAN GERVASIO",
    sigla: "PZ",
    codice_elettorale: 3170640560,
    codice_istat: 76057,
    codice_belfiore: "G261",
  },
  {
    id: 4820,
    comune: "PALAZZOLO ACREIDE",
    sigla: "SR",
    codice_elettorale: 4190760150,
    codice_istat: 89015,
    codice_belfiore: "G267",
  },
  {
    id: 4821,
    comune: "PALAZZOLO DELLO STELLA",
    sigla: "UD",
    codice_elettorale: 1060850690,
    codice_istat: 30069,
    codice_belfiore: "G268",
  },
  {
    id: 4822,
    comune: "PALAZZOLO SULL'OGLIO",
    sigla: "BS",
    codice_elettorale: 1030151240,
    codice_istat: 17133,
    codice_belfiore: "G264",
  },
  {
    id: 4823,
    comune: "PALAZZOLO VERCELLESE",
    sigla: "VC",
    codice_elettorale: 1010880890,
    codice_istat: 2090,
    codice_belfiore: "G266",
  },
  {
    id: 4824,
    comune: "PALAZZUOLO SUL SENIO",
    sigla: "FI",
    codice_elettorale: 2090300310,
    codice_istat: 48031,
    codice_belfiore: "G270",
  },
  {
    id: 4825,
    comune: "PALENA",
    sigla: "CH",
    codice_elettorale: 3130230600,
    codice_istat: 69060,
    codice_belfiore: "G271",
  },
  {
    id: 4826,
    comune: "PALERMITI",
    sigla: "CZ",
    codice_elettorale: 3180220860,
    codice_istat: 79089,
    codice_belfiore: "G272",
  },
  {
    id: 4827,
    comune: "PALERMO",
    sigla: "PA",
    codice_elettorale: 4190550510,
    codice_istat: 82053,
    codice_belfiore: "G273",
  },
  {
    id: 4828,
    comune: "PALESTRINA",
    sigla: "RM",
    codice_elettorale: 2120700730,
    codice_istat: 58074,
    codice_belfiore: "G274",
  },
  {
    id: 4829,
    comune: "PALESTRO",
    sigla: "PV",
    codice_elettorale: 1030571040,
    codice_istat: 18107,
    codice_belfiore: "G275",
  },
  {
    id: 4830,
    comune: "PALIANO",
    sigla: "FR",
    codice_elettorale: 2120330460,
    codice_istat: 60046,
    codice_belfiore: "G276",
  },
  {
    id: 4831,
    comune: "PALIZZI",
    sigla: "RC",
    codice_elettorale: 3180670560,
    codice_istat: 80056,
    codice_belfiore: "G277",
  },
  {
    id: 4832,
    comune: "PALLAGORIO",
    sigla: "KR",
    codice_elettorale: 3180970160,
    codice_istat: 101016,
    codice_belfiore: "G278",
  },
  {
    id: 4833,
    comune: "PALLANZENO",
    sigla: "VB",
    codice_elettorale: 1011020520,
    codice_istat: 103052,
    codice_belfiore: "G280",
  },
  {
    id: 4834,
    comune: "PALLARE",
    sigla: "SV",
    codice_elettorale: 1070740470,
    codice_istat: 9047,
    codice_belfiore: "G281",
  },
  {
    id: 4835,
    comune: "PALMA CAMPANIA",
    sigla: "NA",
    codice_elettorale: 3150510520,
    codice_istat: 63052,
    codice_belfiore: "G283",
  },
  {
    id: 4836,
    comune: "PALMA DI MONTECHIARO",
    sigla: "AG",
    codice_elettorale: 4190010270,
    codice_istat: 84027,
    codice_belfiore: "G282",
  },
  {
    id: 4837,
    comune: "PALMANOVA",
    sigla: "UD",
    codice_elettorale: 1060850700,
    codice_istat: 30070,
    codice_belfiore: "G284",
  },
  {
    id: 4838,
    comune: "PALMARIGGI",
    sigla: "LE",
    codice_elettorale: 3160410570,
    codice_istat: 75058,
    codice_belfiore: "G285",
  },
  {
    id: 4839,
    comune: "PALMAS ARBOREA",
    sigla: "OR",
    codice_elettorale: 4200950390,
    codice_istat: 95039,
    codice_belfiore: "G286",
  },
  {
    id: 4840,
    comune: "PALMI",
    sigla: "RC",
    codice_elettorale: 3180670570,
    codice_istat: 80057,
    codice_belfiore: "G288",
  },
  {
    id: 4841,
    comune: "PALMIANO",
    sigla: "AP",
    codice_elettorale: 2110060560,
    codice_istat: 44056,
    codice_belfiore: "G289",
  },
  {
    id: 4842,
    comune: "PALMOLI",
    sigla: "CH",
    codice_elettorale: 3130230610,
    codice_istat: 69061,
    codice_belfiore: "G290",
  },
  {
    id: 4843,
    comune: "PALO DEL COLLE",
    sigla: "BA",
    codice_elettorale: 3160090330,
    codice_istat: 72033,
    codice_belfiore: "G291",
  },
  {
    id: 4844,
    comune: "PALOMBARA SABINA",
    sigla: "RM",
    codice_elettorale: 2120700740,
    codice_istat: 58075,
    codice_belfiore: "G293",
  },
  {
    id: 4845,
    comune: "PALOMBARO",
    sigla: "CH",
    codice_elettorale: 3130230620,
    codice_istat: 69062,
    codice_belfiore: "G294",
  },
  {
    id: 4846,
    comune: "PALOMONTE",
    sigla: "SA",
    codice_elettorale: 3150720890,
    codice_istat: 65089,
    codice_belfiore: "G292",
  },
  {
    id: 4847,
    comune: "PALOSCO",
    sigla: "BG",
    codice_elettorale: 1030121490,
    codice_istat: 16157,
    codice_belfiore: "G295",
  },
  {
    id: 4848,
    comune: "PALU'",
    sigla: "VR",
    codice_elettorale: 1050890551,
    codice_istat: 23056,
    codice_belfiore: "G297",
  },
  {
    id: 4849,
    comune: "PALU' DEL FERSINA",
    sigla: "TN",
    codice_elettorale: 1040831230,
    codice_istat: 22133,
    codice_belfiore: "G296",
  },
  {
    id: 4850,
    comune: "PALUDI",
    sigla: "CS",
    codice_elettorale: 3180250900,
    codice_istat: 78089,
    codice_belfiore: "G298",
  },
  {
    id: 4851,
    comune: "PALUZZA",
    sigla: "UD",
    codice_elettorale: 1060850710,
    codice_istat: 30071,
    codice_belfiore: "G300",
  },
  {
    id: 4852,
    comune: "PAMPARATO",
    sigla: "CN",
    codice_elettorale: 1010271590,
    codice_istat: 4159,
    codice_belfiore: "G302",
  },
  {
    id: 4853,
    comune: "PANCALIERI",
    sigla: "TO",
    codice_elettorale: 1010811740,
    codice_istat: 1178,
    codice_belfiore: "G303",
  },
  {
    id: 4854,
    comune: "PANCARANA",
    sigla: "PV",
    codice_elettorale: 1030571050,
    codice_istat: 18108,
    codice_belfiore: "G304",
  },
  {
    id: 4855,
    comune: "PANCHIA'",
    sigla: "TN",
    codice_elettorale: 1040831240,
    codice_istat: 22134,
    codice_belfiore: "G305",
  },
  {
    id: 4856,
    comune: "PANDINO",
    sigla: "CR",
    codice_elettorale: 1030260660,
    codice_istat: 19067,
    codice_belfiore: "G306",
  },
  {
    id: 4857,
    comune: "PANETTIERI",
    sigla: "CS",
    codice_elettorale: 3180250910,
    codice_istat: 78090,
    codice_belfiore: "G307",
  },
  {
    id: 4858,
    comune: "PANICALE",
    sigla: "PG",
    codice_elettorale: 2100580370,
    codice_istat: 54037,
    codice_belfiore: "G308",
  },
  {
    id: 4859,
    comune: "PANNARANO",
    sigla: "BN",
    codice_elettorale: 3150110460,
    codice_istat: 62047,
    codice_belfiore: "G311",
  },
  {
    id: 4860,
    comune: "PANNI",
    sigla: "FG",
    codice_elettorale: 3160310350,
    codice_istat: 71037,
    codice_belfiore: "G312",
  },
  {
    id: 4861,
    comune: "PANTELLERIA",
    sigla: "TP",
    codice_elettorale: 4190820150,
    codice_istat: 81014,
    codice_belfiore: "G315",
  },
  {
    id: 4862,
    comune: "PANTIGLIATE",
    sigla: "MI",
    codice_elettorale: 1030491660,
    codice_istat: 15167,
    codice_belfiore: "G316",
  },
  {
    id: 4863,
    comune: "PAOLA",
    sigla: "CS",
    codice_elettorale: 3180250920,
    codice_istat: 78091,
    codice_belfiore: "G317",
  },
  {
    id: 4864,
    comune: "PAOLISI",
    sigla: "BN",
    codice_elettorale: 3150110470,
    codice_istat: 62048,
    codice_belfiore: "G318",
  },
  {
    id: 4865,
    comune: "PAPASIDERO",
    sigla: "CS",
    codice_elettorale: 3180250930,
    codice_istat: 78092,
    codice_belfiore: "G320",
  },
  {
    id: 4866,
    comune: "PAPOZZE",
    sigla: "RO",
    codice_elettorale: 1050710340,
    codice_istat: 29034,
    codice_belfiore: "G323",
  },
  {
    id: 4867,
    comune: "PARABIAGO",
    sigla: "MI",
    codice_elettorale: 1030491670,
    codice_istat: 15168,
    codice_belfiore: "G324",
  },
  {
    id: 4868,
    comune: "PARABITA",
    sigla: "LE",
    codice_elettorale: 3160410580,
    codice_istat: 75059,
    codice_belfiore: "G325",
  },
  {
    id: 4869,
    comune: "PARATICO",
    sigla: "BS",
    codice_elettorale: 1030151250,
    codice_istat: 17134,
    codice_belfiore: "G327",
  },
  {
    id: 4870,
    comune: "PARCINES/PARTSCHINS",
    sigla: "BZ",
    codice_elettorale: 1040140590,
    codice_istat: 21062,
    codice_belfiore: "G328",
  },
  {
    id: 4871,
    comune: "PARELLA",
    sigla: "TO",
    codice_elettorale: 1010811750,
    codice_istat: 1179,
    codice_belfiore: "G330",
  },
  {
    id: 4872,
    comune: "PARENTI",
    sigla: "CS",
    codice_elettorale: 3180250940,
    codice_istat: 78093,
    codice_belfiore: "G331",
  },
  {
    id: 4873,
    comune: "PARETE",
    sigla: "CE",
    codice_elettorale: 3150200540,
    codice_istat: 61054,
    codice_belfiore: "G333",
  },
  {
    id: 4874,
    comune: "PARETO",
    sigla: "AL",
    codice_elettorale: 1010021220,
    codice_istat: 6125,
    codice_belfiore: "G334",
  },
  {
    id: 4875,
    comune: "PARGHELIA",
    sigla: "VV",
    codice_elettorale: 3181030260,
    codice_istat: 102026,
    codice_belfiore: "G335",
  },
  {
    id: 4876,
    comune: "PARLASCO",
    sigla: "LC",
    codice_elettorale: 1030980640,
    codice_istat: 97064,
    codice_belfiore: "G336",
  },
  {
    id: 4877,
    comune: "PARMA",
    sigla: "PR",
    codice_elettorale: 1080560270,
    codice_istat: 34027,
    codice_belfiore: "G337",
  },
  {
    id: 4878,
    comune: "PARODI LIGURE",
    sigla: "AL",
    codice_elettorale: 1010021230,
    codice_istat: 6126,
    codice_belfiore: "G338",
  },
  {
    id: 4879,
    comune: "PAROLDO",
    sigla: "CN",
    codice_elettorale: 1010271600,
    codice_istat: 4160,
    codice_belfiore: "G339",
  },
  {
    id: 4880,
    comune: "PAROLISE",
    sigla: "AV",
    codice_elettorale: 3150080680,
    codice_istat: 64069,
    codice_belfiore: "G340",
  },
  {
    id: 4881,
    comune: "PARONA",
    sigla: "PV",
    codice_elettorale: 1030571060,
    codice_istat: 18109,
    codice_belfiore: "G342",
  },
  {
    id: 4882,
    comune: "PARRANO",
    sigla: "TR",
    codice_elettorale: 2100800250,
    codice_istat: 55025,
    codice_belfiore: "G344",
  },
  {
    id: 4883,
    comune: "PARRE",
    sigla: "BG",
    codice_elettorale: 1030121500,
    codice_istat: 16158,
    codice_belfiore: "G346",
  },
  {
    id: 4884,
    comune: "PARTANNA",
    sigla: "TP",
    codice_elettorale: 4190820160,
    codice_istat: 81015,
    codice_belfiore: "G347",
  },
  {
    id: 4885,
    comune: "PARTINICO",
    sigla: "PA",
    codice_elettorale: 4190550520,
    codice_istat: 82054,
    codice_belfiore: "G348",
  },
  {
    id: 4886,
    comune: "PARUZZARO",
    sigla: "NO",
    codice_elettorale: 1010521080,
    codice_istat: 3114,
    codice_belfiore: "G349",
  },
  {
    id: 4887,
    comune: "PARZANICA",
    sigla: "BG",
    codice_elettorale: 1030121510,
    codice_istat: 16159,
    codice_belfiore: "G350",
  },
  {
    id: 4888,
    comune: "PASIAN DI PRATO",
    sigla: "UD",
    codice_elettorale: 1060850720,
    codice_istat: 30072,
    codice_belfiore: "G352",
  },
  {
    id: 4889,
    comune: "PASIANO DI PORDENONE",
    sigla: "PN",
    codice_elettorale: 1060930290,
    codice_istat: 93029,
    codice_belfiore: "G353",
  },
  {
    id: 4890,
    comune: "PASPARDO",
    sigla: "BS",
    codice_elettorale: 1030151260,
    codice_istat: 17135,
    codice_belfiore: "G354",
  },
  {
    id: 4891,
    comune: "PASSERANO MARMORITO",
    sigla: "AT",
    codice_elettorale: 1010070820,
    codice_istat: 5082,
    codice_belfiore: "G358",
  },
  {
    id: 4892,
    comune: "PASSIGNANO SUL TRASIMENO",
    sigla: "PG",
    codice_elettorale: 2100580380,
    codice_istat: 54038,
    codice_belfiore: "G359",
  },
  {
    id: 4893,
    comune: "PASSIRANO",
    sigla: "BS",
    codice_elettorale: 1030151270,
    codice_istat: 17136,
    codice_belfiore: "G361",
  },
  {
    id: 4894,
    comune: "PASTENA",
    sigla: "FR",
    codice_elettorale: 2120330470,
    codice_istat: 60047,
    codice_belfiore: "G362",
  },
  {
    id: 4895,
    comune: "PASTORANO",
    sigla: "CE",
    codice_elettorale: 3150200550,
    codice_istat: 61055,
    codice_belfiore: "G364",
  },
  {
    id: 4896,
    comune: "PASTRENGO",
    sigla: "VR",
    codice_elettorale: 1050890560,
    codice_istat: 23057,
    codice_belfiore: "G365",
  },
  {
    id: 4897,
    comune: "PASTURANA",
    sigla: "AL",
    codice_elettorale: 1010021240,
    codice_istat: 6127,
    codice_belfiore: "G367",
  },
  {
    id: 4898,
    comune: "PASTURO",
    sigla: "LC",
    codice_elettorale: 1030980650,
    codice_istat: 97065,
    codice_belfiore: "G368",
  },
  {
    id: 4899,
    comune: "PATERNO",
    sigla: "PZ",
    codice_elettorale: 3170640561,
    codice_istat: 76100,
    codice_belfiore: "M269",
  },
  {
    id: 4900,
    comune: "PATERNO CALABRO",
    sigla: "CS",
    codice_elettorale: 3180250950,
    codice_istat: 78094,
    codice_belfiore: "G372",
  },
  {
    id: 4901,
    comune: "PATERNO'",
    sigla: "CT",
    codice_elettorale: 4190210320,
    codice_istat: 87033,
    codice_belfiore: "G371",
  },
  {
    id: 4902,
    comune: "PATERNOPOLI",
    sigla: "AV",
    codice_elettorale: 3150080690,
    codice_istat: 64070,
    codice_belfiore: "G370",
  },
  {
    id: 4903,
    comune: "PATRICA",
    sigla: "FR",
    codice_elettorale: 2120330480,
    codice_istat: 60048,
    codice_belfiore: "G374",
  },
  {
    id: 4904,
    comune: "PATTADA",
    sigla: "SS",
    codice_elettorale: 4200730540,
    codice_istat: 90055,
    codice_belfiore: "G376",
  },
  {
    id: 4905,
    comune: "PATTI",
    sigla: "ME",
    codice_elettorale: 4190480650,
    codice_istat: 83066,
    codice_belfiore: "G377",
  },
  {
    id: 4906,
    comune: "PATU'",
    sigla: "LE",
    codice_elettorale: 3160410590,
    codice_istat: 75060,
    codice_belfiore: "G378",
  },
  {
    id: 4907,
    comune: "PAU",
    sigla: "OR",
    codice_elettorale: 4200950400,
    codice_istat: 95040,
    codice_belfiore: "G379",
  },
  {
    id: 4908,
    comune: "PAULARO",
    sigla: "UD",
    codice_elettorale: 1060850730,
    codice_istat: 30073,
    codice_belfiore: "G381",
  },
  {
    id: 4909,
    comune: "PAULI ARBAREI",
    sigla: "SU",
    codice_elettorale: 4201110530,
    codice_istat: 111053,
    codice_belfiore: "G382",
  },
  {
    id: 4910,
    comune: "PAULILATINO",
    sigla: "OR",
    codice_elettorale: 4200950410,
    codice_istat: 95041,
    codice_belfiore: "G384",
  },
  {
    id: 4911,
    comune: "PAULLO",
    sigla: "MI",
    codice_elettorale: 1030491680,
    codice_istat: 15169,
    codice_belfiore: "G385",
  },
  {
    id: 4912,
    comune: "PAUPISI",
    sigla: "BN",
    codice_elettorale: 3150110480,
    codice_istat: 62049,
    codice_belfiore: "G386",
  },
  {
    id: 4913,
    comune: "PAVAROLO",
    sigla: "TO",
    codice_elettorale: 1010811760,
    codice_istat: 1180,
    codice_belfiore: "G387",
  },
  {
    id: 4914,
    comune: "PAVIA",
    sigla: "PV",
    codice_elettorale: 1030571070,
    codice_istat: 18110,
    codice_belfiore: "G388",
  },
  {
    id: 4915,
    comune: "PAVIA DI UDINE",
    sigla: "UD",
    codice_elettorale: 1060850740,
    codice_istat: 30074,
    codice_belfiore: "G389",
  },
  {
    id: 4916,
    comune: "PAVONE CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010811770,
    codice_istat: 1181,
    codice_belfiore: "G392",
  },
  {
    id: 4917,
    comune: "PAVONE DEL MELLA",
    sigla: "BS",
    codice_elettorale: 1030151280,
    codice_istat: 17137,
    codice_belfiore: "G391",
  },
  {
    id: 4918,
    comune: "PAVULLO NEL FRIGNANO",
    sigla: "MO",
    codice_elettorale: 1080500290,
    codice_istat: 36030,
    codice_belfiore: "G393",
  },
  {
    id: 4919,
    comune: "PAZZANO",
    sigla: "RC",
    codice_elettorale: 3180670580,
    codice_istat: 80058,
    codice_belfiore: "G394",
  },
  {
    id: 4920,
    comune: "PECCIOLI",
    sigla: "PI",
    codice_elettorale: 2090620240,
    codice_istat: 50025,
    codice_belfiore: "G395",
  },
  {
    id: 4921,
    comune: "PECETTO DI VALENZA",
    sigla: "AL",
    codice_elettorale: 1010021250,
    codice_istat: 6128,
    codice_belfiore: "G397",
  },
  {
    id: 4922,
    comune: "PECETTO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010811790,
    codice_istat: 1183,
    codice_belfiore: "G398",
  },
  {
    id: 4923,
    comune: "PEDARA",
    sigla: "CT",
    codice_elettorale: 4190210330,
    codice_istat: 87034,
    codice_belfiore: "G402",
  },
  {
    id: 4924,
    comune: "PEDASO",
    sigla: "FM",
    codice_elettorale: 2111050300,
    codice_istat: 109030,
    codice_belfiore: "G403",
  },
  {
    id: 4925,
    comune: "PEDAVENA",
    sigla: "BL",
    codice_elettorale: 1050100360,
    codice_istat: 25036,
    codice_belfiore: "G404",
  },
  {
    id: 4926,
    comune: "PEDEMONTE",
    sigla: "VI",
    codice_elettorale: 1050900760,
    codice_istat: 24076,
    codice_belfiore: "G406",
  },
  {
    id: 4927,
    comune: "PEDEROBBA",
    sigla: "TV",
    codice_elettorale: 1050840550,
    codice_istat: 26056,
    codice_belfiore: "G408",
  },
  {
    id: 4928,
    comune: "PEDESINA",
    sigla: "SO",
    codice_elettorale: 1030770470,
    codice_istat: 14047,
    codice_belfiore: "G410",
  },
  {
    id: 4929,
    comune: "PEDIVIGLIANO",
    sigla: "CS",
    codice_elettorale: 3180250970,
    codice_istat: 78096,
    codice_belfiore: "G411",
  },
  {
    id: 4930,
    comune: "PEDRENGO",
    sigla: "BG",
    codice_elettorale: 1030121520,
    codice_istat: 16160,
    codice_belfiore: "G412",
  },
  {
    id: 4931,
    comune: "PEGLIO",
    sigla: "CO",
    codice_elettorale: 1030241700,
    codice_istat: 13178,
    codice_belfiore: "G415",
  },
  {
    id: 4932,
    comune: "PEGLIO",
    sigla: "PU",
    codice_elettorale: 2110590410,
    codice_istat: 41041,
    codice_belfiore: "G416",
  },
  {
    id: 4933,
    comune: "PEGOGNAGA",
    sigla: "MN",
    codice_elettorale: 1030450390,
    codice_istat: 20039,
    codice_belfiore: "G417",
  },
  {
    id: 4934,
    comune: "PEIA",
    sigla: "BG",
    codice_elettorale: 1030121530,
    codice_istat: 16161,
    codice_belfiore: "G418",
  },
  {
    id: 4935,
    comune: "PEIO",
    sigla: "TN",
    codice_elettorale: 1040831260,
    codice_istat: 22136,
    codice_belfiore: "G419",
  },
  {
    id: 4936,
    comune: "PELAGO",
    sigla: "FI",
    codice_elettorale: 2090300320,
    codice_istat: 48032,
    codice_belfiore: "G420",
  },
  {
    id: 4937,
    comune: "PELLA",
    sigla: "NO",
    codice_elettorale: 1010521090,
    codice_istat: 3115,
    codice_belfiore: "G421",
  },
  {
    id: 4938,
    comune: "PELLEGRINO PARMENSE",
    sigla: "PR",
    codice_elettorale: 1080560280,
    codice_istat: 34028,
    codice_belfiore: "G424",
  },
  {
    id: 4939,
    comune: "PELLEZZANO",
    sigla: "SA",
    codice_elettorale: 3150720900,
    codice_istat: 65090,
    codice_belfiore: "G426",
  },
  {
    id: 4940,
    comune: "PELLIZZANO",
    sigla: "TN",
    codice_elettorale: 1040831261,
    codice_istat: 22137,
    codice_belfiore: "G428",
  },
  {
    id: 4941,
    comune: "PELUGO",
    sigla: "TN",
    codice_elettorale: 1040831270,
    codice_istat: 22138,
    codice_belfiore: "G429",
  },
  {
    id: 4942,
    comune: "PENANGO",
    sigla: "AT",
    codice_elettorale: 1010070830,
    codice_istat: 5083,
    codice_belfiore: "G430",
  },
  {
    id: 4943,
    comune: "PENNA IN TEVERINA",
    sigla: "TR",
    codice_elettorale: 2100800260,
    codice_istat: 55026,
    codice_belfiore: "G432",
  },
  {
    id: 4944,
    comune: "PENNA SAN GIOVANNI",
    sigla: "MC",
    codice_elettorale: 2110440350,
    codice_istat: 43035,
    codice_belfiore: "G436",
  },
  {
    id: 4945,
    comune: "PENNA SANT'ANDREA",
    sigla: "TE",
    codice_elettorale: 3130790320,
    codice_istat: 67033,
    codice_belfiore: "G437",
  },
  {
    id: 4946,
    comune: "PENNABILLI",
    sigla: "RN",
    codice_elettorale: 1081010116,
    codice_istat: 99024,
    codice_belfiore: "G433",
  },
  {
    id: 4947,
    comune: "PENNADOMO",
    sigla: "CH",
    codice_elettorale: 3130230630,
    codice_istat: 69063,
    codice_belfiore: "G434",
  },
  {
    id: 4948,
    comune: "PENNAPIEDIMONTE",
    sigla: "CH",
    codice_elettorale: 3130230640,
    codice_istat: 69064,
    codice_belfiore: "G435",
  },
  {
    id: 4949,
    comune: "PENNE",
    sigla: "PE",
    codice_elettorale: 3130600270,
    codice_istat: 68027,
    codice_belfiore: "G438",
  },
  {
    id: 4950,
    comune: "PENTONE",
    sigla: "CZ",
    codice_elettorale: 3180220890,
    codice_istat: 79092,
    codice_belfiore: "G439",
  },
  {
    id: 4951,
    comune: "PERANO",
    sigla: "CH",
    codice_elettorale: 3130230650,
    codice_istat: 69065,
    codice_belfiore: "G441",
  },
  {
    id: 4952,
    comune: "PERAROLO DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100370,
    codice_istat: 25037,
    codice_belfiore: "G442",
  },
  {
    id: 4953,
    comune: "PERCA/PERCHA",
    sigla: "BZ",
    codice_elettorale: 1040140600,
    codice_istat: 21063,
    codice_belfiore: "G443",
  },
  {
    id: 4954,
    comune: "PERCILE",
    sigla: "RM",
    codice_elettorale: 2120700750,
    codice_istat: 58076,
    codice_belfiore: "G444",
  },
  {
    id: 4955,
    comune: "PERDASDEFOGU",
    sigla: "NU",
    codice_elettorale: 4200530700,
    codice_istat: 91072,
    codice_belfiore: "G445",
  },
  {
    id: 4956,
    comune: "PERDAXIUS",
    sigla: "SU",
    codice_elettorale: 4201110540,
    codice_istat: 111054,
    codice_belfiore: "G446",
  },
  {
    id: 4957,
    comune: "PERDIFUMO",
    sigla: "SA",
    codice_elettorale: 3150720910,
    codice_istat: 65091,
    codice_belfiore: "G447",
  },
  {
    id: 4958,
    comune: "PERETO",
    sigla: "AQ",
    codice_elettorale: 3130380670,
    codice_istat: 66067,
    codice_belfiore: "G449",
  },
  {
    id: 4959,
    comune: "PERFUGAS",
    sigla: "SS",
    codice_elettorale: 4200730550,
    codice_istat: 90056,
    codice_belfiore: "G450",
  },
  {
    id: 4960,
    comune: "PERGINE VALSUGANA",
    sigla: "TN",
    codice_elettorale: 1040831280,
    codice_istat: 22139,
    codice_belfiore: "G452",
  },
  {
    id: 4961,
    comune: "PERGOLA",
    sigla: "PU",
    codice_elettorale: 2110590430,
    codice_istat: 41043,
    codice_belfiore: "G453",
  },
  {
    id: 4962,
    comune: "PERINALDO",
    sigla: "IM",
    codice_elettorale: 1070370370,
    codice_istat: 8040,
    codice_belfiore: "G454",
  },
  {
    id: 4963,
    comune: "PERITO",
    sigla: "SA",
    codice_elettorale: 3150720920,
    codice_istat: 65092,
    codice_belfiore: "G455",
  },
  {
    id: 4964,
    comune: "PERLEDO",
    sigla: "LC",
    codice_elettorale: 1030980670,
    codice_istat: 97067,
    codice_belfiore: "G456",
  },
  {
    id: 4965,
    comune: "PERLETTO",
    sigla: "CN",
    codice_elettorale: 1010271610,
    codice_istat: 4161,
    codice_belfiore: "G457",
  },
  {
    id: 4966,
    comune: "PERLO",
    sigla: "CN",
    codice_elettorale: 1010271620,
    codice_istat: 4162,
    codice_belfiore: "G458",
  },
  {
    id: 4967,
    comune: "PERLOZ",
    sigla: "AO",
    codice_elettorale: 1020040470,
    codice_istat: 7048,
    codice_belfiore: "G459",
  },
  {
    id: 4968,
    comune: "PERNUMIA",
    sigla: "PD",
    codice_elettorale: 1050540610,
    codice_istat: 28061,
    codice_belfiore: "G461",
  },
  {
    id: 4969,
    comune: "PERO",
    sigla: "MI",
    codice_elettorale: 1030491690,
    codice_istat: 15170,
    codice_belfiore: "C013",
  },
  {
    id: 4970,
    comune: "PEROSA ARGENTINA",
    sigla: "TO",
    codice_elettorale: 1010811800,
    codice_istat: 1184,
    codice_belfiore: "G463",
  },
  {
    id: 4971,
    comune: "PEROSA CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010811810,
    codice_istat: 1185,
    codice_belfiore: "G462",
  },
  {
    id: 4972,
    comune: "PERRERO",
    sigla: "TO",
    codice_elettorale: 1010811820,
    codice_istat: 1186,
    codice_belfiore: "G465",
  },
  {
    id: 4973,
    comune: "PERSICO DOSIMO",
    sigla: "CR",
    codice_elettorale: 1030260670,
    codice_istat: 19068,
    codice_belfiore: "G469",
  },
  {
    id: 4974,
    comune: "PERTENGO",
    sigla: "VC",
    codice_elettorale: 1010880900,
    codice_istat: 2091,
    codice_belfiore: "G471",
  },
  {
    id: 4975,
    comune: "PERTICA ALTA",
    sigla: "BS",
    codice_elettorale: 1030151300,
    codice_istat: 17139,
    codice_belfiore: "G474",
  },
  {
    id: 4976,
    comune: "PERTICA BASSA",
    sigla: "BS",
    codice_elettorale: 1030151310,
    codice_istat: 17140,
    codice_belfiore: "G475",
  },
  {
    id: 4977,
    comune: "PERTOSA",
    sigla: "SA",
    codice_elettorale: 3150720930,
    codice_istat: 65093,
    codice_belfiore: "G476",
  },
  {
    id: 4978,
    comune: "PERTUSIO",
    sigla: "TO",
    codice_elettorale: 1010811830,
    codice_istat: 1187,
    codice_belfiore: "G477",
  },
  {
    id: 4979,
    comune: "PERUGIA",
    sigla: "PG",
    codice_elettorale: 2100580390,
    codice_istat: 54039,
    codice_belfiore: "G478",
  },
  {
    id: 4980,
    comune: "PESARO",
    sigla: "PU",
    codice_elettorale: 2110590440,
    codice_istat: 41044,
    codice_belfiore: "G479",
  },
  {
    id: 4981,
    comune: "PESCAGLIA",
    sigla: "LU",
    codice_elettorale: 2090430220,
    codice_istat: 46022,
    codice_belfiore: "G480",
  },
  {
    id: 4982,
    comune: "PESCANTINA",
    sigla: "VR",
    codice_elettorale: 1050890570,
    codice_istat: 23058,
    codice_belfiore: "G481",
  },
  {
    id: 4983,
    comune: "PESCARA",
    sigla: "PE",
    codice_elettorale: 3130600280,
    codice_istat: 68028,
    codice_belfiore: "G482",
  },
  {
    id: 4984,
    comune: "PESCAROLO ED UNITI",
    sigla: "CR",
    codice_elettorale: 1030260680,
    codice_istat: 19069,
    codice_belfiore: "G483",
  },
  {
    id: 4985,
    comune: "PESCASSEROLI",
    sigla: "AQ",
    codice_elettorale: 3130380680,
    codice_istat: 66068,
    codice_belfiore: "G484",
  },
  {
    id: 4986,
    comune: "PESCATE",
    sigla: "LC",
    codice_elettorale: 1030980680,
    codice_istat: 97068,
    codice_belfiore: "G485",
  },
  {
    id: 4987,
    comune: "PESCHE",
    sigla: "IS",
    codice_elettorale: 3140940310,
    codice_istat: 94031,
    codice_belfiore: "G486",
  },
  {
    id: 4988,
    comune: "PESCHICI",
    sigla: "FG",
    codice_elettorale: 3160310360,
    codice_istat: 71038,
    codice_belfiore: "G487",
  },
  {
    id: 4989,
    comune: "PESCHIERA BORROMEO",
    sigla: "MI",
    codice_elettorale: 1030491700,
    codice_istat: 15171,
    codice_belfiore: "G488",
  },
  {
    id: 4990,
    comune: "PESCHIERA DEL GARDA",
    sigla: "VR",
    codice_elettorale: 1050890580,
    codice_istat: 23059,
    codice_belfiore: "G489",
  },
  {
    id: 4991,
    comune: "PESCIA",
    sigla: "PT",
    codice_elettorale: 2090630120,
    codice_istat: 47012,
    codice_belfiore: "G491",
  },
  {
    id: 4992,
    comune: "PESCINA",
    sigla: "AQ",
    codice_elettorale: 3130380690,
    codice_istat: 66069,
    codice_belfiore: "G492",
  },
  {
    id: 4993,
    comune: "PESCO SANNITA",
    sigla: "BN",
    codice_elettorale: 3150110490,
    codice_istat: 62050,
    codice_belfiore: "G494",
  },
  {
    id: 4994,
    comune: "PESCOCOSTANZO",
    sigla: "AQ",
    codice_elettorale: 3130380700,
    codice_istat: 66070,
    codice_belfiore: "G493",
  },
  {
    id: 4995,
    comune: "PESCOLANCIANO",
    sigla: "IS",
    codice_elettorale: 3140940320,
    codice_istat: 94032,
    codice_belfiore: "G495",
  },
  {
    id: 4996,
    comune: "PESCOPAGANO",
    sigla: "PZ",
    codice_elettorale: 3170640570,
    codice_istat: 76058,
    codice_belfiore: "G496",
  },
  {
    id: 4997,
    comune: "PESCOPENNATARO",
    sigla: "IS",
    codice_elettorale: 3140940330,
    codice_istat: 94033,
    codice_belfiore: "G497",
  },
  {
    id: 4998,
    comune: "PESCOROCCHIANO",
    sigla: "RI",
    codice_elettorale: 2120690470,
    codice_istat: 57049,
    codice_belfiore: "G498",
  },
  {
    id: 4999,
    comune: "PESCOSANSONESCO",
    sigla: "PE",
    codice_elettorale: 3130600290,
    codice_istat: 68029,
    codice_belfiore: "G499",
  },
  {
    id: 5000,
    comune: "PESCOSOLIDO",
    sigla: "FR",
    codice_elettorale: 2120330490,
    codice_istat: 60049,
    codice_belfiore: "G500",
  },
  {
    id: 5001,
    comune: "PESSANO CON BORNAGO",
    sigla: "MI",
    codice_elettorale: 1030491710,
    codice_istat: 15172,
    codice_belfiore: "G502",
  },
  {
    id: 5002,
    comune: "PESSINA CREMONESE",
    sigla: "CR",
    codice_elettorale: 1030260690,
    codice_istat: 19070,
    codice_belfiore: "G504",
  },
  {
    id: 5003,
    comune: "PESSINETTO",
    sigla: "TO",
    codice_elettorale: 1010811840,
    codice_istat: 1188,
    codice_belfiore: "G505",
  },
  {
    id: 5004,
    comune: "PETACCIATO",
    sigla: "CB",
    codice_elettorale: 3140190510,
    codice_istat: 70051,
    codice_belfiore: "G506",
  },
  {
    id: 5005,
    comune: "PETILIA POLICASTRO",
    sigla: "KR",
    codice_elettorale: 3180970170,
    codice_istat: 101017,
    codice_belfiore: "G508",
  },
  {
    id: 5006,
    comune: "PETINA",
    sigla: "SA",
    codice_elettorale: 3150720940,
    codice_istat: 65094,
    codice_belfiore: "G509",
  },
  {
    id: 5007,
    comune: "PETRALIA SOPRANA",
    sigla: "PA",
    codice_elettorale: 4190550530,
    codice_istat: 82055,
    codice_belfiore: "G510",
  },
  {
    id: 5008,
    comune: "PETRALIA SOTTANA",
    sigla: "PA",
    codice_elettorale: 4190550540,
    codice_istat: 82056,
    codice_belfiore: "G511",
  },
  {
    id: 5009,
    comune: "PETRELLA SALTO",
    sigla: "RI",
    codice_elettorale: 2120690480,
    codice_istat: 57050,
    codice_belfiore: "G513",
  },
  {
    id: 5010,
    comune: "PETRELLA TIFERNINA",
    sigla: "CB",
    codice_elettorale: 3140190520,
    codice_istat: 70052,
    codice_belfiore: "G512",
  },
  {
    id: 5011,
    comune: "PETRIANO",
    sigla: "PU",
    codice_elettorale: 2110590450,
    codice_istat: 41045,
    codice_belfiore: "G514",
  },
  {
    id: 5012,
    comune: "PETRIOLO",
    sigla: "MC",
    codice_elettorale: 2110440360,
    codice_istat: 43036,
    codice_belfiore: "G515",
  },
  {
    id: 5013,
    comune: "PETRITOLI",
    sigla: "FM",
    codice_elettorale: 2111050310,
    codice_istat: 109031,
    codice_belfiore: "G516",
  },
  {
    id: 5014,
    comune: "PETRIZZI",
    sigla: "CZ",
    codice_elettorale: 3180220910,
    codice_istat: 79094,
    codice_belfiore: "G517",
  },
  {
    id: 5015,
    comune: "PETRONA'",
    sigla: "CZ",
    codice_elettorale: 3180220920,
    codice_istat: 79095,
    codice_belfiore: "G518",
  },
  {
    id: 5016,
    comune: "PETROSINO",
    sigla: "TP",
    codice_elettorale: 4190820161,
    codice_istat: 81024,
    codice_belfiore: "M281",
  },
  {
    id: 5017,
    comune: "PETRURO IRPINO",
    sigla: "AV",
    codice_elettorale: 3150080700,
    codice_istat: 64071,
    codice_belfiore: "G519",
  },
  {
    id: 5018,
    comune: "PETTENASCO",
    sigla: "NO",
    codice_elettorale: 1010521100,
    codice_istat: 3116,
    codice_belfiore: "G520",
  },
  {
    id: 5019,
    comune: "PETTINENGO",
    sigla: "BI",
    codice_elettorale: 1010960420,
    codice_istat: 96042,
    codice_belfiore: "G521",
  },
  {
    id: 5020,
    comune: "PETTINEO",
    sigla: "ME",
    codice_elettorale: 4190480660,
    codice_istat: 83067,
    codice_belfiore: "G522",
  },
  {
    id: 5021,
    comune: "PETTORANELLO DEL MOLISE",
    sigla: "IS",
    codice_elettorale: 3140940340,
    codice_istat: 94034,
    codice_belfiore: "G523",
  },
  {
    id: 5022,
    comune: "PETTORANO SUL GIZIO",
    sigla: "AQ",
    codice_elettorale: 3130380710,
    codice_istat: 66071,
    codice_belfiore: "G524",
  },
  {
    id: 5023,
    comune: "PETTORAZZA GRIMANI",
    sigla: "RO",
    codice_elettorale: 1050710350,
    codice_istat: 29035,
    codice_belfiore: "G525",
  },
  {
    id: 5024,
    comune: "PEVERAGNO",
    sigla: "CN",
    codice_elettorale: 1010271630,
    codice_istat: 4163,
    codice_belfiore: "G526",
  },
  {
    id: 5025,
    comune: "PEZZANA",
    sigla: "VC",
    codice_elettorale: 1010880920,
    codice_istat: 2093,
    codice_belfiore: "G528",
  },
  {
    id: 5026,
    comune: "PEZZAZE",
    sigla: "BS",
    codice_elettorale: 1030151320,
    codice_istat: 17141,
    codice_belfiore: "G529",
  },
  {
    id: 5027,
    comune: "PEZZOLO VALLE UZZONE",
    sigla: "CN",
    codice_elettorale: 1010271640,
    codice_istat: 4164,
    codice_belfiore: "G532",
  },
  {
    id: 5028,
    comune: "PIACENZA",
    sigla: "PC",
    codice_elettorale: 1080610320,
    codice_istat: 33032,
    codice_belfiore: "G535",
  },
  {
    id: 5029,
    comune: "PIACENZA D'ADIGE",
    sigla: "PD",
    codice_elettorale: 1050540620,
    codice_istat: 28062,
    codice_belfiore: "G534",
  },
  {
    id: 5030,
    comune: "PIADENA DRIZZONA",
    sigla: "CR",
    codice_elettorale: 1030260695,
    codice_istat: 19116,
    codice_belfiore: "M418",
  },
  {
    id: 5031,
    comune: "PIAGGINE",
    sigla: "SA",
    codice_elettorale: 3150720950,
    codice_istat: 65095,
    codice_belfiore: "G538",
  },
  {
    id: 5032,
    comune: "PIAN CAMUNO",
    sigla: "BS",
    codice_elettorale: 1030151330,
    codice_istat: 17142,
    codice_belfiore: "G546",
  },
  {
    id: 5033,
    comune: "PIANA CRIXIA",
    sigla: "SV",
    codice_elettorale: 1070740480,
    codice_istat: 9048,
    codice_belfiore: "G542",
  },
  {
    id: 5034,
    comune: "PIANA DEGLI ALBANESI",
    sigla: "PA",
    codice_elettorale: 4190550550,
    codice_istat: 82057,
    codice_belfiore: "G543",
  },
  {
    id: 5035,
    comune: "PIANA DI MONTE VERNA",
    sigla: "CE",
    codice_elettorale: 3150200560,
    codice_istat: 61056,
    codice_belfiore: "G541",
  },
  {
    id: 5036,
    comune: "PIANCASTAGNAIO",
    sigla: "SI",
    codice_elettorale: 2090750200,
    codice_istat: 52020,
    codice_belfiore: "G547",
  },
  {
    id: 5037,
    comune: "PIANCOGNO",
    sigla: "BS",
    codice_elettorale: 1030151331,
    codice_istat: 17206,
    codice_belfiore: "G549",
  },
  {
    id: 5038,
    comune: "PIANDIMELETO",
    sigla: "PU",
    codice_elettorale: 2110590470,
    codice_istat: 41047,
    codice_belfiore: "G551",
  },
  {
    id: 5039,
    comune: "PIANE CRATI",
    sigla: "CS",
    codice_elettorale: 3180250980,
    codice_istat: 78097,
    codice_belfiore: "G553",
  },
  {
    id: 5040,
    comune: "PIANELLA",
    sigla: "PE",
    codice_elettorale: 3130600300,
    codice_istat: 68030,
    codice_belfiore: "G555",
  },
  {
    id: 5041,
    comune: "PIANELLO DEL LARIO",
    sigla: "CO",
    codice_elettorale: 1030241720,
    codice_istat: 13183,
    codice_belfiore: "G556",
  },
  {
    id: 5042,
    comune: "PIANELLO VAL TIDONE",
    sigla: "PC",
    codice_elettorale: 1080610330,
    codice_istat: 33033,
    codice_belfiore: "G557",
  },
  {
    id: 5043,
    comune: "PIANENGO",
    sigla: "CR",
    codice_elettorale: 1030260701,
    codice_istat: 19072,
    codice_belfiore: "G558",
  },
  {
    id: 5044,
    comune: "PIANEZZA",
    sigla: "TO",
    codice_elettorale: 1010811850,
    codice_istat: 1189,
    codice_belfiore: "G559",
  },
  {
    id: 5045,
    comune: "PIANEZZE",
    sigla: "VI",
    codice_elettorale: 1050900770,
    codice_istat: 24077,
    codice_belfiore: "G560",
  },
  {
    id: 5046,
    comune: "PIANFEI",
    sigla: "CN",
    codice_elettorale: 1010271650,
    codice_istat: 4165,
    codice_belfiore: "G561",
  },
  {
    id: 5047,
    comune: "PIANICO",
    sigla: "BG",
    codice_elettorale: 1030121540,
    codice_istat: 16162,
    codice_belfiore: "G564",
  },
  {
    id: 5048,
    comune: "PIANIGA",
    sigla: "VE",
    codice_elettorale: 1050870280,
    codice_istat: 27028,
    codice_belfiore: "G565",
  },
  {
    id: 5049,
    comune: "PIANO DI SORRENTO",
    sigla: "NA",
    codice_elettorale: 3150510530,
    codice_istat: 63053,
    codice_belfiore: "G568",
  },
  {
    id: 5050,
    comune: "PIANOPOLI",
    sigla: "CZ",
    codice_elettorale: 3180220930,
    codice_istat: 79096,
    codice_belfiore: "D546",
  },
  {
    id: 5051,
    comune: "PIANORO",
    sigla: "BO",
    codice_elettorale: 1080130470,
    codice_istat: 37047,
    codice_belfiore: "G570",
  },
  {
    id: 5052,
    comune: "PIANSANO",
    sigla: "VT",
    codice_elettorale: 2120910420,
    codice_istat: 56043,
    codice_belfiore: "G571",
  },
  {
    id: 5053,
    comune: "PIANTEDO",
    sigla: "SO",
    codice_elettorale: 1030770480,
    codice_istat: 14048,
    codice_belfiore: "G572",
  },
  {
    id: 5054,
    comune: "PIARIO",
    sigla: "BG",
    codice_elettorale: 1030121542,
    codice_istat: 16163,
    codice_belfiore: "G574",
  },
  {
    id: 5055,
    comune: "PIASCO",
    sigla: "CN",
    codice_elettorale: 1010271660,
    codice_istat: 4166,
    codice_belfiore: "G575",
  },
  {
    id: 5056,
    comune: "PIATEDA",
    sigla: "SO",
    codice_elettorale: 1030770490,
    codice_istat: 14049,
    codice_belfiore: "G576",
  },
  {
    id: 5057,
    comune: "PIATTO",
    sigla: "BI",
    codice_elettorale: 1010960430,
    codice_istat: 96043,
    codice_belfiore: "G577",
  },
  {
    id: 5058,
    comune: "PIAZZA AL SERCHIO",
    sigla: "LU",
    codice_elettorale: 2090430230,
    codice_istat: 46023,
    codice_belfiore: "G582",
  },
  {
    id: 5059,
    comune: "PIAZZA ARMERINA",
    sigla: "EN",
    codice_elettorale: 4190280140,
    codice_istat: 86014,
    codice_belfiore: "G580",
  },
  {
    id: 5060,
    comune: "PIAZZA BREMBANA",
    sigla: "BG",
    codice_elettorale: 1030121541,
    codice_istat: 16164,
    codice_belfiore: "G579",
  },
  {
    id: 5061,
    comune: "PIAZZATORRE",
    sigla: "BG",
    codice_elettorale: 1030121550,
    codice_istat: 16165,
    codice_belfiore: "G583",
  },
  {
    id: 5062,
    comune: "PIAZZOLA SUL BRENTA",
    sigla: "PD",
    codice_elettorale: 1050540630,
    codice_istat: 28063,
    codice_belfiore: "G587",
  },
  {
    id: 5063,
    comune: "PIAZZOLO",
    sigla: "BG",
    codice_elettorale: 1030121560,
    codice_istat: 16166,
    codice_belfiore: "G588",
  },
  {
    id: 5064,
    comune: "PICCIANO",
    sigla: "PE",
    codice_elettorale: 3130600310,
    codice_istat: 68031,
    codice_belfiore: "G589",
  },
  {
    id: 5065,
    comune: "PICERNO",
    sigla: "PZ",
    codice_elettorale: 3170640580,
    codice_istat: 76059,
    codice_belfiore: "G590",
  },
  {
    id: 5066,
    comune: "PICINISCO",
    sigla: "FR",
    codice_elettorale: 2120330500,
    codice_istat: 60050,
    codice_belfiore: "G591",
  },
  {
    id: 5067,
    comune: "PICO",
    sigla: "FR",
    codice_elettorale: 2120330510,
    codice_istat: 60051,
    codice_belfiore: "G592",
  },
  {
    id: 5068,
    comune: "PIEA",
    sigla: "AT",
    codice_elettorale: 1010070840,
    codice_istat: 5084,
    codice_belfiore: "G593",
  },
  {
    id: 5069,
    comune: "PIEDICAVALLO",
    sigla: "BI",
    codice_elettorale: 1010960440,
    codice_istat: 96044,
    codice_belfiore: "G594",
  },
  {
    id: 5070,
    comune: "PIEDIMONTE ETNEO",
    sigla: "CT",
    codice_elettorale: 4190210340,
    codice_istat: 87035,
    codice_belfiore: "G597",
  },
  {
    id: 5071,
    comune: "PIEDIMONTE MATESE",
    sigla: "CE",
    codice_elettorale: 3150200570,
    codice_istat: 61057,
    codice_belfiore: "G596",
  },
  {
    id: 5072,
    comune: "PIEDIMONTE SAN GERMANO",
    sigla: "FR",
    codice_elettorale: 2120330520,
    codice_istat: 60052,
    codice_belfiore: "G598",
  },
  {
    id: 5073,
    comune: "PIEDIMULERA",
    sigla: "VB",
    codice_elettorale: 1011020530,
    codice_istat: 103053,
    codice_belfiore: "G600",
  },
  {
    id: 5074,
    comune: "PIEGARO",
    sigla: "PG",
    codice_elettorale: 2100580400,
    codice_istat: 54040,
    codice_belfiore: "G601",
  },
  {
    id: 5075,
    comune: "PIENZA",
    sigla: "SI",
    codice_elettorale: 2090750210,
    codice_istat: 52021,
    codice_belfiore: "G602",
  },
  {
    id: 5076,
    comune: "PIERANICA",
    sigla: "CR",
    codice_elettorale: 1030260710,
    codice_istat: 19073,
    codice_belfiore: "G603",
  },
  {
    id: 5077,
    comune: "PIETRA DE' GIORGI",
    sigla: "PV",
    codice_elettorale: 1030571080,
    codice_istat: 18111,
    codice_belfiore: "G612",
  },
  {
    id: 5078,
    comune: "PIETRA LIGURE",
    sigla: "SV",
    codice_elettorale: 1070740490,
    codice_istat: 9049,
    codice_belfiore: "G605",
  },
  {
    id: 5079,
    comune: "PIETRA MARAZZI",
    sigla: "AL",
    codice_elettorale: 1010021260,
    codice_istat: 6129,
    codice_belfiore: "G619",
  },
  {
    id: 5080,
    comune: "PIETRABBONDANTE",
    sigla: "IS",
    codice_elettorale: 3140940350,
    codice_istat: 94035,
    codice_belfiore: "G606",
  },
  {
    id: 5081,
    comune: "PIETRABRUNA",
    sigla: "IM",
    codice_elettorale: 1070370380,
    codice_istat: 8041,
    codice_belfiore: "G607",
  },
  {
    id: 5082,
    comune: "PIETRACAMELA",
    sigla: "TE",
    codice_elettorale: 3130790330,
    codice_istat: 67034,
    codice_belfiore: "G608",
  },
  {
    id: 5083,
    comune: "PIETRACATELLA",
    sigla: "CB",
    codice_elettorale: 3140190530,
    codice_istat: 70053,
    codice_belfiore: "G609",
  },
  {
    id: 5084,
    comune: "PIETRACUPA",
    sigla: "CB",
    codice_elettorale: 3140190540,
    codice_istat: 70054,
    codice_belfiore: "G610",
  },
  {
    id: 5085,
    comune: "PIETRADEFUSI",
    sigla: "AV",
    codice_elettorale: 3150080710,
    codice_istat: 64072,
    codice_belfiore: "G611",
  },
  {
    id: 5086,
    comune: "PIETRAFERRAZZANA",
    sigla: "CH",
    codice_elettorale: 3130230651,
    codice_istat: 69103,
    codice_belfiore: "G613",
  },
  {
    id: 5087,
    comune: "PIETRAFITTA",
    sigla: "CS",
    codice_elettorale: 3180250990,
    codice_istat: 78098,
    codice_belfiore: "G615",
  },
  {
    id: 5088,
    comune: "PIETRAGALLA",
    sigla: "PZ",
    codice_elettorale: 3170640590,
    codice_istat: 76060,
    codice_belfiore: "G616",
  },
  {
    id: 5089,
    comune: "PIETRALUNGA",
    sigla: "PG",
    codice_elettorale: 2100580410,
    codice_istat: 54041,
    codice_belfiore: "G618",
  },
  {
    id: 5090,
    comune: "PIETRAMELARA",
    sigla: "CE",
    codice_elettorale: 3150200580,
    codice_istat: 61058,
    codice_belfiore: "G620",
  },
  {
    id: 5091,
    comune: "PIETRAMONTECORVINO",
    sigla: "FG",
    codice_elettorale: 3160310370,
    codice_istat: 71039,
    codice_belfiore: "G604",
  },
  {
    id: 5092,
    comune: "PIETRANICO",
    sigla: "PE",
    codice_elettorale: 3130600320,
    codice_istat: 68032,
    codice_belfiore: "G621",
  },
  {
    id: 5093,
    comune: "PIETRAPAOLA",
    sigla: "CS",
    codice_elettorale: 3180251000,
    codice_istat: 78099,
    codice_belfiore: "G622",
  },
  {
    id: 5094,
    comune: "PIETRAPERTOSA",
    sigla: "PZ",
    codice_elettorale: 3170640600,
    codice_istat: 76061,
    codice_belfiore: "G623",
  },
  {
    id: 5095,
    comune: "PIETRAPERZIA",
    sigla: "EN",
    codice_elettorale: 4190280150,
    codice_istat: 86015,
    codice_belfiore: "G624",
  },
  {
    id: 5096,
    comune: "PIETRAPORZIO",
    sigla: "CN",
    codice_elettorale: 1010271670,
    codice_istat: 4167,
    codice_belfiore: "G625",
  },
  {
    id: 5097,
    comune: "PIETRAROJA",
    sigla: "BN",
    codice_elettorale: 3150110500,
    codice_istat: 62051,
    codice_belfiore: "G626",
  },
  {
    id: 5098,
    comune: "PIETRARUBBIA",
    sigla: "PU",
    codice_elettorale: 2110590480,
    codice_istat: 41048,
    codice_belfiore: "G627",
  },
  {
    id: 5099,
    comune: "PIETRASANTA",
    sigla: "LU",
    codice_elettorale: 2090430240,
    codice_istat: 46024,
    codice_belfiore: "G628",
  },
  {
    id: 5100,
    comune: "PIETRASTORNINA",
    sigla: "AV",
    codice_elettorale: 3150080720,
    codice_istat: 64073,
    codice_belfiore: "G629",
  },
  {
    id: 5101,
    comune: "PIETRAVAIRANO",
    sigla: "CE",
    codice_elettorale: 3150200590,
    codice_istat: 61059,
    codice_belfiore: "G630",
  },
  {
    id: 5102,
    comune: "PIETRELCINA",
    sigla: "BN",
    codice_elettorale: 3150110510,
    codice_istat: 62052,
    codice_belfiore: "G631",
  },
  {
    id: 5103,
    comune: "PIEVE A NIEVOLE",
    sigla: "PT",
    codice_elettorale: 2090630130,
    codice_istat: 47013,
    codice_belfiore: "G636",
  },
  {
    id: 5104,
    comune: "PIEVE ALBIGNOLA",
    sigla: "PV",
    codice_elettorale: 1030571090,
    codice_istat: 18112,
    codice_belfiore: "G635",
  },
  {
    id: 5105,
    comune: "PIEVE D'OLMI",
    sigla: "CR",
    codice_elettorale: 1030260720,
    codice_istat: 19074,
    codice_belfiore: "G647",
  },
  {
    id: 5106,
    comune: "PIEVE DEL CAIRO",
    sigla: "PV",
    codice_elettorale: 1030571100,
    codice_istat: 18113,
    codice_belfiore: "G639",
  },
  {
    id: 5107,
    comune: "PIEVE DEL GRAPPA",
    sigla: "TV",
    codice_elettorale: 1050840555,
    codice_istat: 26096,
    codice_belfiore: "M422",
  },
  {
    id: 5108,
    comune: "PIEVE DI BONO-PREZZO",
    sigla: "TN",
    codice_elettorale: 1040831295,
    codice_istat: 22234,
    codice_belfiore: "M365",
  },
  {
    id: 5109,
    comune: "PIEVE DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100390,
    codice_istat: 25039,
    codice_belfiore: "G642",
  },
  {
    id: 5110,
    comune: "PIEVE DI CENTO",
    sigla: "BO",
    codice_elettorale: 1080130480,
    codice_istat: 37048,
    codice_belfiore: "G643",
  },
  {
    id: 5111,
    comune: "PIEVE DI SOLIGO",
    sigla: "TV",
    codice_elettorale: 1050840560,
    codice_istat: 26057,
    codice_belfiore: "G645",
  },
  {
    id: 5112,
    comune: "PIEVE DI TECO",
    sigla: "IM",
    codice_elettorale: 1070370390,
    codice_istat: 8042,
    codice_belfiore: "G632",
  },
  {
    id: 5113,
    comune: "PIEVE EMANUELE",
    sigla: "MI",
    codice_elettorale: 1030491720,
    codice_istat: 15173,
    codice_belfiore: "G634",
  },
  {
    id: 5114,
    comune: "PIEVE FISSIRAGA",
    sigla: "LO",
    codice_elettorale: 1030990450,
    codice_istat: 98045,
    codice_belfiore: "G096",
  },
  {
    id: 5115,
    comune: "PIEVE FOSCIANA",
    sigla: "LU",
    codice_elettorale: 2090430250,
    codice_istat: 46025,
    codice_belfiore: "G648",
  },
  {
    id: 5116,
    comune: "PIEVE LIGURE",
    sigla: "GE",
    codice_elettorale: 1070340430,
    codice_istat: 10043,
    codice_belfiore: "G646",
  },
  {
    id: 5117,
    comune: "PIEVE PORTO MORONE",
    sigla: "PV",
    codice_elettorale: 1030571110,
    codice_istat: 18114,
    codice_belfiore: "G650",
  },
  {
    id: 5118,
    comune: "PIEVE SAN GIACOMO",
    sigla: "CR",
    codice_elettorale: 1030260730,
    codice_istat: 19075,
    codice_belfiore: "G651",
  },
  {
    id: 5119,
    comune: "PIEVE SANTO STEFANO",
    sigla: "AR",
    codice_elettorale: 2090050300,
    codice_istat: 51030,
    codice_belfiore: "G653",
  },
  {
    id: 5120,
    comune: "PIEVE TESINO",
    sigla: "TN",
    codice_elettorale: 1040831310,
    codice_istat: 22142,
    codice_belfiore: "G656",
  },
  {
    id: 5121,
    comune: "PIEVE TORINA",
    sigla: "MC",
    codice_elettorale: 2110440380,
    codice_istat: 43038,
    codice_belfiore: "G657",
  },
  {
    id: 5122,
    comune: "PIEVE VERGONTE",
    sigla: "VB",
    codice_elettorale: 1011020540,
    codice_istat: 103054,
    codice_belfiore: "G658",
  },
  {
    id: 5123,
    comune: "PIEVEPELAGO",
    sigla: "MO",
    codice_elettorale: 1080500300,
    codice_istat: 36031,
    codice_belfiore: "G649",
  },
  {
    id: 5124,
    comune: "PIGLIO",
    sigla: "FR",
    codice_elettorale: 2120330530,
    codice_istat: 60053,
    codice_belfiore: "G659",
  },
  {
    id: 5125,
    comune: "PIGNA",
    sigla: "IM",
    codice_elettorale: 1070370400,
    codice_istat: 8043,
    codice_belfiore: "G660",
  },
  {
    id: 5126,
    comune: "PIGNATARO INTERAMNA",
    sigla: "FR",
    codice_elettorale: 2120330540,
    codice_istat: 60054,
    codice_belfiore: "G662",
  },
  {
    id: 5127,
    comune: "PIGNATARO MAGGIORE",
    sigla: "CE",
    codice_elettorale: 3150200600,
    codice_istat: 61060,
    codice_belfiore: "G661",
  },
  {
    id: 5128,
    comune: "PIGNOLA",
    sigla: "PZ",
    codice_elettorale: 3170640610,
    codice_istat: 76062,
    codice_belfiore: "G663",
  },
  {
    id: 5129,
    comune: "PIGNONE",
    sigla: "SP",
    codice_elettorale: 1070390210,
    codice_istat: 11021,
    codice_belfiore: "G664",
  },
  {
    id: 5130,
    comune: "PIGRA",
    sigla: "CO",
    codice_elettorale: 1030241730,
    codice_istat: 13184,
    codice_belfiore: "G665",
  },
  {
    id: 5131,
    comune: "PILA",
    sigla: "VC",
    codice_elettorale: 1010880950,
    codice_istat: 2096,
    codice_belfiore: "G666",
  },
  {
    id: 5132,
    comune: "PIMENTEL",
    sigla: "SU",
    codice_elettorale: 4201110550,
    codice_istat: 111055,
    codice_belfiore: "G669",
  },
  {
    id: 5133,
    comune: "PIMONTE",
    sigla: "NA",
    codice_elettorale: 3150510540,
    codice_istat: 63054,
    codice_belfiore: "G670",
  },
  {
    id: 5134,
    comune: "PINAROLO PO",
    sigla: "PV",
    codice_elettorale: 1030571120,
    codice_istat: 18115,
    codice_belfiore: "G671",
  },
  {
    id: 5135,
    comune: "PINASCA",
    sigla: "TO",
    codice_elettorale: 1010811860,
    codice_istat: 1190,
    codice_belfiore: "G672",
  },
  {
    id: 5136,
    comune: "PINCARA",
    sigla: "RO",
    codice_elettorale: 1050710360,
    codice_istat: 29036,
    codice_belfiore: "G673",
  },
  {
    id: 5137,
    comune: "PINEROLO",
    sigla: "TO",
    codice_elettorale: 1010811870,
    codice_istat: 1191,
    codice_belfiore: "G674",
  },
  {
    id: 5138,
    comune: "PINETO",
    sigla: "TE",
    codice_elettorale: 3130790340,
    codice_istat: 67035,
    codice_belfiore: "F831",
  },
  {
    id: 5139,
    comune: "PINO D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070850,
    codice_istat: 5085,
    codice_belfiore: "G676",
  },
  {
    id: 5140,
    comune: "PINO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010811880,
    codice_istat: 1192,
    codice_belfiore: "G678",
  },
  {
    id: 5141,
    comune: "PINZANO AL TAGLIAMENTO",
    sigla: "PN",
    codice_elettorale: 1060930300,
    codice_istat: 93030,
    codice_belfiore: "G680",
  },
  {
    id: 5142,
    comune: "PINZOLO",
    sigla: "TN",
    codice_elettorale: 1040831320,
    codice_istat: 22143,
    codice_belfiore: "G681",
  },
  {
    id: 5143,
    comune: "PIOBBICO",
    sigla: "PU",
    codice_elettorale: 2110590490,
    codice_istat: 41049,
    codice_belfiore: "G682",
  },
  {
    id: 5144,
    comune: "PIOBESI D'ALBA",
    sigla: "CN",
    codice_elettorale: 1010271680,
    codice_istat: 4168,
    codice_belfiore: "G683",
  },
  {
    id: 5145,
    comune: "PIOBESI TORINESE",
    sigla: "TO",
    codice_elettorale: 1010811890,
    codice_istat: 1193,
    codice_belfiore: "G684",
  },
  {
    id: 5146,
    comune: "PIODE",
    sigla: "VC",
    codice_elettorale: 1010880960,
    codice_istat: 2097,
    codice_belfiore: "G685",
  },
  {
    id: 5147,
    comune: "PIOLTELLO",
    sigla: "MI",
    codice_elettorale: 1030491740,
    codice_istat: 15175,
    codice_belfiore: "G686",
  },
  {
    id: 5148,
    comune: "PIOMBINO",
    sigla: "LI",
    codice_elettorale: 2090420120,
    codice_istat: 49012,
    codice_belfiore: "G687",
  },
  {
    id: 5149,
    comune: "PIOMBINO DESE",
    sigla: "PD",
    codice_elettorale: 1050540640,
    codice_istat: 28064,
    codice_belfiore: "G688",
  },
  {
    id: 5150,
    comune: "PIORACO",
    sigla: "MC",
    codice_elettorale: 2110440390,
    codice_istat: 43039,
    codice_belfiore: "G690",
  },
  {
    id: 5151,
    comune: "PIOSSASCO",
    sigla: "TO",
    codice_elettorale: 1010811900,
    codice_istat: 1194,
    codice_belfiore: "G691",
  },
  {
    id: 5152,
    comune: "PIOVA' MASSAIA",
    sigla: "AT",
    codice_elettorale: 1010070860,
    codice_istat: 5086,
    codice_belfiore: "G692",
  },
  {
    id: 5153,
    comune: "PIOVE DI SACCO",
    sigla: "PD",
    codice_elettorale: 1050540650,
    codice_istat: 28065,
    codice_belfiore: "G693",
  },
  {
    id: 5154,
    comune: "PIOVENE ROCCHETTE",
    sigla: "VI",
    codice_elettorale: 1050900780,
    codice_istat: 24078,
    codice_belfiore: "G694",
  },
  {
    id: 5155,
    comune: "PIOZZANO",
    sigla: "PC",
    codice_elettorale: 1080610340,
    codice_istat: 33034,
    codice_belfiore: "G696",
  },
  {
    id: 5156,
    comune: "PIOZZO",
    sigla: "CN",
    codice_elettorale: 1010271690,
    codice_istat: 4169,
    codice_belfiore: "G697",
  },
  {
    id: 5157,
    comune: "PIRAINO",
    sigla: "ME",
    codice_elettorale: 4190480670,
    codice_istat: 83068,
    codice_belfiore: "G699",
  },
  {
    id: 5158,
    comune: "PISA",
    sigla: "PI",
    codice_elettorale: 2090620250,
    codice_istat: 50026,
    codice_belfiore: "G702",
  },
  {
    id: 5159,
    comune: "PISANO",
    sigla: "NO",
    codice_elettorale: 1010521130,
    codice_istat: 3119,
    codice_belfiore: "G703",
  },
  {
    id: 5160,
    comune: "PISCINA",
    sigla: "TO",
    codice_elettorale: 1010811910,
    codice_istat: 1195,
    codice_belfiore: "G705",
  },
  {
    id: 5161,
    comune: "PISCINAS",
    sigla: "SU",
    codice_elettorale: 4201110560,
    codice_istat: 111056,
    codice_belfiore: "M291",
  },
  {
    id: 5162,
    comune: "PISCIOTTA",
    sigla: "SA",
    codice_elettorale: 3150720960,
    codice_istat: 65096,
    codice_belfiore: "G707",
  },
  {
    id: 5163,
    comune: "PISOGNE",
    sigla: "BS",
    codice_elettorale: 1030151340,
    codice_istat: 17143,
    codice_belfiore: "G710",
  },
  {
    id: 5164,
    comune: "PISONIANO",
    sigla: "RM",
    codice_elettorale: 2120700760,
    codice_istat: 58077,
    codice_belfiore: "G704",
  },
  {
    id: 5165,
    comune: "PISTICCI",
    sigla: "MT",
    codice_elettorale: 3170470200,
    codice_istat: 77020,
    codice_belfiore: "G712",
  },
  {
    id: 5166,
    comune: "PISTOIA",
    sigla: "PT",
    codice_elettorale: 2090630140,
    codice_istat: 47014,
    codice_belfiore: "G713",
  },
  {
    id: 5167,
    comune: "PITIGLIANO",
    sigla: "GR",
    codice_elettorale: 2090360180,
    codice_istat: 53019,
    codice_belfiore: "G716",
  },
  {
    id: 5168,
    comune: "PIUBEGA",
    sigla: "MN",
    codice_elettorale: 1030450410,
    codice_istat: 20041,
    codice_belfiore: "G717",
  },
  {
    id: 5169,
    comune: "PIURO",
    sigla: "SO",
    codice_elettorale: 1030770500,
    codice_istat: 14050,
    codice_belfiore: "G718",
  },
  {
    id: 5170,
    comune: "PIVERONE",
    sigla: "TO",
    codice_elettorale: 1010811920,
    codice_istat: 1196,
    codice_belfiore: "G719",
  },
  {
    id: 5171,
    comune: "PIZZALE",
    sigla: "PV",
    codice_elettorale: 1030571130,
    codice_istat: 18116,
    codice_belfiore: "G720",
  },
  {
    id: 5172,
    comune: "PIZZIGHETTONE",
    sigla: "CR",
    codice_elettorale: 1030260740,
    codice_istat: 19076,
    codice_belfiore: "G721",
  },
  {
    id: 5173,
    comune: "PIZZO",
    sigla: "VV",
    codice_elettorale: 3181030270,
    codice_istat: 102027,
    codice_belfiore: "G722",
  },
  {
    id: 5174,
    comune: "PIZZOFERRATO",
    sigla: "CH",
    codice_elettorale: 3130230660,
    codice_istat: 69066,
    codice_belfiore: "G724",
  },
  {
    id: 5175,
    comune: "PIZZOLI",
    sigla: "AQ",
    codice_elettorale: 3130380720,
    codice_istat: 66072,
    codice_belfiore: "G726",
  },
  {
    id: 5176,
    comune: "PIZZONE",
    sigla: "IS",
    codice_elettorale: 3140940360,
    codice_istat: 94036,
    codice_belfiore: "G727",
  },
  {
    id: 5177,
    comune: "PIZZONI",
    sigla: "VV",
    codice_elettorale: 3181030280,
    codice_istat: 102028,
    codice_belfiore: "G728",
  },
  {
    id: 5178,
    comune: "PLACANICA",
    sigla: "RC",
    codice_elettorale: 3180670590,
    codice_istat: 80059,
    codice_belfiore: "G729",
  },
  {
    id: 5179,
    comune: "PLATACI",
    sigla: "CS",
    codice_elettorale: 3180251010,
    codice_istat: 78100,
    codice_belfiore: "G733",
  },
  {
    id: 5180,
    comune: "PLATANIA",
    sigla: "CZ",
    codice_elettorale: 3180220960,
    codice_istat: 79099,
    codice_belfiore: "G734",
  },
  {
    id: 5181,
    comune: "PLATI'",
    sigla: "RC",
    codice_elettorale: 3180670600,
    codice_istat: 80060,
    codice_belfiore: "G735",
  },
  {
    id: 5182,
    comune: "PLAUS/PLAUS",
    sigla: "BZ",
    codice_elettorale: 1040140610,
    codice_istat: 21064,
    codice_belfiore: "G299",
  },
  {
    id: 5183,
    comune: "PLESIO",
    sigla: "CO",
    codice_elettorale: 1030241740,
    codice_istat: 13185,
    codice_belfiore: "G737",
  },
  {
    id: 5184,
    comune: "PLOAGHE",
    sigla: "SS",
    codice_elettorale: 4200730560,
    codice_istat: 90057,
    codice_belfiore: "G740",
  },
  {
    id: 5185,
    comune: "PLODIO",
    sigla: "SV",
    codice_elettorale: 1070740500,
    codice_istat: 9050,
    codice_belfiore: "G741",
  },
  {
    id: 5186,
    comune: "POCAPAGLIA",
    sigla: "CN",
    codice_elettorale: 1010271700,
    codice_istat: 4170,
    codice_belfiore: "G742",
  },
  {
    id: 5187,
    comune: "POCENIA",
    sigla: "UD",
    codice_elettorale: 1060850750,
    codice_istat: 30075,
    codice_belfiore: "G743",
  },
  {
    id: 5188,
    comune: "PODENZANA",
    sigla: "MS",
    codice_elettorale: 2090460130,
    codice_istat: 45013,
    codice_belfiore: "G746",
  },
  {
    id: 5189,
    comune: "PODENZANO",
    sigla: "PC",
    codice_elettorale: 1080610350,
    codice_istat: 33035,
    codice_belfiore: "G747",
  },
  {
    id: 5190,
    comune: "POFI",
    sigla: "FR",
    codice_elettorale: 2120330550,
    codice_istat: 60055,
    codice_belfiore: "G749",
  },
  {
    id: 5191,
    comune: "POGGIARDO",
    sigla: "LE",
    codice_elettorale: 3160410600,
    codice_istat: 75061,
    codice_belfiore: "G751",
  },
  {
    id: 5192,
    comune: "POGGIBONSI",
    sigla: "SI",
    codice_elettorale: 2090750220,
    codice_istat: 52022,
    codice_belfiore: "G752",
  },
  {
    id: 5193,
    comune: "POGGIO A CAIANO",
    sigla: "PO",
    codice_elettorale: 2091000040,
    codice_istat: 100004,
    codice_belfiore: "G754",
  },
  {
    id: 5194,
    comune: "POGGIO BUSTONE",
    sigla: "RI",
    codice_elettorale: 2120690490,
    codice_istat: 57051,
    codice_belfiore: "G756",
  },
  {
    id: 5195,
    comune: "POGGIO CATINO",
    sigla: "RI",
    codice_elettorale: 2120690500,
    codice_istat: 57052,
    codice_belfiore: "G757",
  },
  {
    id: 5196,
    comune: "POGGIO IMPERIALE",
    sigla: "FG",
    codice_elettorale: 3160310380,
    codice_istat: 71040,
    codice_belfiore: "G761",
  },
  {
    id: 5197,
    comune: "POGGIO MIRTETO",
    sigla: "RI",
    codice_elettorale: 2120690510,
    codice_istat: 57053,
    codice_belfiore: "G763",
  },
  {
    id: 5198,
    comune: "POGGIO MOIANO",
    sigla: "RI",
    codice_elettorale: 2120690520,
    codice_istat: 57054,
    codice_belfiore: "G764",
  },
  {
    id: 5199,
    comune: "POGGIO NATIVO",
    sigla: "RI",
    codice_elettorale: 2120690530,
    codice_istat: 57055,
    codice_belfiore: "G765",
  },
  {
    id: 5200,
    comune: "POGGIO PICENZE",
    sigla: "AQ",
    codice_elettorale: 3130380730,
    codice_istat: 66073,
    codice_belfiore: "G766",
  },
  {
    id: 5201,
    comune: "POGGIO RENATICO",
    sigla: "FE",
    codice_elettorale: 1080290160,
    codice_istat: 38018,
    codice_belfiore: "G768",
  },
  {
    id: 5202,
    comune: "POGGIO RUSCO",
    sigla: "MN",
    codice_elettorale: 1030450420,
    codice_istat: 20042,
    codice_belfiore: "G753",
  },
  {
    id: 5203,
    comune: "POGGIO SAN LORENZO",
    sigla: "RI",
    codice_elettorale: 2120690540,
    codice_istat: 57056,
    codice_belfiore: "G770",
  },
  {
    id: 5204,
    comune: "POGGIO SAN MARCELLO",
    sigla: "AN",
    codice_elettorale: 2110030370,
    codice_istat: 42037,
    codice_belfiore: "G771",
  },
  {
    id: 5205,
    comune: "POGGIO SAN VICINO",
    sigla: "MC",
    codice_elettorale: 2110440400,
    codice_istat: 43040,
    codice_belfiore: "D566",
  },
  {
    id: 5206,
    comune: "POGGIO SANNITA",
    sigla: "IS",
    codice_elettorale: 3140940370,
    codice_istat: 94037,
    codice_belfiore: "B317",
  },
  {
    id: 5207,
    comune: "POGGIO TORRIANA",
    sigla: "RN",
    codice_elettorale: 1081010125,
    codice_istat: 99028,
    codice_belfiore: "M324",
  },
  {
    id: 5208,
    comune: "POGGIODOMO",
    sigla: "PG",
    codice_elettorale: 2100580420,
    codice_istat: 54042,
    codice_belfiore: "G758",
  },
  {
    id: 5209,
    comune: "POGGIOFIORITO",
    sigla: "CH",
    codice_elettorale: 3130230670,
    codice_istat: 69067,
    codice_belfiore: "G760",
  },
  {
    id: 5210,
    comune: "POGGIOMARINO",
    sigla: "NA",
    codice_elettorale: 3150510550,
    codice_istat: 63055,
    codice_belfiore: "G762",
  },
  {
    id: 5211,
    comune: "POGGIOREALE",
    sigla: "TP",
    codice_elettorale: 4190820170,
    codice_istat: 81016,
    codice_belfiore: "G767",
  },
  {
    id: 5212,
    comune: "POGGIORSINI",
    sigla: "BA",
    codice_elettorale: 3160090331,
    codice_istat: 72034,
    codice_belfiore: "G769",
  },
  {
    id: 5213,
    comune: "POGGIRIDENTI",
    sigla: "SO",
    codice_elettorale: 1030770510,
    codice_istat: 14051,
    codice_belfiore: "G431",
  },
  {
    id: 5214,
    comune: "POGLIANO MILANESE",
    sigla: "MI",
    codice_elettorale: 1030491750,
    codice_istat: 15176,
    codice_belfiore: "G772",
  },
  {
    id: 5215,
    comune: "POGNANA LARIO",
    sigla: "CO",
    codice_elettorale: 1030241750,
    codice_istat: 13186,
    codice_belfiore: "G773",
  },
  {
    id: 5216,
    comune: "POGNANO",
    sigla: "BG",
    codice_elettorale: 1030121570,
    codice_istat: 16167,
    codice_belfiore: "G774",
  },
  {
    id: 5217,
    comune: "POGNO",
    sigla: "NO",
    codice_elettorale: 1010521140,
    codice_istat: 3120,
    codice_belfiore: "G775",
  },
  {
    id: 5218,
    comune: "POIRINO",
    sigla: "TO",
    codice_elettorale: 1010811930,
    codice_istat: 1197,
    codice_belfiore: "G777",
  },
  {
    id: 5219,
    comune: "POJANA MAGGIORE",
    sigla: "VI",
    codice_elettorale: 1050900790,
    codice_istat: 24079,
    codice_belfiore: "G776",
  },
  {
    id: 5220,
    comune: "POLAVENO",
    sigla: "BS",
    codice_elettorale: 1030151350,
    codice_istat: 17144,
    codice_belfiore: "G779",
  },
  {
    id: 5221,
    comune: "POLCENIGO",
    sigla: "PN",
    codice_elettorale: 1060930310,
    codice_istat: 93031,
    codice_belfiore: "G780",
  },
  {
    id: 5222,
    comune: "POLESELLA",
    sigla: "RO",
    codice_elettorale: 1050710370,
    codice_istat: 29037,
    codice_belfiore: "G782",
  },
  {
    id: 5223,
    comune: "POLESINE ZIBELLO",
    sigla: "PR",
    codice_elettorale: 1080560291,
    codice_istat: 34050,
    codice_belfiore: "M367",
  },
  {
    id: 5224,
    comune: "POLI",
    sigla: "RM",
    codice_elettorale: 2120700770,
    codice_istat: 58078,
    codice_belfiore: "G784",
  },
  {
    id: 5225,
    comune: "POLIA",
    sigla: "VV",
    codice_elettorale: 3181030290,
    codice_istat: 102029,
    codice_belfiore: "G785",
  },
  {
    id: 5226,
    comune: "POLICORO",
    sigla: "MT",
    codice_elettorale: 3170470201,
    codice_istat: 77021,
    codice_belfiore: "G786",
  },
  {
    id: 5227,
    comune: "POLIGNANO A MARE",
    sigla: "BA",
    codice_elettorale: 3160090340,
    codice_istat: 72035,
    codice_belfiore: "G787",
  },
  {
    id: 5228,
    comune: "POLINAGO",
    sigla: "MO",
    codice_elettorale: 1080500310,
    codice_istat: 36032,
    codice_belfiore: "G789",
  },
  {
    id: 5229,
    comune: "POLINO",
    sigla: "TR",
    codice_elettorale: 2100800270,
    codice_istat: 55027,
    codice_belfiore: "G790",
  },
  {
    id: 5230,
    comune: "POLISTENA",
    sigla: "RC",
    codice_elettorale: 3180670610,
    codice_istat: 80061,
    codice_belfiore: "G791",
  },
  {
    id: 5231,
    comune: "POLIZZI GENEROSA",
    sigla: "PA",
    codice_elettorale: 4190550560,
    codice_istat: 82058,
    codice_belfiore: "G792",
  },
  {
    id: 5232,
    comune: "POLLA",
    sigla: "SA",
    codice_elettorale: 3150720970,
    codice_istat: 65097,
    codice_belfiore: "G793",
  },
  {
    id: 5233,
    comune: "POLLEIN",
    sigla: "AO",
    codice_elettorale: 1020040480,
    codice_istat: 7049,
    codice_belfiore: "G794",
  },
  {
    id: 5234,
    comune: "POLLENA TROCCHIA",
    sigla: "NA",
    codice_elettorale: 3150510560,
    codice_istat: 63056,
    codice_belfiore: "G795",
  },
  {
    id: 5235,
    comune: "POLLENZA",
    sigla: "MC",
    codice_elettorale: 2110440410,
    codice_istat: 43041,
    codice_belfiore: "F567",
  },
  {
    id: 5236,
    comune: "POLLICA",
    sigla: "SA",
    codice_elettorale: 3150720980,
    codice_istat: 65098,
    codice_belfiore: "G796",
  },
  {
    id: 5237,
    comune: "POLLINA",
    sigla: "PA",
    codice_elettorale: 4190550570,
    codice_istat: 82059,
    codice_belfiore: "G797",
  },
  {
    id: 5238,
    comune: "POLLONE",
    sigla: "BI",
    codice_elettorale: 1010960460,
    codice_istat: 96046,
    codice_belfiore: "G798",
  },
  {
    id: 5239,
    comune: "POLLUTRI",
    sigla: "CH",
    codice_elettorale: 3130230680,
    codice_istat: 69068,
    codice_belfiore: "G799",
  },
  {
    id: 5240,
    comune: "POLONGHERA",
    sigla: "CN",
    codice_elettorale: 1010271710,
    codice_istat: 4171,
    codice_belfiore: "G800",
  },
  {
    id: 5241,
    comune: "POLPENAZZE DEL GARDA",
    sigla: "BS",
    codice_elettorale: 1030151360,
    codice_istat: 17145,
    codice_belfiore: "G801",
  },
  {
    id: 5242,
    comune: "POLVERARA",
    sigla: "PD",
    codice_elettorale: 1050540660,
    codice_istat: 28066,
    codice_belfiore: "G802",
  },
  {
    id: 5243,
    comune: "POLVERIGI",
    sigla: "AN",
    codice_elettorale: 2110030380,
    codice_istat: 42038,
    codice_belfiore: "G803",
  },
  {
    id: 5244,
    comune: "POMARANCE",
    sigla: "PI",
    codice_elettorale: 2090620260,
    codice_istat: 50027,
    codice_belfiore: "G804",
  },
  {
    id: 5245,
    comune: "POMARETTO",
    sigla: "TO",
    codice_elettorale: 1010811931,
    codice_istat: 1198,
    codice_belfiore: "G805",
  },
  {
    id: 5246,
    comune: "POMARICO",
    sigla: "MT",
    codice_elettorale: 3170470210,
    codice_istat: 77022,
    codice_belfiore: "G806",
  },
  {
    id: 5247,
    comune: "POMARO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021280,
    codice_istat: 6131,
    codice_belfiore: "G807",
  },
  {
    id: 5248,
    comune: "POMAROLO",
    sigla: "TN",
    codice_elettorale: 1040831330,
    codice_istat: 22144,
    codice_belfiore: "G808",
  },
  {
    id: 5249,
    comune: "POMBIA",
    sigla: "NO",
    codice_elettorale: 1010521150,
    codice_istat: 3121,
    codice_belfiore: "G809",
  },
  {
    id: 5250,
    comune: "POMEZIA",
    sigla: "RM",
    codice_elettorale: 2120700780,
    codice_istat: 58079,
    codice_belfiore: "G811",
  },
  {
    id: 5251,
    comune: "POMIGLIANO D'ARCO",
    sigla: "NA",
    codice_elettorale: 3150510570,
    codice_istat: 63057,
    codice_belfiore: "G812",
  },
  {
    id: 5252,
    comune: "POMPEI",
    sigla: "NA",
    codice_elettorale: 3150510580,
    codice_istat: 63058,
    codice_belfiore: "G813",
  },
  {
    id: 5253,
    comune: "POMPEIANA",
    sigla: "IM",
    codice_elettorale: 1070370410,
    codice_istat: 8044,
    codice_belfiore: "G814",
  },
  {
    id: 5254,
    comune: "POMPIANO",
    sigla: "BS",
    codice_elettorale: 1030151370,
    codice_istat: 17146,
    codice_belfiore: "G815",
  },
  {
    id: 5255,
    comune: "POMPONESCO",
    sigla: "MN",
    codice_elettorale: 1030450430,
    codice_istat: 20043,
    codice_belfiore: "G816",
  },
  {
    id: 5256,
    comune: "POMPU",
    sigla: "OR",
    codice_elettorale: 4200950420,
    codice_istat: 95042,
    codice_belfiore: "G817",
  },
  {
    id: 5257,
    comune: "PONCARALE",
    sigla: "BS",
    codice_elettorale: 1030151371,
    codice_istat: 17147,
    codice_belfiore: "G818",
  },
  {
    id: 5258,
    comune: "PONDERANO",
    sigla: "BI",
    codice_elettorale: 1010960470,
    codice_istat: 96047,
    codice_belfiore: "G820",
  },
  {
    id: 5259,
    comune: "PONNA",
    sigla: "CO",
    codice_elettorale: 1030241760,
    codice_istat: 13187,
    codice_belfiore: "G821",
  },
  {
    id: 5260,
    comune: "PONSACCO",
    sigla: "PI",
    codice_elettorale: 2090620270,
    codice_istat: 50028,
    codice_belfiore: "G822",
  },
  {
    id: 5261,
    comune: "PONSO",
    sigla: "PD",
    codice_elettorale: 1050540670,
    codice_istat: 28067,
    codice_belfiore: "G823",
  },
  {
    id: 5262,
    comune: "PONT CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010811940,
    codice_istat: 1199,
    codice_belfiore: "G826",
  },
  {
    id: 5263,
    comune: "PONT-SAINT-MARTIN",
    sigla: "AO",
    codice_elettorale: 1020040510,
    codice_istat: 7052,
    codice_belfiore: "G854",
  },
  {
    id: 5264,
    comune: "PONTASSIEVE",
    sigla: "FI",
    codice_elettorale: 2090300330,
    codice_istat: 48033,
    codice_belfiore: "G825",
  },
  {
    id: 5265,
    comune: "PONTBOSET",
    sigla: "AO",
    codice_elettorale: 1020040490,
    codice_istat: 7050,
    codice_belfiore: "G545",
  },
  {
    id: 5266,
    comune: "PONTE",
    sigla: "BN",
    codice_elettorale: 3150110520,
    codice_istat: 62053,
    codice_belfiore: "G827",
  },
  {
    id: 5267,
    comune: "PONTE BUGGIANESE",
    sigla: "PT",
    codice_elettorale: 2090630160,
    codice_istat: 47016,
    codice_belfiore: "G833",
  },
  {
    id: 5268,
    comune: "PONTE DELL'OLIO",
    sigla: "PC",
    codice_elettorale: 1080610360,
    codice_istat: 33036,
    codice_belfiore: "G842",
  },
  {
    id: 5269,
    comune: "PONTE DI LEGNO",
    sigla: "BS",
    codice_elettorale: 1030151390,
    codice_istat: 17148,
    codice_belfiore: "G844",
  },
  {
    id: 5270,
    comune: "PONTE DI PIAVE",
    sigla: "TV",
    codice_elettorale: 1050840570,
    codice_istat: 26058,
    codice_belfiore: "G846",
  },
  {
    id: 5271,
    comune: "PONTE GARDENA/WAIDBRUCK",
    sigla: "BZ",
    codice_elettorale: 1040140620,
    codice_istat: 21065,
    codice_belfiore: "G830",
  },
  {
    id: 5272,
    comune: "PONTE IN VALTELLINA",
    sigla: "SO",
    codice_elettorale: 1030770520,
    codice_istat: 14052,
    codice_belfiore: "G829",
  },
  {
    id: 5273,
    comune: "PONTE LAMBRO",
    sigla: "CO",
    codice_elettorale: 1030241770,
    codice_istat: 13188,
    codice_belfiore: "G847",
  },
  {
    id: 5274,
    comune: "PONTE NELLE ALPI",
    sigla: "BL",
    codice_elettorale: 1050100400,
    codice_istat: 25040,
    codice_belfiore: "B662",
  },
  {
    id: 5275,
    comune: "PONTE NIZZA",
    sigla: "PV",
    codice_elettorale: 1030571140,
    codice_istat: 18117,
    codice_belfiore: "G851",
  },
  {
    id: 5276,
    comune: "PONTE NOSSA",
    sigla: "BG",
    codice_elettorale: 1030121571,
    codice_istat: 16168,
    codice_belfiore: "F941",
  },
  {
    id: 5277,
    comune: "PONTE SAN NICOLO'",
    sigla: "PD",
    codice_elettorale: 1050540690,
    codice_istat: 28069,
    codice_belfiore: "G855",
  },
  {
    id: 5278,
    comune: "PONTE SAN PIETRO",
    sigla: "BG",
    codice_elettorale: 1030121590,
    codice_istat: 16170,
    codice_belfiore: "G856",
  },
  {
    id: 5279,
    comune: "PONTEBBA",
    sigla: "UD",
    codice_elettorale: 1060850760,
    codice_istat: 30076,
    codice_belfiore: "G831",
  },
  {
    id: 5280,
    comune: "PONTECAGNANO FAIANO",
    sigla: "SA",
    codice_elettorale: 3150720990,
    codice_istat: 65099,
    codice_belfiore: "G834",
  },
  {
    id: 5281,
    comune: "PONTECCHIO POLESINE",
    sigla: "RO",
    codice_elettorale: 1050710380,
    codice_istat: 29038,
    codice_belfiore: "G836",
  },
  {
    id: 5282,
    comune: "PONTECHIANALE",
    sigla: "CN",
    codice_elettorale: 1010271720,
    codice_istat: 4172,
    codice_belfiore: "G837",
  },
  {
    id: 5283,
    comune: "PONTECORVO",
    sigla: "FR",
    codice_elettorale: 2120330560,
    codice_istat: 60056,
    codice_belfiore: "G838",
  },
  {
    id: 5284,
    comune: "PONTECURONE",
    sigla: "AL",
    codice_elettorale: 1010021290,
    codice_istat: 6132,
    codice_belfiore: "G839",
  },
  {
    id: 5285,
    comune: "PONTEDASSIO",
    sigla: "IM",
    codice_elettorale: 1070370420,
    codice_istat: 8045,
    codice_belfiore: "G840",
  },
  {
    id: 5286,
    comune: "PONTEDERA",
    sigla: "PI",
    codice_elettorale: 2090620280,
    codice_istat: 50029,
    codice_belfiore: "G843",
  },
  {
    id: 5287,
    comune: "PONTELANDOLFO",
    sigla: "BN",
    codice_elettorale: 3150110530,
    codice_istat: 62054,
    codice_belfiore: "G848",
  },
  {
    id: 5288,
    comune: "PONTELATONE",
    sigla: "CE",
    codice_elettorale: 3150200610,
    codice_istat: 61061,
    codice_belfiore: "G849",
  },
  {
    id: 5289,
    comune: "PONTELONGO",
    sigla: "PD",
    codice_elettorale: 1050540680,
    codice_istat: 28068,
    codice_belfiore: "G850",
  },
  {
    id: 5290,
    comune: "PONTENURE",
    sigla: "PC",
    codice_elettorale: 1080610370,
    codice_istat: 33037,
    codice_belfiore: "G852",
  },
  {
    id: 5291,
    comune: "PONTERANICA",
    sigla: "BG",
    codice_elettorale: 1030121580,
    codice_istat: 16169,
    codice_belfiore: "G853",
  },
  {
    id: 5292,
    comune: "PONTESTURA",
    sigla: "AL",
    codice_elettorale: 1010021300,
    codice_istat: 6133,
    codice_belfiore: "G858",
  },
  {
    id: 5293,
    comune: "PONTEVICO",
    sigla: "BS",
    codice_elettorale: 1030151400,
    codice_istat: 17149,
    codice_belfiore: "G859",
  },
  {
    id: 5294,
    comune: "PONTEY",
    sigla: "AO",
    codice_elettorale: 1020040500,
    codice_istat: 7051,
    codice_belfiore: "G860",
  },
  {
    id: 5295,
    comune: "PONTI",
    sigla: "AL",
    codice_elettorale: 1010021310,
    codice_istat: 6134,
    codice_belfiore: "G861",
  },
  {
    id: 5296,
    comune: "PONTI SUL MINCIO",
    sigla: "MN",
    codice_elettorale: 1030450440,
    codice_istat: 20044,
    codice_belfiore: "G862",
  },
  {
    id: 5297,
    comune: "PONTIDA",
    sigla: "BG",
    codice_elettorale: 1030121600,
    codice_istat: 16171,
    codice_belfiore: "G864",
  },
  {
    id: 5298,
    comune: "PONTINIA",
    sigla: "LT",
    codice_elettorale: 2120400170,
    codice_istat: 59017,
    codice_belfiore: "G865",
  },
  {
    id: 5299,
    comune: "PONTINVREA",
    sigla: "SV",
    codice_elettorale: 1070740510,
    codice_istat: 9051,
    codice_belfiore: "G866",
  },
  {
    id: 5300,
    comune: "PONTIROLO NUOVO",
    sigla: "BG",
    codice_elettorale: 1030121610,
    codice_istat: 16172,
    codice_belfiore: "G867",
  },
  {
    id: 5301,
    comune: "PONTOGLIO",
    sigla: "BS",
    codice_elettorale: 1030151410,
    codice_istat: 17150,
    codice_belfiore: "G869",
  },
  {
    id: 5302,
    comune: "PONTREMOLI",
    sigla: "MS",
    codice_elettorale: 2090460140,
    codice_istat: 45014,
    codice_belfiore: "G870",
  },
  {
    id: 5303,
    comune: "PONZA",
    sigla: "LT",
    codice_elettorale: 2120400180,
    codice_istat: 59018,
    codice_belfiore: "G871",
  },
  {
    id: 5304,
    comune: "PONZANO DI FERMO",
    sigla: "FM",
    codice_elettorale: 2111050320,
    codice_istat: 109032,
    codice_belfiore: "G873",
  },
  {
    id: 5305,
    comune: "PONZANO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021320,
    codice_istat: 6135,
    codice_belfiore: "G872",
  },
  {
    id: 5306,
    comune: "PONZANO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700790,
    codice_istat: 58080,
    codice_belfiore: "G874",
  },
  {
    id: 5307,
    comune: "PONZANO VENETO",
    sigla: "TV",
    codice_elettorale: 1050840580,
    codice_istat: 26059,
    codice_belfiore: "G875",
  },
  {
    id: 5308,
    comune: "PONZONE",
    sigla: "AL",
    codice_elettorale: 1010021330,
    codice_istat: 6136,
    codice_belfiore: "G877",
  },
  {
    id: 5309,
    comune: "POPOLI",
    sigla: "PE",
    codice_elettorale: 3130600330,
    codice_istat: 68033,
    codice_belfiore: "G878",
  },
  {
    id: 5310,
    comune: "POPPI",
    sigla: "AR",
    codice_elettorale: 2090050310,
    codice_istat: 51031,
    codice_belfiore: "G879",
  },
  {
    id: 5311,
    comune: "PORANO",
    sigla: "TR",
    codice_elettorale: 2100800280,
    codice_istat: 55028,
    codice_belfiore: "G881",
  },
  {
    id: 5312,
    comune: "PORCARI",
    sigla: "LU",
    codice_elettorale: 2090430260,
    codice_istat: 46026,
    codice_belfiore: "G882",
  },
  {
    id: 5313,
    comune: "PORCIA",
    sigla: "PN",
    codice_elettorale: 1060930320,
    codice_istat: 93032,
    codice_belfiore: "G886",
  },
  {
    id: 5314,
    comune: "PORDENONE",
    sigla: "PN",
    codice_elettorale: 1060930330,
    codice_istat: 93033,
    codice_belfiore: "G888",
  },
  {
    id: 5315,
    comune: "PORLEZZA",
    sigla: "CO",
    codice_elettorale: 1030241780,
    codice_istat: 13189,
    codice_belfiore: "G889",
  },
  {
    id: 5316,
    comune: "PORNASSIO",
    sigla: "IM",
    codice_elettorale: 1070370430,
    codice_istat: 8046,
    codice_belfiore: "G890",
  },
  {
    id: 5317,
    comune: "PORPETTO",
    sigla: "UD",
    codice_elettorale: 1060850770,
    codice_istat: 30077,
    codice_belfiore: "G891",
  },
  {
    id: 5318,
    comune: "PORTACOMARO",
    sigla: "AT",
    codice_elettorale: 1010070870,
    codice_istat: 5087,
    codice_belfiore: "G894",
  },
  {
    id: 5319,
    comune: "PORTALBERA",
    sigla: "PV",
    codice_elettorale: 1030571150,
    codice_istat: 18118,
    codice_belfiore: "G895",
  },
  {
    id: 5320,
    comune: "PORTE",
    sigla: "TO",
    codice_elettorale: 1010811950,
    codice_istat: 1200,
    codice_belfiore: "G900",
  },
  {
    id: 5321,
    comune: "PORTE DI RENDENA",
    sigla: "TN",
    codice_elettorale: 1040831335,
    codice_istat: 22244,
    codice_belfiore: "M358",
  },
  {
    id: 5322,
    comune: "PORTICI",
    sigla: "NA",
    codice_elettorale: 3150510590,
    codice_istat: 63059,
    codice_belfiore: "G902",
  },
  {
    id: 5323,
    comune: "PORTICO DI CASERTA",
    sigla: "CE",
    codice_elettorale: 3150200620,
    codice_istat: 61062,
    codice_belfiore: "G903",
  },
  {
    id: 5324,
    comune: "PORTICO E SAN BENEDETTO",
    sigla: "FC",
    codice_elettorale: 1080320300,
    codice_istat: 40031,
    codice_belfiore: "G904",
  },
  {
    id: 5325,
    comune: "PORTIGLIOLA",
    sigla: "RC",
    codice_elettorale: 3180670620,
    codice_istat: 80062,
    codice_belfiore: "G905",
  },
  {
    id: 5326,
    comune: "PORTO AZZURRO",
    sigla: "LI",
    codice_elettorale: 2090420130,
    codice_istat: 49013,
    codice_belfiore: "E680",
  },
  {
    id: 5327,
    comune: "PORTO CERESIO",
    sigla: "VA",
    codice_elettorale: 1030860970,
    codice_istat: 12113,
    codice_belfiore: "G906",
  },
  {
    id: 5328,
    comune: "PORTO CESAREO",
    sigla: "LE",
    codice_elettorale: 3160410601,
    codice_istat: 75097,
    codice_belfiore: "M263",
  },
  {
    id: 5329,
    comune: "PORTO EMPEDOCLE",
    sigla: "AG",
    codice_elettorale: 4190010280,
    codice_istat: 84028,
    codice_belfiore: "F299",
  },
  {
    id: 5330,
    comune: "PORTO MANTOVANO",
    sigla: "MN",
    codice_elettorale: 1030450450,
    codice_istat: 20045,
    codice_belfiore: "G917",
  },
  {
    id: 5331,
    comune: "PORTO RECANATI",
    sigla: "MC",
    codice_elettorale: 2110440420,
    codice_istat: 43042,
    codice_belfiore: "G919",
  },
  {
    id: 5332,
    comune: "PORTO SAN GIORGIO",
    sigla: "FM",
    codice_elettorale: 2111050330,
    codice_istat: 109033,
    codice_belfiore: "G920",
  },
  {
    id: 5333,
    comune: "PORTO SANT'ELPIDIO",
    sigla: "FM",
    codice_elettorale: 2111050340,
    codice_istat: 109034,
    codice_belfiore: "G921",
  },
  {
    id: 5334,
    comune: "PORTO TOLLE",
    sigla: "RO",
    codice_elettorale: 1050710390,
    codice_istat: 29039,
    codice_belfiore: "G923",
  },
  {
    id: 5335,
    comune: "PORTO TORRES",
    sigla: "SS",
    codice_elettorale: 4200730570,
    codice_istat: 90058,
    codice_belfiore: "G924",
  },
  {
    id: 5336,
    comune: "PORTO VALTRAVAGLIA",
    sigla: "VA",
    codice_elettorale: 1030860980,
    codice_istat: 12114,
    codice_belfiore: "G907",
  },
  {
    id: 5337,
    comune: "PORTO VIRO",
    sigla: "RO",
    codice_elettorale: 1050710391,
    codice_istat: 29052,
    codice_belfiore: "G926",
  },
  {
    id: 5338,
    comune: "PORTOBUFFOLE'",
    sigla: "TV",
    codice_elettorale: 1050840590,
    codice_istat: 26060,
    codice_belfiore: "G909",
  },
  {
    id: 5339,
    comune: "PORTOCANNONE",
    sigla: "CB",
    codice_elettorale: 3140190550,
    codice_istat: 70055,
    codice_belfiore: "G910",
  },
  {
    id: 5340,
    comune: "PORTOFERRAIO",
    sigla: "LI",
    codice_elettorale: 2090420140,
    codice_istat: 49014,
    codice_belfiore: "G912",
  },
  {
    id: 5341,
    comune: "PORTOFINO",
    sigla: "GE",
    codice_elettorale: 1070340440,
    codice_istat: 10044,
    codice_belfiore: "G913",
  },
  {
    id: 5342,
    comune: "PORTOGRUARO",
    sigla: "VE",
    codice_elettorale: 1050870290,
    codice_istat: 27029,
    codice_belfiore: "G914",
  },
  {
    id: 5343,
    comune: "PORTOMAGGIORE",
    sigla: "FE",
    codice_elettorale: 1080290170,
    codice_istat: 38019,
    codice_belfiore: "G916",
  },
  {
    id: 5344,
    comune: "PORTOPALO DI CAPO PASSERO",
    sigla: "SR",
    codice_elettorale: 4190760151,
    codice_istat: 89020,
    codice_belfiore: "M257",
  },
  {
    id: 5345,
    comune: "PORTOSCUSO",
    sigla: "SU",
    codice_elettorale: 4201110570,
    codice_istat: 111057,
    codice_belfiore: "G922",
  },
  {
    id: 5346,
    comune: "PORTOVENERE",
    sigla: "SP",
    codice_elettorale: 1070390220,
    codice_istat: 11022,
    codice_belfiore: "G925",
  },
  {
    id: 5347,
    comune: "PORTULA",
    sigla: "BI",
    codice_elettorale: 1010960480,
    codice_istat: 96048,
    codice_belfiore: "G927",
  },
  {
    id: 5348,
    comune: "POSADA",
    sigla: "NU",
    codice_elettorale: 4200530710,
    codice_istat: 91073,
    codice_belfiore: "G929",
  },
  {
    id: 5349,
    comune: "POSINA",
    sigla: "VI",
    codice_elettorale: 1050900800,
    codice_istat: 24080,
    codice_belfiore: "G931",
  },
  {
    id: 5350,
    comune: "POSITANO",
    sigla: "SA",
    codice_elettorale: 3150721000,
    codice_istat: 65100,
    codice_belfiore: "G932",
  },
  {
    id: 5351,
    comune: "POSSAGNO",
    sigla: "TV",
    codice_elettorale: 1050840600,
    codice_istat: 26061,
    codice_belfiore: "G933",
  },
  {
    id: 5352,
    comune: "POSTA",
    sigla: "RI",
    codice_elettorale: 2120690550,
    codice_istat: 57057,
    codice_belfiore: "G934",
  },
  {
    id: 5353,
    comune: "POSTA FIBRENO",
    sigla: "FR",
    codice_elettorale: 2120330561,
    codice_istat: 60057,
    codice_belfiore: "G935",
  },
  {
    id: 5354,
    comune: "POSTAL/BURGSTALL",
    sigla: "BZ",
    codice_elettorale: 1040140630,
    codice_istat: 21066,
    codice_belfiore: "G936",
  },
  {
    id: 5355,
    comune: "POSTALESIO",
    sigla: "SO",
    codice_elettorale: 1030770530,
    codice_istat: 14053,
    codice_belfiore: "G937",
  },
  {
    id: 5356,
    comune: "POSTIGLIONE",
    sigla: "SA",
    codice_elettorale: 3150721010,
    codice_istat: 65101,
    codice_belfiore: "G939",
  },
  {
    id: 5357,
    comune: "POSTUA",
    sigla: "VC",
    codice_elettorale: 1010881010,
    codice_istat: 2102,
    codice_belfiore: "G940",
  },
  {
    id: 5358,
    comune: "POTENZA",
    sigla: "PZ",
    codice_elettorale: 3170640620,
    codice_istat: 76063,
    codice_belfiore: "G942",
  },
  {
    id: 5359,
    comune: "POTENZA PICENA",
    sigla: "MC",
    codice_elettorale: 2110440430,
    codice_istat: 43043,
    codice_belfiore: "F632",
  },
  {
    id: 5360,
    comune: "POVE DEL GRAPPA",
    sigla: "VI",
    codice_elettorale: 1050900810,
    codice_istat: 24081,
    codice_belfiore: "G943",
  },
  {
    id: 5361,
    comune: "POVEGLIANO",
    sigla: "TV",
    codice_elettorale: 1050840610,
    codice_istat: 26062,
    codice_belfiore: "G944",
  },
  {
    id: 5362,
    comune: "POVEGLIANO VERONESE",
    sigla: "VR",
    codice_elettorale: 1050890590,
    codice_istat: 23060,
    codice_belfiore: "G945",
  },
  {
    id: 5363,
    comune: "POVIGLIO",
    sigla: "RE",
    codice_elettorale: 1080680290,
    codice_istat: 35029,
    codice_belfiore: "G947",
  },
  {
    id: 5364,
    comune: "POVOLETTO",
    sigla: "UD",
    codice_elettorale: 1060850780,
    codice_istat: 30078,
    codice_belfiore: "G949",
  },
  {
    id: 5365,
    comune: "POZZAGLIA SABINA",
    sigla: "RI",
    codice_elettorale: 2120690560,
    codice_istat: 57058,
    codice_belfiore: "G951",
  },
  {
    id: 5366,
    comune: "POZZAGLIO ED UNITI",
    sigla: "CR",
    codice_elettorale: 1030260750,
    codice_istat: 19077,
    codice_belfiore: "B914",
  },
  {
    id: 5367,
    comune: "POZZALLO",
    sigla: "RG",
    codice_elettorale: 4190650080,
    codice_istat: 88008,
    codice_belfiore: "G953",
  },
  {
    id: 5368,
    comune: "POZZILLI",
    sigla: "IS",
    codice_elettorale: 3140940380,
    codice_istat: 94038,
    codice_belfiore: "G954",
  },
  {
    id: 5369,
    comune: "POZZO D'ADDA",
    sigla: "MI",
    codice_elettorale: 1030491760,
    codice_istat: 15177,
    codice_belfiore: "G955",
  },
  {
    id: 5370,
    comune: "POZZOL GROPPO",
    sigla: "AL",
    codice_elettorale: 1010021340,
    codice_istat: 6137,
    codice_belfiore: "G960",
  },
  {
    id: 5371,
    comune: "POZZOLENGO",
    sigla: "BS",
    codice_elettorale: 1030151420,
    codice_istat: 17151,
    codice_belfiore: "G959",
  },
  {
    id: 5372,
    comune: "POZZOLEONE",
    sigla: "VI",
    codice_elettorale: 1050900820,
    codice_istat: 24082,
    codice_belfiore: "G957",
  },
  {
    id: 5373,
    comune: "POZZOLO FORMIGARO",
    sigla: "AL",
    codice_elettorale: 1010021350,
    codice_istat: 6138,
    codice_belfiore: "G961",
  },
  {
    id: 5374,
    comune: "POZZOMAGGIORE",
    sigla: "SS",
    codice_elettorale: 4200730580,
    codice_istat: 90059,
    codice_belfiore: "G962",
  },
  {
    id: 5375,
    comune: "POZZONOVO",
    sigla: "PD",
    codice_elettorale: 1050540700,
    codice_istat: 28070,
    codice_belfiore: "G963",
  },
  {
    id: 5376,
    comune: "POZZUOLI",
    sigla: "NA",
    codice_elettorale: 3150510600,
    codice_istat: 63060,
    codice_belfiore: "G964",
  },
  {
    id: 5377,
    comune: "POZZUOLO DEL FRIULI",
    sigla: "UD",
    codice_elettorale: 1060850790,
    codice_istat: 30079,
    codice_belfiore: "G966",
  },
  {
    id: 5378,
    comune: "POZZUOLO MARTESANA",
    sigla: "MI",
    codice_elettorale: 1030491770,
    codice_istat: 15178,
    codice_belfiore: "G965",
  },
  {
    id: 5379,
    comune: "PRADALUNGA",
    sigla: "BG",
    codice_elettorale: 1030121620,
    codice_istat: 16173,
    codice_belfiore: "G968",
  },
  {
    id: 5380,
    comune: "PRADAMANO",
    sigla: "UD",
    codice_elettorale: 1060850800,
    codice_istat: 30080,
    codice_belfiore: "G969",
  },
  {
    id: 5381,
    comune: "PRADLEVES",
    sigla: "CN",
    codice_elettorale: 1010271730,
    codice_istat: 4173,
    codice_belfiore: "G970",
  },
  {
    id: 5382,
    comune: "PRAGELATO",
    sigla: "TO",
    codice_elettorale: 1010811960,
    codice_istat: 1201,
    codice_belfiore: "G973",
  },
  {
    id: 5383,
    comune: "PRAIA A MARE",
    sigla: "CS",
    codice_elettorale: 3180251020,
    codice_istat: 78101,
    codice_belfiore: "G975",
  },
  {
    id: 5384,
    comune: "PRAIANO",
    sigla: "SA",
    codice_elettorale: 3150721020,
    codice_istat: 65102,
    codice_belfiore: "G976",
  },
  {
    id: 5385,
    comune: "PRALBOINO",
    sigla: "BS",
    codice_elettorale: 1030151430,
    codice_istat: 17152,
    codice_belfiore: "G977",
  },
  {
    id: 5386,
    comune: "PRALI",
    sigla: "TO",
    codice_elettorale: 1010811970,
    codice_istat: 1202,
    codice_belfiore: "G978",
  },
  {
    id: 5387,
    comune: "PRALORMO",
    sigla: "TO",
    codice_elettorale: 1010811980,
    codice_istat: 1203,
    codice_belfiore: "G979",
  },
  {
    id: 5388,
    comune: "PRALUNGO",
    sigla: "BI",
    codice_elettorale: 1010960490,
    codice_istat: 96049,
    codice_belfiore: "G980",
  },
  {
    id: 5389,
    comune: "PRAMAGGIORE",
    sigla: "VE",
    codice_elettorale: 1050870300,
    codice_istat: 27030,
    codice_belfiore: "G981",
  },
  {
    id: 5390,
    comune: "PRAMOLLO",
    sigla: "TO",
    codice_elettorale: 1010811981,
    codice_istat: 1204,
    codice_belfiore: "G982",
  },
  {
    id: 5391,
    comune: "PRAROLO",
    sigla: "VC",
    codice_elettorale: 1010881030,
    codice_istat: 2104,
    codice_belfiore: "G985",
  },
  {
    id: 5392,
    comune: "PRAROSTINO",
    sigla: "TO",
    codice_elettorale: 1010811982,
    codice_istat: 1205,
    codice_belfiore: "G986",
  },
  {
    id: 5393,
    comune: "PRASCO",
    sigla: "AL",
    codice_elettorale: 1010021360,
    codice_istat: 6139,
    codice_belfiore: "G987",
  },
  {
    id: 5394,
    comune: "PRASCORSANO",
    sigla: "TO",
    codice_elettorale: 1010811990,
    codice_istat: 1206,
    codice_belfiore: "G988",
  },
  {
    id: 5395,
    comune: "PRATA CAMPORTACCIO",
    sigla: "SO",
    codice_elettorale: 1030770540,
    codice_istat: 14054,
    codice_belfiore: "G993",
  },
  {
    id: 5396,
    comune: "PRATA D'ANSIDONIA",
    sigla: "AQ",
    codice_elettorale: 3130380740,
    codice_istat: 66074,
    codice_belfiore: "G992",
  },
  {
    id: 5397,
    comune: "PRATA DI PORDENONE",
    sigla: "PN",
    codice_elettorale: 1060930340,
    codice_istat: 93034,
    codice_belfiore: "G994",
  },
  {
    id: 5398,
    comune: "PRATA DI PRINCIPATO ULTRA",
    sigla: "AV",
    codice_elettorale: 3150080730,
    codice_istat: 64074,
    codice_belfiore: "G990",
  },
  {
    id: 5399,
    comune: "PRATA SANNITA",
    sigla: "CE",
    codice_elettorale: 3150200630,
    codice_istat: 61063,
    codice_belfiore: "G991",
  },
  {
    id: 5400,
    comune: "PRATELLA",
    sigla: "CE",
    codice_elettorale: 3150200640,
    codice_istat: 61064,
    codice_belfiore: "G995",
  },
  {
    id: 5401,
    comune: "PRATIGLIONE",
    sigla: "TO",
    codice_elettorale: 1010812000,
    codice_istat: 1207,
    codice_belfiore: "G997",
  },
  {
    id: 5402,
    comune: "PRATO",
    sigla: "PO",
    codice_elettorale: 2091000050,
    codice_istat: 100005,
    codice_belfiore: "G999",
  },
  {
    id: 5403,
    comune: "PRATO ALLO STELVIO/PRAD AM STILFSER JOCH",
    sigla: "BZ",
    codice_elettorale: 1040140640,
    codice_istat: 21067,
    codice_belfiore: "H004",
  },
  {
    id: 5404,
    comune: "PRATO CARNICO",
    sigla: "UD",
    codice_elettorale: 1060850810,
    codice_istat: 30081,
    codice_belfiore: "H002",
  },
  {
    id: 5405,
    comune: "PRATO SESIA",
    sigla: "NO",
    codice_elettorale: 1010521160,
    codice_istat: 3122,
    codice_belfiore: "H001",
  },
  {
    id: 5406,
    comune: "PRATOLA PELIGNA",
    sigla: "AQ",
    codice_elettorale: 3130380750,
    codice_istat: 66075,
    codice_belfiore: "H007",
  },
  {
    id: 5407,
    comune: "PRATOLA SERRA",
    sigla: "AV",
    codice_elettorale: 3150080740,
    codice_istat: 64075,
    codice_belfiore: "H006",
  },
  {
    id: 5408,
    comune: "PRATOVECCHIO STIA",
    sigla: "AR",
    codice_elettorale: 2090050321,
    codice_istat: 51041,
    codice_belfiore: "M329",
  },
  {
    id: 5409,
    comune: "PRAVISDOMINI",
    sigla: "PN",
    codice_elettorale: 1060930350,
    codice_istat: 93035,
    codice_belfiore: "H010",
  },
  {
    id: 5410,
    comune: "PRAY",
    sigla: "BI",
    codice_elettorale: 1010960500,
    codice_istat: 96050,
    codice_belfiore: "G974",
  },
  {
    id: 5411,
    comune: "PRAZZO",
    sigla: "CN",
    codice_elettorale: 1010271740,
    codice_istat: 4174,
    codice_belfiore: "H011",
  },
  {
    id: 5412,
    comune: "PRE'-SAINT-DIDIER",
    sigla: "AO",
    codice_elettorale: 1020040520,
    codice_istat: 7053,
    codice_belfiore: "H042",
  },
  {
    id: 5413,
    comune: "PRECENICCO",
    sigla: "UD",
    codice_elettorale: 1060850820,
    codice_istat: 30082,
    codice_belfiore: "H014",
  },
  {
    id: 5414,
    comune: "PRECI",
    sigla: "PG",
    codice_elettorale: 2100580430,
    codice_istat: 54043,
    codice_belfiore: "H015",
  },
  {
    id: 5415,
    comune: "PREDAIA",
    sigla: "TN",
    codice_elettorale: 1040831355,
    codice_istat: 22230,
    codice_belfiore: "M344",
  },
  {
    id: 5416,
    comune: "PREDAPPIO",
    sigla: "FC",
    codice_elettorale: 1080320310,
    codice_istat: 40032,
    codice_belfiore: "H017",
  },
  {
    id: 5417,
    comune: "PREDAZZO",
    sigla: "TN",
    codice_elettorale: 1040831360,
    codice_istat: 22147,
    codice_belfiore: "H018",
  },
  {
    id: 5418,
    comune: "PREDOI/PRETTAU",
    sigla: "BZ",
    codice_elettorale: 1040140641,
    codice_istat: 21068,
    codice_belfiore: "H019",
  },
  {
    id: 5419,
    comune: "PREDORE",
    sigla: "BG",
    codice_elettorale: 1030121630,
    codice_istat: 16174,
    codice_belfiore: "H020",
  },
  {
    id: 5420,
    comune: "PREDOSA",
    sigla: "AL",
    codice_elettorale: 1010021370,
    codice_istat: 6140,
    codice_belfiore: "H021",
  },
  {
    id: 5421,
    comune: "PREGANZIOL",
    sigla: "TV",
    codice_elettorale: 1050840620,
    codice_istat: 26063,
    codice_belfiore: "H022",
  },
  {
    id: 5422,
    comune: "PREGNANA MILANESE",
    sigla: "MI",
    codice_elettorale: 1030491780,
    codice_istat: 15179,
    codice_belfiore: "H026",
  },
  {
    id: 5423,
    comune: "PRELA'",
    sigla: "IM",
    codice_elettorale: 1070370440,
    codice_istat: 8047,
    codice_belfiore: "H027",
  },
  {
    id: 5424,
    comune: "PREMANA",
    sigla: "LC",
    codice_elettorale: 1030980690,
    codice_istat: 97069,
    codice_belfiore: "H028",
  },
  {
    id: 5425,
    comune: "PREMARIACCO",
    sigla: "UD",
    codice_elettorale: 1060850830,
    codice_istat: 30083,
    codice_belfiore: "H029",
  },
  {
    id: 5426,
    comune: "PREMENO",
    sigla: "VB",
    codice_elettorale: 1011020550,
    codice_istat: 103055,
    codice_belfiore: "H030",
  },
  {
    id: 5427,
    comune: "PREMIA",
    sigla: "VB",
    codice_elettorale: 1011020560,
    codice_istat: 103056,
    codice_belfiore: "H033",
  },
  {
    id: 5428,
    comune: "PREMILCUORE",
    sigla: "FC",
    codice_elettorale: 1080320320,
    codice_istat: 40033,
    codice_belfiore: "H034",
  },
  {
    id: 5429,
    comune: "PREMOLO",
    sigla: "BG",
    codice_elettorale: 1030121640,
    codice_istat: 16175,
    codice_belfiore: "H036",
  },
  {
    id: 5430,
    comune: "PREMOSELLO-CHIOVENDA",
    sigla: "VB",
    codice_elettorale: 1011020570,
    codice_istat: 103057,
    codice_belfiore: "H037",
  },
  {
    id: 5431,
    comune: "PREONE",
    sigla: "UD",
    codice_elettorale: 1060850840,
    codice_istat: 30084,
    codice_belfiore: "H038",
  },
  {
    id: 5432,
    comune: "PREPOTTO",
    sigla: "UD",
    codice_elettorale: 1060850850,
    codice_istat: 30085,
    codice_belfiore: "H040",
  },
  {
    id: 5433,
    comune: "PRESEGLIE",
    sigla: "BS",
    codice_elettorale: 1030151440,
    codice_istat: 17153,
    codice_belfiore: "H043",
  },
  {
    id: 5434,
    comune: "PRESENZANO",
    sigla: "CE",
    codice_elettorale: 3150200650,
    codice_istat: 61065,
    codice_belfiore: "H045",
  },
  {
    id: 5435,
    comune: "PRESEZZO",
    sigla: "BG",
    codice_elettorale: 1030121650,
    codice_istat: 16176,
    codice_belfiore: "H046",
  },
  {
    id: 5436,
    comune: "PRESICCE-ACQUARICA",
    sigla: "LE",
    codice_elettorale: 3160410611,
    codice_istat: 75098,
    codice_belfiore: "M428",
  },
  {
    id: 5437,
    comune: "PRESSANA",
    sigla: "VR",
    codice_elettorale: 1050890600,
    codice_istat: 23061,
    codice_belfiore: "H048",
  },
  {
    id: 5438,
    comune: "PRETORO",
    sigla: "CH",
    codice_elettorale: 3130230690,
    codice_istat: 69069,
    codice_belfiore: "H052",
  },
  {
    id: 5439,
    comune: "PREVALLE",
    sigla: "BS",
    codice_elettorale: 1030151460,
    codice_istat: 17155,
    codice_belfiore: "H055",
  },
  {
    id: 5440,
    comune: "PREZZA",
    sigla: "AQ",
    codice_elettorale: 3130380760,
    codice_istat: 66076,
    codice_belfiore: "H056",
  },
  {
    id: 5441,
    comune: "PRIERO",
    sigla: "CN",
    codice_elettorale: 1010271750,
    codice_istat: 4175,
    codice_belfiore: "H059",
  },
  {
    id: 5442,
    comune: "PRIGNANO CILENTO",
    sigla: "SA",
    codice_elettorale: 3150721030,
    codice_istat: 65103,
    codice_belfiore: "H062",
  },
  {
    id: 5443,
    comune: "PRIGNANO SULLA SECCHIA",
    sigla: "MO",
    codice_elettorale: 1080500320,
    codice_istat: 36033,
    codice_belfiore: "H061",
  },
  {
    id: 5444,
    comune: "PRIMALUNA",
    sigla: "LC",
    codice_elettorale: 1030980700,
    codice_istat: 97070,
    codice_belfiore: "H063",
  },
  {
    id: 5445,
    comune: "PRIMIERO SAN MARTINO DI CASTROZZA",
    sigla: "TN",
    codice_elettorale: 1040831375,
    codice_istat: 22245,
    codice_belfiore: "M359",
  },
  {
    id: 5446,
    comune: "PRIOCCA",
    sigla: "CN",
    codice_elettorale: 1010271760,
    codice_istat: 4176,
    codice_belfiore: "H068",
  },
  {
    id: 5447,
    comune: "PRIOLA",
    sigla: "CN",
    codice_elettorale: 1010271770,
    codice_istat: 4177,
    codice_belfiore: "H069",
  },
  {
    id: 5448,
    comune: "PRIOLO GARGALLO",
    sigla: "SR",
    codice_elettorale: 4190760152,
    codice_istat: 89021,
    codice_belfiore: "M279",
  },
  {
    id: 5449,
    comune: "PRIVERNO",
    sigla: "LT",
    codice_elettorale: 2120400190,
    codice_istat: 59019,
    codice_belfiore: "G698",
  },
  {
    id: 5450,
    comune: "PRIZZI",
    sigla: "PA",
    codice_elettorale: 4190550580,
    codice_istat: 82060,
    codice_belfiore: "H070",
  },
  {
    id: 5451,
    comune: "PROCENO",
    sigla: "VT",
    codice_elettorale: 2120910430,
    codice_istat: 56044,
    codice_belfiore: "H071",
  },
  {
    id: 5452,
    comune: "PROCIDA",
    sigla: "NA",
    codice_elettorale: 3150510610,
    codice_istat: 63061,
    codice_belfiore: "H072",
  },
  {
    id: 5453,
    comune: "PROPATA",
    sigla: "GE",
    codice_elettorale: 1070340450,
    codice_istat: 10045,
    codice_belfiore: "H073",
  },
  {
    id: 5454,
    comune: "PROSERPIO",
    sigla: "CO",
    codice_elettorale: 1030241810,
    codice_istat: 13192,
    codice_belfiore: "H074",
  },
  {
    id: 5455,
    comune: "PROSSEDI",
    sigla: "LT",
    codice_elettorale: 2120400200,
    codice_istat: 59020,
    codice_belfiore: "H076",
  },
  {
    id: 5456,
    comune: "PROVAGLIO D'ISEO",
    sigla: "BS",
    codice_elettorale: 1030151470,
    codice_istat: 17156,
    codice_belfiore: "H078",
  },
  {
    id: 5457,
    comune: "PROVAGLIO VAL SABBIA",
    sigla: "BS",
    codice_elettorale: 1030151480,
    codice_istat: 17157,
    codice_belfiore: "H077",
  },
  {
    id: 5458,
    comune: "PROVES/PROVEIS",
    sigla: "BZ",
    codice_elettorale: 1040140650,
    codice_istat: 21069,
    codice_belfiore: "H081",
  },
  {
    id: 5459,
    comune: "PROVVIDENTI",
    sigla: "CB",
    codice_elettorale: 3140190560,
    codice_istat: 70056,
    codice_belfiore: "H083",
  },
  {
    id: 5460,
    comune: "PRUNETTO",
    sigla: "CN",
    codice_elettorale: 1010271780,
    codice_istat: 4178,
    codice_belfiore: "H085",
  },
  {
    id: 5461,
    comune: "PUEGNAGO DEL GARDA",
    sigla: "BS",
    codice_elettorale: 1030151490,
    codice_istat: 17158,
    codice_belfiore: "H086",
  },
  {
    id: 5462,
    comune: "PUGLIANELLO",
    sigla: "BN",
    codice_elettorale: 3150110540,
    codice_istat: 62055,
    codice_belfiore: "H087",
  },
  {
    id: 5463,
    comune: "PULA",
    sigla: "CA",
    codice_elettorale: 4200170500,
    codice_istat: 92050,
    codice_belfiore: "H088",
  },
  {
    id: 5464,
    comune: "PULFERO",
    sigla: "UD",
    codice_elettorale: 1060850860,
    codice_istat: 30086,
    codice_belfiore: "H089",
  },
  {
    id: 5465,
    comune: "PULSANO",
    sigla: "TA",
    codice_elettorale: 3160780220,
    codice_istat: 73022,
    codice_belfiore: "H090",
  },
  {
    id: 5466,
    comune: "PUMENENGO",
    sigla: "BG",
    codice_elettorale: 1030121660,
    codice_istat: 16177,
    codice_belfiore: "H091",
  },
  {
    id: 5467,
    comune: "PUSIANO",
    sigla: "CO",
    codice_elettorale: 1030241820,
    codice_istat: 13193,
    codice_belfiore: "H094",
  },
  {
    id: 5468,
    comune: "PUTIFIGARI",
    sigla: "SS",
    codice_elettorale: 4200730590,
    codice_istat: 90060,
    codice_belfiore: "H095",
  },
  {
    id: 5469,
    comune: "PUTIGNANO",
    sigla: "BA",
    codice_elettorale: 3160090350,
    codice_istat: 72036,
    codice_belfiore: "H096",
  },
  {
    id: 5470,
    comune: "QUADRELLE",
    sigla: "AV",
    codice_elettorale: 3150080750,
    codice_istat: 64076,
    codice_belfiore: "H097",
  },
  {
    id: 5471,
    comune: "QUADRI",
    sigla: "CH",
    codice_elettorale: 3130230700,
    codice_istat: 69070,
    codice_belfiore: "H098",
  },
  {
    id: 5472,
    comune: "QUAGLIUZZO",
    sigla: "TO",
    codice_elettorale: 1010812010,
    codice_istat: 1208,
    codice_belfiore: "H100",
  },
  {
    id: 5473,
    comune: "QUALIANO",
    sigla: "NA",
    codice_elettorale: 3150510620,
    codice_istat: 63062,
    codice_belfiore: "H101",
  },
  {
    id: 5474,
    comune: "QUARANTI",
    sigla: "AT",
    codice_elettorale: 1010070880,
    codice_istat: 5088,
    codice_belfiore: "H102",
  },
  {
    id: 5475,
    comune: "QUAREGNA CERRETO",
    sigla: "BI",
    codice_elettorale: 1010960515,
    codice_istat: 96087,
    codice_belfiore: "M414",
  },
  {
    id: 5476,
    comune: "QUARGNENTO",
    sigla: "AL",
    codice_elettorale: 1010021380,
    codice_istat: 6141,
    codice_belfiore: "H104",
  },
  {
    id: 5477,
    comune: "QUARNA SOPRA",
    sigla: "VB",
    codice_elettorale: 1011020580,
    codice_istat: 103058,
    codice_belfiore: "H106",
  },
  {
    id: 5478,
    comune: "QUARNA SOTTO",
    sigla: "VB",
    codice_elettorale: 1011020590,
    codice_istat: 103059,
    codice_belfiore: "H107",
  },
  {
    id: 5479,
    comune: "QUARONA",
    sigla: "VC",
    codice_elettorale: 1010881060,
    codice_istat: 2107,
    codice_belfiore: "H108",
  },
  {
    id: 5480,
    comune: "QUARRATA",
    sigla: "PT",
    codice_elettorale: 2090630161,
    codice_istat: 47017,
    codice_belfiore: "H109",
  },
  {
    id: 5481,
    comune: "QUART",
    sigla: "AO",
    codice_elettorale: 1020040530,
    codice_istat: 7054,
    codice_belfiore: "H110",
  },
  {
    id: 5482,
    comune: "QUARTO",
    sigla: "NA",
    codice_elettorale: 3150510630,
    codice_istat: 63063,
    codice_belfiore: "H114",
  },
  {
    id: 5483,
    comune: "QUARTO D'ALTINO",
    sigla: "VE",
    codice_elettorale: 1050870310,
    codice_istat: 27031,
    codice_belfiore: "H117",
  },
  {
    id: 5484,
    comune: "QUARTU SANT'ELENA",
    sigla: "CA",
    codice_elettorale: 4200170510,
    codice_istat: 92051,
    codice_belfiore: "H118",
  },
  {
    id: 5485,
    comune: "QUARTUCCIU",
    sigla: "CA",
    codice_elettorale: 4200170501,
    codice_istat: 92105,
    codice_belfiore: "H119",
  },
  {
    id: 5486,
    comune: "QUASSOLO",
    sigla: "TO",
    codice_elettorale: 1010812011,
    codice_istat: 1209,
    codice_belfiore: "H120",
  },
  {
    id: 5487,
    comune: "QUATTORDIO",
    sigla: "AL",
    codice_elettorale: 1010021390,
    codice_istat: 6142,
    codice_belfiore: "H121",
  },
  {
    id: 5488,
    comune: "QUATTRO CASTELLA",
    sigla: "RE",
    codice_elettorale: 1080680300,
    codice_istat: 35030,
    codice_belfiore: "H122",
  },
  {
    id: 5489,
    comune: "QUERO VAS",
    sigla: "BL",
    codice_elettorale: 1050100421,
    codice_istat: 25070,
    codice_belfiore: "M332",
  },
  {
    id: 5490,
    comune: "QUILIANO",
    sigla: "SV",
    codice_elettorale: 1070740520,
    codice_istat: 9052,
    codice_belfiore: "H126",
  },
  {
    id: 5491,
    comune: "QUINCINETTO",
    sigla: "TO",
    codice_elettorale: 1010812020,
    codice_istat: 1210,
    codice_belfiore: "H127",
  },
  {
    id: 5492,
    comune: "QUINDICI",
    sigla: "AV",
    codice_elettorale: 3150080760,
    codice_istat: 64077,
    codice_belfiore: "H128",
  },
  {
    id: 5493,
    comune: "QUINGENTOLE",
    sigla: "MN",
    codice_elettorale: 1030450460,
    codice_istat: 20046,
    codice_belfiore: "H129",
  },
  {
    id: 5494,
    comune: "QUINTANO",
    sigla: "CR",
    codice_elettorale: 1030260760,
    codice_istat: 19078,
    codice_belfiore: "H130",
  },
  {
    id: 5495,
    comune: "QUINTO DI TREVISO",
    sigla: "TV",
    codice_elettorale: 1050840630,
    codice_istat: 26064,
    codice_belfiore: "H131",
  },
  {
    id: 5496,
    comune: "QUINTO VERCELLESE",
    sigla: "VC",
    codice_elettorale: 1010881070,
    codice_istat: 2108,
    codice_belfiore: "H132",
  },
  {
    id: 5497,
    comune: "QUINTO VICENTINO",
    sigla: "VI",
    codice_elettorale: 1050900830,
    codice_istat: 24083,
    codice_belfiore: "H134",
  },
  {
    id: 5498,
    comune: "QUINZANO D'OGLIO",
    sigla: "BS",
    codice_elettorale: 1030151500,
    codice_istat: 17159,
    codice_belfiore: "H140",
  },
  {
    id: 5499,
    comune: "QUISTELLO",
    sigla: "MN",
    codice_elettorale: 1030450470,
    codice_istat: 20047,
    codice_belfiore: "H143",
  },
  {
    id: 5500,
    comune: "RABBI",
    sigla: "TN",
    codice_elettorale: 1040831390,
    codice_istat: 22150,
    codice_belfiore: "H146",
  },
  {
    id: 5501,
    comune: "RACALE",
    sigla: "LE",
    codice_elettorale: 3160410620,
    codice_istat: 75063,
    codice_belfiore: "H147",
  },
  {
    id: 5502,
    comune: "RACALMUTO",
    sigla: "AG",
    codice_elettorale: 4190010290,
    codice_istat: 84029,
    codice_belfiore: "H148",
  },
  {
    id: 5503,
    comune: "RACCONIGI",
    sigla: "CN",
    codice_elettorale: 1010271790,
    codice_istat: 4179,
    codice_belfiore: "H150",
  },
  {
    id: 5504,
    comune: "RACCUJA",
    sigla: "ME",
    codice_elettorale: 4190480680,
    codice_istat: 83069,
    codice_belfiore: "H151",
  },
  {
    id: 5505,
    comune: "RACINES/RATSCHINGS",
    sigla: "BZ",
    codice_elettorale: 1040140660,
    codice_istat: 21070,
    codice_belfiore: "H152",
  },
  {
    id: 5506,
    comune: "RADDA IN CHIANTI",
    sigla: "SI",
    codice_elettorale: 2090750230,
    codice_istat: 52023,
    codice_belfiore: "H153",
  },
  {
    id: 5507,
    comune: "RADDUSA",
    sigla: "CT",
    codice_elettorale: 4190210350,
    codice_istat: 87036,
    codice_belfiore: "H154",
  },
  {
    id: 5508,
    comune: "RADICOFANI",
    sigla: "SI",
    codice_elettorale: 2090750240,
    codice_istat: 52024,
    codice_belfiore: "H156",
  },
  {
    id: 5509,
    comune: "RADICONDOLI",
    sigla: "SI",
    codice_elettorale: 2090750250,
    codice_istat: 52025,
    codice_belfiore: "H157",
  },
  {
    id: 5510,
    comune: "RAFFADALI",
    sigla: "AG",
    codice_elettorale: 4190010300,
    codice_istat: 84030,
    codice_belfiore: "H159",
  },
  {
    id: 5511,
    comune: "RAGALNA",
    sigla: "CT",
    codice_elettorale: 4190210351,
    codice_istat: 87058,
    codice_belfiore: "M287",
  },
  {
    id: 5512,
    comune: "RAGOGNA",
    sigla: "UD",
    codice_elettorale: 1060850870,
    codice_istat: 30087,
    codice_belfiore: "H161",
  },
  {
    id: 5513,
    comune: "RAGUSA",
    sigla: "RG",
    codice_elettorale: 4190650090,
    codice_istat: 88009,
    codice_belfiore: "H163",
  },
  {
    id: 5514,
    comune: "RAIANO",
    sigla: "AQ",
    codice_elettorale: 3130380770,
    codice_istat: 66077,
    codice_belfiore: "H166",
  },
  {
    id: 5515,
    comune: "RAMACCA",
    sigla: "CT",
    codice_elettorale: 4190210360,
    codice_istat: 87037,
    codice_belfiore: "H168",
  },
  {
    id: 5516,
    comune: "RANCIO VALCUVIA",
    sigla: "VA",
    codice_elettorale: 1030860990,
    codice_istat: 12115,
    codice_belfiore: "H173",
  },
  {
    id: 5517,
    comune: "RANCO",
    sigla: "VA",
    codice_elettorale: 1030860992,
    codice_istat: 12116,
    codice_belfiore: "H174",
  },
  {
    id: 5518,
    comune: "RANDAZZO",
    sigla: "CT",
    codice_elettorale: 4190210370,
    codice_istat: 87038,
    codice_belfiore: "H175",
  },
  {
    id: 5519,
    comune: "RANICA",
    sigla: "BG",
    codice_elettorale: 1030121670,
    codice_istat: 16178,
    codice_belfiore: "H176",
  },
  {
    id: 5520,
    comune: "RANZANICO",
    sigla: "BG",
    codice_elettorale: 1030121680,
    codice_istat: 16179,
    codice_belfiore: "H177",
  },
  {
    id: 5521,
    comune: "RANZO",
    sigla: "IM",
    codice_elettorale: 1070370450,
    codice_istat: 8048,
    codice_belfiore: "H180",
  },
  {
    id: 5522,
    comune: "RAPAGNANO",
    sigla: "FM",
    codice_elettorale: 2111050350,
    codice_istat: 109035,
    codice_belfiore: "H182",
  },
  {
    id: 5523,
    comune: "RAPALLO",
    sigla: "GE",
    codice_elettorale: 1070340460,
    codice_istat: 10046,
    codice_belfiore: "H183",
  },
  {
    id: 5524,
    comune: "RAPINO",
    sigla: "CH",
    codice_elettorale: 3130230710,
    codice_istat: 69071,
    codice_belfiore: "H184",
  },
  {
    id: 5525,
    comune: "RAPOLANO TERME",
    sigla: "SI",
    codice_elettorale: 2090750260,
    codice_istat: 52026,
    codice_belfiore: "H185",
  },
  {
    id: 5526,
    comune: "RAPOLLA",
    sigla: "PZ",
    codice_elettorale: 3170640630,
    codice_istat: 76064,
    codice_belfiore: "H186",
  },
  {
    id: 5527,
    comune: "RAPONE",
    sigla: "PZ",
    codice_elettorale: 3170640640,
    codice_istat: 76065,
    codice_belfiore: "H187",
  },
  {
    id: 5528,
    comune: "RASSA",
    sigla: "VC",
    codice_elettorale: 1010881090,
    codice_istat: 2110,
    codice_belfiore: "H188",
  },
  {
    id: 5529,
    comune: "RASUN ANTERSELVA/RASEN-ANTHOLZ",
    sigla: "BZ",
    codice_elettorale: 1040140661,
    codice_istat: 21071,
    codice_belfiore: "H189",
  },
  {
    id: 5530,
    comune: "RASURA",
    sigla: "SO",
    codice_elettorale: 1030770550,
    codice_istat: 14055,
    codice_belfiore: "H192",
  },
  {
    id: 5531,
    comune: "RAVANUSA",
    sigla: "AG",
    codice_elettorale: 4190010310,
    codice_istat: 84031,
    codice_belfiore: "H194",
  },
  {
    id: 5532,
    comune: "RAVARINO",
    sigla: "MO",
    codice_elettorale: 1080500330,
    codice_istat: 36034,
    codice_belfiore: "H195",
  },
  {
    id: 5533,
    comune: "RAVASCLETTO",
    sigla: "UD",
    codice_elettorale: 1060850880,
    codice_istat: 30088,
    codice_belfiore: "H196",
  },
  {
    id: 5534,
    comune: "RAVELLO",
    sigla: "SA",
    codice_elettorale: 3150721040,
    codice_istat: 65104,
    codice_belfiore: "H198",
  },
  {
    id: 5535,
    comune: "RAVENNA",
    sigla: "RA",
    codice_elettorale: 1080660140,
    codice_istat: 39014,
    codice_belfiore: "H199",
  },
  {
    id: 5536,
    comune: "RAVEO",
    sigla: "UD",
    codice_elettorale: 1060850890,
    codice_istat: 30089,
    codice_belfiore: "H200",
  },
  {
    id: 5537,
    comune: "RAVISCANINA",
    sigla: "CE",
    codice_elettorale: 3150200660,
    codice_istat: 61066,
    codice_belfiore: "H202",
  },
  {
    id: 5538,
    comune: "RE",
    sigla: "VB",
    codice_elettorale: 1011020600,
    codice_istat: 103060,
    codice_belfiore: "H203",
  },
  {
    id: 5539,
    comune: "REA",
    sigla: "PV",
    codice_elettorale: 1030571161,
    codice_istat: 18119,
    codice_belfiore: "H204",
  },
  {
    id: 5540,
    comune: "REALMONTE",
    sigla: "AG",
    codice_elettorale: 4190010320,
    codice_istat: 84032,
    codice_belfiore: "H205",
  },
  {
    id: 5541,
    comune: "REANA DEL ROJALE",
    sigla: "UD",
    codice_elettorale: 1060850900,
    codice_istat: 30090,
    codice_belfiore: "H206",
  },
  {
    id: 5542,
    comune: "REANO",
    sigla: "TO",
    codice_elettorale: 1010812030,
    codice_istat: 1211,
    codice_belfiore: "H207",
  },
  {
    id: 5543,
    comune: "RECALE",
    sigla: "CE",
    codice_elettorale: 3150200670,
    codice_istat: 61067,
    codice_belfiore: "H210",
  },
  {
    id: 5544,
    comune: "RECANATI",
    sigla: "MC",
    codice_elettorale: 2110440440,
    codice_istat: 43044,
    codice_belfiore: "H211",
  },
  {
    id: 5545,
    comune: "RECCO",
    sigla: "GE",
    codice_elettorale: 1070340470,
    codice_istat: 10047,
    codice_belfiore: "H212",
  },
  {
    id: 5546,
    comune: "RECETTO",
    sigla: "NO",
    codice_elettorale: 1010521230,
    codice_istat: 3129,
    codice_belfiore: "H213",
  },
  {
    id: 5547,
    comune: "RECOARO TERME",
    sigla: "VI",
    codice_elettorale: 1050900840,
    codice_istat: 24084,
    codice_belfiore: "H214",
  },
  {
    id: 5548,
    comune: "REDAVALLE",
    sigla: "PV",
    codice_elettorale: 1030571170,
    codice_istat: 18120,
    codice_belfiore: "H216",
  },
  {
    id: 5549,
    comune: "REDONDESCO",
    sigla: "MN",
    codice_elettorale: 1030450480,
    codice_istat: 20048,
    codice_belfiore: "H218",
  },
  {
    id: 5550,
    comune: "REFRANCORE",
    sigla: "AT",
    codice_elettorale: 1010070890,
    codice_istat: 5089,
    codice_belfiore: "H219",
  },
  {
    id: 5551,
    comune: "REFRONTOLO",
    sigla: "TV",
    codice_elettorale: 1050840640,
    codice_istat: 26065,
    codice_belfiore: "H220",
  },
  {
    id: 5552,
    comune: "REGALBUTO",
    sigla: "EN",
    codice_elettorale: 4190280160,
    codice_istat: 86016,
    codice_belfiore: "H221",
  },
  {
    id: 5553,
    comune: "REGGELLO",
    sigla: "FI",
    codice_elettorale: 2090300350,
    codice_istat: 48035,
    codice_belfiore: "H222",
  },
  {
    id: 5554,
    comune: "REGGIO DI CALABRIA",
    sigla: "RC",
    codice_elettorale: 3180670630,
    codice_istat: 80063,
    codice_belfiore: "H224",
  },
  {
    id: 5555,
    comune: "REGGIO NELL'EMILIA",
    sigla: "RE",
    codice_elettorale: 1080680330,
    codice_istat: 35033,
    codice_belfiore: "H223",
  },
  {
    id: 5556,
    comune: "REGGIOLO",
    sigla: "RE",
    codice_elettorale: 1080680320,
    codice_istat: 35032,
    codice_belfiore: "H225",
  },
  {
    id: 5557,
    comune: "REINO",
    sigla: "BN",
    codice_elettorale: 3150110550,
    codice_istat: 62056,
    codice_belfiore: "H227",
  },
  {
    id: 5558,
    comune: "REITANO",
    sigla: "ME",
    codice_elettorale: 4190480690,
    codice_istat: 83070,
    codice_belfiore: "H228",
  },
  {
    id: 5559,
    comune: "REMANZACCO",
    sigla: "UD",
    codice_elettorale: 1060850910,
    codice_istat: 30091,
    codice_belfiore: "H229",
  },
  {
    id: 5560,
    comune: "REMEDELLO",
    sigla: "BS",
    codice_elettorale: 1030151510,
    codice_istat: 17160,
    codice_belfiore: "H230",
  },
  {
    id: 5561,
    comune: "RENATE",
    sigla: "MB",
    codice_elettorale: 1031040370,
    codice_istat: 108037,
    codice_belfiore: "H233",
  },
  {
    id: 5562,
    comune: "RENDE",
    sigla: "CS",
    codice_elettorale: 3180251030,
    codice_istat: 78102,
    codice_belfiore: "H235",
  },
  {
    id: 5563,
    comune: "RENON/RITTEN",
    sigla: "BZ",
    codice_elettorale: 1040140680,
    codice_istat: 21072,
    codice_belfiore: "H236",
  },
  {
    id: 5564,
    comune: "RESANA",
    sigla: "TV",
    codice_elettorale: 1050840650,
    codice_istat: 26066,
    codice_belfiore: "H238",
  },
  {
    id: 5565,
    comune: "RESCALDINA",
    sigla: "MI",
    codice_elettorale: 1030491800,
    codice_istat: 15181,
    codice_belfiore: "H240",
  },
  {
    id: 5566,
    comune: "RESIA",
    sigla: "UD",
    codice_elettorale: 1060850920,
    codice_istat: 30092,
    codice_belfiore: "H242",
  },
  {
    id: 5567,
    comune: "RESIUTTA",
    sigla: "UD",
    codice_elettorale: 1060850930,
    codice_istat: 30093,
    codice_belfiore: "H244",
  },
  {
    id: 5568,
    comune: "RESUTTANO",
    sigla: "CL",
    codice_elettorale: 4190180140,
    codice_istat: 85014,
    codice_belfiore: "H245",
  },
  {
    id: 5569,
    comune: "RETORBIDO",
    sigla: "PV",
    codice_elettorale: 1030571180,
    codice_istat: 18121,
    codice_belfiore: "H246",
  },
  {
    id: 5570,
    comune: "REVELLO",
    sigla: "CN",
    codice_elettorale: 1010271800,
    codice_istat: 4180,
    codice_belfiore: "H247",
  },
  {
    id: 5571,
    comune: "REVIGLIASCO D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070900,
    codice_istat: 5090,
    codice_belfiore: "H250",
  },
  {
    id: 5572,
    comune: "REVINE LAGO",
    sigla: "TV",
    codice_elettorale: 1050840660,
    codice_istat: 26067,
    codice_belfiore: "H253",
  },
  {
    id: 5573,
    comune: "REZZAGO",
    sigla: "CO",
    codice_elettorale: 1030241840,
    codice_istat: 13195,
    codice_belfiore: "H255",
  },
  {
    id: 5574,
    comune: "REZZATO",
    sigla: "BS",
    codice_elettorale: 1030151520,
    codice_istat: 17161,
    codice_belfiore: "H256",
  },
  {
    id: 5575,
    comune: "REZZO",
    sigla: "IM",
    codice_elettorale: 1070370460,
    codice_istat: 8049,
    codice_belfiore: "H257",
  },
  {
    id: 5576,
    comune: "REZZOAGLIO",
    sigla: "GE",
    codice_elettorale: 1070340480,
    codice_istat: 10048,
    codice_belfiore: "H258",
  },
  {
    id: 5577,
    comune: "RHEMES-NOTRE-DAME",
    sigla: "AO",
    codice_elettorale: 1020040540,
    codice_istat: 7055,
    codice_belfiore: "H262",
  },
  {
    id: 5578,
    comune: "RHEMES-SAINT-GEORGES",
    sigla: "AO",
    codice_elettorale: 1020040550,
    codice_istat: 7056,
    codice_belfiore: "H263",
  },
  {
    id: 5579,
    comune: "RHO",
    sigla: "MI",
    codice_elettorale: 1030491810,
    codice_istat: 15182,
    codice_belfiore: "H264",
  },
  {
    id: 5580,
    comune: "RIACE",
    sigla: "RC",
    codice_elettorale: 3180670640,
    codice_istat: 80064,
    codice_belfiore: "H265",
  },
  {
    id: 5581,
    comune: "RIALTO",
    sigla: "SV",
    codice_elettorale: 1070740530,
    codice_istat: 9053,
    codice_belfiore: "H266",
  },
  {
    id: 5582,
    comune: "RIANO",
    sigla: "RM",
    codice_elettorale: 2120700800,
    codice_istat: 58081,
    codice_belfiore: "H267",
  },
  {
    id: 5583,
    comune: "RIARDO",
    sigla: "CE",
    codice_elettorale: 3150200680,
    codice_istat: 61068,
    codice_belfiore: "H268",
  },
  {
    id: 5584,
    comune: "RIBERA",
    sigla: "AG",
    codice_elettorale: 4190010330,
    codice_istat: 84033,
    codice_belfiore: "H269",
  },
  {
    id: 5585,
    comune: "RIBORDONE",
    sigla: "TO",
    codice_elettorale: 1010812040,
    codice_istat: 1212,
    codice_belfiore: "H270",
  },
  {
    id: 5586,
    comune: "RICADI",
    sigla: "VV",
    codice_elettorale: 3181030300,
    codice_istat: 102030,
    codice_belfiore: "H271",
  },
  {
    id: 5587,
    comune: "RICALDONE",
    sigla: "AL",
    codice_elettorale: 1010021400,
    codice_istat: 6143,
    codice_belfiore: "H272",
  },
  {
    id: 5588,
    comune: "RICCIA",
    sigla: "CB",
    codice_elettorale: 3140190570,
    codice_istat: 70057,
    codice_belfiore: "H273",
  },
  {
    id: 5589,
    comune: "RICCIONE",
    sigla: "RN",
    codice_elettorale: 1081010130,
    codice_istat: 99013,
    codice_belfiore: "H274",
  },
  {
    id: 5590,
    comune: "RICCO' DEL GOLFO DI SPEZIA",
    sigla: "SP",
    codice_elettorale: 1070390230,
    codice_istat: 11023,
    codice_belfiore: "H275",
  },
  {
    id: 5591,
    comune: "RICENGO",
    sigla: "CR",
    codice_elettorale: 1030260770,
    codice_istat: 19079,
    codice_belfiore: "H276",
  },
  {
    id: 5592,
    comune: "RICIGLIANO",
    sigla: "SA",
    codice_elettorale: 3150721050,
    codice_istat: 65105,
    codice_belfiore: "H277",
  },
  {
    id: 5593,
    comune: "RIESE PIO X",
    sigla: "TV",
    codice_elettorale: 1050840670,
    codice_istat: 26068,
    codice_belfiore: "H280",
  },
  {
    id: 5594,
    comune: "RIESI",
    sigla: "CL",
    codice_elettorale: 4190180150,
    codice_istat: 85015,
    codice_belfiore: "H281",
  },
  {
    id: 5595,
    comune: "RIETI",
    sigla: "RI",
    codice_elettorale: 2120690570,
    codice_istat: 57059,
    codice_belfiore: "H282",
  },
  {
    id: 5596,
    comune: "RIFIANO/RIFFIAN",
    sigla: "BZ",
    codice_elettorale: 1040140690,
    codice_istat: 21073,
    codice_belfiore: "H284",
  },
  {
    id: 5597,
    comune: "RIFREDDO",
    sigla: "CN",
    codice_elettorale: 1010271810,
    codice_istat: 4181,
    codice_belfiore: "H285",
  },
  {
    id: 5598,
    comune: "RIGNANO FLAMINIO",
    sigla: "RM",
    codice_elettorale: 2120700810,
    codice_istat: 58082,
    codice_belfiore: "H288",
  },
  {
    id: 5599,
    comune: "RIGNANO GARGANICO",
    sigla: "FG",
    codice_elettorale: 3160310390,
    codice_istat: 71041,
    codice_belfiore: "H287",
  },
  {
    id: 5600,
    comune: "RIGNANO SULL'ARNO",
    sigla: "FI",
    codice_elettorale: 2090300360,
    codice_istat: 48036,
    codice_belfiore: "H286",
  },
  {
    id: 5601,
    comune: "RIGOLATO",
    sigla: "UD",
    codice_elettorale: 1060850940,
    codice_istat: 30094,
    codice_belfiore: "H289",
  },
  {
    id: 5602,
    comune: "RIMELLA",
    sigla: "VC",
    codice_elettorale: 1010881120,
    codice_istat: 2113,
    codice_belfiore: "H293",
  },
  {
    id: 5603,
    comune: "RIMINI",
    sigla: "RN",
    codice_elettorale: 1081010140,
    codice_istat: 99014,
    codice_belfiore: "H294",
  },
  {
    id: 5604,
    comune: "RIO",
    sigla: "LI",
    codice_elettorale: 2090420155,
    codice_istat: 49021,
    codice_belfiore: "M391",
  },
  {
    id: 5605,
    comune: "RIO DI PUSTERIA/MUHLBACH",
    sigla: "BZ",
    codice_elettorale: 1040140700,
    codice_istat: 21074,
    codice_belfiore: "H299",
  },
  {
    id: 5606,
    comune: "RIO SALICETO",
    sigla: "RE",
    codice_elettorale: 1080680340,
    codice_istat: 35034,
    codice_belfiore: "H298",
  },
  {
    id: 5607,
    comune: "RIOFREDDO",
    sigla: "RM",
    codice_elettorale: 2120700820,
    codice_istat: 58083,
    codice_belfiore: "H300",
  },
  {
    id: 5608,
    comune: "RIOLA SARDO",
    sigla: "OR",
    codice_elettorale: 4200950430,
    codice_istat: 95043,
    codice_belfiore: "H301",
  },
  {
    id: 5609,
    comune: "RIOLO TERME",
    sigla: "RA",
    codice_elettorale: 1080660150,
    codice_istat: 39015,
    codice_belfiore: "H302",
  },
  {
    id: 5610,
    comune: "RIOLUNATO",
    sigla: "MO",
    codice_elettorale: 1080500340,
    codice_istat: 36035,
    codice_belfiore: "H303",
  },
  {
    id: 5611,
    comune: "RIOMAGGIORE",
    sigla: "SP",
    codice_elettorale: 1070390240,
    codice_istat: 11024,
    codice_belfiore: "H304",
  },
  {
    id: 5612,
    comune: "RIONERO IN VULTURE",
    sigla: "PZ",
    codice_elettorale: 3170640650,
    codice_istat: 76066,
    codice_belfiore: "H307",
  },
  {
    id: 5613,
    comune: "RIONERO SANNITICO",
    sigla: "IS",
    codice_elettorale: 3140940390,
    codice_istat: 94039,
    codice_belfiore: "H308",
  },
  {
    id: 5614,
    comune: "RIPA TEATINA",
    sigla: "CH",
    codice_elettorale: 3130230720,
    codice_istat: 69072,
    codice_belfiore: "H320",
  },
  {
    id: 5615,
    comune: "RIPABOTTONI",
    sigla: "CB",
    codice_elettorale: 3140190580,
    codice_istat: 70058,
    codice_belfiore: "H311",
  },
  {
    id: 5616,
    comune: "RIPACANDIDA",
    sigla: "PZ",
    codice_elettorale: 3170640660,
    codice_istat: 76067,
    codice_belfiore: "H312",
  },
  {
    id: 5617,
    comune: "RIPALIMOSANI",
    sigla: "CB",
    codice_elettorale: 3140190590,
    codice_istat: 70059,
    codice_belfiore: "H313",
  },
  {
    id: 5618,
    comune: "RIPALTA ARPINA",
    sigla: "CR",
    codice_elettorale: 1030260780,
    codice_istat: 19080,
    codice_belfiore: "H314",
  },
  {
    id: 5619,
    comune: "RIPALTA CREMASCA",
    sigla: "CR",
    codice_elettorale: 1030260790,
    codice_istat: 19081,
    codice_belfiore: "H315",
  },
  {
    id: 5620,
    comune: "RIPALTA GUERINA",
    sigla: "CR",
    codice_elettorale: 1030260791,
    codice_istat: 19082,
    codice_belfiore: "H316",
  },
  {
    id: 5621,
    comune: "RIPARBELLA",
    sigla: "PI",
    codice_elettorale: 2090620290,
    codice_istat: 50030,
    codice_belfiore: "H319",
  },
  {
    id: 5622,
    comune: "RIPATRANSONE",
    sigla: "AP",
    codice_elettorale: 2110060620,
    codice_istat: 44063,
    codice_belfiore: "H321",
  },
  {
    id: 5623,
    comune: "RIPE SAN GINESIO",
    sigla: "MC",
    codice_elettorale: 2110440450,
    codice_istat: 43045,
    codice_belfiore: "H323",
  },
  {
    id: 5624,
    comune: "RIPI",
    sigla: "FR",
    codice_elettorale: 2120330570,
    codice_istat: 60058,
    codice_belfiore: "H324",
  },
  {
    id: 5625,
    comune: "RIPOSTO",
    sigla: "CT",
    codice_elettorale: 4190210380,
    codice_istat: 87039,
    codice_belfiore: "H325",
  },
  {
    id: 5626,
    comune: "RITTANA",
    sigla: "CN",
    codice_elettorale: 1010271820,
    codice_istat: 4182,
    codice_belfiore: "H326",
  },
  {
    id: 5627,
    comune: "RIVA DEL GARDA",
    sigla: "TN",
    codice_elettorale: 1040831420,
    codice_istat: 22153,
    codice_belfiore: "H330",
  },
  {
    id: 5628,
    comune: "RIVA DEL PO",
    sigla: "FE",
    codice_elettorale: 1080290175,
    codice_istat: 38029,
    codice_belfiore: "M410",
  },
  {
    id: 5629,
    comune: "RIVA DI SOLTO",
    sigla: "BG",
    codice_elettorale: 1030121690,
    codice_istat: 16180,
    codice_belfiore: "H331",
  },
  {
    id: 5630,
    comune: "RIVA LIGURE",
    sigla: "IM",
    codice_elettorale: 1070370461,
    codice_istat: 8050,
    codice_belfiore: "H328",
  },
  {
    id: 5631,
    comune: "RIVA PRESSO CHIERI",
    sigla: "TO",
    codice_elettorale: 1010812070,
    codice_istat: 1215,
    codice_belfiore: "H337",
  },
  {
    id: 5632,
    comune: "RIVALBA",
    sigla: "TO",
    codice_elettorale: 1010812050,
    codice_istat: 1213,
    codice_belfiore: "H333",
  },
  {
    id: 5633,
    comune: "RIVALTA BORMIDA",
    sigla: "AL",
    codice_elettorale: 1010021410,
    codice_istat: 6144,
    codice_belfiore: "H334",
  },
  {
    id: 5634,
    comune: "RIVALTA DI TORINO",
    sigla: "TO",
    codice_elettorale: 1010812060,
    codice_istat: 1214,
    codice_belfiore: "H335",
  },
  {
    id: 5635,
    comune: "RIVAMONTE AGORDINO",
    sigla: "BL",
    codice_elettorale: 1050100430,
    codice_istat: 25043,
    codice_belfiore: "H327",
  },
  {
    id: 5636,
    comune: "RIVANAZZANO TERME",
    sigla: "PV",
    codice_elettorale: 1030571190,
    codice_istat: 18122,
    codice_belfiore: "H336",
  },
  {
    id: 5637,
    comune: "RIVARA",
    sigla: "TO",
    codice_elettorale: 1010812080,
    codice_istat: 1216,
    codice_belfiore: "H338",
  },
  {
    id: 5638,
    comune: "RIVAROLO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812090,
    codice_istat: 1217,
    codice_belfiore: "H340",
  },
  {
    id: 5639,
    comune: "RIVAROLO DEL RE ED UNITI",
    sigla: "CR",
    codice_elettorale: 1030260800,
    codice_istat: 19083,
    codice_belfiore: "H341",
  },
  {
    id: 5640,
    comune: "RIVAROLO MANTOVANO",
    sigla: "MN",
    codice_elettorale: 1030450500,
    codice_istat: 20050,
    codice_belfiore: "H342",
  },
  {
    id: 5641,
    comune: "RIVARONE",
    sigla: "AL",
    codice_elettorale: 1010021420,
    codice_istat: 6145,
    codice_belfiore: "H343",
  },
  {
    id: 5642,
    comune: "RIVAROSSA",
    sigla: "TO",
    codice_elettorale: 1010812100,
    codice_istat: 1218,
    codice_belfiore: "H344",
  },
  {
    id: 5643,
    comune: "RIVE",
    sigla: "VC",
    codice_elettorale: 1010881140,
    codice_istat: 2115,
    codice_belfiore: "H346",
  },
  {
    id: 5644,
    comune: "RIVE D'ARCANO",
    sigla: "UD",
    codice_elettorale: 1060850950,
    codice_istat: 30095,
    codice_belfiore: "H347",
  },
  {
    id: 5645,
    comune: "RIVELLO",
    sigla: "PZ",
    codice_elettorale: 3170640670,
    codice_istat: 76068,
    codice_belfiore: "H348",
  },
  {
    id: 5646,
    comune: "RIVERGARO",
    sigla: "PC",
    codice_elettorale: 1080610380,
    codice_istat: 33038,
    codice_belfiore: "H350",
  },
  {
    id: 5647,
    comune: "RIVIGNANO TEOR",
    sigla: "UD",
    codice_elettorale: 1060850965,
    codice_istat: 30188,
    codice_belfiore: "M317",
  },
  {
    id: 5648,
    comune: "RIVISONDOLI",
    sigla: "AQ",
    codice_elettorale: 3130380780,
    codice_istat: 66078,
    codice_belfiore: "H353",
  },
  {
    id: 5649,
    comune: "RIVODUTRI",
    sigla: "RI",
    codice_elettorale: 2120690580,
    codice_istat: 57060,
    codice_belfiore: "H354",
  },
  {
    id: 5650,
    comune: "RIVOLI",
    sigla: "TO",
    codice_elettorale: 1010812110,
    codice_istat: 1219,
    codice_belfiore: "H355",
  },
  {
    id: 5651,
    comune: "RIVOLI VERONESE",
    sigla: "VR",
    codice_elettorale: 1050890610,
    codice_istat: 23062,
    codice_belfiore: "H356",
  },
  {
    id: 5652,
    comune: "RIVOLTA D'ADDA",
    sigla: "CR",
    codice_elettorale: 1030260810,
    codice_istat: 19084,
    codice_belfiore: "H357",
  },
  {
    id: 5653,
    comune: "RIZZICONI",
    sigla: "RC",
    codice_elettorale: 3180670650,
    codice_istat: 80065,
    codice_belfiore: "H359",
  },
  {
    id: 5654,
    comune: "ROANA",
    sigla: "VI",
    codice_elettorale: 1050900850,
    codice_istat: 24085,
    codice_belfiore: "H361",
  },
  {
    id: 5655,
    comune: "ROASCHIA",
    sigla: "CN",
    codice_elettorale: 1010271830,
    codice_istat: 4183,
    codice_belfiore: "H362",
  },
  {
    id: 5656,
    comune: "ROASCIO",
    sigla: "CN",
    codice_elettorale: 1010271840,
    codice_istat: 4184,
    codice_belfiore: "H363",
  },
  {
    id: 5657,
    comune: "ROASIO",
    sigla: "VC",
    codice_elettorale: 1010881150,
    codice_istat: 2116,
    codice_belfiore: "H365",
  },
  {
    id: 5658,
    comune: "ROATTO",
    sigla: "AT",
    codice_elettorale: 1010070910,
    codice_istat: 5091,
    codice_belfiore: "H366",
  },
  {
    id: 5659,
    comune: "ROBASSOMERO",
    sigla: "TO",
    codice_elettorale: 1010812120,
    codice_istat: 1220,
    codice_belfiore: "H367",
  },
  {
    id: 5660,
    comune: "ROBBIATE",
    sigla: "LC",
    codice_elettorale: 1030980710,
    codice_istat: 97071,
    codice_belfiore: "G223",
  },
  {
    id: 5661,
    comune: "ROBBIO",
    sigla: "PV",
    codice_elettorale: 1030571200,
    codice_istat: 18123,
    codice_belfiore: "H369",
  },
  {
    id: 5662,
    comune: "ROBECCHETTO CON INDUNO",
    sigla: "MI",
    codice_elettorale: 1030491820,
    codice_istat: 15183,
    codice_belfiore: "H371",
  },
  {
    id: 5663,
    comune: "ROBECCO D'OGLIO",
    sigla: "CR",
    codice_elettorale: 1030260820,
    codice_istat: 19085,
    codice_belfiore: "H372",
  },
  {
    id: 5664,
    comune: "ROBECCO PAVESE",
    sigla: "PV",
    codice_elettorale: 1030571210,
    codice_istat: 18124,
    codice_belfiore: "H375",
  },
  {
    id: 5665,
    comune: "ROBECCO SUL NAVIGLIO",
    sigla: "MI",
    codice_elettorale: 1030491830,
    codice_istat: 15184,
    codice_belfiore: "H373",
  },
  {
    id: 5666,
    comune: "ROBELLA",
    sigla: "AT",
    codice_elettorale: 1010070920,
    codice_istat: 5092,
    codice_belfiore: "H376",
  },
  {
    id: 5667,
    comune: "ROBILANTE",
    sigla: "CN",
    codice_elettorale: 1010271850,
    codice_istat: 4185,
    codice_belfiore: "H377",
  },
  {
    id: 5668,
    comune: "ROBURENT",
    sigla: "CN",
    codice_elettorale: 1010271860,
    codice_istat: 4186,
    codice_belfiore: "H378",
  },
  {
    id: 5669,
    comune: "ROCCA CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812130,
    codice_istat: 1221,
    codice_belfiore: "H386",
  },
  {
    id: 5670,
    comune: "ROCCA CANTERANO",
    sigla: "RM",
    codice_elettorale: 2120700830,
    codice_istat: 58084,
    codice_belfiore: "H387",
  },
  {
    id: 5671,
    comune: "ROCCA CIGLIE'",
    sigla: "CN",
    codice_elettorale: 1010271880,
    codice_istat: 4188,
    codice_belfiore: "H391",
  },
  {
    id: 5672,
    comune: "ROCCA D'ARAZZO",
    sigla: "AT",
    codice_elettorale: 1010070930,
    codice_istat: 5093,
    codice_belfiore: "H392",
  },
  {
    id: 5673,
    comune: "ROCCA D'ARCE",
    sigla: "FR",
    codice_elettorale: 2120330580,
    codice_istat: 60059,
    codice_belfiore: "H393",
  },
  {
    id: 5674,
    comune: "ROCCA D'EVANDRO",
    sigla: "CE",
    codice_elettorale: 3150200690,
    codice_istat: 61069,
    codice_belfiore: "H398",
  },
  {
    id: 5675,
    comune: "ROCCA DE' BALDI",
    sigla: "CN",
    codice_elettorale: 1010271890,
    codice_istat: 4189,
    codice_belfiore: "H395",
  },
  {
    id: 5676,
    comune: "ROCCA DE' GIORGI",
    sigla: "PV",
    codice_elettorale: 1030571220,
    codice_istat: 18125,
    codice_belfiore: "H396",
  },
  {
    id: 5677,
    comune: "ROCCA DI BOTTE",
    sigla: "AQ",
    codice_elettorale: 3130380800,
    codice_istat: 66080,
    codice_belfiore: "H399",
  },
  {
    id: 5678,
    comune: "ROCCA DI CAMBIO",
    sigla: "AQ",
    codice_elettorale: 3130380810,
    codice_istat: 66081,
    codice_belfiore: "H400",
  },
  {
    id: 5679,
    comune: "ROCCA DI CAVE",
    sigla: "RM",
    codice_elettorale: 2120700840,
    codice_istat: 58085,
    codice_belfiore: "H401",
  },
  {
    id: 5680,
    comune: "ROCCA DI MEZZO",
    sigla: "AQ",
    codice_elettorale: 3130380820,
    codice_istat: 66082,
    codice_belfiore: "H402",
  },
  {
    id: 5681,
    comune: "ROCCA DI NETO",
    sigla: "KR",
    codice_elettorale: 3180970190,
    codice_istat: 101019,
    codice_belfiore: "H403",
  },
  {
    id: 5682,
    comune: "ROCCA DI PAPA",
    sigla: "RM",
    codice_elettorale: 2120700850,
    codice_istat: 58086,
    codice_belfiore: "H404",
  },
  {
    id: 5683,
    comune: "ROCCA GRIMALDA",
    sigla: "AL",
    codice_elettorale: 1010021440,
    codice_istat: 6147,
    codice_belfiore: "H414",
  },
  {
    id: 5684,
    comune: "ROCCA IMPERIALE",
    sigla: "CS",
    codice_elettorale: 3180251040,
    codice_istat: 78103,
    codice_belfiore: "H416",
  },
  {
    id: 5685,
    comune: "ROCCA MASSIMA",
    sigla: "LT",
    codice_elettorale: 2120400220,
    codice_istat: 59022,
    codice_belfiore: "H421",
  },
  {
    id: 5686,
    comune: "ROCCA PIA",
    sigla: "AQ",
    codice_elettorale: 3130380830,
    codice_istat: 66083,
    codice_belfiore: "H429",
  },
  {
    id: 5687,
    comune: "ROCCA PIETORE",
    sigla: "BL",
    codice_elettorale: 1050100440,
    codice_istat: 25044,
    codice_belfiore: "H379",
  },
  {
    id: 5688,
    comune: "ROCCA PRIORA",
    sigla: "RM",
    codice_elettorale: 2120700870,
    codice_istat: 58088,
    codice_belfiore: "H432",
  },
  {
    id: 5689,
    comune: "ROCCA SAN CASCIANO",
    sigla: "FC",
    codice_elettorale: 1080320350,
    codice_istat: 40036,
    codice_belfiore: "H437",
  },
  {
    id: 5690,
    comune: "ROCCA SAN FELICE",
    sigla: "AV",
    codice_elettorale: 3150080780,
    codice_istat: 64079,
    codice_belfiore: "H438",
  },
  {
    id: 5691,
    comune: "ROCCA SAN GIOVANNI",
    sigla: "CH",
    codice_elettorale: 3130230740,
    codice_istat: 69074,
    codice_belfiore: "H439",
  },
  {
    id: 5692,
    comune: "ROCCA SANTA MARIA",
    sigla: "TE",
    codice_elettorale: 3130790350,
    codice_istat: 67036,
    codice_belfiore: "H440",
  },
  {
    id: 5693,
    comune: "ROCCA SANTO STEFANO",
    sigla: "RM",
    codice_elettorale: 2120700880,
    codice_istat: 58089,
    codice_belfiore: "H441",
  },
  {
    id: 5694,
    comune: "ROCCA SINIBALDA",
    sigla: "RI",
    codice_elettorale: 2120690600,
    codice_istat: 57062,
    codice_belfiore: "H446",
  },
  {
    id: 5695,
    comune: "ROCCA SUSELLA",
    sigla: "PV",
    codice_elettorale: 1030571230,
    codice_istat: 18126,
    codice_belfiore: "H450",
  },
  {
    id: 5696,
    comune: "ROCCABASCERANA",
    sigla: "AV",
    codice_elettorale: 3150080770,
    codice_istat: 64078,
    codice_belfiore: "H382",
  },
  {
    id: 5697,
    comune: "ROCCABERNARDA",
    sigla: "KR",
    codice_elettorale: 3180970180,
    codice_istat: 101018,
    codice_belfiore: "H383",
  },
  {
    id: 5698,
    comune: "ROCCABIANCA",
    sigla: "PR",
    codice_elettorale: 1080560300,
    codice_istat: 34030,
    codice_belfiore: "H384",
  },
  {
    id: 5699,
    comune: "ROCCABRUNA",
    sigla: "CN",
    codice_elettorale: 1010271870,
    codice_istat: 4187,
    codice_belfiore: "H385",
  },
  {
    id: 5700,
    comune: "ROCCACASALE",
    sigla: "AQ",
    codice_elettorale: 3130380790,
    codice_istat: 66079,
    codice_belfiore: "H389",
  },
  {
    id: 5701,
    comune: "ROCCADASPIDE",
    sigla: "SA",
    codice_elettorale: 3150721060,
    codice_istat: 65106,
    codice_belfiore: "H394",
  },
  {
    id: 5702,
    comune: "ROCCAFIORITA",
    sigla: "ME",
    codice_elettorale: 4190480700,
    codice_istat: 83071,
    codice_belfiore: "H405",
  },
  {
    id: 5703,
    comune: "ROCCAFLUVIONE",
    sigla: "AP",
    codice_elettorale: 2110060630,
    codice_istat: 44064,
    codice_belfiore: "H390",
  },
  {
    id: 5704,
    comune: "ROCCAFORTE DEL GRECO",
    sigla: "RC",
    codice_elettorale: 3180670660,
    codice_istat: 80066,
    codice_belfiore: "H408",
  },
  {
    id: 5705,
    comune: "ROCCAFORTE LIGURE",
    sigla: "AL",
    codice_elettorale: 1010021430,
    codice_istat: 6146,
    codice_belfiore: "H406",
  },
  {
    id: 5706,
    comune: "ROCCAFORTE MONDOVI'",
    sigla: "CN",
    codice_elettorale: 1010271900,
    codice_istat: 4190,
    codice_belfiore: "H407",
  },
  {
    id: 5707,
    comune: "ROCCAFORZATA",
    sigla: "TA",
    codice_elettorale: 3160780230,
    codice_istat: 73023,
    codice_belfiore: "H409",
  },
  {
    id: 5708,
    comune: "ROCCAFRANCA",
    sigla: "BS",
    codice_elettorale: 1030151530,
    codice_istat: 17162,
    codice_belfiore: "H410",
  },
  {
    id: 5709,
    comune: "ROCCAGIOVINE",
    sigla: "RM",
    codice_elettorale: 2120700860,
    codice_istat: 58087,
    codice_belfiore: "H411",
  },
  {
    id: 5710,
    comune: "ROCCAGLORIOSA",
    sigla: "SA",
    codice_elettorale: 3150721070,
    codice_istat: 65107,
    codice_belfiore: "H412",
  },
  {
    id: 5711,
    comune: "ROCCAGORGA",
    sigla: "LT",
    codice_elettorale: 2120400210,
    codice_istat: 59021,
    codice_belfiore: "H413",
  },
  {
    id: 5712,
    comune: "ROCCALBEGNA",
    sigla: "GR",
    codice_elettorale: 2090360190,
    codice_istat: 53020,
    codice_belfiore: "H417",
  },
  {
    id: 5713,
    comune: "ROCCALUMERA",
    sigla: "ME",
    codice_elettorale: 4190480710,
    codice_istat: 83072,
    codice_belfiore: "H418",
  },
  {
    id: 5714,
    comune: "ROCCAMANDOLFI",
    sigla: "IS",
    codice_elettorale: 3140940400,
    codice_istat: 94040,
    codice_belfiore: "H420",
  },
  {
    id: 5715,
    comune: "ROCCAMENA",
    sigla: "PA",
    codice_elettorale: 4190550590,
    codice_istat: 82061,
    codice_belfiore: "H422",
  },
  {
    id: 5716,
    comune: "ROCCAMONFINA",
    sigla: "CE",
    codice_elettorale: 3150200700,
    codice_istat: 61070,
    codice_belfiore: "H423",
  },
  {
    id: 5717,
    comune: "ROCCAMONTEPIANO",
    sigla: "CH",
    codice_elettorale: 3130230730,
    codice_istat: 69073,
    codice_belfiore: "H424",
  },
  {
    id: 5718,
    comune: "ROCCAMORICE",
    sigla: "PE",
    codice_elettorale: 3130600340,
    codice_istat: 68034,
    codice_belfiore: "H425",
  },
  {
    id: 5719,
    comune: "ROCCANOVA",
    sigla: "PZ",
    codice_elettorale: 3170640680,
    codice_istat: 76069,
    codice_belfiore: "H426",
  },
  {
    id: 5720,
    comune: "ROCCANTICA",
    sigla: "RI",
    codice_elettorale: 2120690590,
    codice_istat: 57061,
    codice_belfiore: "H427",
  },
  {
    id: 5721,
    comune: "ROCCAPALUMBA",
    sigla: "PA",
    codice_elettorale: 4190550600,
    codice_istat: 82062,
    codice_belfiore: "H428",
  },
  {
    id: 5722,
    comune: "ROCCAPIEMONTE",
    sigla: "SA",
    codice_elettorale: 3150721080,
    codice_istat: 65108,
    codice_belfiore: "H431",
  },
  {
    id: 5723,
    comune: "ROCCARAINOLA",
    sigla: "NA",
    codice_elettorale: 3150510650,
    codice_istat: 63065,
    codice_belfiore: "H433",
  },
  {
    id: 5724,
    comune: "ROCCARASO",
    sigla: "AQ",
    codice_elettorale: 3130380840,
    codice_istat: 66084,
    codice_belfiore: "H434",
  },
  {
    id: 5725,
    comune: "ROCCAROMANA",
    sigla: "CE",
    codice_elettorale: 3150200710,
    codice_istat: 61071,
    codice_belfiore: "H436",
  },
  {
    id: 5726,
    comune: "ROCCASCALEGNA",
    sigla: "CH",
    codice_elettorale: 3130230750,
    codice_istat: 69075,
    codice_belfiore: "H442",
  },
  {
    id: 5727,
    comune: "ROCCASECCA",
    sigla: "FR",
    codice_elettorale: 2120330590,
    codice_istat: 60060,
    codice_belfiore: "H443",
  },
  {
    id: 5728,
    comune: "ROCCASECCA DEI VOLSCI",
    sigla: "LT",
    codice_elettorale: 2120400230,
    codice_istat: 59023,
    codice_belfiore: "H444",
  },
  {
    id: 5729,
    comune: "ROCCASICURA",
    sigla: "IS",
    codice_elettorale: 3140940410,
    codice_istat: 94041,
    codice_belfiore: "H445",
  },
  {
    id: 5730,
    comune: "ROCCASPARVERA",
    sigla: "CN",
    codice_elettorale: 1010271910,
    codice_istat: 4191,
    codice_belfiore: "H447",
  },
  {
    id: 5731,
    comune: "ROCCASPINALVETI",
    sigla: "CH",
    codice_elettorale: 3130230760,
    codice_istat: 69076,
    codice_belfiore: "H448",
  },
  {
    id: 5732,
    comune: "ROCCASTRADA",
    sigla: "GR",
    codice_elettorale: 2090360200,
    codice_istat: 53021,
    codice_belfiore: "H449",
  },
  {
    id: 5733,
    comune: "ROCCAVALDINA",
    sigla: "ME",
    codice_elettorale: 4190480720,
    codice_istat: 83073,
    codice_belfiore: "H380",
  },
  {
    id: 5734,
    comune: "ROCCAVERANO",
    sigla: "AT",
    codice_elettorale: 1010070940,
    codice_istat: 5094,
    codice_belfiore: "H451",
  },
  {
    id: 5735,
    comune: "ROCCAVIGNALE",
    sigla: "SV",
    codice_elettorale: 1070740540,
    codice_istat: 9054,
    codice_belfiore: "H452",
  },
  {
    id: 5736,
    comune: "ROCCAVIONE",
    sigla: "CN",
    codice_elettorale: 1010271920,
    codice_istat: 4192,
    codice_belfiore: "H453",
  },
  {
    id: 5737,
    comune: "ROCCAVIVARA",
    sigla: "CB",
    codice_elettorale: 3140190600,
    codice_istat: 70060,
    codice_belfiore: "H454",
  },
  {
    id: 5738,
    comune: "ROCCELLA IONICA",
    sigla: "RC",
    codice_elettorale: 3180670670,
    codice_istat: 80067,
    codice_belfiore: "H456",
  },
  {
    id: 5739,
    comune: "ROCCELLA VALDEMONE",
    sigla: "ME",
    codice_elettorale: 4190480730,
    codice_istat: 83074,
    codice_belfiore: "H455",
  },
  {
    id: 5740,
    comune: "ROCCHETTA A VOLTURNO",
    sigla: "IS",
    codice_elettorale: 3140940420,
    codice_istat: 94042,
    codice_belfiore: "H458",
  },
  {
    id: 5741,
    comune: "ROCCHETTA BELBO",
    sigla: "CN",
    codice_elettorale: 1010271930,
    codice_istat: 4193,
    codice_belfiore: "H462",
  },
  {
    id: 5742,
    comune: "ROCCHETTA DI VARA",
    sigla: "SP",
    codice_elettorale: 1070390250,
    codice_istat: 11025,
    codice_belfiore: "H461",
  },
  {
    id: 5743,
    comune: "ROCCHETTA E CROCE",
    sigla: "CE",
    codice_elettorale: 3150200720,
    codice_istat: 61072,
    codice_belfiore: "H459",
  },
  {
    id: 5744,
    comune: "ROCCHETTA LIGURE",
    sigla: "AL",
    codice_elettorale: 1010021450,
    codice_istat: 6148,
    codice_belfiore: "H465",
  },
  {
    id: 5745,
    comune: "ROCCHETTA NERVINA",
    sigla: "IM",
    codice_elettorale: 1070370480,
    codice_istat: 8051,
    codice_belfiore: "H460",
  },
  {
    id: 5746,
    comune: "ROCCHETTA PALAFEA",
    sigla: "AT",
    codice_elettorale: 1010070950,
    codice_istat: 5095,
    codice_belfiore: "H466",
  },
  {
    id: 5747,
    comune: "ROCCHETTA SANT'ANTONIO",
    sigla: "FG",
    codice_elettorale: 3160310400,
    codice_istat: 71042,
    codice_belfiore: "H467",
  },
  {
    id: 5748,
    comune: "ROCCHETTA TANARO",
    sigla: "AT",
    codice_elettorale: 1010070960,
    codice_istat: 5096,
    codice_belfiore: "H468",
  },
  {
    id: 5749,
    comune: "RODANO",
    sigla: "MI",
    codice_elettorale: 1030491840,
    codice_istat: 15185,
    codice_belfiore: "H470",
  },
  {
    id: 5750,
    comune: "RODDI",
    sigla: "CN",
    codice_elettorale: 1010271940,
    codice_istat: 4194,
    codice_belfiore: "H472",
  },
  {
    id: 5751,
    comune: "RODDINO",
    sigla: "CN",
    codice_elettorale: 1010271950,
    codice_istat: 4195,
    codice_belfiore: "H473",
  },
  {
    id: 5752,
    comune: "RODELLO",
    sigla: "CN",
    codice_elettorale: 1010271960,
    codice_istat: 4196,
    codice_belfiore: "H474",
  },
  {
    id: 5753,
    comune: "RODENGO SAIANO",
    sigla: "BS",
    codice_elettorale: 1030151540,
    codice_istat: 17163,
    codice_belfiore: "H477",
  },
  {
    id: 5754,
    comune: "RODENGO/RODENECK",
    sigla: "BZ",
    codice_elettorale: 1040140701,
    codice_istat: 21075,
    codice_belfiore: "H475",
  },
  {
    id: 5755,
    comune: "RODERO",
    sigla: "CO",
    codice_elettorale: 1030241860,
    codice_istat: 13197,
    codice_belfiore: "H478",
  },
  {
    id: 5756,
    comune: "RODI GARGANICO",
    sigla: "FG",
    codice_elettorale: 3160310410,
    codice_istat: 71043,
    codice_belfiore: "H480",
  },
  {
    id: 5757,
    comune: "RODI' MILICI",
    sigla: "ME",
    codice_elettorale: 4190480740,
    codice_istat: 83075,
    codice_belfiore: "H479",
  },
  {
    id: 5758,
    comune: "RODIGO",
    sigla: "MN",
    codice_elettorale: 1030450510,
    codice_istat: 20051,
    codice_belfiore: "H481",
  },
  {
    id: 5759,
    comune: "ROE' VOLCIANO",
    sigla: "BS",
    codice_elettorale: 1030151550,
    codice_istat: 17164,
    codice_belfiore: "H484",
  },
  {
    id: 5760,
    comune: "ROFRANO",
    sigla: "SA",
    codice_elettorale: 3150721090,
    codice_istat: 65109,
    codice_belfiore: "H485",
  },
  {
    id: 5761,
    comune: "ROGENO",
    sigla: "LC",
    codice_elettorale: 1030980720,
    codice_istat: 97072,
    codice_belfiore: "H486",
  },
  {
    id: 5762,
    comune: "ROGGIANO GRAVINA",
    sigla: "CS",
    codice_elettorale: 3180251050,
    codice_istat: 78104,
    codice_belfiore: "H488",
  },
  {
    id: 5763,
    comune: "ROGHUDI",
    sigla: "RC",
    codice_elettorale: 3180670680,
    codice_istat: 80068,
    codice_belfiore: "H489",
  },
  {
    id: 5764,
    comune: "ROGLIANO",
    sigla: "CS",
    codice_elettorale: 3180251060,
    codice_istat: 78105,
    codice_belfiore: "H490",
  },
  {
    id: 5765,
    comune: "ROGNANO",
    sigla: "PV",
    codice_elettorale: 1030571240,
    codice_istat: 18127,
    codice_belfiore: "H491",
  },
  {
    id: 5766,
    comune: "ROGNO",
    sigla: "BG",
    codice_elettorale: 1030121720,
    codice_istat: 16182,
    codice_belfiore: "H492",
  },
  {
    id: 5767,
    comune: "ROGOLO",
    sigla: "SO",
    codice_elettorale: 1030770560,
    codice_istat: 14056,
    codice_belfiore: "H493",
  },
  {
    id: 5768,
    comune: "ROIATE",
    sigla: "RM",
    codice_elettorale: 2120700890,
    codice_istat: 58090,
    codice_belfiore: "H494",
  },
  {
    id: 5769,
    comune: "ROIO DEL SANGRO",
    sigla: "CH",
    codice_elettorale: 3130230770,
    codice_istat: 69077,
    codice_belfiore: "H495",
  },
  {
    id: 5770,
    comune: "ROISAN",
    sigla: "AO",
    codice_elettorale: 1020040560,
    codice_istat: 7057,
    codice_belfiore: "H497",
  },
  {
    id: 5771,
    comune: "ROLETTO",
    sigla: "TO",
    codice_elettorale: 1010812131,
    codice_istat: 1222,
    codice_belfiore: "H498",
  },
  {
    id: 5772,
    comune: "ROLO",
    sigla: "RE",
    codice_elettorale: 1080680350,
    codice_istat: 35035,
    codice_belfiore: "H500",
  },
  {
    id: 5773,
    comune: "ROMA",
    sigla: "RM",
    codice_elettorale: 2120700900,
    codice_istat: 58091,
    codice_belfiore: "H501",
  },
  {
    id: 5774,
    comune: "ROMAGNANO AL MONTE",
    sigla: "SA",
    codice_elettorale: 3150721100,
    codice_istat: 65110,
    codice_belfiore: "H503",
  },
  {
    id: 5775,
    comune: "ROMAGNANO SESIA",
    sigla: "NO",
    codice_elettorale: 1010521240,
    codice_istat: 3130,
    codice_belfiore: "H502",
  },
  {
    id: 5776,
    comune: "ROMAGNESE",
    sigla: "PV",
    codice_elettorale: 1030571250,
    codice_istat: 18128,
    codice_belfiore: "H505",
  },
  {
    id: 5777,
    comune: "ROMANA",
    sigla: "SS",
    codice_elettorale: 4200730600,
    codice_istat: 90061,
    codice_belfiore: "H507",
  },
  {
    id: 5778,
    comune: "ROMANENGO",
    sigla: "CR",
    codice_elettorale: 1030260830,
    codice_istat: 19086,
    codice_belfiore: "H508",
  },
  {
    id: 5779,
    comune: "ROMANO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812140,
    codice_istat: 1223,
    codice_belfiore: "H511",
  },
  {
    id: 5780,
    comune: "ROMANO D'EZZELINO",
    sigla: "VI",
    codice_elettorale: 1050900860,
    codice_istat: 24086,
    codice_belfiore: "H512",
  },
  {
    id: 5781,
    comune: "ROMANO DI LOMBARDIA",
    sigla: "BG",
    codice_elettorale: 1030121730,
    codice_istat: 16183,
    codice_belfiore: "H509",
  },
  {
    id: 5782,
    comune: "ROMANS D'ISONZO",
    sigla: "GO",
    codice_elettorale: 1060350120,
    codice_istat: 31015,
    codice_belfiore: "H514",
  },
  {
    id: 5783,
    comune: "ROMBIOLO",
    sigla: "VV",
    codice_elettorale: 3181030310,
    codice_istat: 102031,
    codice_belfiore: "H516",
  },
  {
    id: 5784,
    comune: "ROMENO",
    sigla: "TN",
    codice_elettorale: 1040831440,
    codice_istat: 22155,
    codice_belfiore: "H517",
  },
  {
    id: 5785,
    comune: "ROMENTINO",
    sigla: "NO",
    codice_elettorale: 1010521250,
    codice_istat: 3131,
    codice_belfiore: "H518",
  },
  {
    id: 5786,
    comune: "ROMETTA",
    sigla: "ME",
    codice_elettorale: 4190480750,
    codice_istat: 83076,
    codice_belfiore: "H519",
  },
  {
    id: 5787,
    comune: "RONAGO",
    sigla: "CO",
    codice_elettorale: 1030241880,
    codice_istat: 13199,
    codice_belfiore: "H521",
  },
  {
    id: 5788,
    comune: "RONCA'",
    sigla: "VR",
    codice_elettorale: 1050890620,
    codice_istat: 23063,
    codice_belfiore: "H522",
  },
  {
    id: 5789,
    comune: "RONCADE",
    sigla: "TV",
    codice_elettorale: 1050840680,
    codice_istat: 26069,
    codice_belfiore: "H523",
  },
  {
    id: 5790,
    comune: "RONCADELLE",
    sigla: "BS",
    codice_elettorale: 1030151560,
    codice_istat: 17165,
    codice_belfiore: "H525",
  },
  {
    id: 5791,
    comune: "RONCARO",
    sigla: "PV",
    codice_elettorale: 1030571260,
    codice_istat: 18129,
    codice_belfiore: "H527",
  },
  {
    id: 5792,
    comune: "RONCEGNO TERME",
    sigla: "TN",
    codice_elettorale: 1040831450,
    codice_istat: 22156,
    codice_belfiore: "H528",
  },
  {
    id: 5793,
    comune: "RONCELLO",
    sigla: "MB",
    codice_elettorale: 1031040375,
    codice_istat: 108055,
    codice_belfiore: "H529",
  },
  {
    id: 5794,
    comune: "RONCHI DEI LEGIONARI",
    sigla: "GO",
    codice_elettorale: 1060350130,
    codice_istat: 31016,
    codice_belfiore: "H531",
  },
  {
    id: 5795,
    comune: "RONCHI VALSUGANA",
    sigla: "TN",
    codice_elettorale: 1040831460,
    codice_istat: 22157,
    codice_belfiore: "H532",
  },
  {
    id: 5796,
    comune: "RONCHIS",
    sigla: "UD",
    codice_elettorale: 1060850970,
    codice_istat: 30097,
    codice_belfiore: "H533",
  },
  {
    id: 5797,
    comune: "RONCIGLIONE",
    sigla: "VT",
    codice_elettorale: 2120910440,
    codice_istat: 56045,
    codice_belfiore: "H534",
  },
  {
    id: 5798,
    comune: "RONCO ALL'ADIGE",
    sigla: "VR",
    codice_elettorale: 1050890630,
    codice_istat: 23064,
    codice_belfiore: "H540",
  },
  {
    id: 5799,
    comune: "RONCO BIELLESE",
    sigla: "BI",
    codice_elettorale: 1010960530,
    codice_istat: 96053,
    codice_belfiore: "H538",
  },
  {
    id: 5800,
    comune: "RONCO BRIANTINO",
    sigla: "MB",
    codice_elettorale: 1031040380,
    codice_istat: 108038,
    codice_belfiore: "H537",
  },
  {
    id: 5801,
    comune: "RONCO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812150,
    codice_istat: 1224,
    codice_belfiore: "H539",
  },
  {
    id: 5802,
    comune: "RONCO SCRIVIA",
    sigla: "GE",
    codice_elettorale: 1070340490,
    codice_istat: 10049,
    codice_belfiore: "H536",
  },
  {
    id: 5803,
    comune: "RONCOBELLO",
    sigla: "BG",
    codice_elettorale: 1030121740,
    codice_istat: 16184,
    codice_belfiore: "H535",
  },
  {
    id: 5804,
    comune: "RONCOFERRARO",
    sigla: "MN",
    codice_elettorale: 1030450520,
    codice_istat: 20052,
    codice_belfiore: "H541",
  },
  {
    id: 5805,
    comune: "RONCOFREDDO",
    sigla: "FC",
    codice_elettorale: 1080320360,
    codice_istat: 40037,
    codice_belfiore: "H542",
  },
  {
    id: 5806,
    comune: "RONCOLA",
    sigla: "BG",
    codice_elettorale: 1030121750,
    codice_istat: 16185,
    codice_belfiore: "H544",
  },
  {
    id: 5807,
    comune: "RONDANINA",
    sigla: "GE",
    codice_elettorale: 1070340500,
    codice_istat: 10050,
    codice_belfiore: "H546",
  },
  {
    id: 5808,
    comune: "RONDISSONE",
    sigla: "TO",
    codice_elettorale: 1010812160,
    codice_istat: 1225,
    codice_belfiore: "H547",
  },
  {
    id: 5809,
    comune: "RONSECCO",
    sigla: "VC",
    codice_elettorale: 1010881170,
    codice_istat: 2118,
    codice_belfiore: "H549",
  },
  {
    id: 5810,
    comune: "RONZO-CHIENIS",
    sigla: "TN",
    codice_elettorale: 1040831471,
    codice_istat: 22135,
    codice_belfiore: "M303",
  },
  {
    id: 5811,
    comune: "RONZONE",
    sigla: "TN",
    codice_elettorale: 1040831480,
    codice_istat: 22159,
    codice_belfiore: "H552",
  },
  {
    id: 5812,
    comune: "ROPPOLO",
    sigla: "BI",
    codice_elettorale: 1010960540,
    codice_istat: 96054,
    codice_belfiore: "H553",
  },
  {
    id: 5813,
    comune: "RORA'",
    sigla: "TO",
    codice_elettorale: 1010812170,
    codice_istat: 1226,
    codice_belfiore: "H554",
  },
  {
    id: 5814,
    comune: "ROSA'",
    sigla: "VI",
    codice_elettorale: 1050900870,
    codice_istat: 24087,
    codice_belfiore: "H556",
  },
  {
    id: 5815,
    comune: "ROSARNO",
    sigla: "RC",
    codice_elettorale: 3180670690,
    codice_istat: 80069,
    codice_belfiore: "H558",
  },
  {
    id: 5816,
    comune: "ROSASCO",
    sigla: "PV",
    codice_elettorale: 1030571270,
    codice_istat: 18130,
    codice_belfiore: "H559",
  },
  {
    id: 5817,
    comune: "ROSATE",
    sigla: "MI",
    codice_elettorale: 1030491870,
    codice_istat: 15188,
    codice_belfiore: "H560",
  },
  {
    id: 5818,
    comune: "ROSAZZA",
    sigla: "BI",
    codice_elettorale: 1010960550,
    codice_istat: 96055,
    codice_belfiore: "H561",
  },
  {
    id: 5819,
    comune: "ROSCIANO",
    sigla: "PE",
    codice_elettorale: 3130600350,
    codice_istat: 68035,
    codice_belfiore: "H562",
  },
  {
    id: 5820,
    comune: "ROSCIGNO",
    sigla: "SA",
    codice_elettorale: 3150721110,
    codice_istat: 65111,
    codice_belfiore: "H564",
  },
  {
    id: 5821,
    comune: "ROSE",
    sigla: "CS",
    codice_elettorale: 3180251070,
    codice_istat: 78106,
    codice_belfiore: "H565",
  },
  {
    id: 5822,
    comune: "ROSELLO",
    sigla: "CH",
    codice_elettorale: 3130230780,
    codice_istat: 69078,
    codice_belfiore: "H566",
  },
  {
    id: 5823,
    comune: "ROSETO CAPO SPULICO",
    sigla: "CS",
    codice_elettorale: 3180251080,
    codice_istat: 78107,
    codice_belfiore: "H572",
  },
  {
    id: 5824,
    comune: "ROSETO DEGLI ABRUZZI",
    sigla: "TE",
    codice_elettorale: 3130790360,
    codice_istat: 67037,
    codice_belfiore: "F585",
  },
  {
    id: 5825,
    comune: "ROSETO VALFORTORE",
    sigla: "FG",
    codice_elettorale: 3160310420,
    codice_istat: 71044,
    codice_belfiore: "H568",
  },
  {
    id: 5826,
    comune: "ROSIGNANO MARITTIMO",
    sigla: "LI",
    codice_elettorale: 2090420170,
    codice_istat: 49017,
    codice_belfiore: "H570",
  },
  {
    id: 5827,
    comune: "ROSIGNANO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021460,
    codice_istat: 6149,
    codice_belfiore: "H569",
  },
  {
    id: 5828,
    comune: "ROSOLINA",
    sigla: "RO",
    codice_elettorale: 1050710400,
    codice_istat: 29040,
    codice_belfiore: "H573",
  },
  {
    id: 5829,
    comune: "ROSOLINI",
    sigla: "SR",
    codice_elettorale: 4190760160,
    codice_istat: 89016,
    codice_belfiore: "H574",
  },
  {
    id: 5830,
    comune: "ROSORA",
    sigla: "AN",
    codice_elettorale: 2110030400,
    codice_istat: 42040,
    codice_belfiore: "H575",
  },
  {
    id: 5831,
    comune: "ROSSA",
    sigla: "VC",
    codice_elettorale: 1010881200,
    codice_istat: 2121,
    codice_belfiore: "H577",
  },
  {
    id: 5832,
    comune: "ROSSANA",
    sigla: "CN",
    codice_elettorale: 1010271970,
    codice_istat: 4197,
    codice_belfiore: "H578",
  },
  {
    id: 5833,
    comune: "ROSSANO VENETO",
    sigla: "VI",
    codice_elettorale: 1050900880,
    codice_istat: 24088,
    codice_belfiore: "H580",
  },
  {
    id: 5834,
    comune: "ROSSIGLIONE",
    sigla: "GE",
    codice_elettorale: 1070340510,
    codice_istat: 10051,
    codice_belfiore: "H581",
  },
  {
    id: 5835,
    comune: "ROSTA",
    sigla: "TO",
    codice_elettorale: 1010812190,
    codice_istat: 1228,
    codice_belfiore: "H583",
  },
  {
    id: 5836,
    comune: "ROTA D'IMAGNA",
    sigla: "BG",
    codice_elettorale: 1030121760,
    codice_istat: 16186,
    codice_belfiore: "H584",
  },
  {
    id: 5837,
    comune: "ROTA GRECA",
    sigla: "CS",
    codice_elettorale: 3180251100,
    codice_istat: 78109,
    codice_belfiore: "H585",
  },
  {
    id: 5838,
    comune: "ROTELLA",
    sigla: "AP",
    codice_elettorale: 2110060640,
    codice_istat: 44065,
    codice_belfiore: "H588",
  },
  {
    id: 5839,
    comune: "ROTELLO",
    sigla: "CB",
    codice_elettorale: 3140190610,
    codice_istat: 70061,
    codice_belfiore: "H589",
  },
  {
    id: 5840,
    comune: "ROTONDA",
    sigla: "PZ",
    codice_elettorale: 3170640690,
    codice_istat: 76070,
    codice_belfiore: "H590",
  },
  {
    id: 5841,
    comune: "ROTONDELLA",
    sigla: "MT",
    codice_elettorale: 3170470220,
    codice_istat: 77023,
    codice_belfiore: "H591",
  },
  {
    id: 5842,
    comune: "ROTONDI",
    sigla: "AV",
    codice_elettorale: 3150080790,
    codice_istat: 64080,
    codice_belfiore: "H592",
  },
  {
    id: 5843,
    comune: "ROTTOFRENO",
    sigla: "PC",
    codice_elettorale: 1080610390,
    codice_istat: 33039,
    codice_belfiore: "H593",
  },
  {
    id: 5844,
    comune: "ROTZO",
    sigla: "VI",
    codice_elettorale: 1050900890,
    codice_istat: 24089,
    codice_belfiore: "H594",
  },
  {
    id: 5845,
    comune: "ROURE",
    sigla: "TO",
    codice_elettorale: 1010812191,
    codice_istat: 1227,
    codice_belfiore: "H555",
  },
  {
    id: 5846,
    comune: "ROVASENDA",
    sigla: "VC",
    codice_elettorale: 1010881210,
    codice_istat: 2122,
    codice_belfiore: "H364",
  },
  {
    id: 5847,
    comune: "ROVATO",
    sigla: "BS",
    codice_elettorale: 1030151570,
    codice_istat: 17166,
    codice_belfiore: "H598",
  },
  {
    id: 5848,
    comune: "ROVEGNO",
    sigla: "GE",
    codice_elettorale: 1070340520,
    codice_istat: 10052,
    codice_belfiore: "H599",
  },
  {
    id: 5849,
    comune: "ROVELLASCA",
    sigla: "CO",
    codice_elettorale: 1030241890,
    codice_istat: 13201,
    codice_belfiore: "H601",
  },
  {
    id: 5850,
    comune: "ROVELLO PORRO",
    sigla: "CO",
    codice_elettorale: 1030241900,
    codice_istat: 13202,
    codice_belfiore: "H602",
  },
  {
    id: 5851,
    comune: "ROVERBELLA",
    sigla: "MN",
    codice_elettorale: 1030450530,
    codice_istat: 20053,
    codice_belfiore: "H604",
  },
  {
    id: 5852,
    comune: "ROVERCHIARA",
    sigla: "VR",
    codice_elettorale: 1050890640,
    codice_istat: 23065,
    codice_belfiore: "H606",
  },
  {
    id: 5853,
    comune: "ROVERE' DELLA LUNA",
    sigla: "TN",
    codice_elettorale: 1040831490,
    codice_istat: 22160,
    codice_belfiore: "H607",
  },
  {
    id: 5854,
    comune: "ROVERE' VERONESE",
    sigla: "VR",
    codice_elettorale: 1050890660,
    codice_istat: 23067,
    codice_belfiore: "H608",
  },
  {
    id: 5855,
    comune: "ROVEREDO DI GUA'",
    sigla: "VR",
    codice_elettorale: 1050890650,
    codice_istat: 23066,
    codice_belfiore: "H610",
  },
  {
    id: 5856,
    comune: "ROVEREDO IN PIANO",
    sigla: "PN",
    codice_elettorale: 1060930360,
    codice_istat: 93036,
    codice_belfiore: "H609",
  },
  {
    id: 5857,
    comune: "ROVERETO",
    sigla: "TN",
    codice_elettorale: 1040831500,
    codice_istat: 22161,
    codice_belfiore: "H612",
  },
  {
    id: 5858,
    comune: "ROVESCALA",
    sigla: "PV",
    codice_elettorale: 1030571280,
    codice_istat: 18131,
    codice_belfiore: "H614",
  },
  {
    id: 5859,
    comune: "ROVETTA",
    sigla: "BG",
    codice_elettorale: 1030121770,
    codice_istat: 16187,
    codice_belfiore: "H615",
  },
  {
    id: 5860,
    comune: "ROVIANO",
    sigla: "RM",
    codice_elettorale: 2120700910,
    codice_istat: 58092,
    codice_belfiore: "H618",
  },
  {
    id: 5861,
    comune: "ROVIGO",
    sigla: "RO",
    codice_elettorale: 1050710410,
    codice_istat: 29041,
    codice_belfiore: "H620",
  },
  {
    id: 5862,
    comune: "ROVITO",
    sigla: "CS",
    codice_elettorale: 3180251110,
    codice_istat: 78110,
    codice_belfiore: "H621",
  },
  {
    id: 5863,
    comune: "ROVOLON",
    sigla: "PD",
    codice_elettorale: 1050540710,
    codice_istat: 28071,
    codice_belfiore: "H622",
  },
  {
    id: 5864,
    comune: "ROZZANO",
    sigla: "MI",
    codice_elettorale: 1030491880,
    codice_istat: 15189,
    codice_belfiore: "H623",
  },
  {
    id: 5865,
    comune: "RUBANO",
    sigla: "PD",
    codice_elettorale: 1050540720,
    codice_istat: 28072,
    codice_belfiore: "H625",
  },
  {
    id: 5866,
    comune: "RUBIANA",
    sigla: "TO",
    codice_elettorale: 1010812200,
    codice_istat: 1229,
    codice_belfiore: "H627",
  },
  {
    id: 5867,
    comune: "RUBIERA",
    sigla: "RE",
    codice_elettorale: 1080680360,
    codice_istat: 35036,
    codice_belfiore: "H628",
  },
  {
    id: 5868,
    comune: "RUDA",
    sigla: "UD",
    codice_elettorale: 1060850980,
    codice_istat: 30098,
    codice_belfiore: "H629",
  },
  {
    id: 5869,
    comune: "RUDIANO",
    sigla: "BS",
    codice_elettorale: 1030151580,
    codice_istat: 17167,
    codice_belfiore: "H630",
  },
  {
    id: 5870,
    comune: "RUEGLIO",
    sigla: "TO",
    codice_elettorale: 1010812210,
    codice_istat: 1230,
    codice_belfiore: "H631",
  },
  {
    id: 5871,
    comune: "RUFFANO",
    sigla: "LE",
    codice_elettorale: 3160410630,
    codice_istat: 75064,
    codice_belfiore: "H632",
  },
  {
    id: 5872,
    comune: "RUFFIA",
    sigla: "CN",
    codice_elettorale: 1010271980,
    codice_istat: 4198,
    codice_belfiore: "H633",
  },
  {
    id: 5873,
    comune: "RUFFRE'-MENDOLA",
    sigla: "TN",
    codice_elettorale: 1040831510,
    codice_istat: 22162,
    codice_belfiore: "H634",
  },
  {
    id: 5874,
    comune: "RUFINA",
    sigla: "FI",
    codice_elettorale: 2090300370,
    codice_istat: 48037,
    codice_belfiore: "H635",
  },
  {
    id: 5875,
    comune: "RUINAS",
    sigla: "OR",
    codice_elettorale: 4200950440,
    codice_istat: 95044,
    codice_belfiore: "F271",
  },
  {
    id: 5876,
    comune: "RUMO",
    sigla: "TN",
    codice_elettorale: 1040831520,
    codice_istat: 22163,
    codice_belfiore: "H639",
  },
  {
    id: 5877,
    comune: "RUOTI",
    sigla: "PZ",
    codice_elettorale: 3170640700,
    codice_istat: 76071,
    codice_belfiore: "H641",
  },
  {
    id: 5878,
    comune: "RUSSI",
    sigla: "RA",
    codice_elettorale: 1080660160,
    codice_istat: 39016,
    codice_belfiore: "H642",
  },
  {
    id: 5879,
    comune: "RUTIGLIANO",
    sigla: "BA",
    codice_elettorale: 3160090360,
    codice_istat: 72037,
    codice_belfiore: "H643",
  },
  {
    id: 5880,
    comune: "RUTINO",
    sigla: "SA",
    codice_elettorale: 3150721120,
    codice_istat: 65112,
    codice_belfiore: "H644",
  },
  {
    id: 5881,
    comune: "RUVIANO",
    sigla: "CE",
    codice_elettorale: 3150200730,
    codice_istat: 61073,
    codice_belfiore: "H165",
  },
  {
    id: 5882,
    comune: "RUVO DEL MONTE",
    sigla: "PZ",
    codice_elettorale: 3170640710,
    codice_istat: 76072,
    codice_belfiore: "H646",
  },
  {
    id: 5883,
    comune: "RUVO DI PUGLIA",
    sigla: "BA",
    codice_elettorale: 3160090370,
    codice_istat: 72038,
    codice_belfiore: "H645",
  },
  {
    id: 5884,
    comune: "SABAUDIA",
    sigla: "LT",
    codice_elettorale: 2120400240,
    codice_istat: 59024,
    codice_belfiore: "H647",
  },
  {
    id: 5885,
    comune: "SABBIO CHIESE",
    sigla: "BS",
    codice_elettorale: 1030151590,
    codice_istat: 17168,
    codice_belfiore: "H650",
  },
  {
    id: 5886,
    comune: "SABBIONETA",
    sigla: "MN",
    codice_elettorale: 1030450540,
    codice_istat: 20054,
    codice_belfiore: "H652",
  },
  {
    id: 5887,
    comune: "SACCO",
    sigla: "SA",
    codice_elettorale: 3150721130,
    codice_istat: 65113,
    codice_belfiore: "H654",
  },
  {
    id: 5888,
    comune: "SACCOLONGO",
    sigla: "PD",
    codice_elettorale: 1050540730,
    codice_istat: 28073,
    codice_belfiore: "H655",
  },
  {
    id: 5889,
    comune: "SACILE",
    sigla: "PN",
    codice_elettorale: 1060930370,
    codice_istat: 93037,
    codice_belfiore: "H657",
  },
  {
    id: 5890,
    comune: "SACROFANO",
    sigla: "RM",
    codice_elettorale: 2120700920,
    codice_istat: 58093,
    codice_belfiore: "H658",
  },
  {
    id: 5891,
    comune: "SADALI",
    sigla: "SU",
    codice_elettorale: 4201110580,
    codice_istat: 111058,
    codice_belfiore: "H659",
  },
  {
    id: 5892,
    comune: "SAGAMA",
    sigla: "OR",
    codice_elettorale: 4200950446,
    codice_istat: 95086,
    codice_belfiore: "H661",
  },
  {
    id: 5893,
    comune: "SAGLIANO MICCA",
    sigla: "BI",
    codice_elettorale: 1010960560,
    codice_istat: 96056,
    codice_belfiore: "H662",
  },
  {
    id: 5894,
    comune: "SAGRADO",
    sigla: "GO",
    codice_elettorale: 1060350140,
    codice_istat: 31017,
    codice_belfiore: "H665",
  },
  {
    id: 5895,
    comune: "SAGRON MIS",
    sigla: "TN",
    codice_elettorale: 1040831530,
    codice_istat: 22164,
    codice_belfiore: "H666",
  },
  {
    id: 5896,
    comune: "SAINT-CHRISTOPHE",
    sigla: "AO",
    codice_elettorale: 1020040570,
    codice_istat: 7058,
    codice_belfiore: "H669",
  },
  {
    id: 5897,
    comune: "SAINT-DENIS",
    sigla: "AO",
    codice_elettorale: 1020040580,
    codice_istat: 7059,
    codice_belfiore: "H670",
  },
  {
    id: 5898,
    comune: "SAINT-MARCEL",
    sigla: "AO",
    codice_elettorale: 1020040590,
    codice_istat: 7060,
    codice_belfiore: "H671",
  },
  {
    id: 5899,
    comune: "SAINT-NICOLAS",
    sigla: "AO",
    codice_elettorale: 1020040600,
    codice_istat: 7061,
    codice_belfiore: "H672",
  },
  {
    id: 5900,
    comune: "SAINT-OYEN",
    sigla: "AO",
    codice_elettorale: 1020040610,
    codice_istat: 7062,
    codice_belfiore: "H673",
  },
  {
    id: 5901,
    comune: "SAINT-PIERRE",
    sigla: "AO",
    codice_elettorale: 1020040620,
    codice_istat: 7063,
    codice_belfiore: "H674",
  },
  {
    id: 5902,
    comune: "SAINT-RHEMY-EN-BOSSES",
    sigla: "AO",
    codice_elettorale: 1020040630,
    codice_istat: 7064,
    codice_belfiore: "H675",
  },
  {
    id: 5903,
    comune: "SAINT-VINCENT",
    sigla: "AO",
    codice_elettorale: 1020040640,
    codice_istat: 7065,
    codice_belfiore: "H676",
  },
  {
    id: 5904,
    comune: "SALA BAGANZA",
    sigla: "PR",
    codice_elettorale: 1080560310,
    codice_istat: 34031,
    codice_belfiore: "H682",
  },
  {
    id: 5905,
    comune: "SALA BIELLESE",
    sigla: "BI",
    codice_elettorale: 1010960570,
    codice_istat: 96057,
    codice_belfiore: "H681",
  },
  {
    id: 5906,
    comune: "SALA BOLOGNESE",
    sigla: "BO",
    codice_elettorale: 1080130500,
    codice_istat: 37050,
    codice_belfiore: "H678",
  },
  {
    id: 5907,
    comune: "SALA COMACINA",
    sigla: "CO",
    codice_elettorale: 1030241910,
    codice_istat: 13203,
    codice_belfiore: "H679",
  },
  {
    id: 5908,
    comune: "SALA CONSILINA",
    sigla: "SA",
    codice_elettorale: 3150721140,
    codice_istat: 65114,
    codice_belfiore: "H683",
  },
  {
    id: 5909,
    comune: "SALA MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021470,
    codice_istat: 6150,
    codice_belfiore: "H677",
  },
  {
    id: 5910,
    comune: "SALANDRA",
    sigla: "MT",
    codice_elettorale: 3170470230,
    codice_istat: 77024,
    codice_belfiore: "H687",
  },
  {
    id: 5911,
    comune: "SALAPARUTA",
    sigla: "TP",
    codice_elettorale: 4190820180,
    codice_istat: 81017,
    codice_belfiore: "H688",
  },
  {
    id: 5912,
    comune: "SALARA",
    sigla: "RO",
    codice_elettorale: 1050710420,
    codice_istat: 29042,
    codice_belfiore: "H689",
  },
  {
    id: 5913,
    comune: "SALASCO",
    sigla: "VC",
    codice_elettorale: 1010881250,
    codice_istat: 2126,
    codice_belfiore: "H690",
  },
  {
    id: 5914,
    comune: "SALASSA",
    sigla: "TO",
    codice_elettorale: 1010812230,
    codice_istat: 1231,
    codice_belfiore: "H691",
  },
  {
    id: 5915,
    comune: "SALBERTRAND",
    sigla: "TO",
    codice_elettorale: 1010812231,
    codice_istat: 1232,
    codice_belfiore: "H684",
  },
  {
    id: 5916,
    comune: "SALCEDO",
    sigla: "VI",
    codice_elettorale: 1050900900,
    codice_istat: 24090,
    codice_belfiore: "F810",
  },
  {
    id: 5917,
    comune: "SALCITO",
    sigla: "CB",
    codice_elettorale: 3140190620,
    codice_istat: 70062,
    codice_belfiore: "H693",
  },
  {
    id: 5918,
    comune: "SALE",
    sigla: "AL",
    codice_elettorale: 1010021480,
    codice_istat: 6151,
    codice_belfiore: "H694",
  },
  {
    id: 5919,
    comune: "SALE DELLE LANGHE",
    sigla: "CN",
    codice_elettorale: 1010271990,
    codice_istat: 4199,
    codice_belfiore: "H695",
  },
  {
    id: 5920,
    comune: "SALE MARASINO",
    sigla: "BS",
    codice_elettorale: 1030151600,
    codice_istat: 17169,
    codice_belfiore: "H699",
  },
  {
    id: 5921,
    comune: "SALE SAN GIOVANNI",
    sigla: "CN",
    codice_elettorale: 1010272000,
    codice_istat: 4200,
    codice_belfiore: "H704",
  },
  {
    id: 5922,
    comune: "SALEMI",
    sigla: "TP",
    codice_elettorale: 4190820190,
    codice_istat: 81018,
    codice_belfiore: "H700",
  },
  {
    id: 5923,
    comune: "SALENTO",
    sigla: "SA",
    codice_elettorale: 3150721150,
    codice_istat: 65115,
    codice_belfiore: "H686",
  },
  {
    id: 5924,
    comune: "SALERANO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812240,
    codice_istat: 1233,
    codice_belfiore: "H702",
  },
  {
    id: 5925,
    comune: "SALERANO SUL LAMBRO",
    sigla: "LO",
    codice_elettorale: 1030990460,
    codice_istat: 98046,
    codice_belfiore: "H701",
  },
  {
    id: 5926,
    comune: "SALERNO",
    sigla: "SA",
    codice_elettorale: 3150721160,
    codice_istat: 65116,
    codice_belfiore: "H703",
  },
  {
    id: 5927,
    comune: "SALGAREDA",
    sigla: "TV",
    codice_elettorale: 1050840690,
    codice_istat: 26070,
    codice_belfiore: "H706",
  },
  {
    id: 5928,
    comune: "SALI VERCELLESE",
    sigla: "VC",
    codice_elettorale: 1010881260,
    codice_istat: 2127,
    codice_belfiore: "H707",
  },
  {
    id: 5929,
    comune: "SALICE SALENTINO",
    sigla: "LE",
    codice_elettorale: 3160410640,
    codice_istat: 75065,
    codice_belfiore: "H708",
  },
  {
    id: 5930,
    comune: "SALICETO",
    sigla: "CN",
    codice_elettorale: 1010272010,
    codice_istat: 4201,
    codice_belfiore: "H710",
  },
  {
    id: 5931,
    comune: "SALISANO",
    sigla: "RI",
    codice_elettorale: 2120690610,
    codice_istat: 57063,
    codice_belfiore: "H713",
  },
  {
    id: 5932,
    comune: "SALIZZOLE",
    sigla: "VR",
    codice_elettorale: 1050890670,
    codice_istat: 23068,
    codice_belfiore: "H714",
  },
  {
    id: 5933,
    comune: "SALLE",
    sigla: "PE",
    codice_elettorale: 3130600360,
    codice_istat: 68036,
    codice_belfiore: "H715",
  },
  {
    id: 5934,
    comune: "SALMOUR",
    sigla: "CN",
    codice_elettorale: 1010272020,
    codice_istat: 4202,
    codice_belfiore: "H716",
  },
  {
    id: 5935,
    comune: "SALO'",
    sigla: "BS",
    codice_elettorale: 1030151610,
    codice_istat: 17170,
    codice_belfiore: "H717",
  },
  {
    id: 5936,
    comune: "SALORNO SULLA STRADA DEL VINO/SALURN AN DER WEINSTRASSE",
    sigla: "BZ",
    codice_elettorale: 1040140710,
    codice_istat: 21076,
    codice_belfiore: "H719",
  },
  {
    id: 5937,
    comune: "SALSOMAGGIORE TERME",
    sigla: "PR",
    codice_elettorale: 1080560320,
    codice_istat: 34032,
    codice_belfiore: "H720",
  },
  {
    id: 5938,
    comune: "SALTRIO",
    sigla: "VA",
    codice_elettorale: 1030860991,
    codice_istat: 12117,
    codice_belfiore: "H723",
  },
  {
    id: 5939,
    comune: "SALUDECIO",
    sigla: "RN",
    codice_elettorale: 1081010150,
    codice_istat: 99015,
    codice_belfiore: "H724",
  },
  {
    id: 5940,
    comune: "SALUGGIA",
    sigla: "VC",
    codice_elettorale: 1010881270,
    codice_istat: 2128,
    codice_belfiore: "H725",
  },
  {
    id: 5941,
    comune: "SALUSSOLA",
    sigla: "BI",
    codice_elettorale: 1010960580,
    codice_istat: 96058,
    codice_belfiore: "H726",
  },
  {
    id: 5942,
    comune: "SALUZZO",
    sigla: "CN",
    codice_elettorale: 1010272030,
    codice_istat: 4203,
    codice_belfiore: "H727",
  },
  {
    id: 5943,
    comune: "SALVE",
    sigla: "LE",
    codice_elettorale: 3160410650,
    codice_istat: 75066,
    codice_belfiore: "H729",
  },
  {
    id: 5944,
    comune: "SALVIROLA",
    sigla: "CR",
    codice_elettorale: 1030260840,
    codice_istat: 19087,
    codice_belfiore: "H731",
  },
  {
    id: 5945,
    comune: "SALVITELLE",
    sigla: "SA",
    codice_elettorale: 3150721170,
    codice_istat: 65117,
    codice_belfiore: "H732",
  },
  {
    id: 5946,
    comune: "SALZA DI PINEROLO",
    sigla: "TO",
    codice_elettorale: 1010812250,
    codice_istat: 1234,
    codice_belfiore: "H734",
  },
  {
    id: 5947,
    comune: "SALZA IRPINA",
    sigla: "AV",
    codice_elettorale: 3150080800,
    codice_istat: 64081,
    codice_belfiore: "H733",
  },
  {
    id: 5948,
    comune: "SALZANO",
    sigla: "VE",
    codice_elettorale: 1050870320,
    codice_istat: 27032,
    codice_belfiore: "H735",
  },
  {
    id: 5949,
    comune: "SAMARATE",
    sigla: "VA",
    codice_elettorale: 1030861000,
    codice_istat: 12118,
    codice_belfiore: "H736",
  },
  {
    id: 5950,
    comune: "SAMASSI",
    sigla: "SU",
    codice_elettorale: 4201110590,
    codice_istat: 111059,
    codice_belfiore: "H738",
  },
  {
    id: 5951,
    comune: "SAMATZAI",
    sigla: "SU",
    codice_elettorale: 4201110600,
    codice_istat: 111060,
    codice_belfiore: "H739",
  },
  {
    id: 5952,
    comune: "SAMBUCA DI SICILIA",
    sigla: "AG",
    codice_elettorale: 4190010340,
    codice_istat: 84034,
    codice_belfiore: "H743",
  },
  {
    id: 5953,
    comune: "SAMBUCA PISTOIESE",
    sigla: "PT",
    codice_elettorale: 2090630170,
    codice_istat: 47018,
    codice_belfiore: "H744",
  },
  {
    id: 5954,
    comune: "SAMBUCI",
    sigla: "RM",
    codice_elettorale: 2120700930,
    codice_istat: 58094,
    codice_belfiore: "H745",
  },
  {
    id: 5955,
    comune: "SAMBUCO",
    sigla: "CN",
    codice_elettorale: 1010272040,
    codice_istat: 4204,
    codice_belfiore: "H746",
  },
  {
    id: 5956,
    comune: "SAMMICHELE DI BARI",
    sigla: "BA",
    codice_elettorale: 3160090380,
    codice_istat: 72039,
    codice_belfiore: "H749",
  },
  {
    id: 5957,
    comune: "SAMO",
    sigla: "RC",
    codice_elettorale: 3180670700,
    codice_istat: 80070,
    codice_belfiore: "H013",
  },
  {
    id: 5958,
    comune: "SAMOLACO",
    sigla: "SO",
    codice_elettorale: 1030770570,
    codice_istat: 14057,
    codice_belfiore: "H752",
  },
  {
    id: 5959,
    comune: "SAMONE",
    sigla: "TO",
    codice_elettorale: 1010812260,
    codice_istat: 1235,
    codice_belfiore: "H753",
  },
  {
    id: 5960,
    comune: "SAMONE",
    sigla: "TN",
    codice_elettorale: 1040831540,
    codice_istat: 22165,
    codice_belfiore: "H754",
  },
  {
    id: 5961,
    comune: "SAMPEYRE",
    sigla: "CN",
    codice_elettorale: 1010272050,
    codice_istat: 4205,
    codice_belfiore: "H755",
  },
  {
    id: 5962,
    comune: "SAMUGHEO",
    sigla: "OR",
    codice_elettorale: 4200950450,
    codice_istat: 95045,
    codice_belfiore: "H756",
  },
  {
    id: 5963,
    comune: "SAN BARTOLOMEO AL MARE",
    sigla: "IM",
    codice_elettorale: 1070370490,
    codice_istat: 8052,
    codice_belfiore: "H763",
  },
  {
    id: 5964,
    comune: "SAN BARTOLOMEO IN GALDO",
    sigla: "BN",
    codice_elettorale: 3150110560,
    codice_istat: 62057,
    codice_belfiore: "H764",
  },
  {
    id: 5965,
    comune: "SAN BARTOLOMEO VAL CAVARGNA",
    sigla: "CO",
    codice_elettorale: 1030241920,
    codice_istat: 13204,
    codice_belfiore: "H760",
  },
  {
    id: 5966,
    comune: "SAN BASILE",
    sigla: "CS",
    codice_elettorale: 3180251120,
    codice_istat: 78111,
    codice_belfiore: "H765",
  },
  {
    id: 5967,
    comune: "SAN BASILIO",
    sigla: "SU",
    codice_elettorale: 4201110610,
    codice_istat: 111061,
    codice_belfiore: "H766",
  },
  {
    id: 5968,
    comune: "SAN BASSANO",
    sigla: "CR",
    codice_elettorale: 1030260850,
    codice_istat: 19088,
    codice_belfiore: "H767",
  },
  {
    id: 5969,
    comune: "SAN BELLINO",
    sigla: "RO",
    codice_elettorale: 1050710430,
    codice_istat: 29043,
    codice_belfiore: "H768",
  },
  {
    id: 5970,
    comune: "SAN BENEDETTO BELBO",
    sigla: "CN",
    codice_elettorale: 1010272060,
    codice_istat: 4206,
    codice_belfiore: "H770",
  },
  {
    id: 5971,
    comune: "SAN BENEDETTO DEI MARSI",
    sigla: "AQ",
    codice_elettorale: 3130380850,
    codice_istat: 66085,
    codice_belfiore: "H772",
  },
  {
    id: 5972,
    comune: "SAN BENEDETTO DEL TRONTO",
    sigla: "AP",
    codice_elettorale: 2110060650,
    codice_istat: 44066,
    codice_belfiore: "H769",
  },
  {
    id: 5973,
    comune: "SAN BENEDETTO IN PERILLIS",
    sigla: "AQ",
    codice_elettorale: 3130380860,
    codice_istat: 66086,
    codice_belfiore: "H773",
  },
  {
    id: 5974,
    comune: "SAN BENEDETTO PO",
    sigla: "MN",
    codice_elettorale: 1030450550,
    codice_istat: 20055,
    codice_belfiore: "H771",
  },
  {
    id: 5975,
    comune: "SAN BENEDETTO ULLANO",
    sigla: "CS",
    codice_elettorale: 3180251130,
    codice_istat: 78112,
    codice_belfiore: "H774",
  },
  {
    id: 5976,
    comune: "SAN BENEDETTO VAL DI SAMBRO",
    sigla: "BO",
    codice_elettorale: 1080130510,
    codice_istat: 37051,
    codice_belfiore: "G566",
  },
  {
    id: 5977,
    comune: "SAN BENIGNO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812270,
    codice_istat: 1236,
    codice_belfiore: "H775",
  },
  {
    id: 5978,
    comune: "SAN BERNARDINO VERBANO",
    sigla: "VB",
    codice_elettorale: 1011020610,
    codice_istat: 103061,
    codice_belfiore: "H777",
  },
  {
    id: 5979,
    comune: "SAN BIAGIO DELLA CIMA",
    sigla: "IM",
    codice_elettorale: 1070370500,
    codice_istat: 8053,
    codice_belfiore: "H780",
  },
  {
    id: 5980,
    comune: "SAN BIAGIO DI CALLALTA",
    sigla: "TV",
    codice_elettorale: 1050840700,
    codice_istat: 26071,
    codice_belfiore: "H781",
  },
  {
    id: 5981,
    comune: "SAN BIAGIO PLATANI",
    sigla: "AG",
    codice_elettorale: 4190010350,
    codice_istat: 84035,
    codice_belfiore: "H778",
  },
  {
    id: 5982,
    comune: "SAN BIAGIO SARACINISCO",
    sigla: "FR",
    codice_elettorale: 2120330600,
    codice_istat: 60061,
    codice_belfiore: "H779",
  },
  {
    id: 5983,
    comune: "SAN BIASE",
    sigla: "CB",
    codice_elettorale: 3140190630,
    codice_istat: 70063,
    codice_belfiore: "H782",
  },
  {
    id: 5984,
    comune: "SAN BONIFACIO",
    sigla: "VR",
    codice_elettorale: 1050890680,
    codice_istat: 23069,
    codice_belfiore: "H783",
  },
  {
    id: 5985,
    comune: "SAN BUONO",
    sigla: "CH",
    codice_elettorale: 3130230790,
    codice_istat: 69079,
    codice_belfiore: "H784",
  },
  {
    id: 5986,
    comune: "SAN CALOGERO",
    sigla: "VV",
    codice_elettorale: 3181030320,
    codice_istat: 102032,
    codice_belfiore: "H785",
  },
  {
    id: 5987,
    comune: "SAN CANDIDO/INNICHEN",
    sigla: "BZ",
    codice_elettorale: 1040140720,
    codice_istat: 21077,
    codice_belfiore: "H786",
  },
  {
    id: 5988,
    comune: "SAN CANZIAN D'ISONZO",
    sigla: "GO",
    codice_elettorale: 1060350150,
    codice_istat: 31018,
    codice_belfiore: "H787",
  },
  {
    id: 5989,
    comune: "SAN CARLO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812280,
    codice_istat: 1237,
    codice_belfiore: "H789",
  },
  {
    id: 5990,
    comune: "SAN CASCIANO DEI BAGNI",
    sigla: "SI",
    codice_elettorale: 2090750270,
    codice_istat: 52027,
    codice_belfiore: "H790",
  },
  {
    id: 5991,
    comune: "SAN CASCIANO IN VAL DI PESA",
    sigla: "FI",
    codice_elettorale: 2090300380,
    codice_istat: 48038,
    codice_belfiore: "H791",
  },
  {
    id: 5992,
    comune: "SAN CASSIANO",
    sigla: "LE",
    codice_elettorale: 3160410661,
    codice_istat: 75095,
    codice_belfiore: "M264",
  },
  {
    id: 5993,
    comune: "SAN CATALDO",
    sigla: "CL",
    codice_elettorale: 4190180160,
    codice_istat: 85016,
    codice_belfiore: "H792",
  },
  {
    id: 5994,
    comune: "SAN CESAREO",
    sigla: "RM",
    codice_elettorale: 2120700931,
    codice_istat: 58119,
    codice_belfiore: "M295",
  },
  {
    id: 5995,
    comune: "SAN CESARIO DI LECCE",
    sigla: "LE",
    codice_elettorale: 3160410670,
    codice_istat: 75068,
    codice_belfiore: "H793",
  },
  {
    id: 5996,
    comune: "SAN CESARIO SUL PANARO",
    sigla: "MO",
    codice_elettorale: 1080500350,
    codice_istat: 36036,
    codice_belfiore: "H794",
  },
  {
    id: 5997,
    comune: "SAN CHIRICO NUOVO",
    sigla: "PZ",
    codice_elettorale: 3170640720,
    codice_istat: 76073,
    codice_belfiore: "H795",
  },
  {
    id: 5998,
    comune: "SAN CHIRICO RAPARO",
    sigla: "PZ",
    codice_elettorale: 3170640730,
    codice_istat: 76074,
    codice_belfiore: "H796",
  },
  {
    id: 5999,
    comune: "SAN CIPIRELLO",
    sigla: "PA",
    codice_elettorale: 4190550610,
    codice_istat: 82063,
    codice_belfiore: "H797",
  },
  {
    id: 6000,
    comune: "SAN CIPRIANO D'AVERSA",
    sigla: "CE",
    codice_elettorale: 3150200740,
    codice_istat: 61074,
    codice_belfiore: "H798",
  },
  {
    id: 6001,
    comune: "SAN CIPRIANO PICENTINO",
    sigla: "SA",
    codice_elettorale: 3150721180,
    codice_istat: 65118,
    codice_belfiore: "H800",
  },
  {
    id: 6002,
    comune: "SAN CIPRIANO PO",
    sigla: "PV",
    codice_elettorale: 1030571300,
    codice_istat: 18133,
    codice_belfiore: "H799",
  },
  {
    id: 6003,
    comune: "SAN CLEMENTE",
    sigla: "RN",
    codice_elettorale: 1081010160,
    codice_istat: 99016,
    codice_belfiore: "H801",
  },
  {
    id: 6004,
    comune: "SAN COLOMBANO AL LAMBRO",
    sigla: "MI",
    codice_elettorale: 1030491900,
    codice_istat: 15191,
    codice_belfiore: "H803",
  },
  {
    id: 6005,
    comune: "SAN COLOMBANO BELMONTE",
    sigla: "TO",
    codice_elettorale: 1010812290,
    codice_istat: 1238,
    codice_belfiore: "H804",
  },
  {
    id: 6006,
    comune: "SAN COLOMBANO CERTENOLI",
    sigla: "GE",
    codice_elettorale: 1070340530,
    codice_istat: 10053,
    codice_belfiore: "H802",
  },
  {
    id: 6007,
    comune: "SAN CONO",
    sigla: "CT",
    codice_elettorale: 4190210390,
    codice_istat: 87040,
    codice_belfiore: "H805",
  },
  {
    id: 6008,
    comune: "SAN COSMO ALBANESE",
    sigla: "CS",
    codice_elettorale: 3180251140,
    codice_istat: 78113,
    codice_belfiore: "H806",
  },
  {
    id: 6009,
    comune: "SAN COSTANTINO ALBANESE",
    sigla: "PZ",
    codice_elettorale: 3170640740,
    codice_istat: 76075,
    codice_belfiore: "H808",
  },
  {
    id: 6010,
    comune: "SAN COSTANTINO CALABRO",
    sigla: "VV",
    codice_elettorale: 3181030330,
    codice_istat: 102033,
    codice_belfiore: "H807",
  },
  {
    id: 6011,
    comune: "SAN COSTANZO",
    sigla: "PU",
    codice_elettorale: 2110590510,
    codice_istat: 41051,
    codice_belfiore: "H809",
  },
  {
    id: 6012,
    comune: "SAN CRISTOFORO",
    sigla: "AL",
    codice_elettorale: 1010021490,
    codice_istat: 6152,
    codice_belfiore: "H810",
  },
  {
    id: 6013,
    comune: "SAN DAMIANO AL COLLE",
    sigla: "PV",
    codice_elettorale: 1030571310,
    codice_istat: 18134,
    codice_belfiore: "H814",
  },
  {
    id: 6014,
    comune: "SAN DAMIANO D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010070970,
    codice_istat: 5097,
    codice_belfiore: "H811",
  },
  {
    id: 6015,
    comune: "SAN DAMIANO MACRA",
    sigla: "CN",
    codice_elettorale: 1010272070,
    codice_istat: 4207,
    codice_belfiore: "H812",
  },
  {
    id: 6016,
    comune: "SAN DANIELE DEL FRIULI",
    sigla: "UD",
    codice_elettorale: 1060850990,
    codice_istat: 30099,
    codice_belfiore: "H816",
  },
  {
    id: 6017,
    comune: "SAN DANIELE PO",
    sigla: "CR",
    codice_elettorale: 1030260860,
    codice_istat: 19089,
    codice_belfiore: "H815",
  },
  {
    id: 6018,
    comune: "SAN DEMETRIO CORONE",
    sigla: "CS",
    codice_elettorale: 3180251150,
    codice_istat: 78114,
    codice_belfiore: "H818",
  },
  {
    id: 6019,
    comune: "SAN DEMETRIO NE' VESTINI",
    sigla: "AQ",
    codice_elettorale: 3130380870,
    codice_istat: 66087,
    codice_belfiore: "H819",
  },
  {
    id: 6020,
    comune: "SAN DIDERO",
    sigla: "TO",
    codice_elettorale: 1010812300,
    codice_istat: 1239,
    codice_belfiore: "H820",
  },
  {
    id: 6021,
    comune: "SAN DONA' DI PIAVE",
    sigla: "VE",
    codice_elettorale: 1050870330,
    codice_istat: 27033,
    codice_belfiore: "H823",
  },
  {
    id: 6022,
    comune: "SAN DONACI",
    sigla: "BR",
    codice_elettorale: 3160160130,
    codice_istat: 74013,
    codice_belfiore: "H822",
  },
  {
    id: 6023,
    comune: "SAN DONATO DI LECCE",
    sigla: "LE",
    codice_elettorale: 3160410680,
    codice_istat: 75069,
    codice_belfiore: "H826",
  },
  {
    id: 6024,
    comune: "SAN DONATO DI NINEA",
    sigla: "CS",
    codice_elettorale: 3180251160,
    codice_istat: 78115,
    codice_belfiore: "H825",
  },
  {
    id: 6025,
    comune: "SAN DONATO MILANESE",
    sigla: "MI",
    codice_elettorale: 1030491910,
    codice_istat: 15192,
    codice_belfiore: "H827",
  },
  {
    id: 6026,
    comune: "SAN DONATO VAL DI COMINO",
    sigla: "FR",
    codice_elettorale: 2120330610,
    codice_istat: 60062,
    codice_belfiore: "H824",
  },
  {
    id: 6027,
    comune: "SAN DORLIGO DELLA VALLE-DOLINA",
    sigla: "TS",
    codice_elettorale: 1060920040,
    codice_istat: 32004,
    codice_belfiore: "D324",
  },
  {
    id: 6028,
    comune: "SAN FELE",
    sigla: "PZ",
    codice_elettorale: 3170640750,
    codice_istat: 76076,
    codice_belfiore: "H831",
  },
  {
    id: 6029,
    comune: "SAN FELICE A CANCELLO",
    sigla: "CE",
    codice_elettorale: 3150200750,
    codice_istat: 61075,
    codice_belfiore: "H834",
  },
  {
    id: 6030,
    comune: "SAN FELICE CIRCEO",
    sigla: "LT",
    codice_elettorale: 2120400250,
    codice_istat: 59025,
    codice_belfiore: "H836",
  },
  {
    id: 6031,
    comune: "SAN FELICE DEL BENACO",
    sigla: "BS",
    codice_elettorale: 1030151620,
    codice_istat: 17171,
    codice_belfiore: "H838",
  },
  {
    id: 6032,
    comune: "SAN FELICE DEL MOLISE",
    sigla: "CB",
    codice_elettorale: 3140190640,
    codice_istat: 70064,
    codice_belfiore: "H833",
  },
  {
    id: 6033,
    comune: "SAN FELICE SUL PANARO",
    sigla: "MO",
    codice_elettorale: 1080500360,
    codice_istat: 36037,
    codice_belfiore: "H835",
  },
  {
    id: 6034,
    comune: "SAN FERDINANDO",
    sigla: "RC",
    codice_elettorale: 3180670701,
    codice_istat: 80097,
    codice_belfiore: "M277",
  },
  {
    id: 6035,
    comune: "SAN FERDINANDO DI PUGLIA",
    sigla: "BT",
    codice_elettorale: 3161060070,
    codice_istat: 110007,
    codice_belfiore: "H839",
  },
  {
    id: 6036,
    comune: "SAN FERMO DELLA BATTAGLIA",
    sigla: "CO",
    codice_elettorale: 1030241940,
    codice_istat: 13206,
    codice_belfiore: "H840",
  },
  {
    id: 6037,
    comune: "SAN FILI",
    sigla: "CS",
    codice_elettorale: 3180251170,
    codice_istat: 78116,
    codice_belfiore: "H841",
  },
  {
    id: 6038,
    comune: "SAN FILIPPO DEL MELA",
    sigla: "ME",
    codice_elettorale: 4190480760,
    codice_istat: 83077,
    codice_belfiore: "H842",
  },
  {
    id: 6039,
    comune: "SAN FIOR",
    sigla: "TV",
    codice_elettorale: 1050840710,
    codice_istat: 26072,
    codice_belfiore: "H843",
  },
  {
    id: 6040,
    comune: "SAN FIORANO",
    sigla: "LO",
    codice_elettorale: 1030990470,
    codice_istat: 98047,
    codice_belfiore: "H844",
  },
  {
    id: 6041,
    comune: "SAN FLORIANO DEL COLLIO",
    sigla: "GO",
    codice_elettorale: 1060350160,
    codice_istat: 31019,
    codice_belfiore: "H845",
  },
  {
    id: 6042,
    comune: "SAN FLORO",
    sigla: "CZ",
    codice_elettorale: 3180221050,
    codice_istat: 79108,
    codice_belfiore: "H846",
  },
  {
    id: 6043,
    comune: "SAN FRANCESCO AL CAMPO",
    sigla: "TO",
    codice_elettorale: 1010812310,
    codice_istat: 1240,
    codice_belfiore: "H847",
  },
  {
    id: 6044,
    comune: "SAN FRATELLO",
    sigla: "ME",
    codice_elettorale: 4190480770,
    codice_istat: 83078,
    codice_belfiore: "H850",
  },
  {
    id: 6045,
    comune: "SAN GAVINO MONREALE",
    sigla: "SU",
    codice_elettorale: 4201110620,
    codice_istat: 111062,
    codice_belfiore: "H856",
  },
  {
    id: 6046,
    comune: "SAN GEMINI",
    sigla: "TR",
    codice_elettorale: 2100800290,
    codice_istat: 55029,
    codice_belfiore: "H857",
  },
  {
    id: 6047,
    comune: "SAN GENESIO ATESINO/JENESIEN",
    sigla: "BZ",
    codice_elettorale: 1040140740,
    codice_istat: 21079,
    codice_belfiore: "H858",
  },
  {
    id: 6048,
    comune: "SAN GENESIO ED UNITI",
    sigla: "PV",
    codice_elettorale: 1030571320,
    codice_istat: 18135,
    codice_belfiore: "H859",
  },
  {
    id: 6049,
    comune: "SAN GENNARO VESUVIANO",
    sigla: "NA",
    codice_elettorale: 3150510660,
    codice_istat: 63066,
    codice_belfiore: "H860",
  },
  {
    id: 6050,
    comune: "SAN GERMANO CHISONE",
    sigla: "TO",
    codice_elettorale: 1010812320,
    codice_istat: 1242,
    codice_belfiore: "H862",
  },
  {
    id: 6051,
    comune: "SAN GERMANO VERCELLESE",
    sigla: "VC",
    codice_elettorale: 1010881300,
    codice_istat: 2131,
    codice_belfiore: "H861",
  },
  {
    id: 6052,
    comune: "SAN GERVASIO BRESCIANO",
    sigla: "BS",
    codice_elettorale: 1030151630,
    codice_istat: 17172,
    codice_belfiore: "H865",
  },
  {
    id: 6053,
    comune: "SAN GIACOMO DEGLI SCHIAVONI",
    sigla: "CB",
    codice_elettorale: 3140190650,
    codice_istat: 70065,
    codice_belfiore: "H867",
  },
  {
    id: 6054,
    comune: "SAN GIACOMO DELLE SEGNATE",
    sigla: "MN",
    codice_elettorale: 1030450560,
    codice_istat: 20056,
    codice_belfiore: "H870",
  },
  {
    id: 6055,
    comune: "SAN GIACOMO FILIPPO",
    sigla: "SO",
    codice_elettorale: 1030770580,
    codice_istat: 14058,
    codice_belfiore: "H868",
  },
  {
    id: 6056,
    comune: "SAN GIACOMO VERCELLESE",
    sigla: "VC",
    codice_elettorale: 1010881301,
    codice_istat: 2035,
    codice_belfiore: "B952",
  },
  {
    id: 6057,
    comune: "SAN GILLIO",
    sigla: "TO",
    codice_elettorale: 1010812330,
    codice_istat: 1243,
    codice_belfiore: "H873",
  },
  {
    id: 6058,
    comune: "SAN GIMIGNANO",
    sigla: "SI",
    codice_elettorale: 2090750280,
    codice_istat: 52028,
    codice_belfiore: "H875",
  },
  {
    id: 6059,
    comune: "SAN GINESIO",
    sigla: "MC",
    codice_elettorale: 2110440460,
    codice_istat: 43046,
    codice_belfiore: "H876",
  },
  {
    id: 6060,
    comune: "SAN GIORGIO A CREMANO",
    sigla: "NA",
    codice_elettorale: 3150510670,
    codice_istat: 63067,
    codice_belfiore: "H892",
  },
  {
    id: 6061,
    comune: "SAN GIORGIO A LIRI",
    sigla: "FR",
    codice_elettorale: 2120330620,
    codice_istat: 60063,
    codice_belfiore: "H880",
  },
  {
    id: 6062,
    comune: "SAN GIORGIO ALBANESE",
    sigla: "CS",
    codice_elettorale: 3180251190,
    codice_istat: 78118,
    codice_belfiore: "H881",
  },
  {
    id: 6063,
    comune: "SAN GIORGIO BIGARELLO",
    sigla: "MN",
    codice_elettorale: 1030450570,
    codice_istat: 20057,
    codice_belfiore: "H883",
  },
  {
    id: 6064,
    comune: "SAN GIORGIO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812340,
    codice_istat: 1244,
    codice_belfiore: "H890",
  },
  {
    id: 6065,
    comune: "SAN GIORGIO DEL SANNIO",
    sigla: "BN",
    codice_elettorale: 3150110570,
    codice_istat: 62058,
    codice_belfiore: "H894",
  },
  {
    id: 6066,
    comune: "SAN GIORGIO DELLA RICHINVELDA",
    sigla: "PN",
    codice_elettorale: 1060930380,
    codice_istat: 93038,
    codice_belfiore: "H891",
  },
  {
    id: 6067,
    comune: "SAN GIORGIO DELLE PERTICHE",
    sigla: "PD",
    codice_elettorale: 1050540750,
    codice_istat: 28075,
    codice_belfiore: "H893",
  },
  {
    id: 6068,
    comune: "SAN GIORGIO DI LOMELLINA",
    sigla: "PV",
    codice_elettorale: 1030571330,
    codice_istat: 18136,
    codice_belfiore: "H885",
  },
  {
    id: 6069,
    comune: "SAN GIORGIO DI NOGARO",
    sigla: "UD",
    codice_elettorale: 1060851000,
    codice_istat: 30100,
    codice_belfiore: "H895",
  },
  {
    id: 6070,
    comune: "SAN GIORGIO DI PIANO",
    sigla: "BO",
    codice_elettorale: 1080130520,
    codice_istat: 37052,
    codice_belfiore: "H896",
  },
  {
    id: 6071,
    comune: "SAN GIORGIO IN BOSCO",
    sigla: "PD",
    codice_elettorale: 1050540760,
    codice_istat: 28076,
    codice_belfiore: "H897",
  },
  {
    id: 6072,
    comune: "SAN GIORGIO IONICO",
    sigla: "TA",
    codice_elettorale: 3160780240,
    codice_istat: 73024,
    codice_belfiore: "H882",
  },
  {
    id: 6073,
    comune: "SAN GIORGIO LA MOLARA",
    sigla: "BN",
    codice_elettorale: 3150110580,
    codice_istat: 62059,
    codice_belfiore: "H898",
  },
  {
    id: 6074,
    comune: "SAN GIORGIO LUCANO",
    sigla: "MT",
    codice_elettorale: 3170470240,
    codice_istat: 77025,
    codice_belfiore: "H888",
  },
  {
    id: 6075,
    comune: "SAN GIORGIO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021500,
    codice_istat: 6153,
    codice_belfiore: "H878",
  },
  {
    id: 6076,
    comune: "SAN GIORGIO MORGETO",
    sigla: "RC",
    codice_elettorale: 3180670710,
    codice_istat: 80071,
    codice_belfiore: "H889",
  },
  {
    id: 6077,
    comune: "SAN GIORGIO PIACENTINO",
    sigla: "PC",
    codice_elettorale: 1080610400,
    codice_istat: 33040,
    codice_belfiore: "H887",
  },
  {
    id: 6078,
    comune: "SAN GIORGIO SCARAMPI",
    sigla: "AT",
    codice_elettorale: 1010070980,
    codice_istat: 5098,
    codice_belfiore: "H899",
  },
  {
    id: 6079,
    comune: "SAN GIORGIO SU LEGNANO",
    sigla: "MI",
    codice_elettorale: 1030491930,
    codice_istat: 15194,
    codice_belfiore: "H884",
  },
  {
    id: 6080,
    comune: "SAN GIORIO DI SUSA",
    sigla: "TO",
    codice_elettorale: 1010812350,
    codice_istat: 1245,
    codice_belfiore: "H900",
  },
  {
    id: 6081,
    comune: "SAN GIOVANNI A PIRO",
    sigla: "SA",
    codice_elettorale: 3150721190,
    codice_istat: 65119,
    codice_belfiore: "H907",
  },
  {
    id: 6082,
    comune: "SAN GIOVANNI AL NATISONE",
    sigla: "UD",
    codice_elettorale: 1060851010,
    codice_istat: 30101,
    codice_belfiore: "H906",
  },
  {
    id: 6083,
    comune: "SAN GIOVANNI BIANCO",
    sigla: "BG",
    codice_elettorale: 1030121780,
    codice_istat: 16188,
    codice_belfiore: "H910",
  },
  {
    id: 6084,
    comune: "SAN GIOVANNI DEL DOSSO",
    sigla: "MN",
    codice_elettorale: 1030450580,
    codice_istat: 20058,
    codice_belfiore: "H912",
  },
  {
    id: 6085,
    comune: "SAN GIOVANNI DI FASSA",
    sigla: "TN",
    codice_elettorale: 1040831618,
    codice_istat: 22250,
    codice_belfiore: "M390",
  },
  {
    id: 6086,
    comune: "SAN GIOVANNI DI GERACE",
    sigla: "RC",
    codice_elettorale: 3180670720,
    codice_istat: 80072,
    codice_belfiore: "H903",
  },
  {
    id: 6087,
    comune: "SAN GIOVANNI GEMINI",
    sigla: "AG",
    codice_elettorale: 4190010360,
    codice_istat: 84036,
    codice_belfiore: "H914",
  },
  {
    id: 6088,
    comune: "SAN GIOVANNI ILARIONE",
    sigla: "VR",
    codice_elettorale: 1050890690,
    codice_istat: 23070,
    codice_belfiore: "H916",
  },
  {
    id: 6089,
    comune: "SAN GIOVANNI IN CROCE",
    sigla: "CR",
    codice_elettorale: 1030260870,
    codice_istat: 19090,
    codice_belfiore: "H918",
  },
  {
    id: 6090,
    comune: "SAN GIOVANNI IN FIORE",
    sigla: "CS",
    codice_elettorale: 3180251200,
    codice_istat: 78119,
    codice_belfiore: "H919",
  },
  {
    id: 6091,
    comune: "SAN GIOVANNI IN GALDO",
    sigla: "CB",
    codice_elettorale: 3140190660,
    codice_istat: 70066,
    codice_belfiore: "H920",
  },
  {
    id: 6092,
    comune: "SAN GIOVANNI IN MARIGNANO",
    sigla: "RN",
    codice_elettorale: 1081010170,
    codice_istat: 99017,
    codice_belfiore: "H921",
  },
  {
    id: 6093,
    comune: "SAN GIOVANNI IN PERSICETO",
    sigla: "BO",
    codice_elettorale: 1080130530,
    codice_istat: 37053,
    codice_belfiore: "G467",
  },
  {
    id: 6094,
    comune: "SAN GIOVANNI INCARICO",
    sigla: "FR",
    codice_elettorale: 2120330630,
    codice_istat: 60064,
    codice_belfiore: "H917",
  },
  {
    id: 6095,
    comune: "SAN GIOVANNI LA PUNTA",
    sigla: "CT",
    codice_elettorale: 4190210400,
    codice_istat: 87041,
    codice_belfiore: "H922",
  },
  {
    id: 6096,
    comune: "SAN GIOVANNI LIPIONI",
    sigla: "CH",
    codice_elettorale: 3130230800,
    codice_istat: 69080,
    codice_belfiore: "H923",
  },
  {
    id: 6097,
    comune: "SAN GIOVANNI LUPATOTO",
    sigla: "VR",
    codice_elettorale: 1050890700,
    codice_istat: 23071,
    codice_belfiore: "H924",
  },
  {
    id: 6098,
    comune: "SAN GIOVANNI ROTONDO",
    sigla: "FG",
    codice_elettorale: 3160310440,
    codice_istat: 71046,
    codice_belfiore: "H926",
  },
  {
    id: 6099,
    comune: "SAN GIOVANNI SUERGIU",
    sigla: "SU",
    codice_elettorale: 4201110630,
    codice_istat: 111063,
    codice_belfiore: "G287",
  },
  {
    id: 6100,
    comune: "SAN GIOVANNI TEATINO",
    sigla: "CH",
    codice_elettorale: 3130230810,
    codice_istat: 69081,
    codice_belfiore: "D690",
  },
  {
    id: 6101,
    comune: "SAN GIOVANNI VALDARNO",
    sigla: "AR",
    codice_elettorale: 2090050330,
    codice_istat: 51033,
    codice_belfiore: "H901",
  },
  {
    id: 6102,
    comune: "SAN GIULIANO DEL SANNIO",
    sigla: "CB",
    codice_elettorale: 3140190670,
    codice_istat: 70067,
    codice_belfiore: "H928",
  },
  {
    id: 6103,
    comune: "SAN GIULIANO DI PUGLIA",
    sigla: "CB",
    codice_elettorale: 3140190680,
    codice_istat: 70068,
    codice_belfiore: "H929",
  },
  {
    id: 6104,
    comune: "SAN GIULIANO MILANESE",
    sigla: "MI",
    codice_elettorale: 1030491940,
    codice_istat: 15195,
    codice_belfiore: "H930",
  },
  {
    id: 6105,
    comune: "SAN GIULIANO TERME",
    sigla: "PI",
    codice_elettorale: 2090620300,
    codice_istat: 50031,
    codice_belfiore: "A562",
  },
  {
    id: 6106,
    comune: "SAN GIUSEPPE JATO",
    sigla: "PA",
    codice_elettorale: 4190550620,
    codice_istat: 82064,
    codice_belfiore: "H933",
  },
  {
    id: 6107,
    comune: "SAN GIUSEPPE VESUVIANO",
    sigla: "NA",
    codice_elettorale: 3150510680,
    codice_istat: 63068,
    codice_belfiore: "H931",
  },
  {
    id: 6108,
    comune: "SAN GIUSTINO",
    sigla: "PG",
    codice_elettorale: 2100580440,
    codice_istat: 54044,
    codice_belfiore: "H935",
  },
  {
    id: 6109,
    comune: "SAN GIUSTO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812360,
    codice_istat: 1246,
    codice_belfiore: "H936",
  },
  {
    id: 6110,
    comune: "SAN GODENZO",
    sigla: "FI",
    codice_elettorale: 2090300390,
    codice_istat: 48039,
    codice_belfiore: "H937",
  },
  {
    id: 6111,
    comune: "SAN GREGORIO D'IPPONA",
    sigla: "VV",
    codice_elettorale: 3181030340,
    codice_istat: 102034,
    codice_belfiore: "H941",
  },
  {
    id: 6112,
    comune: "SAN GREGORIO DA SASSOLA",
    sigla: "RM",
    codice_elettorale: 2120700940,
    codice_istat: 58095,
    codice_belfiore: "H942",
  },
  {
    id: 6113,
    comune: "SAN GREGORIO DI CATANIA",
    sigla: "CT",
    codice_elettorale: 4190210410,
    codice_istat: 87042,
    codice_belfiore: "H940",
  },
  {
    id: 6114,
    comune: "SAN GREGORIO MAGNO",
    sigla: "SA",
    codice_elettorale: 3150721200,
    codice_istat: 65120,
    codice_belfiore: "H943",
  },
  {
    id: 6115,
    comune: "SAN GREGORIO MATESE",
    sigla: "CE",
    codice_elettorale: 3150200760,
    codice_istat: 61076,
    codice_belfiore: "H939",
  },
  {
    id: 6116,
    comune: "SAN GREGORIO NELLE ALPI",
    sigla: "BL",
    codice_elettorale: 1050100450,
    codice_istat: 25045,
    codice_belfiore: "H938",
  },
  {
    id: 6117,
    comune: "SAN LAZZARO DI SAVENA",
    sigla: "BO",
    codice_elettorale: 1080130540,
    codice_istat: 37054,
    codice_belfiore: "H945",
  },
  {
    id: 6118,
    comune: "SAN LEO",
    sigla: "RN",
    codice_elettorale: 1081010175,
    codice_istat: 99025,
    codice_belfiore: "H949",
  },
  {
    id: 6119,
    comune: "SAN LEONARDO",
    sigla: "UD",
    codice_elettorale: 1060851020,
    codice_istat: 30102,
    codice_belfiore: "H951",
  },
  {
    id: 6120,
    comune: "SAN LEONARDO IN PASSIRIA/ST. LEONHARD IN PASSEIER",
    sigla: "BZ",
    codice_elettorale: 1040140750,
    codice_istat: 21080,
    codice_belfiore: "H952",
  },
  {
    id: 6121,
    comune: "SAN LEUCIO DEL SANNIO",
    sigla: "BN",
    codice_elettorale: 3150110590,
    codice_istat: 62060,
    codice_belfiore: "H953",
  },
  {
    id: 6122,
    comune: "SAN LORENZELLO",
    sigla: "BN",
    codice_elettorale: 3150110600,
    codice_istat: 62061,
    codice_belfiore: "H955",
  },
  {
    id: 6123,
    comune: "SAN LORENZO",
    sigla: "RC",
    codice_elettorale: 3180670730,
    codice_istat: 80073,
    codice_belfiore: "H959",
  },
  {
    id: 6124,
    comune: "SAN LORENZO AL MARE",
    sigla: "IM",
    codice_elettorale: 1070370510,
    codice_istat: 8054,
    codice_belfiore: "H957",
  },
  {
    id: 6125,
    comune: "SAN LORENZO BELLIZZI",
    sigla: "CS",
    codice_elettorale: 3180251210,
    codice_istat: 78120,
    codice_belfiore: "H961",
  },
  {
    id: 6126,
    comune: "SAN LORENZO DEL VALLO",
    sigla: "CS",
    codice_elettorale: 3180251220,
    codice_istat: 78121,
    codice_belfiore: "H962",
  },
  {
    id: 6127,
    comune: "SAN LORENZO DI SEBATO/ST. LORENZEN",
    sigla: "BZ",
    codice_elettorale: 1040140760,
    codice_istat: 21081,
    codice_belfiore: "H956",
  },
  {
    id: 6128,
    comune: "SAN LORENZO DORSINO",
    sigla: "TN",
    codice_elettorale: 1040831555,
    codice_istat: 22231,
    codice_belfiore: "M345",
  },
  {
    id: 6129,
    comune: "SAN LORENZO IN CAMPO",
    sigla: "PU",
    codice_elettorale: 2110590540,
    codice_istat: 41054,
    codice_belfiore: "H958",
  },
  {
    id: 6130,
    comune: "SAN LORENZO ISONTINO",
    sigla: "GO",
    codice_elettorale: 1060350161,
    codice_istat: 31020,
    codice_belfiore: "H964",
  },
  {
    id: 6131,
    comune: "SAN LORENZO MAGGIORE",
    sigla: "BN",
    codice_elettorale: 3150110610,
    codice_istat: 62062,
    codice_belfiore: "H967",
  },
  {
    id: 6132,
    comune: "SAN LORENZO NUOVO",
    sigla: "VT",
    codice_elettorale: 2120910460,
    codice_istat: 56047,
    codice_belfiore: "H969",
  },
  {
    id: 6133,
    comune: "SAN LUCA",
    sigla: "RC",
    codice_elettorale: 3180670740,
    codice_istat: 80074,
    codice_belfiore: "H970",
  },
  {
    id: 6134,
    comune: "SAN LUCIDO",
    sigla: "CS",
    codice_elettorale: 3180251230,
    codice_istat: 78122,
    codice_belfiore: "H971",
  },
  {
    id: 6135,
    comune: "SAN LUPO",
    sigla: "BN",
    codice_elettorale: 3150110620,
    codice_istat: 62063,
    codice_belfiore: "H973",
  },
  {
    id: 6136,
    comune: "SAN MANGO D'AQUINO",
    sigla: "CZ",
    codice_elettorale: 3180221070,
    codice_istat: 79110,
    codice_belfiore: "H976",
  },
  {
    id: 6137,
    comune: "SAN MANGO PIEMONTE",
    sigla: "SA",
    codice_elettorale: 3150721210,
    codice_istat: 65121,
    codice_belfiore: "H977",
  },
  {
    id: 6138,
    comune: "SAN MANGO SUL CALORE",
    sigla: "AV",
    codice_elettorale: 3150080810,
    codice_istat: 64082,
    codice_belfiore: "H975",
  },
  {
    id: 6139,
    comune: "SAN MARCELLINO",
    sigla: "CE",
    codice_elettorale: 3150200770,
    codice_istat: 61077,
    codice_belfiore: "H978",
  },
  {
    id: 6140,
    comune: "SAN MARCELLO",
    sigla: "AN",
    codice_elettorale: 2110030410,
    codice_istat: 42041,
    codice_belfiore: "H979",
  },
  {
    id: 6141,
    comune: "SAN MARCELLO PITEGLIO",
    sigla: "PT",
    codice_elettorale: 2090630185,
    codice_istat: 47024,
    codice_belfiore: "M377",
  },
  {
    id: 6142,
    comune: "SAN MARCO ARGENTANO",
    sigla: "CS",
    codice_elettorale: 3180251240,
    codice_istat: 78123,
    codice_belfiore: "H981",
  },
  {
    id: 6143,
    comune: "SAN MARCO D'ALUNZIO",
    sigla: "ME",
    codice_elettorale: 4190480780,
    codice_istat: 83079,
    codice_belfiore: "H982",
  },
  {
    id: 6144,
    comune: "SAN MARCO DEI CAVOTI",
    sigla: "BN",
    codice_elettorale: 3150110630,
    codice_istat: 62064,
    codice_belfiore: "H984",
  },
  {
    id: 6145,
    comune: "SAN MARCO EVANGELISTA",
    sigla: "CE",
    codice_elettorale: 3150200771,
    codice_istat: 61104,
    codice_belfiore: "F043",
  },
  {
    id: 6146,
    comune: "SAN MARCO IN LAMIS",
    sigla: "FG",
    codice_elettorale: 3160310450,
    codice_istat: 71047,
    codice_belfiore: "H985",
  },
  {
    id: 6147,
    comune: "SAN MARCO LA CATOLA",
    sigla: "FG",
    codice_elettorale: 3160310460,
    codice_istat: 71048,
    codice_belfiore: "H986",
  },
  {
    id: 6148,
    comune: "SAN MARTINO AL TAGLIAMENTO",
    sigla: "PN",
    codice_elettorale: 1060930390,
    codice_istat: 93039,
    codice_belfiore: "H999",
  },
  {
    id: 6149,
    comune: "SAN MARTINO ALFIERI",
    sigla: "AT",
    codice_elettorale: 1010070990,
    codice_istat: 5099,
    codice_belfiore: "H987",
  },
  {
    id: 6150,
    comune: "SAN MARTINO BUON ALBERGO",
    sigla: "VR",
    codice_elettorale: 1050890720,
    codice_istat: 23073,
    codice_belfiore: "I003",
  },
  {
    id: 6151,
    comune: "SAN MARTINO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812370,
    codice_istat: 1247,
    codice_belfiore: "H997",
  },
  {
    id: 6152,
    comune: "SAN MARTINO D'AGRI",
    sigla: "PZ",
    codice_elettorale: 3170640760,
    codice_istat: 76077,
    codice_belfiore: "H994",
  },
  {
    id: 6153,
    comune: "SAN MARTINO DALL'ARGINE",
    sigla: "MN",
    codice_elettorale: 1030450590,
    codice_istat: 20059,
    codice_belfiore: "I005",
  },
  {
    id: 6154,
    comune: "SAN MARTINO DEL LAGO",
    sigla: "CR",
    codice_elettorale: 1030260880,
    codice_istat: 19091,
    codice_belfiore: "I007",
  },
  {
    id: 6155,
    comune: "SAN MARTINO DI FINITA",
    sigla: "CS",
    codice_elettorale: 3180251250,
    codice_istat: 78124,
    codice_belfiore: "H992",
  },
  {
    id: 6156,
    comune: "SAN MARTINO DI LUPARI",
    sigla: "PD",
    codice_elettorale: 1050540770,
    codice_istat: 28077,
    codice_belfiore: "I008",
  },
  {
    id: 6157,
    comune: "SAN MARTINO DI VENEZZE",
    sigla: "RO",
    codice_elettorale: 1050710440,
    codice_istat: 29044,
    codice_belfiore: "H996",
  },
  {
    id: 6158,
    comune: "SAN MARTINO IN BADIA/ST. MARTIN IN THURN",
    sigla: "BZ",
    codice_elettorale: 1040140770,
    codice_istat: 21082,
    codice_belfiore: "H988",
  },
  {
    id: 6159,
    comune: "SAN MARTINO IN PASSIRIA/ST. MARTIN IN PASSEIER",
    sigla: "BZ",
    codice_elettorale: 1040140771,
    codice_istat: 21083,
    codice_belfiore: "H989",
  },
  {
    id: 6160,
    comune: "SAN MARTINO IN PENSILIS",
    sigla: "CB",
    codice_elettorale: 3140190690,
    codice_istat: 70069,
    codice_belfiore: "H990",
  },
  {
    id: 6161,
    comune: "SAN MARTINO IN RIO",
    sigla: "RE",
    codice_elettorale: 1080680370,
    codice_istat: 35037,
    codice_belfiore: "I011",
  },
  {
    id: 6162,
    comune: "SAN MARTINO IN STRADA",
    sigla: "LO",
    codice_elettorale: 1030990480,
    codice_istat: 98048,
    codice_belfiore: "I012",
  },
  {
    id: 6163,
    comune: "SAN MARTINO SANNITA",
    sigla: "BN",
    codice_elettorale: 3150110640,
    codice_istat: 62065,
    codice_belfiore: "I002",
  },
  {
    id: 6164,
    comune: "SAN MARTINO SICCOMARIO",
    sigla: "PV",
    codice_elettorale: 1030571340,
    codice_istat: 18137,
    codice_belfiore: "I014",
  },
  {
    id: 6165,
    comune: "SAN MARTINO SULLA MARRUCINA",
    sigla: "CH",
    codice_elettorale: 3130230820,
    codice_istat: 69082,
    codice_belfiore: "H991",
  },
  {
    id: 6166,
    comune: "SAN MARTINO VALLE CAUDINA",
    sigla: "AV",
    codice_elettorale: 3150080820,
    codice_istat: 64083,
    codice_belfiore: "I016",
  },
  {
    id: 6167,
    comune: "SAN MARZANO DI SAN GIUSEPPE",
    sigla: "TA",
    codice_elettorale: 3160780250,
    codice_istat: 73025,
    codice_belfiore: "I018",
  },
  {
    id: 6168,
    comune: "SAN MARZANO OLIVETO",
    sigla: "AT",
    codice_elettorale: 1010071000,
    codice_istat: 5100,
    codice_belfiore: "I017",
  },
  {
    id: 6169,
    comune: "SAN MARZANO SUL SARNO",
    sigla: "SA",
    codice_elettorale: 3150721220,
    codice_istat: 65122,
    codice_belfiore: "I019",
  },
  {
    id: 6170,
    comune: "SAN MASSIMO",
    sigla: "CB",
    codice_elettorale: 3140190700,
    codice_istat: 70070,
    codice_belfiore: "I023",
  },
  {
    id: 6171,
    comune: "SAN MAURIZIO CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812380,
    codice_istat: 1248,
    codice_belfiore: "I024",
  },
  {
    id: 6172,
    comune: "SAN MAURIZIO D'OPAGLIO",
    sigla: "NO",
    codice_elettorale: 1010521270,
    codice_istat: 3133,
    codice_belfiore: "I025",
  },
  {
    id: 6173,
    comune: "SAN MAURO CASTELVERDE",
    sigla: "PA",
    codice_elettorale: 4190550630,
    codice_istat: 82065,
    codice_belfiore: "I028",
  },
  {
    id: 6174,
    comune: "SAN MAURO CILENTO",
    sigla: "SA",
    codice_elettorale: 3150721230,
    codice_istat: 65123,
    codice_belfiore: "I031",
  },
  {
    id: 6175,
    comune: "SAN MAURO DI SALINE",
    sigla: "VR",
    codice_elettorale: 1050890730,
    codice_istat: 23074,
    codice_belfiore: "H712",
  },
  {
    id: 6176,
    comune: "SAN MAURO FORTE",
    sigla: "MT",
    codice_elettorale: 3170470250,
    codice_istat: 77026,
    codice_belfiore: "I029",
  },
  {
    id: 6177,
    comune: "SAN MAURO LA BRUCA",
    sigla: "SA",
    codice_elettorale: 3150721240,
    codice_istat: 65124,
    codice_belfiore: "I032",
  },
  {
    id: 6178,
    comune: "SAN MAURO MARCHESATO",
    sigla: "KR",
    codice_elettorale: 3180970200,
    codice_istat: 101020,
    codice_belfiore: "I026",
  },
  {
    id: 6179,
    comune: "SAN MAURO PASCOLI",
    sigla: "FC",
    codice_elettorale: 1080320400,
    codice_istat: 40041,
    codice_belfiore: "I027",
  },
  {
    id: 6180,
    comune: "SAN MAURO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010812390,
    codice_istat: 1249,
    codice_belfiore: "I030",
  },
  {
    id: 6181,
    comune: "SAN MICHELE AL TAGLIAMENTO",
    sigla: "VE",
    codice_elettorale: 1050870340,
    codice_istat: 27034,
    codice_belfiore: "I040",
  },
  {
    id: 6182,
    comune: "SAN MICHELE ALL'ADIGE",
    sigla: "TN",
    codice_elettorale: 1040831560,
    codice_istat: 22167,
    codice_belfiore: "I042",
  },
  {
    id: 6183,
    comune: "SAN MICHELE DI GANZARIA",
    sigla: "CT",
    codice_elettorale: 4190210420,
    codice_istat: 87043,
    codice_belfiore: "I035",
  },
  {
    id: 6184,
    comune: "SAN MICHELE DI SERINO",
    sigla: "AV",
    codice_elettorale: 3150080830,
    codice_istat: 64084,
    codice_belfiore: "I034",
  },
  {
    id: 6185,
    comune: "SAN MICHELE MONDOVI'",
    sigla: "CN",
    codice_elettorale: 1010272100,
    codice_istat: 4210,
    codice_belfiore: "I037",
  },
  {
    id: 6186,
    comune: "SAN MICHELE SALENTINO",
    sigla: "BR",
    codice_elettorale: 3160160140,
    codice_istat: 74014,
    codice_belfiore: "I045",
  },
  {
    id: 6187,
    comune: "SAN MINIATO",
    sigla: "PI",
    codice_elettorale: 2090620310,
    codice_istat: 50032,
    codice_belfiore: "I046",
  },
  {
    id: 6188,
    comune: "SAN NAZZARO",
    sigla: "BN",
    codice_elettorale: 3150110641,
    codice_istat: 62066,
    codice_belfiore: "I049",
  },
  {
    id: 6189,
    comune: "SAN NAZZARO SESIA",
    sigla: "NO",
    codice_elettorale: 1010521280,
    codice_istat: 3134,
    codice_belfiore: "I052",
  },
  {
    id: 6190,
    comune: "SAN NAZZARO VAL CAVARGNA",
    sigla: "CO",
    codice_elettorale: 1030241950,
    codice_istat: 13207,
    codice_belfiore: "I051",
  },
  {
    id: 6191,
    comune: "SAN NICANDRO GARGANICO",
    sigla: "FG",
    codice_elettorale: 3160310470,
    codice_istat: 71049,
    codice_belfiore: "I054",
  },
  {
    id: 6192,
    comune: "SAN NICOLA ARCELLA",
    sigla: "CS",
    codice_elettorale: 3180251260,
    codice_istat: 78125,
    codice_belfiore: "I060",
  },
  {
    id: 6193,
    comune: "SAN NICOLA BARONIA",
    sigla: "AV",
    codice_elettorale: 3150080840,
    codice_istat: 64085,
    codice_belfiore: "I061",
  },
  {
    id: 6194,
    comune: "SAN NICOLA DA CRISSA",
    sigla: "VV",
    codice_elettorale: 3181030350,
    codice_istat: 102035,
    codice_belfiore: "I058",
  },
  {
    id: 6195,
    comune: "SAN NICOLA DELL'ALTO",
    sigla: "KR",
    codice_elettorale: 3180970210,
    codice_istat: 101021,
    codice_belfiore: "I057",
  },
  {
    id: 6196,
    comune: "SAN NICOLA LA STRADA",
    sigla: "CE",
    codice_elettorale: 3150200780,
    codice_istat: 61078,
    codice_belfiore: "I056",
  },
  {
    id: 6197,
    comune: "SAN NICOLA MANFREDI",
    sigla: "BN",
    codice_elettorale: 3150110650,
    codice_istat: 62067,
    codice_belfiore: "I062",
  },
  {
    id: 6198,
    comune: "SAN NICOLO' D'ARCIDANO",
    sigla: "OR",
    codice_elettorale: 4200950460,
    codice_istat: 95046,
    codice_belfiore: "A368",
  },
  {
    id: 6199,
    comune: "SAN NICOLO' DI COMELICO",
    sigla: "BL",
    codice_elettorale: 1050100460,
    codice_istat: 25046,
    codice_belfiore: "I063",
  },
  {
    id: 6200,
    comune: "SAN NICOLO' GERREI",
    sigla: "SU",
    codice_elettorale: 4201110640,
    codice_istat: 111064,
    codice_belfiore: "G383",
  },
  {
    id: 6201,
    comune: "SAN PANCRAZIO SALENTINO",
    sigla: "BR",
    codice_elettorale: 3160160150,
    codice_istat: 74015,
    codice_belfiore: "I066",
  },
  {
    id: 6202,
    comune: "SAN PANCRAZIO/ST. PANKRAZ",
    sigla: "BZ",
    codice_elettorale: 1040140772,
    codice_istat: 21084,
    codice_belfiore: "I065",
  },
  {
    id: 6203,
    comune: "SAN PAOLO",
    sigla: "BS",
    codice_elettorale: 1030151631,
    codice_istat: 17138,
    codice_belfiore: "G407",
  },
  {
    id: 6204,
    comune: "SAN PAOLO ALBANESE",
    sigla: "PZ",
    codice_elettorale: 3170640761,
    codice_istat: 76020,
    codice_belfiore: "B906",
  },
  {
    id: 6205,
    comune: "SAN PAOLO BEL SITO",
    sigla: "NA",
    codice_elettorale: 3150510690,
    codice_istat: 63069,
    codice_belfiore: "I073",
  },
  {
    id: 6206,
    comune: "SAN PAOLO D'ARGON",
    sigla: "BG",
    codice_elettorale: 1030121800,
    codice_istat: 16189,
    codice_belfiore: "B310",
  },
  {
    id: 6207,
    comune: "SAN PAOLO DI CIVITATE",
    sigla: "FG",
    codice_elettorale: 3160310480,
    codice_istat: 71050,
    codice_belfiore: "I072",
  },
  {
    id: 6208,
    comune: "SAN PAOLO DI JESI",
    sigla: "AN",
    codice_elettorale: 2110030420,
    codice_istat: 42042,
    codice_belfiore: "I071",
  },
  {
    id: 6209,
    comune: "SAN PAOLO SOLBRITO",
    sigla: "AT",
    codice_elettorale: 1010071010,
    codice_istat: 5101,
    codice_belfiore: "I076",
  },
  {
    id: 6210,
    comune: "SAN PELLEGRINO TERME",
    sigla: "BG",
    codice_elettorale: 1030121810,
    codice_istat: 16190,
    codice_belfiore: "I079",
  },
  {
    id: 6211,
    comune: "SAN PIER D'ISONZO",
    sigla: "GO",
    codice_elettorale: 1060350170,
    codice_istat: 31021,
    codice_belfiore: "I082",
  },
  {
    id: 6212,
    comune: "SAN PIER NICETO",
    sigla: "ME",
    codice_elettorale: 4190480790,
    codice_istat: 83080,
    codice_belfiore: "I084",
  },
  {
    id: 6213,
    comune: "SAN PIERO PATTI",
    sigla: "ME",
    codice_elettorale: 4190480800,
    codice_istat: 83081,
    codice_belfiore: "I086",
  },
  {
    id: 6214,
    comune: "SAN PIETRO A MAIDA",
    sigla: "CZ",
    codice_elettorale: 3180221110,
    codice_istat: 79114,
    codice_belfiore: "I093",
  },
  {
    id: 6215,
    comune: "SAN PIETRO AL NATISONE",
    sigla: "UD",
    codice_elettorale: 1060851030,
    codice_istat: 30103,
    codice_belfiore: "I092",
  },
  {
    id: 6216,
    comune: "SAN PIETRO AL TANAGRO",
    sigla: "SA",
    codice_elettorale: 3150721250,
    codice_istat: 65125,
    codice_belfiore: "I089",
  },
  {
    id: 6217,
    comune: "SAN PIETRO APOSTOLO",
    sigla: "CZ",
    codice_elettorale: 3180221120,
    codice_istat: 79115,
    codice_belfiore: "I095",
  },
  {
    id: 6218,
    comune: "SAN PIETRO AVELLANA",
    sigla: "IS",
    codice_elettorale: 3140940430,
    codice_istat: 94043,
    codice_belfiore: "I096",
  },
  {
    id: 6219,
    comune: "SAN PIETRO CLARENZA",
    sigla: "CT",
    codice_elettorale: 4190210430,
    codice_istat: 87044,
    codice_belfiore: "I098",
  },
  {
    id: 6220,
    comune: "SAN PIETRO DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100470,
    codice_istat: 25047,
    codice_belfiore: "I088",
  },
  {
    id: 6221,
    comune: "SAN PIETRO DI CARIDA'",
    sigla: "RC",
    codice_elettorale: 3180670750,
    codice_istat: 80075,
    codice_belfiore: "I102",
  },
  {
    id: 6222,
    comune: "SAN PIETRO DI FELETTO",
    sigla: "TV",
    codice_elettorale: 1050840720,
    codice_istat: 26073,
    codice_belfiore: "I103",
  },
  {
    id: 6223,
    comune: "SAN PIETRO DI MORUBIO",
    sigla: "VR",
    codice_elettorale: 1050890740,
    codice_istat: 23075,
    codice_belfiore: "I105",
  },
  {
    id: 6224,
    comune: "SAN PIETRO IN AMANTEA",
    sigla: "CS",
    codice_elettorale: 3180251270,
    codice_istat: 78126,
    codice_belfiore: "I108",
  },
  {
    id: 6225,
    comune: "SAN PIETRO IN CARIANO",
    sigla: "VR",
    codice_elettorale: 1050890750,
    codice_istat: 23076,
    codice_belfiore: "I109",
  },
  {
    id: 6226,
    comune: "SAN PIETRO IN CASALE",
    sigla: "BO",
    codice_elettorale: 1080130550,
    codice_istat: 37055,
    codice_belfiore: "I110",
  },
  {
    id: 6227,
    comune: "SAN PIETRO IN CERRO",
    sigla: "PC",
    codice_elettorale: 1080610410,
    codice_istat: 33041,
    codice_belfiore: "G788",
  },
  {
    id: 6228,
    comune: "SAN PIETRO IN GU",
    sigla: "PD",
    codice_elettorale: 1050540780,
    codice_istat: 28078,
    codice_belfiore: "I107",
  },
  {
    id: 6229,
    comune: "SAN PIETRO IN GUARANO",
    sigla: "CS",
    codice_elettorale: 3180251280,
    codice_istat: 78127,
    codice_belfiore: "I114",
  },
  {
    id: 6230,
    comune: "SAN PIETRO IN LAMA",
    sigla: "LE",
    codice_elettorale: 3160410700,
    codice_istat: 75071,
    codice_belfiore: "I115",
  },
  {
    id: 6231,
    comune: "SAN PIETRO INFINE",
    sigla: "CE",
    codice_elettorale: 3150200790,
    codice_istat: 61079,
    codice_belfiore: "I113",
  },
  {
    id: 6232,
    comune: "SAN PIETRO MOSEZZO",
    sigla: "NO",
    codice_elettorale: 1010521290,
    codice_istat: 3135,
    codice_belfiore: "I116",
  },
  {
    id: 6233,
    comune: "SAN PIETRO MUSSOLINO",
    sigla: "VI",
    codice_elettorale: 1050900940,
    codice_istat: 24094,
    codice_belfiore: "I117",
  },
  {
    id: 6234,
    comune: "SAN PIETRO VAL LEMINA",
    sigla: "TO",
    codice_elettorale: 1010812400,
    codice_istat: 1250,
    codice_belfiore: "I090",
  },
  {
    id: 6235,
    comune: "SAN PIETRO VERNOTICO",
    sigla: "BR",
    codice_elettorale: 3160160160,
    codice_istat: 74016,
    codice_belfiore: "I119",
  },
  {
    id: 6236,
    comune: "SAN PIETRO VIMINARIO",
    sigla: "PD",
    codice_elettorale: 1050540790,
    codice_istat: 28079,
    codice_belfiore: "I120",
  },
  {
    id: 6237,
    comune: "SAN PIO DELLE CAMERE",
    sigla: "AQ",
    codice_elettorale: 3130380880,
    codice_istat: 66088,
    codice_belfiore: "I121",
  },
  {
    id: 6238,
    comune: "SAN POLO D'ENZA",
    sigla: "RE",
    codice_elettorale: 1080680380,
    codice_istat: 35038,
    codice_belfiore: "I123",
  },
  {
    id: 6239,
    comune: "SAN POLO DEI CAVALIERI",
    sigla: "RM",
    codice_elettorale: 2120700950,
    codice_istat: 58096,
    codice_belfiore: "I125",
  },
  {
    id: 6240,
    comune: "SAN POLO DI PIAVE",
    sigla: "TV",
    codice_elettorale: 1050840730,
    codice_istat: 26074,
    codice_belfiore: "I124",
  },
  {
    id: 6241,
    comune: "SAN POLO MATESE",
    sigla: "CB",
    codice_elettorale: 3140190710,
    codice_istat: 70071,
    codice_belfiore: "I122",
  },
  {
    id: 6242,
    comune: "SAN PONSO",
    sigla: "TO",
    codice_elettorale: 1010812410,
    codice_istat: 1251,
    codice_belfiore: "I126",
  },
  {
    id: 6243,
    comune: "SAN POSSIDONIO",
    sigla: "MO",
    codice_elettorale: 1080500370,
    codice_istat: 36038,
    codice_belfiore: "I128",
  },
  {
    id: 6244,
    comune: "SAN POTITO SANNITICO",
    sigla: "CE",
    codice_elettorale: 3150200800,
    codice_istat: 61080,
    codice_belfiore: "I130",
  },
  {
    id: 6245,
    comune: "SAN POTITO ULTRA",
    sigla: "AV",
    codice_elettorale: 3150080850,
    codice_istat: 64086,
    codice_belfiore: "I129",
  },
  {
    id: 6246,
    comune: "SAN PRISCO",
    sigla: "CE",
    codice_elettorale: 3150200810,
    codice_istat: 61081,
    codice_belfiore: "I131",
  },
  {
    id: 6247,
    comune: "SAN PROCOPIO",
    sigla: "RC",
    codice_elettorale: 3180670760,
    codice_istat: 80076,
    codice_belfiore: "I132",
  },
  {
    id: 6248,
    comune: "SAN PROSPERO",
    sigla: "MO",
    codice_elettorale: 1080500380,
    codice_istat: 36039,
    codice_belfiore: "I133",
  },
  {
    id: 6249,
    comune: "SAN QUIRICO D'ORCIA",
    sigla: "SI",
    codice_elettorale: 2090750300,
    codice_istat: 52030,
    codice_belfiore: "I135",
  },
  {
    id: 6250,
    comune: "SAN QUIRINO",
    sigla: "PN",
    codice_elettorale: 1060930400,
    codice_istat: 93040,
    codice_belfiore: "I136",
  },
  {
    id: 6251,
    comune: "SAN RAFFAELE CIMENA",
    sigla: "TO",
    codice_elettorale: 1010812420,
    codice_istat: 1252,
    codice_belfiore: "I137",
  },
  {
    id: 6252,
    comune: "SAN ROBERTO",
    sigla: "RC",
    codice_elettorale: 3180670770,
    codice_istat: 80077,
    codice_belfiore: "I139",
  },
  {
    id: 6253,
    comune: "SAN ROCCO AL PORTO",
    sigla: "LO",
    codice_elettorale: 1030990490,
    codice_istat: 98049,
    codice_belfiore: "I140",
  },
  {
    id: 6254,
    comune: "SAN ROMANO IN GARFAGNANA",
    sigla: "LU",
    codice_elettorale: 2090430270,
    codice_istat: 46027,
    codice_belfiore: "I142",
  },
  {
    id: 6255,
    comune: "SAN RUFO",
    sigla: "SA",
    codice_elettorale: 3150721260,
    codice_istat: 65126,
    codice_belfiore: "I143",
  },
  {
    id: 6256,
    comune: "SAN SALVATORE DI FITALIA",
    sigla: "ME",
    codice_elettorale: 4190480810,
    codice_istat: 83082,
    codice_belfiore: "I147",
  },
  {
    id: 6257,
    comune: "SAN SALVATORE MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021510,
    codice_istat: 6154,
    codice_belfiore: "I144",
  },
  {
    id: 6258,
    comune: "SAN SALVATORE TELESINO",
    sigla: "BN",
    codice_elettorale: 3150110660,
    codice_istat: 62068,
    codice_belfiore: "I145",
  },
  {
    id: 6259,
    comune: "SAN SALVO",
    sigla: "CH",
    codice_elettorale: 3130230830,
    codice_istat: 69083,
    codice_belfiore: "I148",
  },
  {
    id: 6260,
    comune: "SAN SEBASTIANO AL VESUVIO",
    sigla: "NA",
    codice_elettorale: 3150510700,
    codice_istat: 63070,
    codice_belfiore: "I151",
  },
  {
    id: 6261,
    comune: "SAN SEBASTIANO CURONE",
    sigla: "AL",
    codice_elettorale: 1010021520,
    codice_istat: 6155,
    codice_belfiore: "I150",
  },
  {
    id: 6262,
    comune: "SAN SEBASTIANO DA PO",
    sigla: "TO",
    codice_elettorale: 1010812430,
    codice_istat: 1253,
    codice_belfiore: "I152",
  },
  {
    id: 6263,
    comune: "SAN SECONDO DI PINEROLO",
    sigla: "TO",
    codice_elettorale: 1010812440,
    codice_istat: 1254,
    codice_belfiore: "I154",
  },
  {
    id: 6264,
    comune: "SAN SECONDO PARMENSE",
    sigla: "PR",
    codice_elettorale: 1080560330,
    codice_istat: 34033,
    codice_belfiore: "I153",
  },
  {
    id: 6265,
    comune: "SAN SEVERINO LUCANO",
    sigla: "PZ",
    codice_elettorale: 3170640770,
    codice_istat: 76078,
    codice_belfiore: "I157",
  },
  {
    id: 6266,
    comune: "SAN SEVERINO MARCHE",
    sigla: "MC",
    codice_elettorale: 2110440470,
    codice_istat: 43047,
    codice_belfiore: "I156",
  },
  {
    id: 6267,
    comune: "SAN SEVERO",
    sigla: "FG",
    codice_elettorale: 3160310490,
    codice_istat: 71051,
    codice_belfiore: "I158",
  },
  {
    id: 6268,
    comune: "SAN SIRO",
    sigla: "CO",
    codice_elettorale: 1030241955,
    codice_istat: 13248,
    codice_belfiore: "I162",
  },
  {
    id: 6269,
    comune: "SAN SOSSIO BARONIA",
    sigla: "AV",
    codice_elettorale: 3150080860,
    codice_istat: 64087,
    codice_belfiore: "I163",
  },
  {
    id: 6270,
    comune: "SAN SOSTENE",
    sigla: "CZ",
    codice_elettorale: 3180221130,
    codice_istat: 79116,
    codice_belfiore: "I164",
  },
  {
    id: 6271,
    comune: "SAN SOSTI",
    sigla: "CS",
    codice_elettorale: 3180251290,
    codice_istat: 78128,
    codice_belfiore: "I165",
  },
  {
    id: 6272,
    comune: "SAN SPERATE",
    sigla: "SU",
    codice_elettorale: 4201110650,
    codice_istat: 111065,
    codice_belfiore: "I166",
  },
  {
    id: 6273,
    comune: "SAN STINO DI LIVENZA",
    sigla: "VE",
    codice_elettorale: 1050870360,
    codice_istat: 27036,
    codice_belfiore: "I373",
  },
  {
    id: 6274,
    comune: "SAN TAMMARO",
    sigla: "CE",
    codice_elettorale: 3150200850,
    codice_istat: 61085,
    codice_belfiore: "I261",
  },
  {
    id: 6275,
    comune: "SAN TEODORO",
    sigla: "ME",
    codice_elettorale: 4190480890,
    codice_istat: 83090,
    codice_belfiore: "I328",
  },
  {
    id: 6276,
    comune: "SAN TEODORO",
    sigla: "SS",
    codice_elettorale: 4200730615,
    codice_istat: 90092,
    codice_belfiore: "I329",
  },
  {
    id: 6277,
    comune: "SAN TOMASO AGORDINO",
    sigla: "BL",
    codice_elettorale: 1050100490,
    codice_istat: 25049,
    codice_belfiore: "I347",
  },
  {
    id: 6278,
    comune: "SAN VALENTINO IN ABRUZZO CITERIORE",
    sigla: "PE",
    codice_elettorale: 3130600380,
    codice_istat: 68038,
    codice_belfiore: "I376",
  },
  {
    id: 6279,
    comune: "SAN VALENTINO TORIO",
    sigla: "SA",
    codice_elettorale: 3150721320,
    codice_istat: 65132,
    codice_belfiore: "I377",
  },
  {
    id: 6280,
    comune: "SAN VENANZO",
    sigla: "TR",
    codice_elettorale: 2100800300,
    codice_istat: 55030,
    codice_belfiore: "I381",
  },
  {
    id: 6281,
    comune: "SAN VENDEMIANO",
    sigla: "TV",
    codice_elettorale: 1050840750,
    codice_istat: 26076,
    codice_belfiore: "I382",
  },
  {
    id: 6282,
    comune: "SAN VERO MILIS",
    sigla: "OR",
    codice_elettorale: 4200950500,
    codice_istat: 95050,
    codice_belfiore: "I384",
  },
  {
    id: 6283,
    comune: "SAN VINCENZO",
    sigla: "LI",
    codice_elettorale: 2090420180,
    codice_istat: 49018,
    codice_belfiore: "I390",
  },
  {
    id: 6284,
    comune: "SAN VINCENZO LA COSTA",
    sigla: "CS",
    codice_elettorale: 3180251350,
    codice_istat: 78135,
    codice_belfiore: "I388",
  },
  {
    id: 6285,
    comune: "SAN VINCENZO VALLE ROVETO",
    sigla: "AQ",
    codice_elettorale: 3130380910,
    codice_istat: 66092,
    codice_belfiore: "I389",
  },
  {
    id: 6286,
    comune: "SAN VITALIANO",
    sigla: "NA",
    codice_elettorale: 3150510750,
    codice_istat: 63075,
    codice_belfiore: "I391",
  },
  {
    id: 6287,
    comune: "SAN VITO",
    sigla: "SU",
    codice_elettorale: 4201110660,
    codice_istat: 111066,
    codice_belfiore: "I402",
  },
  {
    id: 6288,
    comune: "SAN VITO AL TAGLIAMENTO",
    sigla: "PN",
    codice_elettorale: 1060930410,
    codice_istat: 93041,
    codice_belfiore: "I403",
  },
  {
    id: 6289,
    comune: "SAN VITO AL TORRE",
    sigla: "UD",
    codice_elettorale: 1060851050,
    codice_istat: 30105,
    codice_belfiore: "I404",
  },
  {
    id: 6290,
    comune: "SAN VITO CHIETINO",
    sigla: "CH",
    codice_elettorale: 3130230860,
    codice_istat: 69086,
    codice_belfiore: "I394",
  },
  {
    id: 6291,
    comune: "SAN VITO DEI NORMANNI",
    sigla: "BR",
    codice_elettorale: 3160160170,
    codice_istat: 74017,
    codice_belfiore: "I396",
  },
  {
    id: 6292,
    comune: "SAN VITO DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100510,
    codice_istat: 25051,
    codice_belfiore: "I392",
  },
  {
    id: 6293,
    comune: "SAN VITO DI FAGAGNA",
    sigla: "UD",
    codice_elettorale: 1060851060,
    codice_istat: 30106,
    codice_belfiore: "I405",
  },
  {
    id: 6294,
    comune: "SAN VITO DI LEGUZZANO",
    sigla: "VI",
    codice_elettorale: 1050900960,
    codice_istat: 24096,
    codice_belfiore: "I401",
  },
  {
    id: 6295,
    comune: "SAN VITO LO CAPO",
    sigla: "TP",
    codice_elettorale: 4190820201,
    codice_istat: 81020,
    codice_belfiore: "I407",
  },
  {
    id: 6296,
    comune: "SAN VITO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700990,
    codice_istat: 58100,
    codice_belfiore: "I400",
  },
  {
    id: 6297,
    comune: "SAN VITO SULLO IONIO",
    sigla: "CZ",
    codice_elettorale: 3180221190,
    codice_istat: 79122,
    codice_belfiore: "I393",
  },
  {
    id: 6298,
    comune: "SAN VITTORE DEL LAZIO",
    sigla: "FR",
    codice_elettorale: 2120330690,
    codice_istat: 60070,
    codice_belfiore: "I408",
  },
  {
    id: 6299,
    comune: "SAN VITTORE OLONA",
    sigla: "MI",
    codice_elettorale: 1030492000,
    codice_istat: 15201,
    codice_belfiore: "I409",
  },
  {
    id: 6300,
    comune: "SAN ZENO DI MONTAGNA",
    sigla: "VR",
    codice_elettorale: 1050890780,
    codice_istat: 23079,
    codice_belfiore: "I414",
  },
  {
    id: 6301,
    comune: "SAN ZENO NAVIGLIO",
    sigla: "BS",
    codice_elettorale: 1030151640,
    codice_istat: 17173,
    codice_belfiore: "I412",
  },
  {
    id: 6302,
    comune: "SAN ZENONE AL LAMBRO",
    sigla: "MI",
    codice_elettorale: 1030492010,
    codice_istat: 15202,
    codice_belfiore: "I415",
  },
  {
    id: 6303,
    comune: "SAN ZENONE AL PO",
    sigla: "PV",
    codice_elettorale: 1030571420,
    codice_istat: 18145,
    codice_belfiore: "I416",
  },
  {
    id: 6304,
    comune: "SAN ZENONE DEGLI EZZELINI",
    sigla: "TV",
    codice_elettorale: 1050840760,
    codice_istat: 26077,
    codice_belfiore: "I417",
  },
  {
    id: 6305,
    comune: "SANARICA",
    sigla: "LE",
    codice_elettorale: 3160410660,
    codice_istat: 75067,
    codice_belfiore: "H757",
  },
  {
    id: 6306,
    comune: "SANDIGLIANO",
    sigla: "BI",
    codice_elettorale: 1010960590,
    codice_istat: 96059,
    codice_belfiore: "H821",
  },
  {
    id: 6307,
    comune: "SANDRIGO",
    sigla: "VI",
    codice_elettorale: 1050900910,
    codice_istat: 24091,
    codice_belfiore: "H829",
  },
  {
    id: 6308,
    comune: "SANFRE'",
    sigla: "CN",
    codice_elettorale: 1010272080,
    codice_istat: 4208,
    codice_belfiore: "H851",
  },
  {
    id: 6309,
    comune: "SANFRONT",
    sigla: "CN",
    codice_elettorale: 1010272090,
    codice_istat: 4209,
    codice_belfiore: "H852",
  },
  {
    id: 6310,
    comune: "SANGANO",
    sigla: "TO",
    codice_elettorale: 1010812311,
    codice_istat: 1241,
    codice_belfiore: "H855",
  },
  {
    id: 6311,
    comune: "SANGIANO",
    sigla: "VA",
    codice_elettorale: 1030861001,
    codice_istat: 12141,
    codice_belfiore: "H872",
  },
  {
    id: 6312,
    comune: "SANGINETO",
    sigla: "CS",
    codice_elettorale: 3180251180,
    codice_istat: 78117,
    codice_belfiore: "H877",
  },
  {
    id: 6313,
    comune: "SANGUINETTO",
    sigla: "VR",
    codice_elettorale: 1050890710,
    codice_istat: 23072,
    codice_belfiore: "H944",
  },
  {
    id: 6314,
    comune: "SANLURI",
    sigla: "SU",
    codice_elettorale: 4201110670,
    codice_istat: 111067,
    codice_belfiore: "H974",
  },
  {
    id: 6315,
    comune: "SANNAZZARO DE' BURGONDI",
    sigla: "PV",
    codice_elettorale: 1030571350,
    codice_istat: 18138,
    codice_belfiore: "I048",
  },
  {
    id: 6316,
    comune: "SANNICANDRO DI BARI",
    sigla: "BA",
    codice_elettorale: 3160090390,
    codice_istat: 72040,
    codice_belfiore: "I053",
  },
  {
    id: 6317,
    comune: "SANNICOLA",
    sigla: "LE",
    codice_elettorale: 3160410690,
    codice_istat: 75070,
    codice_belfiore: "I059",
  },
  {
    id: 6318,
    comune: "SANREMO",
    sigla: "IM",
    codice_elettorale: 1070370520,
    codice_istat: 8055,
    codice_belfiore: "I138",
  },
  {
    id: 6319,
    comune: "SANSEPOLCRO",
    sigla: "AR",
    codice_elettorale: 2090050340,
    codice_istat: 51034,
    codice_belfiore: "I155",
  },
  {
    id: 6320,
    comune: "SANT'AGAPITO",
    sigla: "IS",
    codice_elettorale: 3140940440,
    codice_istat: 94044,
    codice_belfiore: "I189",
  },
  {
    id: 6321,
    comune: "SANT'AGATA BOLOGNESE",
    sigla: "BO",
    codice_elettorale: 1080130560,
    codice_istat: 37056,
    codice_belfiore: "I191",
  },
  {
    id: 6322,
    comune: "SANT'AGATA DE' GOTI",
    sigla: "BN",
    codice_elettorale: 3150110680,
    codice_istat: 62070,
    codice_belfiore: "I197",
  },
  {
    id: 6323,
    comune: "SANT'AGATA DEL BIANCO",
    sigla: "RC",
    codice_elettorale: 3180670790,
    codice_istat: 80079,
    codice_belfiore: "I198",
  },
  {
    id: 6324,
    comune: "SANT'AGATA DI ESARO",
    sigla: "CS",
    codice_elettorale: 3180251320,
    codice_istat: 78131,
    codice_belfiore: "I192",
  },
  {
    id: 6325,
    comune: "SANT'AGATA DI MILITELLO",
    sigla: "ME",
    codice_elettorale: 4190480830,
    codice_istat: 83084,
    codice_belfiore: "I199",
  },
  {
    id: 6326,
    comune: "SANT'AGATA DI PUGLIA",
    sigla: "FG",
    codice_elettorale: 3160310500,
    codice_istat: 71052,
    codice_belfiore: "I193",
  },
  {
    id: 6327,
    comune: "SANT'AGATA FELTRIA",
    sigla: "RN",
    codice_elettorale: 1081010176,
    codice_istat: 99026,
    codice_belfiore: "I201",
  },
  {
    id: 6328,
    comune: "SANT'AGATA FOSSILI",
    sigla: "AL",
    codice_elettorale: 1010021530,
    codice_istat: 6156,
    codice_belfiore: "I190",
  },
  {
    id: 6329,
    comune: "SANT'AGATA LI BATTIATI",
    sigla: "CT",
    codice_elettorale: 4190210440,
    codice_istat: 87045,
    codice_belfiore: "I202",
  },
  {
    id: 6330,
    comune: "SANT'AGATA SUL SANTERNO",
    sigla: "RA",
    codice_elettorale: 1080660170,
    codice_istat: 39017,
    codice_belfiore: "I196",
  },
  {
    id: 6331,
    comune: "SANT'AGNELLO",
    sigla: "NA",
    codice_elettorale: 3150510710,
    codice_istat: 63071,
    codice_belfiore: "I208",
  },
  {
    id: 6332,
    comune: "SANT'ALBANO STURA",
    sigla: "CN",
    codice_elettorale: 1010272110,
    codice_istat: 4211,
    codice_belfiore: "I210",
  },
  {
    id: 6333,
    comune: "SANT'ALESSIO CON VIALONE",
    sigla: "PV",
    codice_elettorale: 1030571380,
    codice_istat: 18141,
    codice_belfiore: "I213",
  },
  {
    id: 6334,
    comune: "SANT'ALESSIO IN ASPROMONTE",
    sigla: "RC",
    codice_elettorale: 3180670800,
    codice_istat: 80080,
    codice_belfiore: "I214",
  },
  {
    id: 6335,
    comune: "SANT'ALESSIO SICULO",
    sigla: "ME",
    codice_elettorale: 4190480840,
    codice_istat: 83085,
    codice_belfiore: "I215",
  },
  {
    id: 6336,
    comune: "SANT'ALFIO",
    sigla: "CT",
    codice_elettorale: 4190210450,
    codice_istat: 87046,
    codice_belfiore: "I216",
  },
  {
    id: 6337,
    comune: "SANT'AMBROGIO DI TORINO",
    sigla: "TO",
    codice_elettorale: 1010812450,
    codice_istat: 1255,
    codice_belfiore: "I258",
  },
  {
    id: 6338,
    comune: "SANT'AMBROGIO DI VALPOLICELLA",
    sigla: "VR",
    codice_elettorale: 1050890760,
    codice_istat: 23077,
    codice_belfiore: "I259",
  },
  {
    id: 6339,
    comune: "SANT'AMBROGIO SUL GARIGLIANO",
    sigla: "FR",
    codice_elettorale: 2120330640,
    codice_istat: 60065,
    codice_belfiore: "I256",
  },
  {
    id: 6340,
    comune: "SANT'ANASTASIA",
    sigla: "NA",
    codice_elettorale: 3150510720,
    codice_istat: 63072,
    codice_belfiore: "I262",
  },
  {
    id: 6341,
    comune: "SANT'ANATOLIA DI NARCO",
    sigla: "PG",
    codice_elettorale: 2100580450,
    codice_istat: 54045,
    codice_belfiore: "I263",
  },
  {
    id: 6342,
    comune: "SANT'ANDREA APOSTOLO DELLO IONIO",
    sigla: "CZ",
    codice_elettorale: 3180221150,
    codice_istat: 79118,
    codice_belfiore: "I266",
  },
  {
    id: 6343,
    comune: "SANT'ANDREA DEL GARIGLIANO",
    sigla: "FR",
    codice_elettorale: 2120330650,
    codice_istat: 60066,
    codice_belfiore: "I265",
  },
  {
    id: 6344,
    comune: "SANT'ANDREA DI CONZA",
    sigla: "AV",
    codice_elettorale: 3150080880,
    codice_istat: 64089,
    codice_belfiore: "I264",
  },
  {
    id: 6345,
    comune: "SANT'ANDREA FRIUS",
    sigla: "SU",
    codice_elettorale: 4201110690,
    codice_istat: 111069,
    codice_belfiore: "I271",
  },
  {
    id: 6346,
    comune: "SANT'ANGELO A CUPOLO",
    sigla: "BN",
    codice_elettorale: 3150110690,
    codice_istat: 62071,
    codice_belfiore: "I277",
  },
  {
    id: 6347,
    comune: "SANT'ANGELO A FASANELLA",
    sigla: "SA",
    codice_elettorale: 3150721280,
    codice_istat: 65128,
    codice_belfiore: "I278",
  },
  {
    id: 6348,
    comune: "SANT'ANGELO A SCALA",
    sigla: "AV",
    codice_elettorale: 3150080900,
    codice_istat: 64091,
    codice_belfiore: "I280",
  },
  {
    id: 6349,
    comune: "SANT'ANGELO ALL'ESCA",
    sigla: "AV",
    codice_elettorale: 3150080890,
    codice_istat: 64090,
    codice_belfiore: "I279",
  },
  {
    id: 6350,
    comune: "SANT'ANGELO D'ALIFE",
    sigla: "CE",
    codice_elettorale: 3150200860,
    codice_istat: 61086,
    codice_belfiore: "I273",
  },
  {
    id: 6351,
    comune: "SANT'ANGELO DEI LOMBARDI",
    sigla: "AV",
    codice_elettorale: 3150080910,
    codice_istat: 64092,
    codice_belfiore: "I281",
  },
  {
    id: 6352,
    comune: "SANT'ANGELO DEL PESCO",
    sigla: "IS",
    codice_elettorale: 3140940460,
    codice_istat: 94046,
    codice_belfiore: "I282",
  },
  {
    id: 6353,
    comune: "SANT'ANGELO DI BROLO",
    sigla: "ME",
    codice_elettorale: 4190480870,
    codice_istat: 83088,
    codice_belfiore: "I283",
  },
  {
    id: 6354,
    comune: "SANT'ANGELO DI PIOVE DI SACCO",
    sigla: "PD",
    codice_elettorale: 1050540820,
    codice_istat: 28082,
    codice_belfiore: "I275",
  },
  {
    id: 6355,
    comune: "SANT'ANGELO IN PONTANO",
    sigla: "MC",
    codice_elettorale: 2110440480,
    codice_istat: 43048,
    codice_belfiore: "I286",
  },
  {
    id: 6356,
    comune: "SANT'ANGELO IN VADO",
    sigla: "PU",
    codice_elettorale: 2110590570,
    codice_istat: 41057,
    codice_belfiore: "I287",
  },
  {
    id: 6357,
    comune: "SANT'ANGELO LE FRATTE",
    sigla: "PZ",
    codice_elettorale: 3170640780,
    codice_istat: 76079,
    codice_belfiore: "I288",
  },
  {
    id: 6358,
    comune: "SANT'ANGELO LIMOSANO",
    sigla: "CB",
    codice_elettorale: 3140190730,
    codice_istat: 70073,
    codice_belfiore: "I289",
  },
  {
    id: 6359,
    comune: "SANT'ANGELO LODIGIANO",
    sigla: "LO",
    codice_elettorale: 1030990500,
    codice_istat: 98050,
    codice_belfiore: "I274",
  },
  {
    id: 6360,
    comune: "SANT'ANGELO LOMELLINA",
    sigla: "PV",
    codice_elettorale: 1030571410,
    codice_istat: 18144,
    codice_belfiore: "I276",
  },
  {
    id: 6361,
    comune: "SANT'ANGELO MUXARO",
    sigla: "AG",
    codice_elettorale: 4190010380,
    codice_istat: 84039,
    codice_belfiore: "I290",
  },
  {
    id: 6362,
    comune: "SANT'ANGELO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120700970,
    codice_istat: 58098,
    codice_belfiore: "I284",
  },
  {
    id: 6363,
    comune: "SANT'ANNA ARRESI",
    sigla: "SU",
    codice_elettorale: 4201110700,
    codice_istat: 111070,
    codice_belfiore: "M209",
  },
  {
    id: 6364,
    comune: "SANT'ANNA D'ALFAEDO",
    sigla: "VR",
    codice_elettorale: 1050890770,
    codice_istat: 23078,
    codice_belfiore: "I292",
  },
  {
    id: 6365,
    comune: "SANT'ANTIMO",
    sigla: "NA",
    codice_elettorale: 3150510730,
    codice_istat: 63073,
    codice_belfiore: "I293",
  },
  {
    id: 6366,
    comune: "SANT'ANTIOCO",
    sigla: "SU",
    codice_elettorale: 4201110710,
    codice_istat: 111071,
    codice_belfiore: "I294",
  },
  {
    id: 6367,
    comune: "SANT'ANTONINO DI SUSA",
    sigla: "TO",
    codice_elettorale: 1010812460,
    codice_istat: 1256,
    codice_belfiore: "I296",
  },
  {
    id: 6368,
    comune: "SANT'ANTONIO ABATE",
    sigla: "NA",
    codice_elettorale: 3150510740,
    codice_istat: 63074,
    codice_belfiore: "I300",
  },
  {
    id: 6369,
    comune: "SANT'ANTONIO DI GALLURA",
    sigla: "SS",
    codice_elettorale: 4200730602,
    codice_istat: 90085,
    codice_belfiore: "M276",
  },
  {
    id: 6370,
    comune: "SANT'APOLLINARE",
    sigla: "FR",
    codice_elettorale: 2120330660,
    codice_istat: 60067,
    codice_belfiore: "I302",
  },
  {
    id: 6371,
    comune: "SANT'ARCANGELO",
    sigla: "PZ",
    codice_elettorale: 3170640790,
    codice_istat: 76080,
    codice_belfiore: "I305",
  },
  {
    id: 6372,
    comune: "SANT'ARCANGELO TRIMONTE",
    sigla: "BN",
    codice_elettorale: 3150110691,
    codice_istat: 62078,
    codice_belfiore: "F557",
  },
  {
    id: 6373,
    comune: "SANT'ARPINO",
    sigla: "CE",
    codice_elettorale: 3150200870,
    codice_istat: 61087,
    codice_belfiore: "I306",
  },
  {
    id: 6374,
    comune: "SANT'ARSENIO",
    sigla: "SA",
    codice_elettorale: 3150721290,
    codice_istat: 65129,
    codice_belfiore: "I307",
  },
  {
    id: 6375,
    comune: "SANT'EGIDIO ALLA VIBRATA",
    sigla: "TE",
    codice_elettorale: 3130790370,
    codice_istat: 67038,
    codice_belfiore: "I318",
  },
  {
    id: 6376,
    comune: "SANT'EGIDIO DEL MONTE ALBINO",
    sigla: "SA",
    codice_elettorale: 3150721300,
    codice_istat: 65130,
    codice_belfiore: "I317",
  },
  {
    id: 6377,
    comune: "SANT'ELENA",
    sigla: "PD",
    codice_elettorale: 1050540830,
    codice_istat: 28083,
    codice_belfiore: "I319",
  },
  {
    id: 6378,
    comune: "SANT'ELENA SANNITA",
    sigla: "IS",
    codice_elettorale: 3140940470,
    codice_istat: 94047,
    codice_belfiore: "B466",
  },
  {
    id: 6379,
    comune: "SANT'ELIA A PIANISI",
    sigla: "CB",
    codice_elettorale: 3140190740,
    codice_istat: 70074,
    codice_belfiore: "I320",
  },
  {
    id: 6380,
    comune: "SANT'ELIA FIUMERAPIDO",
    sigla: "FR",
    codice_elettorale: 2120330670,
    codice_istat: 60068,
    codice_belfiore: "I321",
  },
  {
    id: 6381,
    comune: "SANT'ELPIDIO A MARE",
    sigla: "FM",
    codice_elettorale: 2111050370,
    codice_istat: 109037,
    codice_belfiore: "I324",
  },
  {
    id: 6382,
    comune: "SANT'EUFEMIA A MAIELLA",
    sigla: "PE",
    codice_elettorale: 3130600370,
    codice_istat: 68037,
    codice_belfiore: "I332",
  },
  {
    id: 6383,
    comune: "SANT'EUFEMIA D'ASPROMONTE",
    sigla: "RC",
    codice_elettorale: 3180670810,
    codice_istat: 80081,
    codice_belfiore: "I333",
  },
  {
    id: 6384,
    comune: "SANT'EUSANIO DEL SANGRO",
    sigla: "CH",
    codice_elettorale: 3130230850,
    codice_istat: 69085,
    codice_belfiore: "I335",
  },
  {
    id: 6385,
    comune: "SANT'EUSANIO FORCONESE",
    sigla: "AQ",
    codice_elettorale: 3130380891,
    codice_istat: 66090,
    codice_belfiore: "I336",
  },
  {
    id: 6386,
    comune: "SANT'ILARIO D'ENZA",
    sigla: "RE",
    codice_elettorale: 1080680390,
    codice_istat: 35039,
    codice_belfiore: "I342",
  },
  {
    id: 6387,
    comune: "SANT'ILARIO DELLO IONIO",
    sigla: "RC",
    codice_elettorale: 3180670820,
    codice_istat: 80082,
    codice_belfiore: "I341",
  },
  {
    id: 6388,
    comune: "SANT'IPPOLITO",
    sigla: "PU",
    codice_elettorale: 2110590580,
    codice_istat: 41058,
    codice_belfiore: "I344",
  },
  {
    id: 6389,
    comune: "SANT'OLCESE",
    sigla: "GE",
    codice_elettorale: 1070340550,
    codice_istat: 10055,
    codice_belfiore: "I346",
  },
  {
    id: 6390,
    comune: "SANT'OMERO",
    sigla: "TE",
    codice_elettorale: 3130790380,
    codice_istat: 67039,
    codice_belfiore: "I348",
  },
  {
    id: 6391,
    comune: "SANT'OMOBONO TERME",
    sigla: "BG",
    codice_elettorale: 1030121831,
    codice_istat: 16252,
    codice_belfiore: "M333",
  },
  {
    id: 6392,
    comune: "SANT'ONOFRIO",
    sigla: "VV",
    codice_elettorale: 3181030360,
    codice_istat: 102036,
    codice_belfiore: "I350",
  },
  {
    id: 6393,
    comune: "SANT'ORESTE",
    sigla: "RM",
    codice_elettorale: 2120700980,
    codice_istat: 58099,
    codice_belfiore: "I352",
  },
  {
    id: 6394,
    comune: "SANT'ORSOLA TERME",
    sigla: "TN",
    codice_elettorale: 1040831570,
    codice_istat: 22168,
    codice_belfiore: "I354",
  },
  {
    id: 6395,
    comune: "SANT'URBANO",
    sigla: "PD",
    codice_elettorale: 1050540840,
    codice_istat: 28084,
    codice_belfiore: "I375",
  },
  {
    id: 6396,
    comune: "SANTA BRIGIDA",
    sigla: "BG",
    codice_elettorale: 1030121820,
    codice_istat: 16191,
    codice_belfiore: "I168",
  },
  {
    id: 6397,
    comune: "SANTA CATERINA ALBANESE",
    sigla: "CS",
    codice_elettorale: 3180251300,
    codice_istat: 78129,
    codice_belfiore: "I171",
  },
  {
    id: 6398,
    comune: "SANTA CATERINA DELLO IONIO",
    sigla: "CZ",
    codice_elettorale: 3180221140,
    codice_istat: 79117,
    codice_belfiore: "I170",
  },
  {
    id: 6399,
    comune: "SANTA CATERINA VILLARMOSA",
    sigla: "CL",
    codice_elettorale: 4190180170,
    codice_istat: 85017,
    codice_belfiore: "I169",
  },
  {
    id: 6400,
    comune: "SANTA CESAREA TERME",
    sigla: "LE",
    codice_elettorale: 3160410710,
    codice_istat: 75072,
    codice_belfiore: "I172",
  },
  {
    id: 6401,
    comune: "SANTA CRISTINA D'ASPROMONTE",
    sigla: "RC",
    codice_elettorale: 3180670780,
    codice_istat: 80078,
    codice_belfiore: "I176",
  },
  {
    id: 6402,
    comune: "SANTA CRISTINA E BISSONE",
    sigla: "PV",
    codice_elettorale: 1030571360,
    codice_istat: 18139,
    codice_belfiore: "I175",
  },
  {
    id: 6403,
    comune: "SANTA CRISTINA GELA",
    sigla: "PA",
    codice_elettorale: 4190550640,
    codice_istat: 82066,
    codice_belfiore: "I174",
  },
  {
    id: 6404,
    comune: "SANTA CRISTINA VALGARDENA/ST. CHRISTINA IN GRODEN",
    sigla: "BZ",
    codice_elettorale: 1040140780,
    codice_istat: 21085,
    codice_belfiore: "I173",
  },
  {
    id: 6405,
    comune: "SANTA CROCE CAMERINA",
    sigla: "RG",
    codice_elettorale: 4190650100,
    codice_istat: 88010,
    codice_belfiore: "I178",
  },
  {
    id: 6406,
    comune: "SANTA CROCE DEL SANNIO",
    sigla: "BN",
    codice_elettorale: 3150110670,
    codice_istat: 62069,
    codice_belfiore: "I179",
  },
  {
    id: 6407,
    comune: "SANTA CROCE DI MAGLIANO",
    sigla: "CB",
    codice_elettorale: 3140190720,
    codice_istat: 70072,
    codice_belfiore: "I181",
  },
  {
    id: 6408,
    comune: "SANTA CROCE SULL'ARNO",
    sigla: "PI",
    codice_elettorale: 2090620320,
    codice_istat: 50033,
    codice_belfiore: "I177",
  },
  {
    id: 6409,
    comune: "SANTA DOMENICA TALAO",
    sigla: "CS",
    codice_elettorale: 3180251310,
    codice_istat: 78130,
    codice_belfiore: "I183",
  },
  {
    id: 6410,
    comune: "SANTA DOMENICA VITTORIA",
    sigla: "ME",
    codice_elettorale: 4190480820,
    codice_istat: 83083,
    codice_belfiore: "I184",
  },
  {
    id: 6411,
    comune: "SANTA ELISABETTA",
    sigla: "AG",
    codice_elettorale: 4190010361,
    codice_istat: 84037,
    codice_belfiore: "I185",
  },
  {
    id: 6412,
    comune: "SANTA FIORA",
    sigla: "GR",
    codice_elettorale: 2090360210,
    codice_istat: 53022,
    codice_belfiore: "I187",
  },
  {
    id: 6413,
    comune: "SANTA FLAVIA",
    sigla: "PA",
    codice_elettorale: 4190550650,
    codice_istat: 82067,
    codice_belfiore: "I188",
  },
  {
    id: 6414,
    comune: "SANTA GIULETTA",
    sigla: "PV",
    codice_elettorale: 1030571370,
    codice_istat: 18140,
    codice_belfiore: "I203",
  },
  {
    id: 6415,
    comune: "SANTA GIUSTA",
    sigla: "OR",
    codice_elettorale: 4200950470,
    codice_istat: 95047,
    codice_belfiore: "I205",
  },
  {
    id: 6416,
    comune: "SANTA GIUSTINA",
    sigla: "BL",
    codice_elettorale: 1050100480,
    codice_istat: 25048,
    codice_belfiore: "I206",
  },
  {
    id: 6417,
    comune: "SANTA GIUSTINA IN COLLE",
    sigla: "PD",
    codice_elettorale: 1050540800,
    codice_istat: 28080,
    codice_belfiore: "I207",
  },
  {
    id: 6418,
    comune: "SANTA LUCE",
    sigla: "PI",
    codice_elettorale: 2090620330,
    codice_istat: 50034,
    codice_belfiore: "I217",
  },
  {
    id: 6419,
    comune: "SANTA LUCIA DEL MELA",
    sigla: "ME",
    codice_elettorale: 4190480850,
    codice_istat: 83086,
    codice_belfiore: "I220",
  },
  {
    id: 6420,
    comune: "SANTA LUCIA DI PIAVE",
    sigla: "TV",
    codice_elettorale: 1050840740,
    codice_istat: 26075,
    codice_belfiore: "I221",
  },
  {
    id: 6421,
    comune: "SANTA LUCIA DI SERINO",
    sigla: "AV",
    codice_elettorale: 3150080870,
    codice_istat: 64088,
    codice_belfiore: "I219",
  },
  {
    id: 6422,
    comune: "SANTA MARGHERITA DI BELICE",
    sigla: "AG",
    codice_elettorale: 4190010370,
    codice_istat: 84038,
    codice_belfiore: "I224",
  },
  {
    id: 6423,
    comune: "SANTA MARGHERITA DI STAFFORA",
    sigla: "PV",
    codice_elettorale: 1030571390,
    codice_istat: 18142,
    codice_belfiore: "I230",
  },
  {
    id: 6424,
    comune: "SANTA MARGHERITA LIGURE",
    sigla: "GE",
    codice_elettorale: 1070340540,
    codice_istat: 10054,
    codice_belfiore: "I225",
  },
  {
    id: 6425,
    comune: "SANTA MARIA A MONTE",
    sigla: "PI",
    codice_elettorale: 2090620340,
    codice_istat: 50035,
    codice_belfiore: "I232",
  },
  {
    id: 6426,
    comune: "SANTA MARIA A VICO",
    sigla: "CE",
    codice_elettorale: 3150200820,
    codice_istat: 61082,
    codice_belfiore: "I233",
  },
  {
    id: 6427,
    comune: "SANTA MARIA CAPUA VETERE",
    sigla: "CE",
    codice_elettorale: 3150200830,
    codice_istat: 61083,
    codice_belfiore: "I234",
  },
  {
    id: 6428,
    comune: "SANTA MARIA COGHINAS",
    sigla: "SS",
    codice_elettorale: 4200730603,
    codice_istat: 90087,
    codice_belfiore: "M284",
  },
  {
    id: 6429,
    comune: "SANTA MARIA DEL CEDRO",
    sigla: "CS",
    codice_elettorale: 3180251321,
    codice_istat: 78132,
    codice_belfiore: "C717",
  },
  {
    id: 6430,
    comune: "SANTA MARIA DEL MOLISE",
    sigla: "IS",
    codice_elettorale: 3140940450,
    codice_istat: 94045,
    codice_belfiore: "I238",
  },
  {
    id: 6431,
    comune: "SANTA MARIA DELLA VERSA",
    sigla: "PV",
    codice_elettorale: 1030571400,
    codice_istat: 18143,
    codice_belfiore: "I237",
  },
  {
    id: 6432,
    comune: "SANTA MARIA DI LICODIA",
    sigla: "CT",
    codice_elettorale: 4190210460,
    codice_istat: 87047,
    codice_belfiore: "I240",
  },
  {
    id: 6433,
    comune: "SANTA MARIA DI SALA",
    sigla: "VE",
    codice_elettorale: 1050870350,
    codice_istat: 27035,
    codice_belfiore: "I242",
  },
  {
    id: 6434,
    comune: "SANTA MARIA HOE'",
    sigla: "LC",
    codice_elettorale: 1030980740,
    codice_istat: 97074,
    codice_belfiore: "I243",
  },
  {
    id: 6435,
    comune: "SANTA MARIA IMBARO",
    sigla: "CH",
    codice_elettorale: 3130230840,
    codice_istat: 69084,
    codice_belfiore: "I244",
  },
  {
    id: 6436,
    comune: "SANTA MARIA LA CARITA'",
    sigla: "NA",
    codice_elettorale: 3150510711,
    codice_istat: 63090,
    codice_belfiore: "M273",
  },
  {
    id: 6437,
    comune: "SANTA MARIA LA FOSSA",
    sigla: "CE",
    codice_elettorale: 3150200840,
    codice_istat: 61084,
    codice_belfiore: "I247",
  },
  {
    id: 6438,
    comune: "SANTA MARIA LA LONGA",
    sigla: "UD",
    codice_elettorale: 1060851040,
    codice_istat: 30104,
    codice_belfiore: "I248",
  },
  {
    id: 6439,
    comune: "SANTA MARIA MAGGIORE",
    sigla: "VB",
    codice_elettorale: 1011020620,
    codice_istat: 103062,
    codice_belfiore: "I249",
  },
  {
    id: 6440,
    comune: "SANTA MARIA NUOVA",
    sigla: "AN",
    codice_elettorale: 2110030430,
    codice_istat: 42043,
    codice_belfiore: "I251",
  },
  {
    id: 6441,
    comune: "SANTA MARINA",
    sigla: "SA",
    codice_elettorale: 3150721270,
    codice_istat: 65127,
    codice_belfiore: "I253",
  },
  {
    id: 6442,
    comune: "SANTA MARINA SALINA",
    sigla: "ME",
    codice_elettorale: 4190480860,
    codice_istat: 83087,
    codice_belfiore: "I254",
  },
  {
    id: 6443,
    comune: "SANTA MARINELLA",
    sigla: "RM",
    codice_elettorale: 2120700960,
    codice_istat: 58097,
    codice_belfiore: "I255",
  },
  {
    id: 6444,
    comune: "SANTA NINFA",
    sigla: "TP",
    codice_elettorale: 4190820200,
    codice_istat: 81019,
    codice_belfiore: "I291",
  },
  {
    id: 6445,
    comune: "SANTA PAOLINA",
    sigla: "AV",
    codice_elettorale: 3150080920,
    codice_istat: 64093,
    codice_belfiore: "I301",
  },
  {
    id: 6446,
    comune: "SANTA SEVERINA",
    sigla: "KR",
    codice_elettorale: 3180970220,
    codice_istat: 101022,
    codice_belfiore: "I308",
  },
  {
    id: 6447,
    comune: "SANTA SOFIA",
    sigla: "FC",
    codice_elettorale: 1080320420,
    codice_istat: 40043,
    codice_belfiore: "I310",
  },
  {
    id: 6448,
    comune: "SANTA SOFIA D'EPIRO",
    sigla: "CS",
    codice_elettorale: 3180251330,
    codice_istat: 78133,
    codice_belfiore: "I309",
  },
  {
    id: 6449,
    comune: "SANTA TERESA DI RIVA",
    sigla: "ME",
    codice_elettorale: 4190480880,
    codice_istat: 83089,
    codice_belfiore: "I311",
  },
  {
    id: 6450,
    comune: "SANTA TERESA GALLURA",
    sigla: "SS",
    codice_elettorale: 4200730610,
    codice_istat: 90063,
    codice_belfiore: "I312",
  },
  {
    id: 6451,
    comune: "SANTA VENERINA",
    sigla: "CT",
    codice_elettorale: 4190210470,
    codice_istat: 87048,
    codice_belfiore: "I314",
  },
  {
    id: 6452,
    comune: "SANTA VITTORIA D'ALBA",
    sigla: "CN",
    codice_elettorale: 1010272120,
    codice_istat: 4212,
    codice_belfiore: "I316",
  },
  {
    id: 6453,
    comune: "SANTA VITTORIA IN MATENANO",
    sigla: "FM",
    codice_elettorale: 2111050360,
    codice_istat: 109036,
    codice_belfiore: "I315",
  },
  {
    id: 6454,
    comune: "SANTADI",
    sigla: "SU",
    codice_elettorale: 4201110680,
    codice_istat: 111068,
    codice_belfiore: "I182",
  },
  {
    id: 6455,
    comune: "SANTARCANGELO DI ROMAGNA",
    sigla: "RN",
    codice_elettorale: 1081010180,
    codice_istat: 99018,
    codice_belfiore: "I304",
  },
  {
    id: 6456,
    comune: "SANTE MARIE",
    sigla: "AQ",
    codice_elettorale: 3130380890,
    codice_istat: 66089,
    codice_belfiore: "I326",
  },
  {
    id: 6457,
    comune: "SANTENA",
    sigla: "TO",
    codice_elettorale: 1010812470,
    codice_istat: 1257,
    codice_belfiore: "I327",
  },
  {
    id: 6458,
    comune: "SANTERAMO IN COLLE",
    sigla: "BA",
    codice_elettorale: 3160090400,
    codice_istat: 72041,
    codice_belfiore: "I330",
  },
  {
    id: 6459,
    comune: "SANTHIA'",
    sigla: "VC",
    codice_elettorale: 1010881320,
    codice_istat: 2133,
    codice_belfiore: "I337",
  },
  {
    id: 6460,
    comune: "SANTI COSMA E DAMIANO",
    sigla: "LT",
    codice_elettorale: 2120400260,
    codice_istat: 59026,
    codice_belfiore: "I339",
  },
  {
    id: 6461,
    comune: "SANTO STEFANO AL MARE",
    sigla: "IM",
    codice_elettorale: 1070370521,
    codice_istat: 8056,
    codice_belfiore: "I365",
  },
  {
    id: 6462,
    comune: "SANTO STEFANO BELBO",
    sigla: "CN",
    codice_elettorale: 1010272130,
    codice_istat: 4213,
    codice_belfiore: "I367",
  },
  {
    id: 6463,
    comune: "SANTO STEFANO D'AVETO",
    sigla: "GE",
    codice_elettorale: 1070340560,
    codice_istat: 10056,
    codice_belfiore: "I368",
  },
  {
    id: 6464,
    comune: "SANTO STEFANO DEL SOLE",
    sigla: "AV",
    codice_elettorale: 3150080940,
    codice_istat: 64095,
    codice_belfiore: "I357",
  },
  {
    id: 6465,
    comune: "SANTO STEFANO DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100500,
    codice_istat: 25050,
    codice_belfiore: "C919",
  },
  {
    id: 6466,
    comune: "SANTO STEFANO DI CAMASTRA",
    sigla: "ME",
    codice_elettorale: 4190480900,
    codice_istat: 83091,
    codice_belfiore: "I370",
  },
  {
    id: 6467,
    comune: "SANTO STEFANO DI MAGRA",
    sigla: "SP",
    codice_elettorale: 1070390260,
    codice_istat: 11026,
    codice_belfiore: "I363",
  },
  {
    id: 6468,
    comune: "SANTO STEFANO DI ROGLIANO",
    sigla: "CS",
    codice_elettorale: 3180251340,
    codice_istat: 78134,
    codice_belfiore: "I359",
  },
  {
    id: 6469,
    comune: "SANTO STEFANO DI SESSANIO",
    sigla: "AQ",
    codice_elettorale: 3130380900,
    codice_istat: 66091,
    codice_belfiore: "I360",
  },
  {
    id: 6470,
    comune: "SANTO STEFANO IN ASPROMONTE",
    sigla: "RC",
    codice_elettorale: 3180670830,
    codice_istat: 80083,
    codice_belfiore: "I371",
  },
  {
    id: 6471,
    comune: "SANTO STEFANO LODIGIANO",
    sigla: "LO",
    codice_elettorale: 1030990510,
    codice_istat: 98051,
    codice_belfiore: "I362",
  },
  {
    id: 6472,
    comune: "SANTO STEFANO QUISQUINA",
    sigla: "AG",
    codice_elettorale: 4190010390,
    codice_istat: 84040,
    codice_belfiore: "I356",
  },
  {
    id: 6473,
    comune: "SANTO STEFANO ROERO",
    sigla: "CN",
    codice_elettorale: 1010272140,
    codice_istat: 4214,
    codice_belfiore: "I372",
  },
  {
    id: 6474,
    comune: "SANTO STEFANO TICINO",
    sigla: "MI",
    codice_elettorale: 1030491990,
    codice_istat: 15200,
    codice_belfiore: "I361",
  },
  {
    id: 6475,
    comune: "SANTOMENNA",
    sigla: "SA",
    codice_elettorale: 3150721310,
    codice_istat: 65131,
    codice_belfiore: "I260",
  },
  {
    id: 6476,
    comune: "SANTOPADRE",
    sigla: "FR",
    codice_elettorale: 2120330680,
    codice_istat: 60069,
    codice_belfiore: "I351",
  },
  {
    id: 6477,
    comune: "SANTORSO",
    sigla: "VI",
    codice_elettorale: 1050900950,
    codice_istat: 24095,
    codice_belfiore: "I353",
  },
  {
    id: 6478,
    comune: "SANTU LUSSURGIU",
    sigla: "OR",
    codice_elettorale: 4200950490,
    codice_istat: 95049,
    codice_belfiore: "I374",
  },
  {
    id: 6479,
    comune: "SANZA",
    sigla: "SA",
    codice_elettorale: 3150721330,
    codice_istat: 65133,
    codice_belfiore: "I410",
  },
  {
    id: 6480,
    comune: "SANZENO",
    sigla: "TN",
    codice_elettorale: 1040831580,
    codice_istat: 22169,
    codice_belfiore: "I411",
  },
  {
    id: 6481,
    comune: "SAONARA",
    sigla: "PD",
    codice_elettorale: 1050540850,
    codice_istat: 28085,
    codice_belfiore: "I418",
  },
  {
    id: 6482,
    comune: "SAPONARA",
    sigla: "ME",
    codice_elettorale: 4190480901,
    codice_istat: 83092,
    codice_belfiore: "I420",
  },
  {
    id: 6483,
    comune: "SAPPADA",
    sigla: "UD",
    codice_elettorale: 1060851065,
    codice_istat: 30189,
    codice_belfiore: "I421",
  },
  {
    id: 6484,
    comune: "SAPRI",
    sigla: "SA",
    codice_elettorale: 3150721340,
    codice_istat: 65134,
    codice_belfiore: "I422",
  },
  {
    id: 6485,
    comune: "SARACENA",
    sigla: "CS",
    codice_elettorale: 3180251360,
    codice_istat: 78136,
    codice_belfiore: "I423",
  },
  {
    id: 6486,
    comune: "SARACINESCO",
    sigla: "RM",
    codice_elettorale: 2120701000,
    codice_istat: 58101,
    codice_belfiore: "I424",
  },
  {
    id: 6487,
    comune: "SARCEDO",
    sigla: "VI",
    codice_elettorale: 1050900970,
    codice_istat: 24097,
    codice_belfiore: "I425",
  },
  {
    id: 6488,
    comune: "SARCONI",
    sigla: "PZ",
    codice_elettorale: 3170640800,
    codice_istat: 76081,
    codice_belfiore: "I426",
  },
  {
    id: 6489,
    comune: "SARDARA",
    sigla: "SU",
    codice_elettorale: 4201110720,
    codice_istat: 111072,
    codice_belfiore: "I428",
  },
  {
    id: 6490,
    comune: "SARDIGLIANO",
    sigla: "AL",
    codice_elettorale: 1010021540,
    codice_istat: 6157,
    codice_belfiore: "I429",
  },
  {
    id: 6491,
    comune: "SAREGO",
    sigla: "VI",
    codice_elettorale: 1050900980,
    codice_istat: 24098,
    codice_belfiore: "I430",
  },
  {
    id: 6492,
    comune: "SARENTINO/SARNTAL",
    sigla: "BZ",
    codice_elettorale: 1040140790,
    codice_istat: 21086,
    codice_belfiore: "I431",
  },
  {
    id: 6493,
    comune: "SAREZZANO",
    sigla: "AL",
    codice_elettorale: 1010021550,
    codice_istat: 6158,
    codice_belfiore: "I432",
  },
  {
    id: 6494,
    comune: "SAREZZO",
    sigla: "BS",
    codice_elettorale: 1030151650,
    codice_istat: 17174,
    codice_belfiore: "I433",
  },
  {
    id: 6495,
    comune: "SARMATO",
    sigla: "PC",
    codice_elettorale: 1080610420,
    codice_istat: 33042,
    codice_belfiore: "I434",
  },
  {
    id: 6496,
    comune: "SARMEDE",
    sigla: "TV",
    codice_elettorale: 1050840770,
    codice_istat: 26078,
    codice_belfiore: "I435",
  },
  {
    id: 6497,
    comune: "SARNANO",
    sigla: "MC",
    codice_elettorale: 2110440490,
    codice_istat: 43049,
    codice_belfiore: "I436",
  },
  {
    id: 6498,
    comune: "SARNICO",
    sigla: "BG",
    codice_elettorale: 1030121840,
    codice_istat: 16193,
    codice_belfiore: "I437",
  },
  {
    id: 6499,
    comune: "SARNO",
    sigla: "SA",
    codice_elettorale: 3150721350,
    codice_istat: 65135,
    codice_belfiore: "I438",
  },
  {
    id: 6500,
    comune: "SARNONICO",
    sigla: "TN",
    codice_elettorale: 1040831590,
    codice_istat: 22170,
    codice_belfiore: "I439",
  },
  {
    id: 6501,
    comune: "SARONNO",
    sigla: "VA",
    codice_elettorale: 1030861010,
    codice_istat: 12119,
    codice_belfiore: "I441",
  },
  {
    id: 6502,
    comune: "SARRE",
    sigla: "AO",
    codice_elettorale: 1020040650,
    codice_istat: 7066,
    codice_belfiore: "I442",
  },
  {
    id: 6503,
    comune: "SARROCH",
    sigla: "CA",
    codice_elettorale: 4200170660,
    codice_istat: 92066,
    codice_belfiore: "I443",
  },
  {
    id: 6504,
    comune: "SARSINA",
    sigla: "FC",
    codice_elettorale: 1080320430,
    codice_istat: 40044,
    codice_belfiore: "I444",
  },
  {
    id: 6505,
    comune: "SARTEANO",
    sigla: "SI",
    codice_elettorale: 2090750310,
    codice_istat: 52031,
    codice_belfiore: "I445",
  },
  {
    id: 6506,
    comune: "SARTIRANA LOMELLINA",
    sigla: "PV",
    codice_elettorale: 1030571430,
    codice_istat: 18146,
    codice_belfiore: "I447",
  },
  {
    id: 6507,
    comune: "SARULE",
    sigla: "NU",
    codice_elettorale: 4200530740,
    codice_istat: 91077,
    codice_belfiore: "I448",
  },
  {
    id: 6508,
    comune: "SARZANA",
    sigla: "SP",
    codice_elettorale: 1070390270,
    codice_istat: 11027,
    codice_belfiore: "I449",
  },
  {
    id: 6509,
    comune: "SASSANO",
    sigla: "SA",
    codice_elettorale: 3150721360,
    codice_istat: 65136,
    codice_belfiore: "I451",
  },
  {
    id: 6510,
    comune: "SASSARI",
    sigla: "SS",
    codice_elettorale: 4200730620,
    codice_istat: 90064,
    codice_belfiore: "I452",
  },
  {
    id: 6511,
    comune: "SASSELLO",
    sigla: "SV",
    codice_elettorale: 1070740550,
    codice_istat: 9055,
    codice_belfiore: "I453",
  },
  {
    id: 6512,
    comune: "SASSETTA",
    sigla: "LI",
    codice_elettorale: 2090420190,
    codice_istat: 49019,
    codice_belfiore: "I454",
  },
  {
    id: 6513,
    comune: "SASSINORO",
    sigla: "BN",
    codice_elettorale: 3150110700,
    codice_istat: 62072,
    codice_belfiore: "I455",
  },
  {
    id: 6514,
    comune: "SASSO DI CASTALDA",
    sigla: "PZ",
    codice_elettorale: 3170640810,
    codice_istat: 76082,
    codice_belfiore: "I457",
  },
  {
    id: 6515,
    comune: "SASSO MARCONI",
    sigla: "BO",
    codice_elettorale: 1080130570,
    codice_istat: 37057,
    codice_belfiore: "G972",
  },
  {
    id: 6516,
    comune: "SASSOCORVARO AUDITORE",
    sigla: "PU",
    codice_elettorale: 2110590595,
    codice_istat: 41071,
    codice_belfiore: "M413",
  },
  {
    id: 6517,
    comune: "SASSOFELTRIO",
    sigla: "RN",
    codice_elettorale: 1081010183,
    codice_istat: 99031,
    codice_belfiore: "I460",
  },
  {
    id: 6518,
    comune: "SASSOFERRATO",
    sigla: "AN",
    codice_elettorale: 2110030440,
    codice_istat: 42044,
    codice_belfiore: "I461",
  },
  {
    id: 6519,
    comune: "SASSUOLO",
    sigla: "MO",
    codice_elettorale: 1080500390,
    codice_istat: 36040,
    codice_belfiore: "I462",
  },
  {
    id: 6520,
    comune: "SATRIANO",
    sigla: "CZ",
    codice_elettorale: 3180221200,
    codice_istat: 79123,
    codice_belfiore: "I463",
  },
  {
    id: 6521,
    comune: "SATRIANO DI LUCANIA",
    sigla: "PZ",
    codice_elettorale: 3170640820,
    codice_istat: 76083,
    codice_belfiore: "G614",
  },
  {
    id: 6522,
    comune: "SAURIS",
    sigla: "UD",
    codice_elettorale: 1060851070,
    codice_istat: 30107,
    codice_belfiore: "I464",
  },
  {
    id: 6523,
    comune: "SAUZE D'OULX",
    sigla: "TO",
    codice_elettorale: 1010812490,
    codice_istat: 1259,
    codice_belfiore: "I466",
  },
  {
    id: 6524,
    comune: "SAUZE DI CESANA",
    sigla: "TO",
    codice_elettorale: 1010812480,
    codice_istat: 1258,
    codice_belfiore: "I465",
  },
  {
    id: 6525,
    comune: "SAVA",
    sigla: "TA",
    codice_elettorale: 3160780260,
    codice_istat: 73026,
    codice_belfiore: "I467",
  },
  {
    id: 6526,
    comune: "SAVELLI",
    sigla: "KR",
    codice_elettorale: 3180970230,
    codice_istat: 101023,
    codice_belfiore: "I468",
  },
  {
    id: 6527,
    comune: "SAVIANO",
    sigla: "NA",
    codice_elettorale: 3150510760,
    codice_istat: 63076,
    codice_belfiore: "I469",
  },
  {
    id: 6528,
    comune: "SAVIGLIANO",
    sigla: "CN",
    codice_elettorale: 1010272150,
    codice_istat: 4215,
    codice_belfiore: "I470",
  },
  {
    id: 6529,
    comune: "SAVIGNANO IRPINO",
    sigla: "AV",
    codice_elettorale: 3150080950,
    codice_istat: 64096,
    codice_belfiore: "I471",
  },
  {
    id: 6530,
    comune: "SAVIGNANO SUL PANARO",
    sigla: "MO",
    codice_elettorale: 1080500400,
    codice_istat: 36041,
    codice_belfiore: "I473",
  },
  {
    id: 6531,
    comune: "SAVIGNANO SUL RUBICONE",
    sigla: "FC",
    codice_elettorale: 1080320440,
    codice_istat: 40045,
    codice_belfiore: "I472",
  },
  {
    id: 6532,
    comune: "SAVIGNONE",
    sigla: "GE",
    codice_elettorale: 1070340570,
    codice_istat: 10057,
    codice_belfiore: "I475",
  },
  {
    id: 6533,
    comune: "SAVIORE DELL'ADAMELLO",
    sigla: "BS",
    codice_elettorale: 1030151651,
    codice_istat: 17175,
    codice_belfiore: "I476",
  },
  {
    id: 6534,
    comune: "SAVOCA",
    sigla: "ME",
    codice_elettorale: 4190480910,
    codice_istat: 83093,
    codice_belfiore: "I477",
  },
  {
    id: 6535,
    comune: "SAVOGNA",
    sigla: "UD",
    codice_elettorale: 1060851080,
    codice_istat: 30108,
    codice_belfiore: "I478",
  },
  {
    id: 6536,
    comune: "SAVOGNA D'ISONZO",
    sigla: "GO",
    codice_elettorale: 1060350180,
    codice_istat: 31022,
    codice_belfiore: "I479",
  },
  {
    id: 6537,
    comune: "SAVOIA DI LUCANIA",
    sigla: "PZ",
    codice_elettorale: 3170640830,
    codice_istat: 76084,
    codice_belfiore: "H730",
  },
  {
    id: 6538,
    comune: "SAVONA",
    sigla: "SV",
    codice_elettorale: 1070740560,
    codice_istat: 9056,
    codice_belfiore: "I480",
  },
  {
    id: 6539,
    comune: "SCAFA",
    sigla: "PE",
    codice_elettorale: 3130600390,
    codice_istat: 68039,
    codice_belfiore: "I482",
  },
  {
    id: 6540,
    comune: "SCAFATI",
    sigla: "SA",
    codice_elettorale: 3150721370,
    codice_istat: 65137,
    codice_belfiore: "I483",
  },
  {
    id: 6541,
    comune: "SCAGNELLO",
    sigla: "CN",
    codice_elettorale: 1010272160,
    codice_istat: 4216,
    codice_belfiore: "I484",
  },
  {
    id: 6542,
    comune: "SCALA",
    sigla: "SA",
    codice_elettorale: 3150721380,
    codice_istat: 65138,
    codice_belfiore: "I486",
  },
  {
    id: 6543,
    comune: "SCALA COELI",
    sigla: "CS",
    codice_elettorale: 3180251370,
    codice_istat: 78137,
    codice_belfiore: "I485",
  },
  {
    id: 6544,
    comune: "SCALDASOLE",
    sigla: "PV",
    codice_elettorale: 1030571440,
    codice_istat: 18147,
    codice_belfiore: "I487",
  },
  {
    id: 6545,
    comune: "SCALEA",
    sigla: "CS",
    codice_elettorale: 3180251380,
    codice_istat: 78138,
    codice_belfiore: "I489",
  },
  {
    id: 6546,
    comune: "SCALENGHE",
    sigla: "TO",
    codice_elettorale: 1010812500,
    codice_istat: 1260,
    codice_belfiore: "I490",
  },
  {
    id: 6547,
    comune: "SCALETTA ZANCLEA",
    sigla: "ME",
    codice_elettorale: 4190480920,
    codice_istat: 83094,
    codice_belfiore: "I492",
  },
  {
    id: 6548,
    comune: "SCAMPITELLA",
    sigla: "AV",
    codice_elettorale: 3150080960,
    codice_istat: 64097,
    codice_belfiore: "I493",
  },
  {
    id: 6549,
    comune: "SCANDALE",
    sigla: "KR",
    codice_elettorale: 3180970240,
    codice_istat: 101024,
    codice_belfiore: "I494",
  },
  {
    id: 6550,
    comune: "SCANDIANO",
    sigla: "RE",
    codice_elettorale: 1080680400,
    codice_istat: 35040,
    codice_belfiore: "I496",
  },
  {
    id: 6551,
    comune: "SCANDICCI",
    sigla: "FI",
    codice_elettorale: 2090300410,
    codice_istat: 48041,
    codice_belfiore: "B962",
  },
  {
    id: 6552,
    comune: "SCANDOLARA RAVARA",
    sigla: "CR",
    codice_elettorale: 1030260890,
    codice_istat: 19092,
    codice_belfiore: "I497",
  },
  {
    id: 6553,
    comune: "SCANDOLARA RIPA D'OGLIO",
    sigla: "CR",
    codice_elettorale: 1030260900,
    codice_istat: 19093,
    codice_belfiore: "I498",
  },
  {
    id: 6554,
    comune: "SCANDRIGLIA",
    sigla: "RI",
    codice_elettorale: 2120690620,
    codice_istat: 57064,
    codice_belfiore: "I499",
  },
  {
    id: 6555,
    comune: "SCANNO",
    sigla: "AQ",
    codice_elettorale: 3130380920,
    codice_istat: 66093,
    codice_belfiore: "I501",
  },
  {
    id: 6556,
    comune: "SCANO DI MONTIFERRO",
    sigla: "OR",
    codice_elettorale: 4200950510,
    codice_istat: 95051,
    codice_belfiore: "I503",
  },
  {
    id: 6557,
    comune: "SCANSANO",
    sigla: "GR",
    codice_elettorale: 2090360220,
    codice_istat: 53023,
    codice_belfiore: "I504",
  },
  {
    id: 6558,
    comune: "SCANZANO JONICO",
    sigla: "MT",
    codice_elettorale: 3170470251,
    codice_istat: 77031,
    codice_belfiore: "M256",
  },
  {
    id: 6559,
    comune: "SCANZOROSCIATE",
    sigla: "BG",
    codice_elettorale: 1030121850,
    codice_istat: 16194,
    codice_belfiore: "I506",
  },
  {
    id: 6560,
    comune: "SCAPOLI",
    sigla: "IS",
    codice_elettorale: 3140940480,
    codice_istat: 94048,
    codice_belfiore: "I507",
  },
  {
    id: 6561,
    comune: "SCARLINO",
    sigla: "GR",
    codice_elettorale: 2090360221,
    codice_istat: 53024,
    codice_belfiore: "I510",
  },
  {
    id: 6562,
    comune: "SCARMAGNO",
    sigla: "TO",
    codice_elettorale: 1010812510,
    codice_istat: 1261,
    codice_belfiore: "I511",
  },
  {
    id: 6563,
    comune: "SCARNAFIGI",
    sigla: "CN",
    codice_elettorale: 1010272170,
    codice_istat: 4217,
    codice_belfiore: "I512",
  },
  {
    id: 6564,
    comune: "SCARPERIA E SAN PIERO",
    sigla: "FI",
    codice_elettorale: 2090300421,
    codice_istat: 48053,
    codice_belfiore: "M326",
  },
  {
    id: 6565,
    comune: "SCENA/SCHENNA",
    sigla: "BZ",
    codice_elettorale: 1040140800,
    codice_istat: 21087,
    codice_belfiore: "I519",
  },
  {
    id: 6566,
    comune: "SCERNI",
    sigla: "CH",
    codice_elettorale: 3130230870,
    codice_istat: 69087,
    codice_belfiore: "I520",
  },
  {
    id: 6567,
    comune: "SCHEGGIA E PASCELUPO",
    sigla: "PG",
    codice_elettorale: 2100580460,
    codice_istat: 54046,
    codice_belfiore: "I522",
  },
  {
    id: 6568,
    comune: "SCHEGGINO",
    sigla: "PG",
    codice_elettorale: 2100580470,
    codice_istat: 54047,
    codice_belfiore: "I523",
  },
  {
    id: 6569,
    comune: "SCHIAVI DI ABRUZZO",
    sigla: "CH",
    codice_elettorale: 3130230880,
    codice_istat: 69088,
    codice_belfiore: "I526",
  },
  {
    id: 6570,
    comune: "SCHIAVON",
    sigla: "VI",
    codice_elettorale: 1050900990,
    codice_istat: 24099,
    codice_belfiore: "I527",
  },
  {
    id: 6571,
    comune: "SCHIGNANO",
    sigla: "CO",
    codice_elettorale: 1030241980,
    codice_istat: 13211,
    codice_belfiore: "I529",
  },
  {
    id: 6572,
    comune: "SCHILPARIO",
    sigla: "BG",
    codice_elettorale: 1030121860,
    codice_istat: 16195,
    codice_belfiore: "I530",
  },
  {
    id: 6573,
    comune: "SCHIO",
    sigla: "VI",
    codice_elettorale: 1050901000,
    codice_istat: 24100,
    codice_belfiore: "I531",
  },
  {
    id: 6574,
    comune: "SCHIVENOGLIA",
    sigla: "MN",
    codice_elettorale: 1030450600,
    codice_istat: 20060,
    codice_belfiore: "I532",
  },
  {
    id: 6575,
    comune: "SCIACCA",
    sigla: "AG",
    codice_elettorale: 4190010400,
    codice_istat: 84041,
    codice_belfiore: "I533",
  },
  {
    id: 6576,
    comune: "SCIARA",
    sigla: "PA",
    codice_elettorale: 4190550660,
    codice_istat: 82068,
    codice_belfiore: "I534",
  },
  {
    id: 6577,
    comune: "SCICLI",
    sigla: "RG",
    codice_elettorale: 4190650110,
    codice_istat: 88011,
    codice_belfiore: "I535",
  },
  {
    id: 6578,
    comune: "SCIDO",
    sigla: "RC",
    codice_elettorale: 3180670840,
    codice_istat: 80084,
    codice_belfiore: "I536",
  },
  {
    id: 6579,
    comune: "SCIGLIANO",
    sigla: "CS",
    codice_elettorale: 3180251390,
    codice_istat: 78139,
    codice_belfiore: "D290",
  },
  {
    id: 6580,
    comune: "SCILLA",
    sigla: "RC",
    codice_elettorale: 3180670850,
    codice_istat: 80085,
    codice_belfiore: "I537",
  },
  {
    id: 6581,
    comune: "SCILLATO",
    sigla: "PA",
    codice_elettorale: 4190550661,
    codice_istat: 82081,
    codice_belfiore: "I538",
  },
  {
    id: 6582,
    comune: "SCIOLZE",
    sigla: "TO",
    codice_elettorale: 1010812520,
    codice_istat: 1262,
    codice_belfiore: "I539",
  },
  {
    id: 6583,
    comune: "SCISCIANO",
    sigla: "NA",
    codice_elettorale: 3150510770,
    codice_istat: 63077,
    codice_belfiore: "I540",
  },
  {
    id: 6584,
    comune: "SCLAFANI BAGNI",
    sigla: "PA",
    codice_elettorale: 4190550670,
    codice_istat: 82069,
    codice_belfiore: "I541",
  },
  {
    id: 6585,
    comune: "SCONTRONE",
    sigla: "AQ",
    codice_elettorale: 3130380930,
    codice_istat: 66094,
    codice_belfiore: "I543",
  },
  {
    id: 6586,
    comune: "SCOPA",
    sigla: "VC",
    codice_elettorale: 1010881330,
    codice_istat: 2134,
    codice_belfiore: "I544",
  },
  {
    id: 6587,
    comune: "SCOPELLO",
    sigla: "VC",
    codice_elettorale: 1010881340,
    codice_istat: 2135,
    codice_belfiore: "I545",
  },
  {
    id: 6588,
    comune: "SCOPPITO",
    sigla: "AQ",
    codice_elettorale: 3130380940,
    codice_istat: 66095,
    codice_belfiore: "I546",
  },
  {
    id: 6589,
    comune: "SCORDIA",
    sigla: "CT",
    codice_elettorale: 4190210480,
    codice_istat: 87049,
    codice_belfiore: "I548",
  },
  {
    id: 6590,
    comune: "SCORRANO",
    sigla: "LE",
    codice_elettorale: 3160410720,
    codice_istat: 75073,
    codice_belfiore: "I549",
  },
  {
    id: 6591,
    comune: "SCORZE'",
    sigla: "VE",
    codice_elettorale: 1050870370,
    codice_istat: 27037,
    codice_belfiore: "I551",
  },
  {
    id: 6592,
    comune: "SCURCOLA MARSICANA",
    sigla: "AQ",
    codice_elettorale: 3130380950,
    codice_istat: 66096,
    codice_belfiore: "I553",
  },
  {
    id: 6593,
    comune: "SCURELLE",
    sigla: "TN",
    codice_elettorale: 1040831600,
    codice_istat: 22171,
    codice_belfiore: "I554",
  },
  {
    id: 6594,
    comune: "SCURZOLENGO",
    sigla: "AT",
    codice_elettorale: 1010071030,
    codice_istat: 5103,
    codice_belfiore: "I555",
  },
  {
    id: 6595,
    comune: "SEBORGA",
    sigla: "IM",
    codice_elettorale: 1070370530,
    codice_istat: 8057,
    codice_belfiore: "I556",
  },
  {
    id: 6596,
    comune: "SECINARO",
    sigla: "AQ",
    codice_elettorale: 3130380960,
    codice_istat: 66097,
    codice_belfiore: "I558",
  },
  {
    id: 6597,
    comune: "SECLI'",
    sigla: "LE",
    codice_elettorale: 3160410730,
    codice_istat: 75074,
    codice_belfiore: "I559",
  },
  {
    id: 6598,
    comune: "SECUGNAGO",
    sigla: "LO",
    codice_elettorale: 1030990520,
    codice_istat: 98052,
    codice_belfiore: "I561",
  },
  {
    id: 6599,
    comune: "SEDEGLIANO",
    sigla: "UD",
    codice_elettorale: 1060851090,
    codice_istat: 30109,
    codice_belfiore: "I562",
  },
  {
    id: 6600,
    comune: "SEDICO",
    sigla: "BL",
    codice_elettorale: 1050100530,
    codice_istat: 25053,
    codice_belfiore: "I563",
  },
  {
    id: 6601,
    comune: "SEDILO",
    sigla: "OR",
    codice_elettorale: 4200950520,
    codice_istat: 95052,
    codice_belfiore: "I564",
  },
  {
    id: 6602,
    comune: "SEDINI",
    sigla: "SS",
    codice_elettorale: 4200730630,
    codice_istat: 90065,
    codice_belfiore: "I565",
  },
  {
    id: 6603,
    comune: "SEDRIANO",
    sigla: "MI",
    codice_elettorale: 1030492030,
    codice_istat: 15204,
    codice_belfiore: "I566",
  },
  {
    id: 6604,
    comune: "SEDRINA",
    sigla: "BG",
    codice_elettorale: 1030121870,
    codice_istat: 16196,
    codice_belfiore: "I567",
  },
  {
    id: 6605,
    comune: "SEFRO",
    sigla: "MC",
    codice_elettorale: 2110440500,
    codice_istat: 43050,
    codice_belfiore: "I569",
  },
  {
    id: 6606,
    comune: "SEGARIU",
    sigla: "SU",
    codice_elettorale: 4201110730,
    codice_istat: 111073,
    codice_belfiore: "I570",
  },
  {
    id: 6607,
    comune: "SEGGIANO",
    sigla: "GR",
    codice_elettorale: 2090360230,
    codice_istat: 53025,
    codice_belfiore: "I571",
  },
  {
    id: 6608,
    comune: "SEGNI",
    sigla: "RM",
    codice_elettorale: 2120701010,
    codice_istat: 58102,
    codice_belfiore: "I573",
  },
  {
    id: 6609,
    comune: "SEGONZANO",
    sigla: "TN",
    codice_elettorale: 1040831610,
    codice_istat: 22172,
    codice_belfiore: "I576",
  },
  {
    id: 6610,
    comune: "SEGRATE",
    sigla: "MI",
    codice_elettorale: 1030492040,
    codice_istat: 15205,
    codice_belfiore: "I577",
  },
  {
    id: 6611,
    comune: "SEGUSINO",
    sigla: "TV",
    codice_elettorale: 1050840780,
    codice_istat: 26079,
    codice_belfiore: "I578",
  },
  {
    id: 6612,
    comune: "SELARGIUS",
    sigla: "CA",
    codice_elettorale: 4200170680,
    codice_istat: 92068,
    codice_belfiore: "I580",
  },
  {
    id: 6613,
    comune: "SELCI",
    sigla: "RI",
    codice_elettorale: 2120690630,
    codice_istat: 57065,
    codice_belfiore: "I581",
  },
  {
    id: 6614,
    comune: "SELEGAS",
    sigla: "SU",
    codice_elettorale: 4201110740,
    codice_istat: 111074,
    codice_belfiore: "I582",
  },
  {
    id: 6615,
    comune: "SELLA GIUDICARIE",
    sigla: "TN",
    codice_elettorale: 1040831615,
    codice_istat: 22246,
    codice_belfiore: "M360",
  },
  {
    id: 6616,
    comune: "SELLANO",
    sigla: "PG",
    codice_elettorale: 2100580480,
    codice_istat: 54048,
    codice_belfiore: "I585",
  },
  {
    id: 6617,
    comune: "SELLERO",
    sigla: "BS",
    codice_elettorale: 1030151660,
    codice_istat: 17176,
    codice_belfiore: "I588",
  },
  {
    id: 6618,
    comune: "SELLIA",
    sigla: "CZ",
    codice_elettorale: 3180221230,
    codice_istat: 79126,
    codice_belfiore: "I589",
  },
  {
    id: 6619,
    comune: "SELLIA MARINA",
    sigla: "CZ",
    codice_elettorale: 3180221231,
    codice_istat: 79127,
    codice_belfiore: "I590",
  },
  {
    id: 6620,
    comune: "SELVA DEI MOLINI/MUHLWALD",
    sigla: "BZ",
    codice_elettorale: 1040140820,
    codice_istat: 21088,
    codice_belfiore: "I593",
  },
  {
    id: 6621,
    comune: "SELVA DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100540,
    codice_istat: 25054,
    codice_belfiore: "I592",
  },
  {
    id: 6622,
    comune: "SELVA DI PROGNO",
    sigla: "VR",
    codice_elettorale: 1050890790,
    codice_istat: 23080,
    codice_belfiore: "I594",
  },
  {
    id: 6623,
    comune: "SELVA DI VAL GARDENA/WOLKENSTEIN IN GRODEN",
    sigla: "BZ",
    codice_elettorale: 1040140821,
    codice_istat: 21089,
    codice_belfiore: "I591",
  },
  {
    id: 6624,
    comune: "SELVAZZANO DENTRO",
    sigla: "PD",
    codice_elettorale: 1050540860,
    codice_istat: 28086,
    codice_belfiore: "I595",
  },
  {
    id: 6625,
    comune: "SELVINO",
    sigla: "BG",
    codice_elettorale: 1030121880,
    codice_istat: 16197,
    codice_belfiore: "I597",
  },
  {
    id: 6626,
    comune: "SEMESTENE",
    sigla: "SS",
    codice_elettorale: 4200730640,
    codice_istat: 90066,
    codice_belfiore: "I598",
  },
  {
    id: 6627,
    comune: "SEMIANA",
    sigla: "PV",
    codice_elettorale: 1030571450,
    codice_istat: 18148,
    codice_belfiore: "I599",
  },
  {
    id: 6628,
    comune: "SEMINARA",
    sigla: "RC",
    codice_elettorale: 3180670860,
    codice_istat: 80086,
    codice_belfiore: "I600",
  },
  {
    id: 6629,
    comune: "SEMPRONIANO",
    sigla: "GR",
    codice_elettorale: 2090360231,
    codice_istat: 53028,
    codice_belfiore: "I601",
  },
  {
    id: 6630,
    comune: "SENAGO",
    sigla: "MI",
    codice_elettorale: 1030492050,
    codice_istat: 15206,
    codice_belfiore: "I602",
  },
  {
    id: 6631,
    comune: "SENALE-SAN FELICE/UNSERE LIEBE FRAU IM WALDE-ST. FELIX",
    sigla: "BZ",
    codice_elettorale: 1040140841,
    codice_istat: 21118,
    codice_belfiore: "I603",
  },
  {
    id: 6632,
    comune: "SENALES/SCHNALS",
    sigla: "BZ",
    codice_elettorale: 1040140840,
    codice_istat: 21091,
    codice_belfiore: "I604",
  },
  {
    id: 6633,
    comune: "SENEGHE",
    sigla: "OR",
    codice_elettorale: 4200950530,
    codice_istat: 95053,
    codice_belfiore: "I605",
  },
  {
    id: 6634,
    comune: "SENERCHIA",
    sigla: "AV",
    codice_elettorale: 3150080970,
    codice_istat: 64098,
    codice_belfiore: "I606",
  },
  {
    id: 6635,
    comune: "SENIGA",
    sigla: "BS",
    codice_elettorale: 1030151670,
    codice_istat: 17177,
    codice_belfiore: "I607",
  },
  {
    id: 6636,
    comune: "SENIGALLIA",
    sigla: "AN",
    codice_elettorale: 2110030450,
    codice_istat: 42045,
    codice_belfiore: "I608",
  },
  {
    id: 6637,
    comune: "SENIS",
    sigla: "OR",
    codice_elettorale: 4200950540,
    codice_istat: 95054,
    codice_belfiore: "I609",
  },
  {
    id: 6638,
    comune: "SENISE",
    sigla: "PZ",
    codice_elettorale: 3170640840,
    codice_istat: 76085,
    codice_belfiore: "I610",
  },
  {
    id: 6639,
    comune: "SENNA COMASCO",
    sigla: "CO",
    codice_elettorale: 1030241990,
    codice_istat: 13212,
    codice_belfiore: "I611",
  },
  {
    id: 6640,
    comune: "SENNA LODIGIANA",
    sigla: "LO",
    codice_elettorale: 1030990530,
    codice_istat: 98053,
    codice_belfiore: "I612",
  },
  {
    id: 6641,
    comune: "SENNARIOLO",
    sigla: "OR",
    codice_elettorale: 4200950550,
    codice_istat: 95055,
    codice_belfiore: "I613",
  },
  {
    id: 6642,
    comune: "SENNORI",
    sigla: "SS",
    codice_elettorale: 4200730650,
    codice_istat: 90067,
    codice_belfiore: "I614",
  },
  {
    id: 6643,
    comune: "SENORBI'",
    sigla: "SU",
    codice_elettorale: 4201110750,
    codice_istat: 111075,
    codice_belfiore: "I615",
  },
  {
    id: 6644,
    comune: "SEPINO",
    sigla: "CB",
    codice_elettorale: 3140190750,
    codice_istat: 70075,
    codice_belfiore: "I618",
  },
  {
    id: 6645,
    comune: "SEQUALS",
    sigla: "PN",
    codice_elettorale: 1060930420,
    codice_istat: 93042,
    codice_belfiore: "I621",
  },
  {
    id: 6646,
    comune: "SERAVEZZA",
    sigla: "LU",
    codice_elettorale: 2090430280,
    codice_istat: 46028,
    codice_belfiore: "I622",
  },
  {
    id: 6647,
    comune: "SERDIANA",
    sigla: "SU",
    codice_elettorale: 4201110760,
    codice_istat: 111076,
    codice_belfiore: "I624",
  },
  {
    id: 6648,
    comune: "SEREGNO",
    sigla: "MB",
    codice_elettorale: 1031040390,
    codice_istat: 108039,
    codice_belfiore: "I625",
  },
  {
    id: 6649,
    comune: "SEREN DEL GRAPPA",
    sigla: "BL",
    codice_elettorale: 1050100550,
    codice_istat: 25055,
    codice_belfiore: "I626",
  },
  {
    id: 6650,
    comune: "SERGNANO",
    sigla: "CR",
    codice_elettorale: 1030260910,
    codice_istat: 19094,
    codice_belfiore: "I627",
  },
  {
    id: 6651,
    comune: "SERIATE",
    sigla: "BG",
    codice_elettorale: 1030121890,
    codice_istat: 16198,
    codice_belfiore: "I628",
  },
  {
    id: 6652,
    comune: "SERINA",
    sigla: "BG",
    codice_elettorale: 1030121900,
    codice_istat: 16199,
    codice_belfiore: "I629",
  },
  {
    id: 6653,
    comune: "SERINO",
    sigla: "AV",
    codice_elettorale: 3150080980,
    codice_istat: 64099,
    codice_belfiore: "I630",
  },
  {
    id: 6654,
    comune: "SERLE",
    sigla: "BS",
    codice_elettorale: 1030151680,
    codice_istat: 17178,
    codice_belfiore: "I631",
  },
  {
    id: 6655,
    comune: "SERMIDE E FELONICA",
    sigla: "MN",
    codice_elettorale: 1030450610,
    codice_istat: 20061,
    codice_belfiore: "I632",
  },
  {
    id: 6656,
    comune: "SERMONETA",
    sigla: "LT",
    codice_elettorale: 2120400270,
    codice_istat: 59027,
    codice_belfiore: "I634",
  },
  {
    id: 6657,
    comune: "SERNAGLIA DELLA BATTAGLIA",
    sigla: "TV",
    codice_elettorale: 1050840790,
    codice_istat: 26080,
    codice_belfiore: "I635",
  },
  {
    id: 6658,
    comune: "SERNIO",
    sigla: "SO",
    codice_elettorale: 1030770590,
    codice_istat: 14059,
    codice_belfiore: "I636",
  },
  {
    id: 6659,
    comune: "SEROLE",
    sigla: "AT",
    codice_elettorale: 1010071040,
    codice_istat: 5104,
    codice_belfiore: "I637",
  },
  {
    id: 6660,
    comune: "SERRA D'AIELLO",
    sigla: "CS",
    codice_elettorale: 3180251400,
    codice_istat: 78140,
    codice_belfiore: "I642",
  },
  {
    id: 6661,
    comune: "SERRA DE' CONTI",
    sigla: "AN",
    codice_elettorale: 2110030460,
    codice_istat: 42046,
    codice_belfiore: "I643",
  },
  {
    id: 6662,
    comune: "SERRA RICCO'",
    sigla: "GE",
    codice_elettorale: 1070340580,
    codice_istat: 10058,
    codice_belfiore: "I640",
  },
  {
    id: 6663,
    comune: "SERRA SAN BRUNO",
    sigla: "VV",
    codice_elettorale: 3181030370,
    codice_istat: 102037,
    codice_belfiore: "I639",
  },
  {
    id: 6664,
    comune: "SERRA SAN QUIRICO",
    sigla: "AN",
    codice_elettorale: 2110030470,
    codice_istat: 42047,
    codice_belfiore: "I653",
  },
  {
    id: 6665,
    comune: "SERRA SANT'ABBONDIO",
    sigla: "PU",
    codice_elettorale: 2110590610,
    codice_istat: 41061,
    codice_belfiore: "I654",
  },
  {
    id: 6666,
    comune: "SERRACAPRIOLA",
    sigla: "FG",
    codice_elettorale: 3160310510,
    codice_istat: 71053,
    codice_belfiore: "I641",
  },
  {
    id: 6667,
    comune: "SERRADIFALCO",
    sigla: "CL",
    codice_elettorale: 4190180180,
    codice_istat: 85018,
    codice_belfiore: "I644",
  },
  {
    id: 6668,
    comune: "SERRALUNGA D'ALBA",
    sigla: "CN",
    codice_elettorale: 1010272180,
    codice_istat: 4218,
    codice_belfiore: "I646",
  },
  {
    id: 6669,
    comune: "SERRALUNGA DI CREA",
    sigla: "AL",
    codice_elettorale: 1010021560,
    codice_istat: 6159,
    codice_belfiore: "I645",
  },
  {
    id: 6670,
    comune: "SERRAMANNA",
    sigla: "SU",
    codice_elettorale: 4201110770,
    codice_istat: 111077,
    codice_belfiore: "I647",
  },
  {
    id: 6671,
    comune: "SERRAMAZZONI",
    sigla: "MO",
    codice_elettorale: 1080500410,
    codice_istat: 36042,
    codice_belfiore: "F357",
  },
  {
    id: 6672,
    comune: "SERRAMEZZANA",
    sigla: "SA",
    codice_elettorale: 3150721390,
    codice_istat: 65139,
    codice_belfiore: "I648",
  },
  {
    id: 6673,
    comune: "SERRAMONACESCA",
    sigla: "PE",
    codice_elettorale: 3130600400,
    codice_istat: 68040,
    codice_belfiore: "I649",
  },
  {
    id: 6674,
    comune: "SERRAPETRONA",
    sigla: "MC",
    codice_elettorale: 2110440510,
    codice_istat: 43051,
    codice_belfiore: "I651",
  },
  {
    id: 6675,
    comune: "SERRARA FONTANA",
    sigla: "NA",
    codice_elettorale: 3150510780,
    codice_istat: 63078,
    codice_belfiore: "I652",
  },
  {
    id: 6676,
    comune: "SERRASTRETTA",
    sigla: "CZ",
    codice_elettorale: 3180221250,
    codice_istat: 79129,
    codice_belfiore: "I655",
  },
  {
    id: 6677,
    comune: "SERRATA",
    sigla: "RC",
    codice_elettorale: 3180670870,
    codice_istat: 80087,
    codice_belfiore: "I656",
  },
  {
    id: 6678,
    comune: "SERRAVALLE A PO",
    sigla: "MN",
    codice_elettorale: 1030450620,
    codice_istat: 20062,
    codice_belfiore: "I662",
  },
  {
    id: 6679,
    comune: "SERRAVALLE DI CHIENTI",
    sigla: "MC",
    codice_elettorale: 2110440520,
    codice_istat: 43052,
    codice_belfiore: "I661",
  },
  {
    id: 6680,
    comune: "SERRAVALLE LANGHE",
    sigla: "CN",
    codice_elettorale: 1010272190,
    codice_istat: 4219,
    codice_belfiore: "I659",
  },
  {
    id: 6681,
    comune: "SERRAVALLE PISTOIESE",
    sigla: "PT",
    codice_elettorale: 2090630190,
    codice_istat: 47020,
    codice_belfiore: "I660",
  },
  {
    id: 6682,
    comune: "SERRAVALLE SCRIVIA",
    sigla: "AL",
    codice_elettorale: 1010021570,
    codice_istat: 6160,
    codice_belfiore: "I657",
  },
  {
    id: 6683,
    comune: "SERRAVALLE SESIA",
    sigla: "VC",
    codice_elettorale: 1010881360,
    codice_istat: 2137,
    codice_belfiore: "I663",
  },
  {
    id: 6684,
    comune: "SERRE",
    sigla: "SA",
    codice_elettorale: 3150721400,
    codice_istat: 65140,
    codice_belfiore: "I666",
  },
  {
    id: 6685,
    comune: "SERRENTI",
    sigla: "SU",
    codice_elettorale: 4201110780,
    codice_istat: 111078,
    codice_belfiore: "I667",
  },
  {
    id: 6686,
    comune: "SERRI",
    sigla: "SU",
    codice_elettorale: 4201110790,
    codice_istat: 111079,
    codice_belfiore: "I668",
  },
  {
    id: 6687,
    comune: "SERRONE",
    sigla: "FR",
    codice_elettorale: 2120330700,
    codice_istat: 60071,
    codice_belfiore: "I669",
  },
  {
    id: 6688,
    comune: "SERSALE",
    sigla: "CZ",
    codice_elettorale: 3180221260,
    codice_istat: 79130,
    codice_belfiore: "I671",
  },
  {
    id: 6689,
    comune: "SERVIGLIANO",
    sigla: "FM",
    codice_elettorale: 2111050380,
    codice_istat: 109038,
    codice_belfiore: "C070",
  },
  {
    id: 6690,
    comune: "SESSA AURUNCA",
    sigla: "CE",
    codice_elettorale: 3150200880,
    codice_istat: 61088,
    codice_belfiore: "I676",
  },
  {
    id: 6691,
    comune: "SESSA CILENTO",
    sigla: "SA",
    codice_elettorale: 3150721410,
    codice_istat: 65141,
    codice_belfiore: "I677",
  },
  {
    id: 6692,
    comune: "SESSAME",
    sigla: "AT",
    codice_elettorale: 1010071050,
    codice_istat: 5105,
    codice_belfiore: "I678",
  },
  {
    id: 6693,
    comune: "SESSANO DEL MOLISE",
    sigla: "IS",
    codice_elettorale: 3140940490,
    codice_istat: 94049,
    codice_belfiore: "I679",
  },
  {
    id: 6694,
    comune: "SESTA GODANO",
    sigla: "SP",
    codice_elettorale: 1070390280,
    codice_istat: 11028,
    codice_belfiore: "E070",
  },
  {
    id: 6695,
    comune: "SESTINO",
    sigla: "AR",
    codice_elettorale: 2090050350,
    codice_istat: 51035,
    codice_belfiore: "I681",
  },
  {
    id: 6696,
    comune: "SESTO AL REGHENA",
    sigla: "PN",
    codice_elettorale: 1060930430,
    codice_istat: 93043,
    codice_belfiore: "I686",
  },
  {
    id: 6697,
    comune: "SESTO CALENDE",
    sigla: "VA",
    codice_elettorale: 1030861020,
    codice_istat: 12120,
    codice_belfiore: "I688",
  },
  {
    id: 6698,
    comune: "SESTO CAMPANO",
    sigla: "IS",
    codice_elettorale: 3140940500,
    codice_istat: 94050,
    codice_belfiore: "I682",
  },
  {
    id: 6699,
    comune: "SESTO ED UNITI",
    sigla: "CR",
    codice_elettorale: 1030260920,
    codice_istat: 19095,
    codice_belfiore: "I683",
  },
  {
    id: 6700,
    comune: "SESTO FIORENTINO",
    sigla: "FI",
    codice_elettorale: 2090300430,
    codice_istat: 48043,
    codice_belfiore: "I684",
  },
  {
    id: 6701,
    comune: "SESTO SAN GIOVANNI",
    sigla: "MI",
    codice_elettorale: 1030492080,
    codice_istat: 15209,
    codice_belfiore: "I690",
  },
  {
    id: 6702,
    comune: "SESTO/SEXTEN",
    sigla: "BZ",
    codice_elettorale: 1040140850,
    codice_istat: 21092,
    codice_belfiore: "I687",
  },
  {
    id: 6703,
    comune: "SESTOLA",
    sigla: "MO",
    codice_elettorale: 1080500420,
    codice_istat: 36043,
    codice_belfiore: "I689",
  },
  {
    id: 6704,
    comune: "SESTRI LEVANTE",
    sigla: "GE",
    codice_elettorale: 1070340590,
    codice_istat: 10059,
    codice_belfiore: "I693",
  },
  {
    id: 6705,
    comune: "SESTRIERE",
    sigla: "TO",
    codice_elettorale: 1010812530,
    codice_istat: 1263,
    codice_belfiore: "I692",
  },
  {
    id: 6706,
    comune: "SESTU",
    sigla: "CA",
    codice_elettorale: 4200170740,
    codice_istat: 92074,
    codice_belfiore: "I695",
  },
  {
    id: 6707,
    comune: "SETTALA",
    sigla: "MI",
    codice_elettorale: 1030492090,
    codice_istat: 15210,
    codice_belfiore: "I696",
  },
  {
    id: 6708,
    comune: "SETTEFRATI",
    sigla: "FR",
    codice_elettorale: 2120330710,
    codice_istat: 60072,
    codice_belfiore: "I697",
  },
  {
    id: 6709,
    comune: "SETTIME",
    sigla: "AT",
    codice_elettorale: 1010071060,
    codice_istat: 5106,
    codice_belfiore: "I698",
  },
  {
    id: 6710,
    comune: "SETTIMO MILANESE",
    sigla: "MI",
    codice_elettorale: 1030492100,
    codice_istat: 15211,
    codice_belfiore: "I700",
  },
  {
    id: 6711,
    comune: "SETTIMO ROTTARO",
    sigla: "TO",
    codice_elettorale: 1010812540,
    codice_istat: 1264,
    codice_belfiore: "I701",
  },
  {
    id: 6712,
    comune: "SETTIMO SAN PIETRO",
    sigla: "CA",
    codice_elettorale: 4200170750,
    codice_istat: 92075,
    codice_belfiore: "I699",
  },
  {
    id: 6713,
    comune: "SETTIMO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010812550,
    codice_istat: 1265,
    codice_belfiore: "I703",
  },
  {
    id: 6714,
    comune: "SETTIMO VITTONE",
    sigla: "TO",
    codice_elettorale: 1010812560,
    codice_istat: 1266,
    codice_belfiore: "I702",
  },
  {
    id: 6715,
    comune: "SETTINGIANO",
    sigla: "CZ",
    codice_elettorale: 3180221270,
    codice_istat: 79131,
    codice_belfiore: "I704",
  },
  {
    id: 6716,
    comune: "SETZU",
    sigla: "SU",
    codice_elettorale: 4201110800,
    codice_istat: 111080,
    codice_belfiore: "I705",
  },
  {
    id: 6717,
    comune: "SEUI",
    sigla: "SU",
    codice_elettorale: 4201110810,
    codice_istat: 111081,
    codice_belfiore: "I706",
  },
  {
    id: 6718,
    comune: "SEULO",
    sigla: "SU",
    codice_elettorale: 4201110820,
    codice_istat: 111082,
    codice_belfiore: "I707",
  },
  {
    id: 6719,
    comune: "SEVESO",
    sigla: "MB",
    codice_elettorale: 1031040400,
    codice_istat: 108040,
    codice_belfiore: "I709",
  },
  {
    id: 6720,
    comune: "SEZZADIO",
    sigla: "AL",
    codice_elettorale: 1010021580,
    codice_istat: 6161,
    codice_belfiore: "I711",
  },
  {
    id: 6721,
    comune: "SEZZE",
    sigla: "LT",
    codice_elettorale: 2120400280,
    codice_istat: 59028,
    codice_belfiore: "I712",
  },
  {
    id: 6722,
    comune: "SFRUZ",
    sigla: "TN",
    codice_elettorale: 1040831620,
    codice_istat: 22173,
    codice_belfiore: "I714",
  },
  {
    id: 6723,
    comune: "SGONICO",
    sigla: "TS",
    codice_elettorale: 1060920050,
    codice_istat: 32005,
    codice_belfiore: "I715",
  },
  {
    id: 6724,
    comune: "SGURGOLA",
    sigla: "FR",
    codice_elettorale: 2120330720,
    codice_istat: 60073,
    codice_belfiore: "I716",
  },
  {
    id: 6725,
    comune: "SIAMAGGIORE",
    sigla: "OR",
    codice_elettorale: 4200950560,
    codice_istat: 95056,
    codice_belfiore: "I717",
  },
  {
    id: 6726,
    comune: "SIAMANNA",
    sigla: "OR",
    codice_elettorale: 4200950570,
    codice_istat: 95057,
    codice_belfiore: "I718",
  },
  {
    id: 6727,
    comune: "SIANO",
    sigla: "SA",
    codice_elettorale: 3150721420,
    codice_istat: 65142,
    codice_belfiore: "I720",
  },
  {
    id: 6728,
    comune: "SIAPICCIA",
    sigla: "OR",
    codice_elettorale: 4200950571,
    codice_istat: 95076,
    codice_belfiore: "I721",
  },
  {
    id: 6729,
    comune: "SICIGNANO DEGLI ALBURNI",
    sigla: "SA",
    codice_elettorale: 3150721430,
    codice_istat: 65143,
    codice_belfiore: "M253",
  },
  {
    id: 6730,
    comune: "SICULIANA",
    sigla: "AG",
    codice_elettorale: 4190010410,
    codice_istat: 84042,
    codice_belfiore: "I723",
  },
  {
    id: 6731,
    comune: "SIDDI",
    sigla: "SU",
    codice_elettorale: 4201110830,
    codice_istat: 111083,
    codice_belfiore: "I724",
  },
  {
    id: 6732,
    comune: "SIDERNO",
    sigla: "RC",
    codice_elettorale: 3180670880,
    codice_istat: 80088,
    codice_belfiore: "I725",
  },
  {
    id: 6733,
    comune: "SIENA",
    sigla: "SI",
    codice_elettorale: 2090750320,
    codice_istat: 52032,
    codice_belfiore: "I726",
  },
  {
    id: 6734,
    comune: "SIGILLO",
    sigla: "PG",
    codice_elettorale: 2100580490,
    codice_istat: 54049,
    codice_belfiore: "I727",
  },
  {
    id: 6735,
    comune: "SIGNA",
    sigla: "FI",
    codice_elettorale: 2090300440,
    codice_istat: 48044,
    codice_belfiore: "I728",
  },
  {
    id: 6736,
    comune: "SILANDRO/SCHLANDERS",
    sigla: "BZ",
    codice_elettorale: 1040140860,
    codice_istat: 21093,
    codice_belfiore: "I729",
  },
  {
    id: 6737,
    comune: "SILANUS",
    sigla: "NU",
    codice_elettorale: 4200530800,
    codice_istat: 91083,
    codice_belfiore: "I730",
  },
  {
    id: 6738,
    comune: "SILEA",
    sigla: "TV",
    codice_elettorale: 1050840800,
    codice_istat: 26081,
    codice_belfiore: "F116",
  },
  {
    id: 6739,
    comune: "SILIGO",
    sigla: "SS",
    codice_elettorale: 4200730660,
    codice_istat: 90068,
    codice_belfiore: "I732",
  },
  {
    id: 6740,
    comune: "SILIQUA",
    sigla: "SU",
    codice_elettorale: 4201110840,
    codice_istat: 111084,
    codice_belfiore: "I734",
  },
  {
    id: 6741,
    comune: "SILIUS",
    sigla: "SU",
    codice_elettorale: 4201110850,
    codice_istat: 111085,
    codice_belfiore: "I735",
  },
  {
    id: 6742,
    comune: "SILLANO GIUNCUGNANO",
    sigla: "LU",
    codice_elettorale: 2090430291,
    codice_istat: 46037,
    codice_belfiore: "M347",
  },
  {
    id: 6743,
    comune: "SILLAVENGO",
    sigla: "NO",
    codice_elettorale: 1010521310,
    codice_istat: 3138,
    codice_belfiore: "I736",
  },
  {
    id: 6744,
    comune: "SILVANO D'ORBA",
    sigla: "AL",
    codice_elettorale: 1010021590,
    codice_istat: 6162,
    codice_belfiore: "I738",
  },
  {
    id: 6745,
    comune: "SILVANO PIETRA",
    sigla: "PV",
    codice_elettorale: 1030571460,
    codice_istat: 18149,
    codice_belfiore: "I739",
  },
  {
    id: 6746,
    comune: "SILVI",
    sigla: "TE",
    codice_elettorale: 3130790390,
    codice_istat: 67040,
    codice_belfiore: "I741",
  },
  {
    id: 6747,
    comune: "SIMALA",
    sigla: "OR",
    codice_elettorale: 4200950580,
    codice_istat: 95058,
    codice_belfiore: "I742",
  },
  {
    id: 6748,
    comune: "SIMAXIS",
    sigla: "OR",
    codice_elettorale: 4200950590,
    codice_istat: 95059,
    codice_belfiore: "I743",
  },
  {
    id: 6749,
    comune: "SIMBARIO",
    sigla: "VV",
    codice_elettorale: 3181030380,
    codice_istat: 102038,
    codice_belfiore: "I744",
  },
  {
    id: 6750,
    comune: "SIMERI CRICHI",
    sigla: "CZ",
    codice_elettorale: 3180221290,
    codice_istat: 79133,
    codice_belfiore: "I745",
  },
  {
    id: 6751,
    comune: "SINAGRA",
    sigla: "ME",
    codice_elettorale: 4190480930,
    codice_istat: 83095,
    codice_belfiore: "I747",
  },
  {
    id: 6752,
    comune: "SINALUNGA",
    sigla: "SI",
    codice_elettorale: 2090750330,
    codice_istat: 52033,
    codice_belfiore: "A468",
  },
  {
    id: 6753,
    comune: "SINDIA",
    sigla: "NU",
    codice_elettorale: 4200530810,
    codice_istat: 91084,
    codice_belfiore: "I748",
  },
  {
    id: 6754,
    comune: "SINI",
    sigla: "OR",
    codice_elettorale: 4200950600,
    codice_istat: 95060,
    codice_belfiore: "I749",
  },
  {
    id: 6755,
    comune: "SINIO",
    sigla: "CN",
    codice_elettorale: 1010272200,
    codice_istat: 4220,
    codice_belfiore: "I750",
  },
  {
    id: 6756,
    comune: "SINISCOLA",
    sigla: "NU",
    codice_elettorale: 4200530820,
    codice_istat: 91085,
    codice_belfiore: "I751",
  },
  {
    id: 6757,
    comune: "SINNAI",
    sigla: "CA",
    codice_elettorale: 4200170800,
    codice_istat: 92080,
    codice_belfiore: "I752",
  },
  {
    id: 6758,
    comune: "SINOPOLI",
    sigla: "RC",
    codice_elettorale: 3180670890,
    codice_istat: 80089,
    codice_belfiore: "I753",
  },
  {
    id: 6759,
    comune: "SIRACUSA",
    sigla: "SR",
    codice_elettorale: 4190760170,
    codice_istat: 89017,
    codice_belfiore: "I754",
  },
  {
    id: 6760,
    comune: "SIRIGNANO",
    sigla: "AV",
    codice_elettorale: 3150080990,
    codice_istat: 64100,
    codice_belfiore: "I756",
  },
  {
    id: 6761,
    comune: "SIRIS",
    sigla: "OR",
    codice_elettorale: 4200950610,
    codice_istat: 95061,
    codice_belfiore: "I757",
  },
  {
    id: 6762,
    comune: "SIRMIONE",
    sigla: "BS",
    codice_elettorale: 1030151690,
    codice_istat: 17179,
    codice_belfiore: "I633",
  },
  {
    id: 6763,
    comune: "SIROLO",
    sigla: "AN",
    codice_elettorale: 2110030480,
    codice_istat: 42048,
    codice_belfiore: "I758",
  },
  {
    id: 6764,
    comune: "SIRONE",
    sigla: "LC",
    codice_elettorale: 1030980750,
    codice_istat: 97075,
    codice_belfiore: "I759",
  },
  {
    id: 6765,
    comune: "SIRTORI",
    sigla: "LC",
    codice_elettorale: 1030980760,
    codice_istat: 97076,
    codice_belfiore: "I761",
  },
  {
    id: 6766,
    comune: "SISSA TRECASALI",
    sigla: "PR",
    codice_elettorale: 1080560345,
    codice_istat: 34049,
    codice_belfiore: "M325",
  },
  {
    id: 6767,
    comune: "SIURGUS DONIGALA",
    sigla: "SU",
    codice_elettorale: 4201110860,
    codice_istat: 111086,
    codice_belfiore: "I765",
  },
  {
    id: 6768,
    comune: "SIZIANO",
    sigla: "PV",
    codice_elettorale: 1030571470,
    codice_istat: 18150,
    codice_belfiore: "E265",
  },
  {
    id: 6769,
    comune: "SIZZANO",
    sigla: "NO",
    codice_elettorale: 1010521320,
    codice_istat: 3139,
    codice_belfiore: "I767",
  },
  {
    id: 6770,
    comune: "SLUDERNO/SCHLUDERNS",
    sigla: "BZ",
    codice_elettorale: 1040140870,
    codice_istat: 21094,
    codice_belfiore: "I771",
  },
  {
    id: 6771,
    comune: "SMERILLO",
    sigla: "FM",
    codice_elettorale: 2111050390,
    codice_istat: 109039,
    codice_belfiore: "I774",
  },
  {
    id: 6772,
    comune: "SOAVE",
    sigla: "VR",
    codice_elettorale: 1050890800,
    codice_istat: 23081,
    codice_belfiore: "I775",
  },
  {
    id: 6773,
    comune: "SOCCHIEVE",
    sigla: "UD",
    codice_elettorale: 1060851100,
    codice_istat: 30110,
    codice_belfiore: "I777",
  },
  {
    id: 6774,
    comune: "SODDI'",
    sigla: "OR",
    codice_elettorale: 4200950611,
    codice_istat: 95078,
    codice_belfiore: "I778",
  },
  {
    id: 6775,
    comune: "SOGLIANO AL RUBICONE",
    sigla: "FC",
    codice_elettorale: 1080320450,
    codice_istat: 40046,
    codice_belfiore: "I779",
  },
  {
    id: 6776,
    comune: "SOGLIANO CAVOUR",
    sigla: "LE",
    codice_elettorale: 3160410740,
    codice_istat: 75075,
    codice_belfiore: "I780",
  },
  {
    id: 6777,
    comune: "SOGLIO",
    sigla: "AT",
    codice_elettorale: 1010071070,
    codice_istat: 5107,
    codice_belfiore: "I781",
  },
  {
    id: 6778,
    comune: "SOIANO DEL LAGO",
    sigla: "BS",
    codice_elettorale: 1030151700,
    codice_istat: 17180,
    codice_belfiore: "I782",
  },
  {
    id: 6779,
    comune: "SOLAGNA",
    sigla: "VI",
    codice_elettorale: 1050901010,
    codice_istat: 24101,
    codice_belfiore: "I783",
  },
  {
    id: 6780,
    comune: "SOLARINO",
    sigla: "SR",
    codice_elettorale: 4190760180,
    codice_istat: 89018,
    codice_belfiore: "I785",
  },
  {
    id: 6781,
    comune: "SOLARO",
    sigla: "MI",
    codice_elettorale: 1030492120,
    codice_istat: 15213,
    codice_belfiore: "I786",
  },
  {
    id: 6782,
    comune: "SOLAROLO",
    sigla: "RA",
    codice_elettorale: 1080660180,
    codice_istat: 39018,
    codice_belfiore: "I787",
  },
  {
    id: 6783,
    comune: "SOLAROLO RAINERIO",
    sigla: "CR",
    codice_elettorale: 1030260930,
    codice_istat: 19096,
    codice_belfiore: "I790",
  },
  {
    id: 6784,
    comune: "SOLARUSSA",
    sigla: "OR",
    codice_elettorale: 4200950620,
    codice_istat: 95062,
    codice_belfiore: "I791",
  },
  {
    id: 6785,
    comune: "SOLBIATE ARNO",
    sigla: "VA",
    codice_elettorale: 1030861030,
    codice_istat: 12121,
    codice_belfiore: "I793",
  },
  {
    id: 6786,
    comune: "SOLBIATE CON CAGNO",
    sigla: "CO",
    codice_elettorale: 1030242025,
    codice_istat: 13255,
    codice_belfiore: "M412",
  },
  {
    id: 6787,
    comune: "SOLBIATE OLONA",
    sigla: "VA",
    codice_elettorale: 1030861040,
    codice_istat: 12122,
    codice_belfiore: "I794",
  },
  {
    id: 6788,
    comune: "SOLDANO",
    sigla: "IM",
    codice_elettorale: 1070370540,
    codice_istat: 8058,
    codice_belfiore: "I796",
  },
  {
    id: 6789,
    comune: "SOLEMINIS",
    sigla: "SU",
    codice_elettorale: 4201110870,
    codice_istat: 111087,
    codice_belfiore: "I797",
  },
  {
    id: 6790,
    comune: "SOLERO",
    sigla: "AL",
    codice_elettorale: 1010021600,
    codice_istat: 6163,
    codice_belfiore: "I798",
  },
  {
    id: 6791,
    comune: "SOLESINO",
    sigla: "PD",
    codice_elettorale: 1050540870,
    codice_istat: 28087,
    codice_belfiore: "I799",
  },
  {
    id: 6792,
    comune: "SOLETO",
    sigla: "LE",
    codice_elettorale: 3160410750,
    codice_istat: 75076,
    codice_belfiore: "I800",
  },
  {
    id: 6793,
    comune: "SOLFERINO",
    sigla: "MN",
    codice_elettorale: 1030450630,
    codice_istat: 20063,
    codice_belfiore: "I801",
  },
  {
    id: 6794,
    comune: "SOLIERA",
    sigla: "MO",
    codice_elettorale: 1080500430,
    codice_istat: 36044,
    codice_belfiore: "I802",
  },
  {
    id: 6795,
    comune: "SOLIGNANO",
    sigla: "PR",
    codice_elettorale: 1080560350,
    codice_istat: 34035,
    codice_belfiore: "I803",
  },
  {
    id: 6796,
    comune: "SOLOFRA",
    sigla: "AV",
    codice_elettorale: 3150081000,
    codice_istat: 64101,
    codice_belfiore: "I805",
  },
  {
    id: 6797,
    comune: "SOLONGHELLO",
    sigla: "AL",
    codice_elettorale: 1010021610,
    codice_istat: 6164,
    codice_belfiore: "I808",
  },
  {
    id: 6798,
    comune: "SOLOPACA",
    sigla: "BN",
    codice_elettorale: 3150110710,
    codice_istat: 62073,
    codice_belfiore: "I809",
  },
  {
    id: 6799,
    comune: "SOLTO COLLINA",
    sigla: "BG",
    codice_elettorale: 1030121910,
    codice_istat: 16200,
    codice_belfiore: "I812",
  },
  {
    id: 6800,
    comune: "SOLZA",
    sigla: "BG",
    codice_elettorale: 1030121911,
    codice_istat: 16251,
    codice_belfiore: "I813",
  },
  {
    id: 6801,
    comune: "SOMAGLIA",
    sigla: "LO",
    codice_elettorale: 1030990540,
    codice_istat: 98054,
    codice_belfiore: "I815",
  },
  {
    id: 6802,
    comune: "SOMANO",
    sigla: "CN",
    codice_elettorale: 1010272210,
    codice_istat: 4221,
    codice_belfiore: "I817",
  },
  {
    id: 6803,
    comune: "SOMMA LOMBARDO",
    sigla: "VA",
    codice_elettorale: 1030861050,
    codice_istat: 12123,
    codice_belfiore: "I819",
  },
  {
    id: 6804,
    comune: "SOMMA VESUVIANA",
    sigla: "NA",
    codice_elettorale: 3150510790,
    codice_istat: 63079,
    codice_belfiore: "I820",
  },
  {
    id: 6805,
    comune: "SOMMACAMPAGNA",
    sigla: "VR",
    codice_elettorale: 1050890810,
    codice_istat: 23082,
    codice_belfiore: "I821",
  },
  {
    id: 6806,
    comune: "SOMMARIVA DEL BOSCO",
    sigla: "CN",
    codice_elettorale: 1010272220,
    codice_istat: 4222,
    codice_belfiore: "I822",
  },
  {
    id: 6807,
    comune: "SOMMARIVA PERNO",
    sigla: "CN",
    codice_elettorale: 1010272230,
    codice_istat: 4223,
    codice_belfiore: "I823",
  },
  {
    id: 6808,
    comune: "SOMMATINO",
    sigla: "CL",
    codice_elettorale: 4190180190,
    codice_istat: 85019,
    codice_belfiore: "I824",
  },
  {
    id: 6809,
    comune: "SOMMO",
    sigla: "PV",
    codice_elettorale: 1030571480,
    codice_istat: 18151,
    codice_belfiore: "I825",
  },
  {
    id: 6810,
    comune: "SONA",
    sigla: "VR",
    codice_elettorale: 1050890820,
    codice_istat: 23083,
    codice_belfiore: "I826",
  },
  {
    id: 6811,
    comune: "SONCINO",
    sigla: "CR",
    codice_elettorale: 1030260940,
    codice_istat: 19097,
    codice_belfiore: "I827",
  },
  {
    id: 6812,
    comune: "SONDALO",
    sigla: "SO",
    codice_elettorale: 1030770600,
    codice_istat: 14060,
    codice_belfiore: "I828",
  },
  {
    id: 6813,
    comune: "SONDRIO",
    sigla: "SO",
    codice_elettorale: 1030770610,
    codice_istat: 14061,
    codice_belfiore: "I829",
  },
  {
    id: 6814,
    comune: "SONGAVAZZO",
    sigla: "BG",
    codice_elettorale: 1030121920,
    codice_istat: 16201,
    codice_belfiore: "I830",
  },
  {
    id: 6815,
    comune: "SONICO",
    sigla: "BS",
    codice_elettorale: 1030151710,
    codice_istat: 17181,
    codice_belfiore: "I831",
  },
  {
    id: 6816,
    comune: "SONNINO",
    sigla: "LT",
    codice_elettorale: 2120400290,
    codice_istat: 59029,
    codice_belfiore: "I832",
  },
  {
    id: 6817,
    comune: "SORA",
    sigla: "FR",
    codice_elettorale: 2120330730,
    codice_istat: 60074,
    codice_belfiore: "I838",
  },
  {
    id: 6818,
    comune: "SORAGA DI FASSA",
    sigla: "TN",
    codice_elettorale: 1040831650,
    codice_istat: 22176,
    codice_belfiore: "I839",
  },
  {
    id: 6819,
    comune: "SORAGNA",
    sigla: "PR",
    codice_elettorale: 1080560360,
    codice_istat: 34036,
    codice_belfiore: "I840",
  },
  {
    id: 6820,
    comune: "SORANO",
    sigla: "GR",
    codice_elettorale: 2090360240,
    codice_istat: 53026,
    codice_belfiore: "I841",
  },
  {
    id: 6821,
    comune: "SORBO SAN BASILE",
    sigla: "CZ",
    codice_elettorale: 3180221300,
    codice_istat: 79134,
    codice_belfiore: "I844",
  },
  {
    id: 6822,
    comune: "SORBO SERPICO",
    sigla: "AV",
    codice_elettorale: 3150081010,
    codice_istat: 64102,
    codice_belfiore: "I843",
  },
  {
    id: 6823,
    comune: "SORBOLO MEZZANI",
    sigla: "PR",
    codice_elettorale: 1080560375,
    codice_istat: 34051,
    codice_belfiore: "M411",
  },
  {
    id: 6824,
    comune: "SORDEVOLO",
    sigla: "BI",
    codice_elettorale: 1010960630,
    codice_istat: 96063,
    codice_belfiore: "I847",
  },
  {
    id: 6825,
    comune: "SORDIO",
    sigla: "LO",
    codice_elettorale: 1030990550,
    codice_istat: 98055,
    codice_belfiore: "I848",
  },
  {
    id: 6826,
    comune: "SORESINA",
    sigla: "CR",
    codice_elettorale: 1030260950,
    codice_istat: 19098,
    codice_belfiore: "I849",
  },
  {
    id: 6827,
    comune: "SORGA'",
    sigla: "VR",
    codice_elettorale: 1050890830,
    codice_istat: 23084,
    codice_belfiore: "I850",
  },
  {
    id: 6828,
    comune: "SORGONO",
    sigla: "NU",
    codice_elettorale: 4200530830,
    codice_istat: 91086,
    codice_belfiore: "I851",
  },
  {
    id: 6829,
    comune: "SORI",
    sigla: "GE",
    codice_elettorale: 1070340600,
    codice_istat: 10060,
    codice_belfiore: "I852",
  },
  {
    id: 6830,
    comune: "SORIANELLO",
    sigla: "VV",
    codice_elettorale: 3181030390,
    codice_istat: 102039,
    codice_belfiore: "I853",
  },
  {
    id: 6831,
    comune: "SORIANO CALABRO",
    sigla: "VV",
    codice_elettorale: 3181030400,
    codice_istat: 102040,
    codice_belfiore: "I854",
  },
  {
    id: 6832,
    comune: "SORIANO NEL CIMINO",
    sigla: "VT",
    codice_elettorale: 2120910470,
    codice_istat: 56048,
    codice_belfiore: "I855",
  },
  {
    id: 6833,
    comune: "SORICO",
    sigla: "CO",
    codice_elettorale: 1030242030,
    codice_istat: 13216,
    codice_belfiore: "I856",
  },
  {
    id: 6834,
    comune: "SORISO",
    sigla: "NO",
    codice_elettorale: 1010521330,
    codice_istat: 3140,
    codice_belfiore: "I857",
  },
  {
    id: 6835,
    comune: "SORISOLE",
    sigla: "BG",
    codice_elettorale: 1030121930,
    codice_istat: 16202,
    codice_belfiore: "I858",
  },
  {
    id: 6836,
    comune: "SORMANO",
    sigla: "CO",
    codice_elettorale: 1030242040,
    codice_istat: 13217,
    codice_belfiore: "I860",
  },
  {
    id: 6837,
    comune: "SORRADILE",
    sigla: "OR",
    codice_elettorale: 4200950630,
    codice_istat: 95063,
    codice_belfiore: "I861",
  },
  {
    id: 6838,
    comune: "SORRENTO",
    sigla: "NA",
    codice_elettorale: 3150510800,
    codice_istat: 63080,
    codice_belfiore: "I862",
  },
  {
    id: 6839,
    comune: "SORSO",
    sigla: "SS",
    codice_elettorale: 4200730670,
    codice_istat: 90069,
    codice_belfiore: "I863",
  },
  {
    id: 6840,
    comune: "SORTINO",
    sigla: "SR",
    codice_elettorale: 4190760190,
    codice_istat: 89019,
    codice_belfiore: "I864",
  },
  {
    id: 6841,
    comune: "SOSPIRO",
    sigla: "CR",
    codice_elettorale: 1030260960,
    codice_istat: 19099,
    codice_belfiore: "I865",
  },
  {
    id: 6842,
    comune: "SOSPIROLO",
    sigla: "BL",
    codice_elettorale: 1050100560,
    codice_istat: 25056,
    codice_belfiore: "I866",
  },
  {
    id: 6843,
    comune: "SOSSANO",
    sigla: "VI",
    codice_elettorale: 1050901020,
    codice_istat: 24102,
    codice_belfiore: "I867",
  },
  {
    id: 6844,
    comune: "SOSTEGNO",
    sigla: "BI",
    codice_elettorale: 1010960640,
    codice_istat: 96064,
    codice_belfiore: "I868",
  },
  {
    id: 6845,
    comune: "SOTTO IL MONTE GIOVANNI XXIII",
    sigla: "BG",
    codice_elettorale: 1030121940,
    codice_istat: 16203,
    codice_belfiore: "I869",
  },
  {
    id: 6846,
    comune: "SOVER",
    sigla: "TN",
    codice_elettorale: 1040831660,
    codice_istat: 22177,
    codice_belfiore: "I871",
  },
  {
    id: 6847,
    comune: "SOVERATO",
    sigla: "CZ",
    codice_elettorale: 3180221330,
    codice_istat: 79137,
    codice_belfiore: "I872",
  },
  {
    id: 6848,
    comune: "SOVERE",
    sigla: "BG",
    codice_elettorale: 1030121950,
    codice_istat: 16204,
    codice_belfiore: "I873",
  },
  {
    id: 6849,
    comune: "SOVERIA MANNELLI",
    sigla: "CZ",
    codice_elettorale: 3180221340,
    codice_istat: 79138,
    codice_belfiore: "I874",
  },
  {
    id: 6850,
    comune: "SOVERIA SIMERI",
    sigla: "CZ",
    codice_elettorale: 3180221350,
    codice_istat: 79139,
    codice_belfiore: "I875",
  },
  {
    id: 6851,
    comune: "SOVERZENE",
    sigla: "BL",
    codice_elettorale: 1050100570,
    codice_istat: 25057,
    codice_belfiore: "I876",
  },
  {
    id: 6852,
    comune: "SOVICILLE",
    sigla: "SI",
    codice_elettorale: 2090750340,
    codice_istat: 52034,
    codice_belfiore: "I877",
  },
  {
    id: 6853,
    comune: "SOVICO",
    sigla: "MB",
    codice_elettorale: 1031040410,
    codice_istat: 108041,
    codice_belfiore: "I878",
  },
  {
    id: 6854,
    comune: "SOVIZZO",
    sigla: "VI",
    codice_elettorale: 1050901030,
    codice_istat: 24103,
    codice_belfiore: "I879",
  },
  {
    id: 6855,
    comune: "SOVRAMONTE",
    sigla: "BL",
    codice_elettorale: 1050100580,
    codice_istat: 25058,
    codice_belfiore: "I673",
  },
  {
    id: 6856,
    comune: "SOZZAGO",
    sigla: "NO",
    codice_elettorale: 1010521340,
    codice_istat: 3141,
    codice_belfiore: "I880",
  },
  {
    id: 6857,
    comune: "SPADAFORA",
    sigla: "ME",
    codice_elettorale: 4190480940,
    codice_istat: 83096,
    codice_belfiore: "I881",
  },
  {
    id: 6858,
    comune: "SPADOLA",
    sigla: "VV",
    codice_elettorale: 3181030410,
    codice_istat: 102041,
    codice_belfiore: "I884",
  },
  {
    id: 6859,
    comune: "SPARANISE",
    sigla: "CE",
    codice_elettorale: 3150200890,
    codice_istat: 61089,
    codice_belfiore: "I885",
  },
  {
    id: 6860,
    comune: "SPARONE",
    sigla: "TO",
    codice_elettorale: 1010812570,
    codice_istat: 1267,
    codice_belfiore: "I886",
  },
  {
    id: 6861,
    comune: "SPECCHIA",
    sigla: "LE",
    codice_elettorale: 3160410760,
    codice_istat: 75077,
    codice_belfiore: "I887",
  },
  {
    id: 6862,
    comune: "SPELLO",
    sigla: "PG",
    codice_elettorale: 2100580500,
    codice_istat: 54050,
    codice_belfiore: "I888",
  },
  {
    id: 6863,
    comune: "SPERLINGA",
    sigla: "EN",
    codice_elettorale: 4190280170,
    codice_istat: 86017,
    codice_belfiore: "I891",
  },
  {
    id: 6864,
    comune: "SPERLONGA",
    sigla: "LT",
    codice_elettorale: 2120400300,
    codice_istat: 59030,
    codice_belfiore: "I892",
  },
  {
    id: 6865,
    comune: "SPERONE",
    sigla: "AV",
    codice_elettorale: 3150081020,
    codice_istat: 64103,
    codice_belfiore: "I893",
  },
  {
    id: 6866,
    comune: "SPESSA",
    sigla: "PV",
    codice_elettorale: 1030571490,
    codice_istat: 18152,
    codice_belfiore: "I894",
  },
  {
    id: 6867,
    comune: "SPEZZANO ALBANESE",
    sigla: "CS",
    codice_elettorale: 3180251420,
    codice_istat: 78142,
    codice_belfiore: "I895",
  },
  {
    id: 6868,
    comune: "SPEZZANO DELLA SILA",
    sigla: "CS",
    codice_elettorale: 3180251430,
    codice_istat: 78143,
    codice_belfiore: "I896",
  },
  {
    id: 6869,
    comune: "SPIAZZO",
    sigla: "TN",
    codice_elettorale: 1040831680,
    codice_istat: 22179,
    codice_belfiore: "I899",
  },
  {
    id: 6870,
    comune: "SPIGNO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021620,
    codice_istat: 6165,
    codice_belfiore: "I901",
  },
  {
    id: 6871,
    comune: "SPIGNO SATURNIA",
    sigla: "LT",
    codice_elettorale: 2120400310,
    codice_istat: 59031,
    codice_belfiore: "I902",
  },
  {
    id: 6872,
    comune: "SPILAMBERTO",
    sigla: "MO",
    codice_elettorale: 1080500440,
    codice_istat: 36045,
    codice_belfiore: "I903",
  },
  {
    id: 6873,
    comune: "SPILIMBERGO",
    sigla: "PN",
    codice_elettorale: 1060930440,
    codice_istat: 93044,
    codice_belfiore: "I904",
  },
  {
    id: 6874,
    comune: "SPILINGA",
    sigla: "VV",
    codice_elettorale: 3181030420,
    codice_istat: 102042,
    codice_belfiore: "I905",
  },
  {
    id: 6875,
    comune: "SPINADESCO",
    sigla: "CR",
    codice_elettorale: 1030260970,
    codice_istat: 19100,
    codice_belfiore: "I906",
  },
  {
    id: 6876,
    comune: "SPINAZZOLA",
    sigla: "BT",
    codice_elettorale: 3161060080,
    codice_istat: 110008,
    codice_belfiore: "I907",
  },
  {
    id: 6877,
    comune: "SPINEA",
    sigla: "VE",
    codice_elettorale: 1050870380,
    codice_istat: 27038,
    codice_belfiore: "I908",
  },
  {
    id: 6878,
    comune: "SPINEDA",
    sigla: "CR",
    codice_elettorale: 1030260980,
    codice_istat: 19101,
    codice_belfiore: "I909",
  },
  {
    id: 6879,
    comune: "SPINETE",
    sigla: "CB",
    codice_elettorale: 3140190760,
    codice_istat: 70076,
    codice_belfiore: "I910",
  },
  {
    id: 6880,
    comune: "SPINETO SCRIVIA",
    sigla: "AL",
    codice_elettorale: 1010021630,
    codice_istat: 6166,
    codice_belfiore: "I911",
  },
  {
    id: 6881,
    comune: "SPINETOLI",
    sigla: "AP",
    codice_elettorale: 2110060700,
    codice_istat: 44071,
    codice_belfiore: "I912",
  },
  {
    id: 6882,
    comune: "SPINO D'ADDA",
    sigla: "CR",
    codice_elettorale: 1030260990,
    codice_istat: 19102,
    codice_belfiore: "I914",
  },
  {
    id: 6883,
    comune: "SPINONE AL LAGO",
    sigla: "BG",
    codice_elettorale: 1030121960,
    codice_istat: 16205,
    codice_belfiore: "I916",
  },
  {
    id: 6884,
    comune: "SPINOSO",
    sigla: "PZ",
    codice_elettorale: 3170640850,
    codice_istat: 76086,
    codice_belfiore: "I917",
  },
  {
    id: 6885,
    comune: "SPIRANO",
    sigla: "BG",
    codice_elettorale: 1030121970,
    codice_istat: 16206,
    codice_belfiore: "I919",
  },
  {
    id: 6886,
    comune: "SPOLETO",
    sigla: "PG",
    codice_elettorale: 2100580510,
    codice_istat: 54051,
    codice_belfiore: "I921",
  },
  {
    id: 6887,
    comune: "SPOLTORE",
    sigla: "PE",
    codice_elettorale: 3130600410,
    codice_istat: 68041,
    codice_belfiore: "I922",
  },
  {
    id: 6888,
    comune: "SPONGANO",
    sigla: "LE",
    codice_elettorale: 3160410770,
    codice_istat: 75078,
    codice_belfiore: "I923",
  },
  {
    id: 6889,
    comune: "SPORMAGGIORE",
    sigla: "TN",
    codice_elettorale: 1040831690,
    codice_istat: 22180,
    codice_belfiore: "I924",
  },
  {
    id: 6890,
    comune: "SPORMINORE",
    sigla: "TN",
    codice_elettorale: 1040831700,
    codice_istat: 22181,
    codice_belfiore: "I925",
  },
  {
    id: 6891,
    comune: "SPOTORNO",
    sigla: "SV",
    codice_elettorale: 1070740570,
    codice_istat: 9057,
    codice_belfiore: "I926",
  },
  {
    id: 6892,
    comune: "SPRESIANO",
    sigla: "TV",
    codice_elettorale: 1050840810,
    codice_istat: 26082,
    codice_belfiore: "I927",
  },
  {
    id: 6893,
    comune: "SPRIANA",
    sigla: "SO",
    codice_elettorale: 1030770620,
    codice_istat: 14062,
    codice_belfiore: "I928",
  },
  {
    id: 6894,
    comune: "SQUILLACE",
    sigla: "CZ",
    codice_elettorale: 3180221380,
    codice_istat: 79142,
    codice_belfiore: "I929",
  },
  {
    id: 6895,
    comune: "SQUINZANO",
    sigla: "LE",
    codice_elettorale: 3160410780,
    codice_istat: 75079,
    codice_belfiore: "I930",
  },
  {
    id: 6896,
    comune: "STAFFOLO",
    sigla: "AN",
    codice_elettorale: 2110030490,
    codice_istat: 42049,
    codice_belfiore: "I932",
  },
  {
    id: 6897,
    comune: "STAGNO LOMBARDO",
    sigla: "CR",
    codice_elettorale: 1030261000,
    codice_istat: 19103,
    codice_belfiore: "I935",
  },
  {
    id: 6898,
    comune: "STAITI",
    sigla: "RC",
    codice_elettorale: 3180670900,
    codice_istat: 80090,
    codice_belfiore: "I936",
  },
  {
    id: 6899,
    comune: "STALETTI'",
    sigla: "CZ",
    codice_elettorale: 3180221390,
    codice_istat: 79143,
    codice_belfiore: "I937",
  },
  {
    id: 6900,
    comune: "STANGHELLA",
    sigla: "PD",
    codice_elettorale: 1050540880,
    codice_istat: 28088,
    codice_belfiore: "I938",
  },
  {
    id: 6901,
    comune: "STARANZANO",
    sigla: "GO",
    codice_elettorale: 1060350190,
    codice_istat: 31023,
    codice_belfiore: "I939",
  },
  {
    id: 6902,
    comune: "STATTE",
    sigla: "TA",
    codice_elettorale: 3160780261,
    codice_istat: 73029,
    codice_belfiore: "M298",
  },
  {
    id: 6903,
    comune: "STAZZANO",
    sigla: "AL",
    codice_elettorale: 1010021640,
    codice_istat: 6167,
    codice_belfiore: "I941",
  },
  {
    id: 6904,
    comune: "STAZZEMA",
    sigla: "LU",
    codice_elettorale: 2090430300,
    codice_istat: 46030,
    codice_belfiore: "I942",
  },
  {
    id: 6905,
    comune: "STAZZONA",
    sigla: "CO",
    codice_elettorale: 1030242050,
    codice_istat: 13218,
    codice_belfiore: "I943",
  },
  {
    id: 6906,
    comune: "STEFANACONI",
    sigla: "VV",
    codice_elettorale: 3181030430,
    codice_istat: 102043,
    codice_belfiore: "I945",
  },
  {
    id: 6907,
    comune: "STELLA",
    sigla: "SV",
    codice_elettorale: 1070740580,
    codice_istat: 9058,
    codice_belfiore: "I946",
  },
  {
    id: 6908,
    comune: "STELLA CILENTO",
    sigla: "SA",
    codice_elettorale: 3150721440,
    codice_istat: 65144,
    codice_belfiore: "G887",
  },
  {
    id: 6909,
    comune: "STELLANELLO",
    sigla: "SV",
    codice_elettorale: 1070740590,
    codice_istat: 9059,
    codice_belfiore: "I947",
  },
  {
    id: 6910,
    comune: "STELVIO/STILFS",
    sigla: "BZ",
    codice_elettorale: 1040140871,
    codice_istat: 21095,
    codice_belfiore: "I948",
  },
  {
    id: 6911,
    comune: "STENICO",
    sigla: "TN",
    codice_elettorale: 1040831710,
    codice_istat: 22182,
    codice_belfiore: "I949",
  },
  {
    id: 6912,
    comune: "STERNATIA",
    sigla: "LE",
    codice_elettorale: 3160410790,
    codice_istat: 75080,
    codice_belfiore: "I950",
  },
  {
    id: 6913,
    comune: "STEZZANO",
    sigla: "BG",
    codice_elettorale: 1030121980,
    codice_istat: 16207,
    codice_belfiore: "I951",
  },
  {
    id: 6914,
    comune: "STIENTA",
    sigla: "RO",
    codice_elettorale: 1050710450,
    codice_istat: 29045,
    codice_belfiore: "I953",
  },
  {
    id: 6915,
    comune: "STIGLIANO",
    sigla: "MT",
    codice_elettorale: 3170470260,
    codice_istat: 77027,
    codice_belfiore: "I954",
  },
  {
    id: 6916,
    comune: "STIGNANO",
    sigla: "RC",
    codice_elettorale: 3180670910,
    codice_istat: 80091,
    codice_belfiore: "I955",
  },
  {
    id: 6917,
    comune: "STILO",
    sigla: "RC",
    codice_elettorale: 3180670920,
    codice_istat: 80092,
    codice_belfiore: "I956",
  },
  {
    id: 6918,
    comune: "STIMIGLIANO",
    sigla: "RI",
    codice_elettorale: 2120690640,
    codice_istat: 57066,
    codice_belfiore: "I959",
  },
  {
    id: 6919,
    comune: "STINTINO",
    sigla: "SS",
    codice_elettorale: 4200730672,
    codice_istat: 90089,
    codice_belfiore: "M290",
  },
  {
    id: 6920,
    comune: "STIO",
    sigla: "SA",
    codice_elettorale: 3150721450,
    codice_istat: 65145,
    codice_belfiore: "I960",
  },
  {
    id: 6921,
    comune: "STORNARA",
    sigla: "FG",
    codice_elettorale: 3160310520,
    codice_istat: 71054,
    codice_belfiore: "I962",
  },
  {
    id: 6922,
    comune: "STORNARELLA",
    sigla: "FG",
    codice_elettorale: 3160310530,
    codice_istat: 71055,
    codice_belfiore: "I963",
  },
  {
    id: 6923,
    comune: "STORO",
    sigla: "TN",
    codice_elettorale: 1040831720,
    codice_istat: 22183,
    codice_belfiore: "I964",
  },
  {
    id: 6924,
    comune: "STRA",
    sigla: "VE",
    codice_elettorale: 1050870390,
    codice_istat: 27039,
    codice_belfiore: "I965",
  },
  {
    id: 6925,
    comune: "STRADELLA",
    sigla: "PV",
    codice_elettorale: 1030571500,
    codice_istat: 18153,
    codice_belfiore: "I968",
  },
  {
    id: 6926,
    comune: "STRAMBINELLO",
    sigla: "TO",
    codice_elettorale: 1010812580,
    codice_istat: 1268,
    codice_belfiore: "I969",
  },
  {
    id: 6927,
    comune: "STRAMBINO",
    sigla: "TO",
    codice_elettorale: 1010812590,
    codice_istat: 1269,
    codice_belfiore: "I970",
  },
  {
    id: 6928,
    comune: "STRANGOLAGALLI",
    sigla: "FR",
    codice_elettorale: 2120330740,
    codice_istat: 60075,
    codice_belfiore: "I973",
  },
  {
    id: 6929,
    comune: "STREGNA",
    sigla: "UD",
    codice_elettorale: 1060851110,
    codice_istat: 30111,
    codice_belfiore: "I974",
  },
  {
    id: 6930,
    comune: "STREMBO",
    sigla: "TN",
    codice_elettorale: 1040831730,
    codice_istat: 22184,
    codice_belfiore: "I975",
  },
  {
    id: 6931,
    comune: "STRESA",
    sigla: "VB",
    codice_elettorale: 1011020640,
    codice_istat: 103064,
    codice_belfiore: "I976",
  },
  {
    id: 6932,
    comune: "STREVI",
    sigla: "AL",
    codice_elettorale: 1010021650,
    codice_istat: 6168,
    codice_belfiore: "I977",
  },
  {
    id: 6933,
    comune: "STRIANO",
    sigla: "NA",
    codice_elettorale: 3150510810,
    codice_istat: 63081,
    codice_belfiore: "I978",
  },
  {
    id: 6934,
    comune: "STRONA",
    sigla: "BI",
    codice_elettorale: 1010960650,
    codice_istat: 96065,
    codice_belfiore: "I980",
  },
  {
    id: 6935,
    comune: "STRONCONE",
    sigla: "TR",
    codice_elettorale: 2100800310,
    codice_istat: 55031,
    codice_belfiore: "I981",
  },
  {
    id: 6936,
    comune: "STRONGOLI",
    sigla: "KR",
    codice_elettorale: 3180970250,
    codice_istat: 101025,
    codice_belfiore: "I982",
  },
  {
    id: 6937,
    comune: "STROPPIANA",
    sigla: "VC",
    codice_elettorale: 1010881410,
    codice_istat: 2142,
    codice_belfiore: "I984",
  },
  {
    id: 6938,
    comune: "STROPPO",
    sigla: "CN",
    codice_elettorale: 1010272240,
    codice_istat: 4224,
    codice_belfiore: "I985",
  },
  {
    id: 6939,
    comune: "STROZZA",
    sigla: "BG",
    codice_elettorale: 1030121990,
    codice_istat: 16208,
    codice_belfiore: "I986",
  },
  {
    id: 6940,
    comune: "STURNO",
    sigla: "AV",
    codice_elettorale: 3150081030,
    codice_istat: 64104,
    codice_belfiore: "I990",
  },
  {
    id: 6941,
    comune: "SUARDI",
    sigla: "PV",
    codice_elettorale: 1030571510,
    codice_istat: 18154,
    codice_belfiore: "B014",
  },
  {
    id: 6942,
    comune: "SUBBIANO",
    sigla: "AR",
    codice_elettorale: 2090050370,
    codice_istat: 51037,
    codice_belfiore: "I991",
  },
  {
    id: 6943,
    comune: "SUBIACO",
    sigla: "RM",
    codice_elettorale: 2120701020,
    codice_istat: 58103,
    codice_belfiore: "I992",
  },
  {
    id: 6944,
    comune: "SUCCIVO",
    sigla: "CE",
    codice_elettorale: 3150200900,
    codice_istat: 61090,
    codice_belfiore: "I993",
  },
  {
    id: 6945,
    comune: "SUEGLIO",
    sigla: "LC",
    codice_elettorale: 1030980770,
    codice_istat: 97077,
    codice_belfiore: "I994",
  },
  {
    id: 6946,
    comune: "SUELLI",
    sigla: "SU",
    codice_elettorale: 4201110880,
    codice_istat: 111088,
    codice_belfiore: "I995",
  },
  {
    id: 6947,
    comune: "SUELLO",
    sigla: "LC",
    codice_elettorale: 1030980780,
    codice_istat: 97078,
    codice_belfiore: "I996",
  },
  {
    id: 6948,
    comune: "SUISIO",
    sigla: "BG",
    codice_elettorale: 1030122000,
    codice_istat: 16209,
    codice_belfiore: "I997",
  },
  {
    id: 6949,
    comune: "SULBIATE",
    sigla: "MB",
    codice_elettorale: 1031040420,
    codice_istat: 108042,
    codice_belfiore: "I998",
  },
  {
    id: 6950,
    comune: "SULMONA",
    sigla: "AQ",
    codice_elettorale: 3130380970,
    codice_istat: 66098,
    codice_belfiore: "I804",
  },
  {
    id: 6951,
    comune: "SULZANO",
    sigla: "BS",
    codice_elettorale: 1030151720,
    codice_istat: 17182,
    codice_belfiore: "L002",
  },
  {
    id: 6952,
    comune: "SUMIRAGO",
    sigla: "VA",
    codice_elettorale: 1030861060,
    codice_istat: 12124,
    codice_belfiore: "L003",
  },
  {
    id: 6953,
    comune: "SUMMONTE",
    sigla: "AV",
    codice_elettorale: 3150081040,
    codice_istat: 64105,
    codice_belfiore: "L004",
  },
  {
    id: 6954,
    comune: "SUNI",
    sigla: "OR",
    codice_elettorale: 4200950636,
    codice_istat: 95087,
    codice_belfiore: "L006",
  },
  {
    id: 6955,
    comune: "SUNO",
    sigla: "NO",
    codice_elettorale: 1010521360,
    codice_istat: 3143,
    codice_belfiore: "L007",
  },
  {
    id: 6956,
    comune: "SUPERSANO",
    sigla: "LE",
    codice_elettorale: 3160410800,
    codice_istat: 75081,
    codice_belfiore: "L008",
  },
  {
    id: 6957,
    comune: "SUPINO",
    sigla: "FR",
    codice_elettorale: 2120330750,
    codice_istat: 60076,
    codice_belfiore: "L009",
  },
  {
    id: 6958,
    comune: "SURANO",
    sigla: "LE",
    codice_elettorale: 3160410810,
    codice_istat: 75082,
    codice_belfiore: "L010",
  },
  {
    id: 6959,
    comune: "SURBO",
    sigla: "LE",
    codice_elettorale: 3160410820,
    codice_istat: 75083,
    codice_belfiore: "L011",
  },
  {
    id: 6960,
    comune: "SUSA",
    sigla: "TO",
    codice_elettorale: 1010812600,
    codice_istat: 1270,
    codice_belfiore: "L013",
  },
  {
    id: 6961,
    comune: "SUSEGANA",
    sigla: "TV",
    codice_elettorale: 1050840820,
    codice_istat: 26083,
    codice_belfiore: "L014",
  },
  {
    id: 6962,
    comune: "SUSTINENTE",
    sigla: "MN",
    codice_elettorale: 1030450640,
    codice_istat: 20064,
    codice_belfiore: "L015",
  },
  {
    id: 6963,
    comune: "SUTERA",
    sigla: "CL",
    codice_elettorale: 4190180200,
    codice_istat: 85020,
    codice_belfiore: "L016",
  },
  {
    id: 6964,
    comune: "SUTRI",
    sigla: "VT",
    codice_elettorale: 2120910480,
    codice_istat: 56049,
    codice_belfiore: "L017",
  },
  {
    id: 6965,
    comune: "SUTRIO",
    sigla: "UD",
    codice_elettorale: 1060851120,
    codice_istat: 30112,
    codice_belfiore: "L018",
  },
  {
    id: 6966,
    comune: "SUVERETO",
    sigla: "LI",
    codice_elettorale: 2090420200,
    codice_istat: 49020,
    codice_belfiore: "L019",
  },
  {
    id: 6967,
    comune: "SUZZARA",
    sigla: "MN",
    codice_elettorale: 1030450650,
    codice_istat: 20065,
    codice_belfiore: "L020",
  },
  {
    id: 6968,
    comune: "TACENO",
    sigla: "LC",
    codice_elettorale: 1030980790,
    codice_istat: 97079,
    codice_belfiore: "L022",
  },
  {
    id: 6969,
    comune: "TADASUNI",
    sigla: "OR",
    codice_elettorale: 4200950640,
    codice_istat: 95064,
    codice_belfiore: "L023",
  },
  {
    id: 6970,
    comune: "TAGGIA",
    sigla: "IM",
    codice_elettorale: 1070370550,
    codice_istat: 8059,
    codice_belfiore: "L024",
  },
  {
    id: 6971,
    comune: "TAGLIACOZZO",
    sigla: "AQ",
    codice_elettorale: 3130380980,
    codice_istat: 66099,
    codice_belfiore: "L025",
  },
  {
    id: 6972,
    comune: "TAGLIO DI PO",
    sigla: "RO",
    codice_elettorale: 1050710460,
    codice_istat: 29046,
    codice_belfiore: "L026",
  },
  {
    id: 6973,
    comune: "TAGLIOLO MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021660,
    codice_istat: 6169,
    codice_belfiore: "L027",
  },
  {
    id: 6974,
    comune: "TAIBON AGORDINO",
    sigla: "BL",
    codice_elettorale: 1050100590,
    codice_istat: 25059,
    codice_belfiore: "L030",
  },
  {
    id: 6975,
    comune: "TAINO",
    sigla: "VA",
    codice_elettorale: 1030861070,
    codice_istat: 12125,
    codice_belfiore: "L032",
  },
  {
    id: 6976,
    comune: "TAIPANA",
    sigla: "UD",
    codice_elettorale: 1060851130,
    codice_istat: 30113,
    codice_belfiore: "G736",
  },
  {
    id: 6977,
    comune: "TALAMELLO",
    sigla: "RN",
    codice_elettorale: 1081010185,
    codice_istat: 99027,
    codice_belfiore: "L034",
  },
  {
    id: 6978,
    comune: "TALAMONA",
    sigla: "SO",
    codice_elettorale: 1030770630,
    codice_istat: 14063,
    codice_belfiore: "L035",
  },
  {
    id: 6979,
    comune: "TALANA",
    sigla: "NU",
    codice_elettorale: 4200530850,
    codice_istat: 91088,
    codice_belfiore: "L036",
  },
  {
    id: 6980,
    comune: "TALEGGIO",
    sigla: "BG",
    codice_elettorale: 1030122010,
    codice_istat: 16210,
    codice_belfiore: "L037",
  },
  {
    id: 6981,
    comune: "TALLA",
    sigla: "AR",
    codice_elettorale: 2090050380,
    codice_istat: 51038,
    codice_belfiore: "L038",
  },
  {
    id: 6982,
    comune: "TALMASSONS",
    sigla: "UD",
    codice_elettorale: 1060851140,
    codice_istat: 30114,
    codice_belfiore: "L039",
  },
  {
    id: 6983,
    comune: "TAMBRE",
    sigla: "BL",
    codice_elettorale: 1050100600,
    codice_istat: 25060,
    codice_belfiore: "L040",
  },
  {
    id: 6984,
    comune: "TAORMINA",
    sigla: "ME",
    codice_elettorale: 4190480950,
    codice_istat: 83097,
    codice_belfiore: "L042",
  },
  {
    id: 6985,
    comune: "TARANO",
    sigla: "RI",
    codice_elettorale: 2120690650,
    codice_istat: 57067,
    codice_belfiore: "L046",
  },
  {
    id: 6986,
    comune: "TARANTA PELIGNA",
    sigla: "CH",
    codice_elettorale: 3130230890,
    codice_istat: 69089,
    codice_belfiore: "L047",
  },
  {
    id: 6987,
    comune: "TARANTASCA",
    sigla: "CN",
    codice_elettorale: 1010272250,
    codice_istat: 4225,
    codice_belfiore: "L048",
  },
  {
    id: 6988,
    comune: "TARANTO",
    sigla: "TA",
    codice_elettorale: 3160780270,
    codice_istat: 73027,
    codice_belfiore: "L049",
  },
  {
    id: 6989,
    comune: "TARCENTO",
    sigla: "UD",
    codice_elettorale: 1060851160,
    codice_istat: 30116,
    codice_belfiore: "L050",
  },
  {
    id: 6990,
    comune: "TARQUINIA",
    sigla: "VT",
    codice_elettorale: 2120910490,
    codice_istat: 56050,
    codice_belfiore: "D024",
  },
  {
    id: 6991,
    comune: "TARSIA",
    sigla: "CS",
    codice_elettorale: 3180251450,
    codice_istat: 78145,
    codice_belfiore: "L055",
  },
  {
    id: 6992,
    comune: "TARTANO",
    sigla: "SO",
    codice_elettorale: 1030770640,
    codice_istat: 14064,
    codice_belfiore: "L056",
  },
  {
    id: 6993,
    comune: "TARVISIO",
    sigla: "UD",
    codice_elettorale: 1060851170,
    codice_istat: 30117,
    codice_belfiore: "L057",
  },
  {
    id: 6994,
    comune: "TARZO",
    sigla: "TV",
    codice_elettorale: 1050840830,
    codice_istat: 26084,
    codice_belfiore: "L058",
  },
  {
    id: 6995,
    comune: "TASSAROLO",
    sigla: "AL",
    codice_elettorale: 1010021670,
    codice_istat: 6170,
    codice_belfiore: "L059",
  },
  {
    id: 6996,
    comune: "TAURANO",
    sigla: "AV",
    codice_elettorale: 3150081050,
    codice_istat: 64106,
    codice_belfiore: "L061",
  },
  {
    id: 6997,
    comune: "TAURASI",
    sigla: "AV",
    codice_elettorale: 3150081060,
    codice_istat: 64107,
    codice_belfiore: "L062",
  },
  {
    id: 6998,
    comune: "TAURIANOVA",
    sigla: "RC",
    codice_elettorale: 3180670930,
    codice_istat: 80093,
    codice_belfiore: "L063",
  },
  {
    id: 6999,
    comune: "TAURISANO",
    sigla: "LE",
    codice_elettorale: 3160410830,
    codice_istat: 75084,
    codice_belfiore: "L064",
  },
  {
    id: 7000,
    comune: "TAVAGNACCO",
    sigla: "UD",
    codice_elettorale: 1060851180,
    codice_istat: 30118,
    codice_belfiore: "L065",
  },
  {
    id: 7001,
    comune: "TAVAGNASCO",
    sigla: "TO",
    codice_elettorale: 1010812610,
    codice_istat: 1271,
    codice_belfiore: "L066",
  },
  {
    id: 7002,
    comune: "TAVAZZANO CON VILLAVESCO",
    sigla: "LO",
    codice_elettorale: 1030990560,
    codice_istat: 98056,
    codice_belfiore: "F260",
  },
  {
    id: 7003,
    comune: "TAVENNA",
    sigla: "CB",
    codice_elettorale: 3140190770,
    codice_istat: 70077,
    codice_belfiore: "L069",
  },
  {
    id: 7004,
    comune: "TAVERNA",
    sigla: "CZ",
    codice_elettorale: 3180221420,
    codice_istat: 79146,
    codice_belfiore: "L070",
  },
  {
    id: 7005,
    comune: "TAVERNERIO",
    sigla: "CO",
    codice_elettorale: 1030242080,
    codice_istat: 13222,
    codice_belfiore: "L071",
  },
  {
    id: 7006,
    comune: "TAVERNOLA BERGAMASCA",
    sigla: "BG",
    codice_elettorale: 1030122020,
    codice_istat: 16211,
    codice_belfiore: "L073",
  },
  {
    id: 7007,
    comune: "TAVERNOLE SUL MELLA",
    sigla: "BS",
    codice_elettorale: 1030151730,
    codice_istat: 17183,
    codice_belfiore: "C698",
  },
  {
    id: 7008,
    comune: "TAVIANO",
    sigla: "LE",
    codice_elettorale: 3160410840,
    codice_istat: 75085,
    codice_belfiore: "L074",
  },
  {
    id: 7009,
    comune: "TAVIGLIANO",
    sigla: "BI",
    codice_elettorale: 1010960660,
    codice_istat: 96066,
    codice_belfiore: "L075",
  },
  {
    id: 7010,
    comune: "TAVOLETO",
    sigla: "PU",
    codice_elettorale: 2110590640,
    codice_istat: 41064,
    codice_belfiore: "L078",
  },
  {
    id: 7011,
    comune: "TAVULLIA",
    sigla: "PU",
    codice_elettorale: 2110590650,
    codice_istat: 41065,
    codice_belfiore: "L081",
  },
  {
    id: 7012,
    comune: "TEANA",
    sigla: "PZ",
    codice_elettorale: 3170640860,
    codice_istat: 76087,
    codice_belfiore: "L082",
  },
  {
    id: 7013,
    comune: "TEANO",
    sigla: "CE",
    codice_elettorale: 3150200910,
    codice_istat: 61091,
    codice_belfiore: "L083",
  },
  {
    id: 7014,
    comune: "TEGGIANO",
    sigla: "SA",
    codice_elettorale: 3150721460,
    codice_istat: 65146,
    codice_belfiore: "D292",
  },
  {
    id: 7015,
    comune: "TEGLIO",
    sigla: "SO",
    codice_elettorale: 1030770650,
    codice_istat: 14065,
    codice_belfiore: "L084",
  },
  {
    id: 7016,
    comune: "TEGLIO VENETO",
    sigla: "VE",
    codice_elettorale: 1050870400,
    codice_istat: 27040,
    codice_belfiore: "L085",
  },
  {
    id: 7017,
    comune: "TELESE TERME",
    sigla: "BN",
    codice_elettorale: 3150110720,
    codice_istat: 62074,
    codice_belfiore: "L086",
  },
  {
    id: 7018,
    comune: "TELGATE",
    sigla: "BG",
    codice_elettorale: 1030122030,
    codice_istat: 16212,
    codice_belfiore: "L087",
  },
  {
    id: 7019,
    comune: "TELTI",
    sigla: "SS",
    codice_elettorale: 4200730671,
    codice_istat: 90080,
    codice_belfiore: "L088",
  },
  {
    id: 7020,
    comune: "TELVE",
    sigla: "TN",
    codice_elettorale: 1040831770,
    codice_istat: 22188,
    codice_belfiore: "L089",
  },
  {
    id: 7021,
    comune: "TELVE DI SOPRA",
    sigla: "TN",
    codice_elettorale: 1040831780,
    codice_istat: 22189,
    codice_belfiore: "L090",
  },
  {
    id: 7022,
    comune: "TEMPIO PAUSANIA",
    sigla: "SS",
    codice_elettorale: 4200730680,
    codice_istat: 90070,
    codice_belfiore: "L093",
  },
  {
    id: 7023,
    comune: "TEMU'",
    sigla: "BS",
    codice_elettorale: 1030151740,
    codice_istat: 17184,
    codice_belfiore: "L094",
  },
  {
    id: 7024,
    comune: "TENNA",
    sigla: "TN",
    codice_elettorale: 1040831790,
    codice_istat: 22190,
    codice_belfiore: "L096",
  },
  {
    id: 7025,
    comune: "TENNO",
    sigla: "TN",
    codice_elettorale: 1040831800,
    codice_istat: 22191,
    codice_belfiore: "L097",
  },
  {
    id: 7026,
    comune: "TEOLO",
    sigla: "PD",
    codice_elettorale: 1050540890,
    codice_istat: 28089,
    codice_belfiore: "L100",
  },
  {
    id: 7027,
    comune: "TEORA",
    sigla: "AV",
    codice_elettorale: 3150081070,
    codice_istat: 64108,
    codice_belfiore: "L102",
  },
  {
    id: 7028,
    comune: "TERAMO",
    sigla: "TE",
    codice_elettorale: 3130790400,
    codice_istat: 67041,
    codice_belfiore: "L103",
  },
  {
    id: 7029,
    comune: "TERDOBBIATE",
    sigla: "NO",
    codice_elettorale: 1010521370,
    codice_istat: 3144,
    codice_belfiore: "L104",
  },
  {
    id: 7030,
    comune: "TERELLE",
    sigla: "FR",
    codice_elettorale: 2120330760,
    codice_istat: 60077,
    codice_belfiore: "L105",
  },
  {
    id: 7031,
    comune: "TERENTO/TERENTEN",
    sigla: "BZ",
    codice_elettorale: 1040140880,
    codice_istat: 21096,
    codice_belfiore: "L106",
  },
  {
    id: 7032,
    comune: "TERENZO",
    sigla: "PR",
    codice_elettorale: 1080560380,
    codice_istat: 34038,
    codice_belfiore: "E548",
  },
  {
    id: 7033,
    comune: "TERGU",
    sigla: "SS",
    codice_elettorale: 4200730681,
    codice_istat: 90086,
    codice_belfiore: "M282",
  },
  {
    id: 7034,
    comune: "TERLANO/TERLAN",
    sigla: "BZ",
    codice_elettorale: 1040140890,
    codice_istat: 21097,
    codice_belfiore: "L108",
  },
  {
    id: 7035,
    comune: "TERLIZZI",
    sigla: "BA",
    codice_elettorale: 3160090420,
    codice_istat: 72043,
    codice_belfiore: "L109",
  },
  {
    id: 7036,
    comune: "TERME VIGLIATORE",
    sigla: "ME",
    codice_elettorale: 4190480951,
    codice_istat: 83106,
    codice_belfiore: "M210",
  },
  {
    id: 7037,
    comune: "TERMENO SULLA STRADA DEL VINO/TRAMIN AN DER WEINSTRASSE",
    sigla: "BZ",
    codice_elettorale: 1040140900,
    codice_istat: 21098,
    codice_belfiore: "L111",
  },
  {
    id: 7038,
    comune: "TERMINI IMERESE",
    sigla: "PA",
    codice_elettorale: 4190550680,
    codice_istat: 82070,
    codice_belfiore: "L112",
  },
  {
    id: 7039,
    comune: "TERMOLI",
    sigla: "CB",
    codice_elettorale: 3140190780,
    codice_istat: 70078,
    codice_belfiore: "L113",
  },
  {
    id: 7040,
    comune: "TERNATE",
    sigla: "VA",
    codice_elettorale: 1030861080,
    codice_istat: 12126,
    codice_belfiore: "L115",
  },
  {
    id: 7041,
    comune: "TERNENGO",
    sigla: "BI",
    codice_elettorale: 1010960670,
    codice_istat: 96067,
    codice_belfiore: "L116",
  },
  {
    id: 7042,
    comune: "TERNI",
    sigla: "TR",
    codice_elettorale: 2100800320,
    codice_istat: 55032,
    codice_belfiore: "L117",
  },
  {
    id: 7043,
    comune: "TERNO D'ISOLA",
    sigla: "BG",
    codice_elettorale: 1030122040,
    codice_istat: 16213,
    codice_belfiore: "L118",
  },
  {
    id: 7044,
    comune: "TERRACINA",
    sigla: "LT",
    codice_elettorale: 2120400320,
    codice_istat: 59032,
    codice_belfiore: "L120",
  },
  {
    id: 7045,
    comune: "TERRAGNOLO",
    sigla: "TN",
    codice_elettorale: 1040831820,
    codice_istat: 22193,
    codice_belfiore: "L121",
  },
  {
    id: 7046,
    comune: "TERRALBA",
    sigla: "OR",
    codice_elettorale: 4200950650,
    codice_istat: 95065,
    codice_belfiore: "L122",
  },
  {
    id: 7047,
    comune: "TERRANOVA DA SIBARI",
    sigla: "CS",
    codice_elettorale: 3180251460,
    codice_istat: 78146,
    codice_belfiore: "L124",
  },
  {
    id: 7048,
    comune: "TERRANOVA DEI PASSERINI",
    sigla: "LO",
    codice_elettorale: 1030990570,
    codice_istat: 98057,
    codice_belfiore: "L125",
  },
  {
    id: 7049,
    comune: "TERRANOVA DI POLLINO",
    sigla: "PZ",
    codice_elettorale: 3170640870,
    codice_istat: 76088,
    codice_belfiore: "L126",
  },
  {
    id: 7050,
    comune: "TERRANOVA SAPPO MINULIO",
    sigla: "RC",
    codice_elettorale: 3180670940,
    codice_istat: 80094,
    codice_belfiore: "L127",
  },
  {
    id: 7051,
    comune: "TERRANUOVA BRACCIOLINI",
    sigla: "AR",
    codice_elettorale: 2090050390,
    codice_istat: 51039,
    codice_belfiore: "L123",
  },
  {
    id: 7052,
    comune: "TERRASINI",
    sigla: "PA",
    codice_elettorale: 4190550690,
    codice_istat: 82071,
    codice_belfiore: "L131",
  },
  {
    id: 7053,
    comune: "TERRASSA PADOVANA",
    sigla: "PD",
    codice_elettorale: 1050540900,
    codice_istat: 28090,
    codice_belfiore: "L132",
  },
  {
    id: 7054,
    comune: "TERRAVECCHIA",
    sigla: "CS",
    codice_elettorale: 3180251470,
    codice_istat: 78147,
    codice_belfiore: "L134",
  },
  {
    id: 7055,
    comune: "TERRAZZO",
    sigla: "VR",
    codice_elettorale: 1050890840,
    codice_istat: 23085,
    codice_belfiore: "L136",
  },
  {
    id: 7056,
    comune: "TERRE D'ADIGE",
    sigla: "TN",
    codice_elettorale: 1040831825,
    codice_istat: 22251,
    codice_belfiore: "M407",
  },
  {
    id: 7057,
    comune: "TERRE DEL RENO",
    sigla: "FE",
    codice_elettorale: 1080290185,
    codice_istat: 38028,
    codice_belfiore: "M381",
  },
  {
    id: 7058,
    comune: "TERRE ROVERESCHE",
    sigla: "PU",
    codice_elettorale: 2110590655,
    codice_istat: 41070,
    codice_belfiore: "M379",
  },
  {
    id: 7059,
    comune: "TERRICCIOLA",
    sigla: "PI",
    codice_elettorale: 2090620350,
    codice_istat: 50036,
    codice_belfiore: "L138",
  },
  {
    id: 7060,
    comune: "TERRUGGIA",
    sigla: "AL",
    codice_elettorale: 1010021680,
    codice_istat: 6171,
    codice_belfiore: "L139",
  },
  {
    id: 7061,
    comune: "TERTENIA",
    sigla: "NU",
    codice_elettorale: 4200530860,
    codice_istat: 91089,
    codice_belfiore: "L140",
  },
  {
    id: 7062,
    comune: "TERZIGNO",
    sigla: "NA",
    codice_elettorale: 3150510820,
    codice_istat: 63082,
    codice_belfiore: "L142",
  },
  {
    id: 7063,
    comune: "TERZO",
    sigla: "AL",
    codice_elettorale: 1010021690,
    codice_istat: 6172,
    codice_belfiore: "L143",
  },
  {
    id: 7064,
    comune: "TERZO D'AQUILEIA",
    sigla: "UD",
    codice_elettorale: 1060851200,
    codice_istat: 30120,
    codice_belfiore: "L144",
  },
  {
    id: 7065,
    comune: "TERZOLAS",
    sigla: "TN",
    codice_elettorale: 1040831840,
    codice_istat: 22195,
    codice_belfiore: "L145",
  },
  {
    id: 7066,
    comune: "TERZORIO",
    sigla: "IM",
    codice_elettorale: 1070370560,
    codice_istat: 8060,
    codice_belfiore: "L146",
  },
  {
    id: 7067,
    comune: "TESERO",
    sigla: "TN",
    codice_elettorale: 1040831850,
    codice_istat: 22196,
    codice_belfiore: "L147",
  },
  {
    id: 7068,
    comune: "TESIMO/TISENS",
    sigla: "BZ",
    codice_elettorale: 1040140910,
    codice_istat: 21099,
    codice_belfiore: "L149",
  },
  {
    id: 7069,
    comune: "TESSENNANO",
    sigla: "VT",
    codice_elettorale: 2120910500,
    codice_istat: 56051,
    codice_belfiore: "L150",
  },
  {
    id: 7070,
    comune: "TESTICO",
    sigla: "SV",
    codice_elettorale: 1070740600,
    codice_istat: 9060,
    codice_belfiore: "L152",
  },
  {
    id: 7071,
    comune: "TETI",
    sigla: "NU",
    codice_elettorale: 4200530870,
    codice_istat: 91090,
    codice_belfiore: "L153",
  },
  {
    id: 7072,
    comune: "TEULADA",
    sigla: "SU",
    codice_elettorale: 4201110890,
    codice_istat: 111089,
    codice_belfiore: "L154",
  },
  {
    id: 7073,
    comune: "TEVEROLA",
    sigla: "CE",
    codice_elettorale: 3150200920,
    codice_istat: 61092,
    codice_belfiore: "L155",
  },
  {
    id: 7074,
    comune: "TEZZE SUL BRENTA",
    sigla: "VI",
    codice_elettorale: 1050901040,
    codice_istat: 24104,
    codice_belfiore: "L156",
  },
  {
    id: 7075,
    comune: "THIENE",
    sigla: "VI",
    codice_elettorale: 1050901050,
    codice_istat: 24105,
    codice_belfiore: "L157",
  },
  {
    id: 7076,
    comune: "THIESI",
    sigla: "SS",
    codice_elettorale: 4200730690,
    codice_istat: 90071,
    codice_belfiore: "L158",
  },
  {
    id: 7077,
    comune: "TIANA",
    sigla: "NU",
    codice_elettorale: 4200530880,
    codice_istat: 91091,
    codice_belfiore: "L160",
  },
  {
    id: 7078,
    comune: "TICENGO",
    sigla: "CR",
    codice_elettorale: 1030261010,
    codice_istat: 19104,
    codice_belfiore: "L164",
  },
  {
    id: 7079,
    comune: "TICINETO",
    sigla: "AL",
    codice_elettorale: 1010021700,
    codice_istat: 6173,
    codice_belfiore: "L165",
  },
  {
    id: 7080,
    comune: "TIGGIANO",
    sigla: "LE",
    codice_elettorale: 3160410850,
    codice_istat: 75086,
    codice_belfiore: "L166",
  },
  {
    id: 7081,
    comune: "TIGLIETO",
    sigla: "GE",
    codice_elettorale: 1070340610,
    codice_istat: 10061,
    codice_belfiore: "L167",
  },
  {
    id: 7082,
    comune: "TIGLIOLE",
    sigla: "AT",
    codice_elettorale: 1010071080,
    codice_istat: 5108,
    codice_belfiore: "L168",
  },
  {
    id: 7083,
    comune: "TIGNALE",
    sigla: "BS",
    codice_elettorale: 1030151750,
    codice_istat: 17185,
    codice_belfiore: "L169",
  },
  {
    id: 7084,
    comune: "TINNURA",
    sigla: "OR",
    codice_elettorale: 4200950656,
    codice_istat: 95088,
    codice_belfiore: "L172",
  },
  {
    id: 7085,
    comune: "TIONE DEGLI ABRUZZI",
    sigla: "AQ",
    codice_elettorale: 3130380990,
    codice_istat: 66100,
    codice_belfiore: "L173",
  },
  {
    id: 7086,
    comune: "TIONE DI TRENTO",
    sigla: "TN",
    codice_elettorale: 1040831880,
    codice_istat: 22199,
    codice_belfiore: "L174",
  },
  {
    id: 7087,
    comune: "TIRANO",
    sigla: "SO",
    codice_elettorale: 1030770660,
    codice_istat: 14066,
    codice_belfiore: "L175",
  },
  {
    id: 7088,
    comune: "TIRES/TIERS",
    sigla: "BZ",
    codice_elettorale: 1040140920,
    codice_istat: 21100,
    codice_belfiore: "L176",
  },
  {
    id: 7089,
    comune: "TIRIOLO",
    sigla: "CZ",
    codice_elettorale: 3180221430,
    codice_istat: 79147,
    codice_belfiore: "L177",
  },
  {
    id: 7090,
    comune: "TIROLO/TIROL",
    sigla: "BZ",
    codice_elettorale: 1040140930,
    codice_istat: 21101,
    codice_belfiore: "L178",
  },
  {
    id: 7091,
    comune: "TISSI",
    sigla: "SS",
    codice_elettorale: 4200730700,
    codice_istat: 90072,
    codice_belfiore: "L180",
  },
  {
    id: 7092,
    comune: "TITO",
    sigla: "PZ",
    codice_elettorale: 3170640880,
    codice_istat: 76089,
    codice_belfiore: "L181",
  },
  {
    id: 7093,
    comune: "TIVOLI",
    sigla: "RM",
    codice_elettorale: 2120701030,
    codice_istat: 58104,
    codice_belfiore: "L182",
  },
  {
    id: 7094,
    comune: "TIZZANO VAL PARMA",
    sigla: "PR",
    codice_elettorale: 1080560390,
    codice_istat: 34039,
    codice_belfiore: "L183",
  },
  {
    id: 7095,
    comune: "TOANO",
    sigla: "RE",
    codice_elettorale: 1080680410,
    codice_istat: 35041,
    codice_belfiore: "L184",
  },
  {
    id: 7096,
    comune: "TOCCO CAUDIO",
    sigla: "BN",
    codice_elettorale: 3150110730,
    codice_istat: 62075,
    codice_belfiore: "L185",
  },
  {
    id: 7097,
    comune: "TOCCO DA CASAURIA",
    sigla: "PE",
    codice_elettorale: 3130600420,
    codice_istat: 68042,
    codice_belfiore: "L186",
  },
  {
    id: 7098,
    comune: "TOCENO",
    sigla: "VB",
    codice_elettorale: 1011020650,
    codice_istat: 103065,
    codice_belfiore: "L187",
  },
  {
    id: 7099,
    comune: "TODI",
    sigla: "PG",
    codice_elettorale: 2100580520,
    codice_istat: 54052,
    codice_belfiore: "L188",
  },
  {
    id: 7100,
    comune: "TOFFIA",
    sigla: "RI",
    codice_elettorale: 2120690660,
    codice_istat: 57068,
    codice_belfiore: "L189",
  },
  {
    id: 7101,
    comune: "TOIRANO",
    sigla: "SV",
    codice_elettorale: 1070740610,
    codice_istat: 9061,
    codice_belfiore: "L190",
  },
  {
    id: 7102,
    comune: "TOLENTINO",
    sigla: "MC",
    codice_elettorale: 2110440530,
    codice_istat: 43053,
    codice_belfiore: "L191",
  },
  {
    id: 7103,
    comune: "TOLFA",
    sigla: "RM",
    codice_elettorale: 2120701040,
    codice_istat: 58105,
    codice_belfiore: "L192",
  },
  {
    id: 7104,
    comune: "TOLLEGNO",
    sigla: "BI",
    codice_elettorale: 1010960680,
    codice_istat: 96068,
    codice_belfiore: "L193",
  },
  {
    id: 7105,
    comune: "TOLLO",
    sigla: "CH",
    codice_elettorale: 3130230900,
    codice_istat: 69090,
    codice_belfiore: "L194",
  },
  {
    id: 7106,
    comune: "TOLMEZZO",
    sigla: "UD",
    codice_elettorale: 1060851210,
    codice_istat: 30121,
    codice_belfiore: "L195",
  },
  {
    id: 7107,
    comune: "TOLVE",
    sigla: "PZ",
    codice_elettorale: 3170640890,
    codice_istat: 76090,
    codice_belfiore: "L197",
  },
  {
    id: 7108,
    comune: "TOMBOLO",
    sigla: "PD",
    codice_elettorale: 1050540910,
    codice_istat: 28091,
    codice_belfiore: "L199",
  },
  {
    id: 7109,
    comune: "TON",
    sigla: "TN",
    codice_elettorale: 1040831890,
    codice_istat: 22200,
    codice_belfiore: "L200",
  },
  {
    id: 7110,
    comune: "TONARA",
    sigla: "NU",
    codice_elettorale: 4200530900,
    codice_istat: 91093,
    codice_belfiore: "L202",
  },
  {
    id: 7111,
    comune: "TONCO",
    sigla: "AT",
    codice_elettorale: 1010071090,
    codice_istat: 5109,
    codice_belfiore: "L203",
  },
  {
    id: 7112,
    comune: "TONEZZA DEL CIMONE",
    sigla: "VI",
    codice_elettorale: 1050901060,
    codice_istat: 24106,
    codice_belfiore: "D717",
  },
  {
    id: 7113,
    comune: "TORA E PICCILLI",
    sigla: "CE",
    codice_elettorale: 3150200930,
    codice_istat: 61093,
    codice_belfiore: "L205",
  },
  {
    id: 7114,
    comune: "TORANO CASTELLO",
    sigla: "CS",
    codice_elettorale: 3180251480,
    codice_istat: 78148,
    codice_belfiore: "L206",
  },
  {
    id: 7115,
    comune: "TORANO NUOVO",
    sigla: "TE",
    codice_elettorale: 3130790410,
    codice_istat: 67042,
    codice_belfiore: "L207",
  },
  {
    id: 7116,
    comune: "TORBOLE CASAGLIA",
    sigla: "BS",
    codice_elettorale: 1030151760,
    codice_istat: 17186,
    codice_belfiore: "L210",
  },
  {
    id: 7117,
    comune: "TORCEGNO",
    sigla: "TN",
    codice_elettorale: 1040831910,
    codice_istat: 22202,
    codice_belfiore: "L211",
  },
  {
    id: 7118,
    comune: "TORCHIARA",
    sigla: "SA",
    codice_elettorale: 3150721470,
    codice_istat: 65147,
    codice_belfiore: "L212",
  },
  {
    id: 7119,
    comune: "TORCHIAROLO",
    sigla: "BR",
    codice_elettorale: 3160160180,
    codice_istat: 74018,
    codice_belfiore: "L213",
  },
  {
    id: 7120,
    comune: "TORELLA DEI LOMBARDI",
    sigla: "AV",
    codice_elettorale: 3150081080,
    codice_istat: 64109,
    codice_belfiore: "L214",
  },
  {
    id: 7121,
    comune: "TORELLA DEL SANNIO",
    sigla: "CB",
    codice_elettorale: 3140190790,
    codice_istat: 70079,
    codice_belfiore: "L215",
  },
  {
    id: 7122,
    comune: "TORGIANO",
    sigla: "PG",
    codice_elettorale: 2100580530,
    codice_istat: 54053,
    codice_belfiore: "L216",
  },
  {
    id: 7123,
    comune: "TORGNON",
    sigla: "AO",
    codice_elettorale: 1020040660,
    codice_istat: 7067,
    codice_belfiore: "L217",
  },
  {
    id: 7124,
    comune: "TORINO",
    sigla: "TO",
    codice_elettorale: 1010812620,
    codice_istat: 1272,
    codice_belfiore: "L219",
  },
  {
    id: 7125,
    comune: "TORINO DI SANGRO",
    sigla: "CH",
    codice_elettorale: 3130230910,
    codice_istat: 69091,
    codice_belfiore: "L218",
  },
  {
    id: 7126,
    comune: "TORITTO",
    sigla: "BA",
    codice_elettorale: 3160090430,
    codice_istat: 72044,
    codice_belfiore: "L220",
  },
  {
    id: 7127,
    comune: "TORLINO VIMERCATI",
    sigla: "CR",
    codice_elettorale: 1030261020,
    codice_istat: 19105,
    codice_belfiore: "L221",
  },
  {
    id: 7128,
    comune: "TORNACO",
    sigla: "NO",
    codice_elettorale: 1010521390,
    codice_istat: 3146,
    codice_belfiore: "L223",
  },
  {
    id: 7129,
    comune: "TORNARECCIO",
    sigla: "CH",
    codice_elettorale: 3130230920,
    codice_istat: 69092,
    codice_belfiore: "L224",
  },
  {
    id: 7130,
    comune: "TORNATA",
    sigla: "CR",
    codice_elettorale: 1030261030,
    codice_istat: 19106,
    codice_belfiore: "L225",
  },
  {
    id: 7131,
    comune: "TORNIMPARTE",
    sigla: "AQ",
    codice_elettorale: 3130381000,
    codice_istat: 66101,
    codice_belfiore: "L227",
  },
  {
    id: 7132,
    comune: "TORNO",
    sigla: "CO",
    codice_elettorale: 1030242090,
    codice_istat: 13223,
    codice_belfiore: "L228",
  },
  {
    id: 7133,
    comune: "TORNOLO",
    sigla: "PR",
    codice_elettorale: 1080560400,
    codice_istat: 34040,
    codice_belfiore: "L229",
  },
  {
    id: 7134,
    comune: "TORO",
    sigla: "CB",
    codice_elettorale: 3140190800,
    codice_istat: 70080,
    codice_belfiore: "L230",
  },
  {
    id: 7135,
    comune: "TORPE'",
    sigla: "NU",
    codice_elettorale: 4200530910,
    codice_istat: 91094,
    codice_belfiore: "L231",
  },
  {
    id: 7136,
    comune: "TORRACA",
    sigla: "SA",
    codice_elettorale: 3150721480,
    codice_istat: 65148,
    codice_belfiore: "L233",
  },
  {
    id: 7137,
    comune: "TORRALBA",
    sigla: "SS",
    codice_elettorale: 4200730710,
    codice_istat: 90073,
    codice_belfiore: "L235",
  },
  {
    id: 7138,
    comune: "TORRAZZA COSTE",
    sigla: "PV",
    codice_elettorale: 1030571520,
    codice_istat: 18155,
    codice_belfiore: "L237",
  },
  {
    id: 7139,
    comune: "TORRAZZA PIEMONTE",
    sigla: "TO",
    codice_elettorale: 1010812630,
    codice_istat: 1273,
    codice_belfiore: "L238",
  },
  {
    id: 7140,
    comune: "TORRAZZO",
    sigla: "BI",
    codice_elettorale: 1010960690,
    codice_istat: 96069,
    codice_belfiore: "L239",
  },
  {
    id: 7141,
    comune: "TORRE ANNUNZIATA",
    sigla: "NA",
    codice_elettorale: 3150510830,
    codice_istat: 63083,
    codice_belfiore: "L245",
  },
  {
    id: 7142,
    comune: "TORRE BERETTI E CASTELLARO",
    sigla: "PV",
    codice_elettorale: 1030571530,
    codice_istat: 18156,
    codice_belfiore: "L250",
  },
  {
    id: 7143,
    comune: "TORRE BOLDONE",
    sigla: "BG",
    codice_elettorale: 1030122050,
    codice_istat: 16214,
    codice_belfiore: "L251",
  },
  {
    id: 7144,
    comune: "TORRE BORMIDA",
    sigla: "CN",
    codice_elettorale: 1010272260,
    codice_istat: 4226,
    codice_belfiore: "L252",
  },
  {
    id: 7145,
    comune: "TORRE CAJETANI",
    sigla: "FR",
    codice_elettorale: 2120330770,
    codice_istat: 60078,
    codice_belfiore: "L243",
  },
  {
    id: 7146,
    comune: "TORRE CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812631,
    codice_istat: 1274,
    codice_belfiore: "L247",
  },
  {
    id: 7147,
    comune: "TORRE D'ARESE",
    sigla: "PV",
    codice_elettorale: 1030571540,
    codice_istat: 18157,
    codice_belfiore: "L256",
  },
  {
    id: 7148,
    comune: "TORRE D'ISOLA",
    sigla: "PV",
    codice_elettorale: 1030571560,
    codice_istat: 18159,
    codice_belfiore: "L269",
  },
  {
    id: 7149,
    comune: "TORRE DE' BUSI",
    sigla: "BG",
    codice_elettorale: 1030122061,
    codice_istat: 16215,
    codice_belfiore: "L257",
  },
  {
    id: 7150,
    comune: "TORRE DE' NEGRI",
    sigla: "PV",
    codice_elettorale: 1030571550,
    codice_istat: 18158,
    codice_belfiore: "L262",
  },
  {
    id: 7151,
    comune: "TORRE DE' PASSERI",
    sigla: "PE",
    codice_elettorale: 3130600430,
    codice_istat: 68043,
    codice_belfiore: "L263",
  },
  {
    id: 7152,
    comune: "TORRE DE' PICENARDI",
    sigla: "CR",
    codice_elettorale: 1030261040,
    codice_istat: 19107,
    codice_belfiore: "L258",
  },
  {
    id: 7153,
    comune: "TORRE DE' ROVERI",
    sigla: "BG",
    codice_elettorale: 1030122070,
    codice_istat: 16216,
    codice_belfiore: "L265",
  },
  {
    id: 7154,
    comune: "TORRE DEL GRECO",
    sigla: "NA",
    codice_elettorale: 3150510840,
    codice_istat: 63084,
    codice_belfiore: "L259",
  },
  {
    id: 7155,
    comune: "TORRE DI MOSTO",
    sigla: "VE",
    codice_elettorale: 1050870410,
    codice_istat: 27041,
    codice_belfiore: "L267",
  },
  {
    id: 7156,
    comune: "TORRE DI RUGGIERO",
    sigla: "CZ",
    codice_elettorale: 3180221440,
    codice_istat: 79148,
    codice_belfiore: "L240",
  },
  {
    id: 7157,
    comune: "TORRE DI SANTA MARIA",
    sigla: "SO",
    codice_elettorale: 1030770670,
    codice_istat: 14067,
    codice_belfiore: "L244",
  },
  {
    id: 7158,
    comune: "TORRE LE NOCELLE",
    sigla: "AV",
    codice_elettorale: 3150081090,
    codice_istat: 64110,
    codice_belfiore: "L272",
  },
  {
    id: 7159,
    comune: "TORRE MONDOVI'",
    sigla: "CN",
    codice_elettorale: 1010272270,
    codice_istat: 4227,
    codice_belfiore: "L241",
  },
  {
    id: 7160,
    comune: "TORRE ORSAIA",
    sigla: "SA",
    codice_elettorale: 3150721490,
    codice_istat: 65149,
    codice_belfiore: "L274",
  },
  {
    id: 7161,
    comune: "TORRE PALLAVICINA",
    sigla: "BG",
    codice_elettorale: 1030122080,
    codice_istat: 16217,
    codice_belfiore: "L276",
  },
  {
    id: 7162,
    comune: "TORRE PELLICE",
    sigla: "TO",
    codice_elettorale: 1010812640,
    codice_istat: 1275,
    codice_belfiore: "L277",
  },
  {
    id: 7163,
    comune: "TORRE SAN GIORGIO",
    sigla: "CN",
    codice_elettorale: 1010272280,
    codice_istat: 4228,
    codice_belfiore: "L278",
  },
  {
    id: 7164,
    comune: "TORRE SAN PATRIZIO",
    sigla: "FM",
    codice_elettorale: 2111050400,
    codice_istat: 109040,
    codice_belfiore: "L279",
  },
  {
    id: 7165,
    comune: "TORRE SANTA SUSANNA",
    sigla: "BR",
    codice_elettorale: 3160160190,
    codice_istat: 74019,
    codice_belfiore: "L280",
  },
  {
    id: 7166,
    comune: "TORREANO",
    sigla: "UD",
    codice_elettorale: 1060851220,
    codice_istat: 30122,
    codice_belfiore: "L246",
  },
  {
    id: 7167,
    comune: "TORREBELVICINO",
    sigla: "VI",
    codice_elettorale: 1050901070,
    codice_istat: 24107,
    codice_belfiore: "L248",
  },
  {
    id: 7168,
    comune: "TORREBRUNA",
    sigla: "CH",
    codice_elettorale: 3130230930,
    codice_istat: 69093,
    codice_belfiore: "L253",
  },
  {
    id: 7169,
    comune: "TORRECUSO",
    sigla: "BN",
    codice_elettorale: 3150110740,
    codice_istat: 62076,
    codice_belfiore: "L254",
  },
  {
    id: 7170,
    comune: "TORREGLIA",
    sigla: "PD",
    codice_elettorale: 1050540920,
    codice_istat: 28092,
    codice_belfiore: "L270",
  },
  {
    id: 7171,
    comune: "TORREGROTTA",
    sigla: "ME",
    codice_elettorale: 4190480960,
    codice_istat: 83098,
    codice_belfiore: "L271",
  },
  {
    id: 7172,
    comune: "TORREMAGGIORE",
    sigla: "FG",
    codice_elettorale: 3160310540,
    codice_istat: 71056,
    codice_belfiore: "L273",
  },
  {
    id: 7173,
    comune: "TORRENOVA",
    sigla: "ME",
    codice_elettorale: 4190480961,
    codice_istat: 83108,
    codice_belfiore: "M286",
  },
  {
    id: 7174,
    comune: "TORRESINA",
    sigla: "CN",
    codice_elettorale: 1010272290,
    codice_istat: 4229,
    codice_belfiore: "L281",
  },
  {
    id: 7175,
    comune: "TORRETTA",
    sigla: "PA",
    codice_elettorale: 4190550700,
    codice_istat: 82072,
    codice_belfiore: "L282",
  },
  {
    id: 7176,
    comune: "TORREVECCHIA PIA",
    sigla: "PV",
    codice_elettorale: 1030571570,
    codice_istat: 18160,
    codice_belfiore: "L285",
  },
  {
    id: 7177,
    comune: "TORREVECCHIA TEATINA",
    sigla: "CH",
    codice_elettorale: 3130230940,
    codice_istat: 69094,
    codice_belfiore: "L284",
  },
  {
    id: 7178,
    comune: "TORRI DEL BENACO",
    sigla: "VR",
    codice_elettorale: 1050890850,
    codice_istat: 23086,
    codice_belfiore: "L287",
  },
  {
    id: 7179,
    comune: "TORRI DI QUARTESOLO",
    sigla: "VI",
    codice_elettorale: 1050901080,
    codice_istat: 24108,
    codice_belfiore: "L297",
  },
  {
    id: 7180,
    comune: "TORRI IN SABINA",
    sigla: "RI",
    codice_elettorale: 2120690680,
    codice_istat: 57070,
    codice_belfiore: "L286",
  },
  {
    id: 7181,
    comune: "TORRICE",
    sigla: "FR",
    codice_elettorale: 2120330780,
    codice_istat: 60079,
    codice_belfiore: "L290",
  },
  {
    id: 7182,
    comune: "TORRICELLA",
    sigla: "TA",
    codice_elettorale: 3160780280,
    codice_istat: 73028,
    codice_belfiore: "L294",
  },
  {
    id: 7183,
    comune: "TORRICELLA DEL PIZZO",
    sigla: "CR",
    codice_elettorale: 1030261050,
    codice_istat: 19108,
    codice_belfiore: "L296",
  },
  {
    id: 7184,
    comune: "TORRICELLA IN SABINA",
    sigla: "RI",
    codice_elettorale: 2120690670,
    codice_istat: 57069,
    codice_belfiore: "L293",
  },
  {
    id: 7185,
    comune: "TORRICELLA PELIGNA",
    sigla: "CH",
    codice_elettorale: 3130230950,
    codice_istat: 69095,
    codice_belfiore: "L291",
  },
  {
    id: 7186,
    comune: "TORRICELLA SICURA",
    sigla: "TE",
    codice_elettorale: 3130790420,
    codice_istat: 67043,
    codice_belfiore: "L295",
  },
  {
    id: 7187,
    comune: "TORRICELLA VERZATE",
    sigla: "PV",
    codice_elettorale: 1030571580,
    codice_istat: 18161,
    codice_belfiore: "L292",
  },
  {
    id: 7188,
    comune: "TORRIGLIA",
    sigla: "GE",
    codice_elettorale: 1070340620,
    codice_istat: 10062,
    codice_belfiore: "L298",
  },
  {
    id: 7189,
    comune: "TORRILE",
    sigla: "PR",
    codice_elettorale: 1080560410,
    codice_istat: 34041,
    codice_belfiore: "L299",
  },
  {
    id: 7190,
    comune: "TORRIONI",
    sigla: "AV",
    codice_elettorale: 3150081100,
    codice_istat: 64111,
    codice_belfiore: "L301",
  },
  {
    id: 7191,
    comune: "TORRITA DI SIENA",
    sigla: "SI",
    codice_elettorale: 2090750350,
    codice_istat: 52035,
    codice_belfiore: "L303",
  },
  {
    id: 7192,
    comune: "TORRITA TIBERINA",
    sigla: "RM",
    codice_elettorale: 2120701050,
    codice_istat: 58106,
    codice_belfiore: "L302",
  },
  {
    id: 7193,
    comune: "TORTOLI'",
    sigla: "NU",
    codice_elettorale: 4200530920,
    codice_istat: 91095,
    codice_belfiore: "A355",
  },
  {
    id: 7194,
    comune: "TORTONA",
    sigla: "AL",
    codice_elettorale: 1010021710,
    codice_istat: 6174,
    codice_belfiore: "L304",
  },
  {
    id: 7195,
    comune: "TORTORA",
    sigla: "CS",
    codice_elettorale: 3180251490,
    codice_istat: 78149,
    codice_belfiore: "L305",
  },
  {
    id: 7196,
    comune: "TORTORELLA",
    sigla: "SA",
    codice_elettorale: 3150721500,
    codice_istat: 65150,
    codice_belfiore: "L306",
  },
  {
    id: 7197,
    comune: "TORTORETO",
    sigla: "TE",
    codice_elettorale: 3130790430,
    codice_istat: 67044,
    codice_belfiore: "L307",
  },
  {
    id: 7198,
    comune: "TORTORICI",
    sigla: "ME",
    codice_elettorale: 4190480970,
    codice_istat: 83099,
    codice_belfiore: "L308",
  },
  {
    id: 7199,
    comune: "TORVISCOSA",
    sigla: "UD",
    codice_elettorale: 1060851230,
    codice_istat: 30123,
    codice_belfiore: "L309",
  },
  {
    id: 7200,
    comune: "TOSCOLANO-MADERNO",
    sigla: "BS",
    codice_elettorale: 1030151770,
    codice_istat: 17187,
    codice_belfiore: "L312",
  },
  {
    id: 7201,
    comune: "TOSSICIA",
    sigla: "TE",
    codice_elettorale: 3130790440,
    codice_istat: 67045,
    codice_belfiore: "L314",
  },
  {
    id: 7202,
    comune: "TOVO DI SANT'AGATA",
    sigla: "SO",
    codice_elettorale: 1030770680,
    codice_istat: 14068,
    codice_belfiore: "L316",
  },
  {
    id: 7203,
    comune: "TOVO SAN GIACOMO",
    sigla: "SV",
    codice_elettorale: 1070740620,
    codice_istat: 9062,
    codice_belfiore: "L315",
  },
  {
    id: 7204,
    comune: "TRABIA",
    sigla: "PA",
    codice_elettorale: 4190550710,
    codice_istat: 82073,
    codice_belfiore: "L317",
  },
  {
    id: 7205,
    comune: "TRADATE",
    sigla: "VA",
    codice_elettorale: 1030861090,
    codice_istat: 12127,
    codice_belfiore: "L319",
  },
  {
    id: 7206,
    comune: "TRAMATZA",
    sigla: "OR",
    codice_elettorale: 4200950660,
    codice_istat: 95066,
    codice_belfiore: "L321",
  },
  {
    id: 7207,
    comune: "TRAMBILENO",
    sigla: "TN",
    codice_elettorale: 1040831920,
    codice_istat: 22203,
    codice_belfiore: "L322",
  },
  {
    id: 7208,
    comune: "TRAMONTI",
    sigla: "SA",
    codice_elettorale: 3150721510,
    codice_istat: 65151,
    codice_belfiore: "L323",
  },
  {
    id: 7209,
    comune: "TRAMONTI DI SOPRA",
    sigla: "PN",
    codice_elettorale: 1060930450,
    codice_istat: 93045,
    codice_belfiore: "L324",
  },
  {
    id: 7210,
    comune: "TRAMONTI DI SOTTO",
    sigla: "PN",
    codice_elettorale: 1060930460,
    codice_istat: 93046,
    codice_belfiore: "L325",
  },
  {
    id: 7211,
    comune: "TRAMUTOLA",
    sigla: "PZ",
    codice_elettorale: 3170640900,
    codice_istat: 76091,
    codice_belfiore: "L326",
  },
  {
    id: 7212,
    comune: "TRANA",
    sigla: "TO",
    codice_elettorale: 1010812650,
    codice_istat: 1276,
    codice_belfiore: "L327",
  },
  {
    id: 7213,
    comune: "TRANI",
    sigla: "BT",
    codice_elettorale: 3161060090,
    codice_istat: 110009,
    codice_belfiore: "L328",
  },
  {
    id: 7214,
    comune: "TRAONA",
    sigla: "SO",
    codice_elettorale: 1030770690,
    codice_istat: 14069,
    codice_belfiore: "L330",
  },
  {
    id: 7215,
    comune: "TRAPANI",
    sigla: "TP",
    codice_elettorale: 4190820210,
    codice_istat: 81021,
    codice_belfiore: "L331",
  },
  {
    id: 7216,
    comune: "TRAPPETO",
    sigla: "PA",
    codice_elettorale: 4190550711,
    codice_istat: 82074,
    codice_belfiore: "L332",
  },
  {
    id: 7217,
    comune: "TRAREGO VIGGIONA",
    sigla: "VB",
    codice_elettorale: 1011020660,
    codice_istat: 103066,
    codice_belfiore: "L333",
  },
  {
    id: 7218,
    comune: "TRASACCO",
    sigla: "AQ",
    codice_elettorale: 3130381010,
    codice_istat: 66102,
    codice_belfiore: "L334",
  },
  {
    id: 7219,
    comune: "TRASAGHIS",
    sigla: "UD",
    codice_elettorale: 1060851240,
    codice_istat: 30124,
    codice_belfiore: "L335",
  },
  {
    id: 7220,
    comune: "TRASQUERA",
    sigla: "VB",
    codice_elettorale: 1011020670,
    codice_istat: 103067,
    codice_belfiore: "L336",
  },
  {
    id: 7221,
    comune: "TRATALIAS",
    sigla: "SU",
    codice_elettorale: 4201110900,
    codice_istat: 111090,
    codice_belfiore: "L337",
  },
  {
    id: 7222,
    comune: "TRAVACO' SICCOMARIO",
    sigla: "PV",
    codice_elettorale: 1030571590,
    codice_istat: 18162,
    codice_belfiore: "I236",
  },
  {
    id: 7223,
    comune: "TRAVAGLIATO",
    sigla: "BS",
    codice_elettorale: 1030151780,
    codice_istat: 17188,
    codice_belfiore: "L339",
  },
  {
    id: 7224,
    comune: "TRAVEDONA-MONATE",
    sigla: "VA",
    codice_elettorale: 1030861100,
    codice_istat: 12128,
    codice_belfiore: "L342",
  },
  {
    id: 7225,
    comune: "TRAVERSELLA",
    sigla: "TO",
    codice_elettorale: 1010812680,
    codice_istat: 1278,
    codice_belfiore: "L345",
  },
  {
    id: 7226,
    comune: "TRAVERSETOLO",
    sigla: "PR",
    codice_elettorale: 1080560420,
    codice_istat: 34042,
    codice_belfiore: "L346",
  },
  {
    id: 7227,
    comune: "TRAVES",
    sigla: "TO",
    codice_elettorale: 1010812681,
    codice_istat: 1279,
    codice_belfiore: "L340",
  },
  {
    id: 7228,
    comune: "TRAVESIO",
    sigla: "PN",
    codice_elettorale: 1060930470,
    codice_istat: 93047,
    codice_belfiore: "L347",
  },
  {
    id: 7229,
    comune: "TRAVO",
    sigla: "PC",
    codice_elettorale: 1080610430,
    codice_istat: 33043,
    codice_belfiore: "L348",
  },
  {
    id: 7230,
    comune: "TRE VILLE",
    sigla: "TN",
    codice_elettorale: 1040831955,
    codice_istat: 22247,
    codice_belfiore: "M361",
  },
  {
    id: 7231,
    comune: "TREBASELEGHE",
    sigla: "PD",
    codice_elettorale: 1050540930,
    codice_istat: 28093,
    codice_belfiore: "L349",
  },
  {
    id: 7232,
    comune: "TREBISACCE",
    sigla: "CS",
    codice_elettorale: 3180251500,
    codice_istat: 78150,
    codice_belfiore: "L353",
  },
  {
    id: 7233,
    comune: "TRECASE",
    sigla: "NA",
    codice_elettorale: 3150510841,
    codice_istat: 63091,
    codice_belfiore: "M280",
  },
  {
    id: 7234,
    comune: "TRECASTAGNI",
    sigla: "CT",
    codice_elettorale: 4190210490,
    codice_istat: 87050,
    codice_belfiore: "L355",
  },
  {
    id: 7235,
    comune: "TRECASTELLI",
    sigla: "AN",
    codice_elettorale: 2110030500,
    codice_istat: 42050,
    codice_belfiore: "M318",
  },
  {
    id: 7236,
    comune: "TRECATE",
    sigla: "NO",
    codice_elettorale: 1010521420,
    codice_istat: 3149,
    codice_belfiore: "L356",
  },
  {
    id: 7237,
    comune: "TRECCHINA",
    sigla: "PZ",
    codice_elettorale: 3170640910,
    codice_istat: 76092,
    codice_belfiore: "L357",
  },
  {
    id: 7238,
    comune: "TRECENTA",
    sigla: "RO",
    codice_elettorale: 1050710470,
    codice_istat: 29047,
    codice_belfiore: "L359",
  },
  {
    id: 7239,
    comune: "TREDOZIO",
    sigla: "FC",
    codice_elettorale: 1080320480,
    codice_istat: 40049,
    codice_belfiore: "L361",
  },
  {
    id: 7240,
    comune: "TREGLIO",
    sigla: "CH",
    codice_elettorale: 3130230960,
    codice_istat: 69096,
    codice_belfiore: "L363",
  },
  {
    id: 7241,
    comune: "TREGNAGO",
    sigla: "VR",
    codice_elettorale: 1050890860,
    codice_istat: 23087,
    codice_belfiore: "L364",
  },
  {
    id: 7242,
    comune: "TREIA",
    sigla: "MC",
    codice_elettorale: 2110440540,
    codice_istat: 43054,
    codice_belfiore: "L366",
  },
  {
    id: 7243,
    comune: "TREISO",
    sigla: "CN",
    codice_elettorale: 1010272291,
    codice_istat: 4230,
    codice_belfiore: "L367",
  },
  {
    id: 7244,
    comune: "TREMESTIERI ETNEO",
    sigla: "CT",
    codice_elettorale: 4190210500,
    codice_istat: 87051,
    codice_belfiore: "L369",
  },
  {
    id: 7245,
    comune: "TREMEZZINA",
    sigla: "CO",
    codice_elettorale: 1030242105,
    codice_istat: 13252,
    codice_belfiore: "M341",
  },
  {
    id: 7246,
    comune: "TREMOSINE SUL GARDA",
    sigla: "BS",
    codice_elettorale: 1030151790,
    codice_istat: 17189,
    codice_belfiore: "L372",
  },
  {
    id: 7247,
    comune: "TRENTINARA",
    sigla: "SA",
    codice_elettorale: 3150721520,
    codice_istat: 65152,
    codice_belfiore: "L377",
  },
  {
    id: 7248,
    comune: "TRENTO",
    sigla: "TN",
    codice_elettorale: 1040831940,
    codice_istat: 22205,
    codice_belfiore: "L378",
  },
  {
    id: 7249,
    comune: "TRENTOLA DUCENTA",
    sigla: "CE",
    codice_elettorale: 3150200940,
    codice_istat: 61094,
    codice_belfiore: "L379",
  },
  {
    id: 7250,
    comune: "TRENZANO",
    sigla: "BS",
    codice_elettorale: 1030151800,
    codice_istat: 17190,
    codice_belfiore: "L380",
  },
  {
    id: 7251,
    comune: "TREPPO GRANDE",
    sigla: "UD",
    codice_elettorale: 1060851260,
    codice_istat: 30126,
    codice_belfiore: "L382",
  },
  {
    id: 7252,
    comune: "TREPPO LIGOSULLO",
    sigla: "UD",
    codice_elettorale: 1060851265,
    codice_istat: 30191,
    codice_belfiore: "M399",
  },
  {
    id: 7253,
    comune: "TREPUZZI",
    sigla: "LE",
    codice_elettorale: 3160410860,
    codice_istat: 75087,
    codice_belfiore: "L383",
  },
  {
    id: 7254,
    comune: "TREQUANDA",
    sigla: "SI",
    codice_elettorale: 2090750360,
    codice_istat: 52036,
    codice_belfiore: "L384",
  },
  {
    id: 7255,
    comune: "TRESANA",
    sigla: "MS",
    codice_elettorale: 2090460150,
    codice_istat: 45015,
    codice_belfiore: "L386",
  },
  {
    id: 7256,
    comune: "TRESCORE BALNEARIO",
    sigla: "BG",
    codice_elettorale: 1030122090,
    codice_istat: 16218,
    codice_belfiore: "L388",
  },
  {
    id: 7257,
    comune: "TRESCORE CREMASCO",
    sigla: "CR",
    codice_elettorale: 1030261060,
    codice_istat: 19109,
    codice_belfiore: "L389",
  },
  {
    id: 7258,
    comune: "TRESIGNANA",
    sigla: "FE",
    codice_elettorale: 1080290195,
    codice_istat: 38030,
    codice_belfiore: "M409",
  },
  {
    id: 7259,
    comune: "TRESIVIO",
    sigla: "SO",
    codice_elettorale: 1030770700,
    codice_istat: 14070,
    codice_belfiore: "L392",
  },
  {
    id: 7260,
    comune: "TRESNURAGHES",
    sigla: "OR",
    codice_elettorale: 4200950670,
    codice_istat: 95067,
    codice_belfiore: "L393",
  },
  {
    id: 7261,
    comune: "TREVENZUOLO",
    sigla: "VR",
    codice_elettorale: 1050890870,
    codice_istat: 23088,
    codice_belfiore: "L396",
  },
  {
    id: 7262,
    comune: "TREVI",
    sigla: "PG",
    codice_elettorale: 2100580540,
    codice_istat: 54054,
    codice_belfiore: "L397",
  },
  {
    id: 7263,
    comune: "TREVI NEL LAZIO",
    sigla: "FR",
    codice_elettorale: 2120330790,
    codice_istat: 60080,
    codice_belfiore: "L398",
  },
  {
    id: 7264,
    comune: "TREVICO",
    sigla: "AV",
    codice_elettorale: 3150081110,
    codice_istat: 64112,
    codice_belfiore: "L399",
  },
  {
    id: 7265,
    comune: "TREVIGLIO",
    sigla: "BG",
    codice_elettorale: 1030122100,
    codice_istat: 16219,
    codice_belfiore: "L400",
  },
  {
    id: 7266,
    comune: "TREVIGNANO",
    sigla: "TV",
    codice_elettorale: 1050840840,
    codice_istat: 26085,
    codice_belfiore: "L402",
  },
  {
    id: 7267,
    comune: "TREVIGNANO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120701060,
    codice_istat: 58107,
    codice_belfiore: "L401",
  },
  {
    id: 7268,
    comune: "TREVILLE",
    sigla: "AL",
    codice_elettorale: 1010021720,
    codice_istat: 6175,
    codice_belfiore: "L403",
  },
  {
    id: 7269,
    comune: "TREVIOLO",
    sigla: "BG",
    codice_elettorale: 1030122110,
    codice_istat: 16220,
    codice_belfiore: "L404",
  },
  {
    id: 7270,
    comune: "TREVISO",
    sigla: "TV",
    codice_elettorale: 1050840850,
    codice_istat: 26086,
    codice_belfiore: "L407",
  },
  {
    id: 7271,
    comune: "TREVISO BRESCIANO",
    sigla: "BS",
    codice_elettorale: 1030151810,
    codice_istat: 17191,
    codice_belfiore: "L406",
  },
  {
    id: 7272,
    comune: "TREZZANO ROSA",
    sigla: "MI",
    codice_elettorale: 1030492180,
    codice_istat: 15219,
    codice_belfiore: "L408",
  },
  {
    id: 7273,
    comune: "TREZZANO SUL NAVIGLIO",
    sigla: "MI",
    codice_elettorale: 1030492190,
    codice_istat: 15220,
    codice_belfiore: "L409",
  },
  {
    id: 7274,
    comune: "TREZZO SULL'ADDA",
    sigla: "MI",
    codice_elettorale: 1030492200,
    codice_istat: 15221,
    codice_belfiore: "L411",
  },
  {
    id: 7275,
    comune: "TREZZO TINELLA",
    sigla: "CN",
    codice_elettorale: 1010272300,
    codice_istat: 4231,
    codice_belfiore: "L410",
  },
  {
    id: 7276,
    comune: "TREZZONE",
    sigla: "CO",
    codice_elettorale: 1030242120,
    codice_istat: 13226,
    codice_belfiore: "L413",
  },
  {
    id: 7277,
    comune: "TRIBANO",
    sigla: "PD",
    codice_elettorale: 1050540940,
    codice_istat: 28094,
    codice_belfiore: "L414",
  },
  {
    id: 7278,
    comune: "TRIBIANO",
    sigla: "MI",
    codice_elettorale: 1030492210,
    codice_istat: 15222,
    codice_belfiore: "L415",
  },
  {
    id: 7279,
    comune: "TRIBOGNA",
    sigla: "GE",
    codice_elettorale: 1070340630,
    codice_istat: 10063,
    codice_belfiore: "L416",
  },
  {
    id: 7280,
    comune: "TRICARICO",
    sigla: "MT",
    codice_elettorale: 3170470270,
    codice_istat: 77028,
    codice_belfiore: "L418",
  },
  {
    id: 7281,
    comune: "TRICASE",
    sigla: "LE",
    codice_elettorale: 3160410870,
    codice_istat: 75088,
    codice_belfiore: "L419",
  },
  {
    id: 7282,
    comune: "TRICERRO",
    sigla: "VC",
    codice_elettorale: 1010881450,
    codice_istat: 2147,
    codice_belfiore: "L420",
  },
  {
    id: 7283,
    comune: "TRICESIMO",
    sigla: "UD",
    codice_elettorale: 1060851270,
    codice_istat: 30127,
    codice_belfiore: "L421",
  },
  {
    id: 7284,
    comune: "TRIEI",
    sigla: "NU",
    codice_elettorale: 4200530940,
    codice_istat: 91097,
    codice_belfiore: "L423",
  },
  {
    id: 7285,
    comune: "TRIESTE",
    sigla: "TS",
    codice_elettorale: 1060920060,
    codice_istat: 32006,
    codice_belfiore: "L424",
  },
  {
    id: 7286,
    comune: "TRIGGIANO",
    sigla: "BA",
    codice_elettorale: 3160090450,
    codice_istat: 72046,
    codice_belfiore: "L425",
  },
  {
    id: 7287,
    comune: "TRIGOLO",
    sigla: "CR",
    codice_elettorale: 1030261070,
    codice_istat: 19110,
    codice_belfiore: "L426",
  },
  {
    id: 7288,
    comune: "TRINITA'",
    sigla: "CN",
    codice_elettorale: 1010272310,
    codice_istat: 4232,
    codice_belfiore: "L427",
  },
  {
    id: 7289,
    comune: "TRINITA' D'AGULTU E VIGNOLA",
    sigla: "SS",
    codice_elettorale: 4200730711,
    codice_istat: 90074,
    codice_belfiore: "L428",
  },
  {
    id: 7290,
    comune: "TRINITAPOLI",
    sigla: "BT",
    codice_elettorale: 3161060100,
    codice_istat: 110010,
    codice_belfiore: "B915",
  },
  {
    id: 7291,
    comune: "TRINO",
    sigla: "VC",
    codice_elettorale: 1010881460,
    codice_istat: 2148,
    codice_belfiore: "L429",
  },
  {
    id: 7292,
    comune: "TRIORA",
    sigla: "IM",
    codice_elettorale: 1070370570,
    codice_istat: 8061,
    codice_belfiore: "L430",
  },
  {
    id: 7293,
    comune: "TRIPI",
    sigla: "ME",
    codice_elettorale: 4190480980,
    codice_istat: 83100,
    codice_belfiore: "L431",
  },
  {
    id: 7294,
    comune: "TRISOBBIO",
    sigla: "AL",
    codice_elettorale: 1010021730,
    codice_istat: 6176,
    codice_belfiore: "L432",
  },
  {
    id: 7295,
    comune: "TRISSINO",
    sigla: "VI",
    codice_elettorale: 1050901100,
    codice_istat: 24110,
    codice_belfiore: "L433",
  },
  {
    id: 7296,
    comune: "TRIUGGIO",
    sigla: "MB",
    codice_elettorale: 1031040430,
    codice_istat: 108043,
    codice_belfiore: "L434",
  },
  {
    id: 7297,
    comune: "TRIVENTO",
    sigla: "CB",
    codice_elettorale: 3140190810,
    codice_istat: 70081,
    codice_belfiore: "L435",
  },
  {
    id: 7298,
    comune: "TRIVIGLIANO",
    sigla: "FR",
    codice_elettorale: 2120330800,
    codice_istat: 60081,
    codice_belfiore: "L437",
  },
  {
    id: 7299,
    comune: "TRIVIGNANO UDINESE",
    sigla: "UD",
    codice_elettorale: 1060851280,
    codice_istat: 30128,
    codice_belfiore: "L438",
  },
  {
    id: 7300,
    comune: "TRIVIGNO",
    sigla: "PZ",
    codice_elettorale: 3170640920,
    codice_istat: 76093,
    codice_belfiore: "L439",
  },
  {
    id: 7301,
    comune: "TRIVOLZIO",
    sigla: "PV",
    codice_elettorale: 1030571600,
    codice_istat: 18163,
    codice_belfiore: "L440",
  },
  {
    id: 7302,
    comune: "TRODENA NEL PARCO NATURALE/TRUDEN IM NATURPARK",
    sigla: "BZ",
    codice_elettorale: 1040140940,
    codice_istat: 21102,
    codice_belfiore: "L444",
  },
  {
    id: 7303,
    comune: "TROFARELLO",
    sigla: "TO",
    codice_elettorale: 1010812690,
    codice_istat: 1280,
    codice_belfiore: "L445",
  },
  {
    id: 7304,
    comune: "TROIA",
    sigla: "FG",
    codice_elettorale: 3160310560,
    codice_istat: 71058,
    codice_belfiore: "L447",
  },
  {
    id: 7305,
    comune: "TROINA",
    sigla: "EN",
    codice_elettorale: 4190280180,
    codice_istat: 86018,
    codice_belfiore: "L448",
  },
  {
    id: 7306,
    comune: "TROMELLO",
    sigla: "PV",
    codice_elettorale: 1030571610,
    codice_istat: 18164,
    codice_belfiore: "L449",
  },
  {
    id: 7307,
    comune: "TRONTANO",
    sigla: "VB",
    codice_elettorale: 1011020680,
    codice_istat: 103068,
    codice_belfiore: "L450",
  },
  {
    id: 7308,
    comune: "TRONZANO LAGO MAGGIORE",
    sigla: "VA",
    codice_elettorale: 1030861110,
    codice_istat: 12129,
    codice_belfiore: "A705",
  },
  {
    id: 7309,
    comune: "TRONZANO VERCELLESE",
    sigla: "VC",
    codice_elettorale: 1010881480,
    codice_istat: 2150,
    codice_belfiore: "L451",
  },
  {
    id: 7310,
    comune: "TROPEA",
    sigla: "VV",
    codice_elettorale: 3181030440,
    codice_istat: 102044,
    codice_belfiore: "L452",
  },
  {
    id: 7311,
    comune: "TROVO",
    sigla: "PV",
    codice_elettorale: 1030571620,
    codice_istat: 18165,
    codice_belfiore: "L453",
  },
  {
    id: 7312,
    comune: "TRUCCAZZANO",
    sigla: "MI",
    codice_elettorale: 1030492230,
    codice_istat: 15224,
    codice_belfiore: "L454",
  },
  {
    id: 7313,
    comune: "TUBRE/TAUFERS IM MUNSTERTAL",
    sigla: "BZ",
    codice_elettorale: 1040140950,
    codice_istat: 21103,
    codice_belfiore: "L455",
  },
  {
    id: 7314,
    comune: "TUFARA",
    sigla: "CB",
    codice_elettorale: 3140190820,
    codice_istat: 70082,
    codice_belfiore: "L458",
  },
  {
    id: 7315,
    comune: "TUFILLO",
    sigla: "CH",
    codice_elettorale: 3130230970,
    codice_istat: 69097,
    codice_belfiore: "L459",
  },
  {
    id: 7316,
    comune: "TUFINO",
    sigla: "NA",
    codice_elettorale: 3150510850,
    codice_istat: 63085,
    codice_belfiore: "L460",
  },
  {
    id: 7317,
    comune: "TUFO",
    sigla: "AV",
    codice_elettorale: 3150081120,
    codice_istat: 64113,
    codice_belfiore: "L461",
  },
  {
    id: 7318,
    comune: "TUGLIE",
    sigla: "LE",
    codice_elettorale: 3160410880,
    codice_istat: 75089,
    codice_belfiore: "L462",
  },
  {
    id: 7319,
    comune: "TUILI",
    sigla: "SU",
    codice_elettorale: 4201110910,
    codice_istat: 111091,
    codice_belfiore: "L463",
  },
  {
    id: 7320,
    comune: "TULA",
    sigla: "SS",
    codice_elettorale: 4200730720,
    codice_istat: 90075,
    codice_belfiore: "L464",
  },
  {
    id: 7321,
    comune: "TUORO SUL TRASIMENO",
    sigla: "PG",
    codice_elettorale: 2100580550,
    codice_istat: 54055,
    codice_belfiore: "L466",
  },
  {
    id: 7322,
    comune: "TURANIA",
    sigla: "RI",
    codice_elettorale: 2120690690,
    codice_istat: 57071,
    codice_belfiore: "G507",
  },
  {
    id: 7323,
    comune: "TURANO LODIGIANO",
    sigla: "LO",
    codice_elettorale: 1030990580,
    codice_istat: 98058,
    codice_belfiore: "L469",
  },
  {
    id: 7324,
    comune: "TURATE",
    sigla: "CO",
    codice_elettorale: 1030242130,
    codice_istat: 13227,
    codice_belfiore: "L470",
  },
  {
    id: 7325,
    comune: "TURBIGO",
    sigla: "MI",
    codice_elettorale: 1030492250,
    codice_istat: 15226,
    codice_belfiore: "L471",
  },
  {
    id: 7326,
    comune: "TURI",
    sigla: "BA",
    codice_elettorale: 3160090460,
    codice_istat: 72047,
    codice_belfiore: "L472",
  },
  {
    id: 7327,
    comune: "TURRI",
    sigla: "SU",
    codice_elettorale: 4201110920,
    codice_istat: 111092,
    codice_belfiore: "L473",
  },
  {
    id: 7328,
    comune: "TURRIACO",
    sigla: "GO",
    codice_elettorale: 1060350200,
    codice_istat: 31024,
    codice_belfiore: "L474",
  },
  {
    id: 7329,
    comune: "TURRIVALIGNANI",
    sigla: "PE",
    codice_elettorale: 3130600440,
    codice_istat: 68044,
    codice_belfiore: "L475",
  },
  {
    id: 7330,
    comune: "TURSI",
    sigla: "MT",
    codice_elettorale: 3170470280,
    codice_istat: 77029,
    codice_belfiore: "L477",
  },
  {
    id: 7331,
    comune: "TUSA",
    sigla: "ME",
    codice_elettorale: 4190480990,
    codice_istat: 83101,
    codice_belfiore: "L478",
  },
  {
    id: 7332,
    comune: "TUSCANIA",
    sigla: "VT",
    codice_elettorale: 2120910510,
    codice_istat: 56052,
    codice_belfiore: "L310",
  },
  {
    id: 7333,
    comune: "UBIALE CLANEZZO",
    sigla: "BG",
    codice_elettorale: 1030122120,
    codice_istat: 16221,
    codice_belfiore: "C789",
  },
  {
    id: 7334,
    comune: "UBOLDO",
    sigla: "VA",
    codice_elettorale: 1030861120,
    codice_istat: 12130,
    codice_belfiore: "L480",
  },
  {
    id: 7335,
    comune: "UCRIA",
    sigla: "ME",
    codice_elettorale: 4190481000,
    codice_istat: 83102,
    codice_belfiore: "L482",
  },
  {
    id: 7336,
    comune: "UDINE",
    sigla: "UD",
    codice_elettorale: 1060851290,
    codice_istat: 30129,
    codice_belfiore: "L483",
  },
  {
    id: 7337,
    comune: "UGENTO",
    sigla: "LE",
    codice_elettorale: 3160410890,
    codice_istat: 75090,
    codice_belfiore: "L484",
  },
  {
    id: 7338,
    comune: "UGGIANO LA CHIESA",
    sigla: "LE",
    codice_elettorale: 3160410900,
    codice_istat: 75091,
    codice_belfiore: "L485",
  },
  {
    id: 7339,
    comune: "UGGIATE-TREVANO",
    sigla: "CO",
    codice_elettorale: 1030242140,
    codice_istat: 13228,
    codice_belfiore: "L487",
  },
  {
    id: 7340,
    comune: "ULA TIRSO",
    sigla: "OR",
    codice_elettorale: 4200950680,
    codice_istat: 95068,
    codice_belfiore: "L488",
  },
  {
    id: 7341,
    comune: "ULASSAI",
    sigla: "NU",
    codice_elettorale: 4200530950,
    codice_istat: 91098,
    codice_belfiore: "L489",
  },
  {
    id: 7342,
    comune: "ULTIMO/ULTEN",
    sigla: "BZ",
    codice_elettorale: 1040140960,
    codice_istat: 21104,
    codice_belfiore: "L490",
  },
  {
    id: 7343,
    comune: "UMBERTIDE",
    sigla: "PG",
    codice_elettorale: 2100580560,
    codice_istat: 54056,
    codice_belfiore: "D786",
  },
  {
    id: 7344,
    comune: "UMBRIATICO",
    sigla: "KR",
    codice_elettorale: 3180970260,
    codice_istat: 101026,
    codice_belfiore: "L492",
  },
  {
    id: 7345,
    comune: "URAGO D'OGLIO",
    sigla: "BS",
    codice_elettorale: 1030151820,
    codice_istat: 17192,
    codice_belfiore: "L494",
  },
  {
    id: 7346,
    comune: "URAS",
    sigla: "OR",
    codice_elettorale: 4200950690,
    codice_istat: 95069,
    codice_belfiore: "L496",
  },
  {
    id: 7347,
    comune: "URBANA",
    sigla: "PD",
    codice_elettorale: 1050540950,
    codice_istat: 28095,
    codice_belfiore: "L497",
  },
  {
    id: 7348,
    comune: "URBANIA",
    sigla: "PU",
    codice_elettorale: 2110590660,
    codice_istat: 41066,
    codice_belfiore: "L498",
  },
  {
    id: 7349,
    comune: "URBE",
    sigla: "SV",
    codice_elettorale: 1070740630,
    codice_istat: 9063,
    codice_belfiore: "L499",
  },
  {
    id: 7350,
    comune: "URBINO",
    sigla: "PU",
    codice_elettorale: 2110590670,
    codice_istat: 41067,
    codice_belfiore: "L500",
  },
  {
    id: 7351,
    comune: "URBISAGLIA",
    sigla: "MC",
    codice_elettorale: 2110440550,
    codice_istat: 43055,
    codice_belfiore: "L501",
  },
  {
    id: 7352,
    comune: "URGNANO",
    sigla: "BG",
    codice_elettorale: 1030122130,
    codice_istat: 16222,
    codice_belfiore: "L502",
  },
  {
    id: 7353,
    comune: "URI",
    sigla: "SS",
    codice_elettorale: 4200730730,
    codice_istat: 90076,
    codice_belfiore: "L503",
  },
  {
    id: 7354,
    comune: "URURI",
    sigla: "CB",
    codice_elettorale: 3140190830,
    codice_istat: 70083,
    codice_belfiore: "L505",
  },
  {
    id: 7355,
    comune: "URZULEI",
    sigla: "NU",
    codice_elettorale: 4200530960,
    codice_istat: 91099,
    codice_belfiore: "L506",
  },
  {
    id: 7356,
    comune: "USCIO",
    sigla: "GE",
    codice_elettorale: 1070340640,
    codice_istat: 10064,
    codice_belfiore: "L507",
  },
  {
    id: 7357,
    comune: "USELLUS",
    sigla: "OR",
    codice_elettorale: 4200950700,
    codice_istat: 95070,
    codice_belfiore: "L508",
  },
  {
    id: 7358,
    comune: "USINI",
    sigla: "SS",
    codice_elettorale: 4200730740,
    codice_istat: 90077,
    codice_belfiore: "L509",
  },
  {
    id: 7359,
    comune: "USMATE VELATE",
    sigla: "MB",
    codice_elettorale: 1031040440,
    codice_istat: 108044,
    codice_belfiore: "L511",
  },
  {
    id: 7360,
    comune: "USSANA",
    sigla: "SU",
    codice_elettorale: 4201110930,
    codice_istat: 111093,
    codice_belfiore: "L512",
  },
  {
    id: 7361,
    comune: "USSARAMANNA",
    sigla: "SU",
    codice_elettorale: 4201110940,
    codice_istat: 111094,
    codice_belfiore: "L513",
  },
  {
    id: 7362,
    comune: "USSASSAI",
    sigla: "NU",
    codice_elettorale: 4200530970,
    codice_istat: 91100,
    codice_belfiore: "L514",
  },
  {
    id: 7363,
    comune: "USSEAUX",
    sigla: "TO",
    codice_elettorale: 1010812710,
    codice_istat: 1281,
    codice_belfiore: "L515",
  },
  {
    id: 7364,
    comune: "USSEGLIO",
    sigla: "TO",
    codice_elettorale: 1010812720,
    codice_istat: 1282,
    codice_belfiore: "L516",
  },
  {
    id: 7365,
    comune: "USSITA",
    sigla: "MC",
    codice_elettorale: 2110440560,
    codice_istat: 43056,
    codice_belfiore: "L517",
  },
  {
    id: 7366,
    comune: "USTICA",
    sigla: "PA",
    codice_elettorale: 4190550720,
    codice_istat: 82075,
    codice_belfiore: "L519",
  },
  {
    id: 7367,
    comune: "UTA",
    sigla: "CA",
    codice_elettorale: 4200170900,
    codice_istat: 92090,
    codice_belfiore: "L521",
  },
  {
    id: 7368,
    comune: "UZZANO",
    sigla: "PT",
    codice_elettorale: 2090630210,
    codice_istat: 47021,
    codice_belfiore: "L522",
  },
  {
    id: 7369,
    comune: "VACCARIZZO ALBANESE",
    sigla: "CS",
    codice_elettorale: 3180251520,
    codice_istat: 78152,
    codice_belfiore: "L524",
  },
  {
    id: 7370,
    comune: "VACONE",
    sigla: "RI",
    codice_elettorale: 2120690700,
    codice_istat: 57072,
    codice_belfiore: "L525",
  },
  {
    id: 7371,
    comune: "VACRI",
    sigla: "CH",
    codice_elettorale: 3130230980,
    codice_istat: 69098,
    codice_belfiore: "L526",
  },
  {
    id: 7372,
    comune: "VADENA/PFATTEN",
    sigla: "BZ",
    codice_elettorale: 1040140970,
    codice_istat: 21105,
    codice_belfiore: "L527",
  },
  {
    id: 7373,
    comune: "VADO LIGURE",
    sigla: "SV",
    codice_elettorale: 1070740640,
    codice_istat: 9064,
    codice_belfiore: "L528",
  },
  {
    id: 7374,
    comune: "VAGLI SOTTO",
    sigla: "LU",
    codice_elettorale: 2090430310,
    codice_istat: 46031,
    codice_belfiore: "L533",
  },
  {
    id: 7375,
    comune: "VAGLIA",
    sigla: "FI",
    codice_elettorale: 2090300460,
    codice_istat: 48046,
    codice_belfiore: "L529",
  },
  {
    id: 7376,
    comune: "VAGLIO BASILICATA",
    sigla: "PZ",
    codice_elettorale: 3170640930,
    codice_istat: 76094,
    codice_belfiore: "L532",
  },
  {
    id: 7377,
    comune: "VAGLIO SERRA",
    sigla: "AT",
    codice_elettorale: 1010071110,
    codice_istat: 5111,
    codice_belfiore: "L531",
  },
  {
    id: 7378,
    comune: "VAIANO",
    sigla: "PO",
    codice_elettorale: 2091000060,
    codice_istat: 100006,
    codice_belfiore: "L537",
  },
  {
    id: 7379,
    comune: "VAIANO CREMASCO",
    sigla: "CR",
    codice_elettorale: 1030261080,
    codice_istat: 19111,
    codice_belfiore: "L535",
  },
  {
    id: 7380,
    comune: "VAIE",
    sigla: "TO",
    codice_elettorale: 1010812730,
    codice_istat: 1283,
    codice_belfiore: "L538",
  },
  {
    id: 7381,
    comune: "VAILATE",
    sigla: "CR",
    codice_elettorale: 1030261090,
    codice_istat: 19112,
    codice_belfiore: "L539",
  },
  {
    id: 7382,
    comune: "VAIRANO PATENORA",
    sigla: "CE",
    codice_elettorale: 3150200950,
    codice_istat: 61095,
    codice_belfiore: "L540",
  },
  {
    id: 7383,
    comune: "VAJONT",
    sigla: "PN",
    codice_elettorale: 1060930471,
    codice_istat: 93052,
    codice_belfiore: "M265",
  },
  {
    id: 7384,
    comune: "VAL BREMBILLA",
    sigla: "BG",
    codice_elettorale: 1030122145,
    codice_istat: 16253,
    codice_belfiore: "M334",
  },
  {
    id: 7385,
    comune: "VAL DELLA TORRE",
    sigla: "TO",
    codice_elettorale: 1010812740,
    codice_istat: 1284,
    codice_belfiore: "L555",
  },
  {
    id: 7386,
    comune: "VAL DI CHY",
    sigla: "TO",
    codice_elettorale: 1010812745,
    codice_istat: 1317,
    codice_belfiore: "M405",
  },
  {
    id: 7387,
    comune: "VAL DI NIZZA",
    sigla: "PV",
    codice_elettorale: 1030571630,
    codice_istat: 18166,
    codice_belfiore: "L562",
  },
  {
    id: 7388,
    comune: "VAL DI VIZZE/PFITSCH",
    sigla: "BZ",
    codice_elettorale: 1040140990,
    codice_istat: 21107,
    codice_belfiore: "L564",
  },
  {
    id: 7389,
    comune: "VAL DI ZOLDO",
    sigla: "BL",
    codice_elettorale: 1050100615,
    codice_istat: 25073,
    codice_belfiore: "M374",
  },
  {
    id: 7390,
    comune: "VAL LIONA",
    sigla: "VI",
    codice_elettorale: 1050901105,
    codice_istat: 24123,
    codice_belfiore: "M384",
  },
  {
    id: 7391,
    comune: "VAL MASINO",
    sigla: "SO",
    codice_elettorale: 1030770740,
    codice_istat: 14074,
    codice_belfiore: "L638",
  },
  {
    id: 7392,
    comune: "VAL REZZO",
    sigla: "CO",
    codice_elettorale: 1030242190,
    codice_istat: 13233,
    codice_belfiore: "H259",
  },
  {
    id: 7393,
    comune: "VALBONDIONE",
    sigla: "BG",
    codice_elettorale: 1030122140,
    codice_istat: 16223,
    codice_belfiore: "L544",
  },
  {
    id: 7394,
    comune: "VALBREMBO",
    sigla: "BG",
    codice_elettorale: 1030122150,
    codice_istat: 16224,
    codice_belfiore: "L545",
  },
  {
    id: 7395,
    comune: "VALBRENTA",
    sigla: "VI",
    codice_elettorale: 1050901108,
    codice_istat: 24125,
    codice_belfiore: "M423",
  },
  {
    id: 7396,
    comune: "VALBREVENNA",
    sigla: "GE",
    codice_elettorale: 1070340650,
    codice_istat: 10065,
    codice_belfiore: "L546",
  },
  {
    id: 7397,
    comune: "VALBRONA",
    sigla: "CO",
    codice_elettorale: 1030242150,
    codice_istat: 13229,
    codice_belfiore: "L547",
  },
  {
    id: 7398,
    comune: "VALCHIUSA",
    sigla: "TO",
    codice_elettorale: 1010812735,
    codice_istat: 1318,
    codice_belfiore: "M415",
  },
  {
    id: 7399,
    comune: "VALDAGNO",
    sigla: "VI",
    codice_elettorale: 1050901110,
    codice_istat: 24111,
    codice_belfiore: "L551",
  },
  {
    id: 7400,
    comune: "VALDAONE",
    sigla: "TN",
    codice_elettorale: 1040831975,
    codice_istat: 22232,
    codice_belfiore: "M343",
  },
  {
    id: 7401,
    comune: "VALDAORA/OLANG",
    sigla: "BZ",
    codice_elettorale: 1040140981,
    codice_istat: 21106,
    codice_belfiore: "L552",
  },
  {
    id: 7402,
    comune: "VALDASTICO",
    sigla: "VI",
    codice_elettorale: 1050901120,
    codice_istat: 24112,
    codice_belfiore: "L554",
  },
  {
    id: 7403,
    comune: "VALDENGO",
    sigla: "BI",
    codice_elettorale: 1010960710,
    codice_istat: 96071,
    codice_belfiore: "L556",
  },
  {
    id: 7404,
    comune: "VALDERICE",
    sigla: "TP",
    codice_elettorale: 4190820211,
    codice_istat: 81022,
    codice_belfiore: "G319",
  },
  {
    id: 7405,
    comune: "VALDIDENTRO",
    sigla: "SO",
    codice_elettorale: 1030770710,
    codice_istat: 14071,
    codice_belfiore: "L557",
  },
  {
    id: 7406,
    comune: "VALDIERI",
    sigla: "CN",
    codice_elettorale: 1010272320,
    codice_istat: 4233,
    codice_belfiore: "L558",
  },
  {
    id: 7407,
    comune: "VALDILANA",
    sigla: "BI",
    codice_elettorale: 1010960715,
    codice_istat: 96088,
    codice_belfiore: "M417",
  },
  {
    id: 7408,
    comune: "VALDINA",
    sigla: "ME",
    codice_elettorale: 4190481010,
    codice_istat: 83103,
    codice_belfiore: "L561",
  },
  {
    id: 7409,
    comune: "VALDISOTTO",
    sigla: "SO",
    codice_elettorale: 1030770720,
    codice_istat: 14072,
    codice_belfiore: "L563",
  },
  {
    id: 7410,
    comune: "VALDOBBIADENE",
    sigla: "TV",
    codice_elettorale: 1050840860,
    codice_istat: 26087,
    codice_belfiore: "L565",
  },
  {
    id: 7411,
    comune: "VALDUGGIA",
    sigla: "VC",
    codice_elettorale: 1010881500,
    codice_istat: 2152,
    codice_belfiore: "L566",
  },
  {
    id: 7412,
    comune: "VALEGGIO",
    sigla: "PV",
    codice_elettorale: 1030571640,
    codice_istat: 18167,
    codice_belfiore: "L568",
  },
  {
    id: 7413,
    comune: "VALEGGIO SUL MINCIO",
    sigla: "VR",
    codice_elettorale: 1050890880,
    codice_istat: 23089,
    codice_belfiore: "L567",
  },
  {
    id: 7414,
    comune: "VALENTANO",
    sigla: "VT",
    codice_elettorale: 2120910520,
    codice_istat: 56053,
    codice_belfiore: "L569",
  },
  {
    id: 7415,
    comune: "VALENZA",
    sigla: "AL",
    codice_elettorale: 1010021740,
    codice_istat: 6177,
    codice_belfiore: "L570",
  },
  {
    id: 7416,
    comune: "VALENZANO",
    sigla: "BA",
    codice_elettorale: 3160090470,
    codice_istat: 72048,
    codice_belfiore: "L571",
  },
  {
    id: 7417,
    comune: "VALERA FRATTA",
    sigla: "LO",
    codice_elettorale: 1030990590,
    codice_istat: 98059,
    codice_belfiore: "L572",
  },
  {
    id: 7418,
    comune: "VALFABBRICA",
    sigla: "PG",
    codice_elettorale: 2100580570,
    codice_istat: 54057,
    codice_belfiore: "L573",
  },
  {
    id: 7419,
    comune: "VALFENERA",
    sigla: "AT",
    codice_elettorale: 1010071120,
    codice_istat: 5112,
    codice_belfiore: "L574",
  },
  {
    id: 7420,
    comune: "VALFLORIANA",
    sigla: "TN",
    codice_elettorale: 1040831980,
    codice_istat: 22209,
    codice_belfiore: "L575",
  },
  {
    id: 7421,
    comune: "VALFORNACE",
    sigla: "MC",
    codice_elettorale: 2110440565,
    codice_istat: 43058,
    codice_belfiore: "M382",
  },
  {
    id: 7422,
    comune: "VALFURVA",
    sigla: "SO",
    codice_elettorale: 1030770730,
    codice_istat: 14073,
    codice_belfiore: "L576",
  },
  {
    id: 7423,
    comune: "VALGANNA",
    sigla: "VA",
    codice_elettorale: 1030861130,
    codice_istat: 12131,
    codice_belfiore: "L577",
  },
  {
    id: 7424,
    comune: "VALGIOIE",
    sigla: "TO",
    codice_elettorale: 1010812742,
    codice_istat: 1285,
    codice_belfiore: "L578",
  },
  {
    id: 7425,
    comune: "VALGOGLIO",
    sigla: "BG",
    codice_elettorale: 1030122151,
    codice_istat: 16225,
    codice_belfiore: "L579",
  },
  {
    id: 7426,
    comune: "VALGRANA",
    sigla: "CN",
    codice_elettorale: 1010272330,
    codice_istat: 4234,
    codice_belfiore: "L580",
  },
  {
    id: 7427,
    comune: "VALGREGHENTINO",
    sigla: "LC",
    codice_elettorale: 1030980820,
    codice_istat: 97082,
    codice_belfiore: "L581",
  },
  {
    id: 7428,
    comune: "VALGRISENCHE",
    sigla: "AO",
    codice_elettorale: 1020040670,
    codice_istat: 7068,
    codice_belfiore: "L582",
  },
  {
    id: 7429,
    comune: "VALGUARNERA CAROPEPE",
    sigla: "EN",
    codice_elettorale: 4190280190,
    codice_istat: 86019,
    codice_belfiore: "L583",
  },
  {
    id: 7430,
    comune: "VALLADA AGORDINA",
    sigla: "BL",
    codice_elettorale: 1050100620,
    codice_istat: 25062,
    codice_belfiore: "L584",
  },
  {
    id: 7431,
    comune: "VALLANZENGO",
    sigla: "BI",
    codice_elettorale: 1010960720,
    codice_istat: 96072,
    codice_belfiore: "L586",
  },
  {
    id: 7432,
    comune: "VALLARSA",
    sigla: "TN",
    codice_elettorale: 1040831990,
    codice_istat: 22210,
    codice_belfiore: "L588",
  },
  {
    id: 7433,
    comune: "VALLATA",
    sigla: "AV",
    codice_elettorale: 3150081130,
    codice_istat: 64114,
    codice_belfiore: "L589",
  },
  {
    id: 7434,
    comune: "VALLE AGRICOLA",
    sigla: "CE",
    codice_elettorale: 3150200960,
    codice_istat: 61096,
    codice_belfiore: "L594",
  },
  {
    id: 7435,
    comune: "VALLE AURINA/AHRNTAL",
    sigla: "BZ",
    codice_elettorale: 1040141000,
    codice_istat: 21108,
    codice_belfiore: "L595",
  },
  {
    id: 7436,
    comune: "VALLE CANNOBINA",
    sigla: "VB",
    codice_elettorale: 1011020685,
    codice_istat: 103079,
    codice_belfiore: "M404",
  },
  {
    id: 7437,
    comune: "VALLE CASTELLANA",
    sigla: "TE",
    codice_elettorale: 3130790450,
    codice_istat: 67046,
    codice_belfiore: "L597",
  },
  {
    id: 7438,
    comune: "VALLE DELL'ANGELO",
    sigla: "SA",
    codice_elettorale: 3150721530,
    codice_istat: 65153,
    codice_belfiore: "G540",
  },
  {
    id: 7439,
    comune: "VALLE DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100630,
    codice_istat: 25063,
    codice_belfiore: "L590",
  },
  {
    id: 7440,
    comune: "VALLE DI CASIES/GSIES",
    sigla: "BZ",
    codice_elettorale: 1040141010,
    codice_istat: 21109,
    codice_belfiore: "L601",
  },
  {
    id: 7441,
    comune: "VALLE DI MADDALONI",
    sigla: "CE",
    codice_elettorale: 3150200970,
    codice_istat: 61097,
    codice_belfiore: "L591",
  },
  {
    id: 7442,
    comune: "VALLE LOMELLINA",
    sigla: "PV",
    codice_elettorale: 1030571650,
    codice_istat: 18168,
    codice_belfiore: "L593",
  },
  {
    id: 7443,
    comune: "VALLE SALIMBENE",
    sigla: "PV",
    codice_elettorale: 1030571660,
    codice_istat: 18169,
    codice_belfiore: "L617",
  },
  {
    id: 7444,
    comune: "VALLE SAN NICOLAO",
    sigla: "BI",
    codice_elettorale: 1010960740,
    codice_istat: 96074,
    codice_belfiore: "L620",
  },
  {
    id: 7445,
    comune: "VALLEBONA",
    sigla: "IM",
    codice_elettorale: 1070370580,
    codice_istat: 8062,
    codice_belfiore: "L596",
  },
  {
    id: 7446,
    comune: "VALLECORSA",
    sigla: "FR",
    codice_elettorale: 2120330810,
    codice_istat: 60082,
    codice_belfiore: "L598",
  },
  {
    id: 7447,
    comune: "VALLECROSIA",
    sigla: "IM",
    codice_elettorale: 1070370590,
    codice_istat: 8063,
    codice_belfiore: "L599",
  },
  {
    id: 7448,
    comune: "VALLEDOLMO",
    sigla: "PA",
    codice_elettorale: 4190550730,
    codice_istat: 82076,
    codice_belfiore: "L603",
  },
  {
    id: 7449,
    comune: "VALLEDORIA",
    sigla: "SS",
    codice_elettorale: 4200730741,
    codice_istat: 90079,
    codice_belfiore: "L604",
  },
  {
    id: 7450,
    comune: "VALLEFIORITA",
    sigla: "CZ",
    codice_elettorale: 3180221470,
    codice_istat: 79151,
    codice_belfiore: "I322",
  },
  {
    id: 7451,
    comune: "VALLEFOGLIA",
    sigla: "PU",
    codice_elettorale: 2110590680,
    codice_istat: 41068,
    codice_belfiore: "M331",
  },
  {
    id: 7452,
    comune: "VALLELAGHI",
    sigla: "TN",
    codice_elettorale: 1040831995,
    codice_istat: 22248,
    codice_belfiore: "M362",
  },
  {
    id: 7453,
    comune: "VALLELONGA",
    sigla: "VV",
    codice_elettorale: 3181030450,
    codice_istat: 102045,
    codice_belfiore: "L607",
  },
  {
    id: 7454,
    comune: "VALLELUNGA PRATAMENO",
    sigla: "CL",
    codice_elettorale: 4190180210,
    codice_istat: 85021,
    codice_belfiore: "L609",
  },
  {
    id: 7455,
    comune: "VALLEMAIO",
    sigla: "FR",
    codice_elettorale: 2120330820,
    codice_istat: 60083,
    codice_belfiore: "L605",
  },
  {
    id: 7456,
    comune: "VALLEPIETRA",
    sigla: "RM",
    codice_elettorale: 2120701070,
    codice_istat: 58108,
    codice_belfiore: "L611",
  },
  {
    id: 7457,
    comune: "VALLERANO",
    sigla: "VT",
    codice_elettorale: 2120910530,
    codice_istat: 56054,
    codice_belfiore: "L612",
  },
  {
    id: 7458,
    comune: "VALLERMOSA",
    sigla: "SU",
    codice_elettorale: 4201110950,
    codice_istat: 111095,
    codice_belfiore: "L613",
  },
  {
    id: 7459,
    comune: "VALLEROTONDA",
    sigla: "FR",
    codice_elettorale: 2120330830,
    codice_istat: 60084,
    codice_belfiore: "L614",
  },
  {
    id: 7460,
    comune: "VALLESACCARDA",
    sigla: "AV",
    codice_elettorale: 3150081131,
    codice_istat: 64115,
    codice_belfiore: "L616",
  },
  {
    id: 7461,
    comune: "VALLEVE",
    sigla: "BG",
    codice_elettorale: 1030122160,
    codice_istat: 16226,
    codice_belfiore: "L623",
  },
  {
    id: 7462,
    comune: "VALLI DEL PASUBIO",
    sigla: "VI",
    codice_elettorale: 1050901130,
    codice_istat: 24113,
    codice_belfiore: "L624",
  },
  {
    id: 7463,
    comune: "VALLINFREDA",
    sigla: "RM",
    codice_elettorale: 2120701080,
    codice_istat: 58109,
    codice_belfiore: "L625",
  },
  {
    id: 7464,
    comune: "VALLIO TERME",
    sigla: "BS",
    codice_elettorale: 1030151830,
    codice_istat: 17193,
    codice_belfiore: "L626",
  },
  {
    id: 7465,
    comune: "VALLO DELLA LUCANIA",
    sigla: "SA",
    codice_elettorale: 3150721540,
    codice_istat: 65154,
    codice_belfiore: "L628",
  },
  {
    id: 7466,
    comune: "VALLO DI NERA",
    sigla: "PG",
    codice_elettorale: 2100580580,
    codice_istat: 54058,
    codice_belfiore: "L627",
  },
  {
    id: 7467,
    comune: "VALLO TORINESE",
    sigla: "TO",
    codice_elettorale: 1010812741,
    codice_istat: 1286,
    codice_belfiore: "L629",
  },
  {
    id: 7468,
    comune: "VALLORIATE",
    sigla: "CN",
    codice_elettorale: 1010272340,
    codice_istat: 4235,
    codice_belfiore: "L631",
  },
  {
    id: 7469,
    comune: "VALMACCA",
    sigla: "AL",
    codice_elettorale: 1010021750,
    codice_istat: 6178,
    codice_belfiore: "L633",
  },
  {
    id: 7470,
    comune: "VALMADRERA",
    sigla: "LC",
    codice_elettorale: 1030980830,
    codice_istat: 97083,
    codice_belfiore: "L634",
  },
  {
    id: 7471,
    comune: "VALMONTONE",
    sigla: "RM",
    codice_elettorale: 2120701090,
    codice_istat: 58110,
    codice_belfiore: "L639",
  },
  {
    id: 7472,
    comune: "VALMOREA",
    sigla: "CO",
    codice_elettorale: 1030242180,
    codice_istat: 13232,
    codice_belfiore: "L640",
  },
  {
    id: 7473,
    comune: "VALMOZZOLA",
    sigla: "PR",
    codice_elettorale: 1080560440,
    codice_istat: 34044,
    codice_belfiore: "L641",
  },
  {
    id: 7474,
    comune: "VALNEGRA",
    sigla: "BG",
    codice_elettorale: 1030122161,
    codice_istat: 16227,
    codice_belfiore: "L642",
  },
  {
    id: 7475,
    comune: "VALPELLINE",
    sigla: "AO",
    codice_elettorale: 1020040680,
    codice_istat: 7069,
    codice_belfiore: "L643",
  },
  {
    id: 7476,
    comune: "VALPERGA",
    sigla: "TO",
    codice_elettorale: 1010812750,
    codice_istat: 1287,
    codice_belfiore: "L644",
  },
  {
    id: 7477,
    comune: "VALPRATO SOANA",
    sigla: "TO",
    codice_elettorale: 1010812760,
    codice_istat: 1288,
    codice_belfiore: "B510",
  },
  {
    id: 7478,
    comune: "VALSAMOGGIA",
    sigla: "BO",
    codice_elettorale: 1080130585,
    codice_istat: 37061,
    codice_belfiore: "M320",
  },
  {
    id: 7479,
    comune: "VALSAVARENCHE",
    sigla: "AO",
    codice_elettorale: 1020040690,
    codice_istat: 7070,
    codice_belfiore: "L647",
  },
  {
    id: 7480,
    comune: "VALSINNI",
    sigla: "MT",
    codice_elettorale: 3170470290,
    codice_istat: 77030,
    codice_belfiore: "D513",
  },
  {
    id: 7481,
    comune: "VALSOLDA",
    sigla: "CO",
    codice_elettorale: 1030242200,
    codice_istat: 13234,
    codice_belfiore: "C936",
  },
  {
    id: 7482,
    comune: "VALSTRONA",
    sigla: "VB",
    codice_elettorale: 1011020690,
    codice_istat: 103069,
    codice_belfiore: "L651",
  },
  {
    id: 7483,
    comune: "VALTOPINA",
    sigla: "PG",
    codice_elettorale: 2100580590,
    codice_istat: 54059,
    codice_belfiore: "L653",
  },
  {
    id: 7484,
    comune: "VALTORTA",
    sigla: "BG",
    codice_elettorale: 1030122180,
    codice_istat: 16229,
    codice_belfiore: "L655",
  },
  {
    id: 7485,
    comune: "VALTOURNENCHE",
    sigla: "AO",
    codice_elettorale: 1020040700,
    codice_istat: 7071,
    codice_belfiore: "L654",
  },
  {
    id: 7486,
    comune: "VALVA",
    sigla: "SA",
    codice_elettorale: 3150721550,
    codice_istat: 65155,
    codice_belfiore: "L656",
  },
  {
    id: 7487,
    comune: "VALVARRONE",
    sigla: "LC",
    codice_elettorale: 1030980835,
    codice_istat: 97093,
    codice_belfiore: "M395",
  },
  {
    id: 7488,
    comune: "VALVASONE ARZENE",
    sigla: "PN",
    codice_elettorale: 1060930476,
    codice_istat: 93053,
    codice_belfiore: "M346",
  },
  {
    id: 7489,
    comune: "VALVERDE",
    sigla: "CT",
    codice_elettorale: 4190210501,
    codice_istat: 87052,
    codice_belfiore: "L658",
  },
  {
    id: 7490,
    comune: "VALVESTINO",
    sigla: "BS",
    codice_elettorale: 1030151850,
    codice_istat: 17194,
    codice_belfiore: "L468",
  },
  {
    id: 7491,
    comune: "VANDOIES/VINTL",
    sigla: "BZ",
    codice_elettorale: 1040141020,
    codice_istat: 21110,
    codice_belfiore: "L660",
  },
  {
    id: 7492,
    comune: "VANZAGHELLO",
    sigla: "MI",
    codice_elettorale: 1030492271,
    codice_istat: 15249,
    codice_belfiore: "L664",
  },
  {
    id: 7493,
    comune: "VANZAGO",
    sigla: "MI",
    codice_elettorale: 1030492280,
    codice_istat: 15229,
    codice_belfiore: "L665",
  },
  {
    id: 7494,
    comune: "VANZONE CON SAN CARLO",
    sigla: "VB",
    codice_elettorale: 1011020700,
    codice_istat: 103070,
    codice_belfiore: "L666",
  },
  {
    id: 7495,
    comune: "VAPRIO D'ADDA",
    sigla: "MI",
    codice_elettorale: 1030492290,
    codice_istat: 15230,
    codice_belfiore: "L667",
  },
  {
    id: 7496,
    comune: "VAPRIO D'AGOGNA",
    sigla: "NO",
    codice_elettorale: 1010521460,
    codice_istat: 3153,
    codice_belfiore: "L668",
  },
  {
    id: 7497,
    comune: "VARALLO",
    sigla: "VC",
    codice_elettorale: 1010881540,
    codice_istat: 2156,
    codice_belfiore: "L669",
  },
  {
    id: 7498,
    comune: "VARALLO POMBIA",
    sigla: "NO",
    codice_elettorale: 1010521470,
    codice_istat: 3154,
    codice_belfiore: "L670",
  },
  {
    id: 7499,
    comune: "VARANO BORGHI",
    sigla: "VA",
    codice_elettorale: 1030861150,
    codice_istat: 12132,
    codice_belfiore: "L671",
  },
  {
    id: 7500,
    comune: "VARANO DE' MELEGARI",
    sigla: "PR",
    codice_elettorale: 1080560450,
    codice_istat: 34045,
    codice_belfiore: "L672",
  },
  {
    id: 7501,
    comune: "VARAPODIO",
    sigla: "RC",
    codice_elettorale: 3180670950,
    codice_istat: 80095,
    codice_belfiore: "L673",
  },
  {
    id: 7502,
    comune: "VARAZZE",
    sigla: "SV",
    codice_elettorale: 1070740650,
    codice_istat: 9065,
    codice_belfiore: "L675",
  },
  {
    id: 7503,
    comune: "VARCO SABINO",
    sigla: "RI",
    codice_elettorale: 2120690710,
    codice_istat: 57073,
    codice_belfiore: "L676",
  },
  {
    id: 7504,
    comune: "VAREDO",
    sigla: "MB",
    codice_elettorale: 1031040450,
    codice_istat: 108045,
    codice_belfiore: "L677",
  },
  {
    id: 7505,
    comune: "VARENNA",
    sigla: "LC",
    codice_elettorale: 1030980840,
    codice_istat: 97084,
    codice_belfiore: "L680",
  },
  {
    id: 7506,
    comune: "VARESE",
    sigla: "VA",
    codice_elettorale: 1030861160,
    codice_istat: 12133,
    codice_belfiore: "L682",
  },
  {
    id: 7507,
    comune: "VARESE LIGURE",
    sigla: "SP",
    codice_elettorale: 1070390290,
    codice_istat: 11029,
    codice_belfiore: "L681",
  },
  {
    id: 7508,
    comune: "VARISELLA",
    sigla: "TO",
    codice_elettorale: 1010812761,
    codice_istat: 1289,
    codice_belfiore: "L685",
  },
  {
    id: 7509,
    comune: "VARMO",
    sigla: "UD",
    codice_elettorale: 1060851300,
    codice_istat: 30130,
    codice_belfiore: "L686",
  },
  {
    id: 7510,
    comune: "VARNA/VAHRN",
    sigla: "BZ",
    codice_elettorale: 1040141030,
    codice_istat: 21111,
    codice_belfiore: "L687",
  },
  {
    id: 7511,
    comune: "VARSI",
    sigla: "PR",
    codice_elettorale: 1080560460,
    codice_istat: 34046,
    codice_belfiore: "L689",
  },
  {
    id: 7512,
    comune: "VARZI",
    sigla: "PV",
    codice_elettorale: 1030571670,
    codice_istat: 18171,
    codice_belfiore: "L690",
  },
  {
    id: 7513,
    comune: "VARZO",
    sigla: "VB",
    codice_elettorale: 1011020710,
    codice_istat: 103071,
    codice_belfiore: "L691",
  },
  {
    id: 7514,
    comune: "VASANELLO",
    sigla: "VT",
    codice_elettorale: 2120910540,
    codice_istat: 56055,
    codice_belfiore: "A701",
  },
  {
    id: 7515,
    comune: "VASIA",
    sigla: "IM",
    codice_elettorale: 1070370600,
    codice_istat: 8064,
    codice_belfiore: "L693",
  },
  {
    id: 7516,
    comune: "VASTO",
    sigla: "CH",
    codice_elettorale: 3130230990,
    codice_istat: 69099,
    codice_belfiore: "E372",
  },
  {
    id: 7517,
    comune: "VASTOGIRARDI",
    sigla: "IS",
    codice_elettorale: 3140940510,
    codice_istat: 94051,
    codice_belfiore: "L696",
  },
  {
    id: 7518,
    comune: "VAUDA CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812770,
    codice_istat: 1290,
    codice_belfiore: "L698",
  },
  {
    id: 7519,
    comune: "VAZZANO",
    sigla: "VV",
    codice_elettorale: 3181030460,
    codice_istat: 102046,
    codice_belfiore: "L699",
  },
  {
    id: 7520,
    comune: "VAZZOLA",
    sigla: "TV",
    codice_elettorale: 1050840870,
    codice_istat: 26088,
    codice_belfiore: "L700",
  },
  {
    id: 7521,
    comune: "VECCHIANO",
    sigla: "PI",
    codice_elettorale: 2090620360,
    codice_istat: 50037,
    codice_belfiore: "L702",
  },
  {
    id: 7522,
    comune: "VEDANO AL LAMBRO",
    sigla: "MB",
    codice_elettorale: 1031040460,
    codice_istat: 108046,
    codice_belfiore: "L704",
  },
  {
    id: 7523,
    comune: "VEDANO OLONA",
    sigla: "VA",
    codice_elettorale: 1030861170,
    codice_istat: 12134,
    codice_belfiore: "L703",
  },
  {
    id: 7524,
    comune: "VEDELAGO",
    sigla: "TV",
    codice_elettorale: 1050840880,
    codice_istat: 26089,
    codice_belfiore: "L706",
  },
  {
    id: 7525,
    comune: "VEDESETA",
    sigla: "BG",
    codice_elettorale: 1030122190,
    codice_istat: 16230,
    codice_belfiore: "L707",
  },
  {
    id: 7526,
    comune: "VEDUGGIO CON COLZANO",
    sigla: "MB",
    codice_elettorale: 1031040470,
    codice_istat: 108047,
    codice_belfiore: "L709",
  },
  {
    id: 7527,
    comune: "VEGGIANO",
    sigla: "PD",
    codice_elettorale: 1050540960,
    codice_istat: 28096,
    codice_belfiore: "L710",
  },
  {
    id: 7528,
    comune: "VEGLIE",
    sigla: "LE",
    codice_elettorale: 3160410910,
    codice_istat: 75092,
    codice_belfiore: "L711",
  },
  {
    id: 7529,
    comune: "VEGLIO",
    sigla: "BI",
    codice_elettorale: 1010960750,
    codice_istat: 96075,
    codice_belfiore: "L712",
  },
  {
    id: 7530,
    comune: "VEJANO",
    sigla: "VT",
    codice_elettorale: 2120910550,
    codice_istat: 56056,
    codice_belfiore: "L713",
  },
  {
    id: 7531,
    comune: "VELESO",
    sigla: "CO",
    codice_elettorale: 1030242220,
    codice_istat: 13236,
    codice_belfiore: "L715",
  },
  {
    id: 7532,
    comune: "VELEZZO LOMELLINA",
    sigla: "PV",
    codice_elettorale: 1030571680,
    codice_istat: 18172,
    codice_belfiore: "L716",
  },
  {
    id: 7533,
    comune: "VELLETRI",
    sigla: "RM",
    codice_elettorale: 2120701100,
    codice_istat: 58111,
    codice_belfiore: "L719",
  },
  {
    id: 7534,
    comune: "VELLEZZO BELLINI",
    sigla: "PV",
    codice_elettorale: 1030571690,
    codice_istat: 18173,
    codice_belfiore: "L720",
  },
  {
    id: 7535,
    comune: "VELO D'ASTICO",
    sigla: "VI",
    codice_elettorale: 1050901150,
    codice_istat: 24115,
    codice_belfiore: "L723",
  },
  {
    id: 7536,
    comune: "VELO VERONESE",
    sigla: "VR",
    codice_elettorale: 1050890890,
    codice_istat: 23090,
    codice_belfiore: "L722",
  },
  {
    id: 7537,
    comune: "VELTURNO/FELDTHURNS",
    sigla: "BZ",
    codice_elettorale: 1040141031,
    codice_istat: 21116,
    codice_belfiore: "L724",
  },
  {
    id: 7538,
    comune: "VENAFRO",
    sigla: "IS",
    codice_elettorale: 3140940520,
    codice_istat: 94052,
    codice_belfiore: "L725",
  },
  {
    id: 7539,
    comune: "VENARIA REALE",
    sigla: "TO",
    codice_elettorale: 1010812790,
    codice_istat: 1292,
    codice_belfiore: "L727",
  },
  {
    id: 7540,
    comune: "VENAROTTA",
    sigla: "AP",
    codice_elettorale: 2110060720,
    codice_istat: 44073,
    codice_belfiore: "L728",
  },
  {
    id: 7541,
    comune: "VENASCA",
    sigla: "CN",
    codice_elettorale: 1010272360,
    codice_istat: 4237,
    codice_belfiore: "L729",
  },
  {
    id: 7542,
    comune: "VENAUS",
    sigla: "TO",
    codice_elettorale: 1010812791,
    codice_istat: 1291,
    codice_belfiore: "L726",
  },
  {
    id: 7543,
    comune: "VENDONE",
    sigla: "SV",
    codice_elettorale: 1070740660,
    codice_istat: 9066,
    codice_belfiore: "L730",
  },
  {
    id: 7544,
    comune: "VENEGONO INFERIORE",
    sigla: "VA",
    codice_elettorale: 1030861181,
    codice_istat: 12136,
    codice_belfiore: "L733",
  },
  {
    id: 7545,
    comune: "VENEGONO SUPERIORE",
    sigla: "VA",
    codice_elettorale: 1030861190,
    codice_istat: 12137,
    codice_belfiore: "L734",
  },
  {
    id: 7546,
    comune: "VENETICO",
    sigla: "ME",
    codice_elettorale: 4190481020,
    codice_istat: 83104,
    codice_belfiore: "L735",
  },
  {
    id: 7547,
    comune: "VENEZIA",
    sigla: "VE",
    codice_elettorale: 1050870420,
    codice_istat: 27042,
    codice_belfiore: "L736",
  },
  {
    id: 7548,
    comune: "VENIANO",
    sigla: "CO",
    codice_elettorale: 1030242240,
    codice_istat: 13238,
    codice_belfiore: "L737",
  },
  {
    id: 7549,
    comune: "VENOSA",
    sigla: "PZ",
    codice_elettorale: 3170640940,
    codice_istat: 76095,
    codice_belfiore: "L738",
  },
  {
    id: 7550,
    comune: "VENTASSO",
    sigla: "RE",
    codice_elettorale: 1080680415,
    codice_istat: 35046,
    codice_belfiore: "M364",
  },
  {
    id: 7551,
    comune: "VENTICANO",
    sigla: "AV",
    codice_elettorale: 3150081140,
    codice_istat: 64116,
    codice_belfiore: "L739",
  },
  {
    id: 7552,
    comune: "VENTIMIGLIA",
    sigla: "IM",
    codice_elettorale: 1070370610,
    codice_istat: 8065,
    codice_belfiore: "L741",
  },
  {
    id: 7553,
    comune: "VENTIMIGLIA DI SICILIA",
    sigla: "PA",
    codice_elettorale: 4190550740,
    codice_istat: 82077,
    codice_belfiore: "L740",
  },
  {
    id: 7554,
    comune: "VENTOTENE",
    sigla: "LT",
    codice_elettorale: 2120400330,
    codice_istat: 59033,
    codice_belfiore: "L742",
  },
  {
    id: 7555,
    comune: "VENZONE",
    sigla: "UD",
    codice_elettorale: 1060851310,
    codice_istat: 30131,
    codice_belfiore: "L743",
  },
  {
    id: 7556,
    comune: "VERANO BRIANZA",
    sigla: "MB",
    codice_elettorale: 1031040480,
    codice_istat: 108048,
    codice_belfiore: "L744",
  },
  {
    id: 7557,
    comune: "VERANO/VORAN",
    sigla: "BZ",
    codice_elettorale: 1040141040,
    codice_istat: 21112,
    codice_belfiore: "L745",
  },
  {
    id: 7558,
    comune: "VERBANIA",
    sigla: "VB",
    codice_elettorale: 1011020720,
    codice_istat: 103072,
    codice_belfiore: "L746",
  },
  {
    id: 7559,
    comune: "VERBICARO",
    sigla: "CS",
    codice_elettorale: 3180251530,
    codice_istat: 78153,
    codice_belfiore: "L747",
  },
  {
    id: 7560,
    comune: "VERCANA",
    sigla: "CO",
    codice_elettorale: 1030242250,
    codice_istat: 13239,
    codice_belfiore: "L748",
  },
  {
    id: 7561,
    comune: "VERCEIA",
    sigla: "SO",
    codice_elettorale: 1030770750,
    codice_istat: 14075,
    codice_belfiore: "L749",
  },
  {
    id: 7562,
    comune: "VERCELLI",
    sigla: "VC",
    codice_elettorale: 1010881560,
    codice_istat: 2158,
    codice_belfiore: "L750",
  },
  {
    id: 7563,
    comune: "VERCURAGO",
    sigla: "LC",
    codice_elettorale: 1030980860,
    codice_istat: 97086,
    codice_belfiore: "L751",
  },
  {
    id: 7564,
    comune: "VERDELLINO",
    sigla: "BG",
    codice_elettorale: 1030122210,
    codice_istat: 16232,
    codice_belfiore: "L752",
  },
  {
    id: 7565,
    comune: "VERDELLO",
    sigla: "BG",
    codice_elettorale: 1030122220,
    codice_istat: 16233,
    codice_belfiore: "L753",
  },
  {
    id: 7566,
    comune: "VERDERIO",
    sigla: "LC",
    codice_elettorale: 1030980873,
    codice_istat: 97091,
    codice_belfiore: "M337",
  },
  {
    id: 7567,
    comune: "VERDUNO",
    sigla: "CN",
    codice_elettorale: 1010272370,
    codice_istat: 4238,
    codice_belfiore: "L758",
  },
  {
    id: 7568,
    comune: "VERGATO",
    sigla: "BO",
    codice_elettorale: 1080130590,
    codice_istat: 37059,
    codice_belfiore: "L762",
  },
  {
    id: 7569,
    comune: "VERGHERETO",
    sigla: "FC",
    codice_elettorale: 1080320490,
    codice_istat: 40050,
    codice_belfiore: "L764",
  },
  {
    id: 7570,
    comune: "VERGIATE",
    sigla: "VA",
    codice_elettorale: 1030861200,
    codice_istat: 12138,
    codice_belfiore: "L765",
  },
  {
    id: 7571,
    comune: "VERMEZZO CON ZELO",
    sigla: "MI",
    codice_elettorale: 1030492335,
    codice_istat: 15251,
    codice_belfiore: "M424",
  },
  {
    id: 7572,
    comune: "VERMIGLIO",
    sigla: "TN",
    codice_elettorale: 1040832020,
    codice_istat: 22213,
    codice_belfiore: "L769",
  },
  {
    id: 7573,
    comune: "VERNANTE",
    sigla: "CN",
    codice_elettorale: 1010272380,
    codice_istat: 4239,
    codice_belfiore: "L771",
  },
  {
    id: 7574,
    comune: "VERNASCA",
    sigla: "PC",
    codice_elettorale: 1080610440,
    codice_istat: 33044,
    codice_belfiore: "L772",
  },
  {
    id: 7575,
    comune: "VERNATE",
    sigla: "MI",
    codice_elettorale: 1030492340,
    codice_istat: 15236,
    codice_belfiore: "L773",
  },
  {
    id: 7576,
    comune: "VERNAZZA",
    sigla: "SP",
    codice_elettorale: 1070390300,
    codice_istat: 11030,
    codice_belfiore: "L774",
  },
  {
    id: 7577,
    comune: "VERNIO",
    sigla: "PO",
    codice_elettorale: 2091000070,
    codice_istat: 100007,
    codice_belfiore: "L775",
  },
  {
    id: 7578,
    comune: "VERNOLE",
    sigla: "LE",
    codice_elettorale: 3160410920,
    codice_istat: 75093,
    codice_belfiore: "L776",
  },
  {
    id: 7579,
    comune: "VEROLANUOVA",
    sigla: "BS",
    codice_elettorale: 1030151860,
    codice_istat: 17195,
    codice_belfiore: "L777",
  },
  {
    id: 7580,
    comune: "VEROLAVECCHIA",
    sigla: "BS",
    codice_elettorale: 1030151870,
    codice_istat: 17196,
    codice_belfiore: "L778",
  },
  {
    id: 7581,
    comune: "VEROLENGO",
    sigla: "TO",
    codice_elettorale: 1010812800,
    codice_istat: 1293,
    codice_belfiore: "L779",
  },
  {
    id: 7582,
    comune: "VEROLI",
    sigla: "FR",
    codice_elettorale: 2120330840,
    codice_istat: 60085,
    codice_belfiore: "L780",
  },
  {
    id: 7583,
    comune: "VERONA",
    sigla: "VR",
    codice_elettorale: 1050890900,
    codice_istat: 23091,
    codice_belfiore: "L781",
  },
  {
    id: 7584,
    comune: "VERONELLA",
    sigla: "VR",
    codice_elettorale: 1050890910,
    codice_istat: 23092,
    codice_belfiore: "D193",
  },
  {
    id: 7585,
    comune: "VERRAYES",
    sigla: "AO",
    codice_elettorale: 1020040710,
    codice_istat: 7072,
    codice_belfiore: "L783",
  },
  {
    id: 7586,
    comune: "VERRETTO",
    sigla: "PV",
    codice_elettorale: 1030571700,
    codice_istat: 18174,
    codice_belfiore: "L784",
  },
  {
    id: 7587,
    comune: "VERRONE",
    sigla: "BI",
    codice_elettorale: 1010960760,
    codice_istat: 96076,
    codice_belfiore: "L785",
  },
  {
    id: 7588,
    comune: "VERRUA PO",
    sigla: "PV",
    codice_elettorale: 1030571710,
    codice_istat: 18175,
    codice_belfiore: "L788",
  },
  {
    id: 7589,
    comune: "VERRUA SAVOIA",
    sigla: "TO",
    codice_elettorale: 1010812810,
    codice_istat: 1294,
    codice_belfiore: "L787",
  },
  {
    id: 7590,
    comune: "VERRES",
    sigla: "AO",
    codice_elettorale: 1020040720,
    codice_istat: 7073,
    codice_belfiore: "C282",
  },
  {
    id: 7591,
    comune: "VERTEMATE CON MINOPRIO",
    sigla: "CO",
    codice_elettorale: 1030242280,
    codice_istat: 13242,
    codice_belfiore: "L792",
  },
  {
    id: 7592,
    comune: "VERTOVA",
    sigla: "BG",
    codice_elettorale: 1030122230,
    codice_istat: 16234,
    codice_belfiore: "L795",
  },
  {
    id: 7593,
    comune: "VERUCCHIO",
    sigla: "RN",
    codice_elettorale: 1081010200,
    codice_istat: 99020,
    codice_belfiore: "L797",
  },
  {
    id: 7594,
    comune: "VERVIO",
    sigla: "SO",
    codice_elettorale: 1030770760,
    codice_istat: 14076,
    codice_belfiore: "L799",
  },
  {
    id: 7595,
    comune: "VERZEGNIS",
    sigla: "UD",
    codice_elettorale: 1060851320,
    codice_istat: 30132,
    codice_belfiore: "L801",
  },
  {
    id: 7596,
    comune: "VERZINO",
    sigla: "KR",
    codice_elettorale: 3180970270,
    codice_istat: 101027,
    codice_belfiore: "L802",
  },
  {
    id: 7597,
    comune: "VERZUOLO",
    sigla: "CN",
    codice_elettorale: 1010272390,
    codice_istat: 4240,
    codice_belfiore: "L804",
  },
  {
    id: 7598,
    comune: "VESCOVANA",
    sigla: "PD",
    codice_elettorale: 1050540970,
    codice_istat: 28097,
    codice_belfiore: "L805",
  },
  {
    id: 7599,
    comune: "VESCOVATO",
    sigla: "CR",
    codice_elettorale: 1030261100,
    codice_istat: 19113,
    codice_belfiore: "L806",
  },
  {
    id: 7600,
    comune: "VESIME",
    sigla: "AT",
    codice_elettorale: 1010071130,
    codice_istat: 5113,
    codice_belfiore: "L807",
  },
  {
    id: 7601,
    comune: "VESPOLATE",
    sigla: "NO",
    codice_elettorale: 1010521510,
    codice_istat: 3158,
    codice_belfiore: "L808",
  },
  {
    id: 7602,
    comune: "VESSALICO",
    sigla: "IM",
    codice_elettorale: 1070370620,
    codice_istat: 8066,
    codice_belfiore: "L809",
  },
  {
    id: 7603,
    comune: "VESTENANOVA",
    sigla: "VR",
    codice_elettorale: 1050890920,
    codice_istat: 23093,
    codice_belfiore: "L810",
  },
  {
    id: 7604,
    comune: "VESTIGNE'",
    sigla: "TO",
    codice_elettorale: 1010812820,
    codice_istat: 1295,
    codice_belfiore: "L811",
  },
  {
    id: 7605,
    comune: "VESTONE",
    sigla: "BS",
    codice_elettorale: 1030151880,
    codice_istat: 17197,
    codice_belfiore: "L812",
  },
  {
    id: 7606,
    comune: "VETRALLA",
    sigla: "VT",
    codice_elettorale: 2120910560,
    codice_istat: 56057,
    codice_belfiore: "L814",
  },
  {
    id: 7607,
    comune: "VETTO",
    sigla: "RE",
    codice_elettorale: 1080680420,
    codice_istat: 35042,
    codice_belfiore: "L815",
  },
  {
    id: 7608,
    comune: "VEZZA D'ALBA",
    sigla: "CN",
    codice_elettorale: 1010272400,
    codice_istat: 4241,
    codice_belfiore: "L817",
  },
  {
    id: 7609,
    comune: "VEZZA D'OGLIO",
    sigla: "BS",
    codice_elettorale: 1030151890,
    codice_istat: 17198,
    codice_belfiore: "L816",
  },
  {
    id: 7610,
    comune: "VEZZANO LIGURE",
    sigla: "SP",
    codice_elettorale: 1070390310,
    codice_istat: 11031,
    codice_belfiore: "L819",
  },
  {
    id: 7611,
    comune: "VEZZANO SUL CROSTOLO",
    sigla: "RE",
    codice_elettorale: 1080680430,
    codice_istat: 35043,
    codice_belfiore: "L820",
  },
  {
    id: 7612,
    comune: "VEZZI PORTIO",
    sigla: "SV",
    codice_elettorale: 1070740670,
    codice_istat: 9067,
    codice_belfiore: "L823",
  },
  {
    id: 7613,
    comune: "VIADANA",
    sigla: "MN",
    codice_elettorale: 1030450660,
    codice_istat: 20066,
    codice_belfiore: "L826",
  },
  {
    id: 7614,
    comune: "VIADANICA",
    sigla: "BG",
    codice_elettorale: 1030122240,
    codice_istat: 16235,
    codice_belfiore: "L827",
  },
  {
    id: 7615,
    comune: "VIAGRANDE",
    sigla: "CT",
    codice_elettorale: 4190210510,
    codice_istat: 87053,
    codice_belfiore: "L828",
  },
  {
    id: 7616,
    comune: "VIALE",
    sigla: "AT",
    codice_elettorale: 1010071140,
    codice_istat: 5114,
    codice_belfiore: "L829",
  },
  {
    id: 7617,
    comune: "VIALFRE'",
    sigla: "TO",
    codice_elettorale: 1010812830,
    codice_istat: 1296,
    codice_belfiore: "L830",
  },
  {
    id: 7618,
    comune: "VIANO",
    sigla: "RE",
    codice_elettorale: 1080680440,
    codice_istat: 35044,
    codice_belfiore: "L831",
  },
  {
    id: 7619,
    comune: "VIAREGGIO",
    sigla: "LU",
    codice_elettorale: 2090430330,
    codice_istat: 46033,
    codice_belfiore: "L833",
  },
  {
    id: 7620,
    comune: "VIARIGI",
    sigla: "AT",
    codice_elettorale: 1010071150,
    codice_istat: 5115,
    codice_belfiore: "L834",
  },
  {
    id: 7621,
    comune: "VIBO VALENTIA",
    sigla: "VV",
    codice_elettorale: 3181030470,
    codice_istat: 102047,
    codice_belfiore: "F537",
  },
  {
    id: 7622,
    comune: "VIBONATI",
    sigla: "SA",
    codice_elettorale: 3150721560,
    codice_istat: 65156,
    codice_belfiore: "L835",
  },
  {
    id: 7623,
    comune: "VICALVI",
    sigla: "FR",
    codice_elettorale: 2120330850,
    codice_istat: 60086,
    codice_belfiore: "L836",
  },
  {
    id: 7624,
    comune: "VICARI",
    sigla: "PA",
    codice_elettorale: 4190550750,
    codice_istat: 82078,
    codice_belfiore: "L837",
  },
  {
    id: 7625,
    comune: "VICCHIO",
    sigla: "FI",
    codice_elettorale: 2090300490,
    codice_istat: 48049,
    codice_belfiore: "L838",
  },
  {
    id: 7626,
    comune: "VICENZA",
    sigla: "VI",
    codice_elettorale: 1050901160,
    codice_istat: 24116,
    codice_belfiore: "L840",
  },
  {
    id: 7627,
    comune: "VICO DEL GARGANO",
    sigla: "FG",
    codice_elettorale: 3160310570,
    codice_istat: 71059,
    codice_belfiore: "L842",
  },
  {
    id: 7628,
    comune: "VICO EQUENSE",
    sigla: "NA",
    codice_elettorale: 3150510860,
    codice_istat: 63086,
    codice_belfiore: "L845",
  },
  {
    id: 7629,
    comune: "VICO NEL LAZIO",
    sigla: "FR",
    codice_elettorale: 2120330860,
    codice_istat: 60087,
    codice_belfiore: "L843",
  },
  {
    id: 7630,
    comune: "VICOFORTE",
    sigla: "CN",
    codice_elettorale: 1010272410,
    codice_istat: 4242,
    codice_belfiore: "L841",
  },
  {
    id: 7631,
    comune: "VICOLI",
    sigla: "PE",
    codice_elettorale: 3130600450,
    codice_istat: 68045,
    codice_belfiore: "L846",
  },
  {
    id: 7632,
    comune: "VICOLUNGO",
    sigla: "NO",
    codice_elettorale: 1010521520,
    codice_istat: 3159,
    codice_belfiore: "L847",
  },
  {
    id: 7633,
    comune: "VICOPISANO",
    sigla: "PI",
    codice_elettorale: 2090620370,
    codice_istat: 50038,
    codice_belfiore: "L850",
  },
  {
    id: 7634,
    comune: "VICOVARO",
    sigla: "RM",
    codice_elettorale: 2120701110,
    codice_istat: 58112,
    codice_belfiore: "L851",
  },
  {
    id: 7635,
    comune: "VIDDALBA",
    sigla: "SS",
    codice_elettorale: 4200730742,
    codice_istat: 90082,
    codice_belfiore: "M259",
  },
  {
    id: 7636,
    comune: "VIDIGULFO",
    sigla: "PV",
    codice_elettorale: 1030571720,
    codice_istat: 18176,
    codice_belfiore: "L854",
  },
  {
    id: 7637,
    comune: "VIDOR",
    sigla: "TV",
    codice_elettorale: 1050840890,
    codice_istat: 26090,
    codice_belfiore: "L856",
  },
  {
    id: 7638,
    comune: "VIDRACCO",
    sigla: "TO",
    codice_elettorale: 1010812850,
    codice_istat: 1298,
    codice_belfiore: "L857",
  },
  {
    id: 7639,
    comune: "VIESTE",
    sigla: "FG",
    codice_elettorale: 3160310580,
    codice_istat: 71060,
    codice_belfiore: "L858",
  },
  {
    id: 7640,
    comune: "VIETRI DI POTENZA",
    sigla: "PZ",
    codice_elettorale: 3170640950,
    codice_istat: 76096,
    codice_belfiore: "L859",
  },
  {
    id: 7641,
    comune: "VIETRI SUL MARE",
    sigla: "SA",
    codice_elettorale: 3150721570,
    codice_istat: 65157,
    codice_belfiore: "L860",
  },
  {
    id: 7642,
    comune: "VIGANO SAN MARTINO",
    sigla: "BG",
    codice_elettorale: 1030122250,
    codice_istat: 16236,
    codice_belfiore: "L865",
  },
  {
    id: 7643,
    comune: "VIGANO'",
    sigla: "LC",
    codice_elettorale: 1030980900,
    codice_istat: 97090,
    codice_belfiore: "L866",
  },
  {
    id: 7644,
    comune: "VIGARANO MAINARDA",
    sigla: "FE",
    codice_elettorale: 1080290200,
    codice_istat: 38022,
    codice_belfiore: "L868",
  },
  {
    id: 7645,
    comune: "VIGASIO",
    sigla: "VR",
    codice_elettorale: 1050890930,
    codice_istat: 23094,
    codice_belfiore: "L869",
  },
  {
    id: 7646,
    comune: "VIGEVANO",
    sigla: "PV",
    codice_elettorale: 1030571730,
    codice_istat: 18177,
    codice_belfiore: "L872",
  },
  {
    id: 7647,
    comune: "VIGGIANELLO",
    sigla: "PZ",
    codice_elettorale: 3170640960,
    codice_istat: 76097,
    codice_belfiore: "L873",
  },
  {
    id: 7648,
    comune: "VIGGIANO",
    sigla: "PZ",
    codice_elettorale: 3170640970,
    codice_istat: 76098,
    codice_belfiore: "L874",
  },
  {
    id: 7649,
    comune: "VIGGIU'",
    sigla: "VA",
    codice_elettorale: 1030861210,
    codice_istat: 12139,
    codice_belfiore: "L876",
  },
  {
    id: 7650,
    comune: "VIGHIZZOLO D'ESTE",
    sigla: "PD",
    codice_elettorale: 1050540980,
    codice_istat: 28098,
    codice_belfiore: "L878",
  },
  {
    id: 7651,
    comune: "VIGLIANO BIELLESE",
    sigla: "BI",
    codice_elettorale: 1010960770,
    codice_istat: 96077,
    codice_belfiore: "L880",
  },
  {
    id: 7652,
    comune: "VIGLIANO D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010071160,
    codice_istat: 5116,
    codice_belfiore: "L879",
  },
  {
    id: 7653,
    comune: "VIGNALE MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021760,
    codice_istat: 6179,
    codice_belfiore: "L881",
  },
  {
    id: 7654,
    comune: "VIGNANELLO",
    sigla: "VT",
    codice_elettorale: 2120910570,
    codice_istat: 56058,
    codice_belfiore: "L882",
  },
  {
    id: 7655,
    comune: "VIGNATE",
    sigla: "MI",
    codice_elettorale: 1030492350,
    codice_istat: 15237,
    codice_belfiore: "L883",
  },
  {
    id: 7656,
    comune: "VIGNOLA",
    sigla: "MO",
    codice_elettorale: 1080500450,
    codice_istat: 36046,
    codice_belfiore: "L885",
  },
  {
    id: 7657,
    comune: "VIGNOLA-FALESINA",
    sigla: "TN",
    codice_elettorale: 1040832041,
    codice_istat: 22216,
    codice_belfiore: "L886",
  },
  {
    id: 7658,
    comune: "VIGNOLE BORBERA",
    sigla: "AL",
    codice_elettorale: 1010021770,
    codice_istat: 6180,
    codice_belfiore: "L887",
  },
  {
    id: 7659,
    comune: "VIGNOLO",
    sigla: "CN",
    codice_elettorale: 1010272420,
    codice_istat: 4243,
    codice_belfiore: "L888",
  },
  {
    id: 7660,
    comune: "VIGNONE",
    sigla: "VB",
    codice_elettorale: 1011020740,
    codice_istat: 103074,
    codice_belfiore: "L889",
  },
  {
    id: 7661,
    comune: "VIGO DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100650,
    codice_istat: 25065,
    codice_belfiore: "L890",
  },
  {
    id: 7662,
    comune: "VIGODARZERE",
    sigla: "PD",
    codice_elettorale: 1050540990,
    codice_istat: 28099,
    codice_belfiore: "L892",
  },
  {
    id: 7663,
    comune: "VIGOLO",
    sigla: "BG",
    codice_elettorale: 1030122260,
    codice_istat: 16237,
    codice_belfiore: "L894",
  },
  {
    id: 7664,
    comune: "VIGOLZONE",
    sigla: "PC",
    codice_elettorale: 1080610450,
    codice_istat: 33045,
    codice_belfiore: "L897",
  },
  {
    id: 7665,
    comune: "VIGONE",
    sigla: "TO",
    codice_elettorale: 1010812860,
    codice_istat: 1299,
    codice_belfiore: "L898",
  },
  {
    id: 7666,
    comune: "VIGONOVO",
    sigla: "VE",
    codice_elettorale: 1050870430,
    codice_istat: 27043,
    codice_belfiore: "L899",
  },
  {
    id: 7667,
    comune: "VIGONZA",
    sigla: "PD",
    codice_elettorale: 1050541000,
    codice_istat: 28100,
    codice_belfiore: "L900",
  },
  {
    id: 7668,
    comune: "VIGUZZOLO",
    sigla: "AL",
    codice_elettorale: 1010021780,
    codice_istat: 6181,
    codice_belfiore: "L904",
  },
  {
    id: 7669,
    comune: "VILLA BARTOLOMEA",
    sigla: "VR",
    codice_elettorale: 1050890940,
    codice_istat: 23095,
    codice_belfiore: "L912",
  },
  {
    id: 7670,
    comune: "VILLA BASILICA",
    sigla: "LU",
    codice_elettorale: 2090430340,
    codice_istat: 46034,
    codice_belfiore: "L913",
  },
  {
    id: 7671,
    comune: "VILLA BISCOSSI",
    sigla: "PV",
    codice_elettorale: 1030571740,
    codice_istat: 18178,
    codice_belfiore: "L917",
  },
  {
    id: 7672,
    comune: "VILLA CARCINA",
    sigla: "BS",
    codice_elettorale: 1030151900,
    codice_istat: 17199,
    codice_belfiore: "L919",
  },
  {
    id: 7673,
    comune: "VILLA CASTELLI",
    sigla: "BR",
    codice_elettorale: 3160160200,
    codice_istat: 74020,
    codice_belfiore: "L920",
  },
  {
    id: 7674,
    comune: "VILLA CELIERA",
    sigla: "PE",
    codice_elettorale: 3130600460,
    codice_istat: 68046,
    codice_belfiore: "L922",
  },
  {
    id: 7675,
    comune: "VILLA COLLEMANDINA",
    sigla: "LU",
    codice_elettorale: 2090430350,
    codice_istat: 46035,
    codice_belfiore: "L926",
  },
  {
    id: 7676,
    comune: "VILLA CORTESE",
    sigla: "MI",
    codice_elettorale: 1030492351,
    codice_istat: 15248,
    codice_belfiore: "L928",
  },
  {
    id: 7677,
    comune: "VILLA D'ADDA",
    sigla: "BG",
    codice_elettorale: 1030122270,
    codice_istat: 16238,
    codice_belfiore: "L929",
  },
  {
    id: 7678,
    comune: "VILLA D'ALME'",
    sigla: "BG",
    codice_elettorale: 1030122271,
    codice_istat: 16239,
    codice_belfiore: "A215",
  },
  {
    id: 7679,
    comune: "VILLA D'OGNA",
    sigla: "BG",
    codice_elettorale: 1030122290,
    codice_istat: 16241,
    codice_belfiore: "L938",
  },
  {
    id: 7680,
    comune: "VILLA DEL BOSCO",
    sigla: "BI",
    codice_elettorale: 1010960780,
    codice_istat: 96078,
    codice_belfiore: "L933",
  },
  {
    id: 7681,
    comune: "VILLA DEL CONTE",
    sigla: "PD",
    codice_elettorale: 1050541010,
    codice_istat: 28101,
    codice_belfiore: "L934",
  },
  {
    id: 7682,
    comune: "VILLA DI BRIANO",
    sigla: "CE",
    codice_elettorale: 3150200980,
    codice_istat: 61098,
    codice_belfiore: "D801",
  },
  {
    id: 7683,
    comune: "VILLA DI CHIAVENNA",
    sigla: "SO",
    codice_elettorale: 1030770770,
    codice_istat: 14077,
    codice_belfiore: "L907",
  },
  {
    id: 7684,
    comune: "VILLA DI SERIO",
    sigla: "BG",
    codice_elettorale: 1030122280,
    codice_istat: 16240,
    codice_belfiore: "L936",
  },
  {
    id: 7685,
    comune: "VILLA DI TIRANO",
    sigla: "SO",
    codice_elettorale: 1030770780,
    codice_istat: 14078,
    codice_belfiore: "L908",
  },
  {
    id: 7686,
    comune: "VILLA ESTENSE",
    sigla: "PD",
    codice_elettorale: 1050541020,
    codice_istat: 28102,
    codice_belfiore: "L937",
  },
  {
    id: 7687,
    comune: "VILLA FARALDI",
    sigla: "IM",
    codice_elettorale: 1070370630,
    codice_istat: 8067,
    codice_belfiore: "L943",
  },
  {
    id: 7688,
    comune: "VILLA GUARDIA",
    sigla: "CO",
    codice_elettorale: 1030242300,
    codice_istat: 13245,
    codice_belfiore: "L956",
  },
  {
    id: 7689,
    comune: "VILLA LAGARINA",
    sigla: "TN",
    codice_elettorale: 1040832100,
    codice_istat: 22222,
    codice_belfiore: "L957",
  },
  {
    id: 7690,
    comune: "VILLA LATINA",
    sigla: "FR",
    codice_elettorale: 2120330870,
    codice_istat: 60088,
    codice_belfiore: "A081",
  },
  {
    id: 7691,
    comune: "VILLA LITERNO",
    sigla: "CE",
    codice_elettorale: 3150200990,
    codice_istat: 61099,
    codice_belfiore: "L844",
  },
  {
    id: 7692,
    comune: "VILLA MINOZZO",
    sigla: "RE",
    codice_elettorale: 1080680450,
    codice_istat: 35045,
    codice_belfiore: "L969",
  },
  {
    id: 7693,
    comune: "VILLA SAN GIOVANNI",
    sigla: "RC",
    codice_elettorale: 3180670960,
    codice_istat: 80096,
    codice_belfiore: "M018",
  },
  {
    id: 7694,
    comune: "VILLA SAN GIOVANNI IN TUSCIA",
    sigla: "VT",
    codice_elettorale: 2120910571,
    codice_istat: 56046,
    codice_belfiore: "H913",
  },
  {
    id: 7695,
    comune: "VILLA SAN PIETRO",
    sigla: "CA",
    codice_elettorale: 4200170990,
    codice_istat: 92099,
    codice_belfiore: "I118",
  },
  {
    id: 7696,
    comune: "VILLA SAN SECONDO",
    sigla: "AT",
    codice_elettorale: 1010071190,
    codice_istat: 5119,
    codice_belfiore: "M019",
  },
  {
    id: 7697,
    comune: "VILLA SANT'ANGELO",
    sigla: "AQ",
    codice_elettorale: 3130381031,
    codice_istat: 66105,
    codice_belfiore: "M023",
  },
  {
    id: 7698,
    comune: "VILLA SANT'ANTONIO",
    sigla: "OR",
    codice_elettorale: 4200950711,
    codice_istat: 95048,
    codice_belfiore: "I298",
  },
  {
    id: 7699,
    comune: "VILLA SANTA LUCIA",
    sigla: "FR",
    codice_elettorale: 2120330880,
    codice_istat: 60089,
    codice_belfiore: "L905",
  },
  {
    id: 7700,
    comune: "VILLA SANTA LUCIA DEGLI ABRUZZI",
    sigla: "AQ",
    codice_elettorale: 3130381030,
    codice_istat: 66104,
    codice_belfiore: "M021",
  },
  {
    id: 7701,
    comune: "VILLA SANTA MARIA",
    sigla: "CH",
    codice_elettorale: 3130231020,
    codice_istat: 69102,
    codice_belfiore: "M022",
  },
  {
    id: 7702,
    comune: "VILLA SANTINA",
    sigla: "UD",
    codice_elettorale: 1060851330,
    codice_istat: 30133,
    codice_belfiore: "L909",
  },
  {
    id: 7703,
    comune: "VILLA SANTO STEFANO",
    sigla: "FR",
    codice_elettorale: 2120330890,
    codice_istat: 60090,
    codice_belfiore: "I364",
  },
  {
    id: 7704,
    comune: "VILLA VERDE",
    sigla: "OR",
    codice_elettorale: 4200950730,
    codice_istat: 95073,
    codice_belfiore: "A609",
  },
  {
    id: 7705,
    comune: "VILLABASSA/NIEDERDORF",
    sigla: "BZ",
    codice_elettorale: 1040141050,
    codice_istat: 21113,
    codice_belfiore: "L915",
  },
  {
    id: 7706,
    comune: "VILLABATE",
    sigla: "PA",
    codice_elettorale: 4190550760,
    codice_istat: 82079,
    codice_belfiore: "L916",
  },
  {
    id: 7707,
    comune: "VILLACHIARA",
    sigla: "BS",
    codice_elettorale: 1030151910,
    codice_istat: 17200,
    codice_belfiore: "L923",
  },
  {
    id: 7708,
    comune: "VILLACIDRO",
    sigla: "SU",
    codice_elettorale: 4201110960,
    codice_istat: 111096,
    codice_belfiore: "L924",
  },
  {
    id: 7709,
    comune: "VILLADEATI",
    sigla: "AL",
    codice_elettorale: 1010021790,
    codice_istat: 6182,
    codice_belfiore: "L931",
  },
  {
    id: 7710,
    comune: "VILLADOSE",
    sigla: "RO",
    codice_elettorale: 1050710480,
    codice_istat: 29048,
    codice_belfiore: "L939",
  },
  {
    id: 7711,
    comune: "VILLADOSSOLA",
    sigla: "VB",
    codice_elettorale: 1011020750,
    codice_istat: 103075,
    codice_belfiore: "L906",
  },
  {
    id: 7712,
    comune: "VILLAFALLETTO",
    sigla: "CN",
    codice_elettorale: 1010272430,
    codice_istat: 4244,
    codice_belfiore: "L942",
  },
  {
    id: 7713,
    comune: "VILLAFRANCA D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010071170,
    codice_istat: 5117,
    codice_belfiore: "L945",
  },
  {
    id: 7714,
    comune: "VILLAFRANCA DI VERONA",
    sigla: "VR",
    codice_elettorale: 1050890950,
    codice_istat: 23096,
    codice_belfiore: "L949",
  },
  {
    id: 7715,
    comune: "VILLAFRANCA IN LUNIGIANA",
    sigla: "MS",
    codice_elettorale: 2090460160,
    codice_istat: 45016,
    codice_belfiore: "L946",
  },
  {
    id: 7716,
    comune: "VILLAFRANCA PADOVANA",
    sigla: "PD",
    codice_elettorale: 1050541030,
    codice_istat: 28103,
    codice_belfiore: "L947",
  },
  {
    id: 7717,
    comune: "VILLAFRANCA PIEMONTE",
    sigla: "TO",
    codice_elettorale: 1010812870,
    codice_istat: 1300,
    codice_belfiore: "L948",
  },
  {
    id: 7718,
    comune: "VILLAFRANCA SICULA",
    sigla: "AG",
    codice_elettorale: 4190010420,
    codice_istat: 84043,
    codice_belfiore: "L944",
  },
  {
    id: 7719,
    comune: "VILLAFRANCA TIRRENA",
    sigla: "ME",
    codice_elettorale: 4190481030,
    codice_istat: 83105,
    codice_belfiore: "L950",
  },
  {
    id: 7720,
    comune: "VILLAFRATI",
    sigla: "PA",
    codice_elettorale: 4190550770,
    codice_istat: 82080,
    codice_belfiore: "L951",
  },
  {
    id: 7721,
    comune: "VILLAGA",
    sigla: "VI",
    codice_elettorale: 1050901170,
    codice_istat: 24117,
    codice_belfiore: "L952",
  },
  {
    id: 7722,
    comune: "VILLAGRANDE STRISAILI",
    sigla: "NU",
    codice_elettorale: 4200530980,
    codice_istat: 91101,
    codice_belfiore: "L953",
  },
  {
    id: 7723,
    comune: "VILLALAGO",
    sigla: "AQ",
    codice_elettorale: 3130381020,
    codice_istat: 66103,
    codice_belfiore: "L958",
  },
  {
    id: 7724,
    comune: "VILLALBA",
    sigla: "CL",
    codice_elettorale: 4190180220,
    codice_istat: 85022,
    codice_belfiore: "L959",
  },
  {
    id: 7725,
    comune: "VILLALFONSINA",
    sigla: "CH",
    codice_elettorale: 3130231000,
    codice_istat: 69100,
    codice_belfiore: "L961",
  },
  {
    id: 7726,
    comune: "VILLALVERNIA",
    sigla: "AL",
    codice_elettorale: 1010021800,
    codice_istat: 6183,
    codice_belfiore: "L963",
  },
  {
    id: 7727,
    comune: "VILLAMAGNA",
    sigla: "CH",
    codice_elettorale: 3130231010,
    codice_istat: 69101,
    codice_belfiore: "L964",
  },
  {
    id: 7728,
    comune: "VILLAMAINA",
    sigla: "AV",
    codice_elettorale: 3150081150,
    codice_istat: 64117,
    codice_belfiore: "L965",
  },
  {
    id: 7729,
    comune: "VILLAMAR",
    sigla: "SU",
    codice_elettorale: 4201110970,
    codice_istat: 111097,
    codice_belfiore: "L966",
  },
  {
    id: 7730,
    comune: "VILLAMARZANA",
    sigla: "RO",
    codice_elettorale: 1050710490,
    codice_istat: 29049,
    codice_belfiore: "L967",
  },
  {
    id: 7731,
    comune: "VILLAMASSARGIA",
    sigla: "SU",
    codice_elettorale: 4201110980,
    codice_istat: 111098,
    codice_belfiore: "L968",
  },
  {
    id: 7732,
    comune: "VILLAMIROGLIO",
    sigla: "AL",
    codice_elettorale: 1010021810,
    codice_istat: 6184,
    codice_belfiore: "L970",
  },
  {
    id: 7733,
    comune: "VILLANDRO/VILLANDERS",
    sigla: "BZ",
    codice_elettorale: 1040141060,
    codice_istat: 21114,
    codice_belfiore: "L971",
  },
  {
    id: 7734,
    comune: "VILLANOVA BIELLESE",
    sigla: "BI",
    codice_elettorale: 1010960790,
    codice_istat: 96079,
    codice_belfiore: "L978",
  },
  {
    id: 7735,
    comune: "VILLANOVA CANAVESE",
    sigla: "TO",
    codice_elettorale: 1010812880,
    codice_istat: 1301,
    codice_belfiore: "L982",
  },
  {
    id: 7736,
    comune: "VILLANOVA D'ALBENGA",
    sigla: "SV",
    codice_elettorale: 1070740680,
    codice_istat: 9068,
    codice_belfiore: "L975",
  },
  {
    id: 7737,
    comune: "VILLANOVA D'ARDENGHI",
    sigla: "PV",
    codice_elettorale: 1030571750,
    codice_istat: 18179,
    codice_belfiore: "L983",
  },
  {
    id: 7738,
    comune: "VILLANOVA D'ASTI",
    sigla: "AT",
    codice_elettorale: 1010071180,
    codice_istat: 5118,
    codice_belfiore: "L984",
  },
  {
    id: 7739,
    comune: "VILLANOVA DEL BATTISTA",
    sigla: "AV",
    codice_elettorale: 3150081160,
    codice_istat: 64118,
    codice_belfiore: "L973",
  },
  {
    id: 7740,
    comune: "VILLANOVA DEL GHEBBO",
    sigla: "RO",
    codice_elettorale: 1050710500,
    codice_istat: 29050,
    codice_belfiore: "L985",
  },
  {
    id: 7741,
    comune: "VILLANOVA DEL SILLARO",
    sigla: "LO",
    codice_elettorale: 1030990600,
    codice_istat: 98060,
    codice_belfiore: "L977",
  },
  {
    id: 7742,
    comune: "VILLANOVA DI CAMPOSAMPIERO",
    sigla: "PD",
    codice_elettorale: 1050541040,
    codice_istat: 28104,
    codice_belfiore: "L979",
  },
  {
    id: 7743,
    comune: "VILLANOVA MARCHESANA",
    sigla: "RO",
    codice_elettorale: 1050710510,
    codice_istat: 29051,
    codice_belfiore: "L988",
  },
  {
    id: 7744,
    comune: "VILLANOVA MONDOVI'",
    sigla: "CN",
    codice_elettorale: 1010272440,
    codice_istat: 4245,
    codice_belfiore: "L974",
  },
  {
    id: 7745,
    comune: "VILLANOVA MONFERRATO",
    sigla: "AL",
    codice_elettorale: 1010021820,
    codice_istat: 6185,
    codice_belfiore: "L972",
  },
  {
    id: 7746,
    comune: "VILLANOVA MONTELEONE",
    sigla: "SS",
    codice_elettorale: 4200730750,
    codice_istat: 90078,
    codice_belfiore: "L989",
  },
  {
    id: 7747,
    comune: "VILLANOVA SOLARO",
    sigla: "CN",
    codice_elettorale: 1010272450,
    codice_istat: 4246,
    codice_belfiore: "L990",
  },
  {
    id: 7748,
    comune: "VILLANOVA SULL'ARDA",
    sigla: "PC",
    codice_elettorale: 1080610460,
    codice_istat: 33046,
    codice_belfiore: "L980",
  },
  {
    id: 7749,
    comune: "VILLANOVA TRUSCHEDU",
    sigla: "OR",
    codice_elettorale: 4200950710,
    codice_istat: 95071,
    codice_belfiore: "L991",
  },
  {
    id: 7750,
    comune: "VILLANOVA TULO",
    sigla: "SU",
    codice_elettorale: 4201110990,
    codice_istat: 111099,
    codice_belfiore: "L992",
  },
  {
    id: 7751,
    comune: "VILLANOVAFORRU",
    sigla: "SU",
    codice_elettorale: 4201111000,
    codice_istat: 111100,
    codice_belfiore: "L986",
  },
  {
    id: 7752,
    comune: "VILLANOVAFRANCA",
    sigla: "SU",
    codice_elettorale: 4201111010,
    codice_istat: 111101,
    codice_belfiore: "L987",
  },
  {
    id: 7753,
    comune: "VILLANTERIO",
    sigla: "PV",
    codice_elettorale: 1030571760,
    codice_istat: 18180,
    codice_belfiore: "L994",
  },
  {
    id: 7754,
    comune: "VILLANUOVA SUL CLISI",
    sigla: "BS",
    codice_elettorale: 1030151920,
    codice_istat: 17201,
    codice_belfiore: "L995",
  },
  {
    id: 7755,
    comune: "VILLAPERUCCIO",
    sigla: "SU",
    codice_elettorale: 4201111020,
    codice_istat: 111102,
    codice_belfiore: "M278",
  },
  {
    id: 7756,
    comune: "VILLAPIANA",
    sigla: "CS",
    codice_elettorale: 3180251540,
    codice_istat: 78154,
    codice_belfiore: "B903",
  },
  {
    id: 7757,
    comune: "VILLAPUTZU",
    sigla: "SU",
    codice_elettorale: 4201111030,
    codice_istat: 111103,
    codice_belfiore: "L998",
  },
  {
    id: 7758,
    comune: "VILLAR DORA",
    sigla: "TO",
    codice_elettorale: 1010812891,
    codice_istat: 1303,
    codice_belfiore: "L999",
  },
  {
    id: 7759,
    comune: "VILLAR FOCCHIARDO",
    sigla: "TO",
    codice_elettorale: 1010812910,
    codice_istat: 1305,
    codice_belfiore: "M007",
  },
  {
    id: 7760,
    comune: "VILLAR PELLICE",
    sigla: "TO",
    codice_elettorale: 1010812920,
    codice_istat: 1306,
    codice_belfiore: "M013",
  },
  {
    id: 7761,
    comune: "VILLAR PEROSA",
    sigla: "TO",
    codice_elettorale: 1010812930,
    codice_istat: 1307,
    codice_belfiore: "M014",
  },
  {
    id: 7762,
    comune: "VILLAR SAN COSTANZO",
    sigla: "CN",
    codice_elettorale: 1010272460,
    codice_istat: 4247,
    codice_belfiore: "M015",
  },
  {
    id: 7763,
    comune: "VILLARBASSE",
    sigla: "TO",
    codice_elettorale: 1010812890,
    codice_istat: 1302,
    codice_belfiore: "M002",
  },
  {
    id: 7764,
    comune: "VILLARBOIT",
    sigla: "VC",
    codice_elettorale: 1010881610,
    codice_istat: 2163,
    codice_belfiore: "M003",
  },
  {
    id: 7765,
    comune: "VILLAREGGIA",
    sigla: "TO",
    codice_elettorale: 1010812900,
    codice_istat: 1304,
    codice_belfiore: "M004",
  },
  {
    id: 7766,
    comune: "VILLARICCA",
    sigla: "NA",
    codice_elettorale: 3150510870,
    codice_istat: 63087,
    codice_belfiore: "G309",
  },
  {
    id: 7767,
    comune: "VILLAROMAGNANO",
    sigla: "AL",
    codice_elettorale: 1010021830,
    codice_istat: 6186,
    codice_belfiore: "M009",
  },
  {
    id: 7768,
    comune: "VILLAROSA",
    sigla: "EN",
    codice_elettorale: 4190280200,
    codice_istat: 86020,
    codice_belfiore: "M011",
  },
  {
    id: 7769,
    comune: "VILLASALTO",
    sigla: "SU",
    codice_elettorale: 4201111040,
    codice_istat: 111104,
    codice_belfiore: "M016",
  },
  {
    id: 7770,
    comune: "VILLASANTA",
    sigla: "MB",
    codice_elettorale: 1031040490,
    codice_istat: 108049,
    codice_belfiore: "M017",
  },
  {
    id: 7771,
    comune: "VILLASIMIUS",
    sigla: "SU",
    codice_elettorale: 4201111050,
    codice_istat: 111105,
    codice_belfiore: "B738",
  },
  {
    id: 7772,
    comune: "VILLASOR",
    sigla: "SU",
    codice_elettorale: 4201111060,
    codice_istat: 111106,
    codice_belfiore: "M025",
  },
  {
    id: 7773,
    comune: "VILLASPECIOSA",
    sigla: "SU",
    codice_elettorale: 4201111070,
    codice_istat: 111107,
    codice_belfiore: "M026",
  },
  {
    id: 7774,
    comune: "VILLASTELLONE",
    sigla: "TO",
    codice_elettorale: 1010812940,
    codice_istat: 1308,
    codice_belfiore: "M027",
  },
  {
    id: 7775,
    comune: "VILLATA",
    sigla: "VC",
    codice_elettorale: 1010881620,
    codice_istat: 2164,
    codice_belfiore: "M028",
  },
  {
    id: 7776,
    comune: "VILLAURBANA",
    sigla: "OR",
    codice_elettorale: 4200950720,
    codice_istat: 95072,
    codice_belfiore: "M030",
  },
  {
    id: 7777,
    comune: "VILLAVALLELONGA",
    sigla: "AQ",
    codice_elettorale: 3130381040,
    codice_istat: 66106,
    codice_belfiore: "M031",
  },
  {
    id: 7778,
    comune: "VILLAVERLA",
    sigla: "VI",
    codice_elettorale: 1050901180,
    codice_istat: 24118,
    codice_belfiore: "M032",
  },
  {
    id: 7779,
    comune: "VILLE D'ANAUNIA",
    sigla: "TN",
    codice_elettorale: 1040832111,
    codice_istat: 22249,
    codice_belfiore: "M363",
  },
  {
    id: 7780,
    comune: "VILLE DI FIEMME",
    sigla: "TN",
    codice_elettorale: 1040832115,
    codice_istat: 22254,
    codice_belfiore: "M431",
  },
  {
    id: 7781,
    comune: "VILLENEUVE",
    sigla: "AO",
    codice_elettorale: 1020040730,
    codice_istat: 7074,
    codice_belfiore: "L981",
  },
  {
    id: 7782,
    comune: "VILLESSE",
    sigla: "GO",
    codice_elettorale: 1060350210,
    codice_istat: 31025,
    codice_belfiore: "M043",
  },
  {
    id: 7783,
    comune: "VILLETTA BARREA",
    sigla: "AQ",
    codice_elettorale: 3130381050,
    codice_istat: 66107,
    codice_belfiore: "M041",
  },
  {
    id: 7784,
    comune: "VILLETTE",
    sigla: "VB",
    codice_elettorale: 1011020760,
    codice_istat: 103076,
    codice_belfiore: "M042",
  },
  {
    id: 7785,
    comune: "VILLIMPENTA",
    sigla: "MN",
    codice_elettorale: 1030450680,
    codice_istat: 20068,
    codice_belfiore: "M044",
  },
  {
    id: 7786,
    comune: "VILLONGO",
    sigla: "BG",
    codice_elettorale: 1030122300,
    codice_istat: 16242,
    codice_belfiore: "M045",
  },
  {
    id: 7787,
    comune: "VILLORBA",
    sigla: "TV",
    codice_elettorale: 1050840900,
    codice_istat: 26091,
    codice_belfiore: "M048",
  },
  {
    id: 7788,
    comune: "VILMINORE DI SCALVE",
    sigla: "BG",
    codice_elettorale: 1030122310,
    codice_istat: 16243,
    codice_belfiore: "M050",
  },
  {
    id: 7789,
    comune: "VIMERCATE",
    sigla: "MB",
    codice_elettorale: 1031040500,
    codice_istat: 108050,
    codice_belfiore: "M052",
  },
  {
    id: 7790,
    comune: "VIMODRONE",
    sigla: "MI",
    codice_elettorale: 1030492400,
    codice_istat: 15242,
    codice_belfiore: "M053",
  },
  {
    id: 7791,
    comune: "VINADIO",
    sigla: "CN",
    codice_elettorale: 1010272470,
    codice_istat: 4248,
    codice_belfiore: "M055",
  },
  {
    id: 7792,
    comune: "VINCHIATURO",
    sigla: "CB",
    codice_elettorale: 3140190840,
    codice_istat: 70084,
    codice_belfiore: "M057",
  },
  {
    id: 7793,
    comune: "VINCHIO",
    sigla: "AT",
    codice_elettorale: 1010071200,
    codice_istat: 5120,
    codice_belfiore: "M058",
  },
  {
    id: 7794,
    comune: "VINCI",
    sigla: "FI",
    codice_elettorale: 2090300500,
    codice_istat: 48050,
    codice_belfiore: "M059",
  },
  {
    id: 7795,
    comune: "VINOVO",
    sigla: "TO",
    codice_elettorale: 1010812950,
    codice_istat: 1309,
    codice_belfiore: "M060",
  },
  {
    id: 7796,
    comune: "VINZAGLIO",
    sigla: "NO",
    codice_elettorale: 1010521550,
    codice_istat: 3164,
    codice_belfiore: "M062",
  },
  {
    id: 7797,
    comune: "VIOLA",
    sigla: "CN",
    codice_elettorale: 1010272480,
    codice_istat: 4249,
    codice_belfiore: "M063",
  },
  {
    id: 7798,
    comune: "VIONE",
    sigla: "BS",
    codice_elettorale: 1030151930,
    codice_istat: 17202,
    codice_belfiore: "M065",
  },
  {
    id: 7799,
    comune: "VIPITENO/STERZING",
    sigla: "BZ",
    codice_elettorale: 1040141070,
    codice_istat: 21115,
    codice_belfiore: "M067",
  },
  {
    id: 7800,
    comune: "VIRLE PIEMONTE",
    sigla: "TO",
    codice_elettorale: 1010812960,
    codice_istat: 1310,
    codice_belfiore: "M069",
  },
  {
    id: 7801,
    comune: "VISANO",
    sigla: "BS",
    codice_elettorale: 1030151940,
    codice_istat: 17203,
    codice_belfiore: "M070",
  },
  {
    id: 7802,
    comune: "VISCHE",
    sigla: "TO",
    codice_elettorale: 1010812970,
    codice_istat: 1311,
    codice_belfiore: "M071",
  },
  {
    id: 7803,
    comune: "VISCIANO",
    sigla: "NA",
    codice_elettorale: 3150510880,
    codice_istat: 63088,
    codice_belfiore: "M072",
  },
  {
    id: 7804,
    comune: "VISCO",
    sigla: "UD",
    codice_elettorale: 1060851350,
    codice_istat: 30135,
    codice_belfiore: "M073",
  },
  {
    id: 7805,
    comune: "VISONE",
    sigla: "AL",
    codice_elettorale: 1010021840,
    codice_istat: 6187,
    codice_belfiore: "M077",
  },
  {
    id: 7806,
    comune: "VISSO",
    sigla: "MC",
    codice_elettorale: 2110440570,
    codice_istat: 43057,
    codice_belfiore: "M078",
  },
  {
    id: 7807,
    comune: "VISTARINO",
    sigla: "PV",
    codice_elettorale: 1030571770,
    codice_istat: 18181,
    codice_belfiore: "M079",
  },
  {
    id: 7808,
    comune: "VISTRORIO",
    sigla: "TO",
    codice_elettorale: 1010812980,
    codice_istat: 1312,
    codice_belfiore: "M080",
  },
  {
    id: 7809,
    comune: "VITA",
    sigla: "TP",
    codice_elettorale: 4190820220,
    codice_istat: 81023,
    codice_belfiore: "M081",
  },
  {
    id: 7810,
    comune: "VITERBO",
    sigla: "VT",
    codice_elettorale: 2120910580,
    codice_istat: 56059,
    codice_belfiore: "M082",
  },
  {
    id: 7811,
    comune: "VITICUSO",
    sigla: "FR",
    codice_elettorale: 2120330900,
    codice_istat: 60091,
    codice_belfiore: "M083",
  },
  {
    id: 7812,
    comune: "VITO D'ASIO",
    sigla: "PN",
    codice_elettorale: 1060930490,
    codice_istat: 93049,
    codice_belfiore: "M085",
  },
  {
    id: 7813,
    comune: "VITORCHIANO",
    sigla: "VT",
    codice_elettorale: 2120910590,
    codice_istat: 56060,
    codice_belfiore: "M086",
  },
  {
    id: 7814,
    comune: "VITTORIA",
    sigla: "RG",
    codice_elettorale: 4190650120,
    codice_istat: 88012,
    codice_belfiore: "M088",
  },
  {
    id: 7815,
    comune: "VITTORIO VENETO",
    sigla: "TV",
    codice_elettorale: 1050840910,
    codice_istat: 26092,
    codice_belfiore: "M089",
  },
  {
    id: 7816,
    comune: "VITTORITO",
    sigla: "AQ",
    codice_elettorale: 3130381060,
    codice_istat: 66108,
    codice_belfiore: "M090",
  },
  {
    id: 7817,
    comune: "VITTUONE",
    sigla: "MI",
    codice_elettorale: 1030492410,
    codice_istat: 15243,
    codice_belfiore: "M091",
  },
  {
    id: 7818,
    comune: "VITULANO",
    sigla: "BN",
    codice_elettorale: 3150110750,
    codice_istat: 62077,
    codice_belfiore: "M093",
  },
  {
    id: 7819,
    comune: "VITULAZIO",
    sigla: "CE",
    codice_elettorale: 3150201000,
    codice_istat: 61100,
    codice_belfiore: "M092",
  },
  {
    id: 7820,
    comune: "VIU'",
    sigla: "TO",
    codice_elettorale: 1010812990,
    codice_istat: 1313,
    codice_belfiore: "M094",
  },
  {
    id: 7821,
    comune: "VIVARO",
    sigla: "PN",
    codice_elettorale: 1060930500,
    codice_istat: 93050,
    codice_belfiore: "M096",
  },
  {
    id: 7822,
    comune: "VIVARO ROMANO",
    sigla: "RM",
    codice_elettorale: 2120701120,
    codice_istat: 58113,
    codice_belfiore: "M095",
  },
  {
    id: 7823,
    comune: "VIVERONE",
    sigla: "BI",
    codice_elettorale: 1010960800,
    codice_istat: 96080,
    codice_belfiore: "M098",
  },
  {
    id: 7824,
    comune: "VIZZINI",
    sigla: "CT",
    codice_elettorale: 4190210520,
    codice_istat: 87054,
    codice_belfiore: "M100",
  },
  {
    id: 7825,
    comune: "VIZZOLA TICINO",
    sigla: "VA",
    codice_elettorale: 1030861220,
    codice_istat: 12140,
    codice_belfiore: "M101",
  },
  {
    id: 7826,
    comune: "VIZZOLO PREDABISSI",
    sigla: "MI",
    codice_elettorale: 1030492420,
    codice_istat: 15244,
    codice_belfiore: "M102",
  },
  {
    id: 7827,
    comune: "VO'",
    sigla: "PD",
    codice_elettorale: 1050541050,
    codice_istat: 28105,
    codice_belfiore: "M103",
  },
  {
    id: 7828,
    comune: "VOBARNO",
    sigla: "BS",
    codice_elettorale: 1030151950,
    codice_istat: 17204,
    codice_belfiore: "M104",
  },
  {
    id: 7829,
    comune: "VOBBIA",
    sigla: "GE",
    codice_elettorale: 1070340660,
    codice_istat: 10066,
    codice_belfiore: "M105",
  },
  {
    id: 7830,
    comune: "VOCCA",
    sigla: "VC",
    codice_elettorale: 1010881640,
    codice_istat: 2166,
    codice_belfiore: "M106",
  },
  {
    id: 7831,
    comune: "VODO CADORE",
    sigla: "BL",
    codice_elettorale: 1050100660,
    codice_istat: 25066,
    codice_belfiore: "M108",
  },
  {
    id: 7832,
    comune: "VOGHERA",
    sigla: "PV",
    codice_elettorale: 1030571780,
    codice_istat: 18182,
    codice_belfiore: "M109",
  },
  {
    id: 7833,
    comune: "VOGHIERA",
    sigla: "FE",
    codice_elettorale: 1080290210,
    codice_istat: 38023,
    codice_belfiore: "M110",
  },
  {
    id: 7834,
    comune: "VOGOGNA",
    sigla: "VB",
    codice_elettorale: 1011020770,
    codice_istat: 103077,
    codice_belfiore: "M111",
  },
  {
    id: 7835,
    comune: "VOLANO",
    sigla: "TN",
    codice_elettorale: 1040832120,
    codice_istat: 22224,
    codice_belfiore: "M113",
  },
  {
    id: 7836,
    comune: "VOLLA",
    sigla: "NA",
    codice_elettorale: 3150510890,
    codice_istat: 63089,
    codice_belfiore: "M115",
  },
  {
    id: 7837,
    comune: "VOLONGO",
    sigla: "CR",
    codice_elettorale: 1030261110,
    codice_istat: 19114,
    codice_belfiore: "M116",
  },
  {
    id: 7838,
    comune: "VOLPAGO DEL MONTELLO",
    sigla: "TV",
    codice_elettorale: 1050840920,
    codice_istat: 26093,
    codice_belfiore: "M118",
  },
  {
    id: 7839,
    comune: "VOLPARA",
    sigla: "PV",
    codice_elettorale: 1030571790,
    codice_istat: 18183,
    codice_belfiore: "M119",
  },
  {
    id: 7840,
    comune: "VOLPEDO",
    sigla: "AL",
    codice_elettorale: 1010021850,
    codice_istat: 6188,
    codice_belfiore: "M120",
  },
  {
    id: 7841,
    comune: "VOLPEGLINO",
    sigla: "AL",
    codice_elettorale: 1010021860,
    codice_istat: 6189,
    codice_belfiore: "M121",
  },
  {
    id: 7842,
    comune: "VOLPIANO",
    sigla: "TO",
    codice_elettorale: 1010813000,
    codice_istat: 1314,
    codice_belfiore: "M122",
  },
  {
    id: 7843,
    comune: "VOLTA MANTOVANA",
    sigla: "MN",
    codice_elettorale: 1030450700,
    codice_istat: 20070,
    codice_belfiore: "M125",
  },
  {
    id: 7844,
    comune: "VOLTAGGIO",
    sigla: "AL",
    codice_elettorale: 1010021870,
    codice_istat: 6190,
    codice_belfiore: "M123",
  },
  {
    id: 7845,
    comune: "VOLTAGO AGORDINO",
    sigla: "BL",
    codice_elettorale: 1050100670,
    codice_istat: 25067,
    codice_belfiore: "M124",
  },
  {
    id: 7846,
    comune: "VOLTERRA",
    sigla: "PI",
    codice_elettorale: 2090620380,
    codice_istat: 50039,
    codice_belfiore: "M126",
  },
  {
    id: 7847,
    comune: "VOLTIDO",
    sigla: "CR",
    codice_elettorale: 1030261120,
    codice_istat: 19115,
    codice_belfiore: "M127",
  },
  {
    id: 7848,
    comune: "VOLTURARA APPULA",
    sigla: "FG",
    codice_elettorale: 3160310590,
    codice_istat: 71061,
    codice_belfiore: "M131",
  },
  {
    id: 7849,
    comune: "VOLTURARA IRPINA",
    sigla: "AV",
    codice_elettorale: 3150081170,
    codice_istat: 64119,
    codice_belfiore: "M130",
  },
  {
    id: 7850,
    comune: "VOLTURINO",
    sigla: "FG",
    codice_elettorale: 3160310600,
    codice_istat: 71062,
    codice_belfiore: "M132",
  },
  {
    id: 7851,
    comune: "VOLVERA",
    sigla: "TO",
    codice_elettorale: 1010813010,
    codice_istat: 1315,
    codice_belfiore: "M133",
  },
  {
    id: 7852,
    comune: "VOTTIGNASCO",
    sigla: "CN",
    codice_elettorale: 1010272490,
    codice_istat: 4250,
    codice_belfiore: "M136",
  },
  {
    id: 7853,
    comune: "ZACCANOPOLI",
    sigla: "VV",
    codice_elettorale: 3181030480,
    codice_istat: 102048,
    codice_belfiore: "M138",
  },
  {
    id: 7854,
    comune: "ZAFFERANA ETNEA",
    sigla: "CT",
    codice_elettorale: 4190210530,
    codice_istat: 87055,
    codice_belfiore: "M139",
  },
  {
    id: 7855,
    comune: "ZAGARISE",
    sigla: "CZ",
    codice_elettorale: 3180221530,
    codice_istat: 79157,
    codice_belfiore: "M140",
  },
  {
    id: 7856,
    comune: "ZAGAROLO",
    sigla: "RM",
    codice_elettorale: 2120701130,
    codice_istat: 58114,
    codice_belfiore: "M141",
  },
  {
    id: 7857,
    comune: "ZAMBRONE",
    sigla: "VV",
    codice_elettorale: 3181030490,
    codice_istat: 102049,
    codice_belfiore: "M143",
  },
  {
    id: 7858,
    comune: "ZANDOBBIO",
    sigla: "BG",
    codice_elettorale: 1030122320,
    codice_istat: 16244,
    codice_belfiore: "M144",
  },
  {
    id: 7859,
    comune: "ZANE'",
    sigla: "VI",
    codice_elettorale: 1050901190,
    codice_istat: 24119,
    codice_belfiore: "M145",
  },
  {
    id: 7860,
    comune: "ZANICA",
    sigla: "BG",
    codice_elettorale: 1030122330,
    codice_istat: 16245,
    codice_belfiore: "M147",
  },
  {
    id: 7861,
    comune: "ZAPPONETA",
    sigla: "FG",
    codice_elettorale: 3160310601,
    codice_istat: 71064,
    codice_belfiore: "M267",
  },
  {
    id: 7862,
    comune: "ZAVATTARELLO",
    sigla: "PV",
    codice_elettorale: 1030571800,
    codice_istat: 18184,
    codice_belfiore: "M150",
  },
  {
    id: 7863,
    comune: "ZECCONE",
    sigla: "PV",
    codice_elettorale: 1030571810,
    codice_istat: 18185,
    codice_belfiore: "M152",
  },
  {
    id: 7864,
    comune: "ZEDDIANI",
    sigla: "OR",
    codice_elettorale: 4200950740,
    codice_istat: 95074,
    codice_belfiore: "M153",
  },
  {
    id: 7865,
    comune: "ZELBIO",
    sigla: "CO",
    codice_elettorale: 1030242310,
    codice_istat: 13246,
    codice_belfiore: "M156",
  },
  {
    id: 7866,
    comune: "ZELO BUON PERSICO",
    sigla: "LO",
    codice_elettorale: 1030990610,
    codice_istat: 98061,
    codice_belfiore: "M158",
  },
  {
    id: 7867,
    comune: "ZEME",
    sigla: "PV",
    codice_elettorale: 1030571820,
    codice_istat: 18186,
    codice_belfiore: "M161",
  },
  {
    id: 7868,
    comune: "ZENEVREDO",
    sigla: "PV",
    codice_elettorale: 1030571830,
    codice_istat: 18187,
    codice_belfiore: "M162",
  },
  {
    id: 7869,
    comune: "ZENSON DI PIAVE",
    sigla: "TV",
    codice_elettorale: 1050840930,
    codice_istat: 26094,
    codice_belfiore: "M163",
  },
  {
    id: 7870,
    comune: "ZERBA",
    sigla: "PC",
    codice_elettorale: 1080610470,
    codice_istat: 33047,
    codice_belfiore: "M165",
  },
  {
    id: 7871,
    comune: "ZERBO",
    sigla: "PV",
    codice_elettorale: 1030571840,
    codice_istat: 18188,
    codice_belfiore: "M166",
  },
  {
    id: 7872,
    comune: "ZERBOLO'",
    sigla: "PV",
    codice_elettorale: 1030571850,
    codice_istat: 18189,
    codice_belfiore: "M167",
  },
  {
    id: 7873,
    comune: "ZERFALIU",
    sigla: "OR",
    codice_elettorale: 4200950750,
    codice_istat: 95075,
    codice_belfiore: "M168",
  },
  {
    id: 7874,
    comune: "ZERI",
    sigla: "MS",
    codice_elettorale: 2090460170,
    codice_istat: 45017,
    codice_belfiore: "M169",
  },
  {
    id: 7875,
    comune: "ZERMEGHEDO",
    sigla: "VI",
    codice_elettorale: 1050901200,
    codice_istat: 24120,
    codice_belfiore: "M170",
  },
  {
    id: 7876,
    comune: "ZERO BRANCO",
    sigla: "TV",
    codice_elettorale: 1050840940,
    codice_istat: 26095,
    codice_belfiore: "M171",
  },
  {
    id: 7877,
    comune: "ZEVIO",
    sigla: "VR",
    codice_elettorale: 1050890960,
    codice_istat: 23097,
    codice_belfiore: "M172",
  },
  {
    id: 7878,
    comune: "ZIANO DI FIEMME",
    sigla: "TN",
    codice_elettorale: 1040832140,
    codice_istat: 22226,
    codice_belfiore: "M173",
  },
  {
    id: 7879,
    comune: "ZIANO PIACENTINO",
    sigla: "PC",
    codice_elettorale: 1080610480,
    codice_istat: 33048,
    codice_belfiore: "L848",
  },
  {
    id: 7880,
    comune: "ZIBIDO SAN GIACOMO",
    sigla: "MI",
    codice_elettorale: 1030492450,
    codice_istat: 15247,
    codice_belfiore: "M176",
  },
  {
    id: 7881,
    comune: "ZIGNAGO",
    sigla: "SP",
    codice_elettorale: 1070390320,
    codice_istat: 11032,
    codice_belfiore: "M177",
  },
  {
    id: 7882,
    comune: "ZIMELLA",
    sigla: "VR",
    codice_elettorale: 1050890970,
    codice_istat: 23098,
    codice_belfiore: "M178",
  },
  {
    id: 7883,
    comune: "ZIMONE",
    sigla: "BI",
    codice_elettorale: 1010960810,
    codice_istat: 96081,
    codice_belfiore: "M179",
  },
  {
    id: 7884,
    comune: "ZINASCO",
    sigla: "PV",
    codice_elettorale: 1030571860,
    codice_istat: 18190,
    codice_belfiore: "M180",
  },
  {
    id: 7885,
    comune: "ZOAGLI",
    sigla: "GE",
    codice_elettorale: 1070340670,
    codice_istat: 10067,
    codice_belfiore: "M182",
  },
  {
    id: 7886,
    comune: "ZOCCA",
    sigla: "MO",
    codice_elettorale: 1080500460,
    codice_istat: 36047,
    codice_belfiore: "M183",
  },
  {
    id: 7887,
    comune: "ZOGNO",
    sigla: "BG",
    codice_elettorale: 1030122340,
    codice_istat: 16246,
    codice_belfiore: "M184",
  },
  {
    id: 7888,
    comune: "ZOLA PREDOSA",
    sigla: "BO",
    codice_elettorale: 1080130600,
    codice_istat: 37060,
    codice_belfiore: "M185",
  },
  {
    id: 7889,
    comune: "ZOLLINO",
    sigla: "LE",
    codice_elettorale: 3160410930,
    codice_istat: 75094,
    codice_belfiore: "M187",
  },
  {
    id: 7890,
    comune: "ZONE",
    sigla: "BS",
    codice_elettorale: 1030151960,
    codice_istat: 17205,
    codice_belfiore: "M188",
  },
  {
    id: 7891,
    comune: "ZOPPE' DI CADORE",
    sigla: "BL",
    codice_elettorale: 1050100690,
    codice_istat: 25069,
    codice_belfiore: "M189",
  },
  {
    id: 7892,
    comune: "ZOPPOLA",
    sigla: "PN",
    codice_elettorale: 1060930510,
    codice_istat: 93051,
    codice_belfiore: "M190",
  },
  {
    id: 7893,
    comune: "ZOVENCEDO",
    sigla: "VI",
    codice_elettorale: 1050901210,
    codice_istat: 24121,
    codice_belfiore: "M194",
  },
  {
    id: 7894,
    comune: "ZUBIENA",
    sigla: "BI",
    codice_elettorale: 1010960820,
    codice_istat: 96082,
    codice_belfiore: "M196",
  },
  {
    id: 7895,
    comune: "ZUCCARELLO",
    sigla: "SV",
    codice_elettorale: 1070740690,
    codice_istat: 9069,
    codice_belfiore: "M197",
  },
  {
    id: 7896,
    comune: "ZUGLIANO",
    sigla: "VI",
    codice_elettorale: 1050901220,
    codice_istat: 24122,
    codice_belfiore: "M199",
  },
  {
    id: 7897,
    comune: "ZUGLIO",
    sigla: "UD",
    codice_elettorale: 1060851360,
    codice_istat: 30136,
    codice_belfiore: "M200",
  },
  {
    id: 7898,
    comune: "ZUMAGLIA",
    sigla: "BI",
    codice_elettorale: 1010960830,
    codice_istat: 96083,
    codice_belfiore: "M201",
  },
  {
    id: 7899,
    comune: "ZUMPANO",
    sigla: "CS",
    codice_elettorale: 3180251550,
    codice_istat: 78155,
    codice_belfiore: "M202",
  },
  {
    id: 7900,
    comune: "ZUNGOLI",
    sigla: "AV",
    codice_elettorale: 3150081180,
    codice_istat: 64120,
    codice_belfiore: "M203",
  },
  {
    id: 7901,
    comune: "ZUNGRI",
    sigla: "VV",
    codice_elettorale: 3181030500,
    codice_istat: 102050,
    codice_belfiore: "M204",
  },
];
