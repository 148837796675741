<template>
  <div>
    <Table :columns="columns" :dataSource="dataSource">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'nome_originale'">
          <Tooltip>
            <template #title>
              <span> {{ record.nome_originale }}</span>
            </template>
            <p
              style="
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100px;
              "
            >
              {{ record.nome_originale }}
            </p>
          </Tooltip>
        </template>
        <template v-if="column.dataIndex === 'buttons'">
          <div class="actions-column">
            <Button :href="record.url" target="_blank" type="primary">
              <DownloadOutlined /> Scarica
            </Button>

            <Button
              v-if="userPermission != 'view'"
              @click="handleDeleteClick(record)"
              type="danger"
            >
              <DeleteOutlined />
              Elimina
            </Button>
          </div>
        </template>
      </template>
    </Table>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { Table, Button, Tooltip } from "ant-design-vue";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons-vue";
import { useUserStore } from "@/store/user.store";

export default {
  name: "LandTable",
  components: {
    Table,
    Button,
    DeleteOutlined,
    DownloadOutlined,
    Tooltip,
  },
  props: {
    documenti: {
      type: Array,
      required: true,
    },
  },
  emits: ["delete-land", "view-land"],
  setup(props, { emit }) {
    const userStore = useUserStore();
    const userPermission = userStore.user.ruolo;
    const columns = ref([
      {
        title: "Nome Documento",
        dataIndex: "nome",
      },
      {
        title: "Nome File",
        dataIndex: "nome_originale",
      },
      {
        title: "Actions",
        dataIndex: "buttons",
      },
    ]);

    const dataSource = computed(() => {
      return props.documenti.map((documento) => {
        const { nome, nome_originale, url } = documento;
        return { nome, nome_originale, url };
      });
    });
    const handleViewClick = (record) => {
      console.log(record);
      emit("download-documento", record);
    };

    const handleDeleteClick = (record) => {
      console.log(record);
      emit("delete-documento", record);
    };

    return {
      columns,
      dataSource,
      handleViewClick,
      handleDeleteClick,
      userPermission,
    };
  },
};
</script>

<style scoped>
.actions-column {
  display: flex;
  gap: 10px;
}
</style>
