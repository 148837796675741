<template>
  <div>
    <Alert
      v-if="action.show"
      :message="action.header"
      :description="action.message"
      :type="action.type"
      showIcon
    />

    <Modal
      title="Aggiungi Proprietario"
      :footer="null"
      v-model:visible="showAddOwnerModal"
    >
      <AddProprietarioModal @add-proprietario="handleAddProprietario" />
    </Modal>

    <Modal
      title="Aggiungi Gestione"
      :footer="null"
      v-model:visible="showAddGestioneModal"
    >
      <AddGestioneModal @add-gestione="handleAddGestione" />
    </Modal>

    <Drawer
      title="Note"
      :visible="showNoteModal"
      :closable="true"
      @close="showNoteModal = false"
    >
      <NoteModal
        :note="formData.note"
        @create-note="handleNewNote"
        @delete-note="handleDeleteNote"
      />
    </Drawer>

    <Drawer
      title="Note Sopralluogo"
      :visible="showVisitNoteModal.visible"
      :closable="true"
      @close="handleCloseVisitNoteModal"
    >
      <NoteModal
        :note="
          formData.visite.filter(
            (visita) => visita._id == showVisitNoteModal.visita
          )[0]?.note || []
        "
        @create-note="handleNewVisitaNote"
        @delete-note="handleDeleteVisitaNote"
      />
    </Drawer>

    <Modal title="Note" :footer="null">
      <NoteModal
        :note="formData.note"
        @create-note="handleNewNote"
        @delete-note="handleDeleteNote"
      />
    </Modal>

    <Modal
      title="Carica documento"
      :footer="null"
      v-model:visible="showCaricaDocumentoModal"
    >
      <UploadDocumentoModalVue @document-upload="handleDocumentUpload" />
    </Modal>

    <Modal
      title="Aggiungi Visita"
      :footer="null"
      v-model:visible="showAddOwnerVisitModal"
    >
      <AddProprietarioVisitModal
        @add-proprietario-visit="handleAddProprietarioVisit"
      />
    </Modal>

    <Card :bordered="false" class="widget-1">
      <Skeleton :loading="isLoading" active>
        <Statistic
          v-if="!isLoading"
          title="Terreno"
          :value="formData.codice"
          class="text-success"
        />
      </Skeleton>

      <div class="icon">
        <BlockOutlined />
      </div>
    </Card>

    <Skeleton :loading="isLoading" active>
      <div v-if="!isLoading">
        <Tabs default-active-key="1" style="margin: 8px">
          <TabPane key="1" tab="Informazioni">
            <Card :bordered="false" class="widget-1" style="margin-top: 20px">
              <h5 style="color: #3f3f3f">Informazioni Principali</h5>
              <Divider />
              <Form ref="form" :model="formData">
                <FormItem label="Codice" label-placement="top">
                  <Input v-model:value="formData.codice" />
                </FormItem>
                <FormItem label="Provincia" label-placement="top">
                  <AutoComplete
                    v-model:value="formData.provincia"
                    :options="provinceOptions"
                    placeholder=""
                  />
                </FormItem>
                <FormItem label="Comune" label-placement="top">
                  <AutoComplete
                    v-model:value="formData.comune"
                    :options="comuniOptions"
                    placeholder=""
                  />
                </FormItem>

                <FormItem label="Indirizzo" label-placement="top">
                  <Input v-model:value="formData.indirizzo" />
                </FormItem>
                <FormItem label="Superficie" label-placement="top">
                  <Input
                    type="number"
                    v-model:value="formData.superficie"
                    addon-before="mq"
                  />
                </FormItem>
                <FormItem label="Progetto" label-placement="top">
                  <Input v-model:value="formData.progetto" />
                </FormItem>

                <FormItem label="Coordinate" label-placement="top">
                  <InputGroup compact>
                    <InputNumber
                      v-model:value="formData.coordinate.lat"
                      addon-before="lat"
                    />
                    <InputNumber
                      v-model:value="formData.coordinate.lon"
                      addon-before="lon"
                    />
                    <Tooltip>
                      <template #title>Copia coordinate</template>
                      <CopyOutlined @click="copyCoordinates"
                    /></Tooltip>
                  </InputGroup>
                </FormItem>
                <FormItem label="Link Google Earth" label-placement="top">
                  <Input v-model:value="formData.g_earth_url" />
                </FormItem>

                <FormItem label="Confini definiti" label-placement="top">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      gap: 10px;
                    "
                  >
                    <Checkbox v-model:value="formData.confini_definiti" />
                    <DatePicker
                      v-model:value="formData.confini_definiti_date"
                      placeholder="Data"
                    />
                  </div>
                </FormItem>

                <iframe
                  style="
                    border-radius: 15px;
                    border: 1px solid black;
                    height: 100%;
                    width: 36%;
                  "
                  :src="
                    'https://maps.google.com/maps?q=' +
                    formData.coordinate.lat +
                    ',' +
                    formData.coordinate.lon +
                    '&t=&z=10&ie=UTF8&iwloc=&output=embed'
                  "
                />
                <div class="buttons-wrapper">
                  <Button
                    v-if="userPermission != 'view'"
                    type="primary"
                    @click="handleConfirm"
                  >
                    <CheckOutlined /> Conferma</Button
                  >

                  <Button type="primary" @click="showNoteModal = true">
                    <CommentOutlined />Note</Button
                  >

                  <Button
                    v-if="userPermission != 'view'"
                    type="danger"
                    @click="handleDelete"
                  >
                    <DeleteOutlined />Elimina Terreno</Button
                  >
                </div>
              </Form>
            </Card>
          </TabPane>

          <TabPane key="2" tab="Proprietari">
            <Card :bordered="false" class="widget-1">
              <h5 style="color: #3f3f3f">Lista Proprietari</h5>
              <Divider />

              <Button
                v-if="userPermission != 'view'"
                type="primary"
                @click="handleAddOwnerClick"
              >
                <PlusCircleOutlined />Aggiungi Proprietario</Button
              >
              <ProprietariList
                style="margin-top: 10px"
                v-if="formData.proprietari.length"
                :proprietari="formData.proprietari"
                @owner-remove="handleOwnerRemove"
              />
              <Empty v-else :image="emptyImage">
                <template #description> Nessun proprietario </template>
              </Empty>
            </Card>
          </TabPane>

          <TabPane key="3" tab="Sopralluoghi">
            <Card :bordered="false" class="widget-1">
              <h5 style="color: #3f3f3f">Sopralluoghi</h5>
              <Divider />

              <Button
                v-if="userPermission != 'view'"
                type="primary"
                @click="handleAddOwnerVisitClick"
                ><PlusCircleOutlined />Aggiungi Visita</Button
              >

              <VisiteTable
                style="margin-top: 10px"
                v-if="formData.visite.length"
                :visite="formData.visite"
                @visit-remove="handleVisitRemove"
                @visit-notes="handleVisitNotes"
              />

              <Empty v-else :image="emptyImage">
                <template #description> Nessun Sopralluogo </template>
              </Empty>
            </Card>
          </TabPane>

          <TabPane key="4" tab="Particelle">
            <Card :bordered="false" class="widget-1">
              <h5 style="color: #3f3f3f">Particelle</h5>
              <Divider />

              <div class="new-entity-wrapper" v-if="userPermission != 'view'">
                <Input
                  type="number"
                  placeholder="Particella"
                  v-model:value="newParticella.numero"
                />
                <Input
                  type="number"
                  placeholder="Reddito agrario"
                  v-model:value="newParticella.reddito_agrario"
                />
                <Input
                  type="number"
                  placeholder="Reddito domenicale"
                  v-model:value="newParticella.reddito_domenicale"
                />
                <Input
                  type="number"
                  placeholder="Superficie"
                  v-model:value="newParticella.superficie"
                />
                <Button @click="handleNewParticella" type="primary"
                  ><PlusCircleOutlined />Aggiungi</Button
                >
              </div>

              <ParticelleTable
                style="margin-top: 10px"
                v-if="formData.particelle.length"
                :particelle="formData.particelle"
                @delete-particella="handleRemoveParticella"
              />

              <Empty v-else :image="emptyImage">
                <template #description> Nessuna particella </template>
              </Empty>
            </Card>
          </TabPane>

          <TabPane key="5" tab="Documenti">
            <Card :bordered="false" class="widget-1">
              <h5 style="color: #3f3f3f">Documenti</h5>
              <Divider />

              <Button
                v-if="userPermission != 'view'"
                type="primary"
                @click="showCaricaDocumentoModal = true"
              >
                <CloudUploadOutlined />Carica Documento</Button
              >
              <DocumentiTable
                style="margin-top: 10px"
                @delete-documento="handleDeleteDocumento"
                :documenti="formData.documenti"
                v-if="formData.documenti.length"
              />

              <Empty v-else :image="emptyImage">
                <template #description> Nessun documento </template>
              </Empty>
            </Card>
          </TabPane>

          <TabPane key="6" tab="Gestione">
            <Card :bordered="false" class="widget-1">
              <h5 style="color: #3f3f3f">Gestione</h5>
              <Divider />

              <Button
                v-if="userPermission != 'view'"
                type="primary"
                @click="showAddGestioneModal = true"
                ><PlusCircleOutlined />Aggiungi Gestione</Button
              >
              <GestioneTable
                style="margin-top: 10px"
                v-if="formData.gestione.length"
                :gestione="formData.gestione"
                @gestione-remove="handleGestioneRemove"
              />
              <Empty v-else :image="emptyImage">
                <template #description> Nessuna gestione </template>
              </Empty>
            </Card>
          </TabPane>

          <TabPane key="7" tab="Destinazioni d'Uso">
            <Card :bordered="false" class="widget-1">
              <h5 style="color: #3f3f3f">Destinazioni d'uso</h5>
              <Divider />

              <div class="new-entity-wrapper" v-if="userPermission != 'view'">
                <Input
                  v-model:value="newUsage.nome"
                  placeholder="Destinazione d'uso"
                ></Input>
                <Input
                  v-model:value="newUsage.percentuale"
                  type="number"
                  placeholder="Percentuale"
                ></Input>
                <Button @click="handleAddUsage" type="primary"
                  ><PlusCircleOutlined style="color: #0092fb" />Aggiungi</Button
                >
              </div>

              <TerrenoUsoTable
                style="margin-top: 10px"
                @delete-utilizzo="handleDeleteUsage"
                v-if="formData.utilizzi?.length"
                :utilizzi="formData.utilizzi"
              />
              <Empty v-else :image="emptyImage">
                <template #description> Nessuna destinazione d'uso </template>
              </Empty>
            </Card>
          </TabPane>
        </Tabs>
      </div>
    </Skeleton>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import {
  Form,
  Input,
  InputNumber,
  InputGroup,
  Button,
  Modal,
  Skeleton,
  Alert,
  Tooltip,
  message,
  Empty,
  Drawer,
  AutoComplete,
  Checkbox,
  DatePicker,
  Card,
  Statistic,
  Tabs,
  TabPane,
  Divider,
} from "ant-design-vue";

import ProprietariList from "@/components/proprietari/ProprietariList.vue";
import VisiteTable from "@/components/proprietari/VisiteTable.vue";
import AddProprietarioModal from "@/components/proprietari/AddProprietarioModal.vue";
import AddProprietarioVisitModal from "@/components/proprietari/AddProprietarioVisitModal.vue";
import DocumentiTable from "@/components/terreni/DocumentiTable.vue";
import ParticelleTable from "@/components/terreni/ParticelleTable.vue";
import {
  DeleteOutlined,
  CheckOutlined,
  CloudUploadOutlined,
  PlusCircleOutlined,
  CopyOutlined,
  CommentOutlined,
  BlockOutlined,
} from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/store/user.store";
import { LandsApi } from "@/common/api/lands.api";
import UploadDocumentoModalVue from "@/components/terreni/UploadDocumentoModal.vue";
import TerrenoUsoTable from "@/components/terreni/TerrenoUsoTable.vue";
import NoteModal from "@/components/terreni/NoteModal.vue";
import { UsersApi } from "@/common/api/users.api";
import listaProvince from "@/common/list/province";
import listaComuni from "@/common/list/comuni";
import moment from "moment";
import { storage } from "@/firebase";
import { ref as firebaseRef, deleteObject } from "firebase/storage";
import AddGestioneModal from "@/components/utenti/AddGestioneModal.vue";
import GestioneTable from "@/components/utenti/GestioneTable.vue";
export default {
  components: {
    Form,
    Button,
    FormItem: Form.Item,
    Modal,
    Input,
    InputNumber,
    InputGroup,
    ProprietariList,
    VisiteTable,
    DeleteOutlined,
    CheckOutlined,
    CloudUploadOutlined,
    PlusCircleOutlined,
    AddProprietarioModal,
    AddProprietarioVisitModal,
    Skeleton,
    Alert,
    Tooltip,
    UploadDocumentoModalVue,
    DocumentiTable,
    ParticelleTable,
    Divider,
    CopyOutlined,
    TerrenoUsoTable,
    Empty,
    CommentOutlined,
    NoteModal,
    Drawer,
    AutoComplete,
    Checkbox,
    DatePicker,
    Card,
    Statistic,
    BlockOutlined,
    Tabs,
    TabPane,
    AddGestioneModal,
    GestioneTable,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const showAddOwnerModal = ref(false);
    const showAddOwnerVisitModal = ref(false);
    const showNoteModal = ref(false);
    const showAddGestioneModal = ref(false);
    const isLoading = ref(true);
    const proprietari = [];
    const formData = ref();
    const action = ref({ show: false, message: "", type: "", header: "" });
    const visiteFuture = ref();
    const userStore = useUserStore();
    const userPermission = userStore.user.ruolo;
    const showCaricaDocumentoModal = ref(false);
    const landsApi = new LandsApi(userStore.user.access_token);
    const usersApi = new UsersApi(userStore.user.access_token);
    const newParticella = ref({
      numero: null,
      reddito_agrario: null,
      reddito_domenicale: null,
      superficie: null,
    });
    const utentiAll = ref([]);
    const newGestione = ref({});
    const newGestioneUtente = ref();
    const newGestioneRuolo = ref("");
    const showVisitNoteModal = ref({ visible: false, visita: "" });
    const utentiAllOptions = computed(() =>
      utentiAll.value.map((utente) => {
        return {
          value: utente._id,
          label: `${utente.nome} ${utente.cognome}`,
        };
      })
    );

    const provinceOptions = computed(() =>
      listaProvince.map((provincia, i) => {
        return {
          value: provincia,
          id: i,
        };
      })
    );

    const comuniOptions = computed(() =>
      listaComuni[formData.value.provincia || "BA"].map((comune, i) => {
        return {
          value: comune,
          id: i,
        };
      })
    );

    function setVisiteFuture(visite) {
      visiteFuture.value = visite.filter(
        (visita) => new Date(visita.visita) > new Date().getTime()
      );
    }
    const newUsage = ref({
      nome: "",
      percentuale: null,
    });
    const emptyImage = Empty.PRESENTED_IMAGE_SIMPLE;
    landsApi
      .getLand(route.params.id)
      .then((response) => {
        if (response.success) {
          formData.value = response.land;
          if (formData.value.confini_definiti_date)
            formData.value.confini_definiti_date = moment(
              formData.value.confini_definiti_date
            );
        } else {
          action.value = {
            show: true,
            message: "Impossibile reperire terreno, riprova",
            type: "error",
            header: "Error",
          };
        }
      })
      .catch(() => {
        action.value = {
          show: true,
          message: "Impossibile reperire terreno, riprova",
          type: "error",
          header: "Error",
        };
      })
      .finally(() => {
        isLoading.value = false;
      });
    usersApi.getAllUsers().then((response) => {
      if (response.success) {
        utentiAll.value = response.users;
        console.log(utentiAll.value);
      }
    });

    function handleConfirm() {
      if (formData.value.gestione) {
        formData.value.gestione = formData.value.gestione.map((gestione) => {
          return {
            utente: gestione.utente._id,
            ruolo: gestione.ruolo,
          };
        });
      }
      landsApi.updateLand(route.params.id, formData.value).then((response) => {
        if (response.success) {
          message.success("Terreno aggiornato correttamente");
        }
      });
    }

    function handleDelete() {
      isLoading.value = true;
      landsApi
        .deleteLand(route.params.id)
        .then((response) => {
          if (response.success) {
            message.success("Terreno eliminato correttamente");
            router.push("/terreni");
          } else throw new Error("Impossibile eliminare terreno, riprova");
        })
        .catch((error) => message.error(error.message))
        .finally(() => (isLoading.value = false));
    }

    function handleViewLandClick(id) {
      router.push(`/terreni/${id}`);
    }

    function handleGestioneChange(data) {
      newGestioneUtente.value = utentiAll.value.find(
        (utente) => utente._id == data
      );
      console.log(newGestioneUtente);
    }

    function handleAddProprietario(data) {
      console.log(data);
      isLoading.value = true;
      landsApi
        .updateLand(route.params.id, {
          proprietari: [...formData.value.proprietari, data],
        })
        .then((response) => {
          if (response.success) {
            message.success("Proprietario aggiunto correttamente");
            formData.value.proprietari.push(data);
          } else throw new Error("Impossibile aggiungere proprietario");
        })
        .catch((error) => {
          message.error(error.message);
        })
        .finally(() => {
          isLoading.value = false;
          showAddOwnerModal.value = false;
        });
    }

    function handleAddGestione(data) {
      console.log(data);
      isLoading.value = true;
      landsApi
        .updateLand(route.params.id, {
          gestione: [...formData.value.gestione, data],
        })
        .then((response) => {
          if (response.success) {
            message.success("Gestione aggiunta correttamente");
            formData.value.gestione.push(data);
          } else throw new Error("Impossibile aggiungere gestione");
        })
        .finally(() => {
          isLoading.value = false;
          showAddGestioneModal.value = false;
        })
        .catch((error) => {
          message.error(error.message);
        });
    }

    function handleDocumentUpload(payload) {
      showCaricaDocumentoModal.value = false;

      isLoading.value = true;
      landsApi
        .updateLand(route.params.id, {
          documenti: [...formData.value.documenti, payload],
        })
        .then((response) => {
          if (response.success) {
            message.success("Documento aggiunto correttamente");
          }
          formData.value.documenti.push(payload);
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function handleDeleteDocumento(documento) {
      isLoading.value = true;
      const storageRef = firebaseRef(
        storage,
        `documenti/${documento.nome_originale}`
      );

      deleteObject(storageRef)
        .then(() => {
          formData.value.documenti = formData.value.documenti.filter(
            (doc) => doc.url != documento.url
          );
          landsApi
            .updateLand(route.params.id, formData.value)
            .then((response) => {
              if (response.success) {
                message.success("Documento eliminato");
              }
            });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (isLoading.value = false));
    }

    function handleAddProprietarioVisit(data) {
      isLoading.value = true;
      console.log([...formData.value.visite, data]);
      landsApi
        .updateLand(route.params.id, {
          visite: [...formData.value.visite, data],
        })
        .then((response) => {
          if (response.success) {
            message.success("Sopralluogo aggiunto correttamente");
          }
          formData.value.visite = [...formData.value.visite, data];
          setVisiteFuture([...formData.value.visite, data]);
        })
        .finally(() => {
          isLoading.value = false;
          showAddOwnerVisitModal.value = false;
        });
    }

    function handleAlertClose() {
      action.value = {
        header: "",
        show: false,
        message: "",
        type: "",
      };
    }

    function handleAddOwnerClick() {
      showAddOwnerModal.value = true;
    }

    function handleAddOwnerVisitClick() {
      showAddOwnerVisitModal.value = true;
    }

    function handleParentLandClick(id) {
      router.push(`/terreni/${id}`);
    }

    function handleOwnerRemove(id) {
      isLoading.value = true;
      const updated = formData.value.proprietari.filter(
        (proprietario) => proprietario.proprietario._id !== id
      );
      landsApi
        .updateLand(route.params.id, {
          proprietari: updated,
        })
        .then((response) => {
          if (response.success) {
            message.success("Proprietario rimosso correttamente");
            formData.value.proprietari = updated;
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function handleVisitNotes(data) {
      showVisitNoteModal.value = { visible: true, visita: data.visita_id };
    }

    function handleCloseVisitNoteModal() {
      showVisitNoteModal.value = { visible: false, visita: "" };
    }

    function handleVisitRemove(data) {
      isLoading.value = true;
      const filtered = formData.value.visite.filter(
        (visita) => visita._id !== data.visita_id
      );

      landsApi
        .updateLand(route.params.id, {
          visite: filtered,
        })
        .then((response) => {
          if (response.success) {
            message.success("Sopralluogo rimossa correttamente");
          }
          formData.value.visite = filtered;
          setVisiteFuture(filtered);
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function handleNewParticella() {
      formData.value.particelle.push(newParticella.value);
      landsApi.updateLand(route.params.id, formData.value).then((response) => {
        if (response.success) {
          message.success("Particella aggiunta correttamente");
        }
      });
    }

    function handleRemoveParticella(particella) {
      let idx = formData.value.particelle.indexOf(particella);
      formData.value.particelle.splice(idx, 1);
      landsApi.updateLand(route.params.id, formData.value).then((response) => {
        if (response.success) {
          message.success("Particella rimossa correttamente");
        }
      });
    }

    function copyCoordinates() {
      const coordinates = `${formData.value.coordinate.lat},${formData.value.coordinate.lon}`;
      navigator.clipboard.writeText(coordinates);
      message.success("Coordinate copiate");
    }

    function handleAddUsage() {
      console.log(formData.value.utilizzi);
      if (
        formData.value.utilizzi?.find(
          (utilizzo) => utilizzo.nome == newUsage.value.nome
        )
      )
        return message.error("Uso già presente");
      if (formData.value.utilizzi) formData.value.utilizzi.push(newUsage.value);
      else formData.value.utilizzi = [newUsage.value];

      landsApi.updateLand(route.params.id, formData.value).then((response) => {
        if (response.success) {
          message.success("Desintazione d'uso aggiunta correttamente");
        }
      });

      newUsage.value = {
        nome: "",
        percentuale: 0,
      };
    }

    function handleDeleteUsage(utilizzo) {
      console.log(utilizzo);
      formData.value.utilizzi = formData.value.utilizzi.filter(
        (usage) => usage.nome !== utilizzo.nome
      );
      formData.value.utilizzi;
    }

    function handleGestioneRemove(gestione) {
      formData.value.gestione = formData.value.gestione.filter(
        (gest) => gest._id !== gestione
      );
      landsApi.updateLand(route.params.id, formData.value).then((response) => {
        if (response.success) {
          message.success("Gestione eliminata correttamente");
        }
      });
    }

    function handleNewNote(nota) {
      const notaPayload = {
        author: `${userStore.user.nome} ${userStore.user.cognome}`,
        content: nota,
        datetime: new Date(),
      };

      if (formData.value.note) {
        formData.value.note.push(notaPayload);
      } else formData.value.note = [notaPayload];

      landsApi.updateLand(route.params.id, formData.value).then((response) => {
        if (response.success) {
          message.success("Nota aggiunta correttamente");
        }
      });
    }

    function handleDeleteNote(nota) {
      formData.value.note = formData.value.note.filter((note) => note !== nota);

      landsApi.updateLand(route.params.id, formData.value).then((response) => {
        if (response.success) {
          message.success("Nota eliminata correttamente");
        }
      });
    }

    function handleNewVisitaNote(nota) {
      const notaPayload = {
        author: `${userStore.user.nome} ${userStore.user.cognome}`,
        content: nota,
        datetime: new Date(),
      };
      let visit = formData.value.visite.filter(
        (visit) => visit._id == showVisitNoteModal.value.visita
      )[0];
      if (visit.note) {
        visit.note.push(notaPayload);
      } else visit.note = [notaPayload];

      landsApi.updateLand(route.params.id, formData.value).then((response) => {
        if (response.success) {
          message.success("Nota aggiunta correttamente");
        }
      });
    }

    function handleDeleteVisitaNote(nota) {
      let visit = formData.value.visite.filter(
        (visit) => visit._id == showVisitNoteModal.value.visita
      )[0];

      visit.note = visit.note.filter((note) => note !== nota);

      landsApi.updateLand(route.params.id, formData.value).then((response) => {
        if (response.success) {
          message.success("Nota eliminata correttamente");
        }
      });
    }

    function handleDeleteGestione(gestione) {
      formData.value.gestione = formData.value.gestione.filter(
        (g) => g.utente !== gestione.utente
      );
      message.success("Utente rimosso correttamente");
    }

    return {
      formData,
      proprietari,
      showAddOwnerModal,
      handleAddOwnerClick,
      showAddOwnerVisitModal,
      handleAddOwnerVisitClick,
      isLoading,
      handleConfirm,
      action,
      handleAlertClose,
      handleDelete,
      handleAddProprietario,
      handleOwnerRemove,
      handleAddProprietarioVisit,
      visiteFuture,
      handleVisitRemove,
      userPermission,
      handleParentLandClick,
      handleViewLandClick,
      showCaricaDocumentoModal,
      handleDocumentUpload,
      handleNewParticella,
      handleRemoveParticella,
      copyCoordinates,
      emptyImage,
      handleAddUsage,
      newUsage,
      handleDeleteUsage,
      showNoteModal,
      handleNewNote,
      handleDeleteNote,
      utentiAll,
      newGestione,
      handleGestioneChange,
      handleAddGestione,
      utentiAllOptions,
      newGestioneRuolo,
      handleDeleteGestione,
      provinceOptions,
      comuniOptions,
      showVisitNoteModal,
      handleNewVisitaNote,
      handleDeleteVisitaNote,
      handleVisitNotes,
      handleCloseVisitNoteModal,
      handleDeleteDocumento,
      newParticella,
      showAddGestioneModal,
      handleGestioneRemove,
    };
  },
};
</script>

<style scoped>
.form {
  display: flex;
  flex-direction: column;
}

.form-item {
  margin-bottom: 20px;
}

.coordinate-input {
  display: flex;
}
.coordinate-input > * {
  flex: 1;
  margin-right: 10px;
}
.coordinate-input > *:last-child {
  margin-right: 0;
}
.buttons-wrapper {
  margin-top: 20px;
}
.buttons-wrapper > * {
  margin: 2px;
}
.new-entity-wrapper {
  display: flex;
  flex-direction: row;
  width: 45%;
  gap: 20px;
  align-items: center;
}
</style>
