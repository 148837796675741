<template>
  <Alert
    v-if="action.show"
    :message="action.header"
    :description="action.message"
    :type="action.type"
    showIcon
    closable
    :afterClose="handleAlertClose"
  />

  <Card :bordered="false" class="widget-1">
    <Skeleton :loading="isLoading" active>
      <Statistic
        v-if="!isLoading"
        title="Utente"
        :value="formData.nome + ' ' + formData.cognome"
        class="text-success"
      />
    </Skeleton>

    <div class="icon">
      <TeamOutlined />
    </div>
  </Card>
  <Skeleton :loading="isLoading" active>
    <div v-if="!isLoading">
      <Card style="margin-top: 20px" :bordered="false" class="widget-1">
        <Form ref="form" :model="formData">
          <FormItem label="ID">
            <Input v-model:value="formData._id" disabled />
          </FormItem>
          <FormItem label="Nome" label-placement="top">
            <Input v-model:value="formData.nome" />
          </FormItem>
          <FormItem label="Cognome" label-placement="top">
            <Input v-model:value="formData.cognome" />
          </FormItem>
          <FormItem label="Email" label-placement="top">
            <Input v-model:value="formData.email" />
          </FormItem>

          <FormItem label="Ruolo" name="ruolo">
            <Select v-model:value="formData.ruolo">
              <SelectOption value="edit">Edit</SelectOption>
              <SelectOption value="view">View</SelectOption>
              <SelectOption value="admin">Admin</SelectOption>
            </Select>
          </FormItem>

          <FormItem>
            <div class="buttons-wrapper">
              <Button type="primary" @click="handleConfirm">
                <CheckOutlined /> Conferma</Button
              >

              <Button @click="handleDelete" type="danger">
                <DeleteOutlined />Elimina</Button
              >
            </div>
          </FormItem>
        </Form>
      </Card>
    </div>
  </Skeleton>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { UsersApi } from "@/common/api/users.api";
import {
  DeleteOutlined,
  CheckOutlined,
  TeamOutlined,
} from "@ant-design/icons-vue";
import {
  Form,
  Input,
  Button,
  Select,
  Alert,
  message,
  Card,
  Statistic,
  Skeleton,
} from "ant-design-vue";
import { useUserStore } from "@/store/user.store";

export default {
  components: {
    Skeleton,
    Button,
    Input,
    Form,
    FormItem: Form.Item,
    Select,
    Alert,
    SelectOption: Select.Option,
    DeleteOutlined,
    CheckOutlined,
    TeamOutlined,
    Card,
    Statistic,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(true);
    const formData = ref();
    const action = ref({ show: false, message: "", type: "", header: "" });
    const userStore = useUserStore();
    const usersApi = new UsersApi(userStore.user.access_token);

    usersApi
      .getUser(route.params.id)
      .then((response) => {
        if (response.success) formData.value = response.user;
        else
          action.value = {
            header: "Errore",
            show: true,
            message: "Impossibile reperire utente",
            type: "error",
          };
      })
      .finally(() => {
        isLoading.value = false;
      });

    function handleConfirm() {
      isLoading.value = true;
      usersApi
        .updateUser(route.params.id, formData.value)
        .then((response) => {
          if (response.success) {
            message.success("Utete aggiornato correttamente");
          } else {
            message.error("Impossibile aggiornare utente, riprova");
          }
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    function handleDelete() {
      usersApi.deleteUser(route.params.id).then((response) => {
        if (response.success) {
          message.success("Utete eliminato correttamente");

          router.push("/utenti");
        }
      });
    }

    return {
      route,
      router,
      isLoading,
      formData,
      handleConfirm,
      handleDelete,
      action,
    };
  },
};
</script>

<style scoped>
.buttons-wrapper {
  display: flex;
  gap: 10px;
}
</style>
