<template>
  <Layout v-if="activeMenu !== 'login'">
    <Layout.Sider
      collapsible
      :collapsedWidth="0"
      style="height: 100vh; overflow: hidden"
      theme="light"
    >
      <div class="menu-wrapper">
        <Menu
          theme="light"
          mode="inline"
          :defaultSelectedKeys="[activeMenu]"
          :style="{ flex: 1 }"
        >
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <img
              src="@/assets/logo.png"
              width="100"
              style="align-self: center"
            />
          </div>
          <Divider />
          <Menu.Item key="Home">
            <router-link to="/">
              <HomeOutlined />
              <span>Home</span>
            </router-link>
          </Menu.Item>

          <Menu.Item key="Terreni">
            <router-link to="/terreni">
              <BlockOutlined />
              <span>Terreni</span>
            </router-link>
          </Menu.Item>
          <Menu.Item key="Proprietari">
            <router-link to="/proprietari">
              <UserOutlined />
              <span>Proprietari</span>
            </router-link>
          </Menu.Item>
          <Menu.Item key="Utenti" v-if="userPermission == 'admin'">
            <router-link to="/utenti">
              <TeamOutlined />
              <span>Utenti</span>
            </router-link>
          </Menu.Item>
        </Menu>
        <div class="control-center-wrapper">
          <ControlCenter :user="user" />
        </div>
      </div>
    </Layout.Sider>

    <Layout.Content style="padding: 24px; overflow-y: scroll">
      <router-view />
    </Layout.Content>
  </Layout>
  <router-view v-else />
</template>

<script setup>
import { Layout, Menu, Divider } from "ant-design-vue";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  UserOutlined,
  TeamOutlined,
  BlockOutlined,
  HomeOutlined,
} from "@ant-design/icons-vue";
import ControlCenter from "./components/sidebar/ControlCenter.vue";
import { useUserStore } from "./store/user.store";
import { UsersApi } from "./common/api/users.api";
const userStore = useUserStore();
const user = userStore.user;
const userPermission = computed(() => userStore.user.ruolo);
const router = useRouter();
const route = useRoute();
const activeMenu = computed(() => route.path.split("/")[1]);

if (user.access_token) {
  const usersApi = new UsersApi(user.access_token);
  usersApi.getUser("me").then((response) => {
    console.log(response);
    if (!response.success) {
      userStore.logout();
      router.push("/login");
    }
  });
}
</script>

<style scoped>
.menu-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ant-layout-content {
  margin-left: 200px;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .ant-layout-content {
    margin-left: 0;
  }
}

.menu-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-layout-sider {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.control-center-wrapper {
  margin-top: auto;
  margin-bottom: 20px;
}
</style>

<style>
.ant-table-container {
  border: 1px solid #f0f0f0 !important;
  border-radius: 10px;
}
</style>
