<template>
  <div>
    <Table :columns="columns" :dataSource="dataSource" rowKey="id">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'buttons'">
          <div class="actions-column">
            <Button @click="handleViewClick(record)" type="primary">
              <EyeOutlined /> Visualizza
            </Button>
            <Button @click="handleDeleteClick(record)" type="danger">
              <DeleteOutlined />
              Elimina
            </Button>
          </div>
        </template>
      </template>
    </Table>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { Table, Button } from "ant-design-vue";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons-vue";
export default {
  name: "UserTable",
  components: {
    Table,
    DeleteOutlined,
    EyeOutlined,
    Button,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  emits: ["view-user", "delete-user"],
  setup(props, { emit }) {
    const columns = ref([
      {
        title: "Nome",
        dataIndex: "nome",
      },
      {
        title: "Cognome",
        dataIndex: "cognome",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Ruolo",
        dataIndex: "ruolo",
      },
      {
        title: "Actions",
        dataIndex: "buttons",
      },
    ]);

    const dataSource = computed(() => {
      return props.users.map((user) => {
        const { _id, nome, cognome, email, ruolo } = user;
        return { _id, nome, cognome, email, ruolo };
      });
    });

    const handleViewClick = (record) => {
      console.log("Viewing user:", record._id);
      emit("view-user", record._id);
    };

    const handleDeleteClick = (record) => {
      emit("delete-user", record._id);
    };

    return {
      columns,
      dataSource,
      handleViewClick,
      handleDeleteClick,
    };
  },
};
</script>

<style scoped>
.actions-column {
  display: flex;
  gap: 10px;
}
</style>
